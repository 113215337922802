import styled from 'styled-components';
import Button from '../Button';
import FileInputButton from '../FileInputButton';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.19fr 1fr;
  grid-auto-flow: column;

  & > div:first-child {
    padding-right: 10px;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }

  & > div:nth-child(3) {
    padding-left: 10px;
  }

  & > div:nth-child(-n + 2) {
    border-right: 1px solid rgba(22, 22, 67, 0.12);
  }
`;

export const ChangePasswordButton = styled(Button)`
  min-width: 194px;
`;

export const UndoButton = styled(Button)`
  min-width: auto;
`;

export const ChangePasswordButtonWrapper = styled.div`
  width: 264px;
  text-align: left;

  & > ${UndoButton} {
    margin-left: auto;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LogoContent = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 264px;
  margin-left: auto;
`;

export const LogoTitle = styled.div`
  font-family: ProximaNova-Regular, sans-serif;
  color: #1a8cff;
  font-size: 14px;
  margin-bottom: 14px;
`;

export const LogoContainer = styled.div<{whiteBg?: boolean}>`
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  background-color: ${(props) => (props.whiteBg ? 'white' : '#b4dfff')};
`;

export const LogoDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ProximaNova-Regular, sans-serif;
  color: #161643;
  font-size: 11px;
  margin-bottom: 15px;
  width: 100%;
`;

export const AddLogoFileInput = styled(FileInputButton)`
  min-width: 114px;
`;

export const MagicWandIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 7px;
`;

export const LogoPlaceholderImage = styled.img`
  width: 226px;
  height: 58px;
`;

export const CustomLogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const LogoButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > :first-child {
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;
