import styled from 'styled-components';
import {default as BaseFileInput} from '../FileInputButton';

export const ContentItemWrapper = styled.div`
  width: 100%;
  padding: 22px 0 29px;
`;

export const Wrapper = styled.div`
  margin: 28px auto 0;
  cursor: default;
  max-width: 1047px;
  padding: 0 120px;

  & ${ContentItemWrapper}:not(:last-child) {
    border-bottom: 0.5px solid rgba(0, 66, 154, 0.2);
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const HeaderText = styled.div`
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 21px;
  color: #161643;
  width: fit-content;
  margin: 0 auto;
`;

export const InputWrapper = styled.div`
  margin-left: 20px;

  & > button {
    min-width: 200px;
  }
`;

export const FileInputButton = styled(BaseFileInput)`
  min-width: 200px;
`;

export const ContentTextWrapper = styled.div`
  text-align: left;
  font-size: 18px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  margin-bottom: 18px;
  padding-right: 110px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const FileInputWrapper = styled.div`
  margin-right: 10px;
`;

export const SelectedFilesList = styled.div`
  display: flex;
  margin-bottom: 14px;
  flex-wrap: wrap;
`;

export const SelectedFileItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 6px 4px 6px 9px;
  margin-right: 10px;
  border-radius: 3px;
  min-width: 144px;
  cursor: default;
  margin-bottom: 10px;
`;

export const SelectedFileItemRemoveImg = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-radius: 3px;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    box-shadow: 0 3px 3px #0f477734;
  }

  &:active {
    opacity: 0.95;
  }
`;

export const SelectedFileItemText = styled.div`
  font-size: 15px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  margin-right: 11px;
`;

export const ToastContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ToastText = styled.div`
  display: flex;
  font-size: 16px;
  margin: 0 9px 0 1px;
  font-family: ProximaNova-Semibold, sans-serif;
  color: #ffffff;
`;

export const ToastImg = styled.img`
  width: 47px;
  height: 29px;
  margin-left: 1px;
  margin-right: 10px;
`;

export const DirectDownloadIcon = styled.img`
  height: 14px;
  width: 18px;
`;
