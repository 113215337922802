import React from 'react';
import moment from 'moment';
import {queryCache, useInfiniteQuery, useQuery} from 'react-query';
import {useInfiniteScroll} from 'react-infinite-scroll-hook';
import {Column, Row, useTable} from 'react-table';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {format, isDate, set} from 'date-fns';
import {toast} from 'react-toastify';
import {
  getSearchParamFromUrl,
  toastResponseError,
  getMenuOptions,
  MenuItemsType,
} from '../../../utils/common';
import {Housing, LightReservation, Reservation, SelectOption} from '../../../utils/types';
import {getGroupMembersNumber, getGuestLeaderName} from '../../../utils/guestGroup';
import {
  useErrorToast,
  useModalControls,
  useSubscriptionAskModal,
} from '../../../utils/hooks';
import {useAuth} from '../../../context/auth';
import {useWebsocket} from '../../../context/websocket';
import {WS_EVENT_TYPES} from '../../../utils/constants';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import {getName} from '../../../utils/housing';
import {getStatusesDescriptions, STATUS_TYPES} from '../../../utils/statuses';
import clockIcon from '../../../assets/clock.svg';
import acceptIcon from '../../../assets/accept-icn-black.svg';
import moreIcon from '../../../assets/icon-more.svg';
import moreIconActiveBlue from '../../../assets/icon-more-active-blue.svg';
import plusIcon from '../../../assets/plus-blue.svg';
import refreshIcon from '../../../assets/refresh.svg';
import addBookingIcon from '../../../assets/add-booking-icon.svg';
import notFoundIcon from '../../../assets/notfound-icon.svg';
import HeadingSelect from '../HeadingSelect';
import SearchHousingsModal from '../SearchHousingsModal';
import SearchGuestLeaderModal from '../SearchGuestLeaderModal';
import SearchDateModal from '../SearchDateModal';
import SearchButton from '../SearchButton';
import TableLoader from '../TableLoader';
import TablePlaceholder, {EMPTY_TABLE_ROWS_NUMBER} from '../TablePlaceholder';
import TableFilter, {DateTableFilter} from '../TableFilter';
import Loader from '../../common/Loader';
import ImportCompleteModal from '../ImportCompleteModal';
import {formatDate} from '../../../utils/date';
import {GuestGroup} from '../../../utils/types';
import {SearchDates} from '../SearchDateModal/SearchDateModal';
import {
  CapitalizeWrapper,
  MissingDataText,
  TablePlaceholderWrapper,
} from '../../../styled/common';
import ModalButton from '../ModalButton';
import {
  Content,
  Heading,
  HeadingButton,
  HeadingSection,
  ActionSection,
  StatusButton,
  StatusButtonCheckMarkIcon,
  StatusButtonClockIcon,
  StatusCell,
  StatusText,
  StatusTooltip,
  TableHeader,
  TableWrapper,
  LoaderWrapper,
  RefreshButtonLoaderWrapper,
  HeadingRefreshButton,
  ThreeDotsImg,
  ReservationTableHeaderItem,
} from './styled';

const STORAGE_RESERVATIONS_STATUS_FILTER = 'reservationsStatusFilter';
const STORAGE_RESERVATIONS_HOUSING_NAME_FILTER = 'reservationsHousingNameFilter';
const STORAGE_RESERVATIONS_EXACT_HOUSING_NAME_FILTER =
  'reservationsExactHousingNameFilter';
const STORAGE_RESERVATIONS_GUEST_FILTER = 'reservationsGuestFilter';
const STORAGE_RESERVATIONS_DATE_FILTER = 'reservationDateFilter';
const STORAGE_RESERVATIONS_CUSTOM_DATE_FILTER = 'reservationCustomDateFilter';

const WS_EVENT_RESERVATIONS_SYNC_TYPES_ARRAY = [
  'SYNC_RESERVATIONS_FINISHED',
  'SYNC_RESERVATIONS_STARTED',
];
const SYNC_IN_PROGRESS_STATUS = 'PRO';
const SYNC_SEN_STATUS = 'SEN';

const ORDERING_PARAM = 'check_in_date,check_out_date,created_at';
const REVERSE_ORDERING_PARAM = '-check_in_date,check_out_date,created_at';
const STATUSES_IN =
  'NEW,CHECKED_IN,CHECKED_OUT,COMPLETE,IN_PROGRESS,CONFIRMED,NOT_CONFIRMED,NOSHOW';

const URL_DATE_FILTER = 'target-date';
const URL_STATUS_FILTER = 'status';

export const RESERVATION_STATUSES: {[key: string]: string} = {
  complete: 'COMPLETE',
  incomplete: 'INCOMPLETE',
  all: 'ALL',
};

const RESERVATION_STATUSES_ICONS: {
  [key: string]: React.ReactNode | JSX.Element;
} = {
  [RESERVATION_STATUSES.complete]: (
    <StatusButtonCheckMarkIcon src={acceptIcon} alt="Check mark" />
  ),
  [RESERVATION_STATUSES.incomplete]: (
    <StatusButtonClockIcon src={clockIcon} alt="Clock" />
  ),
};

const RESERVATIONS_TABLE_MENU_OPTIONS: MenuItemsType = {
  importBooking: {
    label: i18n.t('import_booking'),
    value: '/bookings/import',
    baseUrl: '/bookings/import',
  },
  reservationReport: {
    label: i18n.t('download_booking_report'),
    value: '/bookings/reservation-report',
    baseUrl: '/bookings/reservation-report',
  },
  guestReport: {
    label: i18n.t('download_guest_report'),
    value: '/bookings/guest-report',
    baseUrl: '/bookings/guest-report',
  },
};

const RESERVATION_STATUSES_FILTERS: {[key: string]: SelectOption} = {
  [RESERVATION_STATUSES.all]: {
    value: RESERVATION_STATUSES.all,
    label: i18n.t('all_statuses'),
  },
  [RESERVATION_STATUSES.complete]: {
    value: RESERVATION_STATUSES.complete,
    label: i18n.t('complete'),
  },
  [RESERVATION_STATUSES.incomplete]: {
    value: RESERVATION_STATUSES.incomplete,
    label: i18n.t('incomplete'),
  },
};
const DEFAULT_RESERVATIONS_STATUS_FILTER =
  RESERVATION_STATUSES_FILTERS[RESERVATION_STATUSES.all];

type DateFilter = SelectOption & {
  from?: string | null;
  to?: string | null;
};

enum DATE_FILTERS_KEYS {
  upcoming = 'UPCOMING',
  past = 'PAST',
  custom = 'CUSTOM',
}

const DATE_FILTERS: {[key: string]: DateFilter} = {
  [DATE_FILTERS_KEYS.upcoming]: {
    value: DATE_FILTERS_KEYS.upcoming,
    label: i18n.t('upcoming'),
    from: String(new Date()),
  },
  [DATE_FILTERS_KEYS.past]: {
    value: DATE_FILTERS_KEYS.past,
    label: i18n.t('past'),
    to: String(new Date()),
  },
  [DATE_FILTERS_KEYS.custom]: {
    value: DATE_FILTERS_KEYS.custom,
    label: i18n.t('custom'),
  },
};
const DEFAULT_RESERVATIONS_DATE_FILTER = DATE_FILTERS[DATE_FILTERS_KEYS.upcoming];

function preloadStatusFilter() {
  const prevStatus = sessionStorage.getItem(STORAGE_RESERVATIONS_STATUS_FILTER);
  if (prevStatus && RESERVATION_STATUSES_FILTERS[prevStatus]) {
    return RESERVATION_STATUSES_FILTERS[prevStatus];
  }

  sessionStorage.setItem(
    STORAGE_RESERVATIONS_STATUS_FILTER,
    String(DEFAULT_RESERVATIONS_STATUS_FILTER.value),
  );
  return DEFAULT_RESERVATIONS_STATUS_FILTER;
}

function preloadDateFilter() {
  const prevFilter = sessionStorage.getItem(STORAGE_RESERVATIONS_DATE_FILTER);
  if (prevFilter && DATE_FILTERS[prevFilter]) {
    return DATE_FILTERS[prevFilter];
  }

  sessionStorage.setItem(
    STORAGE_RESERVATIONS_DATE_FILTER,
    String(DEFAULT_RESERVATIONS_DATE_FILTER.value),
  );
  return DEFAULT_RESERVATIONS_DATE_FILTER;
}

function preloadNameFilter() {
  const prevName = sessionStorage.getItem(STORAGE_RESERVATIONS_HOUSING_NAME_FILTER);
  return prevName || '';
}

function preloadExactNameFilter() {
  const prevName = sessionStorage.getItem(STORAGE_RESERVATIONS_EXACT_HOUSING_NAME_FILTER);
  return prevName || '';
}

function preloadGuestFilter() {
  const prevName = sessionStorage.getItem(STORAGE_RESERVATIONS_GUEST_FILTER);
  return prevName || '';
}

function preloadCustomDateFilter(): SearchDates {
  const prevDates = sessionStorage.getItem(STORAGE_RESERVATIONS_CUSTOM_DATE_FILTER);
  if (!prevDates) {
    return {from: null, to: null};
  }

  const dates = JSON.parse(prevDates);
  const fromDate = dates.from && new Date(dates.from);
  const toDate = dates.to && new Date(dates.to);

  return {
    from: isDate(fromDate) ? fromDate : null,
    to: isDate(toDate) ? new Date(toDate) : null,
  };
}

type Params = {
  page?: number;
  status?: SelectOption;
  name?: string;
  dates?: DateFilter;
  customDates?: SearchDates;
  guestName?: string;
  exactName?: string;
};

function getStatusQuery(option: SelectOption) {
  if (option.value === RESERVATION_STATUSES.all) {
    return `status_in=${STATUSES_IN}`;
  }
  return `complete_status=${option.value}`;
}

type DatesParams = Pick<Params, 'customDates' | 'dates'>;

function getDatesQueries({
  customDates = {from: null, to: null},
  dates = DEFAULT_RESERVATIONS_DATE_FILTER,
}: DatesParams) {
  const startDate = customDates.from || dates.from;
  const endDate = customDates.to || dates.to;
  const startOfDayStartDate =
    startDate &&
    set(new Date(startDate), {
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  const endOfDayEndDate =
    endDate &&
    set(new Date(endDate), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });
  const formattedStartDate = startOfDayStartDate
    ? format(startOfDayStartDate, "yyyy-MM-dd'T'HH:mm:ss")
    : '';
  const formattedEndDate = endOfDayEndDate
    ? format(endOfDayEndDate, "yyyy-MM-dd'T'HH:mm:ss")
    : '';

  return {
    from: formattedStartDate,
    to: formattedEndDate,
  };
}

function getOrderingQuery({
  customDates = {from: null, to: null},
  dates = DEFAULT_RESERVATIONS_DATE_FILTER,
}: DatesParams) {
  const isNotDefaultDates =
    customDates.from ||
    customDates.to ||
    dates.value !== DEFAULT_RESERVATIONS_DATE_FILTER.value;

  if (isNotDefaultDates) {
    return REVERSE_ORDERING_PARAM;
  }
  return ORDERING_PARAM;
}

function getNameQuery(name = '', exactName = '') {
  if (exactName) {
    return `exact_name=${encodeURIComponent(exactName)}`;
  }

  return `search=${encodeURIComponent(name)}`;
}

function getReservationsParams({
  page = 1,
  name = '',
  exactName = '',
  guestName = '',
  status = DEFAULT_RESERVATIONS_STATUS_FILTER,
  dates = DEFAULT_RESERVATIONS_DATE_FILTER,
  customDates = {from: null, to: null},
}: Params) {
  const {from, to} = getDatesQueries({customDates, dates});
  const orderingQuery = getOrderingQuery({customDates, dates});
  const statusQuery = getStatusQuery(status);
  const nameQuery = getNameQuery(name, exactName);

  return `ordering=${orderingQuery}&page=${page}&${statusQuery}&${nameQuery}&guest_name=${guestName}&check_in_date_from=${from}&check_in_date_until=${to}`;
}

function fetchReservations(key = '', params = 'ordering=name&page=1') {
  return queryFetcher(api.reservations.ENDPOINTS.light(params));
}

function ReservationsTable() {
  const {t} = useTranslation();
  const ws = useWebsocket();
  const history = useHistory();
  const {accountDetails: user} = useAuth();
  const {SubscriptionAskModal, tryToAskSubscription} = useSubscriptionAskModal();
  const [page, setPage] = React.useState(1);
  const [housingFilter, setHousingFilter] = React.useState(preloadNameFilter);
  const [exactHousingFilter, setExactHousingFilter] = React.useState(
    preloadExactNameFilter,
  );
  const [guestFilter, setGuestFilter] = React.useState(preloadGuestFilter);
  const [customDateFilter, setCustomDateFilter] = React.useState<SearchDates>(
    preloadCustomDateFilter,
  );
  const [statusFilter, setStatusFilter] = React.useState(preloadStatusFilter);
  const [dateFilter, setDateFilter] = React.useState(preloadDateFilter);
  const [isRefreshDisabled, setIsRefreshDisabled] = React.useState(false);
  const [syncTask, setSyncTask] = React.useState<any>();
  const isAnyFilterApplied = Boolean(
    housingFilter ||
      guestFilter ||
      statusFilter?.value !== RESERVATION_STATUSES.all ||
      dateFilter?.value !== DATE_FILTERS_KEYS.upcoming,
  );
  const savedNextPageRef = React.useRef('');

  const housingSearchFilter = exactHousingFilter || housingFilter;
  const reservationsParams = getReservationsParams({
    page,
    status: statusFilter,
    name: housingFilter,
    exactName: exactHousingFilter,
    dates: dateFilter,
    customDates: customDateFilter,
    guestName: guestFilter,
  });
  const {
    isOpen: isSearchHousingsModalOpen,
    openModal: openSearchHousingsModal,
    closeModal: closeSearchHousingsModal,
  } = useModalControls();

  const {
    isOpen: isSearchGuestModalOpen,
    openModal: openSearchGuestModal,
    closeModal: closeSearchGuestModal,
  } = useModalControls();
  const {
    isOpen: isSearchCustomDateModalOpen,
    openModal: openSearchCustomDateModal,
    closeModal: closeSearchCustomDateModal,
  } = useModalControls();
  const {
    isFetching,
    isFetchingMore,
    data,
    fetchMore,
    canFetchMore,
    error,
    status,
  } = useInfiniteQuery(`reservations`, [reservationsParams], fetchReservations as any, {
    getFetchMore: (lastGroup: any = {}) => {
      if (lastGroup.next && lastGroup.next !== savedNextPageRef?.current) {
        savedNextPageRef.current = lastGroup.next;
        return reservationsParams;
      }
      return false;
    },
  });
  useErrorToast(error, {
    notFoundMessage: t('errors.requested_bookings_not_found'),
  });

  const infiniteRef = useInfiniteScroll<HTMLTableElement>({
    loading: isFetching || isFetchingMore,
    hasNextPage: Boolean(canFetchMore),
    onLoadMore: () => {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMore(reservationsParams);
    },
  });

  React.useEffect(() => {
    if (user && !user.has_seen_properties_page) {
      history.push('/properties');
    }
  }, [user, history]);

  const getSyncReservationsTasks = React.useCallback(async () => {
    if (user?.show_buttons_refresh_reservations) {
      setIsRefreshDisabled(true);
      const integrationUrl = user?.origin.toLocaleLowerCase().replace('_', '-');
      const {data} = await api.reservations.getSyncTasks(integrationUrl, user?.id);
      if (data) {
        setSyncTask((data.length && data[0]) || null);
      }
      setIsRefreshDisabled(false);
    }
  }, [user]);

  React.useEffect(() => {
    getSyncReservationsTasks();
  }, [user, getSyncReservationsTasks]);

  const sendRefreshReservationsTasks = async () => {
    setIsRefreshDisabled(true);
    const integrationUrl = user?.origin.toLocaleLowerCase().replace('_', '-');
    const {data, error} = await api.reservations.sendSyncTask(integrationUrl, user?.id);
    if (error) {
      toastResponseError(error);
    }
    if (data) {
      toast.success(t('sync_task_sent'));
      await getSyncReservationsTasks();
    }
    setIsRefreshDisabled(false);
  };

  React.useEffect(() => {
    async function handleWebsocketEvents() {
      if (WS_EVENT_RESERVATIONS_SYNC_TYPES_ARRAY.includes(ws.message?.event_type)) {
        await getSyncReservationsTasks();
      }

      if (
        ws.message.event_type === WS_EVENT_TYPES.reservationCreated ||
        ws.message.event_type === WS_EVENT_TYPES.reservationRemoved
      ) {
        queryCache.refetchQueries('reservations');
      }
    }
    handleWebsocketEvents();

    return () => {
      ws.clearMessage();
    };
  }, [ws, ws.message, getSyncReservationsTasks]);

  const restartQuery = React.useCallback(() => {
    setPage(1);
    queryCache.removeQueries('reservations');
    queryCache.refetchQueries('reservations');
  }, []);

  React.useEffect(
    function preloadURLFilters() {
      const dateFilter = getSearchParamFromUrl(URL_DATE_FILTER);
      const statusFilter = getSearchParamFromUrl(URL_STATUS_FILTER);

      function preloadURLDateFilter() {
        const customDateFilterOption = DATE_FILTERS[DATE_FILTERS_KEYS.custom];
        setDateFilter(customDateFilterOption);
        sessionStorage.setItem(
          STORAGE_RESERVATIONS_DATE_FILTER,
          String(customDateFilterOption.value),
        );

        const dates = {
          from: moment(dateFilter, 'YYYY-MM-DD').toDate(),
          to: moment(dateFilter, 'YYYY-MM-DD').toDate(),
        };
        setCustomDateFilter(dates);
        sessionStorage.setItem(
          STORAGE_RESERVATIONS_CUSTOM_DATE_FILTER,
          JSON.stringify(dates),
        );
      }

      function preloadURLStatusFilter(status: SelectOption) {
        setStatusFilter(status);
        sessionStorage.setItem(STORAGE_RESERVATIONS_STATUS_FILTER, String(status.value));
      }

      const isDateFilterValid = moment(dateFilter).isValid();
      if (isDateFilterValid) {
        preloadURLDateFilter();
      }

      const status = statusFilter && RESERVATION_STATUSES_FILTERS[statusFilter];
      if (status) {
        preloadURLStatusFilter(status);
      }

      if (status || isDateFilterValid) {
        restartQuery();
      }
    },
    [restartQuery],
  );

  const resetHousingFilter = React.useCallback(() => {
    setHousingFilter('');
    setExactHousingFilter('');
    sessionStorage.removeItem(STORAGE_RESERVATIONS_HOUSING_NAME_FILTER);
    sessionStorage.removeItem(STORAGE_RESERVATIONS_EXACT_HOUSING_NAME_FILTER);
    restartQuery();
  }, [restartQuery]);

  const resetGuestFilter = React.useCallback(() => {
    setGuestFilter('');
    sessionStorage.removeItem(STORAGE_RESERVATIONS_GUEST_FILTER);
    restartQuery();
  }, [restartQuery]);

  const resetCustomDateFilter = React.useCallback(() => {
    setCustomDateFilter({from: null, to: null});
    sessionStorage.removeItem(STORAGE_RESERVATIONS_CUSTOM_DATE_FILTER);

    setDateFilter(DEFAULT_RESERVATIONS_DATE_FILTER);
    sessionStorage.setItem(
      STORAGE_RESERVATIONS_DATE_FILTER,
      String(DEFAULT_RESERVATIONS_DATE_FILTER.value),
    );

    restartQuery();
  }, [restartQuery]);

  const columns = React.useMemo<Column<LightReservation>[]>(
    () => [
      {
        Header: (
          <TableHeader>
            {housingSearchFilter ? (
              <TableFilter onRemove={resetHousingFilter}>
                {housingSearchFilter}
              </TableFilter>
            ) : (
              (t('property_name') as string)
            )}
            <SearchButton onClick={openSearchHousingsModal} />
          </TableHeader>
        ),
        accessor: 'housing_id',
        Cell: ({row}) => {
          const reservation = row.original;
          return <HousingNameCell reservation={reservation} />;
        },
      },
      {
        Header: (
          <TableHeader>
            {guestFilter ? (
              <TableFilter onRemove={resetGuestFilter}>{guestFilter}</TableFilter>
            ) : (
              (t('guest_leader_name') as string)
            )}
            <SearchButton onClick={openSearchGuestModal} />
          </TableHeader>
        ),
        accessor: 'guest_group_id',
        Cell: ({row}) => {
          const reservation = row.original;

          return <GuestNameCell reservation={reservation} />;
        },
      },
      {
        Header: (
          <TableHeader>
            {customDateFilter.to || customDateFilter.from ? (
              <DateTableFilter onRemove={resetCustomDateFilter}>
                {isDate(customDateFilter.from)
                  ? format(customDateFilter.from!, 'd/M/yy')
                  : '*'}
                {` ${t('to')} `}
                {isDate(customDateFilter.to)
                  ? format(customDateFilter.to!, 'd/M/yy')
                  : '*'}
                {` `}
              </DateTableFilter>
            ) : (
              (t('check_in_date') as string)
            )}
            <SearchButton onClick={openSearchCustomDateModal} />
          </TableHeader>
        ),
        accessor: 'check_in_date',
        Cell: ({value}) => {
          return formatDate(value);
        },
      },
      {
        Header: t('number_of_guests') as string,
        accessor: 'created_at',
        Cell: ({row}) => {
          const guestGroupId = row.original.guest_group_id;

          return <GuestNumberCell guestGroupId={guestGroupId} />;
        },
      },
      {
        Header: t('booking_status') as string,
        accessor: 'complete_status',
        Cell: ({value, row}) => {
          return <TableStatusCell status={value} reservation={row.original} />;
        },
      },
    ],
    [
      openSearchGuestModal,
      openSearchHousingsModal,
      openSearchCustomDateModal,
      t,
      housingSearchFilter,
      resetHousingFilter,
      guestFilter,
      resetGuestFilter,
      customDateFilter.to,
      customDateFilter.from,
      resetCustomDateFilter,
    ],
  );
  const tableData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      ?.map((g) => {
        return g?.results || [];
      })
      .flat();
  }, [data]);
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data: tableData,
  });
  const isInitiallyLoading = status === 'loading';
  const isLoaderVisible = isInitiallyLoading || isFetchingMore;

  const handleStatusChange = (status: SelectOption) => {
    setStatusFilter(status);
    sessionStorage.setItem(STORAGE_RESERVATIONS_STATUS_FILTER, String(status.value));
    restartQuery();
  };

  const handleDateFilterChange = (filter: DateFilter) => {
    setCustomDateFilter({from: null, to: null});
    sessionStorage.removeItem(STORAGE_RESERVATIONS_CUSTOM_DATE_FILTER);

    if (filter.value === DATE_FILTERS_KEYS.custom) {
      openSearchCustomDateModal();
      return;
    }

    setDateFilter(filter);
    sessionStorage.setItem(STORAGE_RESERVATIONS_DATE_FILTER, String(filter.value));
    restartQuery();
  };

  const submitSearchDateModalQuery = (dates: SearchDates) => {
    const customDateFilterOption = DATE_FILTERS[DATE_FILTERS_KEYS.custom];
    setDateFilter(customDateFilterOption);
    sessionStorage.setItem(
      STORAGE_RESERVATIONS_DATE_FILTER,
      String(customDateFilterOption.value),
    );

    setCustomDateFilter(dates);
    sessionStorage.setItem(
      STORAGE_RESERVATIONS_CUSTOM_DATE_FILTER,
      JSON.stringify(dates),
    );
    restartQuery();
  };

  const submitSearchHousingsModalQuery = (option: SelectOption) => {
    const filter = String(option.label);
    const isExact = Boolean(option.value);

    if (isExact) {
      setExactHousingFilter(filter);
      setHousingFilter('');
      sessionStorage.setItem(STORAGE_RESERVATIONS_EXACT_HOUSING_NAME_FILTER, filter);
      sessionStorage.removeItem(STORAGE_RESERVATIONS_HOUSING_NAME_FILTER);
    } else {
      setHousingFilter(filter);
      setExactHousingFilter('');
      sessionStorage.setItem(STORAGE_RESERVATIONS_HOUSING_NAME_FILTER, filter);
      sessionStorage.removeItem(STORAGE_RESERVATIONS_EXACT_HOUSING_NAME_FILTER);
    }

    restartQuery();
  };

  const submitSearchGuestModalQuery = (option: SelectOption) => {
    setGuestFilter(String(option.value));
    sessionStorage.setItem(STORAGE_RESERVATIONS_GUEST_FILTER, String(option.value));
    restartQuery();
  };

  const goToReservationsAdd = () => {
    if (tryToAskSubscription()) {
      return;
    }

    history.push('/bookings/add');
  };

  const goToReservationsEdit = (row: Row<LightReservation>) => {
    if (tryToAskSubscription()) {
      return;
    }

    const reservation = row.original;
    const reservationId = reservation?.id;

    if (reservationId) {
      queryCache.setQueryData(reservationId, reservation);
      history.push(`/bookings/${reservationId}`);
    }
  };

  const handleReservationItemClick = (value: string) => {
    if (value === RESERVATIONS_TABLE_MENU_OPTIONS.importBooking.value) {
      if (tryToAskSubscription()) {
        return;
      }

      history.push(RESERVATIONS_TABLE_MENU_OPTIONS.importBooking.baseUrl);
    }
    if (value === RESERVATIONS_TABLE_MENU_OPTIONS.reservationReport.value) {
      history.push(RESERVATIONS_TABLE_MENU_OPTIONS.reservationReport.baseUrl);
    }
    if (value === RESERVATIONS_TABLE_MENU_OPTIONS.guestReport.value) {
      history.push(RESERVATIONS_TABLE_MENU_OPTIONS.guestReport.baseUrl);
    }
  };

  const getMenuItemsConditionally = () => {
    const copyMenuItems = {...RESERVATIONS_TABLE_MENU_OPTIONS};

    if (!user?.show_button_import_reservations) {
      delete copyMenuItems.importBooking;
    }

    return copyMenuItems;
  };

  return (
    <>
      <Content>
        <Heading>
          <HeadingSection>
            <HeadingSelect
              onChange={handleDateFilterChange}
              options={Object.values(DATE_FILTERS)}
              value={dateFilter}
            />
            <HeadingSelect
              onChange={handleStatusChange}
              options={Object.values(RESERVATION_STATUSES_FILTERS)}
              value={statusFilter}
            />
          </HeadingSection>
          <ActionSection>
            <ReservationTableHeaderItem
              onMenuItemClick={handleReservationItemClick}
              menuOptions={getMenuOptions(getMenuItemsConditionally())}
              secondaryIcon={<ThreeDotsImg src={moreIcon} alt="Three dots" />}
              activeSecondaryIcon={
                <ThreeDotsImg src={moreIconActiveBlue} alt="Three dots" />
              }
            />
            {user?.show_buttons_add_edit_delete_reservations && (
              <HeadingButton onClick={goToReservationsAdd}>
                <img src={plusIcon} alt="Plus" />
                {t('new_booking')}
              </HeadingButton>
            )}
            {user?.show_buttons_refresh_reservations && (
              <HeadingRefreshButton
                onClick={sendRefreshReservationsTasks}
                disabled={
                  isRefreshDisabled ||
                  (syncTask &&
                    [SYNC_IN_PROGRESS_STATUS, SYNC_SEN_STATUS].includes(syncTask?.status))
                }
              >
                <img src={refreshIcon} alt="Refresh" />
                {syncTask &&
                  ![SYNC_IN_PROGRESS_STATUS, SYNC_SEN_STATUS].includes(
                    syncTask?.status,
                  ) &&
                  !isRefreshDisabled &&
                  t('refresh')}

                {(isRefreshDisabled ||
                  (syncTask &&
                    [SYNC_IN_PROGRESS_STATUS, SYNC_SEN_STATUS].includes(
                      syncTask?.status,
                    ))) && (
                  <RefreshButtonLoaderWrapper>
                    <Loader height={20} />
                  </RefreshButtonLoaderWrapper>
                )}
              </HeadingRefreshButton>
            )}
          </ActionSection>
        </Heading>
        <TableWrapper hasLastRowBorder={tableData?.length < EMPTY_TABLE_ROWS_NUMBER}>
          <table ref={infiniteRef} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} onClick={() => goToReservationsEdit(row)}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoaderVisible && (
            <TableLoader
              hideBorder
              label={data?.length ? t('loading_more') : t('loading')}
            />
          )}
          <TablePlaceholderWrapper>
            <TablePlaceholder
              hidden={isAnyFilterApplied}
              isLoaderVisible={isLoaderVisible}
              isInitiallyLoading={isInitiallyLoading}
              modalIconSrc={addBookingIcon}
              modalIconProps={{height: 58, width: 56}}
              tableDataLength={tableData?.length}
              modalIconAlt="Guest group"
              modalText={t('second_thing_add_booking')}
            >
              <ModalButton label={t('add_a_new_booking')} onClick={goToReservationsAdd} />
            </TablePlaceholder>
          </TablePlaceholderWrapper>
          <TablePlaceholderWrapper>
            <TablePlaceholder
              hidden={!isAnyFilterApplied}
              isInitiallyLoading={isInitiallyLoading}
              isLoaderVisible={isLoaderVisible}
              modalIconSrc={notFoundIcon}
              modalIconProps={{height: 31, width: 31}}
              modalIconAlt="Sad face"
              modalText={t('no_bookings_match_the_filters')}
              modalTitle={`${t('not_found').toUpperCase()}...`}
              tableDataLength={tableData?.length}
            />
          </TablePlaceholderWrapper>
        </TableWrapper>
      </Content>
      <SearchHousingsModal
        open={isSearchHousingsModalOpen}
        onClose={closeSearchHousingsModal}
        onSubmit={submitSearchHousingsModalQuery}
      />
      <SearchGuestLeaderModal
        open={isSearchGuestModalOpen}
        onClose={closeSearchGuestModal}
        onSubmit={submitSearchGuestModalQuery}
      />
      <SearchDateModal
        open={isSearchCustomDateModalOpen}
        onClose={closeSearchCustomDateModal}
        onSubmit={submitSearchDateModalQuery}
        defaultStartDate={customDateFilter.from}
        defaultEndDate={customDateFilter.to}
      />
      <SubscriptionAskModal />
      <ImportCompleteModal />
    </>
  );
}

function fetchHousing(key: string, id = '') {
  return queryFetcher(api.housings.ENDPOINTS.one(id));
}

function fetchGuestGroup(key: string, id: string) {
  return queryFetcher(api.guestGroups.ENDPOINTS.all(id));
}

type StatusCellProps = {
  status: string;
  reservation: LightReservation;
};

function TableStatusCell({status, reservation}: StatusCellProps) {
  const {t} = useTranslation();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const displayStatus =
    RESERVATION_STATUSES.complete === status
      ? RESERVATION_STATUSES.complete
      : RESERVATION_STATUSES.incomplete;
  const statusText =
    displayStatus === RESERVATION_STATUSES.complete ? t('complete') : t('incomplete');

  const housingId = reservation?.housing_id;
  const {data: housing, status: housingStatus, error: housingError} = useQuery<
    Housing,
    [string, string]
  >(Boolean(housingId) && ['housing', housingId], fetchHousing, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(housingError, {
    notFoundMessage: t('errors.requested_housings_not_found'),
  });

  const guestGroupId = reservation?.guest_group_id;
  const {data: guestGroup, error: guestGroupError} = useQuery<
    GuestGroup,
    [string, string]
  >(Boolean(guestGroupId) && ['guestGroup', guestGroupId!], fetchGuestGroup, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(guestGroupError, {
    notFoundMessage: t('errors.requested_guest_groups_not_found'),
  });

  const statusDescriptions = getStatusesDescriptions({
    ...reservation,
    housing,
    guest_group: guestGroup,
  } as Reservation);

  const getStatusDisplay = (status: string | string[] | JSX.Element | null) => {
    if (!Array.isArray(status)) {
      return status;
    }

    return status.map((text, i) => {
      return <div key={i}>{text}</div>;
    });
  };

  return (
    <StatusCell>
      <div
        onMouseOver={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <StatusButton onClick={(e) => e.stopPropagation()} status={displayStatus}>
          {statusText?.toLowerCase()}
          {RESERVATION_STATUSES_ICONS[displayStatus]}
        </StatusButton>
        <StatusTooltip
          onClick={(e) => e.stopPropagation()}
          onMouseOver={(e) => e.stopPropagation()}
          open={isTooltipOpen}
        >
          {housingStatus === 'loading' ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <div>
              <StatusText>
                <b>{t('online_check_in')}</b>
                {statusDescriptions[STATUS_TYPES.checkIn]}
              </StatusText>
              {statusDescriptions[STATUS_TYPES.securityDeposit] && (
                <StatusText>
                  <b>{t('security_deposit')}</b>
                  {statusDescriptions[STATUS_TYPES.securityDeposit]}
                </StatusText>
              )}
              {statusDescriptions[STATUS_TYPES.policeCheckIn] && (
                <StatusText>
                  <b>{t('police_in')}</b>
                  {getStatusDisplay(statusDescriptions[STATUS_TYPES.policeCheckIn])}
                </StatusText>
              )}
              {statusDescriptions[STATUS_TYPES.policeCheckOut] && (
                <StatusText>
                  <b>{t('police_out')}</b>
                  {getStatusDisplay(statusDescriptions[STATUS_TYPES.policeCheckOut])}
                </StatusText>
              )}
              {statusDescriptions[STATUS_TYPES.statsCheckIn] && (
                <StatusText>
                  <b>{t('stats_in')}</b>
                  {getStatusDisplay(statusDescriptions[STATUS_TYPES.statsCheckIn])}
                </StatusText>
              )}
              {statusDescriptions[STATUS_TYPES.statsCheckOut] && (
                <StatusText>
                  <b>{t('stats_out')}</b>
                  {getStatusDisplay(statusDescriptions[STATUS_TYPES.statsCheckOut])}
                </StatusText>
              )}
              {statusDescriptions[STATUS_TYPES.selfCheckIn] && (
                <StatusText>
                  <b>{t('self_checkin')}</b>
                  {statusDescriptions[STATUS_TYPES.selfCheckIn]}
                </StatusText>
              )}
              {statusDescriptions[STATUS_TYPES.taxes] && (
                <StatusText>
                  <b>{t('taxes')}</b>
                  {statusDescriptions[STATUS_TYPES.taxes]}
                </StatusText>
              )}
            </div>
          )}
        </StatusTooltip>
      </div>
    </StatusCell>
  );
}

type GuestNumberCellProps = {
  guestGroupId: string;
};

function GuestNumberCell({guestGroupId}: GuestNumberCellProps) {
  const {t} = useTranslation();
  const {data: guestGroup, error: guestGroupError, status: guestGroupStatus} = useQuery<
    GuestGroup,
    [string, string]
  >(Boolean(guestGroupId) && ['guestGroup', guestGroupId!], fetchGuestGroup, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(guestGroupError, {
    notFoundMessage: t('errors.requested_guest_groups_not_found'),
  });

  if (guestGroupStatus === 'loading') {
    return <Loader />;
  }

  const number = getGroupMembersNumber(guestGroup);

  return (
    <div>
      {number}/{guestGroup?.number_of_guests || 0}
    </div>
  );
}

type GuestNameCellProps = {
  reservation: LightReservation;
};

function GuestNameCell({reservation}: GuestNameCellProps) {
  const {t} = useTranslation();
  const guestGroupId = reservation?.guest_group_id;

  const {data: guestGroup, error: guestGroupError, status: guestGroupStatus} = useQuery<
    GuestGroup,
    [string, string]
  >(Boolean(guestGroupId) && ['guestGroup', guestGroupId!], fetchGuestGroup, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(guestGroupError, {
    notFoundMessage: t('errors.requested_guest_groups_not_found'),
  });

  const name = getGuestLeaderName(guestGroup) || reservation.default_leader_full_name;

  if (guestGroupStatus === 'loading') {
    return <Loader />;
  }

  if (name) {
    return <CapitalizeWrapper>{name.toLowerCase()}</CapitalizeWrapper>;
  }

  return (
    <CapitalizeWrapper>
      <MissingDataText>[{t('name_missing')}]</MissingDataText>
    </CapitalizeWrapper>
  );
}

type HousingNameCellProps = {
  reservation: LightReservation;
};

function HousingNameCell({reservation}: HousingNameCellProps) {
  const {t} = useTranslation();
  const housingId = reservation?.housing_id;
  const {data: housing, status: housingStatus, error: housingError} = useQuery<
    Housing,
    [string, string]
  >(Boolean(housingId) && ['housing', housingId], fetchHousing, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(housingError, {
    notFoundMessage: t('errors.requested_housings_not_found'),
  });

  if (housingStatus === 'loading') {
    return <Loader />;
  }

  return <div>{getName(housing)}</div>;
}

export {ReservationsTable};
