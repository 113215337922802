import styled from 'styled-components';
import {PAYMENTS_SETTINGS_STATUSES} from '../../../utils/types';

export const BalanceGrid = styled.div`
  display: grid;
  grid-template:
    'balance-label balance-label' 20px
    'balance balance-button' 53px / 195px auto;
  grid-column-gap: 40px;
  align-items: center;
  margin: 30px 0;
`;

export const BalanceLabel = styled.div`
  grid-area: balance-label;
  font-family: ProximaNova-Semibold, sans-serif;
  color: #161643;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const Balance = styled.div`
  grid-area: balance;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 43px;
  color: #161643;
`;

export const BalanceCurrency = styled.span`
  font-family: ProximaNova-Semibold, sans-serif;
  color: #161643;
  font-size: 16px;
  vertical-align: middle;
`;

export const BalanceButton = styled.div`
  grid-area: balance-button;
`;

export const BalanceIcon = styled.img`
  width: 29px;
  height: 19px;
`;

export const LoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 5px;
`;

export const StatusWrapper = styled.div`
  margin: 20px 0 30px;
  font-family: ProximaNova-Regular, sans-serif;
  color: #161643;
  font-size: 16px;
`;

type StatusDetailsProps = {
  status: PAYMENTS_SETTINGS_STATUSES;
};

export const StatusDetails = styled.span<StatusDetailsProps>`
  color: ${(props) =>
    props.status === PAYMENTS_SETTINGS_STATUSES.invalid ? '#FF2467' : '#9696B9'};
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
`;

export const ContactSupportButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #1a8cff;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  padding: 0;
  margin: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
`;

export const TryAgainButtonWrapper = styled.span`
  margin-right: 20px;

  & > button {
    display: inline-flex;
    justify-content: center;
    min-width: 114px;
  }
`;

export const PaymentsStatusDetails = styled.span`
  margin-right: 40px;
`;
