import React from 'react';
import {useTranslation} from 'react-i18next';
import blueCloseIcon from '../../../assets/close-blue.svg';
import closeIcon from '../../../assets/close.svg';
import {
  Container,
  Text,
  Exported,
  NumbersWrapper,
  Total,
  ExportText,
  Button,
  ButtonsWrapper,
  CloseIcon,
  Divider,
  ErrorText,
  SuccessText,
} from './styled';

type ContractsExportPopupProps = {
  onStop: () => void;
  onClose: () => void;
  open?: boolean;
  total?: number;
  exported?: number;
  isError?: boolean;
  isSuccess?: boolean;
};

const defaultProps: Partial<ContractsExportPopupProps> = {
  open: false,
  isError: false,
  isSuccess: false,
  total: 0,
  exported: 0,
};

function ContractsExportPopup({
  open,
  exported,
  total,
  onStop,
  isError,
  isSuccess,
  onClose,
}: ContractsExportPopupProps) {
  const {t} = useTranslation();
  const [isStopApproval, setIsStopApproval] = React.useState(false);
  const [isCloseButtonHovered, setIsCloseButtonHovered] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setIsCloseButtonHovered(false);
    }
  }, [open]);

  const handleStop = () => {
    onStop();
    setIsStopApproval(false);
  };

  if (!open) {
    return null;
  }

  if (isError) {
    return (
      <Container onClick={onClose}>
        <CloseIcon
          alt="Cross"
          onMouseOver={() => setIsCloseButtonHovered(true)}
          onMouseOut={() => setIsCloseButtonHovered(false)}
          src={isCloseButtonHovered ? blueCloseIcon : closeIcon}
          onClick={onClose}
        />
        <ErrorText>{t('contracts_settings_export_error_try_again')}</ErrorText>
      </Container>
    );
  }

  if (isSuccess) {
    return (
      <Container onClick={onClose}>
        <CloseIcon
          alt="Cross"
          onMouseOver={() => setIsCloseButtonHovered(true)}
          onMouseOut={() => setIsCloseButtonHovered(false)}
          src={isCloseButtonHovered ? blueCloseIcon : closeIcon}
          onClick={onClose}
        />
        <SuccessText>{t('contracts_settings_successfully_exported')}</SuccessText>
      </Container>
    );
  }

  return (
    <Container>
      {isStopApproval ? (
        <div>
          <Text>{t('stop_exporting_question')}</Text>
          <ButtonsWrapper>
            <Button secondary onClick={handleStop} label={t('yes').toUpperCase()} />
            <Divider>/</Divider>
            <Button
              secondary
              onClick={() => setIsStopApproval(false)}
              label={t('no').toUpperCase()}
            />
          </ButtonsWrapper>
        </div>
      ) : (
        <div>
          <CloseIcon
            alt="Cross"
            onMouseOver={() => setIsCloseButtonHovered(true)}
            onMouseOut={() => setIsCloseButtonHovered(false)}
            src={isCloseButtonHovered ? blueCloseIcon : closeIcon}
            onClick={() => {
              setIsCloseButtonHovered(false);
              setIsStopApproval(true);
            }}
          />
          <Text>{t('exporting_contract_settings')}:</Text>
          <NumbersWrapper>
            <Exported>{exported}</Exported>
            <Total>
              /{total}
              <ExportText> {t('exported').toLowerCase()}</ExportText>
            </Total>
          </NumbersWrapper>
        </div>
      )}
    </Container>
  );
}

ContractsExportPopup.defaultProps = defaultProps;
export {ContractsExportPopup};
