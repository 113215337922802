import React from 'react';
import {default as Content} from '../components/onboarding/PmsResharmonicsOriginRegister';

function PmsResharmonicsOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsResharmonicsOriginRegister};
