import {resolver} from './index';

const ENDPOINTS = {
  all: (id = '') => `payments-settings/${id ? `${id}/` : ''}`,
  movementsPreview: () => `payments-settings/movements-preview/`,
  fullMovements: () => `payments-settings/movements-full/`,
};

function get() {
  return resolver(ENDPOINTS.all());
}

function patch(id: string, payload: any) {
  return resolver(ENDPOINTS.all(id), {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

function post(payload: any) {
  return resolver(ENDPOINTS.all(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export {ENDPOINTS, patch, get, post};
