import React from 'react';
import {useTable, Column, Row} from 'react-table';
import {useInfiniteQuery, useQuery, queryCache} from 'react-query';
import {useTranslation} from 'react-i18next';
import {useInfiniteScroll} from 'react-infinite-scroll-hook';
import {format, isValid} from 'date-fns';
import api, {queryFetcher} from '../../../api';
import {
  useErrorToast,
  useCorrectOptionSelection,
  useIsMounted,
  useErrorModal,
  useStatus,
} from '../../../utils/hooks';
import {SelectOption, PoliceAccount, PoliceReceipt} from '../../../utils/types';
import directDownloadIcon from '../../../assets/direct-download.svg';
import notFoundIcon from '../../../assets/notfound-icon.svg';
import TablePlaceholder, {EMPTY_TABLE_ROWS_NUMBER} from '../TablePlaceholder';
import TableLoader from '../TableLoader';
import HeadingSelect from '../HeadingSelect';
import Loader from '../../common/Loader';
import {TableHeader, TablePlaceholderWrapper} from '../../../styled/common';
import {DocumentButton} from '../../../styled/common';
import {
  TableWrapper,
  Content,
  Heading,
  DownloadAllButton,
  HeadingSection,
} from './styled';

const STORAGE_POLICE_RECEIPTS_HOUSING_FILTER = 'policeReceiptsHousingFilter';
const STORAGE_POLICE_RECEIPTS_AVAILABLE_YEAR_FILTER = 'policeReceiptsAvailableYearFilter';
const DEFAULT_AVAILABLE_YEAR_OPTION = {
  value: String(new Date().getFullYear()),
  label: String(new Date().getFullYear()),
};

const COLUMNS_IDS = {
  registrationDate: 'REGISTRATION_DATE',
  documents: 'DOCUMENTS',
};

const HOUSING_PLACEHOLDER = {
  value: '',
  label: '...',
};

function fetchPoliceAccounts() {
  return queryFetcher(api.policeAccount.ENDPOINTS.all());
}

function fetchPoliceReceiptsYears(key: string, policeAccountId: string) {
  return queryFetcher(
    api.italianPoliceReceipts.ENDPOINTS.availableYears(policeAccountId),
  );
}

function fetchPoliceReceipts(key: string, params = '') {
  return queryFetcher(api.italianPoliceReceipts.ENDPOINTS.all(params));
}

function getPoliceAccountsAsOptions(policeAccounts: PoliceAccount[]) {
  if (!policeAccounts?.length) {
    return [];
  }

  return policeAccounts.map((p) => {
    return {
      value: p.id,
      label: p.username,
    };
  });
}

function getAvailableYearsAsOptions(years?: number[]) {
  if (!years || !years.length) {
    return [];
  }
  return years.map((y) => {
    return {
      value: String(y),
      label: String(y),
    };
  });
}

function preloadHousingFilter() {
  const prevFilter = sessionStorage.getItem(STORAGE_POLICE_RECEIPTS_HOUSING_FILTER);

  if (prevFilter) {
    return JSON.parse(prevFilter);
  }
  return HOUSING_PLACEHOLDER;
}

function preloadAvailableYearFilter() {
  const prevFilter = sessionStorage.getItem(
    STORAGE_POLICE_RECEIPTS_AVAILABLE_YEAR_FILTER,
  );

  if (prevFilter) {
    return JSON.parse(prevFilter);
  }
  return DEFAULT_AVAILABLE_YEAR_OPTION;
}

type Params = {
  page: number;
  policeAccountId?: string;
  year?: string;
};

function getParams({page, policeAccountId = '', year = ''}: Params) {
  return `ordering=-protocol_date&page=${page}&police_account=${policeAccountId}&year=${year}`;
}

function PoliceReceiptsTable() {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const [page, setPage] = React.useState(1);
  const {ErrorModal, displayError} = useErrorModal();
  const [policeAccountFilter, setPoliceAccountFilter] = React.useState<SelectOption>(
    preloadHousingFilter,
  );
  const [availableYearFilter, setAvailableYearFilter] = React.useState<SelectOption>(
    preloadAvailableYearFilter,
  );

  const {
    setStatus: setDownloadAllStatus,
    isLoading: isGeneratingDownloadLink,
  } = useStatus();

  const {
    data: policeAccounts,
    error: policeAccountsError,
    status: policeAccountsStatus,
  } = useQuery('policeAccounts', fetchPoliceAccounts);
  const {
    data: availableYears,
    error: availableYearsError,
    status: availableYearsStatus,
  } = useQuery(
    Boolean(policeAccountFilter?.value) && [
      'availablePoliceReceiptsYears',
      policeAccountFilter.value,
    ],
    fetchPoliceReceiptsYears,
  );
  useErrorToast(policeAccountsError, {
    notFoundMessage: t('errors.requested_police_accounts_not_found'),
  });
  useErrorToast(availableYearsError, {
    notFoundMessage: t('errors.available_years_not_found'),
  });

  const availableYearsOptions = React.useMemo(() => {
    return getAvailableYearsAsOptions(availableYears);
  }, [availableYears]);
  const policeAccountsOptions = React.useMemo(() => {
    return getPoliceAccountsAsOptions(policeAccounts);
  }, [policeAccounts]);

  const params = getParams({
    page,
    policeAccountId: policeAccountFilter?.value,
    year: availableYearFilter?.value,
  });

  const {
    isFetching,
    isFetchingMore,
    data,
    fetchMore,
    canFetchMore,
    error,
    status,
  } = useInfiniteQuery(
    Boolean(policeAccountFilter?.value) && 'policeReceipts',
    [params],
    fetchPoliceReceipts as any,
    {
      getFetchMore: (lastGroup: any = {}) => {
        if (lastGroup.next) {
          return params;
        }
        return false;
      },
    },
  );
  useErrorToast(error, {
    notFoundMessage: t('errors.requested_police_receipts_not_found'),
  });

  const infiniteRef = useInfiniteScroll<HTMLTableElement>({
    loading: isFetching || isFetchingMore,
    hasNextPage: Boolean(canFetchMore),
    onLoadMore: () => {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMore(params);
    },
  });

  const tableData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .map((g) => {
        return g?.results || [];
      })
      .flat();
  }, [data]);

  const restartQuery = React.useCallback(() => {
    setPage(1);
    queryCache.removeQueries('policeReceipts');
    queryCache.refetchQueries('policeReceipts');
  }, []);

  const handleAvailableYearSelect = React.useCallback(
    (option: SelectOption) => {
      setAvailableYearFilter(option);
      sessionStorage.setItem(
        STORAGE_POLICE_RECEIPTS_AVAILABLE_YEAR_FILTER,
        JSON.stringify(option),
      );
      restartQuery();
    },
    [restartQuery],
  );
  useCorrectOptionSelection({
    array: availableYearsOptions,
    option: availableYearFilter,
    handler: handleAvailableYearSelect,
    defaultOption: DEFAULT_AVAILABLE_YEAR_OPTION,
  });

  const handleHousingSelect = React.useCallback(
    (option: SelectOption) => {
      setPoliceAccountFilter(option);
      sessionStorage.setItem(
        STORAGE_POLICE_RECEIPTS_HOUSING_FILTER,
        JSON.stringify(option),
      );
      restartQuery();
    },
    [restartQuery],
  );
  useCorrectOptionSelection({
    array: policeAccountsOptions,
    option: policeAccountFilter,
    handler: handleHousingSelect,
    defaultOption: HOUSING_PLACEHOLDER,
  });

  const downloadAllDocuments = async () => {
    setDownloadAllStatus('loading');

    const {data, error} = await api.italianPoliceReceipts.getDownloadAllLink({
      policeAccountId: policeAccountFilter.value,
      year: availableYearFilter.value,
    });

    if (!isMounted.current) {
      return;
    }

    if (error) {
      displayError(error);
    }

    if (data?.link) {
      window.open(data.link, '_blank');
    } else {
      displayError({message: 'Download link is missing.'});
    }
    setDownloadAllStatus('idle');
  };

  const downloadOneDocument = React.useCallback(
    async (id: string) => {
      const {data, error} = await api.italianPoliceReceipts.getDownloadOneLink(id);

      if (!isMounted.current) {
        return;
      }

      if (error) {
        displayError(error);
      }

      if (data?.link) {
        window.open(data.link, '_blank');
      } else {
        displayError({message: 'Download link is missing.'});
      }
    },
    [displayError, isMounted],
  );

  const columns = React.useMemo<Column<PoliceReceipt>[]>(() => {
    return [
      {
        id: COLUMNS_IDS.registrationDate,
        Header: <TableHeader>{t('check_in_date')}</TableHeader>,
        Cell: ({row}: {row: Row<PoliceReceipt>}) => {
          const checkInDate = row.original.protocol_date;

          if (!checkInDate || !isValid(new Date(checkInDate))) {
            return null;
          }
          return checkInDate && format(new Date(checkInDate), 'dd MMM yyyy');
        },
      },
      {
        id: COLUMNS_IDS.documents,
        Header: t('documents_text') as string,
        Cell: ({row}: {row: Row<PoliceReceipt>}) => {
          const [isLoading, setIsLoading] = React.useState(false);
          const id = row.original.id;

          const downloadReceipt = async () => {
            setIsLoading(true);
            await downloadOneDocument(id);
            setIsLoading(false);
          };

          return (
            <DocumentButton
              blinkingBackwards={isLoading}
              disabled={isLoading}
              onClick={downloadReceipt}
            >
              {isLoading ? <Loader height={10} width={10} color="#1a8cff" /> : `ZIP`}
            </DocumentButton>
          );
        },
      },
    ];
  }, [t, downloadOneDocument]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data: tableData,
  });
  const isInitiallyLoading = status === 'loading';
  const isLoaderVisible =
    isInitiallyLoading ||
    isFetchingMore ||
    availableYearsStatus === 'loading' ||
    policeAccountsStatus === 'loading';

  return (
    <Content>
      <Heading>
        <HeadingSection>
          <HeadingSelect
            isSearchable
            onChange={handleHousingSelect}
            options={policeAccountsOptions}
            value={policeAccountFilter}
          />
          <HeadingSelect
            onChange={handleAvailableYearSelect}
            options={availableYearsOptions}
            value={availableYearFilter}
          />
        </HeadingSection>
        {Boolean(tableData?.length) && (
          <DownloadAllButton
            secondary
            onClick={downloadAllDocuments}
            disabled={isGeneratingDownloadLink}
            blinking={isGeneratingDownloadLink}
            label={
              <>
                <img src={directDownloadIcon} alt="Arrow with a line" />
                {isGeneratingDownloadLink ? `${t('generating')}...` : t('download_all')}
              </>
            }
          />
        )}
      </Heading>
      <TableWrapper hasLastRowBorder={tableData?.length < EMPTY_TABLE_ROWS_NUMBER}>
        <table ref={infiniteRef} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoaderVisible && (
          <TableLoader
            hideBorder
            label={data?.length ? t('loading_more') : t('loading')}
          />
        )}
        <TablePlaceholderWrapper>
          <TablePlaceholder
            isInitiallyLoading={isInitiallyLoading}
            isLoaderVisible={isLoaderVisible}
            modalIconSrc={notFoundIcon}
            modalIconAlt="Sad face"
            modalIconProps={{height: 31, width: 31}}
            modalTitle={`${t('not_found').toUpperCase()}...`}
            tableDataLength={tableData?.length}
          />
        </TablePlaceholderWrapper>
      </TableWrapper>
      <ErrorModal />
    </Content>
  );
}

export {PoliceReceiptsTable};
