import React from 'react';
import i18n from '../../../i18n';
import CurrentPlanSection from '../CurrentPlanSection';
import PreviousInvoicesTable from '../PreviousInvoicesTable';
import BillingDetailsSection from '../BillingDetailsSection';
import ElementsInjectedPaymentCardSection from '../PaymentCardSection';
import {Content, Header, ContentItem} from './styled';

function BillingSection() {
  return (
    <Content>
      <Header>{i18n.t('billing')}</Header>
      <ContentItem>
        <CurrentPlanSection />
      </ContentItem>
      <ContentItem>
        <ElementsInjectedPaymentCardSection />
      </ContentItem>
      <ContentItem>
        <BillingDetailsSection />
      </ContentItem>
      <ContentItem>
        <PreviousInvoicesTable />
      </ContentItem>
    </Content>
  );
}

export {BillingSection};
