import React from 'react';
import {default as Content} from '../components/onboarding/PmsBookingsyncOriginRegister';

function PmsBookingsyncOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsBookingsyncOriginRegister};
