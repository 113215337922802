import React from 'react';
import api from '../api';
import ContractsExportPopup from '../components/dashboard/ContractsExportPopup';
import {useStatus} from '../utils/hooks';
import {queryCache} from 'react-query';

type ContextProps = {
  startContractSettingsExport: (housingIds: string[], payload: any) => Promise<void>;
  isExporting: boolean;
};

const ContractsExportContext = React.createContext<ContextProps>({
  startContractSettingsExport: async () => {},
  isExporting: false,
});

function ContractsExportProvider(props: any) {
  const [isExportPopupVisible, setIsExportPopupVisible] = React.useState(false);
  const [totalNumber, setTotalNumber] = React.useState(0);
  const [exportedNumber, setExportedNumber] = React.useState(0);
  const {isLoading, isError, isSuccess, isIdle, setStatus} = useStatus();
  const exporting = React.useRef(false);

  const updateHousing = React.useCallback(
    async (id: string, payload: any) => {
      const result = await api.housings.patchById(id, payload);

      if (result.error) {
        setStatus('error');
      }
      if (result.data) {
        await queryCache.refetchQueries('housings');
      }
      return result.error;
    },
    [setStatus],
  );

  const stopContractSettingsExporting = React.useCallback(() => {
    exporting.current = false;
    setIsExportPopupVisible(false);
    setStatus('idle');
    setExportedNumber(0);
  }, [setStatus]);

  const startContractSettingsExport = React.useCallback(
    async (housingIds: string[], payload: any) => {
      let error: any = null;

      setTotalNumber(housingIds.length);
      setExportedNumber(0);
      setIsExportPopupVisible(true);

      setStatus('loading');

      exporting.current = true;
      for await (const id of housingIds) {
        if (!exporting.current) {
          break;
        }

        if (error) {
          return;
        }

        error = await updateHousing(id, payload);
        if (!error) {
          setExportedNumber((prevState) => {
            return prevState + 1;
          });
        }
      }

      if (exporting.current) {
        if (error) {
          setStatus('error');
        } else {
          setStatus('success');
        }
      }

      setExportedNumber(0);
      setTotalNumber(0);
    },
    [setStatus, updateHousing],
  );

  const handlePopupClose = React.useCallback(() => {
    setIsExportPopupVisible(false);
    setStatus('idle');
  }, [setStatus]);

  return (
    <div>
      <ContractsExportPopup
        onClose={handlePopupClose}
        open={isExportPopupVisible || !isIdle}
        total={totalNumber}
        isSuccess={isSuccess}
        isError={isError}
        exported={exportedNumber}
        onStop={stopContractSettingsExporting}
      />
      <ContractsExportContext.Provider
        value={{startContractSettingsExport, isExporting: isLoading}}
        {...props}
      />
    </div>
  );
}

function useContractsExport() {
  const context = React.useContext(ContractsExportContext);
  if (context === undefined) {
    throw new Error('useContractsExport must be used within a ContractsExportProvider');
  }
  return context;
}

export {ContractsExportProvider, useContractsExport, ContractsExportContext};
