import styled, {css} from 'styled-components';

type WrapperProps = {
  menuOpen: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 162px;
  min-height: 42px;
  border: 1px solid ${(props) => (props.menuOpen ? '#1A8CFF' : '#9696B9')};
  border-radius: 3px;
  transition: all 0.15s ease-in-out;

  &:hover {
    box-shadow: 0 4px 4px #2699fb1a;
  }

  & .select {
    height: 42px !important;
  }

  & .select__control {
    border: none !important;
    cursor: pointer !important;
    background-color: white !important;
    box-shadow: none !important;
    height: 100% !important;
    padding-left: 16px;
    transition: background-color 0.5s ease-in-out, border-color 0.05s ease-in-out !important;
  }

  & .select__single-value,
  & .select__placeholder {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 15px !important;
    margin-left: 0 !important;
    color: #161643 !important;
    padding-left: 0 !important;
  }

  & .select__input,
  & .select__input input {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 15px !important;
    color: #161643 !important;
    margin: 0 !important;
  }

  & .select__input input {
    width: 300px !important;
  }

  & .select__input {
    position: relative !important;
    top: 0 !important;
  }

  & .select__indicator-separator {
    display: none;
  }

  & .select__dropdown-indicator {
    display: none !important;
  }

  & .select__value-container {
    padding-left: 0 !important;
  }

  & .select__value-container > div {
    margin: 0 !important;
    padding: 0 13px 0 0 !important;
  }

  & .select__value-container :last-child,
  & .select__input {
    margin-left: 0 !important;
    padding: 0;
  }

  & .select__option.select__option--is-selected {
    background-color: white !important;
    font-family: ProximaNova-Bold, sans-serif !important;
  }

  & .select__option.select__option--is-focused.select__option--is-selected {
    cursor: default !important;
    font-family: ProximaNova-Bold, sans-serif !important;
    color: #161643 !important;
  }

  & .select__option.select__option--is-focused {
    cursor: pointer !important;
    color: #1a8cff !important;
  }

  & .select__menu {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 15px !important;
    width: 200px !important;
    height: auto !important;
    min-height: 75px !important;
    max-height: 308px !important;
    background-color: #ffffff;
    box-shadow: none !important;
    z-index: 2 !important;
    margin-top: 1px;
    left: -1px !important;
  }

  & .select__menu-notice {
    color: #161643 !important;
    text-align: left !important;
    margin-top: 20px !important;
    font-size: 20px !important;
  }

  & .select__menu-list {
    padding: 0 34px 0 25px !important;
    box-shadow: 0 30px 30px #2699fb1a !important;
    width: 200px !important;
    height: auto !important;
    min-height: 75px !important;
    max-height: 308px !important;
    border-radius: 8px !important;
  }

  & .select__option {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 16px !important;
    text-align: left !important;
    color: #161643 !important;
    padding: 20px 0 !important;
    box-sizing: border-box !important;
    background-color: white !important;
    border-bottom: 1px solid #e2e7ef !important;
  }

  & .select__option:first-child {
    padding-top: 35px !important;
  }

  & .select__option:last-child {
    border-bottom-color: transparent !important;
    padding-bottom: 37px !important;
  }
`;

type DisplayIconProps = {
  shouldRotate: boolean;
};
export const DisplayIcon = styled.img<DisplayIconProps>`
  width: 12px;
  height: 7px;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 9px;
  margin: auto 0;

  ${(props) =>
    props.shouldRotate &&
    css`
      transform: rotateX(180deg);
    `};
`;
