import React from 'react';
import Tooltip from '../Tooltip';
import {Wrapper, Title, SubtitleWrapper, Subtitle, TooltipWrapper} from './styled';

type HousingSectionProps = {
  children: React.ReactNode | JSX.Element | string;
  title: React.ReactNode | JSX.Element | string;
  subtitle?: React.ReactNode | JSX.Element | string;
  showTooltip?: boolean;
  tooltipContent?: string | React.ReactNode | JSX.Element;
  className?: string;
};

const defaultProps: HousingSectionProps = {
  children: null,
  title: '',
  subtitle: '',
  showTooltip: false,
  tooltipContent: '',
  className: '',
};

function Section({
  children,
  title,
  subtitle,
  showTooltip,
  tooltipContent,
  className,
}: HousingSectionProps) {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      {subtitle && (
        <SubtitleWrapper>
          <Subtitle>{subtitle}</Subtitle>
          {showTooltip && (
            <TooltipWrapper>
              <Tooltip content={tooltipContent} />
            </TooltipWrapper>
          )}
        </SubtitleWrapper>
      )}
      {children}
    </Wrapper>
  );
}

Section.defaultProps = defaultProps;
export {Section};
