import React from 'react';
import {useTranslation} from 'react-i18next';
import {SelectOption} from '../../../utils/types';
import {toastResponseError} from '../../../utils/common';
import searchHousingsIcon from '../../../assets/search-house.svg';
import api from '../../../api';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import {ButtonsWrapper, StyledAsyncSelect} from './styled';

function getSearchParams(query = '') {
  return `ordering=name&name=${query}&fields=id,name`;
}

type SearchHousingsModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: SelectOption) => void;
  loading?: boolean;
  hasOptions?: boolean;
  loadOptions?: (value: string) => Promise<SelectOption[] | undefined>;
  strictOptions?: boolean;
  submitButtonText?: string;
};

const defaultProps: SearchHousingsModalProps = {
  open: false,
  strictOptions: false,
  loadOptions: undefined,
  loading: false,
  hasOptions: false,
  onClose: () => {},
  onSubmit: () => {},
  submitButtonText: '',
};

function SearchHousingsModal({
  open,
  onClose,
  onSubmit,
  strictOptions,
  loadOptions,
  loading,
  hasOptions,
  submitButtonText,
}: SearchHousingsModalProps) {
  const {t} = useTranslation();
  const [query, setQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasResults, setHasResults] = React.useState(false);
  const [option, setOption] = React.useState<SelectOption | null>(null);

  const loadHousingsOptions = async (value: string) => {
    setIsLoading(true);
    const {data, error} = await api.housings.get(getSearchParams(value));
    setIsLoading(false);

    if (data) {
      if (!data.length) {
        setHasResults(false);
      } else {
        setHasResults(true);
      }

      return data.map((h: any) => {
        return {
          label: h.name,
          value: h.id,
        };
      });
    }

    if (error) {
      toastResponseError(error);
    }
  };

  const handleInputChange = (value = '') => {
    setQuery(value);
  };

  const handleClose = () => {
    setOption(null);
    onClose();
  };

  const handleSubmit = () => {
    if (option) {
      onSubmit(option);
      handleClose();
    }
  };

  const handleKeyDown = (event: any) => {
    if (strictOptions) {
      return;
    }

    if (event.key === 'Enter') {
      onSubmit({
        value: '',
        label: query,
      });
      handleClose();
    }
  };

  return (
    <Modal
      closeOnEscape
      closeOnDocumentClick
      open={open}
      onClose={onClose}
      title={t('search_by_property')}
      iconSrc={searchHousingsIcon}
      iconAlt="House magnifier"
      iconProps={{
        height: 84,
        width: 84,
      }}
    >
      <StyledAsyncSelect
        strictOptions={strictOptions}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        loadOptions={loadOptions || loadHousingsOptions}
        placeholder={t('enter_property_name')}
        loading={loading || isLoading}
        hasResults={hasOptions || hasResults}
        value={option}
        onChange={(option: SelectOption) => setOption(option)}
      />
      <ButtonsWrapper>
        <ModalButton
          disabled={!option}
          onClick={handleSubmit}
          label={submitButtonText || t('search')}
        />
        <ModalButton secondary onClick={handleClose} label={t('close')} />
      </ButtonsWrapper>
    </Modal>
  );
}

SearchHousingsModal.defaultProps = defaultProps;
export {SearchHousingsModal};
