import styled, {css} from 'styled-components';

const disabledOpacity = 0.4;

type CheckboxWrapperProps = {
  disabled?: boolean;
};

export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  font-family: ProximaNova-Light, sans-serif;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  min-width: 225px;
  display: flex;
  color: #000000;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: ${disabledOpacity};
      cursor: not-allowed;
    `};
`;

export const AcceptIconImg = styled.img`
  user-select: none;
  height: 13px;
  margin-top: 3px;
`;

type BoxProps = {
  disabled?: boolean;
  checked?: boolean;
};

export const Box = styled.div<BoxProps>`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-right: 19px;
  border: 1px solid #1a8cff;
  background-color: white;
  text-align: center;
  transition: all 0.1s ease-in-out;
  opacity: ${(props) => props.disabled && disabledOpacity};

  ${(props) =>
    props.checked &&
    css`
      background-color: #1a8cff;
    `};
`;

export const Label = styled.span`
  user-select: none;
  vertical-align: middle;
  margin-top: 3px;
`;
