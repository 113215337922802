import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  padding: 42px 0 53px;
  border-bottom: 1px solid #e5ecf5;
`;

export const Title = styled.div`
  font-size: 18px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
  margin-bottom: 25px;
  user-select: none;
`;

export const SubtitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const TooltipWrapper = styled.div`
  margin-left: 20px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-family: ProximaNova-Regular, sans-serif;
  color: #161643;
  user-select: none;
`;
