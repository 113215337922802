import styled from 'styled-components';
import Input from '../Input';
import {Label as InputLabel} from '../Input/styled';

export const Content = styled.main`
  margin-top: 40px;
`;

export const AmountInput = styled(Input)`
  width: 124px;

  & input {
    font-family: ProximaNova-Medium, sans-serif;
    font-size: 25px;
  }

  & input::placeholder {
    font-size: 16px;
  }

  & ${InputLabel} {
    font-family: ProximaNova-Medium, sans-serif;
    font-size: 16px;
    color: #161643;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const RadioButtonsDescription = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #161643;
  font-family: ProximaNova-Regular, sans-serif;
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 15px;
  }
`;

export const Currency = styled.span`
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  font-size: 24px;
  margin-left: 5px;
  position: relative;
  top: 29px;
`;
