import React from 'react';
import {queryCache, useInfiniteQuery} from 'react-query';
import {useInfiniteScroll} from 'react-infinite-scroll-hook';
import {Column, Row, useTable} from 'react-table';
import {useTranslation, Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {toastResponseError} from '../../../utils/common';
import {Housing, SelectOption} from '../../../utils/types';
import {
  useSubscriptionAskModal,
  useErrorToast,
  useWarningModal,
} from '../../../utils/hooks';
import {useAuth} from '../../../context/auth';
import {useWebsocket} from '../../../context/websocket';
import {useComputedDetails} from '../../../context/computedDetails';
import {WS_EVENT_TYPES, ORIGINS_LABELS} from '../../../utils/constants';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import clockIcon from '../../../assets/clock.svg';
import acceptIcon from '../../../assets/accept-icn-black.svg';
import importIcon from '../../../assets/import.svg';
import plusIcon from '../../../assets/plus-blue.svg';
import policemanIcon from '../../../assets/policeman.svg';
import refreshIcon from '../../../assets/refresh.svg';
import collaboratorsNoAccessIcon from '../../../assets/collaborators_no_access.svg';
import addPropertyIcon from '../../../assets/icon-add-property.svg';
import notFoundIcon from '../../../assets/notfound-icon.svg';
import HeadingSelect from '../HeadingSelect';
import SearchHousingsModal from '../SearchHousingsModal';
import TableFilter from '../TableFilter';
import SearchButton from '../SearchButton';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import ImportCompleteModal from '../ImportCompleteModal';
import TablePlaceholder, {EMPTY_TABLE_ROWS_NUMBER} from '../TablePlaceholder';
import {
  InactiveRow,
  Content,
  Heading,
  HeadingAddButton,
  HeadingImportButton,
  PropertyTableHeader,
  StatusButton,
  StatusButtonCheckMarkIcon,
  StatusButtonClockIcon,
  StatusCell,
  StatusIcon,
  StatusText,
  StatusTooltip,
  TableAddButton,
  TableWrapper,
  HeadingRefreshButton,
  HousingsTableLoader,
  MappingNotification,
  MappingNotificationText,
  MappingNotificationLink,
  RefreshButtonLoaderWrapper,
} from './styled';
import Loader from '../../common/Loader';

const STORAGE_HOUSINGS_STATUS_FILTER = 'housingsStatusFilter';
const STORAGE_HOUSINGS_NAME_FILTER = 'housingsNameFilter';
const STORAGE_EXACT_HOUSINGS_NAME_FILTER = 'housingsExactNameFilter';
const IS_UNDER_MAINTENANCE = Boolean(process.env.REACT_APP_MAINTENANCE_MODE);

export enum HOUSING_STATUSES {
  complete = 'COMPLETE',
  incomplete = 'INCOMPLETE',
  all = 'ALL',
}

const HOUSING_STATUSES_LABELS: {[key: string]: string} = {
  [HOUSING_STATUSES.complete]: i18n.t('complete'),
  [HOUSING_STATUSES.incomplete]: i18n.t('incomplete'),
  [HOUSING_STATUSES.all]: i18n.t('all_statuses'),
};
const HOUSING_STATUSES_ICONS: {[key: string]: React.ReactNode | JSX.Element} = {
  [HOUSING_STATUSES.complete]: (
    <StatusButtonCheckMarkIcon src={acceptIcon} alt="Check mark" />
  ),
  [HOUSING_STATUSES.incomplete]: <StatusButtonClockIcon src={clockIcon} alt="Clock" />,
};
const HOUSINGS_STATUSES_FILTERS: {[key: string]: SelectOption} = {
  [HOUSING_STATUSES.all]: {
    value: HOUSING_STATUSES.all,
    label: HOUSING_STATUSES_LABELS[HOUSING_STATUSES.all],
  },
  [HOUSING_STATUSES.complete]: {
    value: HOUSING_STATUSES.complete,
    label: HOUSING_STATUSES_LABELS[HOUSING_STATUSES.complete],
  },
  [HOUSING_STATUSES.incomplete]: {
    value: HOUSING_STATUSES.incomplete,
    label: HOUSING_STATUSES_LABELS[HOUSING_STATUSES.incomplete],
  },
};

const WS_EVENT_HOUSINGS_SYNC_TYPES_ARRAY = [
  'SYNC_HOUSINGS_FINISHED',
  'SYNC_HOUSINGS_STARTED',
  'SYNC_HOUSINGS_WAITING',
  'SYNC_HOUSINGS_MAPPING',
];

const SYNC_SENT_STATUS = 'SEN';
const SYNC_STARTED_STATUS = 'STR';
const SYNC_WAITING_FOR_MAPPING_STATUS = 'WAI';
const SYNC_MAPPING_IN_PROGRESS_STATUS = 'MAP';
const SYNC_IN_PROGRESS_STATUS = 'PRO';

const DEFAULT_HOUSING_STATUS_FILTER = HOUSINGS_STATUSES_FILTERS[HOUSING_STATUSES.all];

function preloadStatusFilter() {
  const prevStatus = sessionStorage.getItem(STORAGE_HOUSINGS_STATUS_FILTER);
  if (prevStatus && HOUSINGS_STATUSES_FILTERS[prevStatus]) {
    return HOUSINGS_STATUSES_FILTERS[prevStatus];
  }

  sessionStorage.setItem(
    STORAGE_HOUSINGS_STATUS_FILTER,
    String(DEFAULT_HOUSING_STATUS_FILTER.value),
  );
  return DEFAULT_HOUSING_STATUS_FILTER;
}

function preloadNameFilter() {
  const prevName = sessionStorage.getItem(STORAGE_HOUSINGS_NAME_FILTER);
  return prevName || '';
}

function preloadExactNameFilter() {
  const prevName = sessionStorage.getItem(STORAGE_EXACT_HOUSINGS_NAME_FILTER);
  return prevName || '';
}

function getStatusQuery(option: SelectOption) {
  if (option.value === HOUSING_STATUSES.all) {
    return '';
  }

  return `status=${option.value}`;
}

function getNameQuery(name = '', exactName = '') {
  if (exactName) {
    return `exact_name=${encodeURIComponent(exactName)}`;
  }

  return `name=${encodeURIComponent(name)}`;
}

function getHousingsParams(
  page = 1,
  status = DEFAULT_HOUSING_STATUS_FILTER,
  name = '',
  exactName = '',
) {
  const searchQuery = getNameQuery(name, exactName);
  return `ordering=name&page=${page}&${getStatusQuery(status)}&${searchQuery}`;
}

function fetchHousings(key = '', params = 'ordering=name&page=1') {
  return queryFetcher(api.housings.ENDPOINTS.all(params));
}

function HousingTable() {
  const {t} = useTranslation();
  const history = useHistory();
  const ws = useWebsocket();
  const {WarningModal, displayWarning} = useWarningModal();
  const {SubscriptionAskModal, tryToAskSubscription} = useSubscriptionAskModal();
  const {isAccountCollaborator} = useComputedDetails();
  const {accountDetails: user, refreshAccount} = useAuth();
  const [page, setPage] = React.useState(1);
  const [isSearchModalOpen, setIsSearchModalOpen] = React.useState(false);
  const [nameFilter, setNameFilter] = React.useState(preloadNameFilter);
  const [exactNameFilter, setExactNameFilter] = React.useState(preloadExactNameFilter);
  const [statusFilter, setStatusFilter] = React.useState(preloadStatusFilter);
  const savedNextPageRef = React.useRef('');
  const [isRefreshDisabled, setIsRefreshDisabled] = React.useState(false);
  const [syncTask, setSyncTask] = React.useState<any>();
  const tableWrapperRef = React.useRef<HTMLTableElement>(null);
  const housingsParams = getHousingsParams(
    page,
    statusFilter,
    nameFilter,
    exactNameFilter,
  );
  const isAnyFilterApplied = Boolean(
    nameFilter || statusFilter?.value !== HOUSING_STATUSES.all,
  );
  const housingNameFilter = exactNameFilter || nameFilter;

  const {
    isFetching,
    isFetchingMore,
    data,
    fetchMore,
    canFetchMore,
    error,
    status,
  } = useInfiniteQuery(`housings`, [housingsParams], fetchHousings as any, {
    getFetchMore: (lastGroup: any = {}) => {
      if (lastGroup.next && lastGroup.next !== savedNextPageRef?.current) {
        savedNextPageRef.current = lastGroup.next;
        return housingsParams;
      }
      return false;
    },
  });
  useErrorToast(error, {
    notFoundMessage: t('errors.requested_housings_not_found'),
  });

  const infiniteRef = useInfiniteScroll<HTMLTableElement>({
    loading: isFetching || isFetchingMore,
    hasNextPage: Boolean(canFetchMore),
    onLoadMore: () => {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMore(housingsParams);
    },
  });

  const getSyncHousingsTasks = React.useCallback(async () => {
    if (user?.show_buttons_refresh_housings) {
      setIsRefreshDisabled(true);
      const integrationUrl = user?.origin.toLocaleLowerCase().replace('_', '-');
      const {data} = await api.housings.getSyncTasks(integrationUrl, user?.id);
      if (data) {
        setSyncTask((data.length && data[0]) || null);
      }
      setIsRefreshDisabled(false);
    }
  }, [user]);

  React.useEffect(() => {
    getSyncHousingsTasks();
  }, [user, getSyncHousingsTasks]);

  const sendRefreshHousingsTasks = async () => {
    setIsRefreshDisabled(true);
    const integrationUrl = user?.origin.toLocaleLowerCase().replace('_', '-');
    const {data, error} = await api.housings.sendSyncTask(integrationUrl, user?.id);
    if (error) {
      toastResponseError(error);
    }
    if (data) {
      await getSyncHousingsTasks();
      toast.success(t('sync_task_sent'));
    }
    setIsRefreshDisabled(false);
  };

  React.useEffect(() => {
    async function handleWebsocketEvents() {
      if (WS_EVENT_HOUSINGS_SYNC_TYPES_ARRAY.includes(ws.message?.event_type)) {
        await getSyncHousingsTasks();
      }

      if (
        ws.message.event_type === WS_EVENT_TYPES.housingCreated ||
        ws.message.event_type === WS_EVENT_TYPES.housingRemoved
      ) {
        queryCache.refetchQueries('housings');
      }
    }

    handleWebsocketEvents();

    return () => {
      ws.clearMessage();
    };
  }, [ws, ws.message, getSyncHousingsTasks]);

  const updateUserIfNeeded = React.useCallback(async () => {
    if (user && !user.has_seen_properties_page) {
      const {error, data} = await api.users.patchMe({has_seen_properties_page: true});
      if (error) {
        toastResponseError(error);
      }
      if (data) {
        refreshAccount();
      }
    }
  }, [user, refreshAccount]);

  React.useEffect(() => {
    updateUserIfNeeded();
  }, [user, updateUserIfNeeded]);

  const restartQuery = React.useCallback(() => {
    setPage(1);
    queryCache.removeQueries('housings');
    queryCache.refetchQueries('housings');
  }, []);

  const resetNameFilter = React.useCallback(() => {
    setNameFilter('');
    setExactNameFilter('');
    sessionStorage.removeItem(STORAGE_HOUSINGS_NAME_FILTER);
    sessionStorage.removeItem(STORAGE_EXACT_HOUSINGS_NAME_FILTER);
    restartQuery();
  }, [restartQuery]);

  const columns = React.useMemo<Column<Housing>[]>(
    () => [
      {
        Header: t('number') as string,
        Cell: ({row}: {row: any}) => {
          return row.index + 1;
        },
      },
      {
        Header: (
          <PropertyTableHeader>
            {housingNameFilter ? (
              <TableFilter onRemove={resetNameFilter}>{housingNameFilter}</TableFilter>
            ) : (
              (t('property_name') as string)
            )}
            <SearchButton onClick={() => setIsSearchModalOpen(true)} />
          </PropertyTableHeader>
        ),
        accessor: 'name',
      },
      {
        Header: t('status') as string,
        accessor: 'status',
        Cell: ({value}) => {
          return <TableStatusCell status={value} />;
        },
      },
    ],
    [t, housingNameFilter, resetNameFilter],
  );
  const tableData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      ?.map((g) => {
        return g?.results || [];
      })
      .flat();
  }, [data]);
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data: tableData,
  });
  const isInitiallyLoading = status === 'loading';
  const isLoaderVisible = isInitiallyLoading || isFetchingMore;

  const handleStatusChange = (status: SelectOption) => {
    setStatusFilter(status);
    sessionStorage.setItem(STORAGE_HOUSINGS_STATUS_FILTER, String(status.value));
    restartQuery();
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const submitSearchModalQuery = (option: SelectOption) => {
    const filter = String(option.label);
    const isExact = Boolean(option.value);

    if (isExact) {
      setExactNameFilter(filter);
      setNameFilter('');
      sessionStorage.setItem(STORAGE_EXACT_HOUSINGS_NAME_FILTER, filter);
      sessionStorage.removeItem(STORAGE_HOUSINGS_NAME_FILTER);
    } else {
      setNameFilter(filter);
      setExactNameFilter('');
      sessionStorage.setItem(STORAGE_HOUSINGS_NAME_FILTER, String(option.label));
      sessionStorage.removeItem(STORAGE_EXACT_HOUSINGS_NAME_FILTER);
    }

    restartQuery();
  };

  const goToHousingsImport = () => {
    if (tryToAskSubscription()) {
      return;
    }

    history.push('/properties/import');
  };

  const goToHousingsAdd = () => {
    if (tryToAskSubscription()) {
      return;
    }

    history.push('/properties/add');
  };

  const goToReservations = () => {
    history.push('/bookings');
  };

  const goToHousingsEdit = (row: Row<Housing>) => {
    const housing = row.original;
    const housingId = housing.id;

    if (housingId) {
      queryCache.setQueryData(['housing', housingId], housing);
      history.push(`/properties/${housingId}`);
    }
  };

  return (
    <>
      <WarningModal />
      <Content>
        <Heading>
          <HeadingSelect
            onChange={handleStatusChange}
            options={Object.values(HOUSINGS_STATUSES_FILTERS)}
            value={statusFilter}
          />
          <div>
            {user?.show_buttons_add_delete_import_housings && (
              <>
                {IS_UNDER_MAINTENANCE ? (
                  <HeadingImportButton
                    onClick={() =>
                      displayWarning(
                        <Trans i18nKey="maintenance_text">
                          <p>
                            We are currently updating the registration process, so you
                            won't be able to register and add properties in the next few
                            hours.
                          </p>
                          Please, contact us by the chat and our team will create an
                          account or add properties for you as soon as we perform the
                          update!
                        </Trans>,
                      )
                    }
                  >
                    <img src={importIcon} alt="Import" />
                  </HeadingImportButton>
                ) : (
                  <HeadingImportButton onClick={goToHousingsImport}>
                    <img src={importIcon} alt="Import" />
                  </HeadingImportButton>
                )}
              </>
            )}
            {user?.show_buttons_add_delete_import_housings && (
              <>
                {IS_UNDER_MAINTENANCE ? (
                  <HeadingAddButton
                    onClick={() =>
                      displayWarning(
                        <Trans i18nKey="maintenance_text">
                          <p>
                            We are currently updating the registration process, so you
                            won't be able to register and add properties in the next few
                            hours.
                          </p>
                          Please, contact us by the chat and our team will create an
                          account or add properties for you as soon as we perform the
                          update!
                        </Trans>,
                      )
                    }
                  >
                    <img src={plusIcon} alt="Plus" />
                    {t('new_property')}
                  </HeadingAddButton>
                ) : (
                  <HeadingAddButton onClick={goToHousingsAdd}>
                    <img src={plusIcon} alt="Plus" />
                    {t('new_property')}
                  </HeadingAddButton>
                )}
              </>
            )}
            {user?.show_buttons_refresh_housings && (
              <HeadingRefreshButton
                onClick={sendRefreshHousingsTasks}
                disabled={
                  isRefreshDisabled ||
                  (syncTask &&
                    [
                      SYNC_IN_PROGRESS_STATUS,
                      SYNC_SENT_STATUS,
                      SYNC_STARTED_STATUS,
                      SYNC_MAPPING_IN_PROGRESS_STATUS,
                      SYNC_WAITING_FOR_MAPPING_STATUS,
                    ].includes(syncTask?.status))
                }
              >
                <img src={refreshIcon} alt="Plus" />
                {syncTask &&
                  ![
                    SYNC_IN_PROGRESS_STATUS,
                    SYNC_SENT_STATUS,
                    SYNC_STARTED_STATUS,
                    SYNC_MAPPING_IN_PROGRESS_STATUS,
                    SYNC_WAITING_FOR_MAPPING_STATUS,
                  ].includes(syncTask?.status) &&
                  !isRefreshDisabled &&
                  t('refresh')}
                {(isRefreshDisabled ||
                  (syncTask &&
                    [
                      SYNC_IN_PROGRESS_STATUS,
                      SYNC_SENT_STATUS,
                      SYNC_STARTED_STATUS,
                      SYNC_MAPPING_IN_PROGRESS_STATUS,
                      SYNC_WAITING_FOR_MAPPING_STATUS,
                    ].includes(syncTask?.status))) && (
                  <RefreshButtonLoaderWrapper>
                    <Loader height={20} />
                  </RefreshButtonLoaderWrapper>
                )}
              </HeadingRefreshButton>
            )}
          </div>
        </Heading>
        <TableWrapper ref={tableWrapperRef}>
          <table ref={infiniteRef} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} onClick={() => goToHousingsEdit(row)}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
              {!isInitiallyLoading &&
                Boolean(tableData?.length) &&
                user?.show_buttons_add_delete_import_housings && (
                  <InactiveRow hasBorder={tableData?.length < EMPTY_TABLE_ROWS_NUMBER}>
                    <td>{rows.length + 1}</td>
                    <td>
                      <TableAddButton onClick={goToHousingsAdd}>
                        <img src={plusIcon} alt="Plus" />
                      </TableAddButton>
                    </td>
                    <td />
                  </InactiveRow>
                )}
            </tbody>
          </table>
          {isLoaderVisible && (
            <HousingsTableLoader
              hideBorder
              label={data?.length ? t('loading_more') : t('loading')}
            />
          )}
          <TablePlaceholder
            hidden={isAnyFilterApplied}
            isInitiallyLoading={isInitiallyLoading}
            isLoaderVisible={isLoaderVisible}
            modalIconSrc={addPropertyIcon}
            modalIconAlt="House with a plus"
            modalIconProps={{height: 58, width: 58}}
            modalText={t('first_thing_add_property')}
            tableDataLength={tableData?.length}
          >
            <ModalButton label={t('add_a_new_property')} onClick={goToHousingsAdd} />
          </TablePlaceholder>
          <TablePlaceholder
            hidden={!isAnyFilterApplied}
            isInitiallyLoading={isInitiallyLoading}
            isLoaderVisible={isLoaderVisible}
            modalIconSrc={notFoundIcon}
            modalIconAlt="Sad face"
            modalIconProps={{height: 31, width: 31}}
            modalText={t('no_properties_match_the_filters')}
            modalTitle={`${t('not_found').toUpperCase()}...`}
            tableDataLength={tableData?.length}
          />
        </TableWrapper>
      </Content>
      {isSearchModalOpen && (
        <SearchHousingsModal
          open
          onClose={closeSearchModal}
          onSubmit={submitSearchModalQuery}
        />
      )}
      {isAccountCollaborator && (
        <Modal
          iconSrc={collaboratorsNoAccessIcon}
          iconAlt="Hands with a warning sign"
          title={t('you_cant_access_properties')}
          text={t('you_have_collaborator_acc_only_managers_can_see')}
        >
          <ModalButton onClick={goToReservations} label={t('ok')} />
        </Modal>
      )}
      <SubscriptionAskModal />
      {user?.show_buttons_mapping &&
        (user?.import_status === 'WAITING_FOR_MAPPING' ||
          syncTask?.status === SYNC_WAITING_FOR_MAPPING_STATUS) && (
          <MappingNotification>
            <MappingNotificationText>
              <Trans
                i18nKey="you_have_added_a_new_property_on"
                values={{
                  integration: ORIGINS_LABELS[user?.origin || ''],
                }}
              >
                You have added a new property on Guesty. Please link it to a property on
                CheKin.
              </Trans>
            </MappingNotificationText>
            <MappingNotificationLink
              to={
                syncTask?.status === SYNC_WAITING_FOR_MAPPING_STATUS
                  ? `/properties/connect?housingSyncTaskId=${syncTask?.id}`
                  : '/properties/connect'
              }
            >
              {t('link_property')}
            </MappingNotificationLink>
          </MappingNotification>
        )}
      <ImportCompleteModal />
    </>
  );
}

type TableStatusCellProps = {
  status: string;
};

function TableStatusCell({status}: TableStatusCellProps) {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const displayStatus =
    HOUSING_STATUSES_LABELS[status] ||
    HOUSING_STATUSES_LABELS[HOUSING_STATUSES.incomplete];

  return (
    <StatusCell>
      <div
        onMouseOver={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <StatusButton onClick={(e) => e.stopPropagation()} status={status}>
          {displayStatus}
          {HOUSING_STATUSES_ICONS[status]}
        </StatusButton>
        <StatusTooltip
          onClick={(e) => e.stopPropagation()}
          onMouseOver={(e) => e.stopPropagation()}
          open={isTooltipOpen}
        >
          <StatusIcon src={policemanIcon} alt="Policeman" />
          <StatusText>{displayStatus}</StatusText>
        </StatusTooltip>
      </div>
    </StatusCell>
  );
}

export {HousingTable};
