import React from 'react';
import ReactSwitch from 'react-switch';
import {Wrapper, Label} from './styled';

export type SwitchProps = {
  onChange: (checked: boolean) => void;
  checked: boolean;
  id?: string;
  label?: React.ReactNode | JSX.Element | string;
  disabled?: boolean;
  className?: string;
};

const defaultProps: SwitchProps = {
  onChange: () => {},
  checked: false,
  label: '',
  disabled: false,
  id: '',
  className: undefined,
};

const SWITCH_CONFIG = {
  offColor: '#DEDEEB',
  onColor: '#1A8CFF',
  width: 40,
  height: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  activeBoxShadow: '0 0 0 0 #fff',
};

function Switch({onChange, label, checked, disabled, className}: SwitchProps) {
  const onChangeHandler = disabled ? () => {} : onChange;

  return (
    <Wrapper
      disabled={disabled}
      onClick={() => onChangeHandler(!checked)}
      className={className}
    >
      <ReactSwitch
        onChange={() => {}}
        checked={checked}
        disabled={disabled}
        {...SWITCH_CONFIG}
      />
      <Label>{label}</Label>
    </Wrapper>
  );
}

Switch.defaultProps = defaultProps;
export {Switch};
