import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment-timezone';
import 'array-flat-polyfill';
import 'polyfill-array-includes';
import 'url-search-params-polyfill';
import ReactGA from 'react-ga';
import FullStory from 'react-fullstory';
import * as Sentry from '@sentry/react';
import {pdfjs} from 'react-pdf';
import {Integrations} from '@sentry/tracing';
import {CaptureConsole} from '@sentry/integrations';
import {createBrowserHistory} from 'history';
import {Userpilot} from 'userpilot';
import * as serviceWorker from './serviceWorker';
import {initMixpanel} from './analytics/mixpanel';
import App from './App';
import AppProviders from './context';

const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';
const FULLSTORY_CONFIG = {
  org: process.env.REACT_APP_FULLSTORY_ORG || '',
  namespace: 'FS',
};
const browserHistory = createBrowserHistory();

function setupGA() {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
  if (!trackingId) {
    console.warn('Missing Google Analytics tracking id. Setup abandoned.');
    return;
  }

  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function setupSentry() {
  const dsn = process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME;
  const environment = process.env.REACT_APP_ENV;
  const domain = process.env.REACT_APP_DOMAIN || 'localhost';

  if (dsn) {
    Sentry.init({
      dsn,
      environment,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [domain],
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
        }),
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 0.5,
      maxValueLength: 10000,
    });
  }
}

function setupMixpanel() {
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;

  if (token) {
    initMixpanel(token);
  }
}

function setupUserpilot() {
  const token = process.env.REACT_APP_USERPILOT_TOKEN;

  if (token) {
    Userpilot?.initialize(token);
  }
}

if (IS_PRODUCTION_BUILD) {
  setupSentry();
  setupGA();
  setupMixpanel();
  setupUserpilot();
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

ReactDOM.render(
  <>
    {IS_PRODUCTION_BUILD && (
      <>
        <FullStory {...FULLSTORY_CONFIG} />
      </>
    )}
    <AppProviders browserHistory={browserHistory}>
      <App />
    </AppProviders>
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
