import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import EditReservationSections from '../components/dashboard/EditReservationSections';

function ReservationsEdit(props: any) {
  return (
    <>
      <TrialHeader />
      <Header />
      <EditReservationSections {...props} />
    </>
  );
}

export {ReservationsEdit};
