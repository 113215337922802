import i18n from '../i18n';
import {COUNTRY_CODES, EU_MEMBERS} from './constants';
import {SelectOption} from './types';

const passport = {
  value: 'P',
  label: i18n.t('documents.passport'),
};

const uaeNonCitizen = [
  passport,
  {
    value: 'AE_RP',
    label: i18n.t('documents.residence_permit'),
  },
];

const portugal = [
  {
    value: 'PT_P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'PT_B',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'PT_O',
    label: i18n.t('documents.other_document'),
  },
];

const czech = [
  {
    value: 'CZ_P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'CZ_ID',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'CZ_DL',
    label: i18n.t('documents.driving_license_cz'),
  },
];

const czechEU = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'I',
    label: i18n.t('documents.identity_card'),
  },
];

const czechNonEU = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
];

const greeceSpecific = [
  {
    value: 'GR_P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'GR_ID',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'GR_DL',
    label: i18n.t('documents.driving_license_gr'),
  },
  {
    value: 'GR_ARMY_ID',
    label: i18n.t('documents.army_id_gr'),
  },
  {
    value: 'GR_WORK_PERMIT',
    label: i18n.t('documents.work_permit_gr'),
  },
];

const greeceEu = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'I',
    label: i18n.t('documents.identity_card'),
  },
];

const greeceDefault = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
];

const slovenia = [
  {
    value: 'SL_P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'SL_I',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'SL_V',
    label: i18n.t('documents.driving_license'),
  },
  {
    value: 'SL_F',
    label: i18n.t('documents.border_pass'),
  },
  {
    value: 'SL_L',
    label: i18n.t('documents.children_citizens_slovenian'),
  },
  {
    value: 'SL_H',
    label: i18n.t('documents.travel_documents'),
  },
  {
    value: 'SL_U',
    label: i18n.t('documents.administrative_documents'),
  },
  {
    value: 'SL_O',
    label: i18n.t('documents.weapon_certificate'),
  },
];

const thailand = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'I',
    label: i18n.t('documents.identity_card'),
  },
];

const austria = [
  {
    value: 'AT_P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'AT_ID',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'AT_DL',
    label: i18n.t('documents.driving_license'),
  },
  {
    value: 'AT_O',
    label: i18n.t('documents.other'),
  },
];

const thailandAllNationalities = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
];

const spanishAllNationalitiesDocuments = [
  {
    value: 'ES_P',
    label: i18n.t('documents.passport'),
  },
];

const italianAllNationalities = [
  {
    value: 'IT_PASOR',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'IT_IDELE',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'IT_PATEN',
    label: i18n.t('documents.driving_license_eu'),
  },
  {
    value: 'IT_PASDI',
    label: i18n.t('documents.diplomatic_passport'),
  },
  {
    value: 'IT_PASSE',
    label: i18n.t('documents.service_passport'),
  },
];

const general = [
  {
    value: 'P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'I',
    label: i18n.t('documents.identity_card'),
  },
];

const colombia = [
  {
    value: 'CO_P',
    label: i18n.t('documents.passport'),
  },
  {
    value: 'CO_FD',
    label: i18n.t('documents.foreign_document'),
  },
  {
    value: 'CO_FID',
    label: i18n.t('documents.residence_permit'),
  },
  {
    value: 'CO_DIP',
    label: i18n.t('documents.diplomatic_id'),
  },
];

const allItalian = [
  ...italianAllNationalities,
  {
    value: 'IT_IDENT',
    label: i18n.t('documents.identity_card_paper'),
  },
  {
    value: 'IT_CERID',
    label: i18n.t('documents.identity_certificate'),
  },
  {
    value: 'IT_CIDIP',
    label: i18n.t('documents.identity_diplomatic'),
  },
  {
    value: 'IT_ACMIL',
    label: i18n.t('documents.tess__app_to_ag_custodia'),
  },
  {
    value: 'IT_ACSOT',
    label: i18n.t('documents.tess__sott_li_ag_custodia'),
  },
  {
    value: 'IT_ACUFF',
    label: i18n.t('documents.tess__uff_li_ag_custodia'),
  },
  {
    value: 'IT_AMMIL',
    label: i18n.t('documents.tess__militare_truppa_a_m'),
  },
  {
    value: 'IT_AMSOT',
    label: i18n.t('documents.tess__sottufficiali_a_m_'),
  },
  {
    value: 'IT_AMUFF',
    label: i18n.t('documents.tess__ufficiali_a_m_'),
  },
  {
    value: 'IT_CCMIL',
    label: i18n.t('documents.tess__app_to_carabinieri'),
  },
  {
    value: 'IT_CCSOT',
    label: i18n.t('documents.tess__sottufficiali_cc'),
  },
  {
    value: 'IT_CCUFF',
    label: i18n.t('documents.tess__ufficiale'),
  },
  {
    value: 'IT_CFMIL',
    label: i18n.t('documents.tess__ag__e_ag_sc__c_f_s_'),
  },
  {
    value: 'IT_CFSOT',
    label: i18n.t('documents.tess__sottuficiali_c_f_s_'),
  },
  {
    value: 'IT_CFUFF',
    label: i18n.t('documents.tess__ufficiali_c_f_s_'),
  },
  {
    value: 'IT_DESIS',
    label: i18n.t('documents.tess__s_i_s_d_e_'),
  },
  {
    value: 'IT_EIMIL',
    label: i18n.t('documents.tess__militare_e_i_'),
  },
  {
    value: 'IT_EISOT',
    label: i18n.t('documents.tess__sottufficiali_e_i_'),
  },
  {
    value: 'IT_EIUFF',
    label: i18n.t('documents.tess__ufficiali_e_i_'),
  },
  {
    value: 'IT_GFMIL',
    label: i18n.t('documents.tess__app_to_finanziere'),
  },
  {
    value: 'IT_GFSOT',
    label: i18n.t('documents.tess__sott_li_g_d_f_'),
  },
  {
    value: 'IT_GFTRI',
    label: i18n.t('documents.tess__pol__trib__g_d_f_'),
  },
  {
    value: 'IT_GFUFF',
    label: i18n.t('documents.tess__ufficiali_g_d_f_'),
  },
  {
    value: 'IT_MAGIS',
    label: i18n.t('documents.tess__pers__magistrati'),
  },
  {
    value: 'IT_MMMIL',
    label: i18n.t('documents.tess__milit__m_m_'),
  },
  {
    value: 'IT_MMSOT',
    label: i18n.t('documents.tess__sottuficiali_m_m_'),
  },
  {
    value: 'IT_MMUFF',
    label: i18n.t('documents.tess__ufficiali_m_m_'),
  },
  {
    value: 'IT_PARLA',
    label: i18n.t('documents.tess__parlamentari'),
  },
  {
    value: 'IT_PATNA',
    label: i18n.t('documents.patente_nautica'),
  },
  {
    value: 'IT_PORM1',
    label: i18n.t('documents.porto_fucile_uso_caccia'),
  },
  {
    value: 'IT_PORM2',
    label: i18n.t('documents.porto_fucile_dif__person_'),
  },
  {
    value: 'IT_PORM3',
    label: i18n.t('documents.porto_darmi_uso_sportivo'),
  },
  {
    value: 'IT_PORM4',
    label: i18n.t('documents.porto_pistola_dif__person'),
  },
  {
    value: 'IT_PORM5',
    label: i18n.t('documents.porto_darmi_guardie_giur'),
  },
  {
    value: 'IT_PPAGE',
    label: i18n.t('documents.tess__agenti/ass_ti_p_p_'),
  },
  {
    value: 'IT_PPISP',
    label: i18n.t('documents.tess__ispettori_p_p_'),
  },
  {
    value: 'IT_PPSOV',
    label: i18n.t('documents.tess__sovrintendenti_p_p_'),
  },
  {
    value: 'IT_PPUFF',
    label: i18n.t('documents.tess__ufficiali_p_p_'),
  },
  {
    value: 'IT_PSAPP',
    label: i18n.t('documents.tess__agenti/ass_ti_p_s_'),
  },
  {
    value: 'IT_PSFEM',
    label: i18n.t('documents.tess__polizia_femminile'),
  },
  {
    value: 'IT_PSFUN',
    label: i18n.t('documents.tess__funzionari_p_s_'),
  },
  {
    value: 'IT_PSISP',
    label: i18n.t('documents.tess__ispettori_p_s_'),
  },
  {
    value: 'IT_PSSOT',
    label: i18n.t('documents.tess__sovrintendenti_p_s_'),
  },
  {
    value: 'IT_PSUFF',
    label: i18n.t('documents.tess__ufficiali_p_s_'),
  },
  {
    value: 'IT_RIFUG',
    label: i18n.t('documents.titolo_viaggio_rif_polit_'),
  },
  {
    value: 'IT_SDMIL',
    label: i18n.t('documents.tess__milit__truppa_sismi'),
  },
  {
    value: 'IT_SDSOT',
    label: i18n.t('documents.tess__sottufficiali_sismi'),
  },
  {
    value: 'IT_SDUFF',
    label: i18n.t('documents.tess__ufficiali_sismi'),
  },
  {
    value: 'IT_TEAMC',
    label: i18n.t('documents.tess__iscr__albo_med/chi_'),
  },
  {
    value: 'IT_TEAOD',
    label: i18n.t('documents.tess__iscriz__albo_odont_'),
  },
  {
    value: 'IT_TECAM',
    label: i18n.t('documents.tes__unico_per_la_camera'),
  },
  {
    value: 'IT_TECOC',
    label: i18n.t('documents.tess__corte_dei_conti'),
  },
  {
    value: 'IT_TEDOG',
    label: i18n.t('documents.tes__doganale_ril_min_fin_'),
  },
  {
    value: 'IT_TEFSE',
    label: i18n.t('documents.tess__ferrov__senato'),
  },
  {
    value: 'IT_TEMPI',
    label: i18n.t('documents.tess__min_pubb_istruzione'),
  },
  {
    value: 'IT_TENAT',
    label: i18n.t('documents.tess__militare_nato'),
  },
  {
    value: 'IT_TENAV',
    label: i18n.t('documents.tes__ente_naz__assis_volo'),
  },
  {
    value: 'IT_TEPOL',
    label: i18n.t('documents.tess__min_polit_agric_for_'),
  },
  {
    value: 'IT_TESAE',
    label: i18n.t('documents.tess__min__affari_esteri'),
  },
  {
    value: 'IT_TESAR',
    label: i18n.t('documents.tess__iscr_albo_architetti'),
  },
  {
    value: 'IT_TESAV',
    label: i18n.t('documents.tessera_iscr__albo_avvoc_'),
  },
  {
    value: 'IT_TESCA',
    label: i18n.t('documents.tess__corte_dappello'),
  },
  {
    value: 'IT_TESCS',
    label: i18n.t('documents.tess__consiglio_di_stato'),
  },
  {
    value: 'IT_TESDI',
    label: i18n.t('documents.tessera_riconosc__d_i_a_'),
  },
  {
    value: 'IT_TESEA',
    label: i18n.t('documents.tess__membro_equip__aereo'),
  },
  {
    value: 'IT_TESIN',
    label: i18n.t('documents.tess__iscr__albo_ingegneri'),
  },
  {
    value: 'IT_TESLP',
    label: i18n.t('documents.tess__ministero_lavori_pu'),
  },
  {
    value: 'IT_TESMB',
    label: i18n.t('documents.tess__min_ben_e_att_cult_'),
  },
  {
    value: 'IT_TESMD',
    label: i18n.t('documents.tess__ministero_difesa'),
  },
  {
    value: 'IT_TESMF',
    label: i18n.t('documents.tess__ministero_finanze'),
  },
  {
    value: 'IT_TESMG',
    label: i18n.t('documents.tess__ministero_giustizia'),
  },
  {
    value: 'IT_TESMI',
    label: i18n.t('documents.tess__ministero_interno'),
  },
  {
    value: 'IT_TESMN',
    label: i18n.t('documents.tess__minist__trasp/navig'),
  },
  {
    value: 'IT_TESMS',
    label: i18n.t('documents.tess__ministero_sanita'),
  },
  {
    value: 'IT_TESMT',
    label: i18n.t('documents.tess__ministero_tesoro'),
  },
  {
    value: 'IT_TESNO',
    label: i18n.t('documents.tessera_dellordine_notai'),
  },
  {
    value: 'IT_TESOG',
    label: i18n.t('documents.tess__ordine_giornalisti'),
  },
  {
    value: 'IT_TESPC',
    label: i18n.t('documents.tess__pres_za_cons__min_'),
  },
  {
    value: 'IT_TESPI',
    label: i18n.t('documents.tess__pubblica_istruzione'),
  },
  {
    value: 'IT_TESPT',
    label: i18n.t('documents.tes__poste_e_telecomunic_'),
  },
  {
    value: 'IT_TESUN',
    label: i18n.t('documents.tessera_u_n_u_c_i_'),
  },
  {
    value: 'IT_TETEL',
    label: i18n.t('documents.tess__identif_telecom_it_'),
  },
  {
    value: 'IT_TFERD',
    label: i18n.t('documents.tes__ferroviaria_deputati'),
  },
  {
    value: 'IT_TFEXD',
    label: i18n.t('documents.tes__ferrov__ex_deputati'),
  },
  {
    value: 'IT_VIMIL',
    label: i18n.t('documents.tess__app_to/vig__urbano'),
  },
  {
    value: 'IT_VISOT',
    label: i18n.t('documents.tess__sott_li_vig__urbani'),
  },
  {
    value: 'IT_VIUFF',
    label: i18n.t('documents.tess__uff_li_vig_urbani'),
  },
  {
    value: 'IT_VVMIL',
    label: i18n.t('documents.tess__app_to/vig__vv_ff_'),
  },
  {
    value: 'IT_VVSOT',
    label: i18n.t('documents.tess__sottuff_li_vv_ff_'),
  },
  {
    value: 'IT_VVUFF',
    label: i18n.t('documents.tess__ufficiali_vv_ff_'),
  },
];

const allSpanishSpecific = [
  ...spanishAllNationalitiesDocuments,
  {
    value: 'ES_D',
    label: i18n.t('documents.dni'),
  },
  {
    value: 'ES_C',
    label: i18n.t('documents.driving_license_es'),
  },
];

const allSpanish = [
  ...spanishAllNationalitiesDocuments,
  {
    value: 'ES_I',
    label: i18n.t('documents.identity_card'),
  },
  {
    value: 'ES_N',
    label: i18n.t('documents.spanish_residence_permit'),
  },
  {
    value: 'ES_X',
    label: i18n.t('documents.eu_residence_permit'),
  },
];
const drivingLicenseES = 'ES_C';
const dniValidation = ['ES_D', drivingLicenseES];
const nieValidation = ['ES_N'];

const types = {
  id: 'I',
  passport: 'P',
  uaeResidencePermit: 'AE_RP',
};

function getRemappedDocType({
  docType,
  countryCode,
}: {
  docType: string;
  countryCode: string;
}) {
  const REMAPPED_ITALIAN_PASSPORT = 'IT_PASOR';
  const ELECTRONIC_ITALIAN_ID = 'IT_IDELE';
  const FOREIGN_DOC_TYPE = 'ID';
  const REMAPPED_FOREIGN_DOC_TYPE = 'I';
  const RESIDENCE_PERMIT = 'IX';
  const REMAPPED_RESIDENCE_PERMIT = 'X';
  const PORTUGAL_ID = 'PT_B';
  const PORTUGAL_PASSPORT = 'PT_P';
  const SPANISH_RESIDENCE_PERMIT = 'ES_N';
  const PORTUGAL_OTHER_DOCUMENT = 'PT_O';
  const SPANISH_PASSPORT = 'ES_P';
  const ES_DNI_CODE = 'ES_D';

  if (docType === FOREIGN_DOC_TYPE) {
    if (countryCode === COUNTRY_CODES.spain) {
      return ES_DNI_CODE;
    } else if (countryCode === COUNTRY_CODES.italy) {
      return ELECTRONIC_ITALIAN_ID;
    } else if (countryCode === COUNTRY_CODES.portugal) {
      return PORTUGAL_ID;
    } else {
      return REMAPPED_FOREIGN_DOC_TYPE;
    }
  } else if (docType === RESIDENCE_PERMIT) {
    if (countryCode === COUNTRY_CODES.spain) {
      return SPANISH_RESIDENCE_PERMIT;
    } else if (countryCode === COUNTRY_CODES.italy) {
      return ELECTRONIC_ITALIAN_ID;
    } else if (countryCode === COUNTRY_CODES.portugal) {
      return PORTUGAL_OTHER_DOCUMENT;
    } else {
      return REMAPPED_RESIDENCE_PERMIT;
    }
  } else if (docType === passport.value) {
    if (countryCode === COUNTRY_CODES.spain) {
      return SPANISH_PASSPORT;
    } else if (countryCode === COUNTRY_CODES.italy) {
      return REMAPPED_ITALIAN_PASSPORT;
    } else if (countryCode === COUNTRY_CODES.portugal) {
      return PORTUGAL_PASSPORT;
    } else {
      return passport.value;
    }
  } else {
    return docType;
  }
}

function getShouldResetDocTypes(
  nextDocTypes: Array<SelectOption>,
  docType?: SelectOption | null,
) {
  if (docType) {
    return !nextDocTypes.find((t) => {
      return t.value === docType.value;
    });
  }
  return false;
}

function getDocTypes(
  countryCode = '',
  nationality = '',
  residence = '',
  citizenship = '',
) {
  switch (countryCode) {
    case COUNTRY_CODES.portugal: {
      return portugal;
    }
    case COUNTRY_CODES.italy: {
      if (nationality === COUNTRY_CODES.italy) {
        return allItalian;
      }
      return italianAllNationalities;
    }
    case COUNTRY_CODES.spain: {
      if (nationality === COUNTRY_CODES.spain) {
        return allSpanishSpecific;
      }
      return allSpanish;
    }
    case COUNTRY_CODES.czech: {
      if (citizenship === COUNTRY_CODES.czech) {
        return czech;
      }
      if (EU_MEMBERS.includes(String(citizenship))) {
        return czechEU;
      }
      return czechNonEU;
    }
    case COUNTRY_CODES.colombia: {
      return colombia;
    }
    case COUNTRY_CODES.austria: {
      return austria;
    }
    case COUNTRY_CODES.slovenia: {
      return slovenia;
    }
    case COUNTRY_CODES.thailand: {
      if (nationality === COUNTRY_CODES.thailand) {
        return thailand;
      }
      return thailandAllNationalities;
    }
    case COUNTRY_CODES.dubai: {
      if (nationality === COUNTRY_CODES.dubai) {
        return general;
      }
      return uaeNonCitizen;
    }
    case COUNTRY_CODES.greece: {
      if (residence === COUNTRY_CODES.greece) {
        return greeceSpecific;
      } else if (EU_MEMBERS.includes(residence)) {
        return greeceEu;
      } else {
        return greeceDefault;
      }
    }
    default: {
      return general;
    }
  }
}

export {getDocTypes, getShouldResetDocTypes, getRemappedDocType};
export default {
  portugal,
  czech,
  czechEU,
  czechNonEU,
  thailand,
  spanishAllNationalitiesDocuments,
  italianAllNationalities,
  general,
  colombia,
  allItalian,
  allSpanishSpecific,
  allSpanish,
  dniValidation,
  drivingLicenseES,
  nieValidation,
  types,
  passport,
  greeceSpecific,
  greeceEu,
  greeceDefault,
};
