import React from 'react';
import uploadIcon from '../../../assets/icon-upload.svg';
import {InputEventType} from '../../../utils/types';
import {
  FileInputLabel,
  HiddenFileUploader,
  FileUploadIcon,
  FileInputText,
} from './styled';

export type FileInputProps = {
  onChange?: (e: InputEventType) => void;
  label?: string;
  accept?: string;
  disabled?: boolean;
  icon?: React.ReactNode | JSX.Element | string;
  secondary?: boolean;
  multiple?: boolean;
  className?: string;
};

const defaultProps: FileInputProps = {
  onChange: () => {},
  label: '',
  accept: '',
  disabled: false,
  secondary: false,
  className: undefined,
  multiple: false,
  icon: <FileUploadIcon src={uploadIcon} alt="Upload img" />,
};

function FileInputButton({
  onChange,
  label,
  accept,
  disabled,
  icon,
  secondary,
  className,
  multiple,
}: FileInputProps) {
  return (
    <FileInputLabel className={className} disabled={disabled} secondary={secondary}>
      <HiddenFileUploader
        multiple={multiple}
        disabled={disabled}
        type="file"
        name="fileInput"
        accept={accept}
        onChange={onChange}
      />
      {icon}
      <FileInputText>{label}</FileInputText>
    </FileInputLabel>
  );
}

FileInputButton.defaultProps = defaultProps;
export {FileInputButton};
