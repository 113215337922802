import {resolver} from './index';

const ENDPOINTS = {
  all: (id = '', params = '') => `guests/${id ? `${id}/?${params}` : `?${params}`}`,
  guestLeaders: (params = '') => `guest-leaders/?${params}`,
};

function patchById(id: string, payload: any, params = '') {
  return resolver(ENDPOINTS.all(id, params), {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
}

function post(payload: any) {
  return resolver(ENDPOINTS.all(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function deleteById(id: string) {
  return resolver(ENDPOINTS.all(id), {
    method: 'DELETE',
  });
}

function getGuestLeaders(params: string) {
  return resolver(ENDPOINTS.guestLeaders(params));
}

export {ENDPOINTS, patchById, post, deleteById, getGuestLeaders};
