import {User} from './types';
import {COLLABORATOR_GROUPS} from './constants';

function getIsAccountOwner(user?: User | null) {
  return !Boolean(user?.manager);
}

function getIsAccountCollaborator(user?: User | null) {
  return Boolean(
    user?.groups?.some((group) => group?.name === COLLABORATOR_GROUPS.collaborator),
  );
}

export {getIsAccountOwner, getIsAccountCollaborator};
