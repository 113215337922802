import React from 'react';
import {default as Content} from '../components/onboarding/PmsEzeeOriginRegister';

function PmsEzeeOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsEzeeOriginRegister};
