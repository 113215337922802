import styled from 'styled-components';

type WrapperProps = {
  disabled?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Label = styled.div`
  margin-left: 12px;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 16px;
  color: #161643;
  user-select: none;
`;
