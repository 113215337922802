import styled from 'styled-components';
import BaseButton from '../ModalButton';

export const Container = styled.div`
  position: fixed;
  bottom: 150px;
  right: 20px;
  width: 220px;
  min-height: 50px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 30px 30px #2699fb1a;
  padding: 45px 18px 21px 29px;
  text-align: center;
  z-index: 20;
  cursor: default;
`;

export const Text = styled.div`
  font-family: ProximaNova-Light, sans-serif;
  font-size: 16px;
  color: #161643;
`;

export const Total = styled.span`
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 19px;
`;

export const Exported = styled.span`
  font-family: ProximaNova-Black, sans-serif;
  font-size: 19px;
`;

export const NumbersWrapper = styled.div`
  margin-top: 3px;
`;

export const ExportText = styled.span`
  font-size: 16px;
`;

export const Button = styled(BaseButton)`
  min-width: auto;
  padding: 0;
`;

export const YesButton = styled(Button)`
  margin-right: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
`;

export const CloseIcon = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 0 10px 10px #2699fb1a;
`;

export const Divider = styled.span`
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 16px;
  color: #161643;
`;

export const SuccessText = styled(Text)`
  font-family: ProximaNova-Bold, sans-serif;
  color: #35e5bc;
`;

export const ErrorText = styled(Text)`
  font-family: ProximaNova-Bold, sans-serif;
  color: #ff2467;
`;
