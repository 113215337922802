import React from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {queryCache, useQuery} from 'react-query';
import {useForm, Controller} from 'react-hook-form';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import {
  addSupportEmailToMessage,
  getRequiredOrOptionalFieldLabel,
  toastResponseError,
} from '../../../utils/common';
import {useErrorToast, useIsMounted} from '../../../utils/hooks';
import {COUNTRY_CODES, DIVISION_LVL_1_COUNTRIES} from '../../../utils/constants';
import {useWebsocket} from '../../../context/websocket';
import {WS_EVENT_TYPES} from '../../../utils/constants';
import {
  BillingDetails,
  District,
  Province,
  SelectOption,
  TaxId,
} from '../../../utils/types';
import Input from '../Input';
import Select from '../Select';
import Button from '../Button';
import Loader from '../../common/Loader';
import {
  Content,
  HeaderText,
  FormWrapper,
  FormPartWrapper,
  FormItemWrapper,
  FormTextInfo,
  Form,
  CityFormItemWrapper,
  SubmitButtonWrapper,
  ButtonLabelText,
  ButtonLabelWrapper,
  LoaderWrapper,
} from './styled';

const PROVINCES_CACHE_TIME_MIN = 60;
const COUNTRIES_CACHE_TIME_MIN = 60;
const COUNTRIES_STALE_TIME_MIN = 30;
const PROVINCES_STALE_TIME_MIN = 30;

const TAX_TYPE = 'eu_vat';

export const TAX_COUNTRIES = [
  {value: COUNTRY_CODES.spain, label: i18n.t('sp_vat')},
  {value: COUNTRY_CODES.italy, label: i18n.t('it_vat')},
  {value: COUNTRY_CODES.portugal, label: i18n.t('pt_vat')},
  {value: COUNTRY_CODES.germany, label: i18n.t('de_vat')},
];

const TAX_REGEX_VALUES: {[index: string]: any} = {
  ES: /[0-9A-Z][0-9]{7}[0-9A-Z]/,
  PT: /[0-9]{9}/,
  IT: /[0-9]{11}/,
  DE: /[0-9]{9}/,
};

const DIVISION_LVL_1_PROVINCE_COUNTRIES = [
  COUNTRY_CODES.dubai,
  COUNTRY_CODES.romania,
  COUNTRY_CODES.portugal,
];

function fetchBillingDetails() {
  return queryFetcher(api.payments.ENDPOINTS.billingDetails());
}

function fetchCountries() {
  return queryFetcher(api.locations.ENDPOINTS.all(`ordering=name`));
}

function fetchProvinces(key: string, country = '') {
  return queryFetcher(api.locations.ENDPOINTS.all(`country=${country}&ordering=name`));
}

function getProvincesAsOptions(provinces: any, country = '') {
  if (!provinces?.results) {
    return [];
  }

  if (DIVISION_LVL_1_PROVINCE_COUNTRIES.includes(country)) {
    return provinces?.results?.map((c: District) => {
      return {
        label: c?.division_level_1?.name,
        value: c?.division_level_1?.code,
      };
    });
  }

  return provinces?.results?.map((c: Province) => {
    return {
      label: c?.division_level_2?.name,
      value: c?.division_level_2?.code,
    };
  });
}

function getCountriesAsOptions(countries: any) {
  if (!countries?.results) {
    return [];
  }

  return countries?.results?.map((c: District) => {
    return {
      label: c?.country?.name,
      value: c?.country?.code,
    };
  });
}

function getCountryCode(billing?: BillingDetails | null): string {
  return billing?.address?.country || '';
}

function getProvinceCode(billing?: BillingDetails | null) {
  const countryCode = getCountryCode(billing);

  if (DIVISION_LVL_1_COUNTRIES.includes(countryCode)) {
    return billing?.address?.state;
  }
  return billing?.address?.state;
}

function getInitTaxIdOption(taxId: TaxId) {
  return TAX_COUNTRIES.find((option) => {
    return option?.value === taxId?.country;
  });
}

function getInitTaxValue(taxId: TaxId) {
  if (!taxId) {
    return '';
  }
  return taxId.value.slice(2);
}

function getInitCountryOption(countryCode: string, countries: SelectOption[]) {
  if (!countries) {
    return undefined;
  }

  return countries.find((option) => {
    return option.value === countryCode;
  });
}

export enum FORM_NAMES {
  name = 'name',
  country = 'country',
  city = 'city',
  address_line_1 = 'address_line_1',
  address_line_2 = 'address_line_2',
  postal_code = 'postal_code',
  state = 'state',
  tax_id = 'tax_id',
  tax_value = 'tax_value',
}

type FormTypes = {
  [FORM_NAMES.name]: string;
  [FORM_NAMES.country]: SelectOption;
  [FORM_NAMES.state]: SelectOption;
  [FORM_NAMES.city]: string;
  [FORM_NAMES.address_line_1]: string;
  [FORM_NAMES.address_line_2]: string;
  [FORM_NAMES.tax_id]: SelectOption;
  [FORM_NAMES.tax_value]: string;
  [FORM_NAMES.postal_code]: string;
};

const INIT_DISPLAY_FIELDS = {
  [FORM_NAMES.name]: true,
  [FORM_NAMES.country]: true,
  [FORM_NAMES.state]: true,
  [FORM_NAMES.city]: true,
  [FORM_NAMES.address_line_1]: true,
  [FORM_NAMES.address_line_2]: true,
  [FORM_NAMES.tax_id]: true,
  [FORM_NAMES.tax_value]: true,
  [FORM_NAMES.postal_code]: true,
};

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.name]: i18n.t('required') as string,
  [FORM_NAMES.country]: i18n.t('required') as string,
  [FORM_NAMES.state]: false,
  [FORM_NAMES.city]: false,
  [FORM_NAMES.address_line_1]: i18n.t('required') as string,
  [FORM_NAMES.address_line_2]: false,
  [FORM_NAMES.tax_id]: i18n.t('required') as string,
  [FORM_NAMES.tax_value]: i18n.t('required') as string,
  [FORM_NAMES.postal_code]: false,
};

function getFields() {
  return {
    display: INIT_DISPLAY_FIELDS,
    required: INIT_REQUIRED_FIELDS,
  };
}

function BillingDetailsSection() {
  const {t} = useTranslation();
  const ws = useWebsocket();
  const isMounted = useIsMounted();
  const {
    register,
    errors,
    control,
    formState,
    triggerValidation,
    setValue,
    watch,
    handleSubmit,
  } = useForm<FormTypes>();

  const country = watch(FORM_NAMES.country)?.value;
  const taxId = watch(FORM_NAMES.tax_id)?.value;
  const taxValue = watch(FORM_NAMES.tax_value);

  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isCountryPreloaded, setIsCountryPreloaded] = React.useState(false);
  const [isProvincePreloaded, setIsProvincePreloaded] = React.useState(false);
  const [fields] = React.useState(() => {
    return getFields();
  });

  const {
    data: fetchedBillingDetails,
    error: fetchedBillingDetailsError,
    status,
  } = useQuery('billingDetails', fetchBillingDetails, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(fetchedBillingDetailsError, {
    notFoundMessage: t('errors.requested_billing_details_not_found'),
  });

  const isLoading = status === 'loading';

  const billing = React.useMemo(() => {
    return fetchedBillingDetails;
  }, [fetchedBillingDetails]);

  const {data: provinces, error} = useQuery(['provinces', country], fetchProvinces, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * PROVINCES_CACHE_TIME_MIN,
    staleTime: 1000 * 60 * PROVINCES_STALE_TIME_MIN,
  });
  useErrorToast(error, {
    notFoundMessage: t('errors.requested_provinces_not_found'),
  });
  const {data: countries, error: countriesError} = useQuery('locations', fetchCountries, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * COUNTRIES_CACHE_TIME_MIN,
    staleTime: 1000 * 60 * COUNTRIES_STALE_TIME_MIN,
  });
  useErrorToast(countriesError, {
    notFoundMessage: t('errors.requested_countries_not_found'),
  });

  const countriesOptions = React.useMemo(() => {
    return getCountriesAsOptions(countries);
  }, [countries]);

  React.useEffect(
    function preloadFormData() {
      if (!billing) {
        return;
      }

      const countryCode = getCountryCode(billing);
      const formData = [
        {[FORM_NAMES.name]: billing?.name},
        {[FORM_NAMES.address_line_1]: billing?.address?.line1},
        {[FORM_NAMES.address_line_2]: billing?.address?.line2},
        {[FORM_NAMES.postal_code]: billing?.address?.postal_code},
        {[FORM_NAMES.city]: billing?.address?.city},
        {[FORM_NAMES.tax_value]: getInitTaxValue(billing?.tax_id)},
        {[FORM_NAMES.tax_id]: getInitTaxIdOption(billing?.tax_id)},
        {
          [FORM_NAMES.country]: getInitCountryOption(countryCode, countriesOptions),
        },
      ];

      setValue(formData);
      setIsCountryPreloaded(true);
    },
    [countriesOptions, billing, setValue],
  );

  React.useEffect(
    function preloadProvince() {
      const canPreloadProvince =
        !isProvincePreloaded && isCountryPreloaded && provinces && billing;

      if (canPreloadProvince) {
        const nextProvince = getProvincesAsOptions(provinces).find(
          (option: SelectOption) => {
            return option.value === getProvinceCode(billing);
          },
        );

        setValue(FORM_NAMES.state, nextProvince);
        setIsProvincePreloaded(true);
      }
    },
    [billing, isCountryPreloaded, isProvincePreloaded, provinces, setValue],
  );

  React.useEffect(() => {
    if (ws.message?.event_type === WS_EVENT_TYPES.subscriptionUpdated) {
      queryCache.refetchQueries('billingDetails');
    }

    return () => ws.clearMessage();
  }, [ws]);

  React.useEffect(() => {
    if (formState.isSubmitted) {
      triggerValidation();
    }
  }, [fields, formState.isSubmitted, triggerValidation]);

  const getIsTaxValueValid = () => {
    if (!taxId || !taxValue) {
      return true;
    }
    return Boolean(taxValue.toUpperCase().match(TAX_REGEX_VALUES[taxId]));
  };

  const getPayload = (formData: FormTypes) => {
    const taxIdPayload =
      taxId && taxValue
        ? {
            type: TAX_TYPE,
            value: `${(formData[FORM_NAMES.tax_id] as SelectOption)?.value}${(formData[
              FORM_NAMES.tax_value
            ] as string).toUpperCase()}`,
          }
        : null;

    return {
      name: formData[FORM_NAMES.name],
      address: {
        line1: formData[FORM_NAMES.address_line_1] || '',
        line2: formData[FORM_NAMES.address_line_2] || '',
        country: country || undefined,
        city: formData[FORM_NAMES.city] || '',
        postal_code: formData[FORM_NAMES.postal_code],
        state: (formData[FORM_NAMES.state] as SelectOption)?.value,
      },
      tax_id: taxIdPayload,
    };
  };

  const onSubmit = async (formData: FormTypes) => {
    if (!getIsTaxValueValid()) {
      toast.error(addSupportEmailToMessage(t('errors.invalid_tax_value')));
      return;
    }

    const payload = getPayload(formData);

    setIsUpdating(true);
    const {data, error} = await api.payments.patchBillingDetails(payload);

    if (!isMounted.current) {
      return;
    }

    if (data) {
      await queryCache.refetchQueries('billingDetails');
      toast.success(t('billing_details_updated'));
    }
    if (error) {
      toastResponseError(error);
    }

    setIsUpdating(false);
  };

  return (
    <Content>
      <HeaderText>{t('billing_details')}</HeaderText>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <FormPartWrapper>
            <FormItemWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.name],
                })}
                label={getRequiredOrOptionalFieldLabel(
                  t('commercial_name'),
                  fields.required[FORM_NAMES.name],
                )}
                name={FORM_NAMES.name}
                error={errors[FORM_NAMES.name]?.message}
                placeholder={t('enter_name')}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <FormTextInfo>{t('tax_id')}</FormTextInfo>
            </FormItemWrapper>
            <FormItemWrapper>
              <Controller
                as={<Select />}
                control={control}
                rules={{
                  required: fields.required[FORM_NAMES.tax_id],
                }}
                label={getRequiredOrOptionalFieldLabel(
                  t('id_type'),
                  fields.required[FORM_NAMES.tax_id],
                )}
                name={FORM_NAMES.tax_id}
                options={TAX_COUNTRIES}
                error={(errors[FORM_NAMES.tax_id] as any)?.message}
                placeholder={t('enter_type')}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.name],
                })}
                label={getRequiredOrOptionalFieldLabel(
                  t('fiscal_code'),
                  fields.required[FORM_NAMES.tax_value],
                )}
                name={FORM_NAMES.tax_value}
                inputMode="numeric"
                error={errors[FORM_NAMES.tax_value]?.message}
                placeholder={t('enter_code')}
              />
            </FormItemWrapper>
          </FormPartWrapper>
          <FormPartWrapper>
            <FormItemWrapper>
              <FormTextInfo>{t('invoicing_address')}</FormTextInfo>
            </FormItemWrapper>
            <FormItemWrapper>
              <Controller
                as={<Select />}
                control={control}
                rules={{required: fields.required[FORM_NAMES.country]}}
                label={getRequiredOrOptionalFieldLabel(
                  t('country'),
                  fields.required[FORM_NAMES.country],
                )}
                name={FORM_NAMES.country}
                options={countriesOptions}
                error={(errors[FORM_NAMES.country] as any)?.message}
                placeholder={t('enter_country')}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.address_line_1],
                })}
                label={getRequiredOrOptionalFieldLabel(
                  t('street_1'),
                  fields.required[FORM_NAMES.address_line_1],
                )}
                name={FORM_NAMES.address_line_1}
                error={errors[FORM_NAMES.address_line_1]?.message}
                placeholder={t('enter_street')}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.address_line_2],
                })}
                label={getRequiredOrOptionalFieldLabel(
                  t('street_2'),
                  fields.required[FORM_NAMES.address_line_2],
                )}
                name={FORM_NAMES.address_line_2}
                error={errors[FORM_NAMES.address_line_2]?.message}
                placeholder={t('enter_street')}
              />
            </FormItemWrapper>
          </FormPartWrapper>
          <FormPartWrapper>
            <FormItemWrapper>
              <CityFormItemWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.city],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('city'),
                    fields.required[FORM_NAMES.city],
                  )}
                  name={FORM_NAMES.city}
                  error={errors[FORM_NAMES.city]?.message}
                  placeholder={t('enter_city')}
                />
              </CityFormItemWrapper>
            </FormItemWrapper>

            <FormItemWrapper>
              <Controller
                as={<Select />}
                control={control}
                rules={{required: fields.required[FORM_NAMES.state]}}
                label={getRequiredOrOptionalFieldLabel(
                  t('province'),
                  fields.required[FORM_NAMES.state],
                )}
                options={getProvincesAsOptions(provinces, country)}
                name={FORM_NAMES.state}
                error={(errors[FORM_NAMES.state] as any)?.message}
                placeholder={t('enter_province')}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.postal_code],
                })}
                label={getRequiredOrOptionalFieldLabel(
                  t('postal_code'),
                  fields.required[FORM_NAMES.postal_code],
                )}
                name={FORM_NAMES.postal_code}
                inputMode="numeric"
                error={errors[FORM_NAMES.postal_code]?.message}
                placeholder={t('enter_postal_code')}
              />
            </FormItemWrapper>
          </FormPartWrapper>
        </FormWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader width={30} height={30} />
          </LoaderWrapper>
        ) : (
          <SubmitButtonWrapper>
            <Button
              secondary
              type="submit"
              disabled={isUpdating}
              label={
                <ButtonLabelWrapper>
                  <ButtonLabelText>{t('save_billing_info')}</ButtonLabelText>
                </ButtonLabelWrapper>
              }
            />
          </SubmitButtonWrapper>
        )}
      </Form>
    </Content>
  );
}

export {BillingDetailsSection};
