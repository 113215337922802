import React from 'react';
import Loader from '../../common/Loader';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {CardElement, Elements, injectStripe} from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';
import {useQuery} from 'react-query';
import {useUser} from '../../../context/user';
import api, {queryFetcher} from '../../../api';
import closeIcon from '../../../assets/close.svg';
import checkIcon from '../../../assets/green-check.svg';
import {useTranslation} from 'react-i18next';
import {useSubscription} from '../../../context/subscription';
import {useErrorToast, useIsMounted} from '../../../utils/hooks';
import {toastResponseError} from '../../../utils/common';
import type {Coupon, Plan, PlanTotalPrice} from '../../../utils/types';
import {PaymentMethod} from '../../../utils/types';
import {PERIODICITY, SUBSCRIPTION_TYPES} from '../../../utils/constants';
import BackButton from '../BackButton';
import CardPlaceholder from '../CardPlaceholder';
import ModalButton from '../ModalButton';
import {Heading} from '../../../styled/common';
import {
  BoldText,
  ButtonWrapper,
  CancelChangingWrapper,
  CardForm,
  CardInfoWrapper,
  ChangeCardWrapper,
  CheckImg,
  Content,
  CouponAppliedText,
  CouponButton,
  CouponHeader,
  CouponHelper,
  CouponInput,
  CouponInputWrapper,
  CouponWrapper,
  DiscountText,
  Dot,
  DotsWrapper,
  GreyText,
  Header,
  LoaderWrapper,
  PriceValue,
  ResetCouponImg,
  stripeInputStyle,
  SubHeader,
  TextWrapper,
  Tip,
} from './styled';

function fetchPaymentMethod() {
  return queryFetcher(api.payments.ENDPOINTS.paymentMethod());
}

type LocationState = {
  accommodationsNumber?: string;
  plan?: Plan;
  price?: PlanTotalPrice;
};

const CARD_BINDING_SUCCESS_STATUS = 'ok';

function SubscriptionPaymentStep({stripe, elements}: any) {
  const {t} = useTranslation();
  const user = useUser();
  const isMounted = useIsMounted();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const {
    subscription,
    isSubscriptionCanceled,
    inactiveSubscriptions,
    subscribeAndSetSubscription,
    updateAndSetSubscription,
    hasSubscriptionPlan,
  } = useSubscription();
  const [stripeSecret, setStripeSecret] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCardComplete, setIsCardComplete] = React.useState(false);
  const [isCardReady, setIsCardReady] = React.useState(false);
  const [enteredCoupon, setEnteredCoupon] = React.useState('');
  const [coupon, setCoupon] = React.useState<Coupon | null>(null);
  const [validatingCoupon, setValidatingCoupon] = React.useState(false);
  const [subscriptionType, setSubscriptionType] = React.useState<string | undefined>('');
  const [isChangingCreditCard, setIsChangingCreditCard] = React.useState(false);

  const {
    data: paymentMethod,
    error: paymentMethodError,
    status: paymentMethodStatus,
  } = useQuery<PaymentMethod, string>('paymentMethod', fetchPaymentMethod, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(paymentMethodError);

  const hasCard = paymentMethodStatus !== 'loading' && paymentMethod?.card;
  const accommodationsNumber = location.state?.accommodationsNumber;

  React.useEffect(() => {
    if (!accommodationsNumber) {
      history.replace('/subscription/number');
    }
    if (!location.state?.plan) {
      history.replace('/subscription/select-plan');
    }
  }, [location.state, history, accommodationsNumber]);

  React.useEffect(() => {
    if (hasCard) {
      setIsCardReady(false);
      setIsCardComplete(false);
    }
  }, [hasCard]);

  React.useEffect(() => {
    async function fetchStripeSecret() {
      setIsLoading(true);
      const {data, error} = await api.payments.getSecret();

      if (!isMounted.current) {
        return;
      }

      if (error) {
        toastResponseError(error);
      }
      if (data) {
        setStripeSecret(data.secret);
      }

      setIsLoading(false);
    }

    fetchStripeSecret();
  }, [isMounted]);

  React.useEffect(() => {
    setSubscriptionType(user?.subscription_type);
  }, [user]);

  const handleCardChange = (event: any) => {
    setIsCardComplete(event.complete);
  };

  const handleCardReady = (element: any) => {
    setIsCardReady(true);
    element.focus();
  };

  const getPersistedState = () => {
    return location.state;
  };

  const goNext = () => {
    const persistedState = getPersistedState();
    history.push('/subscription/payment-info', persistedState);
  };

  const goBack = () => {
    history.push('/subscription/select-plan', location.state);
  };

  const toggleCardChanging = () => {
    setIsChangingCreditCard((prevState) => {
      if (prevState) {
        setIsCardReady(false);
        setIsCardComplete(false);
      }

      return !prevState;
    });
  };

  const getPrice = () => {
    const price = location?.state?.price?.price_with_tax;
    const isYearlyInterval = location.state?.plan?.interval === PERIODICITY.yearly;

    if (!price) {
      return 0;
    }

    if (isYearlyInterval) {
      return (price / 12 / Number(accommodationsNumber)!).toFixed(2);
    }

    return (price / Number(accommodationsNumber)!).toFixed(2);
  };

  const getFinishedPrice = () => {
    if (location.state?.price) {
      let priceWithTax = location.state.price.price_with_tax;

      if (coupon) {
        return (priceWithTax * (1 - coupon.percent_off / 100)).toFixed(2);
      } else {
        return priceWithTax;
      }
    } else {
      return 0.0;
    }
  };

  const getMonthlyLabel = () => {
    return subscriptionType === SUBSCRIPTION_TYPES.hotel
      ? t('every_months_per_number_rooms', {number: accommodationsNumber})
      : t('every_months_per_number_properties', {number: accommodationsNumber});
  };

  const getYearlyLabel = () => {
    return subscriptionType === SUBSCRIPTION_TYPES.hotel
      ? t('every_12_months_per_number_rooms', {number: accommodationsNumber})
      : t('every_12_months_per_number_properties', {number: accommodationsNumber});
  };

  const handleCouponInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {target} = event;
    setEnteredCoupon(target.value);
  };

  const validateCoupon = async () => {
    setValidatingCoupon(true);
    const {error, data} = await api.payments.getOneCoupon(enteredCoupon);
    if (data) {
      setCoupon(data);
    }
    if (error) {
      toastResponseError(error);
    }
    setValidatingCoupon(false);
  };

  const resetCoupon = () => {
    setEnteredCoupon('');
    setCoupon(null);
  };

  const getCardElement = () => {
    let cardElement = null;
    try {
      cardElement = elements.getElement('card');
    } catch (error) {
      Sentry.captureException(error);
      toastResponseError(error);
    }
    return cardElement;
  };

  const setupCard = async () => {
    const cardElement = getCardElement();
    if (!cardElement) {
      return;
    }

    const {setupIntent, error} = await stripe.confirmCardSetup(stripeSecret, {
      payment_method: {
        card: cardElement,
      },
    });
    if (error) {
      setIsLoading(false);
      toastResponseError(error);
    }
    return {setupIntent, error};
  };

  const bindCardToCustomer = async ({payment_method = {}}) => {
    let bindingStatus = '';
    const {data, error} = await api.payments.bindUserCard({payment_method});
    if (data) {
      bindingStatus = data.status;
    }
    if (error) {
      toastResponseError(error);
    }
    return bindingStatus;
  };

  const setupAndBindCardToCustomer = async () => {
    setIsLoading(true);
    let bindingStatus = '';

    const setupCardResult = await setupCard();
    if (setupCardResult) {
      if (!setupCardResult.error && isMounted.current) {
        bindingStatus = await bindCardToCustomer(setupCardResult.setupIntent);
        return bindingStatus;
      }
    }
    return bindingStatus;
  };

  const getSubscriptionPayload = () => {
    let type = subscription?.type;
    if (!type || (isSubscriptionCanceled && inactiveSubscriptions.length)) {
      type = inactiveSubscriptions[0].type;
    }

    return {
      type,
      coupon: coupon?.coupon || undefined,
      max_quantity: accommodationsNumber,
      plan: location.state?.plan?.unique_id,
    };
  };

  const updateSubscription = async () => {
    setIsLoading(true);

    const payload = getSubscriptionPayload();
    const {error} = await updateAndSetSubscription(subscription!.stripe_id, payload);

    if (error) {
      setIsLoading(false);
      toastResponseError(error);
    } else {
      goNext();
    }
  };

  const createSubscription = async () => {
    setIsLoading(true);

    const subscriptionPayload = getSubscriptionPayload();
    const {error} = await subscribeAndSetSubscription(subscriptionPayload);

    if (error) {
      setIsLoading(false);
      toastResponseError(error);
    } else {
      goNext();
    }
  };

  const subscribe = async () => {
    if (hasSubscriptionPlan) {
      await updateSubscription();
    } else {
      await createSubscription();
    }
  };

  const bindCardAndSubscribe = async () => {
    const cardBindingStatus = await setupAndBindCardToCustomer();

    if (cardBindingStatus === CARD_BINDING_SUCCESS_STATUS && isMounted.current) {
      await subscribe();
    }
  };

  const handleExistingCardSubmit = () => {
    subscribe();
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    await bindCardAndSubscribe();
  };

  return (
    <Content>
      <Heading>
        <BackButton onClick={goBack} />
        <Header>{t('subscription')}</Header>
      </Heading>
      <SubHeader>{t('payment')}</SubHeader>
      <Tip>{t('you_can_unsubscribe_at_any_moment')}</Tip>
      <SubHeader>{t('payment_card_details')}</SubHeader>
      {hasCard && !isChangingCreditCard && (
        <div>
          <CardPlaceholder />
          <ChangeCardWrapper>
            <ModalButton
              secondary
              disabled={isLoading}
              label={t('change_card')}
              onClick={toggleCardChanging}
            />
          </ChangeCardWrapper>
          {isLoading || paymentMethodStatus === 'loading' ? (
            <LoaderWrapper>
              <Loader width={45} height={45} />
            </LoaderWrapper>
          ) : (
            <div>
              <ButtonWrapper>
                <ModalButton
                  onClick={handleExistingCardSubmit}
                  type="submit"
                  label={t('subscribe_now')}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <Link to="/properties">
                  <ModalButton secondary label={t('cancel')} />
                </Link>
              </ButtonWrapper>
            </div>
          )}
        </div>
      )}
      {(!hasCard || isChangingCreditCard) && (
        <CardForm onSubmit={handleSubmit}>
          {paymentMethodStatus !== 'loading' && (
            <CardInfoWrapper>
              <CardElement
                hideIcon
                disabled={isLoading}
                onChange={handleCardChange}
                onReady={handleCardReady}
                style={stripeInputStyle}
              />
            </CardInfoWrapper>
          )}
          {hasCard && (
            <CancelChangingWrapper>
              <ModalButton
                secondary
                disabled={isLoading}
                label={t('cancel_changing')}
                onClick={toggleCardChanging}
              />
            </CancelChangingWrapper>
          )}
          {isLoading || !isCardReady || paymentMethodStatus === 'loading' ? (
            <LoaderWrapper>
              <Loader width={45} height={45} />
            </LoaderWrapper>
          ) : (
            <div>
              <ButtonWrapper>
                <ModalButton
                  disabled={!isCardComplete}
                  type="submit"
                  label={t('subscribe_now')}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <Link to="/properties">
                  <ModalButton secondary label={t('cancel')} />
                </Link>
              </ButtonWrapper>
            </div>
          )}
        </CardForm>
      )}
      <CouponWrapper>
        {location.state?.plan?.interval === PERIODICITY.monthly && (
          <>
            <CouponHeader>{t('do_you_have_a_coupon')}</CouponHeader>
            <CouponInputWrapper>
              <CouponInput
                value={enteredCoupon}
                placeholder={t('enter_coupon')}
                readOnly={Boolean(coupon)}
                onChange={handleCouponInputChange}
              />
              {coupon && (
                <ResetCouponImg onClick={resetCoupon} src={closeIcon} alt="reset" />
              )}
            </CouponInputWrapper>
            <CouponButton
              label={t('apply_coupon')}
              onClick={validateCoupon}
              disabled={!enteredCoupon || validatingCoupon}
            />
            {(accommodationsNumber || 0) > 1 && (
              <DotsWrapper>
                <Dot />
                <Dot />
                <Dot />
              </DotsWrapper>
            )}
          </>
        )}
        {!coupon ? (
          <TextWrapper>
            {(accommodationsNumber || 0) > 1 && (
              <div>
                <BoldText>{getPrice()}€</BoldText>
                <GreyText>
                  /
                  {subscriptionType === SUBSCRIPTION_TYPES.hotel
                    ? t('room')
                    : t('property')}{' '}
                  <div>
                    x {` `}
                    <BoldText>{accommodationsNumber}</BoldText>{' '}
                    <GreyText>
                      {subscriptionType === SUBSCRIPTION_TYPES.hotel
                        ? t('rooms')
                        : t('properties')}{' '}
                    </GreyText>
                  </div>
                  {location.state?.plan?.interval === PERIODICITY.yearly && (
                    <span>
                      <GreyText>x</GreyText> <BoldText>12</BoldText>{' '}
                      <GreyText>{t('months')}</GreyText>
                    </span>
                  )}
                </GreyText>
              </div>
            )}
          </TextWrapper>
        ) : (
          <>
            <CouponAppliedText>
              {t('coupon_applied')} <CheckImg src={checkIcon} alt="Check mark" />
            </CouponAppliedText>
            <DiscountText>
              {coupon.percent_off}% {t('discount').toLowerCase()}
            </DiscountText>
          </>
        )}
        {((location.state?.plan?.interval === PERIODICITY.yearly &&
          (accommodationsNumber || 0) > 1) ||
          location.state?.plan?.interval === PERIODICITY.monthly) && (
          <DotsWrapper>
            <Dot />
            <Dot />
            <Dot />
          </DotsWrapper>
        )}
        <PriceValue>{getFinishedPrice()}€</PriceValue>
        {!coupon ? (
          <GreyText>
            {location.state?.plan?.interval === PERIODICITY.monthly
              ? getMonthlyLabel()
              : getYearlyLabel()}
          </GreyText>
        ) : (
          <>
            <CouponHelper>
              <GreyText>{t('the_first_month')}.</GreyText>
            </CouponHelper>
            <CouponHelper>
              <GreyText>{t('then')}</GreyText> {` `}{' '}
              <BoldText>{location.state?.price?.price_with_tax || 0}€</BoldText>
              <GreyText>/ {t('month')}</GreyText>
            </CouponHelper>
          </>
        )}
      </CouponWrapper>
    </Content>
  );
}

const InjectedSubscriptionPaymentStep = injectStripe(SubscriptionPaymentStep);

function ElementsInjectedSubscriptionPaymentStep() {
  return (
    <Elements>
      <InjectedSubscriptionPaymentStep />
    </Elements>
  );
}

export {ElementsInjectedSubscriptionPaymentStep};
