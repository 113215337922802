import React from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import {useErrorToast, useIsMounted} from '../../../utils/hooks';
import {toastResponseError, getBase64} from '../../../utils/common';
import {useQuery} from 'react-query';
import {SelectOption} from '../../../utils/types';
import {useComputedDetails} from '../../../context/computedDetails';
import {TOAST_AUTO_CLOSE_MS} from '../../../utils/constants';
import BackButton from '../BackButton';
import removeIcon from '../../../assets/icon-remove-gray.svg';
import importWhiteIcon from '../../../assets/import-white.svg';
import directDownloadIcon from '../../../assets/direct-download.svg';
import downloadTemplatesIcon from '../../../assets/import-properties-icon.svg';
import importTemplatesIcon from '../../../assets/upload-properties-icon.svg';
import Select from '../Select';
import Button from '../Button';
import {Heading} from '../../../styled/common';
import {
  ButtonsWrapper,
  ContentItemWrapper,
  ContentTextWrapper,
  FileInputWrapper,
  HeaderText,
  InputWrapper,
  SelectedFileItemRemoveImg,
  SelectedFileItemText,
  SelectedFileItemWrapper,
  SelectedFilesList,
  ToastContent,
  ToastImg,
  ToastText,
  Wrapper,
  DirectDownloadIcon,
  FileInputButton,
  HeadingWrapper,
} from './styled';
import {useOpenModals} from '../../../context/openModals';
import {HOUSING} from '../../../utils/constants';

const COUNTRIES_CACHE_TIME_MIN = 60;
const COUNTRIES_STALE_TIME_MIN = 30;
const IS_UNDER_MAINTENANCE = Boolean(process.env.REACT_APP_MAINTENANCE_MODE);

const ENTITY_TYPE = 'HOUSING';
const DOWNLOAD_TOAST_CONTENT = (
  <ToastContent>
    <ToastImg src={downloadTemplatesIcon} alt="Download icon" />
    <ToastText>{i18n.t('downloading')}</ToastText>
  </ToastContent>
);
const IMPORT_TOAST_CONTENT = (
  <ToastContent>
    <ToastImg src={importTemplatesIcon} alt="Import icon" />
    <ToastText>{i18n.t('importing')}</ToastText>
  </ToastContent>
);

function fetchCountries() {
  return queryFetcher(api.locations.ENDPOINTS.all(`ordering=name`));
}

function getCountriesAsOptions(countries: any) {
  if (!countries?.results) {
    return [];
  }

  return countries?.results?.map((c: any) => {
    return {
      label: c?.country?.name,
      value: c?.country?.code,
    };
  });
}

type TemplateObject = {
  link: string;
};

function ImportHousings() {
  const {t} = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const {isNeedToAskForSubscription} = useComputedDetails();
  const openModals = useOpenModals();
  const [templateCountryCode, setTemplateCountryCode] = React.useState<SelectOption>();
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);

  const {data: countries, error: countriesError} = useQuery('locations', fetchCountries, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * COUNTRIES_CACHE_TIME_MIN,
    staleTime: 1000 * 60 * COUNTRIES_STALE_TIME_MIN,
  });
  useErrorToast(countriesError, {
    notFoundMessage: t('errors.requested_countries_not_found'),
  });

  const countriesOptions = React.useMemo(() => {
    return getCountriesAsOptions(countries);
  }, [countries]);

  React.useEffect(() => {
    if (IS_UNDER_MAINTENANCE) {
      history.push('/housings');
    }
  }, [history]);

  React.useEffect(
    function redirect() {
      if (isNeedToAskForSubscription) {
        history.push('/bookings');
      }
    },
    [history, isNeedToAskForSubscription],
  );

  const handleSelectChange = (option: SelectOption) => {
    setTemplateCountryCode(option);
  };

  const downloadTemplate = async () => {
    const payload = {
      entity_type: ENTITY_TYPE,
      countries: [templateCountryCode?.value],
    };

    toast.info(DOWNLOAD_TOAST_CONTENT, {
      position: 'bottom-right',
      autoClose: TOAST_AUTO_CLOSE_MS,
    });
    const {data, error} = await api.importXLSX.getTemplates(payload);

    if (!isMounted.current) {
      return;
    }

    if (data && data.length) {
      data.map((element: TemplateObject) => (window.location.href = element.link));
    }
    if (error) {
      toastResponseError(error);
    }
  };

  const handleFileSelect = async (event: any) => {
    const {target} = event;
    if (target?.files.length) {
      const nextFiles = [...filesToUpload, ...target.files];
      setFilesToUpload(nextFiles);
    }
  };

  const removeSelectedFile = (fileName: string) => {
    const filteredFiles = filesToUpload.filter((file: File) => {
      return file.name !== fileName;
    });
    setFilesToUpload(filteredFiles);
  };

  const getUploadPayload = async () => {
    let data = [];
    for (let file of filesToUpload) {
      data.push({
        file: await getBase64(file),
        entity_type: ENTITY_TYPE,
      });
    }
    return {requests: data};
  };

  const goToHousings = () => {
    history.push('/properties');
  };

  const uploadFiles = async () => {
    const payload = await getUploadPayload();
    toast.info(IMPORT_TOAST_CONTENT, {
      position: 'bottom-right',
      autoClose: TOAST_AUTO_CLOSE_MS,
    });
    await api.importXLSX.uploadTemplates(payload);
    openModals.setImportType(HOUSING);
    goToHousings();
  };

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading>
          <div>
            <BackButton onClick={goToHousings} />
          </div>
          <HeaderText>{t('import_properties')}</HeaderText>
        </Heading>
      </HeadingWrapper>
      <ContentItemWrapper>
        <ContentTextWrapper>1 - {t('select_the_country_on_which')}</ContentTextWrapper>
        <InputWrapper>
          <Select
            label={t('country')}
            options={countriesOptions}
            onChange={handleSelectChange}
            value={templateCountryCode}
            placeholder={t('select_your_country')}
          />
        </InputWrapper>
      </ContentItemWrapper>
      <ContentItemWrapper>
        <ContentTextWrapper>2 - {t('download_the_template_or')}</ContentTextWrapper>
        <InputWrapper>
          <Button
            secondary
            disabled={!templateCountryCode}
            label={
              <>
                <DirectDownloadIcon src={directDownloadIcon} alt="Arrow down" />
                {t('download_template')}
              </>
            }
            onClick={downloadTemplate}
          />
        </InputWrapper>
      </ContentItemWrapper>
      <ContentItemWrapper>
        <ContentTextWrapper>3 - {t('upload_the_same_template')}</ContentTextWrapper>
        <InputWrapper>
          {Boolean(filesToUpload.length) && (
            <SelectedFilesList>
              {filesToUpload.map((file, index) => {
                return (
                  <SelectedFileItemWrapper key={index}>
                    <SelectedFileItemText>{file.name}</SelectedFileItemText>
                    <SelectedFileItemRemoveImg
                      onClick={() => removeSelectedFile(file.name)}
                      src={removeIcon}
                    />
                  </SelectedFileItemWrapper>
                );
              })}
            </SelectedFilesList>
          )}
          <ButtonsWrapper>
            {Boolean(filesToUpload.length) && (
              <FileInputWrapper>
                <Button
                  onClick={uploadFiles}
                  disabled={!filesToUpload.length}
                  label={
                    <>
                      <img src={importWhiteIcon} alt="Arrow right" />
                      {t('import_properties_now')}
                    </>
                  }
                />
              </FileInputWrapper>
            )}
            <FileInputButton
              multiple
              secondary
              accept=".xlsx, .xls, .csv"
              label={t('upload_templates')}
              onChange={handleFileSelect}
            />
          </ButtonsWrapper>
        </InputWrapper>
      </ContentItemWrapper>
    </Wrapper>
  );
}

export {ImportHousings};
