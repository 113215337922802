import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import SubscriptionPaymentInfoStep from '../components/dashboard/SubscriptionPaymentInfoStep';

function SubscriptionPaymentInfo() {
  return (
    <>
      <TrialHeader />
      <Header />
      <SubscriptionPaymentInfoStep />
    </>
  );
}

export {SubscriptionPaymentInfo};
