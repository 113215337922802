import styled, {css} from 'styled-components';

type StyledButtonProps = {
  active?: boolean;
  disabled?: boolean;
};

export const StyledButton = styled.button<StyledButtonProps>`
  height: 40px;
  background-color: #f8f8f8;
  min-width: 117px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #9696b9;
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  color: #9696b9;
  text-align: left;
  padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  user-select: none;
  transition: all 0.07s ease-in-out;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        opacity: 0.88;
        box-shadow: 0 4px 4px #2699fb1a;
      }

      &:active {
        opacity: 1;
      }
    `};

  ${(props) =>
    props.active &&
    css`
      background-color: #ffffff;
      border-color: #1a8cff;
      color: #161643;
    `};
`;

export const Label = styled.div`
  height: 18px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export const Selector = styled.div`
  width: 14px;
  height: 14px;
  background: #ffffff;
  box-shadow: 0 3px 3px #2699fb12;
  border: 1px solid #9696b9;
  border-radius: 100px;
  position: relative;
  margin-right: 8px;
`;

export const FillSelector = styled.div`
  width: 12px;
  height: 12px;
  background: #1a8cff;
  border-radius: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
