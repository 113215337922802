import styled, {css} from 'styled-components';
import {RESERVATION_STATUSES} from './ReservationsTable';
import {device} from '../../../styled/device';
import HeaderItem from '../HeaderItem';

export const Content = styled.div`
  max-width: 1040px;
  padding: 0 20px;
  margin: 20px auto 76px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`;

type TableWrapperProps = {
  hasLastRowBorder?: boolean;
};

export const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;
  margin: 20px auto 0;
  box-shadow: 0 5px 5px #2699fb1a;

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    & > thead {
      cursor: default;
      background-color: #cbe9ff;
      text-transform: uppercase;
      font-family: ProximaNova-Bold, sans-serif;
      font-size: 10px;
      color: #161643;

      & > tr {
        height: 60px;
        text-align: left;

        & th:nth-child(1) {
          padding-left: 14px;
        }

        & th:nth-child(3) {
          min-width: 105px;
        }

        & th:last-child {
          width: 35%;
        }
      }
    }

    & > tbody > tr {
      font-family: ProximaNova-Semibold, sans-serif;
      font-size: 14px;
      color: #161643;
      height: 60px;
      border-bottom: 1px solid #f0f0f3;
      cursor: pointer;
      transition: background-color 0.07s ease-in-out;

      &:hover {
        background-color: #f7fbff;
      }

      & td:nth-child(1) {
        padding-left: 14px;
      }

      &:last-child {
        border-bottom: ${(props) => !props.hasLastRowBorder && 'none'};
      }
    }
  }
`;

export const StatusButtonCheckMarkIcon = styled.img`
  margin-left: 13px;
  vertical-align: middle;
  width: 12px;
  margin-top: -2px;
  margin-right: -1px;
`;

export const StatusButtonClockIcon = styled.img`
  margin-left: 4px;
  vertical-align: middle;
  height: 11px;
  margin-top: -3px;
`;

export const ReservationTableHeaderItem = styled(HeaderItem)`
  border-right: 1px solid #d8d9e6;
`;

export const HeadingButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  height: 30px;
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  padding: 1px 0 0 16px;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;

  &:hover > img {
    box-shadow: 0 3px 3px #0f477734;
  }

  &:active > img {
    opacity: 0.95;
  }

  & > img {
    border-radius: 3px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    transition: box-shadow 0.15s ease-in-out;
  }
`;

type StatusButtonProps = {
  status: string;
};

export const StatusButton = styled.button<StatusButtonProps>`
  border-radius: 2px;
  padding: 3px 6px;
  box-sizing: border-box;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 14px;
  border: 1px solid;
  text-transform: capitalize;
  outline: none;
  cursor: default;
  ${(props) => getStatusButtonStyle(props)}
`;

function getStatusButtonStyle({status}: StatusButtonProps) {
  switch (status) {
    case RESERVATION_STATUSES.complete: {
      return css`
        color: #161643;
        border-color: #71c3ff;
        background-color: #cbe9ff;
      `;
    }
    case RESERVATION_STATUSES.incomplete:
    default: {
      return css`
        color: #adadcc;
        border-color: #9696b9;
        background-color: #f8f8f8;
      `;
    }
  }
}

export const TableHeader = styled.div`
  display: inline-flex;
  align-items: center;
`;

type StatusTooltipProps = {
  open: boolean;
};

export const StatusTooltip = styled.div<StatusTooltipProps>`
  position: absolute;
  cursor: default;
  right: 0;
  width: 258px;
  top: 0;
  padding: 35px 36px 32px;
  box-shadow: 0 30px 30px #2699fb1a;
  box-sizing: border-box;
  background-color: white;
  border-radius: 6px;
  font-size: 14px;
  font-family: ProximaNova-Regular, sans-serif;
  z-index: 999;
  color: #161643;
  transition: opacity 0.15s ease-in-out;
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => !props.open && 'none'};
`;

export const StatusCell = styled.div`
  position: relative;
  cursor: pointer;
`;

export const StatusText = styled.div`
  font-size: 16px;
  font-family: ProximaNova-Regular, sans-serif;
  color: #161643;
  margin-bottom: 16px;

  & > b {
    font-weight: normal;
    font-family: ProximaNova-Bold, sans-serif;
    color: #161643;
    display: block;
    margin-bottom: 7px;
  }
`;

export const HeadingSection = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 10px;
  }
`;

export const ActionSection = styled.div`
  display: flex;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

export const RefreshButtonLoaderWrapper = styled.div`
  margin-left: 5px;
  width: inherit;
`;

export const HeadingRefreshButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  height: 20px;
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  padding: 1px 0 0 16px;
  display: inline-flex;
  vertical-align: middle;
  width: 95px;

  &:hover > img {
    box-shadow: 0 3px 3px #0f477734;
  }

  &:active > img {
    opacity: 0.95;
  }

  & > img {
    border-radius: 3px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    transition: box-shadow 0.15s ease-in-out;
  }
`;

export const ThreeDotsImg = styled.img`
  width: 20px;
  height: 20px;
`;
