import styled, {css} from 'styled-components';

type WrapperProps = {
  disabled?: boolean;
  invalid?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 471px;
  min-height: 47px;

  & .select {
    min-height: 49px !important;
  }

  & .select__control {
    border: none !important;
    cursor: pointer !important;
    background-color: white !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    height: 100% !important;
    transition: background-color 0.5s ease-in-out, border-color 0.05s ease-in-out !important;
    border-bottom: 1px solid ${(props) => (props.invalid ? '#FF2467' : '#e2e7ef')} !important;
  }

  & .select__single-value,
  & .select__placeholder {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 16px !important;
    padding-top: 20px !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    color: ${(props) => (props.invalid ? '#FF2467' : '#161643')} !important;
  }

  & .select__placeholder {
    color: ${(props) => (props.invalid ? '#FF2467' : '#9696B9')} !important;
  }

  & .select__input,
  & .select__input input {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 16px !important;
    color: #161643 !important;
    margin: 0 !important;
  }

  & .select__input input {
    width: 200px !important;
    position: relative !important;
    top: -3px !important;
  }

  & .select__input {
    position: relative !important;
    top: 0 !important;
  }

  & .select__indicator-separator {
    display: none;
  }

  & .select__dropdown-indicator {
    display: none !important;
  }

  & .select__value-container {
    padding-top: 22px !important;
    padding-left: 0 !important;
  }

  & .select__value-container > div {
    padding-bottom: 0 !important;
    margin: 0 12px 0 0 !important;
  }

  & .select__value-container :last-child,
  & .select__input {
    padding: 0 !important;
    margin: 0 0 1px 0 !important;
  }

  & .select__option.select__option--is-selected {
    background-color: white !important;
    font-family: ProximaNova-Bold, sans-serif !important;
  }

  & .select__option.select__option--is-focused.select__option--is-selected {
    cursor: default !important;
    font-family: ProximaNova-Bold, sans-serif !important;
    color: #161643 !important;
  }

  & .select__option.select__option--is-focused {
    cursor: pointer !important;
    color: #1a8cff !important;
  }

  & .select__menu {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 16px !important;
    width: 100% !important;
    height: auto !important;
    min-height: 75px !important;
    max-height: 322px !important;
    box-shadow: 0 30px 30px #2699fb1a !important;
    border-radius: 0 0 8px 8px !important;
    z-index: 2 !important;
    margin-top: 0;
  }

  & .select__menu-notice {
    color: #161643 !important;
    text-align: left !important;
    margin-top: 10px !important;
    font-size: 16px !important;
  }

  & .select__menu-list {
    padding: 17px 27px 19px 16px !important;
    box-shadow: 0 30px 30px #2699fb1a !important;
    width: 100% !important;
    height: auto !important;
    min-height: 75px !important;
    max-height: 322px !important;
    border-radius: 0 0 8px 8px !important;
  }

  & .select__option {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 16px !important;
    text-align: left !important;
    color: #161643 !important;
    padding: 18px 0 19px 16px !important;
    box-sizing: border-box !important;
    background-color: white !important;
    border-bottom: 1px solid #f2f4f8 !important;
  }

  & .select__option:last-child {
    border-bottom-color: transparent !important;
  }

  & .select__multi-value {
    background-color: transparent;
  }

  & .select__multi-value__label {
    font-family: ProximaNova-Medium, sans-serif !important;
    font-size: 16px !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 6px !important;
    color: ${(props) => (props.invalid ? '#FF2467' : '#161643')} !important;
  }

  & .select__multi-value__remove:hover {
    background: transparent;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
    `};
`;

export const Label = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 14px;
  text-align: left;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  color: #1a8cff;
`;

export const ClearIcon = styled.img`
  height: 15px;
  width: 15px;
  border-radius: 3px;
  cursor: pointer;

  &:active {
    opacity: 0.95;
  }

  &:hover {
    box-shadow: 0 3px 3px #0f477734;
    transition: box-shadow 0.15s ease-in-out;
  }
`;
