import styled from 'styled-components';
import Button from '../Button';

export const Content = styled.main`
  width: 470px;
`;

export const SeeMoreButton = styled(Button)`
  justify-content: center;
  min-width: 114px;
  margin-top: 34px;
`;
