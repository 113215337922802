import {DIVISION_LVL_1_COUNTRIES} from './constants';
import {Housing, SelectOption, ShortHousing} from './types';
import {FORM_NAMES as POLICE_FORM_NAMES} from '../components/dashboard/HousingPoliceConnectionSection';
import {FORM_NAMES as STAT_FORM_NAMES} from '../components/dashboard/HousingStatConnectionSection';
import {FORM_NAMES as ICALS_FORM_NAMES} from '../components/dashboard/HousingICalsSection';
import {FORM_NAMES} from '../components/dashboard/AddHousingSections';
import {HousingOption} from '../components/dashboard/ReservationInfoSection/ReservationInfoSection';

type ExternalHousing = {
  subhouse_code: string;
  subhouse_name: string;
};

function getExternalHousingsAsOptions(data: ExternalHousing[]) {
  return data.map((h) => {
    return {
      value: h.subhouse_code,
      label: h.subhouse_name,
    };
  });
}

function getOneHousingAsOption(housing: ShortHousing) {
  return {
    label: housing.name,
    value: housing.id,
    country: getCountryCode(housing),
    data: housing,
  };
}

function getShortHousingsAsOptions(shortHousings?: ShortHousing[]): HousingOption[] {
  if (!shortHousings || !Array.isArray(shortHousings)) {
    return [];
  }

  return shortHousings.map(getOneHousingAsOption);
}

function getCountryCode(housing: any): string {
  return housing?.location?.country?.code || '';
}

function getName(housing: any) {
  return housing?.name || '';
}

function getStatType(housing?: any): string {
  return housing?.stat_account?.type || '';
}

function getCountryAsOption(housing: any) {
  return {
    value: housing?.location?.country?.code,
    label: housing?.location?.country?.name,
  };
}

function getProvinceCode(housing: any) {
  const countryCode = getCountryCode(housing);

  if (DIVISION_LVL_1_COUNTRIES.includes(countryCode)) {
    return housing?.location?.details?.division_level_1?.code;
  }
  return housing?.location?.details?.division_level_2?.code;
}

function buildPolicePayload(data: any) {
  const policeAccount = {
    ...data,
    [POLICE_FORM_NAMES.external_housing]: undefined,
    external_id:
      (data[POLICE_FORM_NAMES.external_housing] as SelectOption)?.value ||
      data[POLICE_FORM_NAMES.external_id],
    external_name: (data[POLICE_FORM_NAMES.external_housing] as SelectOption)?.label,
    [POLICE_FORM_NAMES.type]: (data[POLICE_FORM_NAMES.type] as SelectOption)?.value,
    [POLICE_FORM_NAMES.type_of_document]: (data[
      POLICE_FORM_NAMES.type_of_document
    ] as SelectOption)?.value,
  };

  return {
    is_auto_police_registration_enabled: true,
    police_account: policeAccount,
  };
}

function buildStatPayload(data: any) {
  const statAccount = {
    external_housing_id: (data[STAT_FORM_NAMES.external_housing] as SelectOption)?.value,
    [STAT_FORM_NAMES.type]: (data[STAT_FORM_NAMES.type] as SelectOption)?.value,
    [STAT_FORM_NAMES.location]: (data[STAT_FORM_NAMES.location] as SelectOption)?.value,
    [STAT_FORM_NAMES.username]: data[STAT_FORM_NAMES.username],
    [STAT_FORM_NAMES.password]: data[STAT_FORM_NAMES.password],
  };

  return {
    stat_account: statAccount,
    is_stat_registration_enabled: true,
    [STAT_FORM_NAMES.room_quantity]: data[STAT_FORM_NAMES.room_quantity],
    [STAT_FORM_NAMES.beds_quantity]: data[STAT_FORM_NAMES.beds_quantity],
  };
}

function buildExternalSourcesPayload(data: any) {
  const sources: {[key: string]: string} = {
    [ICALS_FORM_NAMES.booking]: data[ICALS_FORM_NAMES.booking],
    [ICALS_FORM_NAMES.airbnb]: data[ICALS_FORM_NAMES.airbnb],
    [ICALS_FORM_NAMES.other]: data[ICALS_FORM_NAMES.other],
  };
  const payload = Object.keys(sources)
    .filter((key) => sources[key])
    .map((key) => {
      return {
        url: sources[key],
        type: key,
      };
    });

  return {
    external_sources: payload,
  };
}

function buildHousingPayload(data: any, country: string) {
  const divisionLvl = DIVISION_LVL_1_COUNTRIES.includes(country)
    ? {division_level_1: data[FORM_NAMES.province]?.value}
    : {division_level_2: data[FORM_NAMES.province]?.value};

  const hasLocation =
    data[FORM_NAMES.city] ||
    data[FORM_NAMES.address] ||
    data[FORM_NAMES.country]?.value ||
    data[FORM_NAMES.province]?.value;
  const location = hasLocation
    ? {
        ...divisionLvl,
        [FORM_NAMES.city]: data[FORM_NAMES.city],
        [FORM_NAMES.country]: data[FORM_NAMES.country]?.value,
        [FORM_NAMES.address]: data[FORM_NAMES.address] || undefined,
      }
    : undefined;

  return {
    ...data,
    location,
    type: 'UNK',
    [ICALS_FORM_NAMES.booking]: undefined,
    [ICALS_FORM_NAMES.airbnb]: undefined,
    [ICALS_FORM_NAMES.other]: undefined,
    [FORM_NAMES.city]: undefined,
    [FORM_NAMES.country]: undefined,
    [FORM_NAMES.address]: undefined,
    [FORM_NAMES.province]: undefined,
    [FORM_NAMES.depositAmount]: undefined,
    [FORM_NAMES.manager_name]: data[FORM_NAMES.manager_name] || undefined,
    [FORM_NAMES.email_language]: data[FORM_NAMES.email_language]?.value,
  };
}

function getTimezone(housing?: Housing) {
  return housing?.time_zone || 'UTC';
}

export {
  getTimezone,
  buildExternalSourcesPayload,
  buildPolicePayload,
  buildStatPayload,
  getExternalHousingsAsOptions,
  getProvinceCode,
  getCountryCode,
  buildHousingPayload,
  getName,
  getStatType,
  getCountryAsOption,
  getOneHousingAsOption,
  getShortHousingsAsOptions,
};
