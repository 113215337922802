import React from 'react';
import {default as Content} from '../components/onboarding/PmsFantasticstayOriginRegister';

function PmsFantasticstayOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsFantasticstayOriginRegister};
