import React from 'react';
import {useQuery} from 'react-query';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import api, {queryFetcher} from '../../../api';
import {useErrorToast, useScrollToTop} from '../../../utils/hooks';
import {PaymentsMovements as PaymentsMovementsType} from '../../../utils/types';
import Loader from '../../common/Loader';
import BackButton from '../BackButton';
import PaymentsMovementsGrid from '../PaymentsMovementsGrid';
import {Heading, HeadingTitle, ContentWrapper} from '../../../styled/common';
import {Main, LoaderWrapper} from './styled';

function fetchFullMovements() {
  return queryFetcher(api.paymentsSettings.ENDPOINTS.fullMovements());
}

function PaymentsMovements() {
  useScrollToTop();
  const {t} = useTranslation();
  const {data: movements, status: movementsStatus, error: movementsError} = useQuery<
    PaymentsMovementsType,
    string
  >('paymentsFullMovements', fetchFullMovements);
  useErrorToast(movementsError);

  return (
    <ContentWrapper>
      <Heading>
        <div>
          <Link to="/account">
            <BackButton />
          </Link>
        </div>
        <HeadingTitle>{t('movements')}</HeadingTitle>
      </Heading>
      <Main>
        {movementsStatus === 'loading' ? (
          <LoaderWrapper>
            <Loader label={t('loading')} height={45} width={45} />
          </LoaderWrapper>
        ) : (
          <PaymentsMovementsGrid movements={movements} />
        )}
      </Main>
    </ContentWrapper>
  );
}

export {PaymentsMovements};
