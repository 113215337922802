import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {queryCache} from 'react-query';
import {toast} from 'react-toastify';
import api from '../../../api';
import i18n from '../../../i18n';
import {useStatus} from '../../../utils/hooks';
import {SelectOption} from '../../../utils/types';
import {getRequiredOrOptionalFieldLabel, toastResponseError} from '../../../utils/common';
import {useIsMounted} from '../../../utils/hooks';
import {LOCK_VENDOR_OPTIONS, LOCK_VENDORS} from '../../../utils/constants';
import propertyIcon from '../../../assets/icon-property-key.svg';
import Modal from '../Modal';
import Select from '../Select';
import Input from '../Input';
import ModalButton from '../ModalButton';
import Loader from '../../common/Loader';
import {
  FieldsWrapper,
  Container,
  ButtonsWrapper,
  contentStyle,
  NextButton,
  ManualBoxButton,
  SingleFieldWrapper,
  MarketplaceConnectionText,
  CancelButtonWrapper,
} from './styled';

const vendorOptions = Object.values(LOCK_VENDOR_OPTIONS);
const MARKETPLACE_VENDORS = [LOCK_VENDORS.akiles];

const MARKETPLACE_LINKS: {[key: string]: string} = {
  [LOCK_VENDORS.akiles]: '/marketplace/access-providers/Akiles',
};

enum FORM_NAMES {
  apiKey = 'token',
  accountName = 'account_name',
  username = 'username',
  email = 'email',
  password = 'password',
  oauthCode = 'oauth_code',
  oauthState = 'oauth_state',
}

type FormTypes = {
  [FORM_NAMES.apiKey]: string;
  [FORM_NAMES.accountName]: string;
  [FORM_NAMES.username]: string;
  [FORM_NAMES.email]: string;
  [FORM_NAMES.password]: string;
  [FORM_NAMES.oauthCode]: string;
  [FORM_NAMES.oauthState]: string;
};

const INIT_DISPLAY_FIELDS: {[key: string]: boolean} = {
  [FORM_NAMES.accountName]: true,
  [FORM_NAMES.apiKey]: false,
  [FORM_NAMES.username]: false,
  [FORM_NAMES.email]: false,
  [FORM_NAMES.password]: false,
  [FORM_NAMES.oauthCode]: false,
  [FORM_NAMES.oauthState]: false,
};

function getDisplayFields(provider?: string) {
  switch (provider) {
    case LOCK_VENDORS.nuki:
    case LOCK_VENDORS.homeit:
    case LOCK_VENDORS.keynest: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.apiKey]: true,
      };
    }
    case LOCK_VENDORS.akiles: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.oauthCode]: true,
        [FORM_NAMES.oauthState]: true,
      };
    }
    case LOCK_VENDORS.omnitec: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.username]: true,
        [FORM_NAMES.password]: true,
      };
    }
    case LOCK_VENDORS.keyless: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.username]: true,
        [FORM_NAMES.apiKey]: true,
      };
    }
    case LOCK_VENDORS.keycafe: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.email]: true,
        [FORM_NAMES.apiKey]: true,
      };
    }
    case LOCK_VENDORS.manualBox: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.accountName]: true,
      };
    }
    default: {
      return INIT_DISPLAY_FIELDS;
    }
  }
}

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.accountName]: false,
  [FORM_NAMES.apiKey]: i18n.t('required'),
  [FORM_NAMES.username]: i18n.t('required'),
  [FORM_NAMES.email]: i18n.t('required'),
  [FORM_NAMES.password]: i18n.t('required'),
  [FORM_NAMES.oauthCode]: i18n.t('required'),
  [FORM_NAMES.oauthState]: i18n.t('required'),
};

function getRequiredFields() {
  return INIT_REQUIRED_FIELDS;
}

function getFields(provider?: string) {
  const display = getDisplayFields(provider);
  const required = getRequiredFields();

  return {display, required};
}

function getIsMarketplaceVendor(vendorOption: SelectOption | null) {
  if (!vendorOption) {
    return false;
  }

  return MARKETPLACE_VENDORS.includes(vendorOption.value as LOCK_VENDORS);
}

type SelfCheckinSelectProviderModalProps = {
  open: boolean;
  onClose: () => void;
  onFinish: () => Promise<void>;
};

const defaultProps: SelfCheckinSelectProviderModalProps = {
  open: false,
  onClose: () => {},
  onFinish: async () => {},
};

function SelfCheckinSelectProviderModal({
  open,
  onFinish,
  onClose,
}: SelfCheckinSelectProviderModalProps) {
  const {t} = useTranslation();
  const {isLoading, setStatus} = useStatus();
  const isMounted = useIsMounted();
  const {handleSubmit, register, errors, setValue} = useForm<FormTypes>();
  const [selectedProvider, setSelectedProvider] = React.useState<SelectOption | null>(
    null,
  );
  const [isProviderDetails, setIsProviderDetails] = React.useState(false);
  const [fields, setFields] = React.useState(() => {
    return getFields();
  });

  const isMarketplaceVendor = React.useMemo(() => {
    return getIsMarketplaceVendor(selectedProvider);
  }, [selectedProvider]);

  React.useEffect(() => {
    if (!selectedProvider) {
      return;
    }

    const nextFields = getFields(selectedProvider.value);
    setFields(nextFields);
  }, [selectedProvider]);

  React.useEffect(() => {
    if (!isProviderDetails) {
      return;
    }

    if (selectedProvider?.value === LOCK_VENDORS.manualBox) {
      setValue(FORM_NAMES.accountName, t('manual_box'));
    }
  }, [isProviderDetails, selectedProvider, setValue, t]);

  const handleProviderChange = (option: SelectOption) => {
    setSelectedProvider(option);
  };

  const goToProviderDetails = () => {
    setIsProviderDetails(true);
  };

  const goBackFromProviderDetails = () => {
    setIsProviderDetails(false);

    if (selectedProvider?.value === LOCK_VENDORS.manualBox) {
      setSelectedProvider(null);
    }
  };

  const handleManualBoxAdd = () => {
    setSelectedProvider({
      value: LOCK_VENDORS.manualBox,
      label: LOCK_VENDORS.manualBox,
    });
    goToProviderDetails();
  };

  const goToMarketplace = () => {
    const marketplaceLink = MARKETPLACE_LINKS[selectedProvider!.value];

    if (marketplaceLink) {
      window.open(marketplaceLink);
      onClose();
    } else {
      toast.warn(`Marketplace link is missing for the ${selectedProvider?.label}`);
    }
  };

  const getLockUserPayload = (formData: Partial<FormTypes>) => {
    return {
      ...formData,
      vendor: selectedProvider?.value,
    };
  };

  const createLockUser = async (formData: Partial<FormTypes>) => {
    const payload = getLockUserPayload(formData);
    const {error} = await api.locks.createLockUser(payload);

    if (!isMounted.current) {
      return;
    }

    if (error) {
      toastResponseError(error);
    } else {
      await queryCache.refetchQueries('lockUsers');
    }

    return error;
  };

  const onSubmit = async (formData: Partial<FormTypes>) => {
    setStatus('loading');

    const error = await createLockUser(formData);
    if (!error) {
      await onFinish();
    }

    setStatus('idle');
  };

  return (
    <Modal
      iconSrc={propertyIcon}
      iconAlt="Property with a key"
      iconProps={{height: 84, width: 84}}
      title={t('add_an_account')}
      open={open}
      contentStyle={contentStyle}
    >
      <Container>
        {isProviderDetails && !isMarketplaceVendor && (
          <div>
            <FieldsWrapper>
              {fields.display[FORM_NAMES.apiKey] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.apiKey],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('api_key'),
                      fields.required[FORM_NAMES.apiKey],
                    )}
                    placeholder={t('enter_key')}
                    name={FORM_NAMES.apiKey}
                    error={errors[FORM_NAMES.apiKey]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
              {fields.display[FORM_NAMES.username] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.username],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('username'),
                      fields.required[FORM_NAMES.username],
                    )}
                    placeholder={t('enter_username')}
                    name={FORM_NAMES.username}
                    error={errors[FORM_NAMES.username]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
              {fields.display[FORM_NAMES.email] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.email],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('email'),
                      fields.required[FORM_NAMES.email],
                    )}
                    placeholder={t('enter_email')}
                    name={FORM_NAMES.email}
                    error={errors[FORM_NAMES.email]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
              {fields.display[FORM_NAMES.password] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.password],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('password'),
                      fields.required[FORM_NAMES.password],
                    )}
                    type="password"
                    placeholder={t('enter_password')}
                    name={FORM_NAMES.password}
                    error={errors[FORM_NAMES.password]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
              {fields.display[FORM_NAMES.oauthState] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.oauthState],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('email'),
                      fields.required[FORM_NAMES.oauthState],
                    )}
                    placeholder={t('enter_email')}
                    name={FORM_NAMES.oauthState}
                    error={errors[FORM_NAMES.oauthState]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
              {fields.display[FORM_NAMES.oauthCode] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.oauthCode],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('oauth_code'),
                      fields.required[FORM_NAMES.oauthCode],
                    )}
                    placeholder={t('enter_code')}
                    name={FORM_NAMES.oauthCode}
                    error={errors[FORM_NAMES.oauthCode]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
              {fields.display[FORM_NAMES.accountName] && (
                <SingleFieldWrapper>
                  <Input
                    ref={register({
                      required: fields.required[FORM_NAMES.accountName],
                    })}
                    label={getRequiredOrOptionalFieldLabel(
                      t('account_name'),
                      fields.required[FORM_NAMES.accountName],
                    )}
                    placeholder={t('enter_name')}
                    name={FORM_NAMES.accountName}
                    error={errors[FORM_NAMES.accountName]?.message}
                    disabled={isLoading}
                  />
                </SingleFieldWrapper>
              )}
            </FieldsWrapper>
            <ButtonsWrapper>
              {isLoading ? (
                <Loader height={37} width={37} />
              ) : (
                <>
                  <NextButton
                    visible
                    label={t('next')}
                    onClick={handleSubmit(onSubmit)}
                  />
                  <ModalButton
                    secondary
                    label={t('cancel')}
                    onClick={goBackFromProviderDetails}
                  />
                </>
              )}
            </ButtonsWrapper>
          </div>
        )}
        {isProviderDetails && isMarketplaceVendor && (
          <div>
            <MarketplaceConnectionText>
              {t('link_account_from_marketplace')}
            </MarketplaceConnectionText>
            <NextButton visible label={t('link_my_account')} onClick={goToMarketplace} />
            <CancelButtonWrapper>
              <ModalButton
                secondary
                label={t('cancel')}
                onClick={goBackFromProviderDetails}
              />
            </CancelButtonWrapper>
          </div>
        )}
        {!isProviderDetails && (
          <>
            <FieldsWrapper>
              <Select
                label={t('select_provider')}
                placeholder={t('select_provider')}
                options={vendorOptions}
                value={selectedProvider}
                onChange={handleProviderChange}
              />
            </FieldsWrapper>
            <ManualBoxButton onClick={handleManualBoxAdd}>
              {t('do_you_have_a_manual_box')}
            </ManualBoxButton>
            <ButtonsWrapper>
              <NextButton
                visible={Boolean(selectedProvider)}
                label={t('next')}
                onClick={goToProviderDetails}
              />
              <ModalButton secondary label={t('cancel')} onClick={onClose} />
            </ButtonsWrapper>
          </>
        )}
      </Container>
    </Modal>
  );
}

SelfCheckinSelectProviderModal.defaultProps = defaultProps;
export {SelfCheckinSelectProviderModal};
