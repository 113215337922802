import React from 'react';
import {default as Content} from '../components/onboarding/PmsHostawayOriginRegister';

function PmsHostawayOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsHostawayOriginRegister};
