import styled, {css} from 'styled-components';
import {Status} from '../utils/types';
import Button from '../components/dashboard/Button';
import {TableDivRow} from '../components/dashboard/TablePlaceholder/styled';

export const ErrorMessage = styled.div`
  text-align: right;
  font-family: ProximaNova-Semibold, sans-serif;
  color: #ff2467;
  font-size: 12px;
  margin-top: 3px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 30px;

  &:first-child {
    margin-top: 30px;
  }
`;

export const CapitalizeWrapper = styled.div`
  text-transform: capitalize;
`;

type SaveButtonProps = {
  status: Status;
};

export const SaveButton = styled(Button)<SaveButtonProps>`
  transition: all 0.1s ease-out;

  ${(props) =>
    props.status &&
    css`
      justify-content: center;
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 1;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    `};

  ${(props) =>
    props.status === 'error' &&
    css`
      background-color: #ff2467;
      border-color: #ff2467;
    `};

  &:hover {
    opacity: 1;
  }
`;

export const ResolveButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: #1a8cff;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
`;

export const ModalTwoButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 65px;
  height: 95px;

  & button:first-child {
    margin-bottom: 15px;
  }
`;

export const MissingDataText = styled.div`
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 14px;
  color: #9696b9;
`;

export const TableHeader = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  max-width: 1047px;
  margin: 21px auto 0;
  cursor: default;
  padding: 0 120px 230px;
`;

export const Heading = styled.div`
  width: 100%;
  margin-top: 13px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;

  & > :nth-child(2) {
    text-align: center;
  }

  & > :nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
`;

type DocumentButtonProps = {
  disabled?: boolean;
  blinking?: boolean;
  blinkingBackwards?: boolean;
};
export const DocumentButton = styled.button<DocumentButtonProps>`
  outline: none;
  cursor: pointer;
  width: 61px;
  min-width: 61px;
  height: 22px;
  font-size: 14px;
  border: 1px solid #1a8cff;
  border-radius: 2px;
  background-color: white;
  margin-left: auto;
  color: #161643;
  font-family: ProximaNova-Medium, sans-serif;

  &:hover {
    opacity: 0.78;
    box-shadow: 0 4px 4px #2699fb1a;
  }

  &:active {
    opacity: 1;
  }

  ${(props) =>
    props.disabled &&
    css`
      &:hover {
        opacity: 1;
        box-shadow: none;
      }

      &:active {
        opacity: 1;
      }
    `};

  ${(props) =>
    props.blinking &&
    css`
      &,
      &:hover,
      &:active {
        cursor: progress;
        animation: blink 3s ease-in-out infinite;
        opacity: 1;
      }
    `};

  ${(props) =>
    props.blinkingBackwards &&
    css`
      &,
      &:hover,
      &:active {
        cursor: progress;
        animation: blink-backwards 3s ease-in-out infinite;
        opacity: 1;
      }
    `};

  @keyframes blink {
    0% {
      border-color: #d7d8e4;
    }
    50% {
      border-color: #1a8cff;
    }
    0% {
      border-color: #d7d8e4;
    }
  }

  @keyframes blink-backwards {
    0% {
      border-color: #1a8cff;
    }
    50% {
      border-color: #d7d8e4;
    }
    0% {
      border-color: #1a8cff;
    }
  }
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const TablePlaceholderWrapper = styled.div`
  & ${TableDivRow}:last-child {
    border-bottom: none;
  }
`;

export const HeadingTitle = styled.div`
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
  font-size: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const GreenSectionTag = styled.div`
  display: inline-block;
  background: #35e5bc;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 16px;
  color: #ffffff;
  height: 25px;
  padding: 0 20px;
  line-height: 25px;
  text-align: center;
  vertical-align: middle;
  margin-left: 12px;
`;
