import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useQuery} from 'react-query';
import {getStatTypeIfStatActive} from '../../../utils/reservations';
import {
  getGroupMembersNumber,
  getGuestLeader,
  hasAnyCompletedGuest,
} from '../../../utils/guestGroup';
import {getStatusesDescriptions, STATUS_TYPES} from '../../../utils/statuses';
import type {GuestGroup, Housing, Reservation} from '../../../utils/types';
import {
  COUNTRIES_WITH_POLICE,
  COUNTRIES_WITH_POLICE_ALLOWED_SEND_AFTER_1_DAY,
  COUNTRIES_WITH_STAT,
  POLICE_LIKE_STAT_TYPES,
} from '../../../utils/constants';
import {getTimezoneDate} from '../../../utils/date';
import {getGuestStatStatusInCode} from '../../../utils/guest';
import api, {queryFetcher} from '../../../api';
import {LightReservation} from '../../../utils/types';
import {useErrorToast, useModalControls} from '../../../utils/hooks';
import {getCountryCode, getTimezone} from '../../../utils/housing';
import SendToRegistrationModal from '../SendToRegistrationModal';
import luggageIcon from '../../../assets/luggage-icon.svg';
import keyIcon from '../../../assets/key.svg';
import policemanIcon from '../../../assets/policeman.svg';
import businessPresentationIcon from '../../../assets/business-presentation.svg';
import cardsIcon from '../../../assets/cards.svg';
import closedLock from '../../../assets/closed-padlock.svg';
import openLock from '../../../assets/open-padlock.svg';
import Section from '../Section';
import Loader from '../../common/Loader';
import DepositChargeModals from '../DepositChargeModals';
import {
  CardsIcon,
  BusinessPresentationImage,
  Content,
  GuestsStatusTileTitle,
  KeyImage,
  LoaderWrapper,
  LuggageImage,
  PoliceManImage,
  StatusTileContent,
  StatusTileName,
  StatusTileResolveButton,
  StatusTileSubtitle,
  StatusTileTitle,
  ResolveButton,
  TilesWrapper,
  DepositIcon,
} from './styled';

export enum STATUS_COLORS {
  red = 'red',
  orange = 'orange',
  green = 'green',
  white = 'white',
  none = 'none',
}

function StatusTileLoader() {
  return (
    <LoaderWrapper>
      <Loader height={22} />
    </LoaderWrapper>
  );
}

function fetchGuestGroup(key: string, id: string) {
  return queryFetcher(api.guestGroups.ENDPOINTS.all(id));
}

function fetchHousing(key: string, id = '') {
  return queryFetcher(api.housings.ENDPOINTS.one(id));
}

type StatusTileProps = {
  name: string;
  title: React.ReactNode | JSX.Element | string;
  statusColor?: keyof typeof STATUS_COLORS;
  subtitle?: string | JSX.Element | null;
  Image?: React.ReactNode | JSX.Element;
  ResolveButton?: React.ReactNode | JSX.Element;
  children?: React.ReactNode | JSX.Element;
};

function StatusTile({
  name,
  title,
  subtitle,
  Image,
  ResolveButton,
  statusColor,
  children,
}: StatusTileProps) {
  const displayTitle = Array.isArray(title)
    ? title.map((text, i) => {
        const hasComma = i + 1 < title.length;
        return (
          <React.Fragment key={i}>
            <div>{text}</div>
            {hasComma && ', '}
          </React.Fragment>
        );
      })
    : title;

  return (
    <StatusTileContent>
      <StatusTileName>{name}</StatusTileName>
      {Image}
      {displayTitle && (
        <StatusTileTitle color={statusColor}>
          <b>{displayTitle}</b>
        </StatusTileTitle>
      )}
      {subtitle && <StatusTileSubtitle>{subtitle}</StatusTileSubtitle>}
      {children}
      {ResolveButton}
    </StatusTileContent>
  );
}

type ReservationStatusSectionProps = {
  goToGuest: (id?: string) => void;
  reservation?: LightReservation;
};

function ReservationStatusSection({
  reservation,
  goToGuest,
}: ReservationStatusSectionProps) {
  const {t} = useTranslation();

  const [isPolice, setIsPolice] = React.useState(true);

  const {
    openModal: openSendToRegistrationModal,
    closeModal: closeSendToRegistrationModal,
    isOpen: isSendToRegistrationModalOpen,
  } = useModalControls();
  const {
    openModal: openDepositChargeModals,
    closeModal: closeDepositChargeModals,
    isOpen: isDepositChargeModalsOpen,
  } = useModalControls();

  const housingId = reservation?.housing_id;
  const {data: housing, status: housingStatus, error: housingError} = useQuery<
    Housing,
    [string, string]
  >(Boolean(housingId) && ['housing', housingId!], fetchHousing, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(housingError, {
    notFoundMessage: t('errors.requested_housing_not_found'),
  });

  const guestGroupId = reservation?.guest_group_id;
  const {data: guestGroup, error: guestGroupError, status: guestGroupStatus} = useQuery<
    GuestGroup,
    [string, string]
  >(Boolean(guestGroupId) && ['guestGroup', guestGroupId!], fetchGuestGroup);
  useErrorToast(guestGroupError, {
    notFoundMessage: t('errors.requested_guest_group_not_found'),
  });

  const isPendingSecurityDepositsStatus =
    reservation?.security_deposit?.status !== 'CONFIRMED' &&
    reservation?.security_deposit?.status !== 'RELEASED';

  const isSelfCheckInEnabled = Boolean(housing?.is_self_online_check_in_enabled);
  const statusesDescriptions = React.useMemo(() => {
    return getStatusesDescriptions({
      ...reservation,
      housing,
      guest_group: guestGroup,
    } as Reservation);
  }, [guestGroup, housing, reservation]);

  const getGuestsStatusTitle = () => {
    if (!reservation) {
      return <StatusTileLoader />;
    }

    const groupMembersNumber = getGroupMembersNumber(guestGroup);
    const numberOfGuests = guestGroup?.number_of_guests || 0;

    return (
      <>
        {groupMembersNumber}/<b>{numberOfGuests}</b>
      </>
    );
  };

  const getIsAllGuestsRegistered = () => {
    if (!reservation) {
      return false;
    }

    const groupMembersNumber = getGroupMembersNumber(guestGroup);
    const numberOfGuests = guestGroup?.number_of_guests || 0;
    return groupMembersNumber === numberOfGuests;
  };

  const getGuestStatusColor = () => {
    if (!reservation) {
      return STATUS_COLORS.white;
    }

    if (getIsAllGuestsRegistered()) {
      return STATUS_COLORS.green;
    }
    return STATUS_COLORS.orange;
  };

  const getIsCheckInOnlineCompleted = () => {
    const guestLeader = getGuestLeader(guestGroup);

    if (guestLeader) {
      const isCheckInCompleted =
        guestLeader?.biomatch_doc && guestLeader?.biomatch_selfie;

      if (isCheckInCompleted) {
        return true;
      }
    }
    return false;
  };

  const getIsBiomatchPassed = () => {
    const guestLeader = getGuestLeader(guestGroup);
    return Boolean(guestLeader?.biomatch_passed);
  };

  const getTaxesStatusColor = () => {
    const isPaid = Boolean(reservation?.have_taxes_been_paid);
    if (isPaid) {
      return STATUS_COLORS.green;
    }

    return STATUS_COLORS.orange;
  };

  const getSelfCheckInStatusColor = () => {
    if (!isSelfCheckInEnabled) {
      return STATUS_COLORS.white;
    }

    if (!getIsCheckInOnlineCompleted()) {
      return STATUS_COLORS.orange;
    }

    if (getIsBiomatchPassed()) {
      return STATUS_COLORS.green;
    }

    return STATUS_COLORS.red;
  };

  const getSecurityDepositsPendingStatusColor = () => {
    if (isPendingSecurityDepositsStatus) {
      return STATUS_COLORS.orange;
    }

    return STATUS_COLORS.none;
  };

  const getSecurityDepositsPendingStatusTitle = () => {
    if (isPendingSecurityDepositsStatus) {
      return statusesDescriptions[STATUS_TYPES.securityDeposit];
    }

    return '';
  };

  const getSecurityDepositsTitle = () => {
    const onHold = reservation?.security_deposit?.status === 'CONFIRMED';
    const isReleased = reservation?.security_deposit?.status === 'RELEASED';
    const statusDescription = statusesDescriptions[STATUS_TYPES.securityDeposit];

    if (onHold) {
      return (
        <>
          <DepositIcon src={closedLock} alt="Open lock" /> <b>{statusDescription}</b>
        </>
      );
    }

    if (isReleased) {
      return (
        <>
          <DepositIcon src={openLock} alt="Open lock" /> <b>{statusDescription}</b>
        </>
      );
    }

    return statusDescription;
  };

  const getSecurityDepositsSubtitle = () => {
    const isReleased = reservation?.security_deposit?.status === 'RELEASED';

    if (isReleased) {
      const chargedAmount = reservation?.security_deposit?.charged_amount;
      return t('amount_charged', {amount: chargedAmount});
    }

    return null;
  };

  const getSelfCheckInStatusSubtitle = () => {
    if (!isSelfCheckInEnabled) {
      return '';
    }

    if (!getIsCheckInOnlineCompleted()) {
      return t('online_checkin_not_completed');
    }

    if (getIsBiomatchPassed()) {
      return t('biomatch_passed');
    }

    return t('biomatch_failed');
  };

  const getStatusColor = (status: any) => {
    if (status === t('completed')) {
      return STATUS_COLORS.green;
    }

    if (
      status === t('credentials_missing') ||
      status.includes(t('guests_waiting_to_be_registered'))
    ) {
      return STATUS_COLORS.orange;
    }

    return STATUS_COLORS.red;
  };

  const hasPoliceInCountry = () => {
    return COUNTRIES_WITH_POLICE.includes(getCountryCode(housing));
  };

  const hasPoliceActivated = () => {
    return housing?.police_account && housing?.is_police_registration_enabled;
  };

  const hasStatInCountry = () => {
    return COUNTRIES_WITH_STAT.includes(getCountryCode(housing));
  };

  const hasStatActivated = () => {
    return housing?.stat_account && housing?.is_stat_registration_enabled;
  };

  const hasPoliceLikeStatType = () => {
    return POLICE_LIKE_STAT_TYPES.includes(getStatTypeIfStatActive(housing));
  };

  const hasGuestsInGroup = () => {
    return Boolean(getGroupMembersNumber(guestGroup));
  };

  const getTimezoneCheckInDate = (timezone: string) => {
    const checkInDate = reservation?.check_in_date;
    if (!checkInDate) {
      return null;
    }

    return getTimezoneDate(checkInDate, timezone);
  };

  const isCheckInDateToday = () => {
    const timezone = getTimezone(housing);
    const checkInDate = getTimezoneCheckInDate(timezone);

    if (!checkInDate) {
      return false;
    }

    const today = moment().tz(timezone);
    return checkInDate.isSame(today, 'day');
  };

  const isCheckInDateTomorrow = () => {
    const timezone = getTimezone(housing);
    const checkInDate = getTimezoneCheckInDate(timezone);

    if (!checkInDate) {
      return false;
    }

    const today = moment().tz(timezone);
    return checkInDate.isSame(today.subtract(1, 'day'), 'day');
  };

  const isCheckInDateAfterTomorrow = () => {
    const timezone = getTimezone(housing);
    const checkInDate = getTimezoneCheckInDate(timezone);

    if (!checkInDate) {
      return false;
    }

    const today = moment().tz(timezone);
    return checkInDate.isSameOrBefore(today.subtract(2, 'day'), 'day');
  };

  const isSendingAfterOneDay = () => {
    return (
      isCheckInDateAfterTomorrow() &&
      COUNTRIES_WITH_POLICE_ALLOWED_SEND_AFTER_1_DAY.includes(getCountryCode(housing))
    );
  };

  const shouldShowPoliceButton = () => {
    if (!hasPoliceInCountry()) {
      return false;
    }

    if (!hasPoliceActivated()) {
      return false;
    }

    if (!hasGuestsInGroup()) {
      return false;
    }

    if (isCheckInDateToday() || isCheckInDateTomorrow()) {
      return true;
    }

    return isSendingAfterOneDay();
  };

  const shouldShowStatButton = () => {
    if (!hasStatInCountry()) {
      return false;
    }

    if (!hasStatActivated()) {
      return false;
    }

    if (!hasGuestsInGroup()) {
      return false;
    }

    if (!hasPoliceLikeStatType()) {
      return false;
    }

    if (hasAnyCompletedGuest(getGuestStatStatusInCode, guestGroup)) {
      return false;
    }

    return isCheckInDateTomorrow() || isCheckInDateAfterTomorrow();
  };

  const openSendToPoliceRegistrationModal = () => {
    setIsPolice(true);
    openSendToRegistrationModal();
  };

  const openSendToStatRegistrationModal = () => {
    setIsPolice(false);
    openSendToRegistrationModal();
  };

  const renderChargeDepositButton = () => {
    const onHold = reservation?.security_deposit?.status === 'CONFIRMED';

    if (!onHold) {
      return null;
    }

    return (
      <StatusTileResolveButton
        onClick={(event) => {
          event.stopPropagation();
          openDepositChargeModals();
        }}
        type="button"
      >
        ({t('charge')})
      </StatusTileResolveButton>
    );
  };

  const renderPoliceButton = () => {
    return (
      shouldShowPoliceButton() && (
        <ResolveButton
          onClick={(event) => {
            event.stopPropagation();
            openSendToPoliceRegistrationModal();
          }}
          type="button"
        >
          ({t('send_manually')})
        </ResolveButton>
      )
    );
  };

  const renderStatButton = () => {
    return (
      shouldShowStatButton() && (
        <ResolveButton
          onClick={(event) => {
            event.stopPropagation();
            openSendToStatRegistrationModal();
          }}
          type="button"
        >
          ({t('send_manually')})
        </ResolveButton>
      )
    );
  };

  return (
    <Section title={t('booking_status')}>
      <Content>
        {!reservation || housingStatus === 'loading' || guestGroupStatus === 'loading' ? (
          <LoaderWrapper>
            <Loader color="#EEEEEE" height={45} width={45} />
          </LoaderWrapper>
        ) : (
          <TilesWrapper>
            <StatusTileContent>
              <StatusTileName>{t('guest_registration')}</StatusTileName>
              <LuggageImage src={luggageIcon} alt="Luggage" />
              <GuestsStatusTileTitle color={getGuestStatusColor()}>
                {getGuestsStatusTitle()}
              </GuestsStatusTileTitle>
              <>
                <StatusTileSubtitle>{t('registered_guests')}</StatusTileSubtitle>
                {!getIsAllGuestsRegistered() && (
                  <StatusTileResolveButton onClick={() => goToGuest()} type="button">
                    ({t('register_guest')})
                  </StatusTileResolveButton>
                )}
              </>
            </StatusTileContent>
            {statusesDescriptions[STATUS_TYPES.securityDeposit] && (
              <StatusTile
                name={t('security_deposit')}
                title={getSecurityDepositsPendingStatusTitle()}
                statusColor={getSecurityDepositsPendingStatusColor()}
                ResolveButton={renderChargeDepositButton()}
                Image={<CardsIcon src={cardsIcon} alt="Cards" />}
              >
                {!isPendingSecurityDepositsStatus && (
                  <div>
                    <StatusTileTitle>{getSecurityDepositsTitle()}</StatusTileTitle>
                    <StatusTileSubtitle>
                      {getSecurityDepositsSubtitle()}
                    </StatusTileSubtitle>
                  </div>
                )}
              </StatusTile>
            )}
            {statusesDescriptions[STATUS_TYPES.policeCheckIn] && (
              <StatusTile
                name={t('police_in')}
                statusColor={getStatusColor(
                  statusesDescriptions[STATUS_TYPES.policeCheckIn],
                )}
                title={statusesDescriptions[STATUS_TYPES.policeCheckIn]}
                Image={<PoliceManImage src={policemanIcon} alt="Policeman" />}
                ResolveButton={renderPoliceButton()}
              />
            )}
            {statusesDescriptions[STATUS_TYPES.policeCheckOut] && (
              <StatusTile
                name={t('police_out')}
                statusColor={getStatusColor(
                  statusesDescriptions[STATUS_TYPES.policeCheckOut],
                )}
                title={statusesDescriptions[STATUS_TYPES.policeCheckOut]}
                Image={<PoliceManImage src={policemanIcon} alt="Policeman" />}
              />
            )}
            {statusesDescriptions[STATUS_TYPES.statsCheckIn] && (
              <StatusTile
                name={t('stats_in')}
                statusColor={getStatusColor(
                  statusesDescriptions[STATUS_TYPES.statsCheckIn],
                )}
                title={statusesDescriptions[STATUS_TYPES.statsCheckIn]}
                Image={
                  <BusinessPresentationImage
                    src={businessPresentationIcon}
                    alt="Business presentation"
                  />
                }
                ResolveButton={renderStatButton()}
              />
            )}
            {statusesDescriptions[STATUS_TYPES.statsCheckOut] && (
              <StatusTile
                name={t('stats_out')}
                statusColor={getStatusColor(
                  statusesDescriptions[STATUS_TYPES.statsCheckOut],
                )}
                title={statusesDescriptions[STATUS_TYPES.statsCheckOut]}
                Image={
                  <BusinessPresentationImage
                    src={businessPresentationIcon}
                    alt="Business presentation"
                  />
                }
              />
            )}
            {statusesDescriptions[STATUS_TYPES.selfCheckIn] && (
              <StatusTile
                name={t('self_checkin')}
                title={statusesDescriptions[STATUS_TYPES.selfCheckIn]}
                subtitle={getSelfCheckInStatusSubtitle()}
                statusColor={getSelfCheckInStatusColor()}
                Image={<KeyImage src={keyIcon} alt="Key" />}
              />
            )}
            {statusesDescriptions[STATUS_TYPES.taxes] && (
              <StatusTile
                name={t('taxes')}
                title={statusesDescriptions[STATUS_TYPES.taxes]}
                statusColor={getTaxesStatusColor()}
                Image={<CardsIcon src={cardsIcon} alt="Cards" />}
              />
            )}
            {isSendToRegistrationModalOpen && (
              <SendToRegistrationModal
                handleModalCancel={closeSendToRegistrationModal}
                shouldShowWarning={isSendingAfterOneDay()}
                isPoliceRegistration={isPolice}
                reservationId={reservation.id}
                guestGroupId={reservation.guest_group_id}
              />
            )}
            {isDepositChargeModalsOpen && (
              <DepositChargeModals
                open
                onClose={closeDepositChargeModals}
                reservation={reservation}
              />
            )}
          </TilesWrapper>
        )}
      </Content>
    </Section>
  );
}

export {ReservationStatusSection};
