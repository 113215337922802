import React from 'react';
import {default as Content} from '../components/onboarding/PmsPlanyoOriginRegister';

function PmsPlanyoOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsPlanyoOriginRegister};
