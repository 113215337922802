import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import PaymentsMovementsComponent from '../components/dashboard/PaymentsMovements';

function PaymentsMovements(props: any) {
  return (
    <>
      <TrialHeader />
      <Header />
      <PaymentsMovementsComponent {...props} />
    </>
  );
}

export {PaymentsMovements};
