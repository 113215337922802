import React from 'react';
import {useQuery} from 'react-query';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useLocation} from 'react-router-dom';
import api, {queryFetcher} from '../../../api';
import {useSubscription} from '../../../context/subscription';
import {PERIODICITY, SUBSCRIPTION_TYPES} from '../../../utils/constants';
import type {Plan} from '../../../utils/types';
import {PlanTotalPrice} from '../../../utils/types';
import {toastResponseError} from '../../../utils/common';
import {useErrorToast} from '../../../utils/hooks';
import {useUser} from '../../../context/user';
import {Heading} from '../../../styled/common';
import Loader from '../../common/Loader';
import BackButton from '../BackButton';
import {
  CancelButton,
  Content,
  Header,
  LoaderWrapper,
  PlanTile,
  PlanTileBestValue,
  PlanTileMonth,
  PlanTilePriceDescriptionInfo,
  PlanTilePriceDescriptionTip,
  PlanTilePriceInfo,
  PlanTilePriceValue,
  SubHeader,
  SubscribeButton,
} from './styled';

const PLAN_QUERY_CONFIG = {
  cacheTime: 24 * 60 * 1000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

function getPlanDetailsQuery(interval = '', type = '') {
  return `interval=${interval}&type=${type}`;
}

async function fetchPlan(plan: string, type: string) {
  const planDetailsQuery = getPlanDetailsQuery(plan, type);
  return queryFetcher(api.payments.ENDPOINTS.plans(planDetailsQuery));
}

function fetchPrice(key: string, planId: string, quantity = '1') {
  return queryFetcher(
    api.payments.ENDPOINTS.planTotalPrice(planId, `quantity=${quantity}`),
  );
}

type LocationState = {
  accommodationsNumber?: string;
};

function SubscriptionSelectPlanStep() {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const user = useUser();
  const {
    subscription,
    isSubscriptionCanceled,
    inactiveSubscriptions,
    isLoading: isLoadingSubscription,
  } = useSubscription();
  const [subscriptionType, setSubscriptionType] = React.useState<string | undefined>('');
  const [planType, setPlanType] = React.useState('');

  const accommodationsNumber = location.state?.accommodationsNumber;

  const {data: yearlyPlan, status: yearlyPlanStatus} = useQuery<Plan, [string, string]>(
    Boolean(planType) && [PERIODICITY.yearly, planType],
    fetchPlan,
    PLAN_QUERY_CONFIG,
  );

  const {data: monthlyPlan, status: monthlyPlanStatus} = useQuery<Plan, [string, string]>(
    Boolean(planType) && [PERIODICITY.monthly, planType],
    fetchPlan,
    PLAN_QUERY_CONFIG,
  );

  const {
    data: yearlyPrice,
    status: yearlyPriceStatus,
    error: yearlyPriceError,
  } = useQuery<PlanTotalPrice, [string, string, string?]>(
    Boolean(yearlyPlan?.unique_id) && [
      'planChangePrice',
      yearlyPlan!.unique_id,
      accommodationsNumber,
    ],
    fetchPrice,
    {
      refetchOnWindowFocus: false,
    },
  );
  useErrorToast(yearlyPriceError, {
    notFoundMessage: 'Requested yearly price could not be found. Please contact support.',
  });

  const {
    data: monthlyPrice,
    status: monthlyPriceStatus,
    error: monthlyPriceError,
  } = useQuery<PlanTotalPrice, [string, string, string?]>(
    Boolean(monthlyPlan?.unique_id) && [
      'planChangePrice',
      monthlyPlan!.unique_id,
      accommodationsNumber,
    ],
    fetchPrice,
    {
      refetchOnWindowFocus: false,
    },
  );
  useErrorToast(monthlyPriceError, {
    notFoundMessage: 'Requested price could not be found. Please contact support.',
  });

  const isLoading =
    yearlyPlanStatus === 'loading' ||
    monthlyPlanStatus === 'loading' ||
    isLoadingSubscription ||
    monthlyPriceStatus === 'loading' ||
    yearlyPriceStatus === 'loading';

  React.useEffect(() => {
    if (isSubscriptionCanceled && inactiveSubscriptions?.length) {
      const inactivePlanType = inactiveSubscriptions[0].type;
      setPlanType(inactivePlanType);
      return;
    }

    if (subscription?.type) {
      setPlanType(subscription.type);
    }
  }, [inactiveSubscriptions, isSubscriptionCanceled, subscription]);

  React.useEffect(() => {
    if (!accommodationsNumber) {
      history.replace('/subscription/number');
    }
  }, [location.state, history, accommodationsNumber]);

  React.useEffect(() => {
    setSubscriptionType(user?.subscription_type);
  }, [user]);

  const goNext = (persistedState: any) => {
    history.push('/subscription/payment', persistedState);
  };

  const goBack = () => {
    history.push('/subscription/number', location.state);
  };

  const getPrice = (price = 0) => {
    if (!price) {
      return 0;
    }

    return price.toFixed(2);
  };

  const getMonthlyPriceFromYearly = (price = 0) => {
    if (!price) {
      return 0;
    }

    return getPrice(price / 12);
  };

  const selectPlan = (plan?: Plan, price?: PlanTotalPrice) => {
    if (!plan) {
      toastResponseError(t('plan_not_found'));
      return;
    }

    const newState = {
      ...location.state,
      plan,
      price,
    };
    goNext(newState);
  };

  return (
    <Content>
      <Heading>
        <BackButton onClick={goBack} />
        <Header>{t('subscription')}</Header>
      </Heading>
      <SubHeader>{t('select_plan')}</SubHeader>
      {isLoading && (
        <LoaderWrapper>
          <Loader width={50} height={50} label={t('loading')} />
        </LoaderWrapper>
      )}
      {!isLoading && (
        <>
          <PlanTile>
            <PlanTileBestValue>{t('best_value')}</PlanTileBestValue>
            <PlanTileMonth>12 {t('months')}</PlanTileMonth>
            <PlanTilePriceInfo>
              <PlanTilePriceValue>
                {getMonthlyPriceFromYearly(yearlyPrice?.price_with_tax)}
                {` `} €
              </PlanTilePriceValue>
              /
              {subscriptionType === SUBSCRIPTION_TYPES.hotel
                ? t('month_per_number_rooms', {number: accommodationsNumber})
                : t('month_per_number_properties', {number: accommodationsNumber})}
            </PlanTilePriceInfo>
            <PlanTilePriceDescriptionInfo>
              {getPrice(yearlyPrice?.price_with_tax)}
              {` `} €
              <PlanTilePriceDescriptionTip>
                {t('every_12_months')}
              </PlanTilePriceDescriptionTip>
            </PlanTilePriceDescriptionInfo>
            <SubscribeButton
              onClick={() => selectPlan(yearlyPlan, yearlyPrice)}
              label={t('subscribe_yearly')}
              secondary
            />
          </PlanTile>
          <PlanTile>
            <PlanTileMonth>1 {t('month').toLowerCase()}</PlanTileMonth>
            <PlanTilePriceInfo>
              <PlanTilePriceValue>
                {getPrice(monthlyPrice?.price_with_tax)}
                {` `} €
              </PlanTilePriceValue>
              {` `}/{' '}
              {subscriptionType === SUBSCRIPTION_TYPES.hotel
                ? t('month_per_number_rooms', {number: accommodationsNumber})
                : t('month_per_number_properties', {number: accommodationsNumber})}
            </PlanTilePriceInfo>
            <SubscribeButton
              onClick={() => selectPlan(monthlyPlan, monthlyPrice)}
              label={t('subscribe_monthly')}
              secondary
            />
          </PlanTile>
          <Link to="/properties">
            <CancelButton label={t('cancel')} secondary />
          </Link>
        </>
      )}
    </Content>
  );
}

export {SubscriptionSelectPlanStep};
