// @ts-nocheck
function initHubspot(path: string) {
  const hubspot = (window._hsq = window._hsq || []);

  hubspot.push(['setPath', path]);
  hubspot.push(['trackPageView']);
}

function openHubspotChat() {
  window.HubSpotConversations.widget.open();
}

export {initHubspot, openHubspotChat};
