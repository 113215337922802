import React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {useUser} from '../../../context/user';
import {Status, User} from '../../../utils/types';
import {PATTERNS} from '../../../utils/constants';
import {useModalControls} from '../../../utils/hooks';
import {useComputedDetails} from '../../../context/computedDetails';
import {ModalTwoButtonsWrapper} from '../../../styled/common';
import lockIcon from '../../../assets/lock.svg';
import chekinLogoWhiteImage from '../../../assets/chekin-logo-white.svg';
import fixIcon from '../../../assets/fix.svg';
import rubbishIcon from '../../../assets/rubbish.svg';
import warningIcon from '../../../assets/warning-icon.svg';
import Section from '../Section';
import Input from '../Input';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import {
  Content,
  ChangePasswordButton,
  ChangePasswordButtonWrapper,
  FieldWrapper,
  UndoButton,
  LogoTitle,
  AddLogoFileInput,
  LogoContainer,
  LogoContent,
  LogoDetails,
  MagicWandIcon,
  LogoPlaceholderImage,
  CustomLogoImage,
  LogoButtonsWrapper,
} from './styled';

export enum FORM_NAMES {
  name = 'name',
  email = 'email',
  phoneNumber = 'phoneNumber',
  phoneCode = 'phoneCode',
  oldPassword = 'old_password',
  newPassword = 'password',
  repeatedNewPassword = 'repeatedNewPassword',
  logo = 'picture',
}

export type FormTypes = {
  [FORM_NAMES.name]: string;
  [FORM_NAMES.email]: string;
  [FORM_NAMES.phoneNumber]: string;
  [FORM_NAMES.phoneCode]: string;
  [FORM_NAMES.oldPassword]?: string;
  [FORM_NAMES.newPassword]?: string;
  [FORM_NAMES.repeatedNewPassword]?: string;
  [FORM_NAMES.logo]?: string | File;
};

export const PASSWORD_FIELDS = [
  FORM_NAMES.repeatedNewPassword,
  FORM_NAMES.newPassword,
  FORM_NAMES.oldPassword,
];

const REST_FIELDS = [FORM_NAMES.name, FORM_NAMES.email, FORM_NAMES.phoneNumber];

function buildFormData(user?: User | null) {
  if (!user) {
    return {};
  }

  return {
    [FORM_NAMES.name]: user.name,
    [FORM_NAMES.email]: user.email,
    [FORM_NAMES.phoneNumber]: user.phone,
    [FORM_NAMES.logo]: user?.picture?.src || '',
  };
}

function getShouldResetFormState(dirtyFields: string[]) {
  if (!dirtyFields.length) {
    return false;
  }

  const hasRestFields = dirtyFields.some((field) => {
    const included = [...REST_FIELDS].find((f) => f === field);
    return Boolean(included);
  });
  if (hasRestFields) {
    return false;
  }

  const hasPasswordFields = dirtyFields.some((field) => {
    const included = [...PASSWORD_FIELDS].find((f) => f === field);
    return Boolean(included);
  });
  return hasPasswordFields;
}

function getLogoSrc(logo: string | File) {
  if (typeof logo === 'string') {
    return logo;
  }
  return URL.createObjectURL(logo);
}

type AccountDetailsSectionProps = {
  status: Status;
};

function AccountDetailsSection({status}: AccountDetailsSectionProps) {
  const {t} = useTranslation();
  const user = useUser();
  const {
    register,
    reset,
    errors,
    formState,
    getValues,
    setValue,
    watch,
    triggerValidation,
  } = useFormContext<FormTypes>();
  const {dirtyFields} = formState;
  const {isAccountOwner} = useComputedDetails();
  const {
    closeModal: closeRemoveLogoModal,
    isOpen: isRemoveLogoModalOpen,
    openModal: openRemoveLogoModal,
  } = useModalControls();

  const logo = watch(FORM_NAMES.logo);
  const newPassword = watch(FORM_NAMES.newPassword);

  const [isChangingPassword, setIsChangingPassword] = React.useState(false);
  const shouldResetFormState =
    !isChangingPassword && getShouldResetFormState(Array.from(dirtyFields));

  React.useEffect(
    function preloadFormData() {
      const formData = buildFormData(user);
      reset({
        ...getValues(),
        ...formData,
      });
    },
    [user, reset, getValues],
  );

  React.useEffect(
    function resetFormStateOnPasswordFieldsHide() {
      if (shouldResetFormState) {
        reset(getValues());
      }
    },
    [shouldResetFormState, getValues, reset],
  );

  React.useEffect(
    function registerLogo() {
      register({name: FORM_NAMES.logo});
    },
    [register],
  );

  React.useEffect(
    function revalidateRepeatedPasswordOnNewPasswordChange() {
      if (newPassword && formState.dirty && formState.isSubmitted) {
        triggerValidation(FORM_NAMES.repeatedNewPassword);
      }
    },
    [formState, newPassword, triggerValidation],
  );

  React.useEffect(
    function stopChangingPasswordOnSuccess() {
      if (status === 'success') {
        setIsChangingPassword(false);
      }
    },
    [status],
  );

  const toggleIsChangingPassword = () => {
    setIsChangingPassword((prevState) => !prevState);
  };

  const handleFileSelect = (event: any) => {
    const {target} = event;
    if (target?.files?.length) {
      const nextLogo = target.files[0];
      setValue(FORM_NAMES.logo, nextLogo);
    }
  };

  const removeCustomLogo = () => {
    setValue(FORM_NAMES.logo, '');
    closeRemoveLogoModal();
  };

  return (
    <>
      <Section title={t('account_details')}>
        <Content>
          <div>
            <FieldWrapper>
              <Input
                ref={register({
                  required: t('required') as string,
                  pattern: {
                    value: PATTERNS.name,
                    message: t('cant_contain_number_and_symbols'),
                  },
                })}
                name={FORM_NAMES.name}
                error={errors[FORM_NAMES.name]?.message}
                placeholder={t('enter_name')}
                label={t('name')}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                readOnly
                ref={register({
                  required: t('required') as string,
                  pattern: {
                    value: PATTERNS.email,
                    message: t('invalid_email'),
                  },
                })}
                name={FORM_NAMES.email}
                error={errors[FORM_NAMES.email]?.message}
                inputMode="email"
                placeholder={t('enter_email')}
                label={t('email')}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                ref={register({
                  required: false,
                })}
                name={FORM_NAMES.phoneNumber}
                error={errors[FORM_NAMES.phoneNumber]?.message}
                type="phone"
                inputMode="tel"
                placeholder={t('enter_phone')}
                label={`${t('phone')} (${t('optional')})`}
              />
            </FieldWrapper>
          </div>
          <div>
            {isChangingPassword ? (
              <>
                <FieldWrapper>
                  <Input
                    ref={register({
                      required: t('required') as string,
                    })}
                    name={FORM_NAMES.oldPassword}
                    error={errors[FORM_NAMES.oldPassword]?.message}
                    type="password"
                    empty={!watch(FORM_NAMES.oldPassword)}
                    placeholder={t('enter_current_password')}
                    label={t('current_password')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Input
                    ref={register({
                      required: t('required') as string,
                    })}
                    name={FORM_NAMES.newPassword}
                    error={
                      errors[FORM_NAMES.newPassword]?.message ||
                      errors[FORM_NAMES.newPassword]?.types?.message
                    }
                    disabled={false}
                    type="password"
                    empty={!watch(FORM_NAMES.newPassword)}
                    placeholder={t('enter_new_password')}
                    label={t('new_password')}
                  />
                </FieldWrapper>{' '}
                <FieldWrapper>
                  <Input
                    ref={register({
                      required: t('required') as string,
                      validate: (value) =>
                        value === getValues()[FORM_NAMES.newPassword] ||
                        (t('password_doesnt_match') as string),
                    })}
                    name={FORM_NAMES.repeatedNewPassword}
                    empty={!watch(FORM_NAMES.repeatedNewPassword)}
                    error={errors[FORM_NAMES.repeatedNewPassword]?.message}
                    type="password"
                    placeholder={t('repeat_new_password')}
                    label={t('repeat_new_password')}
                  />
                </FieldWrapper>
              </>
            ) : (
              <FieldWrapper>
                <Input
                  hideReveal
                  type="password"
                  name="password-placeholder"
                  value="*******"
                  label={t('password')}
                  readOnly
                />
              </FieldWrapper>
            )}
            <ChangePasswordButtonWrapper>
              {isChangingPassword ? (
                <UndoButton
                  secondary
                  type="button"
                  label={t('cancel')}
                  onClick={toggleIsChangingPassword}
                />
              ) : (
                <ChangePasswordButton
                  secondary
                  type="button"
                  onClick={toggleIsChangingPassword}
                  label={
                    <>
                      <img src={lockIcon} alt="Lock" />
                      {t('change_password')}
                    </>
                  }
                />
              )}
            </ChangePasswordButtonWrapper>
          </div>
          {isAccountOwner && (
            <div>
              <LogoContent>
                <LogoTitle>{t('logo')}</LogoTitle>
                <LogoContainer whiteBg={Boolean(logo)}>
                  {logo ? (
                    <CustomLogoImage src={getLogoSrc(logo)} alt="Any" />
                  ) : (
                    <LogoPlaceholderImage src={chekinLogoWhiteImage} alt="Chekin" />
                  )}
                </LogoContainer>
                <LogoDetails>
                  <div>{t('size')}: 108 px x 28 px</div>
                  <div>{t('format')}: JPG, PNG</div>
                </LogoDetails>
                <LogoButtonsWrapper>
                  <AddLogoFileInput
                    secondary
                    onChange={handleFileSelect}
                    icon={<MagicWandIcon src={fixIcon} alt="Magic wand" />}
                    label={logo ? t('change') : t('add_logo')}
                    accept=".jpg, .png"
                  />
                  {logo && (
                    <UndoButton
                      secondary
                      type="button"
                      onClick={openRemoveLogoModal}
                      label={
                        <>
                          <img src={rubbishIcon} alt="Trash" />
                          {t('remove')}
                        </>
                      }
                    />
                  )}
                </LogoButtonsWrapper>
              </LogoContent>
            </div>
          )}
        </Content>
      </Section>
      {isRemoveLogoModalOpen && (
        <Modal
          open
          closeOnDocumentClick
          closeOnEscape
          iconSrc={warningIcon}
          iconAlt="Warning"
          title={t('are_you_sure')}
          text={t('your_current_logo_will_be_deleted')}
          onClose={closeRemoveLogoModal}
        >
          <ModalTwoButtonsWrapper>
            <ModalButton label={t('delete_logo')} onClick={removeCustomLogo} />
            <ModalButton secondary label={t('cancel')} onClick={closeRemoveLogoModal} />
          </ModalTwoButtonsWrapper>
        </Modal>
      )}
    </>
  );
}

export {AccountDetailsSection};
