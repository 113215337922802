import React from 'react';
import {StyledButton} from './styled';

export type ModalButtonProps = {
  label: React.ReactNode | JSX.Element | string;
  onClick?: () => any;
  disabled?: boolean;
  className?: string;
  secondary?: boolean;
  type?: 'button' | 'submit';
};

const defaultProps: ModalButtonProps = {
  label: '',
  disabled: false,
  onClick: () => {},
  className: undefined,
  secondary: false,
  type: 'button',
};

function ModalButton({
  label,
  onClick,
  disabled,
  className,
  secondary,
  type,
  ...props
}: ModalButtonProps) {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={className}
      secondary={secondary}
      {...props}
    >
      {label}
    </StyledButton>
  );
}

ModalButton.defaultProps = defaultProps;
export {ModalButton};
