import styled from 'styled-components';

export const Main = styled.main`
  margin: 90px 0;
`;

export const LoaderWrapper = styled.div`
  margin-top: 20%;
  text-align: center;
`;
