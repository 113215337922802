import React, {Suspense} from 'react';
import {withProfiler, ErrorBoundary} from '@sentry/react';
import {useTranslation} from 'react-i18next';
import {useUser} from './context/user';
import Authenticated from './apps/Authenticated';
import Analytics from './components/common/Analytics';
import FullPageSpinner from './components/common/FullPageSpinner';
import ErrorBoundaryFallback from './components/common/ErrorBoundaryFallback';
import WebcamWarningModal from './components/dashboard/WebcamWarningModal';

const Unauthenticated = React.lazy(() => import('./apps/Unauthenticated'));

const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';

function App() {
  const {t} = useTranslation();
  const user = useUser();

  return (
    <ErrorBoundary
      fallback={<ErrorBoundaryFallback />}
      showDialog={IS_PRODUCTION_BUILD}
      dialogOptions={{
        user: {
          name: user?.name,
          email: user?.email,
        },
        labelComments: t('describe_what_happened'),
      }}
    >
      <Suspense fallback={<FullPageSpinner />}>
        {user ? (
          <div>
            <WebcamWarningModal />
            <Analytics />
            <Authenticated />
          </div>
        ) : (
          <Unauthenticated />
        )}
      </Suspense>
    </ErrorBoundary>
  );
}

export default withProfiler(App);
