import React, {Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import moment, {Moment} from 'moment';
import api, {queryFetcher} from '../../../api';
import {
  Housing,
  InputEventType,
  Season,
  SeasonRule,
  SelectOption,
  TaxExemption,
} from '../../../utils/types';
import {useErrorToast, useIsFormTouched, useModalControls} from '../../../utils/hooks';
import {COUNTRIES_WITH_TAXES_AGE_RULES_CALC} from '../../../utils/constants';
import {useTaxesExport} from '../../../context/taxesExport';
import {useFormContext} from 'react-hook-form';
import Checkbox from '../Checkbox';
import Section from '../Section';
import Switch from '../Switch';
import TaxesCreationModals from '../TaxesCreationModals';
import MultiSelect from '../MultiSelect';
import Loader from '../../common/Loader';
import Button from '../Button';
import ExportTaxesSettingsModal from '../ExportTaxesSettingsModal';
import ImportTaxesModal from '../ImportTaxesModal';
import {RelativeWrapper, GreenSectionTag} from '../../../styled/common';
import {
  ExceptionRuleWrapper,
  ExceptionsLabel,
  ExceptionsMultiSelectWrapper,
  ExceptionsRules,
  LessThanRuleWrapper,
  LoaderWrapper,
  MaxNightsTaxedInputWrapper,
  SeasonContainer,
  SeasonDatePickerWrapper,
  SeasonLabel,
  SeasonsGroup,
  ShortInput,
  ShortInputCurrency,
  ShortInputWrapper,
  ExportSettingsButtonWrapper,
  MonthElement,
  DayMonthPicker,
  LowSeasonDayMonthPicker,
  SinglePricePerNightWrapper,
  ModalAgeRulesContainer,
  AgeRuleRow,
  CheckboxWrapper,
  AgeRuleNameButton,
  AgeRuleInput,
  AgeRuleSeasonInput,
  AgeRuleSeasonInputSideLabel,
  BetweenAgesText,
  BetweenAgesWrapper,
  AgeRulesLabel,
  AgeRulesContainer,
  ModalAgeRuleRow,
  WhiteSpace,
  RestartTaxesModalButtonWrapper,
} from './styled';

const DEFAULT_EXEMPTIONS_PRICE = 0;
const MIN_PRICE = 0;
const MIN_AGE = 0;
const MIN_NIGHTS_NUMBER_TAXED = 1;

enum SEASON_NAMES {
  high = 'HIGH',
  low = 'LOW',
}

export enum FORM_NAMES {
  lowPrice = 'lowPrice',
  highPrice = 'highPrice',
  lessThanAge = 'lessThanAge',
  maxNightsTaxed = 'maxNightsTaxed',
  moreThanAge = 'moreThanAge',
  betweenLowAge = 'betweenLowAge',
  betweenHighAge = 'betweenHighAge',
  moreThanHighSeasonPrice = 'moreThanHighSeasonPrice',
  moreThanLowSeasonPrice = 'moreThanLowSeasonPrice',
  betweenHighSeasonPrice = 'betweenHighSeasonPrice',
  betweenLowSeasonPrice = 'betweenLowSeasonPrice',
  lessThanHighSeasonPrice = 'lessThanHighSeasonPrice',
  lessThanLowSeasonPrice = 'lessThanLowSeasonPrice',
}

export const AGE_CALCULATOR_FORM_NAMES = [
  FORM_NAMES.moreThanAge,
  FORM_NAMES.lessThanAge,
  FORM_NAMES.betweenLowAge,
  FORM_NAMES.betweenHighAge,
  FORM_NAMES.moreThanLowSeasonPrice,
  FORM_NAMES.lessThanLowSeasonPrice,
  FORM_NAMES.moreThanHighSeasonPrice,
  FORM_NAMES.lessThanHighSeasonPrice,
  FORM_NAMES.betweenLowSeasonPrice,
  FORM_NAMES.betweenHighSeasonPrice,
];

type FormTypes = {
  [FORM_NAMES.lowPrice]: string;
  [FORM_NAMES.highPrice]: string;
  [FORM_NAMES.lessThanAge]: string;
  [FORM_NAMES.maxNightsTaxed]: string;
  [FORM_NAMES.moreThanAge]: string;
  [FORM_NAMES.betweenLowAge]: string;
  [FORM_NAMES.betweenHighAge]: string;
  [FORM_NAMES.moreThanHighSeasonPrice]: string;
  [FORM_NAMES.moreThanLowSeasonPrice]: string;
  [FORM_NAMES.betweenHighSeasonPrice]: string;
  [FORM_NAMES.betweenLowSeasonPrice]: string;
  [FORM_NAMES.lessThanHighSeasonPrice]: string;
  [FORM_NAMES.lessThanLowSeasonPrice]: string;
};

enum AGE_RULES_CHECKBOXES {
  moreThan = 'moreThan',
  between = 'between',
  lessThan = 'lessThan',
}

const displayFields = {
  [FORM_NAMES.lowPrice]: true,
  [FORM_NAMES.highPrice]: true,
  [FORM_NAMES.lessThanAge]: true,
  [FORM_NAMES.maxNightsTaxed]: true,
  [FORM_NAMES.moreThanAge]: true,
  [FORM_NAMES.lessThanAge]: true,
  [FORM_NAMES.betweenLowAge]: true,
  [FORM_NAMES.betweenHighAge]: true,
  [FORM_NAMES.moreThanLowSeasonPrice]: true,
  [FORM_NAMES.lessThanLowSeasonPrice]: true,
  [FORM_NAMES.moreThanHighSeasonPrice]: true,
  [FORM_NAMES.lessThanHighSeasonPrice]: true,
  [FORM_NAMES.betweenLowSeasonPrice]: true,
  [FORM_NAMES.betweenHighSeasonPrice]: true,
};

function getTaxExemptionsAsOptions(data?: TaxExemption[]) {
  const NO_TAX_EXEMPTIONS = 'NONE';

  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data
    .filter((t) => {
      return t.id !== NO_TAX_EXEMPTIONS;
    })
    .map((t) => {
      return {
        value: t.id,
        label: t.name,
      };
    });
}

function fetchTaxExemptions() {
  return queryFetcher(api.statTaxExemptions.ENDPOINTS.all());
}

function getInitInputValues() {
  let result: {[key: string]: string} = {};
  Object.keys(FORM_NAMES).forEach((key) => {
    result[key] = '';
  });

  return result;
}

type HousingTaxesSectionProps = {
  openExportTaxesModal: () => void;
  closeExportTaxesModal: () => void;
  isExportTaxesModalOpen: boolean;
  isSectionTouched: boolean;
  setIsSectionTouched: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  areSeasonsLoading?: boolean;
  highSeason?: Season;
  housing?: Housing;
  lowSeason?: Season;
  country?: string;
};

const defaultProps: Partial<HousingTaxesSectionProps> = {
  disabled: false,
  areSeasonsLoading: false,
  country: '',
};

const HousingTaxesSection = React.forwardRef(
  (
    {
      disabled,
      areSeasonsLoading,
      highSeason,
      lowSeason,
      housing,
      setIsSectionTouched,
      openExportTaxesModal,
      closeExportTaxesModal,
      isExportTaxesModalOpen,
      isSectionTouched,
      country,
    }: HousingTaxesSectionProps,
    ref,
  ) => {
    const {t} = useTranslation();
    const {
      register,
      getValues,
      errors,
      setValue,
      watch,
      formState,
      triggerValidation,
    } = useFormContext<FormTypes>();
    const {isSubmitted} = formState;
    const {
      isOpen: isTaxesModalOpen,
      openModal: openTaxesModal,
      closeModal: closeTaxesModal,
    } = useModalControls();
    const {
      isOpen: isImportTaxesModalOpen,
      openModal: openImportTaxesModal,
      closeModal: closeImportTaxesModal,
    } = useModalControls();
    const {isExporting} = useTaxesExport();
    const {isFormTouched, setUntouchedValues} = useIsFormTouched({
      displayFields,
      watch,
    });

    const [isSectionActive, setIsSectionActive] = React.useState(false);
    const [highSeasonStartDate, setHighSeasonStartDate] = React.useState<Moment | null>(
      null,
    );
    const [highSeasonEndDate, setHighSeasonEndDate] = React.useState<Moment | null>(null);
    const [lowSeasonStartDate, setLowSeasonStartDate] = React.useState<Moment | null>(
      null,
    );
    const [lowSeasonEndDate, setLowSeasonEndDate] = React.useState<Moment | null>(null);
    const [focusedLowSeasonModalInput, setFocusedLowSeasonModalInput] = React.useState<
      'startDate' | 'endDate' | null
    >(null);
    const [focusedHighSeasonModalInput, setFocusedHighSeasonModalInput] = React.useState<
      'startDate' | 'endDate' | null
    >(null);
    const [focusedHighSeasonInput, setFocusedHighSeasonInput] = React.useState<
      'startDate' | 'endDate' | null
    >(null);
    const [focusedLowSeasonInput, setFocusedLowSeasonInput] = React.useState<
      'startDate' | 'endDate' | null
    >(null);
    const [inputValues, setInputValues] = React.useState<{[key: string]: string}>(
      getInitInputValues,
    );
    const [exemptions, setExemptions] = React.useState<SelectOption[]>([]);
    const [isMaxNightsTaxed, setIsMaxNightsTaxed] = React.useState(false);
    const [ageRulesCheckboxes, setAgeRulesCheckboxes] = React.useState({
      [AGE_RULES_CHECKBOXES.moreThan]: false,
      [AGE_RULES_CHECKBOXES.between]: false,
      [AGE_RULES_CHECKBOXES.lessThan]: false,
    });
    const [defaultAgeCalculatorValues, setDefaultAgeCalculatorValues] = React.useState<{
      [key: string]: any;
    }>({});
    const [isCreationModalUsed, setIsCreationModalUsed] = React.useState(false);

    const [isHighSeasonPreloaded, setIsHighSeasonPreloaded] = React.useState(false);
    const isHighSeasonDatesComplete = Boolean(highSeasonEndDate && highSeasonStartDate);
    const isLowSeasonDatesComplete = Boolean(lowSeasonEndDate && lowSeasonStartDate);
    const hasTwoSeasons = isHighSeasonDatesComplete;

    const {data: taxExemptions, error: taxExemptionsError} = useQuery<
      TaxExemption[],
      string
    >('taxExemptions', fetchTaxExemptions, {
      refetchOnWindowFocus: false,
    });
    useErrorToast(taxExemptionsError, {
      notFoundMessage:
        'Requested tax exemptions could not be found. Please contact support.',
    });

    const taxExemptionsOptions = React.useMemo(() => {
      return getTaxExemptionsAsOptions(taxExemptions);
    }, [taxExemptions]);

    const isCountryWithTaxesCalc = COUNTRIES_WITH_TAXES_AGE_RULES_CALC.includes(country!);
    const hasLessThanAgeRule =
      !isCountryWithTaxesCalc && Boolean(inputValues[FORM_NAMES.lessThanAge]);
    const isAgeCalculatorNextButtonDisabled =
      Object.values(ageRulesCheckboxes).every((checkbox) => {
        return !checkbox;
      }) ||
      Object.keys(errors).some((key) => {
        if ((errors as any)[key]?.message) {
          return AGE_CALCULATOR_FORM_NAMES.includes(key as FORM_NAMES);
        }
        return false;
      });

    const getHighSeasonExemptionsPayload = React.useCallback(() => {
      if (!exemptions) {
        return [];
      }

      return exemptions.map((exemption) => {
        const existingExemption = highSeason?.exemptions?.find((ex) => {
          return ex?.exemption_type === exemption.value;
        });

        return {
          id: existingExemption?.id,
          exemption_type: exemption.value,
          price: DEFAULT_EXEMPTIONS_PRICE,
        };
      });
    }, [exemptions, highSeason]);

    const getLowSeasonExemptionsPayload = React.useCallback(() => {
      if (!exemptions) {
        return [];
      }

      return exemptions.map((exemption) => {
        const existingExemption = lowSeason?.exemptions?.find((ex) => {
          return ex?.exemption_type === exemption.value;
        });

        return {
          id: existingExemption?.id,
          exemption_type: exemption.value,
          price: DEFAULT_EXEMPTIONS_PRICE,
        };
      });
    }, [exemptions, lowSeason]);

    const getHighSeasonPayload = React.useCallback(() => {
      const rules: Partial<SeasonRule>[] = [];
      const formValues = getValues();
      const maxNightsTaxed = formValues?.[FORM_NAMES.maxNightsTaxed];
      const highPrice = formValues?.[FORM_NAMES.highPrice] || MIN_PRICE;
      const exemptions = getHighSeasonExemptionsPayload();

      if (
        !isCountryWithTaxesCalc ||
        (isCountryWithTaxesCalc && ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan])
      ) {
        const lessThanAge = Number(formValues?.[FORM_NAMES.lessThanAge] || MIN_AGE);

        if (lessThanAge) {
          const lessThanPrice = Number(
            formValues?.[FORM_NAMES.lessThanHighSeasonPrice] || MIN_PRICE,
          );
          const lessThanRule: Partial<SeasonRule> = {
            less_than: lessThanAge,
            price: lessThanPrice,
          };

          if (lessThanRule) {
            rules.push(lessThanRule);
          }
        }
      }

      if (isCountryWithTaxesCalc && ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]) {
        const moreThanAge = Number(formValues?.[FORM_NAMES.moreThanAge] || MIN_AGE);

        if (moreThanAge) {
          const moreThanPrice = Number(
            formValues?.[FORM_NAMES.moreThanHighSeasonPrice] || MIN_PRICE,
          );
          const moreThanRule: Partial<SeasonRule> = {
            up_to: moreThanAge,
            price: moreThanPrice,
          };

          if (moreThanRule) {
            rules.push(moreThanRule);
          }
        }
      }

      if (isCountryWithTaxesCalc && ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
        const betweenHighAge = Number(formValues?.[FORM_NAMES.betweenHighAge] || MIN_AGE);
        const betweenLowAge = Number(formValues?.[FORM_NAMES.betweenLowAge] || MIN_AGE);
        const betweenPrice = Number(
          formValues?.[FORM_NAMES.betweenHighSeasonPrice] || MIN_PRICE,
        );

        if (betweenHighAge && betweenLowAge) {
          const betweenThanRule: Partial<SeasonRule> = {
            less_than: betweenHighAge,
            up_to: betweenLowAge,
            price: betweenPrice,
          };

          if (betweenThanRule) {
            rules.push(betweenThanRule);
          }
        }

        if (
          betweenHighAge &&
          !betweenLowAge &&
          !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
        ) {
          const lessThanRule: Partial<SeasonRule> = {
            less_than: betweenHighAge,
            price: betweenPrice,
          };

          if (lessThanRule) {
            rules.push(lessThanRule);
          }
        }
      }

      return {
        exemptions,
        rules,
        name: SEASON_NAMES.high,
        housing_id: housing?.id,
        from_date: highSeasonStartDate?.format('YYYY-MM-DD') || null,
        to_date: highSeasonEndDate?.format('YYYY-MM-DD') || null,
        price_per_night: highPrice,
        max_nights: maxNightsTaxed || undefined,
        is_max_nights_taxed: Boolean(maxNightsTaxed),
      };
    }, [
      ageRulesCheckboxes,
      getHighSeasonExemptionsPayload,
      getValues,
      highSeasonEndDate,
      highSeasonStartDate,
      housing,
      isCountryWithTaxesCalc,
    ]);

    const getLowSeasonPayload = React.useCallback(() => {
      if (!highSeasonStartDate && !highSeasonEndDate) {
        return null;
      }

      const rules: Partial<SeasonRule>[] = [];
      const formValues = getValues();
      const maxNightsTaxed = formValues?.[FORM_NAMES.maxNightsTaxed];
      const lowPrice = formValues?.[FORM_NAMES.lowPrice] || MIN_PRICE;
      const exemptions = getLowSeasonExemptionsPayload();

      if (
        !isCountryWithTaxesCalc ||
        (isCountryWithTaxesCalc && ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan])
      ) {
        const lessThanAge = Number(formValues?.[FORM_NAMES.lessThanAge] || MIN_AGE);

        if (lessThanAge) {
          const lessThanPrice = Number(
            formValues?.[FORM_NAMES.lessThanLowSeasonPrice] || MIN_PRICE,
          );
          const lessThanRule: Partial<SeasonRule> = {
            less_than: lessThanAge,
            price: lessThanPrice,
          };

          if (lessThanRule) {
            rules.push(lessThanRule);
          }
        }
      }

      if (isCountryWithTaxesCalc && ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]) {
        const moreThanAge = Number(formValues?.[FORM_NAMES.moreThanAge] || MIN_AGE);

        if (moreThanAge) {
          const moreThanPrice = Number(
            formValues?.[FORM_NAMES.moreThanLowSeasonPrice] || MIN_PRICE,
          );

          const moreThanRule: Partial<SeasonRule> = {
            up_to: moreThanAge,
            price: moreThanPrice,
          };

          if (moreThanRule) {
            rules.push(moreThanRule);
          }
        }
      }

      if (isCountryWithTaxesCalc && ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
        const betweenHighAge = Number(formValues?.[FORM_NAMES.betweenHighAge] || MIN_AGE);
        const betweenLowAge = Number(formValues?.[FORM_NAMES.betweenLowAge] || MIN_AGE);
        const betweenPrice = Number(
          formValues?.[FORM_NAMES.betweenLowSeasonPrice] || MIN_PRICE,
        );

        if (betweenHighAge && betweenLowAge) {
          const betweenThanRule: Partial<SeasonRule> = {
            less_than: betweenHighAge,
            up_to: betweenLowAge,
            price: betweenPrice,
          };

          if (betweenThanRule) {
            rules.push(betweenThanRule);
          }
        }

        if (
          betweenHighAge &&
          !betweenLowAge &&
          !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
        ) {
          const lessThanRule: Partial<SeasonRule> = {
            less_than: betweenHighAge,
            price: betweenPrice,
          };

          if (lessThanRule) {
            rules.push(lessThanRule);
          }
        }
      }

      return {
        exemptions,
        rules,
        name: SEASON_NAMES.low,
        housing_id: housing?.id,
        from_date: null,
        to_date: null,
        price_per_night: lowPrice,
        max_nights: maxNightsTaxed || undefined,
        is_max_nights_taxed: Boolean(maxNightsTaxed),
      };
    }, [
      ageRulesCheckboxes,
      getLowSeasonExemptionsPayload,
      getValues,
      highSeasonEndDate,
      highSeasonStartDate,
      housing,
      isCountryWithTaxesCalc,
    ]);

    const resetAgeCalculatorDefaultValuesAndCheckboxes = React.useCallback(() => {
      setDefaultAgeCalculatorValues({});
      setAgeRulesCheckboxes({
        [AGE_RULES_CHECKBOXES.between]: false,
        [AGE_RULES_CHECKBOXES.moreThan]: false,
        [AGE_RULES_CHECKBOXES.lessThan]: false,
      });
    }, []);

    const resetTaxesAgeCalculator = React.useCallback(() => {
      setAgeRulesCheckboxes({
        [AGE_RULES_CHECKBOXES.between]: false,
        [AGE_RULES_CHECKBOXES.lessThan]: false,
        [AGE_RULES_CHECKBOXES.moreThan]: false,
      });

      setValue([
        {[FORM_NAMES.lessThanAge]: String(MIN_AGE)},
        {[FORM_NAMES.moreThanAge]: String(MIN_AGE)},
        {[FORM_NAMES.betweenLowAge]: String(MIN_AGE)},
        {[FORM_NAMES.betweenHighAge]: String(MIN_AGE)},
        {[FORM_NAMES.lessThanLowSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.lessThanHighSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.moreThanLowSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.moreThanHighSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.betweenLowSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.betweenHighSeasonPrice]: String(MIN_PRICE)},
      ]);
    }, [setValue]);

    const resetTaxesDetails = React.useCallback(() => {
      setHighSeasonStartDate(null);
      setHighSeasonEndDate(null);
      setLowSeasonStartDate(null);
      setLowSeasonEndDate(null);
      setInputValues(getInitInputValues());
      resetAgeCalculatorDefaultValuesAndCheckboxes();
      setExemptions([]);
      setIsMaxNightsTaxed(false);
      setIsHighSeasonPreloaded(false);
      resetTaxesAgeCalculator();
    }, [resetAgeCalculatorDefaultValuesAndCheckboxes, resetTaxesAgeCalculator]);

    const activateAgeRuleCheckbox = React.useCallback(
      (name: typeof AGE_RULES_CHECKBOXES[keyof typeof AGE_RULES_CHECKBOXES]) => {
        if (isCountryWithTaxesCalc) {
          setAgeRulesCheckboxes((prevState) => {
            return {
              ...prevState,
              [name]: true,
            };
          });
        }
      },
      [isCountryWithTaxesCalc],
    );

    const findLessThanRule = React.useCallback((season: Season) => {
      const rules = season?.rules;

      if (!rules?.length) {
        return null;
      }

      return rules.find((rule: SeasonRule) => {
        return rule.less_than && !rule.up_to;
      });
    }, []);

    const findMoreThanRule = React.useCallback((season: Season) => {
      const rules = season?.rules;

      if (!rules?.length) {
        return null;
      }

      return rules.find((rule: SeasonRule) => {
        return rule.up_to && !rule.less_than;
      });
    }, []);

    const findBetweenRule = React.useCallback((season: Season) => {
      const rules = season?.rules;

      if (!rules?.length) {
        return null;
      }

      return rules.find((rule: SeasonRule) => {
        return rule.up_to && rule.less_than;
      });
    }, []);

    const preloadHighSeasonData = React.useCallback(
      (season: Season) => {
        const startDate = season.from_date ? moment(season.from_date) : null;
        const endDate = season.to_date ? moment(season.to_date) : null;
        setHighSeasonStartDate(startDate);
        setHighSeasonEndDate(endDate);

        if (startDate && endDate) {
          const lowSeasonStartDate = endDate.clone().add(1, 'days');
          const lowSeasonEndDate = startDate.clone().subtract(1, 'days');
          setLowSeasonStartDate(lowSeasonStartDate);
          setLowSeasonEndDate(lowSeasonEndDate);
        }

        const pricePerNight =
          season?.price_per_night !== undefined ? season?.price_per_night : MIN_PRICE;
        setInputValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.highPrice]: String(pricePerNight),
          };
        });
        setValue(FORM_NAMES.highPrice, String(pricePerNight));

        const maxNightsTaxed = season?.max_nights || MIN_NIGHTS_NUMBER_TAXED;
        setInputValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.maxNightsTaxed]: String(maxNightsTaxed),
          };
        });
        setValue(FORM_NAMES.maxNightsTaxed, String(maxNightsTaxed));

        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.highPrice]: String(pricePerNight),
            [FORM_NAMES.maxNightsTaxed]: String(maxNightsTaxed),
          };
        });

        const isMaxNightsTaxes = Boolean(season?.is_max_nights_taxed);
        setIsMaxNightsTaxed(isMaxNightsTaxes);

        const lessThanRule = findLessThanRule(season);
        if (lessThanRule) {
          const lessThanAge = String(lessThanRule.less_than || MIN_AGE);
          const lessThanPrice = String(lessThanRule.price || MIN_PRICE);

          activateAgeRuleCheckbox(AGE_RULES_CHECKBOXES.lessThan);
          setInputValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.lessThanAge]: lessThanAge,
              [FORM_NAMES.lessThanHighSeasonPrice]: lessThanPrice,
            };
          });
          setValue([
            {[FORM_NAMES.lessThanAge]: lessThanAge},
            {[FORM_NAMES.lessThanHighSeasonPrice]: lessThanPrice},
          ]);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.lessThanAge]: lessThanAge,
              [FORM_NAMES.lessThanHighSeasonPrice]: lessThanPrice,
            };
          });
        }

        const moreThanRule = findMoreThanRule(season);
        if (moreThanRule) {
          const moreThanAge = String(moreThanRule.up_to || MIN_AGE);
          const moreThanPrice = String(moreThanRule.price || MIN_PRICE);

          activateAgeRuleCheckbox(AGE_RULES_CHECKBOXES.moreThan);
          setInputValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.moreThanAge]: moreThanAge,
              [FORM_NAMES.moreThanHighSeasonPrice]: moreThanPrice,
            };
          });
          setValue([
            {[FORM_NAMES.moreThanAge]: moreThanAge},
            {[FORM_NAMES.moreThanHighSeasonPrice]: moreThanPrice},
          ]);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.moreThanAge]: moreThanAge,
              [FORM_NAMES.moreThanHighSeasonPrice]: moreThanPrice,
            };
          });
        }

        const betweenRule = findBetweenRule(season);
        if (betweenRule) {
          const betweenHighAge = String(betweenRule.less_than || MIN_AGE);
          const betweenLowAge = String(betweenRule.up_to || MIN_PRICE);
          const betweenPrice = String(betweenRule.price || MIN_PRICE);

          activateAgeRuleCheckbox(AGE_RULES_CHECKBOXES.between);
          setInputValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.betweenHighAge]: betweenHighAge,
              [FORM_NAMES.betweenLowAge]: betweenLowAge,
              [FORM_NAMES.betweenHighSeasonPrice]: betweenPrice,
            };
          });
          setValue([
            {[FORM_NAMES.betweenHighAge]: betweenHighAge},
            {[FORM_NAMES.betweenLowAge]: betweenLowAge},
            {[FORM_NAMES.betweenHighSeasonPrice]: betweenPrice},
          ]);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.betweenHighAge]: betweenHighAge,
              [FORM_NAMES.betweenLowAge]: betweenLowAge,
              [FORM_NAMES.betweenHighSeasonPrice]: betweenPrice,
            };
          });
        }

        if (season?.exemptions?.length) {
          const nextExemptions: SelectOption[] = [];

          season?.exemptions.forEach((exemption) => {
            const existingExemption = taxExemptionsOptions.find((option) => {
              return option.value === exemption.exemption_type;
            });

            if (existingExemption) {
              nextExemptions.push(existingExemption);
            }
          });

          if (nextExemptions?.length) {
            setExemptions(nextExemptions);
          }
        }

        setIsHighSeasonPreloaded(true);
      },
      [
        activateAgeRuleCheckbox,
        findBetweenRule,
        findLessThanRule,
        findMoreThanRule,
        setUntouchedValues,
        setValue,
        taxExemptionsOptions,
      ],
    );

    const preloadLowSeasonData = React.useCallback(
      (season: Season) => {
        const pricePerNight =
          season?.price_per_night !== undefined ? season?.price_per_night : MIN_PRICE;
        setInputValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.lowPrice]: String(pricePerNight),
          };
        });
        setValue(FORM_NAMES.lowPrice, String(pricePerNight));
        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.lowPrice]: String(pricePerNight),
          };
        });

        const lessThanRule = findLessThanRule(season);
        if (lessThanRule) {
          const lessThanPrice = String(lessThanRule.price || MIN_PRICE);
          setInputValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.lessThanLowSeasonPrice]: lessThanPrice,
            };
          });
          setValue([{[FORM_NAMES.lessThanLowSeasonPrice]: lessThanPrice}]);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.lessThanLowSeasonPrice]: lessThanPrice,
            };
          });
        }

        const moreThanRule = findMoreThanRule(season);
        if (moreThanRule) {
          const moreThanPrice = String(moreThanRule.price || MIN_PRICE);
          setInputValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.moreThanLowSeasonPrice]: moreThanPrice,
            };
          });
          setValue([{[FORM_NAMES.moreThanLowSeasonPrice]: moreThanPrice}]);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.moreThanLowSeasonPrice]: moreThanPrice,
            };
          });
        }

        const betweenRule = findBetweenRule(season);
        if (betweenRule) {
          const betweenPrice = String(betweenRule.price || MIN_PRICE);
          setInputValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.betweenLowSeasonPrice]: betweenPrice,
            };
          });
          setValue([{[FORM_NAMES.betweenLowSeasonPrice]: betweenPrice}]);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.betweenLowSeasonPrice]: betweenPrice,
            };
          });
        }
      },
      [findBetweenRule, findLessThanRule, findMoreThanRule, setUntouchedValues, setValue],
    );

    React.useImperativeHandle(ref, () => {
      return {
        getHighSeasonPayload,
        getLowSeasonPayload,
        resetTaxesDetails,
        active: isSectionActive,
      };
    });

    React.useEffect(() => {
      if (disabled) {
        return;
      }

      const hasTaxes = Boolean(housing?.seasons?.length);
      setIsSectionActive(hasTaxes);
    }, [housing, disabled]);

    React.useEffect(
      function setHighSeasonDetailsOnSectionToggle() {
        if (!isSectionActive || areSeasonsLoading || !highSeason || isCreationModalUsed) {
          return;
        }

        setIsHighSeasonPreloaded(false);
        preloadHighSeasonData(highSeason);
      },
      [
        areSeasonsLoading,
        highSeason,
        isCreationModalUsed,
        isSectionActive,
        preloadHighSeasonData,
      ],
    );

    React.useEffect(
      function setLowSeasonDetails() {
        if (
          !lowSeason ||
          areSeasonsLoading ||
          !isHighSeasonPreloaded ||
          isCreationModalUsed
        ) {
          return;
        }

        preloadLowSeasonData(lowSeason);
      },
      [
        lowSeason,
        preloadLowSeasonData,
        areSeasonsLoading,
        isHighSeasonPreloaded,
        isCreationModalUsed,
      ],
    );

    const setSectionTouched = React.useCallback(() => {
      if (setIsSectionTouched && isSectionActive) {
        setIsSectionTouched(true);
      }
    }, [isSectionActive, setIsSectionTouched]);

    React.useEffect(() => {
      if (isFormTouched) {
        setSectionTouched();
      }
    }, [isFormTouched, setSectionTouched]);

    const setFieldValueAndValidateIt = async (
      name: typeof FORM_NAMES[keyof typeof FORM_NAMES],
      value: string,
      forceValidation = false,
    ) => {
      setValue(name, value);
      if (isSubmitted || forceValidation) {
        await triggerValidation(name);
      }
    };

    const toggleIsSectionActive = () => {
      const hasAnySeason = Boolean(highSeason || lowSeason);

      setIsSectionActive((prevState) => {
        setIsCreationModalUsed(false);

        if (!prevState) {
          resetTaxesDetails();

          if (!hasAnySeason) {
            openTaxesModal();
            return prevState;
          }
        }

        setSectionTouched();
        return !prevState;
      });
    };

    const getIsNegativeValue = (value: string) => {
      return value && Number(value) < 0;
    };

    const getIsIntegerFieldFloat = (value: string, name: FORM_NAMES) => {
      const integerFields = [FORM_NAMES.lessThanAge, FORM_NAMES.maxNightsTaxed];
      const mustBeIntegerField = value && integerFields.includes(name);

      return mustBeIntegerField && !Number.isInteger(Number(value));
    };

    const handleInputChange = (event: InputEventType) => {
      const {value, name} = event.target;
      const isNegative = getIsNegativeValue(value);
      const isIntegerFieldFloat = getIsIntegerFieldFloat(value, name as FORM_NAMES);

      if (isNegative || isIntegerFieldFloat) {
        return;
      }

      setInputValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      setSectionTouched();
    };

    const resetMoreLessThanAgeFields = (forceValidation = false) => {
      setValue([
        {[FORM_NAMES.moreThanAge]: String(MIN_AGE)},
        {[FORM_NAMES.lessThanAge]: String(MIN_AGE)},
        {[FORM_NAMES.moreThanLowSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.moreThanHighSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.lessThanLowSeasonPrice]: String(MIN_PRICE)},
        {[FORM_NAMES.lessThanHighSeasonPrice]: String(MIN_PRICE)},
      ]);
      if (isSubmitted || forceValidation) {
        triggerValidation([
          FORM_NAMES.moreThanAge,
          FORM_NAMES.lessThanAge,
          FORM_NAMES.moreThanLowSeasonPrice,
          FORM_NAMES.moreThanHighSeasonPrice,
          FORM_NAMES.lessThanLowSeasonPrice,
          FORM_NAMES.lessThanHighSeasonPrice,
        ]);
      }
    };

    const preloadMoreLessThanAgeFields = async (forceValidation = false) => {
      const formValues = getValues();
      const betweenLowAge = formValues[FORM_NAMES.betweenLowAge];
      const betweenHighAge = formValues[FORM_NAMES.betweenHighAge];

      if (betweenLowAge) {
        await setFieldValueAndValidateIt(
          FORM_NAMES.moreThanAge,
          betweenLowAge,
          forceValidation,
        );

        if (isSubmitted || forceValidation) {
          await triggerValidation(FORM_NAMES.lessThanAge);
        }
      }
      if (betweenHighAge) {
        await setFieldValueAndValidateIt(
          FORM_NAMES.lessThanAge,
          betweenHighAge,
          forceValidation,
        );

        if (isSubmitted || forceValidation) {
          await triggerValidation(FORM_NAMES.moreThanAge);
        }
      }
    };

    const resetBetweenAgeFields = (forceValidation = false) => {
      setValue([
        {[FORM_NAMES.betweenHighAge]: String(MIN_AGE)},
        {[FORM_NAMES.betweenLowAge]: String(MIN_AGE)},
        {
          [FORM_NAMES.betweenLowSeasonPrice]: String(MIN_PRICE),
        },
        {[FORM_NAMES.betweenHighSeasonPrice]: String(MIN_PRICE)},
      ]);

      if (isSubmitted || forceValidation) {
        triggerValidation([
          FORM_NAMES.betweenLowSeasonPrice,
          FORM_NAMES.betweenHighSeasonPrice,
          FORM_NAMES.betweenHighAge,
          FORM_NAMES.betweenLowAge,
        ]);
      }
    };

    const preloadBetweenAgeFields = async (forceValidation = false) => {
      const formValues = getValues();
      const moreThanAge = formValues[FORM_NAMES.moreThanAge];
      const lessThanAge = formValues[FORM_NAMES.lessThanAge];

      if (moreThanAge) {
        await setFieldValueAndValidateIt(
          FORM_NAMES.betweenLowAge,
          moreThanAge,
          forceValidation,
        );
        if (isSubmitted || forceValidation) {
          await triggerValidation(FORM_NAMES.betweenHighAge);
        }
      }

      if (lessThanAge) {
        await setFieldValueAndValidateIt(
          FORM_NAMES.betweenHighAge,
          lessThanAge,
          forceValidation,
        );
        if (isSubmitted || forceValidation) {
          await triggerValidation(FORM_NAMES.betweenLowAge);
        }
      }
    };

    const handleAgeRulesCheckboxesSet = (name?: string, forceValidation = false) => {
      if (
        name === AGE_RULES_CHECKBOXES.lessThan ||
        name === AGE_RULES_CHECKBOXES.moreThan
      ) {
        setAgeRulesCheckboxes((prevState) => {
          const prevCheckedState = prevState[name];
          if (prevCheckedState) {
            resetMoreLessThanAgeFields(forceValidation);
          } else {
            preloadMoreLessThanAgeFields(forceValidation);
          }

          return {
            ...prevState,
            [AGE_RULES_CHECKBOXES.lessThan]: !prevCheckedState,
            [AGE_RULES_CHECKBOXES.moreThan]: !prevCheckedState,
          };
        });
      }

      if (name === AGE_RULES_CHECKBOXES.between) {
        setAgeRulesCheckboxes((prevState) => {
          const prevCheckedState = prevState[name];
          if (prevCheckedState) {
            resetBetweenAgeFields(forceValidation);
          } else {
            preloadBetweenAgeFields(forceValidation);
          }

          return {
            ...prevState,
            [AGE_RULES_CHECKBOXES.between]: !prevCheckedState,
          };
        });
      }
    };

    const handleAgeRuleCheckboxCheck = (name?: string) => {
      handleAgeRulesCheckboxesSet(name);
      setSectionTouched();
    };

    const handleModalAgeRuleCheckboxCheck = (name?: string) => {
      const forceValidation = true;
      handleAgeRulesCheckboxesSet(name, forceValidation);
    };

    const resetSeasons = () => {
      setHighSeasonStartDate(null);
      setHighSeasonEndDate(null);
      setLowSeasonStartDate(null);
      setLowSeasonEndDate(null);
    };

    const resetExemptions = () => {
      setExemptions([]);
    };

    const handleImportTaxesModalSubmit = (seasons: Season[]) => {
      const highSeason = seasons[0];
      const lowSeason = seasons[1];

      setIsSectionActive(true);
      if (highSeason) {
        preloadHighSeasonData(highSeason);

        if (lowSeason) {
          preloadLowSeasonData(lowSeason);
        }
      }

      setSectionTouched();
      closeImportTaxesModal();
      closeTaxesModal();
    };

    const handleModalHighSeasonDatesChange = ({
      startDate,
      endDate,
    }: {
      startDate: Moment | null;
      endDate: Moment | null;
    }) => {
      setSectionTouched();

      if (!startDate || !endDate) {
        setLowSeasonStartDate(null);
        setLowSeasonEndDate(null);
        setInputValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.lowPrice]: '',
          };
        });
      } else {
        const lowSeasonStartDate = endDate.clone().add(1, 'days');
        const lowSeasonEndDate = startDate.clone().subtract(1, 'days');

        setLowSeasonStartDate(lowSeasonStartDate);
        setLowSeasonEndDate(lowSeasonEndDate);
      }

      setHighSeasonStartDate(startDate);
      setHighSeasonEndDate(endDate);
    };

    const handleHighSeasonDatesChange = ({
      startDate,
      endDate,
    }: {
      startDate: Moment | null;
      endDate: Moment | null;
    }) => {
      setSectionTouched();

      if (startDate && !endDate) {
        const nextEndDate = startDate.clone().add(1, 'day');
        setHighSeasonEndDate(nextEndDate);
        setHighSeasonStartDate(startDate);

        const lowSeasonStartDate = nextEndDate.clone().add(1, 'days');
        const lowSeasonEndDate = startDate.clone().subtract(1, 'days');
        setLowSeasonStartDate(lowSeasonStartDate);
        setLowSeasonEndDate(lowSeasonEndDate);

        return;
      }

      if (endDate && !startDate) {
        const nextStartDate = endDate.clone().subtract(1, 'day');
        setHighSeasonStartDate(nextStartDate);
        setHighSeasonEndDate(endDate);

        const lowSeasonStartDate = endDate.clone().add(1, 'days');
        const lowSeasonEndDate = nextStartDate.clone().subtract(1, 'days');
        setLowSeasonStartDate(lowSeasonStartDate);
        setLowSeasonEndDate(lowSeasonEndDate);

        return;
      }

      if (endDate && startDate) {
        const lowSeasonStartDate = endDate.clone().add(1, 'days');
        const lowSeasonEndDate = startDate.clone().subtract(1, 'days');
        setLowSeasonStartDate(lowSeasonStartDate);
        setLowSeasonEndDate(lowSeasonEndDate);
      }

      setHighSeasonStartDate(startDate);
      setHighSeasonEndDate(endDate);
    };

    const handleLowSeasonDatesChange = ({
      startDate,
      endDate,
    }: {
      startDate: Moment | null;
      endDate: Moment | null;
    }) => {
      setSectionTouched();
      setLowSeasonStartDate(startDate);
      setLowSeasonEndDate(endDate);
    };

    const handleExemptionChange = (nextExemptions: SelectOption[]) => {
      setExemptions(nextExemptions);
      setSectionTouched();
    };

    const handleIsMaxNightsTaxedSwitch = (checked: boolean) => {
      if (!checked) {
        setInputValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.maxNightsTaxed]: '',
          };
        });
      }

      setIsMaxNightsTaxed(checked);
      setSectionTouched();
    };

    const saveAgeCalculatorDefaultValues = () => {
      const formValues: any = getValues();
      const defaultValues: {[key: string]: any} = {};

      Object.keys(formValues).forEach((key) => {
        if (AGE_CALCULATOR_FORM_NAMES.includes(key as FORM_NAMES)) {
          defaultValues[key] = formValues[key];
        }
      });
      setDefaultAgeCalculatorValues(defaultValues);
    };

    const validateSelf = (e: InputEventType) => {
      const fieldName = e.target.name as typeof FORM_NAMES[keyof typeof FORM_NAMES];
      triggerValidation(fieldName);
    };

    const restartTaxesCreation = () => {
      setIsSectionActive(false);
      resetTaxesDetails();
      openTaxesModal();
    };

    const handleFinish = () => {
      const isMaxNightsTaxed = Boolean(inputValues[FORM_NAMES.maxNightsTaxed]);
      if (isMaxNightsTaxed) {
        setIsMaxNightsTaxed(true);
      }

      setIsCreationModalUsed(true);
      closeTaxesModal();
      setIsSectionActive(true);
      setIsSectionTouched(true);
    };

    return (
      <Section
        title={
          <div>
            {t('tourist_taxes')}
            <GreenSectionTag>{t('beta')}</GreenSectionTag>
          </div>
        }
        subtitle={t('tourist_taxes_subtitle')}
      >
        {isExportTaxesModalOpen && (
          <ExportTaxesSettingsModal
            open
            housing={housing}
            getHighSeasonPayload={getHighSeasonPayload}
            getLowSeasonPayload={getLowSeasonPayload}
            onClose={closeExportTaxesModal}
          />
        )}
        <Switch
          checked={isSectionActive}
          onChange={toggleIsSectionActive}
          label={t('activate_tourist_taxes')}
          disabled={disabled}
        />
        {isSectionActive && (
          <div>
            {areSeasonsLoading ? (
              <LoaderWrapper>
                <Loader label={t('loading')} height={45} width={45} />
              </LoaderWrapper>
            ) : (
              <div>
                <RestartTaxesModalButtonWrapper>
                  <Button
                    secondary
                    disabled={disabled}
                    label={t('restart_taxes_creation')}
                    onClick={restartTaxesCreation}
                  />
                </RestartTaxesModalButtonWrapper>
                {highSeasonStartDate && highSeasonEndDate ? (
                  <SeasonsGroup>
                    <SeasonContainer>
                      <SeasonLabel>{t('high_season')}</SeasonLabel>
                      <SeasonDatePickerWrapper>
                        <DayMonthPicker
                          readOnly
                          startDate={highSeasonStartDate}
                          endDate={highSeasonEndDate}
                          startDateId="start-high-date"
                          endDateId="end-high-date"
                          displayFormat="DD-MM"
                          showClearDates={false}
                          startDatePlaceholderText="00-00"
                          endDatePlaceholderText="00-00"
                          focusedInput={focusedHighSeasonInput}
                          renderMonthElement={({month}) => (
                            <MonthElement>{moment(month).format('MMMM')}</MonthElement>
                          )}
                          initialVisibleMonth={() => {
                            if (highSeasonStartDate) {
                              return highSeasonStartDate;
                            }

                            return moment('01-01-2020', 'DD-MM-YYYY');
                          }}
                          isOutsideRange={(day) => {
                            const leftBoundary = moment('01-01-2020', 'DD-MM-YYYY');
                            const rightBoundary = moment('31-12-2020', 'DD-MM-YYYY');

                            return (
                              day.isBefore(leftBoundary) || day.isAfter(rightBoundary)
                            );
                          }}
                          label={t('from_to')}
                          onFocusChange={(focusedInput) =>
                            setFocusedHighSeasonInput(focusedInput)
                          }
                          onDatesChange={handleHighSeasonDatesChange}
                          disabled={disabled}
                        />
                      </SeasonDatePickerWrapper>
                      {!isCountryWithTaxesCalc && displayFields[FORM_NAMES.highPrice] && (
                        <ShortInputWrapper>
                          <ShortInput
                            ref={register({
                              required: t('required') as string,
                              min: {
                                value: MIN_PRICE,
                                message: t('min_number_is', {number: MIN_PRICE}),
                              },
                            })}
                            defaultValue={inputValues[FORM_NAMES.highPrice]}
                            error={errors[FORM_NAMES.highPrice]?.message}
                            label={t('price_per_night')}
                            placeholder={t('enter_price')}
                            name={FORM_NAMES.highPrice}
                            step="0.01"
                            inputMode="decimal"
                            type="number"
                            disabled={disabled}
                          />
                          <ShortInputCurrency>€</ShortInputCurrency>
                        </ShortInputWrapper>
                      )}
                    </SeasonContainer>
                    <SeasonContainer>
                      <SeasonLabel>{t('low_season')}</SeasonLabel>
                      <SeasonDatePickerWrapper>
                        <LowSeasonDayMonthPicker
                          disabled
                          readOnly
                          showClearDates={false}
                          displayFormat="DD-MM"
                          startDate={lowSeasonStartDate}
                          endDate={lowSeasonEndDate}
                          startDatePlaceholderText="00-00"
                          endDatePlaceholderText="00-00"
                          startDateId="start-low-date"
                          endDateId="end-low-date"
                          focusedInput={focusedLowSeasonInput}
                          label={t('from_to')}
                          isOutsideRange={(day) => {
                            return day.isSameOrBefore(highSeasonEndDate);
                          }}
                          onFocusChange={(focusedInput) =>
                            setFocusedLowSeasonInput(focusedInput)
                          }
                          onDatesChange={handleLowSeasonDatesChange}
                        />
                      </SeasonDatePickerWrapper>
                      {!isCountryWithTaxesCalc && displayFields[FORM_NAMES.lowPrice] && (
                        <ShortInputWrapper>
                          <ShortInput
                            ref={register({
                              required: t('required') as string,
                              min: {
                                value: MIN_PRICE,
                                message: t('min_number_is', {number: MIN_PRICE}),
                              },
                            })}
                            error={errors[FORM_NAMES.lowPrice]?.message}
                            label={t('price_per_night')}
                            placeholder={t('enter_price')}
                            defaultValue={inputValues[FORM_NAMES.lowPrice]}
                            name={FORM_NAMES.lowPrice}
                            disabled={disabled}
                            step="0.01"
                            inputMode="decimal"
                            type="number"
                          />
                          <ShortInputCurrency
                            disabled={Boolean(
                              !highSeasonStartDate || !highSeasonEndDate || disabled,
                            )}
                          >
                            €
                          </ShortInputCurrency>
                        </ShortInputWrapper>
                      )}
                    </SeasonContainer>
                  </SeasonsGroup>
                ) : (
                  <div>
                    {!isCountryWithTaxesCalc && displayFields[FORM_NAMES.highPrice] && (
                      <SinglePricePerNightWrapper>
                        <ShortInput
                          ref={register({
                            required: t('required') as string,
                            min: {
                              value: MIN_PRICE,
                              message: t('min_number_is', {number: MIN_PRICE}),
                            },
                          })}
                          defaultValue={inputValues[FORM_NAMES.highPrice]}
                          error={errors[FORM_NAMES.highPrice]?.message}
                          label={t('price_per_night')}
                          placeholder={t('enter_price')}
                          name={FORM_NAMES.highPrice}
                          inputMode="decimal"
                          type="number"
                          step="0.01"
                          disabled={disabled}
                        />
                        <ShortInputCurrency>€</ShortInputCurrency>
                      </SinglePricePerNightWrapper>
                    )}
                  </div>
                )}
                {isCountryWithTaxesCalc && (
                  <div>
                    <AgeRulesLabel>{t('prices')}</AgeRulesLabel>
                    <AgeRulesContainer>
                      <AgeRuleRow
                        active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                      >
                        <CheckboxWrapper
                          checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                        >
                          <Checkbox
                            disabled={disabled}
                            onChange={handleAgeRuleCheckboxCheck}
                            checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                            name={AGE_RULES_CHECKBOXES.lessThan}
                          />
                        </CheckboxWrapper>
                        <AgeRuleNameButton
                          type="button"
                          disabled={disabled}
                          grayed={
                            disabled || !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
                          }
                          onClick={() =>
                            handleAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.lessThan)
                          }
                        >
                          {t('less_than')}
                        </AgeRuleNameButton>
                        <AgeRuleInput
                          disabled={
                            disabled || !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
                          }
                          label={t('age')}
                          placeholder={MIN_AGE}
                          defaultValue={
                            defaultAgeCalculatorValues[FORM_NAMES.lessThanAge] || MIN_AGE
                          }
                          error={errors[FORM_NAMES.lessThanAge]?.message}
                          name={FORM_NAMES.lessThanAge}
                          type="number"
                          inputMode="numeric"
                          onChange={(e: InputEventType) => {
                            if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
                              setFieldValueAndValidateIt(
                                FORM_NAMES.betweenLowAge,
                                e.target.value,
                              );
                            }

                            if (isSubmitted) {
                              triggerValidation([
                                FORM_NAMES.moreThanAge,
                                FORM_NAMES.betweenHighAge,
                              ]);
                            }
                          }}
                          ref={register({
                            validate: (value) => {
                              const moreThanAge = parseInt(
                                getValues()[FORM_NAMES.moreThanAge],
                                10,
                              );
                              if (moreThanAge > 0 && value > moreThanAge) {
                                return t('max_number', {number: moreThanAge}) as string;
                              }

                              if (value < MIN_AGE) {
                                return t('min_number', {number: MIN_AGE}) as string;
                              }
                            },
                          })}
                        />
                        <RelativeWrapper>
                          <AgeRuleSeasonInput
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
                            }
                            label={hasTwoSeasons ? t('high_season_price') : t('price')}
                            placeholder={MIN_PRICE}
                            defaultValue={
                              defaultAgeCalculatorValues[
                                FORM_NAMES.lessThanHighSeasonPrice
                              ] || MIN_PRICE
                            }
                            error={errors[FORM_NAMES.lessThanHighSeasonPrice]?.message}
                            name={FORM_NAMES.lessThanHighSeasonPrice}
                            type="number"
                            step="0.01"
                            inputMode="decimal"
                            ref={register({
                              min: {
                                value: MIN_PRICE,
                                message: t('min_number', {number: MIN_PRICE}),
                              },
                            })}
                          />
                          <AgeRuleSeasonInputSideLabel
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
                            }
                          >
                            € /{t('night').toLowerCase()}
                          </AgeRuleSeasonInputSideLabel>
                        </RelativeWrapper>
                        {hasTwoSeasons && (
                          <RelativeWrapper>
                            <AgeRuleSeasonInput
                              disabled={
                                disabled ||
                                !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
                              }
                              label={t('low_season_price')}
                              placeholder={MIN_PRICE}
                              defaultValue={
                                defaultAgeCalculatorValues[
                                  FORM_NAMES.lessThanLowSeasonPrice
                                ] || MIN_PRICE
                              }
                              error={errors[FORM_NAMES.lessThanLowSeasonPrice]?.message}
                              name={FORM_NAMES.lessThanLowSeasonPrice}
                              type="number"
                              step="0.01"
                              inputMode="decimal"
                              ref={register({
                                min: {
                                  value: MIN_PRICE,
                                  message: t('min_number', {number: MIN_PRICE}),
                                },
                              })}
                            />
                            <AgeRuleSeasonInputSideLabel
                              disabled={
                                disabled ||
                                !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]
                              }
                            >
                              € /{t('night').toLowerCase()}
                            </AgeRuleSeasonInputSideLabel>
                          </RelativeWrapper>
                        )}
                      </AgeRuleRow>
                      <AgeRuleRow
                        active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      >
                        <CheckboxWrapper
                          checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                        >
                          <Checkbox
                            disabled={disabled}
                            onChange={handleAgeRuleCheckboxCheck}
                            checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                            name={AGE_RULES_CHECKBOXES.between}
                          />
                        </CheckboxWrapper>
                        <AgeRuleNameButton
                          type="button"
                          disabled={disabled}
                          grayed={
                            disabled || !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                          }
                          onClick={() =>
                            handleAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.between)
                          }
                        >
                          {t('between')}
                        </AgeRuleNameButton>
                        <BetweenAgesWrapper>
                          <AgeRuleInput
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                            }
                            label={t('age')}
                            placeholder={MIN_AGE}
                            defaultValue={
                              defaultAgeCalculatorValues[FORM_NAMES.betweenLowAge] ||
                              MIN_AGE
                            }
                            error={errors[FORM_NAMES.betweenLowAge]?.message}
                            name={FORM_NAMES.betweenLowAge}
                            type="number"
                            inputMode="numeric"
                            onChange={(e: InputEventType) => {
                              if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]) {
                                setFieldValueAndValidateIt(
                                  FORM_NAMES.lessThanAge,
                                  e.target.value,
                                );
                              }

                              if (isSubmitted) {
                                triggerValidation([
                                  FORM_NAMES.moreThanAge,
                                  FORM_NAMES.betweenHighAge,
                                ]);
                              }
                            }}
                            ref={register({
                              validate: (value) => {
                                const betweenHighAge = parseInt(
                                  getValues()[FORM_NAMES.betweenHighAge],
                                  10,
                                );
                                if (betweenHighAge > 0 && value > betweenHighAge) {
                                  return t('max_number', {
                                    number: betweenHighAge,
                                  }) as string;
                                }

                                if (value < MIN_AGE) {
                                  return t('min_number', {number: MIN_AGE}) as string;
                                }
                              },
                            })}
                          />
                          <BetweenAgesText
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                            }
                          >
                            {t('and').toLowerCase()}
                          </BetweenAgesText>
                          <AgeRuleInput
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                            }
                            label={t('age')}
                            placeholder={MIN_AGE}
                            defaultValue={
                              defaultAgeCalculatorValues[FORM_NAMES.betweenHighAge] ||
                              MIN_AGE
                            }
                            error={errors[FORM_NAMES.betweenHighAge]?.message}
                            name={FORM_NAMES.betweenHighAge}
                            onChange={(e: InputEventType) => {
                              if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]) {
                                setFieldValueAndValidateIt(
                                  FORM_NAMES.moreThanAge,
                                  e.target.value,
                                );
                              }

                              if (isSubmitted) {
                                triggerValidation([
                                  FORM_NAMES.lessThanAge,
                                  FORM_NAMES.betweenLowAge,
                                ]);
                              }
                            }}
                            type="number"
                            inputMode="numeric"
                            ref={register({
                              validate: (value) => {
                                const betweenLowAge = parseInt(
                                  getValues()[FORM_NAMES.betweenLowAge],
                                  10,
                                );
                                if (betweenLowAge > 0 && value < betweenLowAge) {
                                  return t('min_number', {
                                    number: betweenLowAge,
                                  }) as string;
                                }

                                if (value < MIN_AGE) {
                                  return t('min_number', {number: MIN_AGE}) as string;
                                }
                              },
                            })}
                          />
                        </BetweenAgesWrapper>
                        <RelativeWrapper>
                          <AgeRuleSeasonInput
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                            }
                            label={hasTwoSeasons ? t('high_season_price') : t('price')}
                            placeholder={MIN_PRICE}
                            defaultValue={
                              defaultAgeCalculatorValues[
                                FORM_NAMES.betweenHighSeasonPrice
                              ] || MIN_PRICE
                            }
                            error={errors[FORM_NAMES.betweenHighSeasonPrice]?.message}
                            name={FORM_NAMES.betweenHighSeasonPrice}
                            type="number"
                            step="0.01"
                            inputMode="decimal"
                            ref={register({
                              min: {
                                value: MIN_PRICE,
                                message: t('min_number', {number: MIN_PRICE}),
                              },
                            })}
                          />
                          <AgeRuleSeasonInputSideLabel
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                            }
                          >
                            € /{t('night').toLowerCase()}
                          </AgeRuleSeasonInputSideLabel>
                        </RelativeWrapper>
                        {hasTwoSeasons && (
                          <RelativeWrapper>
                            <AgeRuleSeasonInput
                              disabled={
                                disabled ||
                                !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                              }
                              label={t('low_season_price')}
                              placeholder={MIN_PRICE}
                              defaultValue={
                                defaultAgeCalculatorValues[
                                  FORM_NAMES.betweenLowSeasonPrice
                                ] || MIN_PRICE
                              }
                              error={errors[FORM_NAMES.betweenLowSeasonPrice]?.message}
                              name={FORM_NAMES.betweenLowSeasonPrice}
                              type="number"
                              step="0.01"
                              inputMode="decimal"
                              ref={register({
                                min: {
                                  value: MIN_PRICE,
                                  message: t('min_number', {number: MIN_PRICE}),
                                },
                              })}
                            />
                            <AgeRuleSeasonInputSideLabel
                              disabled={
                                disabled ||
                                !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]
                              }
                            >
                              € /{t('night').toLowerCase()}
                            </AgeRuleSeasonInputSideLabel>
                          </RelativeWrapper>
                        )}
                      </AgeRuleRow>
                      <AgeRuleRow
                        active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                      >
                        <CheckboxWrapper
                          checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                        >
                          <Checkbox
                            disabled={disabled}
                            onChange={handleAgeRuleCheckboxCheck}
                            checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                            name={AGE_RULES_CHECKBOXES.moreThan}
                          />
                        </CheckboxWrapper>
                        <AgeRuleNameButton
                          type="button"
                          disabled={disabled}
                          grayed={
                            disabled || !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]
                          }
                          onClick={() =>
                            handleAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.moreThan)
                          }
                        >
                          {t('more_than')}
                        </AgeRuleNameButton>
                        <AgeRuleInput
                          disabled={
                            disabled || !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]
                          }
                          label={t('age')}
                          placeholder={MIN_AGE}
                          defaultValue={
                            defaultAgeCalculatorValues[FORM_NAMES.moreThanAge] || MIN_AGE
                          }
                          error={errors[FORM_NAMES.moreThanAge]?.message}
                          name={FORM_NAMES.moreThanAge}
                          onChange={(e: InputEventType) => {
                            if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
                              setFieldValueAndValidateIt(
                                FORM_NAMES.betweenHighAge,
                                e.target.value,
                              );
                            }

                            if (isSubmitted) {
                              triggerValidation([
                                FORM_NAMES.lessThanAge,
                                FORM_NAMES.betweenLowAge,
                              ]);
                            }
                          }}
                          type="number"
                          inputMode="numeric"
                          ref={register({
                            validate: (value) => {
                              const lessThanAge = parseInt(
                                getValues()[FORM_NAMES.lessThanAge],
                                10,
                              );
                              if (lessThanAge > 0 && value < lessThanAge) {
                                return t('min_number', {number: lessThanAge}) as string;
                              }

                              if (value < MIN_AGE) {
                                return t('min_number', {number: MIN_AGE}) as string;
                              }
                            },
                          })}
                        />
                        <RelativeWrapper>
                          <AgeRuleSeasonInput
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]
                            }
                            label={hasTwoSeasons ? t('high_season_price') : t('price')}
                            placeholder={MIN_PRICE}
                            defaultValue={
                              defaultAgeCalculatorValues[
                                FORM_NAMES.moreThanHighSeasonPrice
                              ] || MIN_PRICE
                            }
                            error={errors[FORM_NAMES.moreThanHighSeasonPrice]?.message}
                            name={FORM_NAMES.moreThanHighSeasonPrice}
                            type="number"
                            step="0.01"
                            inputMode="decimal"
                            ref={register({
                              min: {
                                value: MIN_PRICE,
                                message: t('min_number', {number: MIN_PRICE}),
                              },
                            })}
                          />
                          <AgeRuleSeasonInputSideLabel
                            disabled={
                              disabled ||
                              !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]
                            }
                          >
                            € /{t('night').toLowerCase()}
                          </AgeRuleSeasonInputSideLabel>
                        </RelativeWrapper>
                        {hasTwoSeasons && (
                          <RelativeWrapper>
                            <AgeRuleSeasonInput
                              disabled={
                                disabled ||
                                !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]
                              }
                              label={t('low_season_price')}
                              placeholder={MIN_PRICE}
                              defaultValue={
                                defaultAgeCalculatorValues[
                                  FORM_NAMES.moreThanLowSeasonPrice
                                ] || MIN_PRICE
                              }
                              error={errors[FORM_NAMES.moreThanLowSeasonPrice]?.message}
                              name={FORM_NAMES.moreThanLowSeasonPrice}
                              type="number"
                              step="0.01"
                              inputMode="decimal"
                              ref={register({
                                min: {
                                  value: MIN_PRICE,
                                  message: t('min_number', {number: MIN_PRICE}),
                                },
                              })}
                            />
                            <AgeRuleSeasonInputSideLabel
                              disabled={
                                disabled ||
                                !ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]
                              }
                            >
                              € /{t('night').toLowerCase()}
                            </AgeRuleSeasonInputSideLabel>
                          </RelativeWrapper>
                        )}
                      </AgeRuleRow>
                    </AgeRulesContainer>
                  </div>
                )}
                <ExceptionsLabel>{t('exceptions')}</ExceptionsLabel>
                <ExceptionsRules>
                  {hasLessThanAgeRule && (
                    <div>
                      <LessThanRuleWrapper>
                        <ExceptionRuleWrapper>
                          <ShortInput
                            readOnly
                            disabled={disabled}
                            label={t('rule')}
                            value={t('less_than')}
                          />
                        </ExceptionRuleWrapper>
                        {displayFields[FORM_NAMES.lessThanAge] && (
                          <ShortInput
                            ref={register({
                              required: t('required') as string,
                              min: {
                                value: MIN_AGE,
                                message: t('min_number_is', {number: MIN_AGE}),
                              },
                              validate: (value: string) => {
                                const isFloat = getIsIntegerFieldFloat(
                                  value,
                                  FORM_NAMES.lessThanAge,
                                );

                                return !isFloat || (t('invalid') as string);
                              },
                            })}
                            error={errors[FORM_NAMES.lessThanAge]?.message}
                            label={t('age')}
                            placeholder={t('enter_age')}
                            name={FORM_NAMES.lessThanAge}
                            defaultValue={inputValues[FORM_NAMES.lessThanAge]}
                            inputMode="numeric"
                            type="number"
                            disabled={disabled}
                          />
                        )}
                      </LessThanRuleWrapper>
                    </div>
                  )}
                  <ExceptionsMultiSelectWrapper>
                    <MultiSelect
                      isSearchable
                      label={t('name_of_exceptions')}
                      placeholder={t('enter_exceptions')}
                      options={taxExemptionsOptions}
                      onChange={handleExemptionChange}
                      value={exemptions}
                      disabled={disabled}
                    />
                  </ExceptionsMultiSelectWrapper>
                  <Switch
                    onChange={handleIsMaxNightsTaxedSwitch}
                    checked={isMaxNightsTaxed}
                    label={t('max_nights_taxed')}
                    disabled={disabled}
                  />
                  {isMaxNightsTaxed && displayFields[FORM_NAMES.maxNightsTaxed] && (
                    <MaxNightsTaxedInputWrapper>
                      <ShortInput
                        ref={register({
                          required: t('required') as string,
                          min: {
                            value: MIN_NIGHTS_NUMBER_TAXED,
                            message: t('min_number_is', {
                              number: MIN_NIGHTS_NUMBER_TAXED,
                            }),
                          },
                          validate: (value: string) => {
                            const isFloat = getIsIntegerFieldFloat(
                              value,
                              FORM_NAMES.maxNightsTaxed,
                            );

                            return !isFloat || (t('invalid') as string);
                          },
                        })}
                        error={errors[FORM_NAMES.maxNightsTaxed]?.message}
                        label={t('number_of_nights')}
                        name={FORM_NAMES.maxNightsTaxed}
                        defaultValue={inputValues[FORM_NAMES.maxNightsTaxed]}
                        type="number"
                        inputMode="numeric"
                        placeholder={t('enter_number')}
                        disabled={disabled}
                      />
                    </MaxNightsTaxedInputWrapper>
                  )}
                </ExceptionsRules>
                <ExportSettingsButtonWrapper>
                  <Button
                    secondary
                    blinking={isExporting}
                    disabled={isExporting || disabled}
                    label={t('export_these_settings_to_other_properties')}
                    onClick={openExportTaxesModal}
                  />
                </ExportSettingsButtonWrapper>
              </div>
            )}
          </div>
        )}
        <ImportTaxesModal
          strictOptions
          onSubmit={handleImportTaxesModalSubmit}
          housingId={housing?.id}
          open={isImportTaxesModalOpen}
          onClose={closeImportTaxesModal}
        />
        {isTaxesModalOpen && (
          <TaxesCreationModals
            open={isTaxesModalOpen}
            exemptions={exemptions}
            resetExemptions={resetExemptions}
            resetSeasons={resetSeasons}
            onClose={closeTaxesModal}
            isHighSeasonDatesComplete={isHighSeasonDatesComplete}
            isLowSeasonDatesComplete={isLowSeasonDatesComplete}
            handleInputChange={handleInputChange}
            inputValues={inputValues}
            setInputValues={setInputValues}
            onFinish={handleFinish}
            openImportTaxesModal={openImportTaxesModal}
            country={country}
            saveAgeCalculatorDefaultValues={saveAgeCalculatorDefaultValues}
            resetAgeCalculatorDefaultValuesAndCheckboxes={
              resetAgeCalculatorDefaultValuesAndCheckboxes
            }
            isAgeCalculatorNextButtonDisabled={isAgeCalculatorNextButtonDisabled}
            highSeasonDateRangePicker={
              <DayMonthPicker
                readOnly
                startDate={highSeasonStartDate}
                endDate={highSeasonEndDate}
                startDateId="start-modal-high-date"
                endDateId="end-modal-high-date"
                focusedInput={focusedHighSeasonModalInput}
                onFocusChange={(focusedInput) =>
                  setFocusedHighSeasonModalInput(focusedInput)
                }
                displayFormat="DD-MM"
                showClearDates={false}
                startDatePlaceholderText="00-00"
                endDatePlaceholderText="00-00"
                renderMonthElement={({month}) => (
                  <MonthElement>{moment(month).format('MMMM')}</MonthElement>
                )}
                initialVisibleMonth={() => {
                  if (highSeasonStartDate) {
                    return highSeasonStartDate;
                  }

                  return moment('01-01-2020', 'DD-MM-YYYY');
                }}
                isOutsideRange={(day) => {
                  const leftBoundary = moment('01-01-2020', 'DD-MM-YYYY');
                  const rightBoundary = moment('31-12-2020', 'DD-MM-YYYY');

                  return day.isBefore(leftBoundary) || day.isAfter(rightBoundary);
                }}
                label={t('from_to')}
                onDatesChange={handleModalHighSeasonDatesChange}
                disabled={disabled}
              />
            }
            lowSeasonDateRangePicker={
              <LowSeasonDayMonthPicker
                disabled
                startDate={lowSeasonStartDate}
                endDate={lowSeasonEndDate}
                startDateId="start-modal-low-date"
                endDateId="end-modal-low-date"
                focusedInput={focusedLowSeasonModalInput}
                label={t('from_to')}
                isOutsideRange={(day) => {
                  return day.isSameOrBefore(highSeasonEndDate);
                }}
                onFocusChange={(focusedInput) =>
                  setFocusedLowSeasonModalInput(focusedInput)
                }
                onDatesChange={handleLowSeasonDatesChange}
                showClearDates={false}
                displayFormat="DD-MM"
                startDatePlaceholderText="00-00"
                endDatePlaceholderText="00-00"
                readOnly
              />
            }
            exceptionsMultiSelect={
              <MultiSelect
                isSearchable
                label={t('name_of_exceptions')}
                placeholder={t('enter_exceptions')}
                options={taxExemptionsOptions}
                onChange={handleExemptionChange}
                value={exemptions}
              />
            }
            ageCalculator={
              <ModalAgeRulesContainer>
                <ModalAgeRuleRow
                  active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                >
                  <WhiteSpace />
                  <CheckboxWrapper
                    checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                  >
                    <Checkbox
                      onChange={handleModalAgeRuleCheckboxCheck}
                      checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                      name={AGE_RULES_CHECKBOXES.lessThan}
                    />
                  </CheckboxWrapper>
                  <AgeRuleNameButton
                    type="button"
                    grayed={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    onClick={() =>
                      handleModalAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.lessThan)
                    }
                  >
                    {t('less_than')}
                  </AgeRuleNameButton>
                  <AgeRuleInput
                    disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    label={t('age')}
                    placeholder={MIN_AGE}
                    defaultValue={
                      defaultAgeCalculatorValues[FORM_NAMES.lessThanAge] || ''
                    }
                    error={errors[FORM_NAMES.lessThanAge]?.message}
                    name={FORM_NAMES.lessThanAge}
                    type="number"
                    inputMode="numeric"
                    onChange={(e: InputEventType) => {
                      validateSelf(e);
                      if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
                        setValue(FORM_NAMES.betweenLowAge, e.target.value);
                      }

                      triggerValidation([
                        FORM_NAMES.betweenLowAge,
                        FORM_NAMES.moreThanAge,
                        FORM_NAMES.betweenHighAge,
                      ]);
                    }}
                    ref={register({
                      validate: (value) => {
                        const moreThanAge = parseInt(
                          getValues()[FORM_NAMES.moreThanAge],
                          10,
                        );
                        if (moreThanAge > 0 && value > moreThanAge) {
                          return t('max_number', {number: moreThanAge}) as string;
                        }

                        if (value < MIN_AGE) {
                          return t('min_number', {number: MIN_AGE}) as string;
                        }
                      },
                    })}
                  />
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                      label={t('high_season_price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.lessThanHighSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.lessThanHighSeasonPrice]?.message}
                      name={FORM_NAMES.lessThanHighSeasonPrice}
                      onChange={validateSelf}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                      label={t('low_season_price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.lessThanLowSeasonPrice] ||
                        MIN_PRICE
                      }
                      onChange={validateSelf}
                      error={errors[FORM_NAMES.lessThanLowSeasonPrice]?.message}
                      name={FORM_NAMES.lessThanLowSeasonPrice}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <WhiteSpace />
                </ModalAgeRuleRow>
                <ModalAgeRuleRow
                  active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                >
                  <WhiteSpace />
                  <CheckboxWrapper
                    checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                  >
                    <Checkbox
                      onChange={handleModalAgeRuleCheckboxCheck}
                      checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      name={AGE_RULES_CHECKBOXES.between}
                    />
                  </CheckboxWrapper>
                  <AgeRuleNameButton
                    type="button"
                    grayed={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    onClick={() =>
                      handleModalAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.between)
                    }
                  >
                    {t('between')}
                  </AgeRuleNameButton>
                  <BetweenAgesWrapper>
                    <AgeRuleInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('age')}
                      placeholder={MIN_AGE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenLowAge] || ''
                      }
                      error={errors[FORM_NAMES.betweenLowAge]?.message}
                      name={FORM_NAMES.betweenLowAge}
                      type="number"
                      inputMode="numeric"
                      onChange={(e: InputEventType) => {
                        validateSelf(e);
                        if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]) {
                          setValue(FORM_NAMES.lessThanAge, e.target.value);
                        }

                        triggerValidation([
                          FORM_NAMES.lessThanAge,
                          FORM_NAMES.moreThanAge,
                          FORM_NAMES.betweenHighAge,
                        ]);
                      }}
                      ref={register({
                        validate: (value) => {
                          const betweenHighAge = parseInt(
                            getValues()[FORM_NAMES.betweenHighAge],
                            10,
                          );
                          if (betweenHighAge > 0 && value > betweenHighAge) {
                            return t('max_number', {number: betweenHighAge}) as string;
                          }

                          if (value < MIN_AGE) {
                            return t('min_number', {number: MIN_AGE}) as string;
                          }
                        },
                      })}
                    />
                    <BetweenAgesText
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    >
                      {t('and').toLowerCase()}
                    </BetweenAgesText>
                    <AgeRuleInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('age')}
                      placeholder={MIN_AGE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenHighAge] || ''
                      }
                      error={errors[FORM_NAMES.betweenHighAge]?.message}
                      name={FORM_NAMES.betweenHighAge}
                      onChange={(e: InputEventType) => {
                        validateSelf(e);
                        if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]) {
                          setValue(FORM_NAMES.moreThanAge, e.target.value);
                        }
                        triggerValidation([
                          FORM_NAMES.moreThanAge,
                          FORM_NAMES.lessThanAge,
                          FORM_NAMES.betweenLowAge,
                        ]);
                      }}
                      type="number"
                      inputMode="numeric"
                      ref={register({
                        validate: (value) => {
                          const betweenLowAge = parseInt(
                            getValues()[FORM_NAMES.betweenLowAge],
                            10,
                          );
                          if (betweenLowAge > 0 && value < betweenLowAge) {
                            return t('min_number', {number: betweenLowAge}) as string;
                          }

                          if (value < MIN_AGE) {
                            return t('min_number', {number: MIN_AGE}) as string;
                          }
                        },
                      })}
                    />
                  </BetweenAgesWrapper>
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('high_season_price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenHighSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.betweenHighSeasonPrice]?.message}
                      name={FORM_NAMES.betweenHighSeasonPrice}
                      onChange={validateSelf}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('low_season_price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenLowSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.betweenLowSeasonPrice]?.message}
                      name={FORM_NAMES.betweenLowSeasonPrice}
                      onChange={validateSelf}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <WhiteSpace />
                </ModalAgeRuleRow>
                <ModalAgeRuleRow
                  active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                >
                  <WhiteSpace />
                  <CheckboxWrapper
                    checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                  >
                    <Checkbox
                      onChange={handleModalAgeRuleCheckboxCheck}
                      checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                      name={AGE_RULES_CHECKBOXES.moreThan}
                    />
                  </CheckboxWrapper>
                  <AgeRuleNameButton
                    type="button"
                    grayed={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    onClick={() =>
                      handleModalAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.moreThan)
                    }
                  >
                    {t('more_than')}
                  </AgeRuleNameButton>
                  <AgeRuleInput
                    disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    label={t('age')}
                    placeholder={MIN_AGE}
                    defaultValue={
                      defaultAgeCalculatorValues[FORM_NAMES.moreThanAge] || ''
                    }
                    error={errors[FORM_NAMES.moreThanAge]?.message}
                    name={FORM_NAMES.moreThanAge}
                    onChange={(e: InputEventType) => {
                      validateSelf(e);
                      if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
                        setValue(FORM_NAMES.betweenHighAge, e.target.value);
                      }

                      triggerValidation([
                        FORM_NAMES.lessThanAge,
                        FORM_NAMES.betweenLowAge,
                        FORM_NAMES.betweenHighAge,
                      ]);
                    }}
                    type="number"
                    inputMode="numeric"
                    ref={register({
                      validate: (value) => {
                        const lessThanAge = parseInt(
                          getValues()[FORM_NAMES.lessThanAge],
                          10,
                        );
                        if (lessThanAge > 0 && value < lessThanAge) {
                          return t('min_number', {number: lessThanAge}) as string;
                        }

                        if (value < MIN_AGE) {
                          return t('min_number', {number: MIN_AGE}) as string;
                        }
                      },
                    })}
                  />
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                      label={t('high_season_price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.moreThanHighSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.moreThanHighSeasonPrice]?.message}
                      name={FORM_NAMES.moreThanHighSeasonPrice}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      onChange={validateSelf}
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                      label={t('low_season_price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.moreThanLowSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.moreThanLowSeasonPrice]?.message}
                      name={FORM_NAMES.moreThanLowSeasonPrice}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      onChange={validateSelf}
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <WhiteSpace />
                </ModalAgeRuleRow>
              </ModalAgeRulesContainer>
            }
            ageCalculatorWithoutSeasons={
              <ModalAgeRulesContainer>
                <ModalAgeRuleRow
                  active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                >
                  <WhiteSpace />
                  <CheckboxWrapper
                    checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                  >
                    <Checkbox
                      onChange={handleModalAgeRuleCheckboxCheck}
                      checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                      name={AGE_RULES_CHECKBOXES.lessThan}
                    />
                  </CheckboxWrapper>
                  <AgeRuleNameButton
                    type="button"
                    grayed={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    onClick={() =>
                      handleModalAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.lessThan)
                    }
                  >
                    {t('less_than')}
                  </AgeRuleNameButton>
                  <AgeRuleInput
                    disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    label={t('age')}
                    placeholder={MIN_AGE}
                    defaultValue={
                      defaultAgeCalculatorValues[FORM_NAMES.lessThanAge] || ''
                    }
                    error={errors[FORM_NAMES.lessThanAge]?.message}
                    name={FORM_NAMES.lessThanAge}
                    type="number"
                    inputMode="numeric"
                    onChange={(e: InputEventType) => {
                      validateSelf(e);
                      if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
                        setValue(FORM_NAMES.betweenLowAge, e.target.value);
                      }

                      triggerValidation([
                        FORM_NAMES.betweenLowAge,
                        FORM_NAMES.moreThanAge,
                        FORM_NAMES.betweenHighAge,
                      ]);
                    }}
                    ref={register({
                      validate: (value) => {
                        const moreThanAge = parseInt(
                          getValues()[FORM_NAMES.moreThanAge],
                          10,
                        );
                        if (moreThanAge > 0 && value > moreThanAge) {
                          return t('max_number', {number: moreThanAge}) as string;
                        }

                        if (value < MIN_AGE) {
                          return t('min_number', {number: MIN_AGE}) as string;
                        }
                      },
                    })}
                  />
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                      label={t('price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.lessThanHighSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.lessThanHighSeasonPrice]?.message}
                      name={FORM_NAMES.lessThanHighSeasonPrice}
                      onChange={validateSelf}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <WhiteSpace />
                </ModalAgeRuleRow>
                <ModalAgeRuleRow
                  active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                >
                  <WhiteSpace />
                  <CheckboxWrapper
                    checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                  >
                    <Checkbox
                      onChange={handleModalAgeRuleCheckboxCheck}
                      checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      name={AGE_RULES_CHECKBOXES.between}
                    />
                  </CheckboxWrapper>
                  <AgeRuleNameButton
                    type="button"
                    grayed={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    onClick={() =>
                      handleModalAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.between)
                    }
                  >
                    {t('between')}
                  </AgeRuleNameButton>
                  <BetweenAgesWrapper>
                    <AgeRuleInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('age')}
                      placeholder={MIN_AGE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenLowAge] || ''
                      }
                      error={errors[FORM_NAMES.betweenLowAge]?.message}
                      name={FORM_NAMES.betweenLowAge}
                      type="number"
                      inputMode="numeric"
                      onChange={(e: InputEventType) => {
                        validateSelf(e);
                        if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]) {
                          setValue(FORM_NAMES.lessThanAge, e.target.value);
                        }

                        triggerValidation([
                          FORM_NAMES.lessThanAge,
                          FORM_NAMES.moreThanAge,
                          FORM_NAMES.betweenHighAge,
                        ]);
                      }}
                      ref={register({
                        validate: (value) => {
                          const betweenHighAge = parseInt(
                            getValues()[FORM_NAMES.betweenHighAge],
                            10,
                          );
                          if (betweenHighAge > 0 && value > betweenHighAge) {
                            return t('max_number', {number: betweenHighAge}) as string;
                          }

                          if (value < MIN_AGE) {
                            return t('min_number', {number: MIN_AGE}) as string;
                          }
                        },
                      })}
                    />
                    <BetweenAgesText
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    >
                      {t('and').toLowerCase()}
                    </BetweenAgesText>
                    <AgeRuleInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('age')}
                      placeholder={MIN_AGE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenHighAge] || ''
                      }
                      error={errors[FORM_NAMES.betweenHighAge]?.message}
                      name={FORM_NAMES.betweenHighAge}
                      onChange={(e: InputEventType) => {
                        validateSelf(e);
                        if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.lessThan]) {
                          setValue(FORM_NAMES.moreThanAge, e.target.value);
                        }

                        triggerValidation([
                          FORM_NAMES.moreThanAge,
                          FORM_NAMES.lessThanAge,
                          FORM_NAMES.betweenLowAge,
                        ]);
                      }}
                      type="number"
                      inputMode="numeric"
                      ref={register({
                        validate: (value) => {
                          const betweenLowAge = parseInt(
                            getValues()[FORM_NAMES.betweenLowAge],
                            10,
                          );
                          if (betweenLowAge > 0 && value < betweenLowAge) {
                            return t('min_number', {number: betweenLowAge}) as string;
                          }

                          if (value < MIN_AGE) {
                            return t('min_number', {number: MIN_AGE}) as string;
                          }
                        },
                      })}
                    />
                  </BetweenAgesWrapper>
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                      label={t('price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.betweenHighSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.betweenHighSeasonPrice]?.message}
                      name={FORM_NAMES.betweenHighSeasonPrice}
                      onChange={validateSelf}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <div />
                  <WhiteSpace />
                </ModalAgeRuleRow>
                <ModalAgeRuleRow
                  active={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                >
                  <WhiteSpace />
                  <CheckboxWrapper
                    checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                  >
                    <Checkbox
                      onChange={handleModalAgeRuleCheckboxCheck}
                      checked={ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                      name={AGE_RULES_CHECKBOXES.moreThan}
                    />
                  </CheckboxWrapper>
                  <AgeRuleNameButton
                    type="button"
                    grayed={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    onClick={() =>
                      handleModalAgeRuleCheckboxCheck(AGE_RULES_CHECKBOXES.moreThan)
                    }
                  >
                    {t('more_than')}
                  </AgeRuleNameButton>
                  <AgeRuleInput
                    disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    label={t('age')}
                    placeholder={MIN_AGE}
                    defaultValue={
                      defaultAgeCalculatorValues[FORM_NAMES.moreThanAge] || ''
                    }
                    error={errors[FORM_NAMES.moreThanAge]?.message}
                    name={FORM_NAMES.moreThanAge}
                    onChange={(e: InputEventType) => {
                      validateSelf(e);
                      if (ageRulesCheckboxes[AGE_RULES_CHECKBOXES.between]) {
                        setValue(FORM_NAMES.betweenHighAge, e.target.value);
                      }

                      triggerValidation([
                        FORM_NAMES.lessThanAge,
                        FORM_NAMES.betweenLowAge,
                        FORM_NAMES.betweenHighAge,
                      ]);
                    }}
                    type="number"
                    inputMode="numeric"
                    ref={register({
                      validate: (value) => {
                        const lessThanAge = parseInt(
                          getValues()[FORM_NAMES.lessThanAge],
                          10,
                        );
                        if (lessThanAge > 0 && value < lessThanAge) {
                          return t('min_number', {number: lessThanAge}) as string;
                        }

                        if (value < MIN_AGE) {
                          return t('min_number', {number: MIN_AGE}) as string;
                        }
                      },
                    })}
                  />
                  <RelativeWrapper>
                    <AgeRuleSeasonInput
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                      label={t('price')}
                      placeholder={MIN_PRICE}
                      defaultValue={
                        defaultAgeCalculatorValues[FORM_NAMES.moreThanHighSeasonPrice] ||
                        MIN_PRICE
                      }
                      error={errors[FORM_NAMES.moreThanHighSeasonPrice]?.message}
                      name={FORM_NAMES.moreThanHighSeasonPrice}
                      type="number"
                      step="0.01"
                      inputMode="decimal"
                      onChange={validateSelf}
                      ref={register({
                        min: {
                          value: MIN_PRICE,
                          message: t('min_number', {number: MIN_PRICE}),
                        },
                      })}
                    />
                    <AgeRuleSeasonInputSideLabel
                      disabled={!ageRulesCheckboxes[AGE_RULES_CHECKBOXES.moreThan]}
                    >
                      € /{t('night').toLowerCase()}
                    </AgeRuleSeasonInputSideLabel>
                  </RelativeWrapper>
                  <div />
                  <WhiteSpace />
                </ModalAgeRuleRow>
              </ModalAgeRulesContainer>
            }
          />
        )}
      </Section>
    );
  },
);

HousingTaxesSection.defaultProps = defaultProps;
export {HousingTaxesSection};
