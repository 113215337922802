import styled, {css} from 'styled-components';
import {Label as InputLabel, Wrapper as InputWrapper} from '../Input/styled';
import {StyledButton as SelectorButton} from '../SelectorButton/styled';

export const PremiumLabel = styled.div`
  background: #35e5bc 0 0 no-repeat padding-box;
  font-family: ProximaNova-Semibold, sans-serif;
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  padding: 3px 7px;
  margin-left: 12px;
`;

export const AddButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  padding: 2px 0;
  width: max-content;

  &:hover > img {
    box-shadow: 0 3px 3px #0f477734;
  }

  &:active > img {
    opacity: 0.95;
  }

  & > img {
    border-radius: 3px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    transition: box-shadow 0.15s ease-in-out;
  }

  ${(props) =>
    props.disabled &&
    css`
      visibility: hidden;
    `}
`;

export const Content = styled.main`
  margin-top: 30px;
`;

export const AccountWrapper = styled.div`
  display: flex;
`;

export const AccountSelectWrapper = styled.div`
  margin-right: 20px;
`;

export const LocksContainer = styled.div`
  margin-top: 30px;
`;

export const LocksHeader = styled.div`
  font-family: ProximaNova-Semibold, sans-serif;
  color: #161643;
  font-size: 16px;
  margin-bottom: 26px;
`;

export const PrivateLocksHeader = styled(LocksHeader)`
  margin-top: 26px;
`;

export const LocksSubHeader = styled.div`
  font-size: 16px;
  color: #161643;
  font-family: ProximaNova-Regular, sans-serif;
  margin-bottom: 30px;
`;

export const LocksHeaderTitle = styled.div`
  text-decoration: underline;
`;

type LockWrapperProps = {
  isLoading?: boolean;
};

export const LockWrapper = styled.div<LockWrapperProps>`
  height: 62px;
  display: flex;
  position: relative;

  & > div {
    margin-top: auto;
  }

  & ${InputLabel} {
    margin-top: -5px;
    font-family: ProximaNova-Medium, sans-serif;
    color: #161643;
    text-decoration: underline;
    font-size: 16px;
  }

  ${(props) =>
    props.isLoading &&
    css`
      cursor: progress;

      & ${InputWrapper} {
        opacity: 1;
      }
    `}
`;

export const LocksList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(auto-fill, minmax(47px, 77px));
  grid-auto-columns: min-content;
  max-height: 231px;

  & > :nth-child(n) {
    padding-bottom: 30px;
    padding-right: 64px;
  }

  & > :nth-child(3n) {
    height: 48px;
    padding-bottom: 0;
  }

  & > :nth-child(n + 4) {
    padding-left: 64px;
  }

  & > :first-child:nth-last-child(n + 4) {
    & ~ :nth-child(2),
    & ~ :nth-child(3) {
      border-right: 1px solid rgba(22, 22, 67, 0.12);
    }
    border-right: 1px solid rgba(22, 22, 67, 0.12);
  }

  & > :first-child:nth-last-child(n + 7) {
    & ~ :nth-child(4),
    & ~ :nth-child(5),
    & ~ :nth-child(6) {
      border-right: 1px solid rgba(22, 22, 67, 0.12);
    }
  }
`;

export const LocksLoaderWrapper = styled.div`
  margin: 0 auto;
`;

export const NoLocksMessage = styled.div`
  margin: 0 auto 35px;
  font-family: ProximaNova-Semibold, sans-serif;
  color: #161643;
  font-size: 18px;
  text-align: center;
  padding: 0 10px;

  & > ${AddButton} {
    margin-top: 5px;
  }
`;

type PaginationContentProps = {
  hasPagination: boolean;
};

export const PaginationContent = styled.div<PaginationContentProps>`
  margin-top: -5px;
  min-height: 37px;
  position: relative;

  & > ${AddButton} {
    margin-top: ${(props) => !props.hasPagination && '21px'};
    margin-bottom: ${(props) => !props.hasPagination && '13px'};
    position: ${(props) => props.hasPagination && 'absolute'};
    top: 0;
    left: 0;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #eeeeee;
  border-radius: 50%;
`;

export const ThreeDotsGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 33px;

  & ${Dot}:nth-child(2) {
    margin: 0 21px;
  }
`;

export const SelectorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -10px;

  & ${SelectorButton} {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const RemindersNotes = styled.div`
  color: #161643;
  font-family: ProximaNova-Semibold, sans-serif;
  margin-bottom: 21px;
  font-size: 16px;
`;
