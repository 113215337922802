import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import ElementsInjectedSubscriptionPaymentStep from '../components/dashboard/SubscriptionPaymentStep';

function SubscriptionPayment(props: any) {
  return (
    <>
      <TrialHeader />
      <Header />
      <ElementsInjectedSubscriptionPaymentStep {...props} />
    </>
  );
}

export {SubscriptionPayment};
