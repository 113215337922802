import React from 'react';
import Section from '../Section';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {useQuery} from 'react-query';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import {
  COUNTRIES_WITH_STAT,
  COUNTRY_CODES,
  STAT_TYPES,
  VALIDATION_STATUSES,
} from '../../../utils/constants';
import {SelectOption} from '../../../utils/types';
import {getRequiredOrOptionalFieldLabel, toastResponseError} from '../../../utils/common';
import {getCountryCode, getExternalHousingsAsOptions} from '../../../utils/housing';
import {
  useErrorToast,
  useIsFormTouched,
  useIsMounted,
  usePrevious,
} from '../../../utils/hooks';
import ValidationButton from '../ValidationButton';
import Switch from '../Switch';
import Input from '../Input';
import Select from '../Select';
import {FieldWrapper} from '../../../styled/common';

const STAT_DATA_CACHE_TIME_MIN = 60;
const STAT_DATA_STALE_TIME_MIN = 30;
const STATUS_DISPLAY_TIMEOUT_SEC = 3;
const VALIDATION_REQUESTS_GAP_SEC = 2;
const MIN_BEDS_NUMBER = 0;
const MIN_ROOMS_NUMBER = 0;

function fetchStatTypes(key: string, params = '') {
  return queryFetcher(api.statAccount.ENDPOINTS.types(params));
}

function fetchStatLocations(key: string, params = '') {
  return queryFetcher(api.statAccount.ENDPOINTS.locations(params));
}

type StatData = {
  id: string;
  name: string;
}[];

function getStatDataAsOptions(data?: StatData) {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data?.map((d) => {
    return {
      value: d?.id,
      label: d?.name,
    };
  });
}

export enum FORM_NAMES {
  room_quantity = 'rooms_quantity',
  type = 'type',
  location = 'external_location_id',
  username = 'username',
  password = 'password',
  beds_quantity = 'beds_quantity',
  external_housing = 'external_housing',
}

type FormTypes = {
  [FORM_NAMES.username]: string;
  [FORM_NAMES.password]: string;
  [FORM_NAMES.type]: SelectOption | null;
  [FORM_NAMES.location]: SelectOption | null;
  [FORM_NAMES.room_quantity]: string;
  [FORM_NAMES.beds_quantity]: string;
  [FORM_NAMES.external_housing]: SelectOption;
};

const INIT_DISPLAY_FIELDS = {
  [FORM_NAMES.type]: true,
  [FORM_NAMES.username]: true,
  [FORM_NAMES.password]: true,
  [FORM_NAMES.location]: true,
  [FORM_NAMES.room_quantity]: true,
  [FORM_NAMES.beds_quantity]: false,
  [FORM_NAMES.external_housing]: false,
};

function getDisplayFields(country: string, statType: string) {
  switch (country) {
    case COUNTRY_CODES.italy: {
      if (statType === STAT_TYPES.lazioRadar) {
        return INIT_DISPLAY_FIELDS;
      }
      if (
        statType === STAT_TYPES.campania ||
        statType === STAT_TYPES.siciliaOCR ||
        statType === STAT_TYPES.toscanaRicestat
      ) {
        return {
          ...INIT_DISPLAY_FIELDS,
          [FORM_NAMES.room_quantity]: false,
          [FORM_NAMES.external_housing]: false,
        };
      }
      if (statType === STAT_TYPES.valtellinaAbit || statType === STAT_TYPES.venetto) {
        return {
          ...INIT_DISPLAY_FIELDS,
          [FORM_NAMES.external_housing]: true,
          [FORM_NAMES.room_quantity]: false,
        };
      }

      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.external_housing]: true,
      };
    }
    case COUNTRY_CODES.germany: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.room_quantity]: true,
        [FORM_NAMES.beds_quantity]: true,
        [FORM_NAMES.external_housing]: true,
      };
    }
    default: {
      return INIT_DISPLAY_FIELDS;
    }
  }
}

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.type]: i18n.t('required'),
  [FORM_NAMES.location]: i18n.t('required'),
  [FORM_NAMES.username]: i18n.t('required'),
  [FORM_NAMES.password]: i18n.t('required'),
  [FORM_NAMES.room_quantity]: i18n.t('required'),
  [FORM_NAMES.beds_quantity]: i18n.t('required'),
  [FORM_NAMES.external_housing]: i18n.t('required'),
};

function getRequiredFields(country: string, statType: string) {
  switch (country) {
    default: {
      return INIT_REQUIRED_FIELDS;
    }
  }
}

function getFields(country = '', statType = '') {
  const display = getDisplayFields(country, statType);
  const required = getRequiredFields(country, statType);

  return {display, required};
}

type HousingStatConnectionSectionProps = {
  disabled: boolean;
  country: string;
  openIncompleteModal: () => void;
  setIsSectionTouched?: (isTouched: boolean) => void;
  housing?: any;
};

const defaultProps: Partial<HousingStatConnectionSectionProps> = {
  country: '',
  disabled: false,
  housing: null,
};

const HousingStatConnectionSection = React.forwardRef(
  (
    {
      country,
      disabled,
      housing,
      openIncompleteModal,
      setIsSectionTouched,
    }: HousingStatConnectionSectionProps,
    ref,
  ) => {
    const {t} = useTranslation();
    const {
      handleSubmit,
      register,
      control,
      watch,
      formState,
      triggerValidation,
      setValue,
      getValues,
      errors,
      reset,
    } = useForm<FormTypes>({
      submitFocusError: false,
    });
    const prevFormState = usePrevious<typeof formState>(formState);
    const isMounted = useIsMounted();
    const sectionRef = React.useRef(false);
    const statusTimeoutRef = React.useRef(0);
    const validationTimeoutRef = React.useRef(0);
    const statType = watch(FORM_NAMES.type)?.value || '';
    const statLocation = watch(FORM_NAMES.location)?.value || '';
    const statUsername = watch(FORM_NAMES.username);
    const statPassword = watch(FORM_NAMES.password);
    const statRoomQuantity = watch(FORM_NAMES.room_quantity);
    const statBedsQuantity = watch(FORM_NAMES.beds_quantity);
    const statExternalHousing = watch(FORM_NAMES.external_housing)?.value;

    const {data: statTypes, error: statTypesError} = useQuery(
      Boolean(country) && ['statTypes', `country=${country}`],
      fetchStatTypes,
      {
        cacheTime: STAT_DATA_CACHE_TIME_MIN * 1000 * 60,
        staleTime: STAT_DATA_STALE_TIME_MIN * 1000 * 60,
        refetchOnWindowFocus: false,
      },
    );
    useErrorToast(statTypesError, {
      notFoundMessage: t('errors.requested_stat_types_not_found'),
    });
    const {data: statLocations, error: statLocationsError} = useQuery(
      Boolean(statType) && ['statLocations', `stat_type=${statType}`],
      fetchStatLocations,
      {
        cacheTime: STAT_DATA_CACHE_TIME_MIN * 1000 * 60,
        staleTime: STAT_DATA_STALE_TIME_MIN * 1000 * 60,
        refetchOnWindowFocus: false,
      },
    );
    useErrorToast(statLocationsError, {
      notFoundMessage: t('errors.requested_stat_locations_not_found'),
    });
    const [isSectionActive, setIsSectionActive] = React.useState(false);
    const [validationStatus, setValidationStatus] = React.useState('');
    const [isCountryPreloaded, setIsCountryPreloaded] = React.useState(false);
    const [fields, setFields] = React.useState(() => {
      return getFields(country);
    });
    const [isPreloadedStatType, setIsPreloadedStatType] = React.useState(false);
    const [isPreloadedStatLocation, setIsPreloadedStatLocation] = React.useState(false);
    const [isValidateButtonVisible, setIsValidateButtonVisible] = React.useState(true);
    const [isValidationErrorVisible, setIsValidationErrorVisible] = React.useState(false);
    const [externalHousingsOptions, setExternalHousingsOptions] = React.useState<
      SelectOption[]
    >([]);
    const {isFormTouched, setUntouchedValues} = useIsFormTouched({
      watch,
      displayFields: fields.display,
      debug: true,
    });
    const isValidating = validationStatus === VALIDATION_STATUSES.inProgress;

    React.useEffect(() => {
      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(isFormTouched);
      }
    }, [isFormTouched, setIsSectionTouched]);

    React.useEffect(() => {
      if (!isValidationErrorVisible) {
        setValidationStatus('');
      }
    }, [isValidationErrorVisible]);

    React.useEffect(() => {
      setIsValidateButtonVisible(true);
      setIsValidationErrorVisible(false);
    }, [
      statType,
      statLocation,
      statUsername,
      statPassword,
      statRoomQuantity,
      statBedsQuantity,
      statExternalHousing,
    ]);

    React.useImperativeHandle(ref, () => {
      return {
        getValues,
        submit: submitAndValidate,
        active: sectionRef.current,
      };
    });

    React.useLayoutEffect(
      function showIncompleteModalOneTime() {
        const shouldOpenModal =
          formState.isSubmitted && !prevFormState?.isValid && !formState.isValid;

        if (shouldOpenModal) {
          openIncompleteModal();
        }
      },
      [formState.isSubmitted, formState.isValid, prevFormState, openIncompleteModal],
    );

    React.useEffect(() => {
      if (formState.isSubmitted) {
        triggerValidation();
      }
    }, [fields, formState.isSubmitted, triggerValidation]);

    React.useEffect(() => {
      if (housing?.is_stat_registration_enabled) {
        setIsSectionActive(true);
        sectionRef.current = true;
      }
    }, [housing]);

    React.useEffect(
      function updateFields() {
        const nextFields = getFields(country, statType as string);
        setFields(nextFields);
        setExternalHousingsOptions([]);

        if (country) {
          setIsCountryPreloaded(true);
        }
      },
      [country, statType, setValue],
    );

    React.useEffect(
      function resetOnSectionDisable() {
        if (!isSectionActive) {
          reset();
          clearTimeout(statusTimeoutRef.current);
          clearTimeout(validationTimeoutRef.current);
          setExternalHousingsOptions([]);
          setIsPreloadedStatType(false);
          setIsPreloadedStatLocation(false);
          setIsCountryPreloaded(false);
        }

        return () => {
          clearTimeout(statusTimeoutRef.current);
          clearTimeout(validationTimeoutRef.current);
        };
      },
      [isSectionActive, reset],
    );

    React.useEffect(
      function resetStatTypeOnCountryChange() {
        setValue(FORM_NAMES.type, null);
      },
      [country, setValue],
    );

    React.useEffect(
      function resetStatLocationOnNewLocations() {
        if (statLocations && statLocation) {
          const includes = getStatDataAsOptions(statLocations).find((o) => {
            return o?.value === statLocation;
          });

          if (!includes) {
            setValue(FORM_NAMES.location, null);
          }
        }
      },
      [setValue, statLocation, statLocations],
    );

    React.useEffect(
      function setDefaultExternalHousing() {
        const formValues = getValues() as any;
        if (
          !formValues[FORM_NAMES.external_housing]?.value &&
          externalHousingsOptions.length
        ) {
          const initExternalHousingId = housing?.stat_account?.external_housing_id;
          const initExternalHousing = externalHousingsOptions.find((o) => {
            return o?.value === initExternalHousingId;
          });

          setValue(
            FORM_NAMES.external_housing,
            initExternalHousing || externalHousingsOptions[0],
          );
        }
      },
      [externalHousingsOptions, setValue, getValues, housing],
    );

    const resetValidationStatusAfterTimeout = () => {
      statusTimeoutRef.current = setTimeout(() => {
        setValidationStatus('');
      }, STATUS_DISPLAY_TIMEOUT_SEC * 1000);
    };

    const handleValidationError = React.useCallback((error = null) => {
      if (error) {
        toastResponseError(error as any);
      }

      setValidationStatus(VALIDATION_STATUSES.error);
      setIsValidationErrorVisible(true);

      setExternalHousingsOptions([]);
    }, []);

    const hideValidateButtonAfterTimeout = () => {
      statusTimeoutRef.current = setTimeout(() => {
        setIsValidateButtonVisible(false);
      }, STATUS_DISPLAY_TIMEOUT_SEC * 1000);
    };

    const handleValidationSuccess = React.useCallback((data: any) => {
      if (data?.external_housings_data?.length) {
        const options = getExternalHousingsAsOptions(data.external_housings_data);
        setExternalHousingsOptions(options);
      } else {
        setExternalHousingsOptions([]);
      }
      setValidationStatus(VALIDATION_STATUSES.complete);
      resetValidationStatusAfterTimeout();
      hideValidateButtonAfterTimeout();
    }, []);

    const checkStatAccountValidation = React.useCallback(
      async (id = ''): Promise<any> => {
        if (!id) {
          handleValidationError();
          return false;
        }

        const {error, data} = await api.statAccount.checkValidationStatus(id);

        if (isMounted.current && sectionRef.current) {
          if (data?.status === VALIDATION_STATUSES.inProgress) {
            return new Promise((resolve) => {
              validationTimeoutRef.current = setTimeout(() => {
                if (sectionRef.current) {
                  resolve(checkStatAccountValidation(id));
                }
              }, VALIDATION_REQUESTS_GAP_SEC * 1000);
            });
          }

          if (
            data?.status === VALIDATION_STATUSES.complete &&
            data?.is_successful_login
          ) {
            handleValidationSuccess(data);
            return true;
          }

          handleValidationError(error);
        }
        return false;
      },
      [handleValidationError, handleValidationSuccess, isMounted],
    );

    const getValidationPayload = (data: FormTypes) => {
      let always_valid;
      if (data?.username?.toLowerCase() === 'test valid') {
        always_valid = true;
      }

      return {
        ...data,
        always_valid,
        [FORM_NAMES.beds_quantity]: undefined,
        [FORM_NAMES.room_quantity]: undefined,
        [FORM_NAMES.external_housing]: undefined,
        [FORM_NAMES.location]: (data[FORM_NAMES.location] as any)?.value,
        [FORM_NAMES.type]: (data[FORM_NAMES.type] as any)?.value,
      };
    };

    const validateStatAccount = React.useCallback(async () => {
      const payload = getValidationPayload(getValues() as FormTypes);

      setValidationStatus(VALIDATION_STATUSES.inProgress);
      const {error, data} = await api.statAccount.startValidation(payload);

      if (isMounted.current && sectionRef.current) {
        if (data?.status === VALIDATION_STATUSES.complete && data?.is_successful_login) {
          handleValidationSuccess(data);
          return true;
        }

        if (data?.id) {
          return checkStatAccountValidation(data.id);
        }

        handleValidationError(error);
      }
      return false;
    }, [
      checkStatAccountValidation,
      getValues,
      handleValidationError,
      handleValidationSuccess,
      isMounted,
    ]);

    const getInitStat = React.useCallback(() => {
      const statAccount = housing?.stat_account;
      return [
        {[FORM_NAMES.username]: statAccount?.username},
        {[FORM_NAMES.password]: statAccount?.password},
        {[FORM_NAMES.room_quantity]: String(housing?.rooms_quantity)},
        {[FORM_NAMES.beds_quantity]: String(housing?.beds_quantity)},
      ];
    }, [housing]);

    const loadInitStat = React.useCallback(() => {
      const isStatActive = housing?.is_stat_registration_enabled;
      const housingCountry = getCountryCode(housing);
      const isStatEnabled = COUNTRIES_WITH_STAT.includes(country);

      if (!isStatActive || !country || housingCountry !== country || !isStatEnabled) {
        return;
      }

      const formData = getInitStat();
      setUntouchedValues((prevState) => {
        let result: {[key: string]: any} = {};

        formData.forEach((data: any) => {
          const field = Object.keys(data)[0];
          result[field] = data[field];
        });

        return {
          ...prevState,
          ...result,
        };
      });
      setValue(formData);
    }, [country, getInitStat, housing, setUntouchedValues, setValue]);

    React.useEffect(
      function preload() {
        if (isCountryPreloaded && housing && isSectionActive) {
          loadInitStat();
        }
      },
      [housing, isCountryPreloaded, isSectionActive, loadInitStat],
    );

    React.useEffect(
      function preloadStatType() {
        const canPreload =
          !isPreloadedStatType && statTypes && isSectionActive && isCountryPreloaded;

        if (canPreload) {
          const initStatTypeValue = housing?.stat_account?.type;
          const initStatType = getStatDataAsOptions(statTypes).find((t) => {
            return t?.value === initStatTypeValue;
          });

          if (initStatType) {
            setValue(FORM_NAMES.type, initStatType);
          }
          setIsPreloadedStatType(true);

          const hasExternalHousing = housing?.stat_account?.external_housing_id;
          const hasExternalLocation = housing?.stat_account?.external_location_id;
          if (hasExternalHousing && !hasExternalLocation) {
            validateStatAccount();
          }
        }
      },
      [
        isCountryPreloaded,
        housing,
        isPreloadedStatType,
        setValue,
        statTypes,
        isSectionActive,
        validateStatAccount,
      ],
    );

    React.useEffect(
      function preloadStatLocation() {
        const canPreload =
          !isPreloadedStatLocation &&
          statLocations?.length &&
          isSectionActive &&
          isPreloadedStatType;

        if (canPreload) {
          const initStatLocationValue = housing?.stat_account?.external_location_id;
          const initStatLocation = getStatDataAsOptions(statLocations).find((t) => {
            return t?.value === initStatLocationValue;
          });

          if (initStatLocation) {
            setValue(FORM_NAMES.location, initStatLocation);
          }
          setIsPreloadedStatLocation(true);

          const hasExternalHousing = housing?.stat_account?.external_housing_id;
          if (hasExternalHousing) {
            validateStatAccount();
          }
        }
      },
      [
        validateStatAccount,
        isPreloadedStatType,
        housing,
        setValue,
        isSectionActive,
        isPreloadedStatLocation,
        statLocations,
      ],
    );

    const toggleIsSectionActive = () => {
      setIsSectionActive((prevState) => {
        if (!prevState) {
          setValidationStatus('');
        }

        sectionRef.current = !prevState;
        return !prevState;
      });

      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(true);
      }
    };

    const submitAndValidate = async () => {
      await handleSubmit(() => {})();
      if (formState.isValid) {
        return validateStatAccount();
      } else {
        const isFormValid = await triggerValidation();
        if (isFormValid) {
          return validateStatAccount();
        }
      }
      return false;
    };

    const onSubmit = async () => {
      await validateStatAccount();
    };

    if (!COUNTRIES_WITH_STAT.includes(country)) {
      return null;
    }

    return (
      <Section
        showTooltip
        title={t('stats_connection_title')}
        subtitle={t('stats_connection_subtitle')}
        tooltipContent={
          <>
            {t('stat_tooltip_top_content')}
            <p />
            {t('stat_tooltip_bottom_content')}
          </>
        }
      >
        <Switch
          checked={isSectionActive}
          onChange={toggleIsSectionActive}
          label={t('activate_stat_sending')}
          disabled={disabled}
        />
        {isSectionActive && (
          <div>
            {fields.display[FORM_NAMES.type] && (
              <FieldWrapper>
                <Controller
                  as={<Select />}
                  control={control}
                  label={getRequiredOrOptionalFieldLabel(
                    t('region'),
                    fields.required[FORM_NAMES.type],
                  )}
                  name={FORM_NAMES.type}
                  options={getStatDataAsOptions(statTypes)}
                  rules={{required: fields.required[FORM_NAMES.type]}}
                  error={(errors[FORM_NAMES.type] as any)?.message}
                  disabled={isValidating || disabled}
                  placeholder={t('select_your_region')}
                />
              </FieldWrapper>
            )}
            {fields.display[FORM_NAMES.location] && Boolean(statLocations?.length) && (
              <FieldWrapper>
                <Controller
                  as={<Select />}
                  control={control}
                  label={getRequiredOrOptionalFieldLabel(
                    t('location'),
                    fields.required[FORM_NAMES.location],
                  )}
                  name={FORM_NAMES.location}
                  options={getStatDataAsOptions(statLocations)}
                  rules={{required: fields.required[FORM_NAMES.location]}}
                  error={(errors[FORM_NAMES.location] as any)?.message}
                  disabled={isValidating || disabled}
                  placeholder={t('select_your_location')}
                />
              </FieldWrapper>
            )}
            {fields.display[FORM_NAMES.username] && (
              <FieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.username],
                  })}
                  name={FORM_NAMES.username}
                  label={getRequiredOrOptionalFieldLabel(
                    t('username'),
                    fields.required[FORM_NAMES.username],
                  )}
                  placeholder={t('enter_username')}
                  error={errors[FORM_NAMES.username]?.message}
                  disabled={isValidating || disabled}
                />
              </FieldWrapper>
            )}
            {fields.display[FORM_NAMES.password] && (
              <FieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.password],
                  })}
                  name={FORM_NAMES.password}
                  type="password"
                  empty={!Boolean(statPassword)}
                  label={getRequiredOrOptionalFieldLabel(
                    t('password'),
                    fields.required[FORM_NAMES.password],
                  )}
                  placeholder={t('enter_password')}
                  error={errors[FORM_NAMES.password]?.message}
                  disabled={isValidating || disabled}
                />
              </FieldWrapper>
            )}
            {fields.display[FORM_NAMES.room_quantity] && (
              <FieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.room_quantity],
                    min: {
                      value: MIN_ROOMS_NUMBER,
                      message: t('min_number_is', {number: MIN_ROOMS_NUMBER}),
                    },
                  })}
                  inputMode="decimal"
                  name={FORM_NAMES.room_quantity}
                  type="number"
                  label={getRequiredOrOptionalFieldLabel(
                    t('room_quantity'),
                    fields.required[FORM_NAMES.room_quantity],
                  )}
                  placeholder={t('enter_room_quantity')}
                  error={errors[FORM_NAMES.room_quantity]?.message}
                  disabled={isValidating || disabled}
                />
              </FieldWrapper>
            )}
            {fields.display[FORM_NAMES.beds_quantity] && (
              <FieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.beds_quantity],
                    min: {
                      value: MIN_ROOMS_NUMBER,
                      message: t('min_number_is', {number: MIN_BEDS_NUMBER}),
                    },
                  })}
                  name={FORM_NAMES.beds_quantity}
                  type="number"
                  inputMode="decimal"
                  label={getRequiredOrOptionalFieldLabel(
                    t('beds_quantity'),
                    fields.required[FORM_NAMES.beds_quantity],
                  )}
                  placeholder={t('enter_beds_quantity')}
                  error={errors[FORM_NAMES.beds_quantity]?.message}
                  disabled={isValidating || disabled}
                />
              </FieldWrapper>
            )}
            {fields.display[FORM_NAMES.external_housing] &&
              Boolean(externalHousingsOptions.length) && (
                <FieldWrapper>
                  <Controller
                    as={<Select />}
                    control={control}
                    options={externalHousingsOptions}
                    name={FORM_NAMES.external_housing}
                    label={getRequiredOrOptionalFieldLabel(
                      t('property'),
                      fields.required[FORM_NAMES.external_housing],
                    )}
                    rules={{
                      required: fields.required[FORM_NAMES.external_housing],
                    }}
                    error={(errors[FORM_NAMES.external_housing] as any)?.message}
                    disabled={isValidating || disabled}
                    placeholder={t('select_your_property')}
                  />
                </FieldWrapper>
              )}
            {isValidateButtonVisible && (
              <ValidationButton
                secondary
                type="button"
                errorMessage={t('connection_validation_error')}
                disabled={Boolean(validationStatus) || disabled}
                status={validationStatus}
                onClick={handleSubmit(onSubmit)}
              />
            )}
          </div>
        )}
      </Section>
    );
  },
);

HousingStatConnectionSection.defaultProps = defaultProps;
export {HousingStatConnectionSection};
