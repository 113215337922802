import React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {SECURITY_DEPOSIT_STATUSES, MAX_DEPOSIT_AMOUNT} from '../../../utils/constants';
import {Housing} from '../../../utils/types';
import {useIsFormTouched} from '../../../utils/hooks';
import Section from '../Section';
import Switch from '../Switch';
import SelectorButton from '../SelectorButton';
import {GreenSectionTag} from '../../../styled/common';
import {
  AmountInput,
  Content,
  Currency,
  InputWrapper,
  RadioButtonsDescription,
  RadioButtonsWrapper,
} from './styled';

const MIN_DEPOSIT = 0;

enum FORM_NAMES {
  depositAmount = 'security_deposit_amount',
}

type FormTypes = {
  [FORM_NAMES.depositAmount]: string;
};

const displayFields = {
  [FORM_NAMES.depositAmount]: true,
};

type HousingSecurityDepositSectionProps = {
  disabled: boolean;
  setIsSectionTouched?: (isTouched: boolean) => void;
  housing?: Housing;
};

const HousingSecurityDepositSection = React.forwardRef(
  ({disabled, setIsSectionTouched, housing}: HousingSecurityDepositSectionProps, ref) => {
    const {t} = useTranslation();
    const {register, errors, watch, setValue} = useFormContext<FormTypes>();
    const [isSectionActive, setIsSectionActive] = React.useState(false);
    const [depositStatus, setDepositStatus] = React.useState(
      SECURITY_DEPOSIT_STATUSES.inactive,
    );
    const {isFormTouched, setUntouchedValues} = useIsFormTouched({
      displayFields,
      watch,
    });
    const housingDepositAmount =
      housing?.security_deposit_amount && String(housing?.security_deposit_amount);

    React.useImperativeHandle(ref, () => {
      return {
        depositStatus,
        active: isSectionActive,
      };
    });

    React.useEffect(() => {
      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(isFormTouched);
      }
    }, [isFormTouched, setIsSectionTouched]);

    React.useEffect(
      function preloadIsSectionActive() {
        const isSectionActive = Boolean(
          housing?.security_deposit_status &&
            housing?.security_deposit_status !== SECURITY_DEPOSIT_STATUSES.inactive,
        );
        setIsSectionActive(isSectionActive);
      },
      [housing],
    );

    React.useEffect(
      function preloadHousingDepositStatus() {
        const housingDepositStatus = housing?.security_deposit_status;

        if (housingDepositStatus) {
          setDepositStatus(housingDepositStatus);
        }
      },
      [housing],
    );

    React.useEffect(
      function loadHousingDepositAmount() {
        if (housingDepositAmount) {
          setValue(FORM_NAMES.depositAmount, housingDepositAmount);
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [FORM_NAMES.depositAmount]: housingDepositAmount,
            };
          });
        }
      },
      [housing, housingDepositAmount, setUntouchedValues, setValue],
    );

    const setSectionTouched = () => {
      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(true);
      }
    };

    const toggleIsSectionActive = () => {
      setIsSectionActive((prevState) => {
        const depositStatus =
          housing?.security_deposit_status &&
          housing?.security_deposit_status !== SECURITY_DEPOSIT_STATUSES.inactive
            ? housing?.security_deposit_status
            : SECURITY_DEPOSIT_STATUSES.optional;

        if (!prevState) {
          setDepositStatus(depositStatus);
        }

        return !prevState;
      });
      setSectionTouched();
    };

    const handleDepositOptionChange = (option: SECURITY_DEPOSIT_STATUSES) => {
      setDepositStatus(option);
      setSectionTouched();
    };

    return (
      <Section
        title={
          <div>
            {t('security_deposit_title')}
            <GreenSectionTag>{t('beta')}</GreenSectionTag>
          </div>
        }
        subtitle={t('security_deposit_subtitle')}
      >
        <Switch
          checked={isSectionActive}
          onChange={toggleIsSectionActive}
          label={t('activate_deposits')}
          disabled={disabled}
        />
        {isSectionActive && (
          <Content>
            <InputWrapper>
              <AmountInput
                ref={register({
                  required: t('required') as string,
                  min: {
                    value: MIN_DEPOSIT,
                    message: t('min_number_is', {number: MIN_DEPOSIT}),
                  },
                  max: {
                    value: MAX_DEPOSIT_AMOUNT,
                    message: t('max_number_is', {number: MAX_DEPOSIT_AMOUNT}),
                  },
                })}
                disabled={disabled}
                error={errors[FORM_NAMES.depositAmount]?.message}
                label={t('deposit_amount')}
                placeholder={t('enter_amount')}
                name={FORM_NAMES.depositAmount}
                defaultValue={housingDepositAmount}
                type="number"
                inputMode="numeric"
                step="0.01"
              />
              <Currency>€</Currency>
            </InputWrapper>
            <RadioButtonsDescription>
              {t('select_mandatory_or_optional_deposit')}
            </RadioButtonsDescription>
            <RadioButtonsWrapper>
              <SelectorButton
                disabled={disabled}
                onClick={() =>
                  handleDepositOptionChange(SECURITY_DEPOSIT_STATUSES.optional)
                }
                active={depositStatus === SECURITY_DEPOSIT_STATUSES.optional}
                label={t('deposits_optional')}
              />
              <SelectorButton
                disabled={disabled}
                onClick={() =>
                  handleDepositOptionChange(SECURITY_DEPOSIT_STATUSES.mandatory)
                }
                active={depositStatus === SECURITY_DEPOSIT_STATUSES.mandatory}
                label={t('deposits_mandatory')}
              />
            </RadioButtonsWrapper>
          </Content>
        )}
      </Section>
    );
  },
);

export {HousingSecurityDepositSection};
