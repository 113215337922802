import React from 'react';
import {useUser} from './user';
import {getIsAccountCollaborator, getIsAccountOwner} from '../utils/user';
import {useSubscription} from './subscription';

type ContextProps = {
  isAccountOwner: boolean;
  isAccountCollaborator: boolean;
  isAccountManager: boolean;
  isNeedToAskForSubscription: boolean;
};

const ComputedDetailsContext = React.createContext<ContextProps>({
  isAccountOwner: false,
  isAccountCollaborator: false,
  isAccountManager: false,
  isNeedToAskForSubscription: false,
});

function ComputedDetailsProvider(props: any) {
  const user = useUser();
  const {
    isSubscriptionRequired,
    isSubscriptionCanceled,
    isTrialEnded,
  } = useSubscription();

  const isAccountOwner = getIsAccountOwner(user);
  const isAccountCollaborator = getIsAccountCollaborator(user);

  const isAccountManager = !isAccountOwner && !isAccountCollaborator;
  const isNeedToAskForSubscription =
    isSubscriptionRequired && (isSubscriptionCanceled || isTrialEnded);

  return (
    <ComputedDetailsContext.Provider
      value={{
        isAccountOwner,
        isAccountCollaborator,
        isAccountManager,
        isNeedToAskForSubscription,
      }}
      {...props}
    />
  );
}

function useComputedDetails() {
  const context = React.useContext(ComputedDetailsContext);
  if (context === undefined) {
    throw new Error('useComputedDetails must be used within a ComputedDetailsProvider');
  }
  return context;
}

export {ComputedDetailsProvider, useComputedDetails, ComputedDetailsContext};
