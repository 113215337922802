import React from 'react';
import {useTranslation} from 'react-i18next';
import AsyncReactSelect from 'react-select/async';
import displayArrowIcon from '../../../assets/display-icn.svg';
import {ErrorMessage} from '../../../styled/common';
import {Wrapper, DisplayIcon, Label} from '../Select/styled';
import {createFilter} from 'react-select';

type OptionTypes = {
  label: string | JSX.Element | React.ReactNode;
  value: string | number;
};

type AsyncSelectProps = {
  onChange?: (option: any, info: any) => void;
  loadOptions: (value: any, callback: any) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
  value?: OptionTypes | null;
  name?: string;
  placeholder?: string;
  error?: any;
  className?: string;
  cacheOptions?: boolean;
  defaultValues?: [] | boolean;
};

const defaultProps: AsyncSelectProps = {
  onChange: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
  loadOptions: () => {},
  value: null,
  label: '',
  invalid: false,
  disabled: false,
  name: '',
  placeholder: '- -',
  error: '',
  className: undefined,
  cacheOptions: false,
  defaultValues: false,
};

function AsyncSelect({
  onChange,
  value,
  label,
  invalid,
  disabled,
  name,
  placeholder,
  error,
  className,
  onMenuOpen,
  onMenuClose,
  loadOptions,
  cacheOptions,
  defaultValues,
  ...props
}: AsyncSelectProps) {
  const {t} = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [hasQuery, setHasQuery] = React.useState(false);
  const onChangeHandler = disabled ? undefined : onChange;

  const handleMenuOpen = React.useCallback(() => {
    setIsMenuOpen(true);
    onMenuOpen!();
  }, [onMenuOpen]);

  const handleMenuClose = React.useCallback(() => {
    setIsMenuOpen(false);
    onMenuClose!();
  }, [onMenuClose]);

  const handleChange = (value: string) => {
    setHasQuery(Boolean(value));
  };

  return (
    <Wrapper
      disabled={disabled}
      invalid={invalid || Boolean(error)}
      className={className}
    >
      {label && <Label>{label}</Label>}
      <DisplayIcon alt="display" src={displayArrowIcon} shouldRotate={isMenuOpen} />
      <AsyncReactSelect
        noOptionsMessage={() => (hasQuery ? t('no_options') : t('start_typing'))}
        onInputChange={handleChange}
        loadOptions={loadOptions}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        className="select"
        classNamePrefix="select"
        value={value}
        onChange={onChangeHandler}
        placeholder={placeholder}
        aria-label={name}
        name={name}
        isDisabled={disabled}
        cacheOptions={cacheOptions}
        defaultValues={defaultValues}
        filterOption={createFilter({ignoreAccents: false})}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
}

AsyncSelect.defaultProps = defaultProps;
export {AsyncSelect};
