import styled from 'styled-components';
import Button from '../Button';

export const Content = styled.div`
  max-width: 1047px;
  margin: 21px auto 0;
  cursor: default;
  padding: 0 120px 230px;
`;

export const Header = styled.div`
  text-align: center;
  font-size: 21px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
`;

export const SubHeader = styled.div`
  text-align: center;
  font-size: 18px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
  margin-top: 49px;
  margin-bottom: 30px;
`;

export const PlanTile = styled.div`
  position: relative;
  margin: 0 auto 22px;
  box-shadow: 0 0 7px #2699fb29;
  border-radius: 6px;
  min-height: 115px;
  width: 443px;
`;

export const PlanTileMonth = styled.div`
  font-size: 15px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  padding-top: 18px;
  margin-left: 20px;
`;

export const PlanTileBestValue = styled.div`
  background: #1a8cff;
  padding: 4px 14px;
  font-size: 15px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #ffffff;
  margin-bottom: -8px;
  width: 118px;
`;

export const PlanTilePriceInfo = styled.div`
  margin: 3px 0 3px 20px;
  font-size: 15px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  text-transform: lowercase;
`;

export const PlanTilePriceValue = styled.span`
  font-size: 24px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #1a8cff;
`;

export const PlanTilePriceDescriptionInfo = styled.div`
  font-size: 15px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  padding-bottom: 28px;
  margin-left: 20px;
`;

export const PlanTilePriceDescriptionTip = styled.span`
  margin-left: 4px;
  font-size: 13px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #9696b9;
  text-transform: lowercase;
`;

export const SubscribeButton = styled(Button)`
  margin: 0 auto 34px;
  border: 1px solid #1a8cff;
  min-width: 162px;
  position: absolute;
  right: 13px;
  bottom: -20px;
  height: 32px;

  div {
    font-size: 16px;
    margin: 0 auto;
    height: auto;
  }
`;

export const CancelButton = styled(Button)`
  margin: 19px auto 34px;
  border: none;
  color: #9696b9;
  min-width: 162px;

  &:hover,
  &:active {
    box-shadow: none;
  }

  div {
    margin: 0 auto;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7%;
`;
