import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 31px;
  text-align: left;
`;

export const Logo = styled.img`
  width: 104px;
  height: 30px;
`;
