import React, {Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {InputEventType, SelectOption} from '../../../utils/types';
import {COUNTRIES_WITH_TAXES_AGE_RULES_CALC} from '../../../utils/constants';
import {usePrevious} from '../../../utils/hooks';
import backDisplayIcon from '../../../assets/back-display-icn.svg';
import xIcon from '../../../assets/x_blue.svg';
import Modal from '../Modal';
import {AGE_CALCULATOR_FORM_NAMES, FORM_NAMES} from '../HousingTaxesSection';
import {
  Content,
  Title,
  Steps,
  SubTitle,
  Button,
  ChoicesButtonsWrapper,
  TransparentButton,
  ShortInput,
  BackButton,
  AgeExceptionsInputLabel,
  AgeExceptionsInputWrapper,
  AgeExceptionsButtonWrapper,
  TitleTooltip,
  MultiSelectWrapper,
  NextButtonWrapper,
  NumberOfNightsWrapper,
  FinishButtonWrapper,
  ShortInputCurrency,
  ShortInputWrapper,
  CloseButton,
  PricePerNightInputWrapper,
  Text,
  PricePerNightSubTitle,
  SeasonContainer,
  SeasonsGroup,
  SeasonLabel,
  AgeCalculatorWrapper,
  SeasonContainerShortInputWrapper,
  WideSeasonContainer,
} from './styled';

type TaxesCreationModalsProps = {
  open?: boolean;
  onClose: () => void;
  resetSeasons: () => void;
  resetExemptions: () => void;
  openImportTaxesModal: () => void;
  resetAgeCalculatorDefaultValuesAndCheckboxes: () => void;
  saveAgeCalculatorDefaultValues: () => void;
  handleInputChange: (event: InputEventType) => void;
  highSeasonDateRangePicker: React.ReactNode;
  lowSeasonDateRangePicker: React.ReactNode;
  ageCalculator: React.ReactNode;
  ageCalculatorWithoutSeasons: React.ReactNode;
  isHighSeasonDatesComplete: boolean;
  isLowSeasonDatesComplete: boolean;
  isAgeCalculatorNextButtonDisabled: boolean;
  inputValues: {[key: string]: string};
  setInputValues: Dispatch<SetStateAction<{[key: string]: string}>>;
  onFinish: () => void;
  exceptionsMultiSelect: React.ReactNode;
  exemptions: SelectOption[];
  country?: string;
};

const defaultProps: Partial<TaxesCreationModalsProps> = {
  open: false,
  country: '',
  exemptions: [],
};

function TaxesCreationModals({
  open,
  onClose,
  highSeasonDateRangePicker,
  lowSeasonDateRangePicker,
  isHighSeasonDatesComplete,
  isLowSeasonDatesComplete,
  handleInputChange,
  inputValues,
  setInputValues,
  onFinish,
  exceptionsMultiSelect,
  resetSeasons,
  resetExemptions,
  openImportTaxesModal,
  exemptions,
  country,
  ageCalculator,
  resetAgeCalculatorDefaultValuesAndCheckboxes,
  saveAgeCalculatorDefaultValues,
  isAgeCalculatorNextButtonDisabled,
  ageCalculatorWithoutSeasons,
}: TaxesCreationModalsProps) {
  const {t} = useTranslation();
  const {triggerValidation} = useFormContext();
  const [step, setStep] = React.useState(1);
  const [totalSteps] = React.useState(4);
  const [hasSeasons, setHasSeasons] = React.useState(false);
  const prevHasSeasons = usePrevious(hasSeasons);
  const [hasNoSeasons, setHasNoSeasons] = React.useState(false);
  const [isAskedForSeasons, setIsAskedForSeasons] = React.useState(false);
  const [hasAgeExceptions, setHasAgeExceptions] = React.useState(false);

  const isCountryWithTaxesCalc = COUNTRIES_WITH_TAXES_AGE_RULES_CALC.includes(country!);

  React.useEffect(
    function revalidateAgeCalculatorOnMount() {
      if (step === 2 && isCountryWithTaxesCalc) {
        triggerValidation(AGE_CALCULATOR_FORM_NAMES);
      }
    },
    [step, isCountryWithTaxesCalc, triggerValidation],
  );

  const goBack = () => {
    setStep((prevState) => {
      if (prevState === 4) {
        return prevState - 1;
      }

      if (prevState === 3) {
        return prevState - 1;
      }

      if (prevState === 2) {
        if (hasAgeExceptions) {
          setHasAgeExceptions(false);
          return prevState;
        }

        if (isCountryWithTaxesCalc) {
          saveAgeCalculatorDefaultValues();

          if (isAskedForSeasons && !hasSeasons) {
            setIsAskedForSeasons(false);
          }
        }

        return prevState - 1;
      }

      if (step === 1) {
        if (isAskedForSeasons) {
          setIsAskedForSeasons(false);
          setHasSeasons(false);
        }
      }

      return prevState;
    });
  };

  const goNext = () => {
    setStep((prevState) => prevState + 1);
  };

  const saveDefaultFormValuesAndGoNext = () => {
    saveAgeCalculatorDefaultValues();
    goNext();
  };

  const handleHasSeasons = () => {
    if (hasNoSeasons) {
      if (isCountryWithTaxesCalc) {
        resetAgeCalculatorDefaultValuesAndCheckboxes();
      } else {
        setInputValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.highPrice]: '',
            [FORM_NAMES.lowPrice]: '',
          };
        });
      }

      setHasNoSeasons(false);
    }

    setHasSeasons(true);
    setIsAskedForSeasons(true);
  };

  const handleNoSeasons = () => {
    if (prevHasSeasons) {
      resetSeasons();
      setInputValues((prevState) => {
        return {
          ...prevState,
          [FORM_NAMES.lowPrice]: '',
          [FORM_NAMES.highPrice]: '',
        };
      });
    }

    if (isCountryWithTaxesCalc) {
      if (prevHasSeasons) {
        resetAgeCalculatorDefaultValuesAndCheckboxes();
      }

      goNext();
    }

    setHasNoSeasons(true);
    setIsAskedForSeasons(true);
  };

  const handleHasAgeExceptions = () => {
    setHasAgeExceptions(true);
  };

  const handleHasNoAgeExceptions = () => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        [FORM_NAMES.lessThanAge]: '',
      };
    });

    goNext();
  };

  const handleNoExemptions = () => {
    resetExemptions();
    goNext();
  };

  return (
    <Modal open={open}>
      <Content>
        <CloseButton type="button" onClick={onClose}>
          <img src={xIcon} alt="Cross" />
        </CloseButton>
        <Steps>
          {isAskedForSeasons && (
            <BackButton type="button" onClick={goBack}>
              <img src={backDisplayIcon} alt="Arrow" />
            </BackButton>
          )}
          {step}/{totalSteps}
        </Steps>
        {step === 1 && (
          <div>
            {!isAskedForSeasons ? (
              <div>
                <Title>{t('lets_do_quick_setup')}</Title>
                <SubTitle>
                  {t('do_you_have_different_taxes_depending_on_season')}
                </SubTitle>
                <ChoicesButtonsWrapper>
                  <Button label={t('yes')} onClick={handleHasSeasons} />
                  <Button label={t('no')} onClick={handleNoSeasons} />
                </ChoicesButtonsWrapper>
                <TransparentButton type="button" onClick={openImportTaxesModal}>
                  {t('would_you_like_import_setup_from_another_property')}
                </TransparentButton>
              </div>
            ) : (
              <div>
                {isCountryWithTaxesCalc ? (
                  <div>
                    <Title>{t('seasons')}</Title>
                    <SeasonsGroup>
                      <SeasonContainer>
                        <SeasonLabel>{t('high_season')}</SeasonLabel>
                        {highSeasonDateRangePicker}
                      </SeasonContainer>
                      <SeasonContainer>
                        <SeasonLabel>{t('low_season')}</SeasonLabel>
                        {lowSeasonDateRangePicker}
                      </SeasonContainer>
                    </SeasonsGroup>
                    <NextButtonWrapper>
                      <Button
                        disabled={!isHighSeasonDatesComplete || !isLowSeasonDatesComplete}
                        label={t('next')}
                        onClick={goNext}
                      />
                    </NextButtonWrapper>
                  </div>
                ) : (
                  <div>
                    {!hasSeasons && (
                      <div>
                        <Title>{t('price')}</Title>
                        <PricePerNightSubTitle>
                          {t('what_is_the_tax_per_night')}
                        </PricePerNightSubTitle>
                        <Text>{t('introduce_tax_per_night')}</Text>
                        <PricePerNightInputWrapper>
                          <ShortInputWrapper>
                            <ShortInput
                              value={inputValues[FORM_NAMES.highPrice]}
                              onChange={handleInputChange}
                              name={FORM_NAMES.highPrice}
                              type="number"
                              label={t('price_per_night')}
                              placeholder={t('enter_price')}
                              step="0.01"
                              inputMode="decimal"
                            />
                            <ShortInputCurrency>€</ShortInputCurrency>
                          </ShortInputWrapper>
                        </PricePerNightInputWrapper>
                        <NextButtonWrapper>
                          <Button
                            disabled={!inputValues[FORM_NAMES.highPrice]}
                            label={t('next')}
                            onClick={goNext}
                          />
                        </NextButtonWrapper>
                      </div>
                    )}
                    {hasSeasons && (
                      <div>
                        <Title>{t('seasons')}</Title>
                        <SeasonsGroup>
                          <WideSeasonContainer>
                            <SeasonLabel>{t('high_season')}</SeasonLabel>
                            {highSeasonDateRangePicker}
                            <SeasonContainerShortInputWrapper>
                              <ShortInput
                                value={inputValues[FORM_NAMES.highPrice]}
                                onChange={handleInputChange}
                                name={FORM_NAMES.highPrice}
                                type="number"
                                label={t('price_per_night')}
                                placeholder={t('enter_price')}
                                step="0.01"
                                inputMode="decimal"
                              />
                              <ShortInputCurrency>€</ShortInputCurrency>
                            </SeasonContainerShortInputWrapper>
                          </WideSeasonContainer>
                          <WideSeasonContainer>
                            <SeasonLabel>{t('low_season')}</SeasonLabel>
                            {lowSeasonDateRangePicker}
                            <SeasonContainerShortInputWrapper>
                              <ShortInput
                                type="number"
                                onChange={handleInputChange}
                                label={t('price_per_night')}
                                placeholder={t('enter_price')}
                                step="0.01"
                                inputMode="decimal"
                                name={FORM_NAMES.lowPrice}
                                value={inputValues[FORM_NAMES.lowPrice]}
                              />
                              <ShortInputCurrency>€</ShortInputCurrency>
                            </SeasonContainerShortInputWrapper>
                          </WideSeasonContainer>
                        </SeasonsGroup>
                        <NextButtonWrapper>
                          <Button
                            disabled={
                              !isHighSeasonDatesComplete ||
                              !isLowSeasonDatesComplete ||
                              !inputValues[FORM_NAMES.highPrice] ||
                              !inputValues[FORM_NAMES.lowPrice]
                            }
                            label={t('next')}
                            onClick={goNext}
                          />
                        </NextButtonWrapper>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {step === 2 && (
          <div>
            {isCountryWithTaxesCalc ? (
              <div>
                <Title>{t('age_rules')}</Title>
                <AgeCalculatorWrapper>
                  {hasSeasons ? ageCalculator : ageCalculatorWithoutSeasons}
                </AgeCalculatorWrapper>
                <NextButtonWrapper>
                  <Button
                    disabled={isAgeCalculatorNextButtonDisabled}
                    label={t('next')}
                    onClick={saveDefaultFormValuesAndGoNext}
                  />
                </NextButtonWrapper>
              </div>
            ) : (
              <div>
                <Title>{t('age_exceptions')}</Title>
                <SubTitle>
                  {t('are_some_guests_exempted_to_pay_taxes_dep_on_age')}
                </SubTitle>
                {hasAgeExceptions ? (
                  <div>
                    <AgeExceptionsInputWrapper>
                      <AgeExceptionsInputLabel>{t('less_than')}</AgeExceptionsInputLabel>
                      <ShortInput
                        label={t('age')}
                        placeholder={t('enter_age')}
                        type="number"
                        inputMode="numeric"
                        onChange={handleInputChange}
                        name={FORM_NAMES.lessThanAge}
                        value={inputValues[FORM_NAMES.lessThanAge]}
                      />
                    </AgeExceptionsInputWrapper>
                    <AgeExceptionsButtonWrapper>
                      <Button label={t('next')} onClick={goNext} />
                    </AgeExceptionsButtonWrapper>
                  </div>
                ) : (
                  <ChoicesButtonsWrapper>
                    <Button label={t('yes')} onClick={handleHasAgeExceptions} />
                    <Button label={t('no')} onClick={handleHasNoAgeExceptions} />
                  </ChoicesButtonsWrapper>
                )}
              </div>
            )}
          </div>
        )}
        {step === 3 && (
          <div>
            <Title>
              {t('more_exceptions')}{' '}
              <TitleTooltip
                content={t('exception_will_be_shown_to_a_guest')}
                position="bottom"
                trigger={<span>({t('what_is_this')})</span>}
              />
            </Title>
            <SubTitle>{t('do_you_have_more_exceptions')}</SubTitle>
            <MultiSelectWrapper>{exceptionsMultiSelect}</MultiSelectWrapper>
            <NextButtonWrapper>
              <Button disabled={!exemptions?.length} label={t('next')} onClick={goNext} />
            </NextButtonWrapper>
            <TransparentButton type="button" onClick={handleNoExemptions}>
              {t('there_are_no_exceptions')}
            </TransparentButton>
          </div>
        )}
        {step === 4 && (
          <div>
            <Title>
              {t('number_of_taxable_nights')}{' '}
              <TitleTooltip
                content={t('what_is_taxable_nights')}
                position="bottom"
                trigger={<span>({t('what_is_this')})</span>}
              />
            </Title>
            <SubTitle>
              {t('do_you_have_max_tax_nights')}
              <p />
              {t('if_not_click_finish')}
            </SubTitle>
            <NumberOfNightsWrapper>
              <ShortInput
                label={t('number_of_nights')}
                placeholder={t('enter_number')}
                type="number"
                inputMode="numeric"
                name={FORM_NAMES.maxNightsTaxed}
                value={inputValues[FORM_NAMES.maxNightsTaxed]}
                onChange={handleInputChange}
              />
            </NumberOfNightsWrapper>
            <FinishButtonWrapper>
              <Button label={t('finish')} onClick={onFinish} />
            </FinishButtonWrapper>
          </div>
        )}
      </Content>
    </Modal>
  );
}

TaxesCreationModals.defaultProps = defaultProps;
export {TaxesCreationModals};
