import i18n from '../i18n';
import {
  getGroupMembersNumber,
  getGroupNumberOfGuests,
  getGuestGroupMembers,
  getGuestLeader,
} from './guestGroup';
import {
  getHasSecurityDeposit,
  getHasTaxes,
  getIsSelfCheckInEnabled,
  getStatTypeIfStatActive,
} from './reservations';
import {getCountryCode} from './housing';
import {GuestGroup, Reservation} from './types';
import {COUNTRY_CODES, STAT_TYPES_WITHOUT_CHECK_OUT, STATUS_CODES} from './constants';

enum STATUS_TYPES {
  checkIn = 'CHECK_IN',
  policeCheckIn = 'POLICE_CHECK_IN',
  policeCheckOut = 'POLICE_CHECK_OUT',
  statsCheckIn = 'STATS_CHECK_IN',
  statsCheckOut = 'STATS_CHECK_OUT',
  selfCheckIn = 'SELF_CHECK_IN',
  taxes = 'TAXES',
  securityDeposit = 'SECURITY_DEPOSIT',
}

function getDisplayStatuses(reservation?: Reservation) {
  const countryCode = getCountryCode(reservation?.housing);
  const isSelfCheckInEnabled = getIsSelfCheckInEnabled(reservation);
  const statType = getStatTypeIfStatActive(reservation?.housing);
  const hasTaxes = getHasTaxes(reservation);
  const hasSecurityDeposit = getHasSecurityDeposit(reservation);

  let statuses: string[] = [STATUS_TYPES.checkIn];

  if (!reservation) {
    return statuses;
  }

  if (isSelfCheckInEnabled) {
    statuses = [...statuses, STATUS_TYPES.selfCheckIn];
  }

  if (hasTaxes) {
    statuses = [...statuses, STATUS_TYPES.taxes];
  }

  if (hasSecurityDeposit) {
    statuses = [...statuses, STATUS_TYPES.securityDeposit];
  }

  switch (countryCode) {
    case COUNTRY_CODES.portugal:
    case COUNTRY_CODES.czech:
    case COUNTRY_CODES.spain: {
      return [...statuses, STATUS_TYPES.policeCheckIn];
    }
    case COUNTRY_CODES.italy: {
      if (STAT_TYPES_WITHOUT_CHECK_OUT.includes(statType)) {
        return [...statuses, STATUS_TYPES.policeCheckIn, STATUS_TYPES.statsCheckIn];
      } else {
        return [
          ...statuses,
          STATUS_TYPES.policeCheckIn,
          STATUS_TYPES.statsCheckIn,
          STATUS_TYPES.statsCheckOut,
        ];
      }
    }
    case COUNTRY_CODES.germany: {
      return [...statuses, STATUS_TYPES.statsCheckIn, STATUS_TYPES.statsCheckOut];
    }
    case COUNTRY_CODES.thailand: {
      return [...statuses, STATUS_TYPES.policeCheckIn];
    }
    case COUNTRY_CODES.colombia:
    case COUNTRY_CODES.dubai: {
      return [...statuses, STATUS_TYPES.policeCheckIn, STATUS_TYPES.policeCheckOut];
    }
    case COUNTRY_CODES.austria: {
      return [...statuses, STATUS_TYPES.policeCheckIn];
    }
    default: {
      return statuses;
    }
  }
}

function getCheckInStatusDescription(reservation: Reservation) {
  const registeredGuestsNumber = getGroupMembersNumber(reservation?.guest_group);
  const guestsNumber = getGroupNumberOfGuests(reservation?.guest_group);

  return `${registeredGuestsNumber}/${guestsNumber} ${i18n.t('guests_checked_in')}`;
}

type StatusTypes = {
  guestGroup?: GuestGroup;
  status: 'police' | 'statistics' | 'data';
  type?: 'in' | 'out';
  code?: string;
};

function getNumberOfStatusTypeGuests({guestGroup, status, type, code}: StatusTypes) {
  if (!code) {
    throw new Error('Missing status code at getNumberOfStatusTypeGuests call.');
  }

  const members = guestGroup?.members;
  if (!members?.length) {
    return 0;
  }

  if (status === 'data') {
    return members.filter((guest) => {
      return guest?.statuses?.[status]?.code === code;
    }).length;
  }

  return members.filter((guest) => {
    return guest?.statuses?.[status]?.[type!]?.code === code;
  }).length;
}

type DescriptionTypes = Pick<StatusTypes, 'status' | 'type'> & {
  reservation: Reservation;
};

function getStatusDescription({status, type, reservation}: DescriptionTypes) {
  const hasStatusAccount =
    status === 'police'
      ? reservation?.housing?.police_account
      : reservation?.housing?.stat_account;
  const countryCode = getCountryCode(reservation?.housing);

  const registeredGuestsNumber = getGroupMembersNumber(reservation?.guest_group);
  const guestsNumber = getGroupNumberOfGuests(reservation?.guest_group);

  const numberOfNewGuests = getNumberOfStatusTypeGuests({
    status,
    type,
    guestGroup: reservation?.guest_group,
    code: STATUS_CODES.new,
  });

  const numberOfFailedGuests = getNumberOfStatusTypeGuests({
    status,
    type,
    guestGroup: reservation?.guest_group,
    code: STATUS_CODES.error,
  });

  const numberOfCompletedGuests = getNumberOfStatusTypeGuests({
    status,
    type,
    guestGroup: reservation?.guest_group,
    code: STATUS_CODES.complete,
  });

  const missingNumberOfGuests =
    guestsNumber - (numberOfCompletedGuests + numberOfFailedGuests);

  if (!hasStatusAccount) {
    return i18n.t('credentials_missing');
  }

  if (
    !registeredGuestsNumber ||
    numberOfNewGuests ||
    countryCode === COUNTRY_CODES.germany
  ) {
    const newGuestsString = `${numberOfNewGuests} ${i18n.t(
      'guests_waiting_to_be_registered',
    )}`;
    if (numberOfCompletedGuests) {
      return `${numberOfCompletedGuests} ${i18n
        .t('complete')
        .toLowerCase()}, ${newGuestsString}`;
    }
    return newGuestsString;
  }

  if (numberOfCompletedGuests === guestsNumber) {
    return i18n.t('completed');
  }

  let detailedStatus = [];

  if (numberOfCompletedGuests) {
    detailedStatus.push(`${numberOfCompletedGuests} ${i18n.t('completed')}`);
  }

  if (numberOfFailedGuests) {
    detailedStatus.push(`${numberOfFailedGuests} ${i18n.t('error')}`);
  }

  if (missingNumberOfGuests) {
    detailedStatus.push(`${missingNumberOfGuests} ${i18n.t('missing')}`);
  }

  return detailedStatus;
}

function getIsCheckInOnlineCompleted(reservation: Reservation) {
  const guestLeader = getGuestLeader(reservation!.guest_group);
  const isBiomatchForAllEnabled =
    reservation?.housing?.is_biometric_match_for_all_enabled;

  if (isBiomatchForAllEnabled) {
    const members = getGuestGroupMembers(reservation?.guest_group);
    return members.every((guest) => {
      return guest?.biomatch_selfie && guest?.biomatch_doc;
    });
  }

  return guestLeader?.biomatch_doc && guestLeader?.biomatch_selfie;
}

function getIsBiomatchPassed(reservation: Reservation) {
  const guestLeader = getGuestLeader(reservation!.guest_group);
  const isBiomatchForAllEnabled =
    reservation?.housing?.is_biometric_match_for_all_enabled;

  if (isBiomatchForAllEnabled) {
    const members = getGuestGroupMembers(reservation?.guest_group);
    return members.every((guest) => {
      return guest?.biomatch_passed;
    });
  }

  return Boolean(guestLeader?.biomatch_passed);
}

function getSelfCheckInStatusDescription(reservation: Reservation) {
  if (!getIsCheckInOnlineCompleted(reservation)) {
    return i18n.t('pending');
  }

  if (getIsBiomatchPassed(reservation)) {
    return i18n.t('complete');
  }

  return i18n.t('failed');
}

function getTaxesStatusDescription(reservation: Reservation) {
  const isPaid = reservation?.have_taxes_been_paid;

  if (isPaid) {
    return i18n.t('paid');
  }

  return i18n.t('pending');
}

function getSecurityDepositStatusDescription(reservation: Reservation) {
  const onHold = reservation.security_deposit?.status === 'CONFIRMED';
  const isReleased = reservation.security_deposit?.status === 'RELEASED';

  if (onHold) {
    return i18n.t('on_hold');
  }

  if (isReleased) {
    return i18n.t('released');
  }

  return i18n.t('pending');
}

type Descriptions = {
  [key: string]: string | JSX.Element | null | string[];
};

function getStatusesDescriptions(reservation?: Reservation) {
  let descriptions: Descriptions = {
    [STATUS_TYPES.checkIn]: null,
    [STATUS_TYPES.policeCheckIn]: null,
    [STATUS_TYPES.policeCheckOut]: null,
    [STATUS_TYPES.statsCheckIn]: null,
    [STATUS_TYPES.statsCheckOut]: null,
    [STATUS_TYPES.selfCheckIn]: null,
    [STATUS_TYPES.taxes]: null,
  };

  if (!reservation) {
    return descriptions;
  }

  const displayStatuses = getDisplayStatuses(reservation);

  if (displayStatuses.includes(STATUS_TYPES.checkIn)) {
    descriptions[STATUS_TYPES.checkIn] = getCheckInStatusDescription(reservation);
  }

  if (displayStatuses.includes(STATUS_TYPES.policeCheckIn)) {
    descriptions[STATUS_TYPES.policeCheckIn] = getStatusDescription({
      reservation,
      status: 'police',
      type: 'in',
    });
  }

  if (displayStatuses.includes(STATUS_TYPES.policeCheckOut)) {
    descriptions[STATUS_TYPES.policeCheckOut] = getStatusDescription({
      reservation,
      status: 'police',
      type: 'out',
    });
  }

  if (displayStatuses.includes(STATUS_TYPES.statsCheckIn)) {
    descriptions[STATUS_TYPES.statsCheckIn] = getStatusDescription({
      reservation,
      status: 'statistics',
      type: 'in',
    });
  }

  if (displayStatuses.includes(STATUS_TYPES.statsCheckOut)) {
    descriptions[STATUS_TYPES.statsCheckOut] = getStatusDescription({
      reservation,
      status: 'statistics',
      type: 'out',
    });
  }

  if (displayStatuses.includes(STATUS_TYPES.selfCheckIn)) {
    descriptions[STATUS_TYPES.selfCheckIn] = getSelfCheckInStatusDescription(reservation);
  }

  if (displayStatuses.includes(STATUS_TYPES.taxes)) {
    descriptions[STATUS_TYPES.taxes] = getTaxesStatusDescription(reservation);
  }

  if (displayStatuses.includes(STATUS_TYPES.securityDeposit)) {
    descriptions[STATUS_TYPES.securityDeposit] = getSecurityDepositStatusDescription(
      reservation,
    );
  }

  return descriptions;
}

export {getStatusesDescriptions, STATUS_TYPES, getDisplayStatuses};
