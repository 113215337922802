import {format} from 'date-fns';
import {CARD_BRANDS} from './constants';
import {Guest} from './types';

type AdditionalGuestDataTypes = {
  reservation?: any;
  ocrWasUsed?: boolean;
};

function buildGuest(data: any, {reservation, ocrWasUsed}: AdditionalGuestDataTypes = {}) {
  if (!data) {
    return null;
  }

  const hasDocIssuePlace = data.countryOfIssue || data.cityOfIssue?.value;
  const hasBirthPlace = data.birthCountry || data.cityOfBirth;
  const hasDocument =
    data.docType ||
    data.docNumber ||
    data.visaNumber ||
    data.back_side_scan ||
    data.front_side_scan;
  const hasResidence =
    data.residenceAddress ||
    data.residenceCity ||
    data.residenceCountry ||
    data.residencePostalCode ||
    data.residenceProvince;
  const hasNextDestination =
    data.nextDestinationAddress ||
    data.nextDestinationCity ||
    data.nextDestinationCountry ||
    data.nextDestinationDistrict;
  const hasArrival =
    data.arrivalCountry || data.arrivalMunicipality || data.arrivalDistrict;
  const birth_date = data.birthDate
    ? format(new Date(data.birthDate), 'yyyy-MM-dd')
    : undefined;
  const issue_date = data.docDateOfIssue
    ? format(new Date(data.docDateOfIssue), 'yyyy-MM-dd')
    : undefined;
  let documents: any = hasDocument
    ? [
        {
          issue_date,
          front_side_scan: !data?.front_side_scan?.startsWith('https')
            ? data.front_side_scan
            : undefined,
          back_side_scan: !data?.back_side_scan?.startsWith('https')
            ? data.back_side_scan
            : undefined,
          type: data.docType?.value,
          number: data.docNumber,
          issue_place: hasDocIssuePlace
            ? {
                country: data.countryOfIssue?.value,
                city: data.cityOfIssue?.value || data.cityOfIssue || undefined,
                division_level_3: data.cityOfIssue?.value,
              }
            : undefined,
        },
      ]
    : undefined;
  const visaDoc = data.visaNumber
    ? {
        type: CARD_BRANDS.visa,
        number: data.visaNumber,
      }
    : undefined;

  if (visaDoc) {
    if (documents) {
      documents.unshift(visaDoc);
    } else {
      documents = [visaDoc];
    }
  }

  return {
    birth_date,
    documents,
    email: data.email || undefined,
    phone: data.phone || undefined,
    fiscal_code: data.fiscal_code,
    origin: 'DASHBOARD',
    ocr_was_used: ocrWasUsed,
    reservation_id: reservation?.id,
    name: data.name,
    surname: data.surname,
    second_surname: data.second_surname,
    nationality: data.nationality?.value,
    citizenship: data.citizenship?.value,
    purpose_of_stay: data.purposeOfStay?.value,
    sex: data.sex?.value,
    tax_exemption: data.taxExemption?.value,
    full_tourist_tax: data.full_tourist_tax,
    birth_place: hasBirthPlace
      ? {
          country: data.birthCountry?.value,
          city: data.cityOfBirth?.value ? undefined : data.cityOfBirth,
          division_level_3: data.cityOfBirth?.value,
        }
      : undefined,
    residence: hasResidence
      ? {
          address: data.residenceAddress,
          city: data.residenceCity?.value ? undefined : data.residenceCity,
          country: data.residenceCountry?.value,
          postal_code: data.residencePostalCode,
          division_level_2: data.residenceProvince?.value,
          division_level_3: data.residenceCity?.value,
        }
      : undefined,
    arrived_from: hasArrival
      ? {
          country: data.arrivalCountry?.value,
          division_lvl_1: data.arrivalDistrict?.value,
          division_lvl_2: data.arrivalMunicipality?.value,
        }
      : undefined,
    next_destination: hasNextDestination
      ? {
          address: data.nextDestinationAddress,
          city: data.nextDestinationCity,
          country: data.nextDestinationCountry?.value,
          division_level_1: data.nextDestinationDistrict?.value,
          division_level_2: data.nextDestinationMunicipality?.value,
        }
      : undefined,
    signature: !data.signature?.startsWith('https') ? data.signature : undefined,
  };
}

function getGuestPoliceStatusInCode(guest?: Guest) {
  return guest?.statuses?.police?.in?.code || '';
}

function getGuestStatStatusInCode(guest?: Guest) {
  return guest?.statuses?.statistics?.in?.code || '';
}

export {buildGuest, getGuestPoliceStatusInCode, getGuestStatStatusInCode};
