import React from 'react';
import TrialHeader from '../components/dashboard/TrialHeader';
import Header from '../components/dashboard/Header';
import DocumentsHeader from '../components/dashboard/DocumentsHeader';
import EntryFormsGuestbookView from '../components/dashboard/EntryFormsGuestbookView';

function DocumentsEntryFormsGuestbook() {
  return (
    <>
      <TrialHeader />
      <Header />
      <DocumentsHeader />
      <EntryFormsGuestbookView />
    </>
  );
}

export {DocumentsEntryFormsGuestbook};
