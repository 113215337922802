import styled from 'styled-components';
import Button from '../Button';
import ModalButton from '../ModalButton';
import Tooltip from '../Tooltip';
import {Heading as BaseHeading} from '../../../styled/common';

export const Heading = styled(BaseHeading)`
  grid-template-rows: 60px;
`;

export const LogoutButton = styled(Button)`
  min-width: 136px;
`;

export const AccountCell = styled.div``;

export const Title = styled.div`
  color: #161643;
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 21px;
  margin-bottom: 8px;
`;

export const AccountType = styled.div`
  font-family: ProximaNova-Regular, sans-serif;
  color: #161643;
  font-size: 16px;
  display: flex;
  justify-content: center;
`;

export const AccountTypeTooltip = styled(Tooltip)`
  margin-left: 5px;
`;

export const DeleteAccountButton = styled(Button)`
  margin-top: 43px;
`;

export const DeleteAccountModalButton = styled(ModalButton)`
  border-color: #ff2467;

  &:hover {
    background-color: #ff2467;
    border-color: #ff2467;
  }
`;
