import React from 'react';
import {default as Content} from '../components/onboarding/PmsVillas365OriginRegister';

function PmsVillas365OriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsVillas365OriginRegister};
