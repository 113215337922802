import styled from 'styled-components';
import Button from '../Button';

export const Content = styled.div`
  max-width: 1040px;
  padding: 0 20px;
  margin: 20px auto 76px;
`;

export const DocsLinkButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: #9696b9;
  font-size: 14px;
  font-family: ProximaNova-Medium, sans-serif;
  cursor: pointer;
  padding-left: 0;
  transition: all 0.15s ease-in-out;
`;

type TableWrapperProps = {
  hasLastRowBorder?: boolean;
};

export const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;
  margin: 20px auto 0;
  box-shadow: 0 5px 5px #2699fb1a;

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    & > thead {
      cursor: default;
      background-color: #cbe9ff;
      text-transform: uppercase;
      font-family: ProximaNova-Bold, sans-serif;
      font-size: 10px;
      color: #161643;

      & > tr {
        height: 60px;
        text-align: left;

        & th:nth-child(1) {
          padding-left: 14px;
        }

        & th:nth-child(3) {
          width: 36%;
          min-width: 60px;
        }

        & th:nth-child(4) {
          width: 25%;
        }
      }
    }

    & > tbody > tr {
      font-family: ProximaNova-Semibold, sans-serif;
      font-size: 14px;
      color: #161643;
      height: 60px;
      border-bottom: 1px solid #f0f0f3;
      cursor: pointer;
      transition: background-color 0.07s ease-in-out;

      &:hover {
        background-color: #f7fbff;

        & ${DocsLinkButton} {
          color: #007eec;
          opacity: 0.8;
        }
      }

      &:active ${DocsLinkButton} {
        opacity: 1;
      }

      & td:nth-child(1) {
        padding-left: 14px;
      }

      &:last-child {
        border-bottom: ${(props) => !props.hasLastRowBorder && 'none'};
      }
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EntryFormsButton = styled(Button)`
  min-width: 136px;
  margin-left: 10px;
`;

export const DirectDownloadIcon = styled.img`
  height: 14px;
  width: 18px;
`;

export const HeadingButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
