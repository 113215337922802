import React from 'react';
import {StyledButton, Label, Selector, FillSelector} from './styled';

type SelectorButtonProps = {
  label: React.ReactNode | JSX.Element | string;
  onClick?: () => void;
  active?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

const defaultProps: SelectorButtonProps = {
  label: '',
  onClick: () => {},
  active: false,
  className: undefined,
  type: 'button',
  disabled: false,
};

function SelectorButton({
  label,
  active,
  onClick,
  className,
  type,
  disabled,
  ...props
}: SelectorButtonProps) {
  return (
    <StyledButton
      type={type}
      active={active}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <Selector>{active && <FillSelector />}</Selector>
      <Label>{label}</Label>
    </StyledButton>
  );
}

SelectorButton.defaultProps = defaultProps;
export {SelectorButton};
