import styled from 'styled-components';
import BaseCheckbox from '../components/dashboard/Checkbox';
import {Box, AcceptIconImg} from '../components/dashboard/Checkbox/styled';
import {Heading} from './common';

export const ReportHeading = styled(Heading)`
  grid-template-columns: 1fr 2fr 1fr;
`;

export const Title = styled.div`
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
  font-size: 21px;
`;

export const Section = styled.div`
  padding-bottom: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid rgb(220 229 242);
`;

export const SectionTitle = styled.div`
  color: #161643;
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 18px;
  margin-bottom: 18px;
`;

export const Main = styled.main`
  margin-top: 28px;
`;

export const CheckboxesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
  grid-template-rows: repeat(10, auto);
`;

export const Checkbox = styled(BaseCheckbox)`
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 17px;
  color: #161643;
  display: inline-block;
  margin-bottom: 18px;

  & ${Box} {
    height: 16px;
    width: 16px;
    margin-right: 12px;
  }

  & ${AcceptIconImg} {
    height: 9px;
    position: relative;
    top: -4px;
  }
`;

export const DirectDownloadIcon = styled.img`
  height: 14px;
  width: 18px;
`;

export const Text = styled.div`
  font-family: ProximaNova-Regular, sans-serif;
  color: #161643;
  font-size: 16px;
  margin-bottom: 28px;
`;

export const LoaderWrapper = styled.div`
  margin-top: 13%;
  text-align: center;
`;
