import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../index.css';
import i18n from '../i18n';
import {Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {I18nextProvider} from 'react-i18next';
import {StripeProvider} from 'react-stripe-elements';
import {AuthProvider} from './auth';
import {UserProvider} from './user';
import {ComputedDetailsProvider} from './computedDetails';
import {SubscriptionProvider} from './subscription';
import {WebsocketProvider} from './websocket';
import {OpenModalsProvider} from './openModals';
import {ContractsExportProvider} from './contractsExport';
import {TaxesExportProvider} from './taxesExport';
import {GlobalStyle} from '../styled/global';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || '';

function resetI18nLanguage() {
  localStorage.removeItem('i18nextLng');
}

type AppProvidersProps = {
  children: React.ReactNode | JSX.Element;
  browserHistory: any;
};

const defaultProps: Partial<AppProvidersProps> = {
  children: null,
};

function AppProviders({children, browserHistory}: AppProvidersProps) {
  React.useEffect(() => {
    window.addEventListener('beforeunload', resetI18nLanguage);
    return () => {
      window.removeEventListener('beforeunload', resetI18nLanguage);
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <>
          <GlobalStyle />
          <Router history={browserHistory}>
            <AuthProvider>
              <UserProvider>
                <WebsocketProvider>
                  <SubscriptionProvider>
                    <OpenModalsProvider>
                      <ComputedDetailsProvider>
                        <ContractsExportProvider>
                          <TaxesExportProvider>
                            <ToastContainer
                              newestOnTop
                              hideProgressBar
                              pauseOnFocusLoss={false}
                              autoClose={false}
                              position="bottom-right"
                            />
                            {children}
                          </TaxesExportProvider>
                        </ContractsExportProvider>
                      </ComputedDetailsProvider>
                    </OpenModalsProvider>
                  </SubscriptionProvider>
                </WebsocketProvider>
              </UserProvider>
            </AuthProvider>
          </Router>
        </>
      </StripeProvider>
    </I18nextProvider>
  );
}

AppProviders.defaultProps = defaultProps;
export default AppProviders;
