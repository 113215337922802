import i18n from '../i18n';
import {SelectOption} from './types';

const PATTERNS = {
  dniAndDl: /^[0-9]{8}[a-zA-Z]{1}$/,
  nie: /^[a-zA-Z]{1}[0-9]{7}[a-zA-Z]{1}$/,
  name: /^[A-Za-zÀ-ÖØ-öø-ÿ]*( *[A-Za-zÀ-ÖØ-öø-ÿ *]+)*$/,
  nameWithoutAccents: /^[A-Za-z]*( *[A-Za-z *]+)*$/,
  email: /^([\w.%+-]+)@([\w-]+\.)?([\w]{2,}\.)?([\w]{2,})$/i,
  fiscalCode: /^[A-Za-z0-9]+$/,
  housingEdit: /^\/(properties)\/.{32}/,
  housingAdd: /^\/(properties)\/add$/,
  reservationEdit: /^\/(bookings)\/.{32}/,
  docNumber: /^[a-zA-Z0-9\\s]*$/,
};

const COUNTRY_CODES = {
  spain: 'ES',
  italy: 'IT',
  germany: 'DE',
  portugal: 'PT',
  dubai: 'AE',
  netherlands: 'NL',
  austria: 'AT',
  uk: 'GB',
  france: 'FR',
  belgium: 'BE',
  hungary: 'HU',
  czech: 'CZ',
  colombia: 'CO',
  thailand: 'TH',
  romania: 'RO',
  greece: 'GR',
  slovenia: 'SI',
  croatia: 'HR',
};

const LANGUAGES_ISO_3 = {
  spanish: 'spa',
  italian: 'ita',
  english: 'eng',
  french: 'fra',
  russian: 'rus',
  portuguese: 'por',
  german: 'deu',
  hungarian: 'hun',
  czech: 'ces',
};

const POLICE_CODES = {
  nationalPolice: 'POL',
};

const POLICE_TYPES = {
  [COUNTRY_CODES.spain]: [
    {value: 'NAT', label: i18n.t('police_types.nat')},
    {value: POLICE_CODES.nationalPolice, label: i18n.t('police_types.pol')},
    {value: 'ERT', label: i18n.t('police_types.ert')},
    {value: 'MOS', label: i18n.t('police_types.mos')},
    {value: 'FAKE', label: i18n.t('police_types.fake')},
  ],
  [COUNTRY_CODES.italy]: [
    {value: 'ISP', label: i18n.t('police_types.isp')},
    {value: 'FAKE', label: i18n.t('police_types.fake')},
  ],
  [COUNTRY_CODES.portugal]: [
    {value: 'SEF', label: i18n.t('police_types.sef')},
    {value: 'FAKE', label: i18n.t('police_types.fake')},
  ],
  [COUNTRY_CODES.dubai]: [
    {value: 'UHH', label: i18n.t('police_types.uhh')},
    {value: 'FAKE', label: i18n.t('police_types.fake')},
  ],
  [COUNTRY_CODES.czech]: [
    {value: 'FAKE', label: i18n.t('police_types.fake')},
    {value: 'CUP', label: i18n.t('police_types.cup')},
  ],
  [COUNTRY_CODES.colombia]: [{value: 'COL', label: i18n.t('police_types.colombia_sire')}],
  [COUNTRY_CODES.thailand]: [
    {value: 'FAKE', label: i18n.t('police_types.fake')},
    {value: 'THAI', label: i18n.t('police_types.thai')},
  ],
  [COUNTRY_CODES.austria]: [
    {value: 'FER', label: i18n.t('police_types.austrian_feratel')},
  ],
  [COUNTRY_CODES.slovenia]: [
    {value: 'AJP', label: i18n.t('police_types.slovenia_agency')},
  ],
  [COUNTRY_CODES.croatia]: [
    {value: 'HREV', label: i18n.t('police_types.croatia_evisitor')},
  ],
};
const COUNTRIES_WITH_STAT = [COUNTRY_CODES.germany, COUNTRY_CODES.italy];
const COUNTRIES_WITH_POLICE = [
  COUNTRY_CODES.spain,
  COUNTRY_CODES.italy,
  COUNTRY_CODES.portugal,
  COUNTRY_CODES.dubai,
  COUNTRY_CODES.hungary,
  COUNTRY_CODES.czech,
  COUNTRY_CODES.colombia,
  COUNTRY_CODES.slovenia,
  COUNTRY_CODES.thailand,
  COUNTRY_CODES.austria,
  COUNTRY_CODES.croatia,
];
const COUNTRIES_WITH_POLICE_ALLOWED_SEND_AFTER_1_DAY = [
  COUNTRY_CODES.spain,
  COUNTRY_CODES.dubai,
  COUNTRY_CODES.czech,
  COUNTRY_CODES.colombia,
];
const COUNTRIES_WITH_POLICE_NOT_ALLOWED_SEND_ONE_GUEST = [
  COUNTRY_CODES.italy,
  COUNTRY_CODES.dubai,
];
const COUNTRIES_WITH_CONTRACTS = [COUNTRY_CODES.spain];
const POLICE_TYPES_OF_DOCUMENT = {
  [COUNTRY_CODES.colombia]: [
    {value: 'CO_ID', label: i18n.t('col_colombian_id')},
    {value: 'CO_FID', label: i18n.t('col_residence_permit')},
  ],
};
const VALIDATION_STATUSES = {
  inProgress: 'IN_PROGRESS',
  complete: 'COMPLETE',
  error: 'ERROR',
};
const STAT_TYPES = {
  lazioRadar: 'ITRA',
  campania: 'ITCA',
  siciliaOCR: 'ITSI',
  toscanaRicestat: 'ITTO',
  toscanaTuristat: 'ITT3',
  valtellinaAbit: 'ITVA',
  venetto: 'ITVE',
  fruiliVenezia: 'ITFV',
  abruzzo: 'ITAB',
  emiliaRomania: 'ITER',
  lombardia: 'ITLO',
  marche: 'ITMA',
  piemonte: 'ITPI',
  sardegna: 'ITSA',
};
const STAT_TYPES_WITHOUT_OCCUPIED_ROOMS = [
  STAT_TYPES.campania,
  STAT_TYPES.siciliaOCR,
  STAT_TYPES.toscanaRicestat,
  STAT_TYPES.fruiliVenezia,
  STAT_TYPES.valtellinaAbit,
  STAT_TYPES.venetto,
];
const STAT_TYPES_WITHOUT_CHECK_OUT = [
  STAT_TYPES.abruzzo,
  STAT_TYPES.emiliaRomania,
  STAT_TYPES.fruiliVenezia,
  STAT_TYPES.lombardia,
  STAT_TYPES.marche,
  STAT_TYPES.piemonte,
  STAT_TYPES.sardegna,
  STAT_TYPES.toscanaRicestat,
  STAT_TYPES.toscanaTuristat,
];
const POLICE_LIKE_STAT_TYPES = [
  STAT_TYPES.abruzzo,
  STAT_TYPES.emiliaRomania,
  STAT_TYPES.fruiliVenezia,
  STAT_TYPES.lombardia,
  STAT_TYPES.marche,
  STAT_TYPES.piemonte,
  STAT_TYPES.sardegna,
  STAT_TYPES.toscanaRicestat,
  STAT_TYPES.toscanaTuristat,
];

const CONTRACT_TYPES = {
  hotel: 'HOTEL',
  apartment: 'APARTMENT',
  housing: 'HOUSING',
};
const CONTRACT_TYPES_OPTIONS = {
  [CONTRACT_TYPES.hotel]: {
    label: i18n.t('contract_types.hotel'),
    value: 'HOTEL',
  },
  [CONTRACT_TYPES.apartment]: {
    label: i18n.t('contract_types.apartment'),
    value: 'APARTMENT',
  },
  [CONTRACT_TYPES.housing]: {
    label: i18n.t('contract_types.housing'),
    value: 'HOUSING',
  },
};
const DIVISION_LVL_1_COUNTRIES = [
  COUNTRY_CODES.dubai,
  COUNTRY_CODES.portugal,
  COUNTRY_CODES.romania,
  COUNTRY_CODES.hungary,
];
const EXTERNAL_SOURCES_TYPES = {
  airBnb: 'AIRBNB_CALENDAR',
  booking: 'BOOKING_CALENDAR',
  other: 'GENERIC_CALENDAR',
};
const REMINDER_OPTIONS = {
  is_chekin_online_email_after_reservation_created_enabled:
    'is_chekin_online_email_after_reservation_created_enabled',
  is_chekin_online_email_one_week_before_check_in_enabled:
    'is_chekin_online_email_one_week_before_check_in_enabled',
  is_chekin_online_email_72_hours_before_check_in_enabled:
    'is_chekin_online_email_72_hours_before_check_in_enabled',
  is_chekin_online_email_48_hours_before_check_in_enabled:
    'is_chekin_online_email_48_hours_before_check_in_enabled',
  is_chekin_online_email_24_hours_before_check_in_enabled:
    'is_chekin_online_email_24_hours_before_check_in_enabled',
};
const ONLINE_CHECK_IN_SELECTORS = [
  {
    label: i18n.t('when_reservation_created'),
    name: REMINDER_OPTIONS.is_chekin_online_email_after_reservation_created_enabled,
  },
  {
    label: i18n.t('one_week_before'),
    name: REMINDER_OPTIONS.is_chekin_online_email_one_week_before_check_in_enabled,
  },
  {
    label: i18n.t('72_hours_ago'),
    name: REMINDER_OPTIONS.is_chekin_online_email_72_hours_before_check_in_enabled,
  },
  {
    label: i18n.t('48_hours_ago'),
    name: REMINDER_OPTIONS.is_chekin_online_email_48_hours_before_check_in_enabled,
  },
  {
    label: i18n.t('24_hours_ago'),
    name: REMINDER_OPTIONS.is_chekin_online_email_24_hours_before_check_in_enabled,
  },
];

const LOCK_REMINDER_OPTIONS = {
  is_smart_lock_email_after_all_guests_passed_biomatch_enabled:
    'is_smart_lock_email_after_all_guests_passed_biomatch_enabled',
  is_smart_lock_email_after_lead_guest_passed_biomatch_enabled:
    'is_smart_lock_email_after_lead_guest_passed_biomatch_enabled',
  is_smart_lock_email_after_reservation_created_enabled:
    'is_smart_lock_email_after_reservation_created_enabled',
  is_smart_lock_email_one_week_before_check_in_enabled:
    'is_smart_lock_email_one_week_before_check_in_enabled',
  is_smart_lock_email_72_hours_before_check_in_enabled:
    'is_smart_lock_email_72_hours_before_check_in_enabled',
  is_smart_lock_email_48_hours_before_check_in_enabled:
    'is_smart_lock_email_48_hours_before_check_in_enabled',
  is_smart_lock_email_24_hours_before_check_in_enabled:
    'is_smart_lock_email_24_hours_before_check_in_enabled',
};
const LOCK_SELECTORS = [
  {
    label: i18n.t('all_guest_identification_completed'),
    name:
      LOCK_REMINDER_OPTIONS.is_smart_lock_email_after_all_guests_passed_biomatch_enabled,
  },
  {
    label: i18n.t('lead_guest_identification_completed'),
    name:
      LOCK_REMINDER_OPTIONS.is_smart_lock_email_after_lead_guest_passed_biomatch_enabled,
  },
  {
    label: i18n.t('when_reservation_created'),
    name: LOCK_REMINDER_OPTIONS.is_smart_lock_email_after_reservation_created_enabled,
  },
  {
    label: i18n.t('one_week_before'),
    name: LOCK_REMINDER_OPTIONS.is_smart_lock_email_one_week_before_check_in_enabled,
  },
  {
    label: i18n.t('72_hours_ago'),
    name: LOCK_REMINDER_OPTIONS.is_smart_lock_email_72_hours_before_check_in_enabled,
  },
  {
    label: i18n.t('48_hours_ago'),
    name: LOCK_REMINDER_OPTIONS.is_smart_lock_email_48_hours_before_check_in_enabled,
  },
  {
    label: i18n.t('24_hours_ago'),
    name: LOCK_REMINDER_OPTIONS.is_smart_lock_email_24_hours_before_check_in_enabled,
  },
];

const WS_EVENT_TYPES = {
  reservationCreated: 'RESERVATION_CREATED',
  reservationRemoved: 'RESERVATION_REMOVED',
  housingCreated: 'HOUSING_CREATED',
  housingRemoved: 'HOUSING_REMOVED',
  syncReservationsFinished: 'SYNC_RESERVATIONS_FINISHED',
  syncHousingsFinished: 'SYNC_HOUSINGS_FINISHED',
  syncReservationsStarted: 'SYNC_RESERVATIONS_STARTED',
  syncHousingsStarted: 'SYNC_HOUSINGS_STARTED',
  contractSampleCreationStarted: 'CONTRACT_SAMPLE_CREATION_STARTED',
  contractSampleCreationFinished: 'CONTRACT_SAMPLE_CREATION_FINISHED',
  contractSampleCreationFailed: 'CONTRACT_SAMPLE_CREATION_FAILED',
  contractsArchiveGenerationStarted: 'CONTRACTS_ARCHIVE_GENERATION_STARTED',
  contractsArchiveGenerationFinished: 'CONTRACTS_ARCHIVE_GENERATION_FINISHED',
  contractsArchiveGenerationFailed: 'CONTRACTS_ARCHIVE_GENERATION_FAILED',
  subscriptionUpdated: 'SUBSCRIPTION_UPDATED',
  userUpdated: 'USER_UPDATED',
  importXLSXFinished: 'IMPORT_XLSX_FINISHED',
  guestReportGenerationStarted: 'GUEST_REPORT_EXCEL_GENERATION_STARTED',
  guestReportGenerationFinished: 'GUEST_REPORT_EXCEL_GENERATION_FINISHED',
  guestReportGenerationFailed: 'GUEST_REPORT_EXCEL_GENERATION_FAILED',
  entryFormsArchiveGenerationStarted: 'ENTRY_FORM_ARCHIVE_GENERATION_STARTED',
  entryFormsArchiveGenerationFinished: 'ENTRY_FORM_ARCHIVE_GENERATION_FINISHED',
  entryFormsArchiveGenerationFailed: 'ENTRY_FORM_ARCHIVE_GENERATION_FAILED',
  guestbookGenerationStarted: 'GUEST_BOOK_GENERATION_STARTED',
  guestbookGenerationFinished: 'GUEST_BOOK_GENERATION_FINISHED',
  guestbookGenerationFailed: 'GUEST_BOOK_GENERATION_FAILED',
  writeOffCompleted: 'WRITE_OFF_COMPLETED',
  writeOffFailed: 'WRITE_OFF_FAILED',
  guestPaymentCompleted: 'GUEST_PAYMENT_COMPLETED',
  guestPaymentFailed: 'GUEST_PAYMENT_FAILED',
  reservationReportExcelStarted: 'RESERVATION_REPORT_EXCEL_STARTED',
  reservationReportExcelFinished: 'RESERVATION_REPORT_EXCEL_FINISHED',
  reservationReportExcelFailed: 'RESERVATION_REPORT_EXCEL_FAILED',
};

const STAT_TYPES_WITH_TAX_EXEMPTIONS = [STAT_TYPES.valtellinaAbit];
const SINGLE_GROUP_TYPE = 'S';
const GROUP_GROUP_TYPE = 'G';
const FAMILY_GROUP_TYPE = 'F';
const TOURIST_GROUP_GROUP_TYPE = 'T';
const SINGLE_GROUP_TYPES_OPTIONS = [
  {
    value: SINGLE_GROUP_TYPE,
    label: i18n.t('single'),
  },
];
const GROUP_GROUP_TYPES_OPTIONS = [
  {
    value: GROUP_GROUP_TYPE,
    label: i18n.t('group'),
  },
  {
    value: FAMILY_GROUP_TYPE,
    label: i18n.t('family'),
  },
];
const ALL_GROUP_TYPES_OPTIONS = [
  ...SINGLE_GROUP_TYPES_OPTIONS,
  ...GROUP_GROUP_TYPES_OPTIONS,
];
const TOURIST_GROUP_GROUP_TYPE_OPTION = {
  value: TOURIST_GROUP_GROUP_TYPE,
  label: i18n.t('tourist_group'),
};
const RESPONSIBLE_ME_OPTION = {
  value: 'ME',
  label: i18n.t('me'),
};
const GENDERS_OPTIONS = [
  {
    value: 'M',
    label: i18n.t('male'),
  },
  {
    value: 'F',
    label: i18n.t('female'),
  },
];
const EU_MEMBERS = [
  'BE',
  'EL',
  'LT',
  'PT',
  'BG',
  'ES',
  'LU',
  'RO',
  'CZ',
  'FR',
  'HU',
  'SI',
  'DK',
  'HR',
  'MT',
  'SK',
  'DE',
  'IT',
  'NL',
  'FI',
  'EE',
  'CY',
  'AT',
  'SE',
  'IE',
  'LV',
  'PL',
];
const COUNTRIES_WITH_PURPOSES_OF_STAY = [COUNTRY_CODES.romania];
const MAX_NUMBER_OF_GUESTS = 20;
const CARD_BRANDS = {
  visa: 'V',
};
const GUEST_PLACEHOLDER_ID = 'new';

const COLLABORATOR_GROUPS = {
  manager: 'object admin',
  collaborator: 'reservations assigned only',
};

const USER_ORIGINS = {
  unknown: 'UNKNOWN',
  checkinOnline: 'CH_ONLINE',
  default: 'DEFAULT',
  dashboard: 'DASHBOARD',
  dashboard_pms: 'DASHBOARD_PMS',
  vreasy: 'VREASY',
  octorate: 'OCTORATE',
  lodgify: 'LODGIFY',
  rentals_united: 'RENTALS_UNITED',
  legacy: 'LEGACY',
  booking: 'BOOKING',
  bookingsync: 'BOOKINGSYNC',
  hostaway: 'HOSTAWAY',
  planyo: 'PLANYO',
  mews: 'MEWS',
  guesty: 'GUESTY',
  ofi: 'OFI',
  cloudbeds: 'CLOUDBEDS',
};

const ORIGINS_LABELS = {
  [USER_ORIGINS.guesty]: 'Guesty',
  [USER_ORIGINS.lodgify]: 'Lodgify',
  [USER_ORIGINS.octorate]: 'Octorate',
  [USER_ORIGINS.rentals_united]: 'Rentals United',
  [USER_ORIGINS.booking]: 'Booking',
  [USER_ORIGINS.bookingsync]: 'BookingSync',
  [USER_ORIGINS.hostaway]: 'Hostaway',
  [USER_ORIGINS.mews]: 'Mews',
  [USER_ORIGINS.ofi]: 'OFI',
  [USER_ORIGINS.dashboard_pms]: 'Dashboard PMS',
  [USER_ORIGINS.dashboard]: 'Dashboard',
  [USER_ORIGINS.default]: 'Default',
};

const ORIGINS_WITH_POSSIBLE_MAPPING = [
  USER_ORIGINS.mews,
  USER_ORIGINS.octorate,
  USER_ORIGINS.cloudbeds,
];

const ORIGINS_WITH_SUBSCRIPTION = [
  USER_ORIGINS.dashboard,
  USER_ORIGINS.dashboard_pms,
  USER_ORIGINS.octorate,
  USER_ORIGINS.vreasy,
  USER_ORIGINS.lodgify,
  USER_ORIGINS.rentals_united,
  USER_ORIGINS.legacy,
  USER_ORIGINS.booking,
  USER_ORIGINS.planyo,
  USER_ORIGINS.hostaway,
  USER_ORIGINS.bookingsync,
];

const PERIODICITY = {
  yearly: 'YEAR',
  monthly: 'MONTH',
};

const SUBSCRIPTION_TYPES = {
  housing: 'HOU',
  hotel: 'HOT',
};

enum SUBSCRIPTION_INTERVALS {
  month = 'MONTH',
  year = 'YEAR',
}

const SUBSCRIPTION_INTERVALS_OPTIONS = {
  [SUBSCRIPTION_INTERVALS.month]: {
    value: SUBSCRIPTION_INTERVALS.month,
    label: i18n.t('monthly'),
  } as SelectOption,
  [SUBSCRIPTION_INTERVALS.year]: {
    value: SUBSCRIPTION_INTERVALS.year,
    label: i18n.t('yearly'),
  } as SelectOption,
};

const STATUS_CODES = {
  complete: 'COMPLETE',
  new: 'NEW',
  error: 'ERROR',
};

const DEFAULT_OCCUPIED_ROOMS_NUMBER = 1;

const ACCOMMODATION_TYPES = {
  house: 'HOU',
  hotel: 'HOT',
  api: 'API',
};

const AJPES_MONTHLY_REPORT_STATUSES_OPTION = [
  {
    value: 11,
    label: i18n.t('open_tourists_are_coming'),
  },
  {
    value: 12,
    label: i18n.t('open_tourists_were_no_tourists'),
  },
  {
    value: 50,
    label: i18n.t('temporarily_closed_other_reasons'),
  },
  {
    value: 51,
    label: i18n.t('temporarily_closed_adaption'),
  },
  {
    value: 54,
    label: i18n.t('temporarily_closed_seasonal_closure'),
  },
  {
    value: 61,
    label: i18n.t('termination_of_rental_of_accommodation_capacities_to_tourists'),
  },
  {
    value: 62,
    label: i18n.t('temporarily_accommodation_сapacities_under_preparation'),
  },
];

const TOAST_AUTO_CLOSE_MS = 4000;
const MIN_MEMBERS_NUMBER = 1;
enum REPORT_TYPES {
  idev = 'de-idev',
  ajpes = 'sl-ajpes',
}

const HOUSING = 'housing';
const RESERVATION = 'reservation';

const SUBSCRIPTION_PRODUCT_TYPES = {
  chekin: 'CHEKIN',
  selfCheckin: 'SELF_CHECKIN',
};

enum LOCK_VENDORS {
  omnitec = 'o',
  keynest = 'k',
  keyless = 'l',
  akiles = 'a',
  keycafe = 'c',
  nuki = 'n', // I did it all for the NUKI
  homeit = 'h',
  manualBox = 'm',
}

const LOCK_VENDOR_OPTIONS = {
  [LOCK_VENDORS.omnitec]: {
    label: 'Omnitec',
    value: LOCK_VENDORS.omnitec,
  },
  [LOCK_VENDORS.keynest]: {
    label: 'KeyNest',
    value: LOCK_VENDORS.keynest,
  },
  /*[LOCK_VENDORS.keyless]: {
    label: 'KeyLess',
    value: LOCK_VENDORS.keyless,
  },*/
  [LOCK_VENDORS.akiles]: {
    label: 'Akiles',
    value: LOCK_VENDORS.akiles,
  },
  [LOCK_VENDORS.keycafe]: {
    label: 'Keycafe',
    value: LOCK_VENDORS.keycafe,
  },
  [LOCK_VENDORS.nuki]: {
    label: 'Nuki',
    value: LOCK_VENDORS.nuki,
  },
  [LOCK_VENDORS.homeit]: {
    label: 'HomeIT',
    value: LOCK_VENDORS.homeit,
  },
};

const LOCK_ACCOUNT_NAMES_OPTIONS = {
  ...LOCK_VENDOR_OPTIONS,
  [LOCK_VENDORS.manualBox]: {
    label: i18n.t('manual_box'),
    value: LOCK_VENDORS.manualBox,
  },
  [LOCK_VENDORS.keynest]: {
    label: 'KeyNest',
    value: LOCK_VENDORS.keynest,
  },
  [LOCK_VENDORS.keyless]: {
    label: 'KeyLess',
    value: LOCK_VENDORS.keyless,
  },
};

enum LOCK_TYPES {
  buildingDoor = 'B',
  apartmentDoor = 'A',
  smartBox = 'S',
}

const LOCK_TYPES_OPTIONS = {
  [LOCK_TYPES.buildingDoor]: {
    label: i18n.t('building_door'),
    value: LOCK_TYPES.buildingDoor,
  },
  [LOCK_TYPES.apartmentDoor]: {
    label: i18n.t('apartment_door'),
    value: LOCK_TYPES.apartmentDoor,
  },
  [LOCK_TYPES.smartBox]: {
    label: i18n.t('smart_box'),
    value: LOCK_TYPES.smartBox,
  },
};

enum LOCK_ACCESS_TYPES {
  private = 'PRIVATE',
  common = 'COMMON',
}

enum SUBSCRIPTION_TRIAL_TYPES {
  stripe = '0',
  custom = '1',
}

const MARKETPLACE_TYPES = {
  access_provider: i18n.t('access_provider'),
  integration: 'integration',
};

const MARKETPLACE_STATUSES = {
  connected: 'connected',
  unconnected: 'unconnected',
  coming: 'coming',
};

const COUNTRIES_WITH_TAXES_AGE_RULES_CALC = [
  COUNTRY_CODES.slovenia,
  COUNTRY_CODES.croatia,
  COUNTRY_CODES.germany,
  COUNTRY_CODES.belgium,
  COUNTRY_CODES.portugal,
];

enum CREDITOR_TYPES {
  individual = 'INDIVIDUAL',
  freelance = 'FREELANCE',
  company = 'COMPANY',
}

const CREDITOR_TYPES_OPTIONS = {
  [CREDITOR_TYPES.individual]: {
    value: CREDITOR_TYPES.individual,
    label: i18n.t('individual'),
  },
  [CREDITOR_TYPES.freelance]: {
    value: CREDITOR_TYPES.freelance,
    label: i18n.t('freelance'),
  },
  [CREDITOR_TYPES.company]: {
    value: CREDITOR_TYPES.company,
    label: i18n.t('company'),
  },
};

enum SECURITY_DEPOSIT_STATUSES {
  inactive = 'INACTIVE',
  optional = 'OPTIONAL',
  mandatory = 'MANDATORY',
}

const ZERO_DEPOSIT = '0.00';

const LANGUAGE_OPTIONS = [
  {
    value: LANGUAGES_ISO_3.english,
    label: i18n.t('english'),
  },
  {
    value: LANGUAGES_ISO_3.spanish,
    label: i18n.t('spanish'),
  },
  {
    value: LANGUAGES_ISO_3.italian,
    label: i18n.t('italian'),
  },
  {
    value: LANGUAGES_ISO_3.french,
    label: i18n.t('french'),
  },
  {
    value: LANGUAGES_ISO_3.russian,
    label: i18n.t('russian'),
  },
  {
    value: LANGUAGES_ISO_3.portuguese,
    label: i18n.t('portuguese'),
  },
  {
    value: LANGUAGES_ISO_3.german,
    label: i18n.t('german'),
  },
  {
    value: LANGUAGES_ISO_3.hungarian,
    label: i18n.t('hungarian'),
  },
  {
    value: LANGUAGES_ISO_3.czech,
    label: i18n.t('czech'),
  },
];

const MAX_DEPOSIT_AMOUNT = 1000;

export {
  MAX_DEPOSIT_AMOUNT,
  ZERO_DEPOSIT,
  CREDITOR_TYPES,
  CREDITOR_TYPES_OPTIONS,
  COUNTRIES_WITH_TAXES_AGE_RULES_CALC,
  TOAST_AUTO_CLOSE_MS,
  SUBSCRIPTION_TRIAL_TYPES,
  SUBSCRIPTION_PRODUCT_TYPES,
  ACCOMMODATION_TYPES,
  DEFAULT_OCCUPIED_ROOMS_NUMBER,
  STATUS_CODES,
  COLLABORATOR_GROUPS,
  GUEST_PLACEHOLDER_ID,
  CARD_BRANDS,
  MAX_NUMBER_OF_GUESTS,
  COUNTRIES_WITH_PURPOSES_OF_STAY,
  GENDERS_OPTIONS,
  EU_MEMBERS,
  STAT_TYPES_WITH_TAX_EXEMPTIONS,
  ONLINE_CHECK_IN_SELECTORS,
  RESPONSIBLE_ME_OPTION,
  ALL_GROUP_TYPES_OPTIONS,
  GROUP_GROUP_TYPES_OPTIONS,
  SINGLE_GROUP_TYPES_OPTIONS,
  SINGLE_GROUP_TYPE,
  GROUP_GROUP_TYPE,
  FAMILY_GROUP_TYPE,
  TOURIST_GROUP_GROUP_TYPE,
  EXTERNAL_SOURCES_TYPES,
  DIVISION_LVL_1_COUNTRIES,
  STAT_TYPES,
  COUNTRIES_WITH_STAT,
  COUNTRIES_WITH_POLICE,
  COUNTRIES_WITH_POLICE_ALLOWED_SEND_AFTER_1_DAY,
  VALIDATION_STATUSES,
  POLICE_TYPES,
  COUNTRY_CODES,
  PATTERNS,
  POLICE_TYPES_OF_DOCUMENT,
  CONTRACT_TYPES,
  CONTRACT_TYPES_OPTIONS,
  COUNTRIES_WITH_CONTRACTS,
  REMINDER_OPTIONS,
  WS_EVENT_TYPES,
  USER_ORIGINS,
  ORIGINS_WITH_SUBSCRIPTION,
  PERIODICITY,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_INTERVALS_OPTIONS,
  POLICE_CODES,
  STAT_TYPES_WITHOUT_OCCUPIED_ROOMS,
  STAT_TYPES_WITHOUT_CHECK_OUT,
  POLICE_LIKE_STAT_TYPES,
  ORIGINS_LABELS,
  ORIGINS_WITH_POSSIBLE_MAPPING,
  LANGUAGES_ISO_3,
  TOURIST_GROUP_GROUP_TYPE_OPTION,
  HOUSING,
  RESERVATION,
  MIN_MEMBERS_NUMBER,
  REPORT_TYPES,
  COUNTRIES_WITH_POLICE_NOT_ALLOWED_SEND_ONE_GUEST,
  AJPES_MONTHLY_REPORT_STATUSES_OPTION,
  MARKETPLACE_TYPES,
  MARKETPLACE_STATUSES,
  LOCK_VENDOR_OPTIONS,
  LOCK_VENDORS,
  LOCK_TYPES_OPTIONS,
  LOCK_TYPES,
  SUBSCRIPTION_INTERVALS,
  LOCK_REMINDER_OPTIONS,
  LOCK_SELECTORS,
  LOCK_ACCOUNT_NAMES_OPTIONS,
  SECURITY_DEPOSIT_STATUSES,
  LANGUAGE_OPTIONS,
  LOCK_ACCESS_TYPES,
};
