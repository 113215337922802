import {resolver} from './index';

const ENDPOINTS = {
  all: (params = '') => `housings/?${params}`,
  one: (id = '', params = '') => `housings/${id}/?${params}`,
  sendSync: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/users/${userId}/sync-user-housings/`,
  syncTasks: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/housings-syncronize-tasks/?user_id=${userId}`,
  mapTask: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/users/${userId}/send-map-housings-tasks/`,
  unmapped: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/housings/unmapped/?user_id=${userId}`,
  connectAutomatically: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/users/${userId}/send-connect-housings-automatically-task/`,
  contracts: () => `contract-sample-generation/`,
  ajpesMonthlyReport: () => `ajpes-monthly-report/report/`,
  deactivateSelfCheckin: () => `housings/deactivate-self-online-check-in/`,
};

function get(params = '') {
  return resolver(ENDPOINTS.all(params));
}

function getOne(id: string, params = '') {
  return resolver(ENDPOINTS.one(id, params));
}

function deleteById(id: string) {
  return resolver(ENDPOINTS.one(id), {
    method: 'DELETE',
  });
}

function post(payload: any = {}) {
  return resolver(ENDPOINTS.all(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function createContractSample(payload: any = {}) {
  return resolver(ENDPOINTS.contracts(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function patchById(id: string, payload: any = {}) {
  return resolver(ENDPOINTS.one(id), {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

function sendSyncTask(integrationUrl = '', userId = '') {
  return resolver(ENDPOINTS.sendSync(integrationUrl, userId), {
    method: 'POST',
    body: JSON.stringify({}),
  });
}

function getSyncTasks(integrationUrl = '', userId = '') {
  return resolver(ENDPOINTS.syncTasks(integrationUrl, userId));
}

function sendMapTask(integrationUrl = '', userId = '', data = {}) {
  return resolver(ENDPOINTS.mapTask(integrationUrl, userId), {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

function sendConnectHousingsAutomaticallyTask(
  integrationUrl = '',
  userId = '',
  data = {},
) {
  return resolver(ENDPOINTS.connectAutomatically(integrationUrl, userId), {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

function getUnmapped(integrationUrl = '', userId = '') {
  return resolver(ENDPOINTS.unmapped(integrationUrl, userId));
}

function sendAjpesMonthlyReportTask(payload: any = {}) {
  return resolver(ENDPOINTS.ajpesMonthlyReport(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function deactivateSelfCheckin() {
  return resolver(ENDPOINTS.deactivateSelfCheckin(), {
    method: 'POST',
  });
}

export {
  ENDPOINTS,
  get,
  post,
  getOne,
  deleteById,
  patchById,
  createContractSample,
  sendSyncTask,
  getSyncTasks,
  getUnmapped,
  sendMapTask,
  sendConnectHousingsAutomaticallyTask,
  sendAjpesMonthlyReportTask,
  deactivateSelfCheckin,
};
