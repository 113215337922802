import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactSelect from 'react-select';
import displayArrowIcon from '../../../assets/display-icn.svg';
import {SelectOption} from '../../../utils/types';
import {Wrapper, DisplayIcon} from './styled';

type HeadingSelectProps = {
  onChange?: (option: any, info: any) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  options?: SelectOption[];
  label?: string;
  value?: SelectOption | null;
  name?: string;
  placeholder?: string;
  className?: string;
  isSearchable?: boolean;
};

const defaultProps: HeadingSelectProps = {
  onChange: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
  options: [],
  value: null,
  label: '',
  name: '',
  placeholder: '- -',
  className: undefined,
  isSearchable: false,
};

function HeadingSelect({
  onChange,
  options,
  value,
  label,
  name,
  placeholder,
  className,
  onMenuOpen,
  onMenuClose,
  isSearchable,
  ...props
}: HeadingSelectProps) {
  const {t} = useTranslation();
  const ref = React.useRef<any>();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuOpen = React.useCallback(() => {
    setIsMenuOpen(true);
    onMenuOpen!();
  }, [onMenuOpen]);

  const handleMenuClose = React.useCallback(() => {
    setIsMenuOpen(false);
    onMenuClose!();
  }, [onMenuClose]);

  return (
    <Wrapper menuOpen={isMenuOpen} className={className}>
      <DisplayIcon alt="display" src={displayArrowIcon} shouldRotate={isMenuOpen} />
      <ReactSelect
        ref={ref}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        noOptionsMessage={() => t('no_options')}
        className="select"
        classNamePrefix="select"
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        aria-label={name}
        name={name}
        isSearchable={isSearchable}
        {...props}
      />
    </Wrapper>
  );
}

HeadingSelect.defaultProps = defaultProps;
export {HeadingSelect};
