import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {HOUSING_STATUSES} from './HousingsTable';
import TableLoader from '../TableLoader';

export const Content = styled.div`
  max-width: 1040px;
  padding: 0 20px;
  margin: 20px auto 76px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin: 20px auto 0;
  box-shadow: 0 5px 5px #2699fb1a;
  padding-bottom: 28px;

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    & > thead {
      cursor: default;
      background-color: #cbe9ff;
      text-transform: uppercase;
      font-family: ProximaNova-Bold, sans-serif;
      font-size: 10px;
      color: #161643;

      & > tr {
        height: 60px;
        text-align: left;

        & th:nth-child(1) {
          width: 10%;
          min-width: 60px;
          padding-left: 14px;
        }

        & th:nth-child(3) {
          width: 55%;
          min-width: 60px;
        }
      }
    }

    & > tbody > tr {
      font-family: ProximaNova-Semibold, sans-serif;
      font-size: 14px;
      color: #161643;
      height: 60px;
      border-bottom: 1px solid #f0f0f3;
      cursor: pointer;
      transition: background-color 0.07s ease-in-out;

      &:hover {
        background-color: #f7fbff;
      }

      & td:nth-child(1) {
        font-family: ProximaNova-Bold, sans-serif;
        padding-left: 14px;
      }
    }
  }
`;

export const StatusButtonCheckMarkIcon = styled.img`
  margin-left: 13px;
  vertical-align: middle;
  width: 12px;
  margin-top: -2px;
  margin-right: -1px;
`;

export const StatusButtonClockIcon = styled.img`
  margin-left: 4px;
  vertical-align: middle;
  height: 11px;
  margin-top: -3px;
`;

type StatusButtonProps = {
  status: string;
};

export const HeadingImportButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0 16px;
  height: 20px;
  border-right: 1px solid #d8d9e6;
  transition: opacity 0.15s ease-in-out;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => props.disabled && 0.5};

  & > img {
    vertical-align: middle;
    height: 20px;
    width: 20px;
  }

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        & > img {
          opacity: 0.9;
        }
      }

      &:active {
        & > img {
          opacity: 0.8;
        }
      }
    `}
`;

export const TableAddButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  height: 20px;
  cursor: pointer;
  transition: box-shadow 0.15s ease-in-out;
  border-radius: 3px;
  display: block;

  &:hover {
    box-shadow: 0 3px 3px #0f477734;
  }

  &:active {
    opacity: 0.95;
  }

  & > img {
    height: 20px;
    width: 20px;
  }
`;

export const HeadingAddButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  height: 30px;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  padding: 1px 0 0 16px;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  opacity: ${(props) => props.disabled && 0.5};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover > img {
        box-shadow: 0 3px 3px #0f477734;
      }

      &:active > img {
        opacity: 0.95;
      }
    `}

  & > img {
    border-radius: 3px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    transition: box-shadow 0.15s ease-in-out;
  }
`;

export const HeadingRefreshButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  height: 20px;
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  padding: 1px 0 0 16px;
  display: inline-flex;
  vertical-align: middle;
  width: 95px;

  &:hover > img {
    box-shadow: 0 3px 3px #0f477734;
  }

  &:active > img {
    opacity: 0.95;
  }

  & > img {
    border-radius: 3px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    transition: box-shadow 0.15s ease-in-out;
  }
`;

export const StatusButton = styled.button<StatusButtonProps>`
  border-radius: 2px;
  padding: 3px 6px;
  box-sizing: border-box;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 14px;
  border: 1px solid;
  text-transform: capitalize;
  outline: none;
  ${(props) => getStatusButtonStyle(props)}
`;

export const PropertyTableHeader = styled.div`
  display: inline-flex;
  align-items: center;
`;

type InactiveRowProps = {
  hasBorder?: boolean;
};

export const InactiveRow = styled.tr<InactiveRowProps>`
  &&& {
    cursor: default;
    user-select: none;
    border: ${(props) => !props.hasBorder && 'none'};

    &:hover {
      background-color: white;
    }
  }
`;

function getStatusButtonStyle({status}: StatusButtonProps) {
  switch (status) {
    case HOUSING_STATUSES.complete: {
      return css`
        color: #161643;
        border-color: #71c3ff;
        background-color: #cbe9ff;
      `;
    }
    case HOUSING_STATUSES.incomplete:
    default: {
      return css`
        color: #adadcc;
        border-color: #9696b9;
        background-color: #f8f8f8;
      `;
    }
  }
}

type StatusTooltipProps = {
  open: boolean;
};

export const StatusTooltip = styled.div<StatusTooltipProps>`
  position: absolute;
  width: 220px;
  text-align: center;
  padding: 21px 0 28px;
  box-shadow: 0 10px 10px #2699fb1a;
  background-color: white;
  border-radius: 6px;
  font-size: 16px;
  font-family: ProximaNova-Medium, sans-serif;
  z-index: 999;
  color: #161643;
  left: calc(100% + 5px);
  top: 0;
  cursor: default;
  transition: opacity 0.15s ease-in-out;
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => !props.open && 'none'};
`;

export const StatusCell = styled.div`
  position: relative;
  cursor: pointer;

  & > div {
    display: inline-block;
    position: relative;
  }
`;

export const StatusIcon = styled.img`
  width: 26px;
  height: 34px;
  margin-bottom: 7px;
  user-select: none;
`;

export const StatusText = styled.div`
  font-size: 16px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #161643;
`;

export const HousingsTableLoader = styled(TableLoader)`
  padding-bottom: 20px;
`;

export const MappingNotification = styled.div`
  z-index: 10;
  position: fixed;
  right: 24px;
  bottom: 200px;
  width: 257px;
  padding: 12px 9px 14px 18px;
  box-shadow: 0 10px 10px #2699fb1a;
  border-radius: 3px;
  text-align: center;
  background-color: white;
`;

export const MappingNotificationText = styled.div`
  font-size: 13px;
  font-family: ProximaNova-Light, sans-serif;
  color: #161643;
  margin-bottom: 20px;
  text-align: left;
`;

export const MappingNotificationLink = styled(Link)`
  margin: 20px auto 0;
  font-size: 15px;
  font-family: ProximaNova-Medium, sans-serif;
  color: #1a8cff;
`;

export const RefreshButtonLoaderWrapper = styled.div`
  margin-left: 5px;
  width: inherit;
`;
