import styled from 'styled-components';

type LabelProps = {
  active: boolean;
};

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ToggleWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0 20px;
`;

export const Label = styled.span<LabelProps>`
  && * {
    color: ${(props) => props.active && '#FFFFFF'};
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  color: #000000cf;
  padding: 23px 0;
  text-transform: capitalize;
  user-select: none;

  &:hover {
    color: #1a8cff;
  }

  & > div {
    padding-left: 11px;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: 41px;
  right: 0;
  cursor: default;
  background-color: #ffffff;
  box-shadow: 0 30px 30px #2699fb1a;
  border-radius: 0 0 8px 8px;
  opacity: 1;
  z-index: 2;
  padding: 12px 27px 14px 16px;
  width: 157px;
  margin-bottom: 25px;

  & ${MenuItem}:not(:last-child) {
    padding-bottom: 22px;
    border-bottom: 1px solid #9696b91c;
  }
`;
