import React from 'react';
import ReactSelect, {createFilter} from 'react-select';
import displayArrowIcon from '../../../assets/display-icn.svg';
import Loader from '../../common/Loader';
import {ErrorMessage} from '../../../styled/common';
import {SelectOption} from '../../../utils/types';
import {Wrapper, DisplayIcon, Label, LoaderWrapper} from './styled';

const MIN_SEARCH_ENABLE_OPTIONS_LENGTH = 4;

type SelectProps = {
  onChange?: (option: any, info: any) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  options?: SelectOption[];
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
  value?: SelectOption | null;
  name?: string;
  placeholder?: string;
  error?: any;
  className?: string;
  isSearchable?: boolean;
  loading?: boolean;
};

const defaultProps: SelectProps = {
  onChange: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
  options: [],
  value: null,
  label: '',
  invalid: false,
  disabled: false,
  name: '',
  placeholder: '- -',
  error: '',
  className: undefined,
  isSearchable: undefined,
  loading: false,
};

function Select({
  onChange,
  options,
  value,
  label,
  invalid,
  disabled,
  name,
  placeholder,
  error,
  className,
  onMenuOpen,
  onMenuClose,
  isSearchable,
  loading,
  ...props
}: SelectProps) {
  const ref = React.useRef<any>();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const onChangeHandler = disabled ? undefined : onChange;
  const isSearchEnabled =
    isSearchable || options?.length! > MIN_SEARCH_ENABLE_OPTIONS_LENGTH;

  React.useEffect(() => {
    // Disable first menu item autofocus
    ref.current.select.getNextFocusedOption = () => null;
  }, []);

  const handleMenuOpen = React.useCallback(() => {
    setIsMenuOpen(true);
    onMenuOpen!();
  }, [onMenuOpen]);

  const handleMenuClose = React.useCallback(() => {
    setIsMenuOpen(false);
    onMenuClose!();
  }, [onMenuClose]);

  return (
    <Wrapper
      disabled={disabled}
      invalid={invalid || Boolean(error)}
      className={className}
    >
      {loading && (
        <LoaderWrapper>
          <Loader height={18} width={18} />
        </LoaderWrapper>
      )}
      {label && <Label>{label}</Label>}
      <DisplayIcon alt="display" src={displayArrowIcon} shouldRotate={isMenuOpen} />
      <ReactSelect
        ref={ref}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        className="select"
        classNamePrefix="select"
        value={value}
        onChange={onChangeHandler}
        options={options}
        placeholder={placeholder}
        aria-label={name}
        name={name}
        isDisabled={disabled}
        isSearchable={isSearchEnabled}
        filterOption={createFilter({ignoreAccents: false})}
        {...props}
      />
      {error && <ErrorMessage data-testid={`${name}-error`}>{error}</ErrorMessage>}
    </Wrapper>
  );
}

Select.defaultProps = defaultProps;
export {Select};
