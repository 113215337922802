import moment from 'moment';
import {Housing, Reservation} from './types';
import {
  MAX_NUMBER_OF_GUESTS,
  RESPONSIBLE_ME_OPTION,
  SECURITY_DEPOSIT_STATUSES,
  ZERO_DEPOSIT,
} from './constants';
import {
  FORM_NAMES,
  FormTypes,
} from '../components/dashboard/ReservationInfoSection/ReservationInfoSection';
import {
  DEFAULT_LANGUAGE_FIELD_NAME,
  FORM_NAMES as ONLINE_CHECKIN_FORM_NAMES,
  FormTypes as OnlineCheckInFormTypes,
} from '../components/dashboard/ReservationOnlineCheckInSection/ReservationOnlineCheckInSection';

function buildReservationPayload(data: FormTypes & Partial<OnlineCheckInFormTypes>) {
  const defaultPhoneNumber = data?.default_phone_number || undefined;
  const responsible =
    data[FORM_NAMES.responsible]?.value !== RESPONSIBLE_ME_OPTION.value
      ? data[FORM_NAMES.responsible]?.value
      : undefined;
  const numberOfGuests =
    data[FORM_NAMES.number_of_guests] && data[FORM_NAMES.children]
      ? Number(data[FORM_NAMES.number_of_guests]) - Number(data[FORM_NAMES.children])
      : data[FORM_NAMES.number_of_guests];
  const checkInDate = data[FORM_NAMES.check_in_date]
    ? moment(data[FORM_NAMES.check_in_date]!).format('YYYY-MM-DD')
    : undefined;
  const checkOutDate = data[FORM_NAMES.check_out_date]
    ? moment(data[FORM_NAMES.check_out_date]!).format('YYYY-MM-DD')
    : undefined;
  const numberOfNights = checkOutDate
    ? undefined
    : data[FORM_NAMES.number_of_nights] || 1;

  return {
    [FORM_NAMES.default_phone_number]: defaultPhoneNumber,
    [ONLINE_CHECKIN_FORM_NAMES.lead_guest_email]:
      data[ONLINE_CHECKIN_FORM_NAMES.lead_guest_email],
    [DEFAULT_LANGUAGE_FIELD_NAME]: data[ONLINE_CHECKIN_FORM_NAMES.language]?.value,
    [FORM_NAMES.responsible]: responsible,
    [FORM_NAMES.check_in_date]: checkInDate,
    [FORM_NAMES.check_out_date]: checkOutDate,
    [FORM_NAMES.occupied_rooms]: data[FORM_NAMES.occupied_rooms],
    [FORM_NAMES.price]: data[FORM_NAMES.price] || null,
    [FORM_NAMES.deposit]: data[FORM_NAMES.deposit] || null,
    [FORM_NAMES.guest_leader_name]: data[FORM_NAMES.guest_leader_name] || '',
    [FORM_NAMES.housing]: data[FORM_NAMES.housing]?.value,
    [FORM_NAMES.number_of_nights]: numberOfNights,
    [FORM_NAMES.external_room_id]:
      data[FORM_NAMES.external_room_id_option]?.value ||
      data[FORM_NAMES.external_room_id],
    guest_group: {
      members: [],
      [FORM_NAMES.number_of_guests]: numberOfGuests,
      [FORM_NAMES.type]: data[FORM_NAMES.type]?.value,
    },
    room_id: data[FORM_NAMES.space]?.value || null,
    [FORM_NAMES.deposit_amount]: data[FORM_NAMES.deposit_amount],
  };
}

function getGroupMembers(reservation?: Reservation) {
  return reservation?.guest_group?.members || [];
}

function getGroupLeaderId(reservation?: Reservation) {
  return reservation?.guest_group?.leader_id || '';
}

function getNumberOfGuests(reservation?: Reservation, initNumber: any = 0): number {
  if (!reservation) {
    return initNumber;
  }
  return reservation?.guest_group?.number_of_guests || initNumber;
}

function getNightsOfStay(reservation?: Reservation, initNumber: any = 0): number {
  if (!reservation) {
    return initNumber;
  }
  return reservation?.nights_of_stay || initNumber;
}

function getIsCaptureStatFieldsEnabled(housing?: Housing) {
  return Boolean(housing?.is_capture_stat_fields_enabled);
}

function getIsSelfCheckInEnabled(reservation?: Reservation) {
  if (!reservation) {
    return false;
  }
  return reservation?.housing?.is_self_online_check_in_enabled || false;
}

function getHasTaxes(reservation?: Reservation) {
  if (!reservation) {
    return false;
  }

  return Boolean(reservation?.housing?.seasons?.length);
}

function getHasSecurityDeposit(reservation?: Reservation) {
  if (!reservation) {
    return false;
  }

  return (
    reservation?.housing?.security_deposit_status !==
      SECURITY_DEPOSIT_STATUSES.inactive &&
    reservation?.security_deposit_amount !== ZERO_DEPOSIT
  );
}

function getIsContractEnabled(reservation: any = {}) {
  const isEnabled = reservation?.housing?.is_contract_enabled;
  return Boolean(isEnabled);
}

function getIsStatEnabled(housing?: Housing) {
  const isEnabled = housing?.is_stat_registration_enabled;
  return Boolean(isEnabled);
}

function getStatType(housing?: Housing): string {
  const type = housing?.stat_account?.type;

  if (!type) {
    return '';
  }
  return type;
}

function getStatTypeIfStatActive(housing?: Housing) {
  if (!getIsStatEnabled(housing)) {
    return '';
  }
  return getStatType(housing);
}

function getCalculatedNumberOfGuests(guests: number, children = 0) {
  let actualNumber = guests;

  if (guests > MAX_NUMBER_OF_GUESTS) {
    actualNumber = MAX_NUMBER_OF_GUESTS;
  }

  if (children) {
    actualNumber -= Number(children);
  }

  if (actualNumber < 0) {
    actualNumber = 0;
  }

  return actualNumber;
}

export {
  getCalculatedNumberOfGuests,
  getStatType,
  getStatTypeIfStatActive,
  getIsContractEnabled,
  getIsStatEnabled,
  getNumberOfGuests,
  getNightsOfStay,
  getIsSelfCheckInEnabled,
  buildReservationPayload,
  getIsCaptureStatFieldsEnabled,
  getGroupMembers,
  getGroupLeaderId,
  getHasTaxes,
  getHasSecurityDeposit,
};
