import React from 'react';
import {useHistory} from 'react-router-dom';
import {queryCache} from 'react-query';
import {mixpanelReset} from '../analytics/mixpanel';
import {getCurrentLocale} from '../utils/common';
import api, {
  getTokenFromLocalStorage,
  persistUserTokenToLocalStorage,
  removeUserTokenFromLocalStorage,
  ResolverTypes,
  USER_TOKEN,
} from '../api';
import type {User} from '../utils/types';
import FullPageSpinner from '../components/common/FullPageSpinner';

export type ContextProps = {
  accountDetails: User | null | undefined;
  logout: () => void;
  login: (token?: string, redirectToUrl?: string) => Promise<Partial<ResolverTypes>>;
  refreshAccount: () => Promise<object>;
  updateAccount: (data: any) => Promise<ResolverTypes>;
};

const AuthContext = React.createContext<ContextProps>({
  accountDetails: null,
  logout: () => {},
  login: () => Promise.resolve({}),
  refreshAccount: () => Promise.resolve({}),
  updateAccount: () => Promise.resolve({error: null, data: null}),
});

function patchUserCurrentLocale() {
  const payload = {
    language: getCurrentLocale(),
  };

  api.users.patchMe(payload);
}

function getUserTokenFromUrl() {
  const url = new URL(window.location.href);
  return url.searchParams.get(USER_TOKEN);
}

function getAndPersistUserTokenFromUrlIfExists() {
  const userToken = getUserTokenFromUrl();

  if (userToken) {
    persistUserTokenToLocalStorage(userToken);
    window.history.replaceState(null, '', window.location.pathname);
  }
  return userToken;
}

function AuthProvider(props: any) {
  const [accountDetails, setAccountDetails] = React.useState<User | null | undefined>(
    null,
  );
  const [isPending, setIsPending] = React.useState(true);
  const [persistedToken] = React.useState(() => {
    return getTokenFromLocalStorage();
  });
  const history = useHistory();

  const logout = React.useCallback(() => {
    removeUserTokenFromLocalStorage();
    setIsPending(false);
    setAccountDetails(null);
    sessionStorage.clear();
    queryCache.clear();
    mixpanelReset();
  }, []);

  const fetchAndSetAccountDetails = React.useCallback(async () => {
    const {data, error} = await api.users.getMe();

    if (data) {
      patchUserCurrentLocale();

      setAccountDetails(data);
      setIsPending(false);
    }
    if (error) {
      logout();
    }

    return {data, error};
  }, [setAccountDetails, setIsPending, logout]);

  const login = React.useCallback(
    async (token?: string, redirectToUrl?: string) => {
      if (!token) {
        return;
      }

      persistUserTokenToLocalStorage(token);
      setIsPending(true);
      const result = await fetchAndSetAccountDetails();
      setIsPending(false);

      if (!result.error && redirectToUrl) {
        history.push(redirectToUrl);
      }

      return result;
    },
    [fetchAndSetAccountDetails, history],
  );

  const updateAccountDetails = React.useCallback(async (details: any = {}) => {
    const {data, error} = await api.users.patchMe(details);

    if (data) {
      setAccountDetails(data);
    }
    return {data, error};
  }, []);

  React.useLayoutEffect(() => {
    if (persistedToken) {
      getAndPersistUserTokenFromUrlIfExists();
      fetchAndSetAccountDetails();
      return;
    }

    const token = getAndPersistUserTokenFromUrlIfExists();
    if (!token) {
      logout();
    } else {
      fetchAndSetAccountDetails();
    }
  }, [persistedToken, logout, fetchAndSetAccountDetails]);

  React.useEffect(() => {
    async function refreshToken() {
      const prevToken = getTokenFromLocalStorage();
      const {error, data} = await api.auth.refreshToken({
        token: prevToken,
      });

      if (data?.token) {
        persistUserTokenToLocalStorage(data.token);
      }
      if (error) {
        logout();
      }
    }

    if (!isPending) {
      refreshToken();
    }
  }, [isPending, logout]);

  if (isPending) {
    return <FullPageSpinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        logout,
        login,
        accountDetails,
        refreshAccount: fetchAndSetAccountDetails,
        updateAccount: updateAccountDetails,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export {AuthProvider, useAuth, AuthContext};
