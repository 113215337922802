import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import api from '../../../api';
import i18n from '../../../i18n';
import {SelectOption} from '../../../utils/types';
import {getRequiredOrOptionalFieldLabel, toastResponseError} from '../../../utils/common';
import {getCountryCode, getExternalHousingsAsOptions} from '../../../utils/housing';
import {useIsFormTouched, useIsMounted, usePrevious} from '../../../utils/hooks';
import {
  COUNTRY_CODES,
  POLICE_CODES,
  POLICE_TYPES,
  POLICE_TYPES_OF_DOCUMENT,
  VALIDATION_STATUSES,
} from '../../../utils/constants';
import Input from '../Input';
import Select from '../Select';
import Section from '../Section';
import Switch from '../Switch';
import ValidationButton from '../ValidationButton';
import {FieldWrapper} from '../../../styled/common';
import {TitleLink} from './styled';

const STATUS_DISPLAY_TIMEOUT_SEC = 3;
const VALIDATION_REQUESTS_GAP_SEC = 2;
const POLICE_ACCOUNT_INFO_LINK = 'https://chekin.io/en/blog/host-registration/';

export enum FORM_NAMES {
  type = 'type',
  username = 'username',
  password = 'password',
  external_housing = 'external_housing',
  external_id = 'external_id',
  type_of_document = 'username_type',
  certificate_password = 'certificate_password',
  establishment_number = 'establishment_number',
}

type FormTypes = {
  [FORM_NAMES.type]: SelectOption | null;
  [FORM_NAMES.username]: string;
  [FORM_NAMES.password]: string;
  [FORM_NAMES.certificate_password]: string;
  [FORM_NAMES.establishment_number]: string;
  [FORM_NAMES.type_of_document]: SelectOption;
  [FORM_NAMES.external_housing]?: SelectOption | null;
  [FORM_NAMES.external_id]: string;
};

const INIT_DISPLAY_FIELDS = {
  [FORM_NAMES.type]: true,
  [FORM_NAMES.username]: true,
  [FORM_NAMES.password]: true,
  [FORM_NAMES.external_housing]: false,
  [FORM_NAMES.certificate_password]: false,
  [FORM_NAMES.establishment_number]: false,
  [FORM_NAMES.type_of_document]: false,
  [FORM_NAMES.external_id]: false,
};

function getDisplayFields(country: string, policeType: string) {
  switch (country) {
    case COUNTRY_CODES.colombia: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.external_housing]: true,
        [FORM_NAMES.type_of_document]: true,
      };
    }
    case COUNTRY_CODES.italy: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.external_housing]: true,
        [FORM_NAMES.certificate_password]: true,
      };
    }
    case COUNTRY_CODES.portugal: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.establishment_number]: true,
      };
    }
    case COUNTRY_CODES.dubai: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.external_housing]: true,
      };
    }
    case COUNTRY_CODES.spain: {
      if (policeType === POLICE_CODES.nationalPolice) {
        return {
          ...INIT_DISPLAY_FIELDS,
          [FORM_NAMES.external_housing]: true,
        };
      }
      return INIT_DISPLAY_FIELDS;
    }
    case COUNTRY_CODES.czech: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.external_housing]: true,
      };
    }
    case COUNTRY_CODES.austria: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.password]: false,
        [FORM_NAMES.external_id]: true,
      };
    }
    case COUNTRY_CODES.croatia:
    case COUNTRY_CODES.slovenia: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.external_id]: true,
      };
    }
    default: {
      return INIT_DISPLAY_FIELDS;
    }
  }
}

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.type]: i18n.t('required'),
  [FORM_NAMES.username]: i18n.t('required'),
  [FORM_NAMES.password]: i18n.t('required'),
  [FORM_NAMES.external_housing]: i18n.t('required'),
  [FORM_NAMES.certificate_password]: i18n.t('required'),
  [FORM_NAMES.establishment_number]: i18n.t('required'),
  [FORM_NAMES.type_of_document]: i18n.t('required'),
  [FORM_NAMES.external_id]: i18n.t('required'),
};

function getRequiredFields(country: string, policeType: string) {
  switch (country) {
    default: {
      return INIT_REQUIRED_FIELDS;
    }
  }
}

function getFields(country = '', policeType = '') {
  const display = getDisplayFields(country, policeType);
  const required = getRequiredFields(country, policeType);

  return {display, required};
}

type HousingPoliceConnectionSectionProps = {
  country: string;
  disabled: boolean;
  housing?: any;
  openIncompleteModal: () => void;
  setIsSectionTouched?: (isTouched: boolean) => void;
};

const defaultProps: Partial<HousingPoliceConnectionSectionProps> = {
  country: '',
  disabled: false,
  housing: null,
};

const HousingPoliceConnectionSection = React.forwardRef<
  any,
  HousingPoliceConnectionSectionProps
>(({country, disabled, housing, openIncompleteModal, setIsSectionTouched}, ref) => {
  const {t} = useTranslation();
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    control,
    formState,
    reset,
    triggerValidation,
    setValue,
    watch,
  } = useForm<FormTypes>({
    submitFocusError: false,
  });
  const {isSubmitted, isValid} = formState;
  const prevIsValid = usePrevious<boolean>(isValid);
  const isMounted = useIsMounted();
  const statusTimeoutRef = React.useRef(0);
  const validationTimeoutRef = React.useRef(0);
  const sectionRef = React.useRef(false);
  const [fields, setFields] = React.useState(() => {
    return getFields(country);
  });
  const [isSectionActive, setIsSectionActive] = React.useState(false);
  const [validationStatus, setValidationStatus] = React.useState('');
  const [isPreloaded, setIsPreloaded] = React.useState(false);
  const [isCountryPreloaded, setIsCountryPreloaded] = React.useState(false);
  const [isValidateButtonVisible, setIsValidateButtonVisible] = React.useState(true);
  const [isValidationErrorVisible, setIsValidationErrorVisible] = React.useState(false);
  const [isExternalHousingPreloaded, setIsExternalHousingPreloaded] = React.useState(
    false,
  );
  const [externalHousingsOptions, setExternalHousingsOptions] = React.useState<
    SelectOption[]
  >([]);
  const {isFormTouched, setUntouchedValues} = useIsFormTouched({
    displayFields: fields.display,
    watch,
  });
  const isValidating = validationStatus === VALIDATION_STATUSES.inProgress;

  const policeType = watch(FORM_NAMES.type)?.value;
  const policeUsername = watch(FORM_NAMES.username);
  const policePassword = watch(FORM_NAMES.password);
  const policeExternalId = watch(FORM_NAMES.external_id);
  const policeExternalHousing = watch(FORM_NAMES.external_housing)?.value;
  const policeTypeOfDocument = watch(FORM_NAMES.type_of_document)?.value;
  const policeCertificatePassword = watch(FORM_NAMES.certificate_password);
  const policeEstablishmentNumber = watch(FORM_NAMES.establishment_number);
  const policeTypeOptions = POLICE_TYPES[country];

  React.useImperativeHandle(ref, () => {
    return {
      getValues,
      submit: submitAndValidate,
      active: sectionRef.current,
    };
  });

  React.useEffect(() => {
    if (typeof setIsSectionTouched === 'function') {
      setIsSectionTouched(isFormTouched);
    }
  }, [setIsSectionTouched, isFormTouched]);

  React.useEffect(() => {
    if (!isValidationErrorVisible) {
      setValidationStatus('');
    }
  }, [isValidationErrorVisible]);

  React.useEffect(() => {
    if (statusTimeoutRef.current) {
      clearTimeout(statusTimeoutRef.current);
    }

    setIsValidateButtonVisible(true);
    setIsValidationErrorVisible(false);
  }, [
    policeType,
    policeUsername,
    policePassword,
    policeExternalHousing,
    policeTypeOfDocument,
    policeCertificatePassword,
    policeEstablishmentNumber,
    policeExternalId,
  ]);

  React.useLayoutEffect(
    function showIncompleteModalOneTime() {
      const shouldOpenModal = isSubmitted && !prevIsValid && !isValid;

      if (shouldOpenModal) {
        openIncompleteModal();
      }
    },
    [isSubmitted, isValid, prevIsValid, openIncompleteModal],
  );

  React.useEffect(() => {
    if (formState.isSubmitted) {
      triggerValidation();
    }
  }, [fields, formState.isSubmitted, triggerValidation]);

  React.useEffect(() => {
    if (housing?.is_auto_police_registration_enabled) {
      setIsSectionActive(true);
      sectionRef.current = true;
    }
  }, [housing]);

  React.useEffect(() => {
    if (!isSectionActive) {
      setIsPreloaded(false);
    }
  }, [isSectionActive]);

  const resetValidationStatusAfterTimeout = () => {
    statusTimeoutRef.current = setTimeout(() => {
      setValidationStatus('');
    }, STATUS_DISPLAY_TIMEOUT_SEC * 1000);
  };

  const handleValidationError = React.useCallback((error?: any) => {
    if (error) {
      toastResponseError(error);
    }

    setValidationStatus(VALIDATION_STATUSES.error);
    setIsValidationErrorVisible(true);

    setExternalHousingsOptions([]);
  }, []);

  const hideValidateButtonAfterTimeout = () => {
    statusTimeoutRef.current = setTimeout(() => {
      setIsValidateButtonVisible(false);
    }, STATUS_DISPLAY_TIMEOUT_SEC * 1000);
  };

  const handleValidationSuccess = React.useCallback((data: any) => {
    if (data?.external_housings_data?.length) {
      const options = getExternalHousingsAsOptions(data.external_housings_data);
      setExternalHousingsOptions(options);
    } else {
      setExternalHousingsOptions([]);
    }
    setValidationStatus(VALIDATION_STATUSES.complete);
    resetValidationStatusAfterTimeout();
    hideValidateButtonAfterTimeout();
  }, []);

  const checkPoliceAccountValidation = React.useCallback(
    async (id = ''): Promise<any> => {
      if (!id) {
        handleValidationError();
        return false;
      }

      const {error, data} = await api.policeAccount.checkValidationStatus(id);

      if (isMounted.current && sectionRef.current) {
        if (data?.status === VALIDATION_STATUSES.inProgress) {
          return new Promise((resolve) => {
            validationTimeoutRef.current = setTimeout(() => {
              if (sectionRef.current) {
                resolve(checkPoliceAccountValidation(id));
              }
            }, VALIDATION_REQUESTS_GAP_SEC * 1000);
          });
        }

        if (data?.status === VALIDATION_STATUSES.complete && data?.is_successful_login) {
          handleValidationSuccess(data);
          return true;
        }

        handleValidationError(error);
      }
      return false;
    },
    [handleValidationError, handleValidationSuccess, isMounted],
  );

  const getValidationPayload = (data: FormTypes) => {
    let always_valid;
    if (data?.username?.toLowerCase() === 'test valid') {
      always_valid = true;
    }

    return {
      ...data,
      always_valid,
      type_of_document: undefined,
      username_type: data[FORM_NAMES.type_of_document]?.value,
      type: data[FORM_NAMES.type]?.value,
    };
  };

  const validatePoliceAccount = React.useCallback(async () => {
    const payload = getValidationPayload(getValues() as FormTypes);

    setValidationStatus(VALIDATION_STATUSES.inProgress);
    const {error, data} = await api.policeAccount.startValidation(payload);

    if (isMounted.current && sectionRef.current) {
      if (data?.status === VALIDATION_STATUSES.complete && data?.is_successful_login) {
        handleValidationSuccess(data);
        return true;
      }

      if (data?.id) {
        return checkPoliceAccountValidation(data.id);
      }

      handleValidationError(error);
    }
    return false;
  }, [
    checkPoliceAccountValidation,
    getValues,
    handleValidationError,
    handleValidationSuccess,
    isMounted,
  ]);

  const getInitPolice = React.useCallback(() => {
    const policeAccount = housing?.police_account;
    const policeType = policeTypeOptions?.find((t) => {
      return t.value === policeAccount?.type;
    });
    const typeOfDocument = POLICE_TYPES_OF_DOCUMENT[country]?.find((t) => {
      return t?.value === policeAccount?.username_type;
    });

    return [
      {[FORM_NAMES.certificate_password]: policeAccount?.certificate_password},
      {[FORM_NAMES.username]: policeAccount?.username},
      {[FORM_NAMES.password]: policeAccount?.password},
      {[FORM_NAMES.establishment_number]: policeAccount?.establishment_number},
      {[FORM_NAMES.type]: policeType},
      {[FORM_NAMES.type_of_document]: typeOfDocument},
      {[FORM_NAMES.external_id]: policeAccount?.external_id},
    ];
  }, [country, housing, policeTypeOptions]);

  const loadInitPolice = React.useCallback(() => {
    const isPoliceActive = housing?.is_police_registration_enabled;
    const housingCountry = getCountryCode(housing);
    const isPoliceEnabled = POLICE_TYPES[country];

    if (!isPoliceActive || !country || housingCountry !== country || !isPoliceEnabled) {
      return;
    }

    const formData = getInitPolice();
    setUntouchedValues((prevState) => {
      let result: {[key: string]: any} = {};

      formData.forEach((data: any) => {
        const field = Object.keys(data)[0];
        result[field] = data[field];
      });

      return {
        ...prevState,
        ...result,
      };
    });
    setValue(formData);

    const hasExternalHousing = housing?.police_account?.external_id;
    if (hasExternalHousing) {
      validatePoliceAccount();
    }
  }, [
    country,
    getInitPolice,
    housing,
    setUntouchedValues,
    setValue,
    validatePoliceAccount,
  ]);

  React.useEffect(
    function preloadData() {
      if (!isPreloaded && isSectionActive && isCountryPreloaded) {
        loadInitPolice();
        setIsPreloaded(true);
      }
    },
    [isCountryPreloaded, isPreloaded, loadInitPolice, isSectionActive],
  );

  React.useEffect(
    function updateFields() {
      const nextFields = getFields(country, policeType);
      setFields(nextFields);
      setExternalHousingsOptions([]);

      if (country) {
        setIsCountryPreloaded(true);
      }
    },
    [country, setValue, policeType],
  );

  React.useEffect(
    function keepCorrectPoliceTypeSelection() {
      if (!policeType || !policeTypeOptions?.length) {
        return;
      }

      const hasOption = policeTypeOptions.find((option) => {
        return option.value === policeType;
      });
      if (!hasOption) {
        setValue(FORM_NAMES.type, null);
      }
    },
    [policeType, policeTypeOptions, setValue],
  );

  React.useEffect(
    function preloadExternalHousing() {
      const canPreload =
        !isExternalHousingPreloaded &&
        isPreloaded &&
        isSectionActive &&
        isCountryPreloaded &&
        externalHousingsOptions.length;

      if (canPreload) {
        const id = housing?.police_account?.external_id;
        const externalHousing = externalHousingsOptions.find((option) => {
          return option.value === id;
        });

        setValue(FORM_NAMES.external_housing, externalHousing);
        setIsExternalHousingPreloaded(true);
      }
    },
    [
      externalHousingsOptions,
      housing,
      isCountryPreloaded,
      isExternalHousingPreloaded,
      isPreloaded,
      isSectionActive,
      setValue,
    ],
  );

  React.useEffect(
    function setDefaultExternalHousing() {
      const formValues = getValues() as any;

      if (
        formValues[FORM_NAMES.external_housing]?.value &&
        externalHousingsOptions.length
      ) {
        const value = housing?.police_account?.external_id;
        const label = housing?.police_account?.external_name;

        const initExternalHousing = value && label && {value, label};
        const selectedExternalHousing = formValues[FORM_NAMES.external_housing]?.value;

        const hasOption = externalHousingsOptions.find((option) => {
          if (selectedExternalHousing) {
            return option.value === selectedExternalHousing;
          }

          return option.value === value;
        });

        if (!hasOption) {
          setValue(
            FORM_NAMES.external_housing,
            initExternalHousing || externalHousingsOptions[0],
          );
        }
      }
    },
    [housing, externalHousingsOptions, setValue, getValues],
  );

  React.useEffect(
    function resetOnSectionDisable() {
      if (!isSectionActive) {
        clearTimeout(statusTimeoutRef.current);
        clearTimeout(validationTimeoutRef.current);
        reset();
        setExternalHousingsOptions([]);
        setIsExternalHousingPreloaded(false);
      }
      return () => {
        clearTimeout(statusTimeoutRef.current);
        clearTimeout(validationTimeoutRef.current);
      };
    },
    [isSectionActive, reset],
  );

  const toggleIsSectionActive = () => {
    setIsSectionActive((prevState) => {
      if (!prevState) {
        setValidationStatus('');
      }
      sectionRef.current = !prevState;
      return !prevState;
    });

    if (typeof setIsSectionTouched === 'function') {
      setIsSectionTouched(true);
    }
  };

  const submitAndValidate = async () => {
    await handleSubmit(() => {})();
    if (formState.isValid) {
      return validatePoliceAccount();
    } else {
      const isFormValid = await triggerValidation();
      if (isFormValid) {
        return validatePoliceAccount();
      }
    }
    return false;
  };

  const onSubmit = async () => {
    await validatePoliceAccount();
  };

  if (!POLICE_TYPES[country]) {
    return null;
  }

  return (
    <Section
      showTooltip
      title={
        <>
          {t('police_connection_title')}
          <TitleLink href={POLICE_ACCOUNT_INFO_LINK} target="_blank">
            ({t('dont_have_police_acc_yet')})
          </TitleLink>
        </>
      }
      subtitle={t('police_connection_subtitle')}
      tooltipContent={
        <>
          {t('police_tooltip_top_content')}
          <p />
          {t('police_tooltip_bottom_content')}
        </>
      }
    >
      <Switch
        checked={isSectionActive}
        onChange={toggleIsSectionActive}
        label={t('activate_police_sending')}
        disabled={disabled}
      />
      {isSectionActive && (
        <div>
          {fields.display[FORM_NAMES.type] && (
            <FieldWrapper>
              <Controller
                as={<Select />}
                control={control}
                rules={{required: fields.required[FORM_NAMES.type]}}
                options={POLICE_TYPES[country]}
                name={FORM_NAMES.type}
                label={getRequiredOrOptionalFieldLabel(
                  t('automatically_send_info_to'),
                  fields.required[FORM_NAMES.type],
                )}
                error={(errors[FORM_NAMES.type] as any)?.message}
                disabled={isValidating || disabled}
                placeholder={t('select_your_type')}
              />
            </FieldWrapper>
          )}
          {fields.display[FORM_NAMES.type_of_document] && (
            <FieldWrapper>
              <Controller
                as={<Select />}
                control={control}
                rules={{required: fields.required[FORM_NAMES.type_of_document]}}
                options={POLICE_TYPES_OF_DOCUMENT[country]}
                name={FORM_NAMES.type_of_document}
                label={getRequiredOrOptionalFieldLabel(
                  t('type_of_document'),
                  fields.required[FORM_NAMES.type_of_document],
                )}
                error={(errors[FORM_NAMES.type_of_document] as any)?.message}
                disabled={isValidating || disabled}
                placeholder={t('select_your_type')}
              />
            </FieldWrapper>
          )}
          {fields.display[FORM_NAMES.username] && (
            <FieldWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.username],
                })}
                name={FORM_NAMES.username}
                label={getRequiredOrOptionalFieldLabel(
                  country === COUNTRY_CODES.colombia
                    ? t('document_number')
                    : t('police_username'),
                  fields.required[FORM_NAMES.username],
                )}
                placeholder={
                  country === COUNTRY_CODES.colombia
                    ? t('enter_number')
                    : t('enter_username')
                }
                error={errors[FORM_NAMES.username]?.message}
                disabled={isValidating || disabled}
              />
            </FieldWrapper>
          )}
          {fields.display[FORM_NAMES.password] && (
            <FieldWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.password],
                })}
                name={FORM_NAMES.password}
                type="password"
                empty={!Boolean(policePassword)}
                label={getRequiredOrOptionalFieldLabel(
                  t('police_password'),
                  fields.required[FORM_NAMES.password],
                )}
                placeholder={t('enter_password')}
                error={errors[FORM_NAMES.password]?.message}
                disabled={isValidating || disabled}
              />
            </FieldWrapper>
          )}
          {fields.display[FORM_NAMES.certificate_password] && (
            <FieldWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.certificate_password],
                })}
                name={FORM_NAMES.certificate_password}
                type="password"
                empty={!Boolean(policeCertificatePassword)}
                label={getRequiredOrOptionalFieldLabel(
                  t('certificate_password'),
                  fields.required[FORM_NAMES.certificate_password],
                )}
                placeholder={t('enter_certificate_password')}
                error={errors[FORM_NAMES.certificate_password]?.message}
                disabled={isValidating || disabled}
              />
            </FieldWrapper>
          )}
          {fields.display[FORM_NAMES.establishment_number] && (
            <FieldWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.establishment_number],
                })}
                name={FORM_NAMES.establishment_number}
                type="text"
                label={getRequiredOrOptionalFieldLabel(
                  t('establishment_number'),
                  fields.required[FORM_NAMES.establishment_number],
                )}
                placeholder={t('enter_establishment_number')}
                error={errors[FORM_NAMES.establishment_number]?.message}
                disabled={isValidating || disabled}
              />
            </FieldWrapper>
          )}
          {fields.display[FORM_NAMES.external_housing] &&
            Boolean(externalHousingsOptions.length) && (
              <FieldWrapper>
                <Controller
                  as={<Select />}
                  control={control}
                  options={externalHousingsOptions}
                  name={FORM_NAMES.external_housing}
                  label={getRequiredOrOptionalFieldLabel(
                    t('property'),
                    fields.required[FORM_NAMES.external_housing],
                  )}
                  rules={{
                    required: fields.required[FORM_NAMES.external_housing],
                  }}
                  error={(errors[FORM_NAMES.external_housing] as any)?.message}
                  disabled={isValidating || disabled}
                  placeholder={t('select_your_property')}
                />
              </FieldWrapper>
            )}
          {fields.display[FORM_NAMES.external_id] && (
            <FieldWrapper>
              <Input
                ref={register({
                  required: fields.required[FORM_NAMES.external_id],
                })}
                name={FORM_NAMES.external_id}
                label={getRequiredOrOptionalFieldLabel(
                  t('external_id'),
                  fields.required[FORM_NAMES.external_id],
                )}
                placeholder={t('enter_external_id')}
                error={errors[FORM_NAMES.external_id]?.message}
                disabled={isValidating || disabled}
              />
            </FieldWrapper>
          )}
          {isValidateButtonVisible && (
            <ValidationButton
              secondary
              type="button"
              errorMessage={t('connection_validation_error')}
              disabled={Boolean(validationStatus) || disabled}
              status={validationStatus}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </div>
      )}
    </Section>
  );
});

HousingPoliceConnectionSection.defaultProps = defaultProps;
export {HousingPoliceConnectionSection};
