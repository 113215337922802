import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import i18n from '../../../i18n';
import {PaymentsMovements} from '../../../utils/types';
import {GridHeader, GridRow, SpannedRow, WriteOffRow, Wrapper} from './styled';

function getMovementDate(date: string) {
  const movementDate = moment(date, 'YYYY-MM-DD').startOf('day');
  const today = moment().startOf('day');
  const yesterday = today.clone().subtract(1, 'day');

  if (movementDate.isSame(today, 'day')) {
    return i18n.t('today');
  }

  if (movementDate.isSame(yesterday, 'day')) {
    return i18n.t('yesterday');
  }

  return movementDate.format('DD-MM-YYYY');
}

type PaymentsInvoicesGridProps = {
  movements?: PaymentsMovements;
  className?: string;
};

function PaymentsMovementsGrid({movements, className}: PaymentsInvoicesGridProps) {
  const {t} = useTranslation();

  const isArrayEmpty = (arr: Array<any>) => {
    return arr.length === 0;
  };

  const memoizedMovements = React.useMemo(() => {
    if (!movements) {
      return null;
    }

    if (Object.values(movements).every(isArrayEmpty)) {
      return null!;
    }

    return Object.keys(movements).map((date) => {
      return (
        <div key={date}>
          <GridHeader>{getMovementDate(date)}</GridHeader>
          {movements[date].map((movement) => {
            if (movement.type === 'WRITE_OFF') {
              return (
                <GridRow key={movement.id}>
                  <SpannedRow>{t('transfer_to_your_bank_account')}</SpannedRow>
                  <WriteOffRow>-{movement.amount} €</WriteOffRow>
                </GridRow>
              );
            }

            return (
              <GridRow key={movement.id}>
                <div>{movement.guest_name}</div>
                <div>{movement.housing_name}</div>
                <div>{movement.amount} €</div>
              </GridRow>
            );
          })}
        </div>
      );
    });
  }, [movements, t]);

  return <Wrapper className={className}>{memoizedMovements}</Wrapper>;
}

export {PaymentsMovementsGrid};
