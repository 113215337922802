import styled from 'styled-components';
import ModalButton from '../ModalButton';

export const Content = styled.div`
  max-width: 1047px;
  margin: 21px auto 0;
  cursor: default;
  padding: 0 120px 230px;
`;

export const Wrapper = styled.div``;

export const Heading = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
  font-size: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ButtonLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonLabelIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 10px;
  margin-left: 2px;
`;

export const ButtonLabelText = styled.div`
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
`;

export const DeleteButtonWrapper = styled.div`
  margin-top: 43px;
`;

export const DeleteButtonLabelIcon = styled.img`
  width: 12px;
  height: 16px;
  margin-right: 9px;
`;

export const DeleteButtonLabelText = styled.div`
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
`;

export const DeleteBookingButtonsWrapper = styled.div`
  margin: 44px auto 34px;
  width: fit-content;
  display: flex;
  flex-direction: column;

  & button:first-child {
    margin-bottom: 33px;
  }
`;

export const DeleteModalButton = styled(ModalButton)`
  border-color: #9696b9;
`;

export const ModalButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 52px;
  margin-top: -20px;
`;

export const BottomButtonWrapper = styled.div`
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);
`;
