import styled from 'styled-components';
import BaseButton from '../Button';
import {StyledButton as SelectorButton} from '../SelectorButton/styled';

export const Content = styled.div`
  position: relative;
`;

export const SelectorsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -10px;

  & ${SelectorButton} {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 19px;
  height: 70px;

  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    height: 140px;
  }
`;

export const SelectWrapper = styled.div`
  padding-left: 20px;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
    padding-top: 15px;
  }
`;

export const ShareOnlineCheckInContainer = styled.div`
  position: absolute;
  top: -16px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: ProximaNova-Light, sans-serif;
  color: #161643;
  font-size: 13px;

  & > button {
    min-width: 171px;
    margin-top: 10px;
  }
`;

export const EnvelopeImage = styled.img`
  height: 13px;
`;

export const CopyButton = styled(BaseButton)`
  min-width: 210px !important;
  cursor: copy;

  &:active {
    border-color: #1a8cff;
  }
`;

export const SendCheckInOnlineButton = styled(BaseButton)`
  min-width: 210px !important;
  margin-bottom: 10px;
`;

export const LoaderWrapper = styled.div`
  margin-top: 10px;
`;

export const SentEmailsContainer = styled.div`
  width: 177px;
  min-height: 118px;
  font-family: ProximaNova-Light, sans-serif;
  font-size: 13px;
  color: #161643;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 5px 5px #2699fb1a;
  border-radius: 6px;
  z-index: -1;
`;

export const SentEmailsTitle = styled.div`
  padding: 0 12px 12px;
`;

export const SentEmailsImage = styled.img`
  width: 31px;
  height: 36px;
  padding-bottom: 2px;
`;

export const SentEmailsNumber = styled.div`
  color: #161643;
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 31px;
  padding-bottom: 10px;
`;

export const ShareOnlineCheckinManuallyWrapper = styled.div`
  min-height: 118px;
  margin-left: 26px;
`;

export const ShareOnlineCheckinManuallyText = styled.div`
  margin-bottom: 10px;
`;
