import React from 'react';
import TrialHeader from '../components/dashboard/TrialHeader';
import Header from '../components/dashboard/Header';
import GuestDetailsForm from '../components/dashboard/GuestDetailsForm';

function Guest() {
  return (
    <>
      <TrialHeader />
      <Header />
      <GuestDetailsForm />
    </>
  );
}

export {Guest};
