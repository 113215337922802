import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import i18n from '../../../i18n';
import {
  REMINDER_OPTIONS,
  ONLINE_CHECK_IN_SELECTORS,
  LANGUAGE_OPTIONS,
} from '../../../utils/constants';
import {useIsFormTouched} from '../../../utils/hooks';
import {Housing, SelectOption} from '../../../utils/types';
import Section from '../Section';
import SelectorButton from '../SelectorButton';
import Switch from '../Switch';
import Select from '../Select';
import {TooltipContentItem, Content, SelectorsWrapper, SelectWrapper} from './styled';

const TOOLTIP_CONTENT = (
  <>
    <TooltipContentItem>
      {i18n.t('checkin_online_section_tooltip_first_part')}
    </TooltipContentItem>
    <TooltipContentItem>
      {i18n.t('checkin_online_section_tooltip_second_part')}
    </TooltipContentItem>
  </>
);

type Selector = {
  [key: string]: boolean;
};

enum HOUSING_FORM_NAMES {
  email_language = 'default_email_language',
}

export type FormTypes = {
  [HOUSING_FORM_NAMES.email_language]: SelectOption;
};

function getInitOnlineCheckInSelectors() {
  let result: Selector = {};
  Object.keys(REMINDER_OPTIONS).forEach((key) => {
    result[key] = false;
  });

  return result;
}

function getIsAnySelectorActive(selectors?: Selector) {
  if (selectors) {
    return Object.keys(REMINDER_OPTIONS).some((key) => {
      return selectors[key];
    });
  }
  return false;
}

const displayFields = {
  [HOUSING_FORM_NAMES.email_language]: true,
};

type HousingOnlineCheckInSectionProps = {
  disabled: boolean;
  initSelectors?: Selector;
  housing?: Housing;
  setIsSectionTouched?: (isTouched: boolean) => void;
};

const defaultProps: Partial<HousingOnlineCheckInSectionProps> = {
  initSelectors: undefined,
  disabled: false,
  housing: undefined,
};

const DEFAULT_EMAIL_LANGUAGE = LANGUAGE_OPTIONS[0];

const HousingOnlineCheckInSection = React.forwardRef(
  ({disabled, housing, setIsSectionTouched}: HousingOnlineCheckInSectionProps, ref) => {
    const {t} = useTranslation();
    const [isPreloaded, setIsPreloaded] = React.useState(false);
    const [isSectionActive, setIsSectionActive] = React.useState(false);
    const [selectors, setSelectors] = React.useState<Selector>(() => {
      return getInitOnlineCheckInSelectors();
    });
    const {control, errors, watch, setValue} = useFormContext<FormTypes>();
    const {isFormTouched, setUntouchedValues} = useIsFormTouched({
      displayFields,
      watch,
    });

    const setSectionTouched = React.useCallback(() => {
      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(true);
      }
    }, [setIsSectionTouched]);

    React.useEffect(
      function preloadHousingLanguage() {
        const housingLanguage = housing?.default_email_language;

        const housingLanguageOptions = LANGUAGE_OPTIONS.find(
          (lang) => lang.value === housingLanguage,
        );

        if (housingLanguage) {
          setValue(
            HOUSING_FORM_NAMES.email_language,
            housingLanguageOptions as SelectOption,
          );
          setUntouchedValues((prevState) => {
            return {
              ...prevState,
              [HOUSING_FORM_NAMES.email_language]: housingLanguageOptions,
            };
          });
        }
      },
      [housing, setUntouchedValues, setValue],
    );

    React.useEffect(() => {
      if (isFormTouched) {
        setSectionTouched();
      }
    }, [isFormTouched, setSectionTouched]);

    React.useImperativeHandle(ref, () => {
      return {
        data: selectors,
        active: isSectionActive,
      };
    });

    const getInitSelectors = React.useCallback(() => {
      const options: Selector = {};

      Object.values(REMINDER_OPTIONS).forEach((value) => {
        options[value] = Boolean(housing?.[value as keyof Housing]);
      });
      return options;
    }, [housing]);

    React.useEffect(
      function preloadSelectors() {
        const initSelectors = getInitSelectors();

        if (initSelectors && housing?.id) {
          if (!isPreloaded) {
            const isAnySelectorActive = getIsAnySelectorActive(initSelectors);
            setIsSectionActive(isAnySelectorActive);
          }

          setSelectors(initSelectors);
          setIsPreloaded(true);
        }
      },
      [isSectionActive, getInitSelectors, housing, isPreloaded],
    );
    const disableAllSelectors = () => {
      setSelectors((prevState) => {
        const nextState = {...prevState};
        Object.keys(prevState).forEach((key) => {
          nextState[key] = false;
        });
        return nextState;
      });
    };

    const toggleActiveCheckInOnline = () => {
      setIsSectionActive((prevState) => {
        if (prevState) {
          disableAllSelectors();
        }
        return !prevState;
      });

      setSectionTouched();
    };

    const toggleCheckInSelector = (option = '') => {
      setSelectors((prevState) => {
        return {
          ...prevState,
          [option]: !prevState[option],
        };
      });

      setSectionTouched();
    };

    return (
      <Section
        showTooltip
        title={t('online_check_in_title')}
        subtitle={t('online_check_in_subtitle')}
        tooltipContent={TOOLTIP_CONTENT}
      >
        <SelectWrapper>
          <Controller
            control={control}
            label={t('default_email_language_housing_online_check_in')}
            placeholder={t('select_language')}
            name={HOUSING_FORM_NAMES.email_language}
            options={LANGUAGE_OPTIONS}
            as={<Select />}
            disabled={disabled}
            error={(errors[HOUSING_FORM_NAMES.email_language] as any)?.message}
            rules={{required: t('required') as string}}
            defaultValue={DEFAULT_EMAIL_LANGUAGE}
          />
        </SelectWrapper>
        <Content>
          <Switch
            checked={isSectionActive}
            onChange={toggleActiveCheckInOnline}
            label={t('send_check_in')}
            disabled={disabled}
          />
          {isSectionActive && (
            <SelectorsWrapper>
              {ONLINE_CHECK_IN_SELECTORS.map((selector, index) => {
                return (
                  <SelectorButton
                    type="button"
                    key={index}
                    label={selector.label}
                    active={selectors[selector.name]}
                    disabled={disabled}
                    onClick={() => toggleCheckInSelector(selector.name)}
                  />
                );
              })}
            </SelectorsWrapper>
          )}
        </Content>
      </Section>
    );
  },
);

HousingOnlineCheckInSection.defaultProps = defaultProps;
export {HousingOnlineCheckInSection};
