import {resolver} from './index';

const ENDPOINTS = {
  lockUsers: () => `lock-users/`,
  lockUserById: (id: string) => `lock-users/${id}/`,
  externalLocks: (userId: string) => `lock-users/${userId}/external-locks/`,
  locks: (id?: string | null, params = '') =>
    `locks/${id ? `${id}/?${params}` : `?${params}`}`,
  unlockLinks: (params = '') => `unlock-links/?${params}`,
  unlock: (token: string) => `locks/unlock/?token=${token}`,
};

function createLockUser(payload: any) {
  return resolver(ENDPOINTS.lockUsers(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function deleteLockUser(id: string) {
  return resolver(ENDPOINTS.lockUserById(id), {
    method: 'DELETE',
  });
}

function createLock(payload: any) {
  return resolver(ENDPOINTS.locks(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function updateLock(id: string, payload: any) {
  return resolver(ENDPOINTS.locks(id), {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

export {ENDPOINTS, createLockUser, createLock, updateLock, deleteLockUser};
