import React from 'react';
import {StyledButton, Label} from './styled';

export type ButtonProps = {
  label: React.ReactNode | JSX.Element | string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  type?: 'submit' | 'button';
  secondary?: boolean;
  blinking?: boolean;
};

const defaultProps: ButtonProps = {
  label: '',
  disabled: false,
  secondary: false,
  blinking: false,
  onClick: () => {},
  className: undefined,
  type: 'button',
};

function Button({
  label,
  onClick,
  disabled,
  className,
  type,
  secondary,
  blinking,
  ...props
}: ButtonProps) {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      className={className}
      type={type}
      secondary={secondary}
      blinking={blinking}
      {...props}
    >
      <Label>{label}</Label>
    </StyledButton>
  );
}

Button.defaultProps = defaultProps;
export {Button};
