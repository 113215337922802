import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import api, {queryFetcher} from '../../../api';
import {EmailStatistics, LightReservation, SelectOption} from '../../../utils/types';
import {
  useErrorModal,
  useErrorToast,
  useIsFormTouched,
  useIsMounted,
  useStatus,
} from '../../../utils/hooks';
import {copyToClipboard, getRequiredOrOptionalFieldLabel} from '../../../utils/common';
import {
  LANGUAGE_OPTIONS,
  ONLINE_CHECK_IN_SELECTORS,
  PATTERNS,
  REMINDER_OPTIONS,
} from '../../../utils/constants';
import signsIcon from '../../../assets/signs.svg';
import envelopeIcon from '../../../assets/envelope.svg';
import paperPlaneIcon from '../../../assets/paperplane-icon.svg';
import {
  FORM_NAMES as RESERVATION_INFO_FORM_NAMES,
  HousingOption,
} from '../ReservationInfoSection/ReservationInfoSection';
import Section from '../Section';
import SelectorButton from '../SelectorButton';
import Switch from '../Switch';
import Input from '../Input';
import Select from '../Select';
import Loader from '../../common/Loader';
import {
  Content,
  CopyButton,
  EnvelopeImage,
  FieldWrapper,
  SelectorsWrapper,
  SelectWrapper,
  SendCheckInOnlineButton,
  SentEmailsContainer,
  SentEmailsImage,
  SentEmailsNumber,
  SentEmailsTitle,
  ShareOnlineCheckInContainer,
  ShareOnlineCheckinManuallyText,
  ShareOnlineCheckinManuallyWrapper,
} from './styled';

const COPIED_LINK_TIMEOUT_S = 1.5;
const EMAIL_STATISTICS_REFETCH_INTERVAL_S = 4;

type SelectorOption = {
  label: string;
  name: string;
};

type Selector = {
  [key: string]: boolean;
};

function getInitOnlineCheckInSelectors(selectors: SelectorOption[]) {
  let result: Selector = {};
  Object.keys(selectors).forEach((key) => {
    result[key] = false;
  });

  return result;
}

function getIsAnySelectorActive(selectors?: Selector) {
  if (selectors) {
    return Object.keys(REMINDER_OPTIONS).some((key) => {
      return selectors[key];
    });
  }
  return false;
}

function fetchEmailStatistics(key: string, id: string) {
  return queryFetcher(api.reservations.ENDPOINTS.emailStatistics(id));
}

function getDefaultEmailLanguage(defaultEmailLanguage?: string) {
  if (!defaultEmailLanguage) {
    return LANGUAGE_OPTIONS[0];
  }

  const defaultLanguageOption = LANGUAGE_OPTIONS.filter(
    (l) => l.value === defaultEmailLanguage,
  );
  return defaultLanguageOption[0];
}

export const DEFAULT_LANGUAGE_FIELD_NAME = 'default_email_language';

export enum FORM_NAMES {
  lead_guest_email = 'default_invite_email',
  language = 'language',
}

export type FormTypes = {
  [FORM_NAMES.language]: SelectOption;
  [FORM_NAMES.lead_guest_email]?: string;
};

const displayFields = {
  [FORM_NAMES.lead_guest_email]: true,
  [FORM_NAMES.language]: true,
};

const defaultValues = {
  [FORM_NAMES.lead_guest_email]: '',
  [FORM_NAMES.language]: LANGUAGE_OPTIONS[0],
};

type LocationState = {
  formData?: any;
  checkInOnlineData?: {
    [key: string]: boolean;
  };
};

type HousingOnlineCheckInSectionProps = {
  disabled: boolean;
  initSelectors?: Selector;
  reservation?: LightReservation;
  isEditing?: boolean;
  isSectionTouched?: boolean;
  setIsSectionTouched?: (isTouched: boolean) => void;
  defaultLanguage?: string;
};

const defaultProps: Partial<HousingOnlineCheckInSectionProps> = {
  initSelectors: undefined,
  disabled: false,
  isEditing: false,
  isSectionTouched: false,
  reservation: undefined,
  defaultLanguage: undefined,
};

const ReservationOnlineCheckInSection = React.forwardRef(
  (
    {
      disabled,
      reservation,
      isEditing,
      setIsSectionTouched,
      isSectionTouched,
      defaultLanguage,
    }: HousingOnlineCheckInSectionProps,
    ref,
  ) => {
    const {t} = useTranslation();
    const isMounted = useIsMounted();
    const location = useLocation<LocationState>();
    const persistedState = location.state?.checkInOnlineData;
    const timeoutRef = React.useRef<number>();
    const isPersistedStatePreloaded = React.useRef<boolean>(false);
    const {ErrorModal, displayError} = useErrorModal();
    const {
      control,
      register,
      errors,
      watch,
      setValue,
      triggerValidation,
      formState,
    } = useFormContext<FormTypes>();

    const {isSubmitted} = formState;
    const [isSendingEnabled, setIsSendingEnabled] = React.useState(false);
    const [isLinkCopied, setIsLinkCopied] = React.useState(false);
    const [selectors, setSelectors] = React.useState<Selector>(() => {
      return getInitOnlineCheckInSelectors(ONLINE_CHECK_IN_SELECTORS);
    });
    const {isFormTouched, setUntouchedValues} = useIsFormTouched({
      watch,
      displayFields,
      defaultValues,
    });

    const {
      setStatus: setEmailSendingStatus,
      isLoading: isSendingEmail,
      isIdle: isEmailSendingIdle,
      isSuccess: isEmailSendingSuccess,
    } = useStatus({
      autoReset: true,
    });

    const {
      data: emailStatistics,
      status: emailStatisticsStatus,
      error: emailStatisticsError,
      refetch: refetchEmailStatistics,
    } = useQuery<EmailStatistics, [string, string]>(
      Boolean(reservation?.id) && ['emailStatistics', reservation!.id],
      fetchEmailStatistics,
      {
        refetchInterval: EMAIL_STATISTICS_REFETCH_INTERVAL_S * 1000,
      },
    );
    useErrorToast(emailStatisticsError, {
      notFoundMessage: t('errors.email_statistics_not_found'),
    });

    const defaultEmailLanguage = defaultLanguage || reservation?.default_email_language;

    const housing = (watch(RESERVATION_INFO_FORM_NAMES.housing) as HousingOption)?.data;
    const guestLeaderEmail = watch(FORM_NAMES.lead_guest_email) as string;
    const language = watch(FORM_NAMES.language) as SelectOption;
    const isEmailFieldRequired =
      (isSendingEnabled || housing?.is_self_online_check_in_enabled) &&
      (t('required') as string);

    React.useImperativeHandle(ref, () => {
      return {
        data: selectors,
        active: isSendingEnabled,
        emailLanguage: language?.value,
      };
    });

    React.useEffect(() => {
      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(isFormTouched);
      }
    }, [isFormTouched, setIsSectionTouched]);
    React.useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    React.useEffect(() => {
      const defaultInviteEmail =
        reservation?.default_invite_email || defaultValues[FORM_NAMES.lead_guest_email];
      const defaultEmailLanguageOption = getDefaultEmailLanguage(defaultEmailLanguage);

      setUntouchedValues((prevState) => {
        return {
          ...prevState,
          [FORM_NAMES.lead_guest_email]: defaultInviteEmail,
          [FORM_NAMES.language]: defaultEmailLanguageOption,
        };
      });

      setValue(FORM_NAMES.lead_guest_email, defaultInviteEmail);
      setValue(FORM_NAMES.language, defaultEmailLanguageOption);
    }, [reservation, setValue, setUntouchedValues, defaultEmailLanguage]);

    const getInitSelectors = React.useCallback(() => {
      const options: {[key: string]: boolean} = {};
      const canUsePersistedState = persistedState && !isPersistedStatePreloaded.current;

      Object.values(REMINDER_OPTIONS).forEach((value) => {
        if (canUsePersistedState) {
          options[value] = Boolean(persistedState?.[value]);
        } else {
          options[value] = Boolean((housing as any)?.[value]);
        }
      });

      if (canUsePersistedState) {
        isPersistedStatePreloaded.current = true;
      }
      return options;
    }, [housing, persistedState]);

    React.useEffect(
      function preloadSelectors() {
        if (!housing) {
          return;
        }

        const initSelectors = getInitSelectors();

        if (initSelectors) {
          setSelectors(initSelectors);

          const isAnySelectorActive = getIsAnySelectorActive(initSelectors);
          setIsSendingEnabled(isAnySelectorActive);
        }
      },
      [getInitSelectors, housing],
    );

    React.useEffect(() => {
      if (isSubmitted) {
        triggerValidation(FORM_NAMES.lead_guest_email);
      }
    }, [isSubmitted, triggerValidation, isEmailFieldRequired]);

    const disableAllSelectors = () => {
      setSelectors((prevState) => {
        const nextState = {...prevState};

        Object.keys(prevState).forEach((key) => {
          nextState[key] = false;
        });
        return nextState;
      });
    };

    const setSectionTouched = () => {
      if (typeof setIsSectionTouched === 'function') {
        setIsSectionTouched(true);
      }
    };

    const toggleActiveCheckInOnline = () => {
      setIsSendingEnabled((prevState) => {
        if (prevState) {
          disableAllSelectors();
        } else {
          const initSelectors = getInitSelectors();

          if (initSelectors) {
            setSelectors(initSelectors);
          }
        }

        return !prevState;
      });

      setSectionTouched();
    };

    const toggleCheckInSelector = (option = '') => {
      setSelectors((prevState) => {
        return {
          ...prevState,
          [option]: !prevState[option],
        };
      });
      setSectionTouched();
    };

    const copyShareLinkToClipboard = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      copyToClipboard(reservation!.signup_form_link);
      setIsLinkCopied(true);
      timeoutRef.current = setTimeout(() => {
        setIsLinkCopied(false);
      }, COPIED_LINK_TIMEOUT_S * 1000);
    };

    const getIsEmailSendingEnabled = () => {
      if (isSendingEmail || disabled) {
        return false;
      }

      return isEditing && guestLeaderEmail && PATTERNS.email.test(guestLeaderEmail);
    };

    const getCheckinOnlineEmailPayload = () => {
      return {
        email_address: guestLeaderEmail,
        language: language?.value,
        reservation: reservation?.id,
      };
    };

    const getReservationEmailLanguage = () => {
      return {
        [DEFAULT_LANGUAGE_FIELD_NAME]: language?.value,
      };
    };

    const handleEmailSendingError = (error = '') => {
      setEmailSendingStatus('idle');
      displayError(error);
    };

    const sendCheckinOnlineEmail = async () => {
      setEmailSendingStatus('loading');

      const reservationEmailPayload = getCheckinOnlineEmailPayload();
      const {error: createEmailError} = await api.reservations.sendReservationEmail(
        reservationEmailPayload,
      );

      if (!isMounted.current) {
        return;
      }

      if (createEmailError) {
        handleEmailSendingError(createEmailError);
        return;
      }

      const reservationPayload = getReservationEmailLanguage();
      const {error: updateLanguageError} = await api.reservations.patch(
        reservation!.id,
        reservationPayload,
      );

      if (!isMounted.current) {
        return;
      }

      if (updateLanguageError) {
        handleEmailSendingError(updateLanguageError);
        return;
      }

      await refetchEmailStatistics({force: true});
      setEmailSendingStatus('success');
    };

    return (
      <>
        <Section showTooltip title={t('online_check_in_title')}>
          <Content>
            <FieldWrapper>
              <Input
                ref={register({
                  required: isEmailFieldRequired,
                  pattern: {
                    value: PATTERNS.email,
                    message: t('invalid_email'),
                  },
                })}
                inputMode="email"
                name={FORM_NAMES.lead_guest_email}
                label={getRequiredOrOptionalFieldLabel(
                  t('guest_leader_email'),
                  isEmailFieldRequired,
                )}
                placeholder={t('enter_email')}
                disabled={disabled}
                error={errors[FORM_NAMES.lead_guest_email]?.message}
              />
              <SelectWrapper>
                <Controller
                  control={control}
                  label={t('language')}
                  placeholder={t('select_language')}
                  name={FORM_NAMES.language}
                  options={LANGUAGE_OPTIONS}
                  as={<Select />}
                  disabled={disabled}
                  error={(errors[FORM_NAMES.language] as any)?.message}
                  rules={{required: t('required') as string}}
                />
              </SelectWrapper>
            </FieldWrapper>
            <Switch
              checked={isSendingEnabled}
              onChange={toggleActiveCheckInOnline}
              label={t('send_check_in')}
              disabled={disabled}
            />
            {isEditing && (
              <>
                <ShareOnlineCheckInContainer>
                  <SentEmailsContainer>
                    <SentEmailsTitle>{t('emails_already_sent')}</SentEmailsTitle>
                    <SentEmailsImage src={paperPlaneIcon} alt="Paper plane" />
                    <SentEmailsNumber>
                      {!reservation || emailStatisticsStatus === 'loading' ? (
                        <Loader />
                      ) : (
                        emailStatistics?.success || 0
                      )}
                    </SentEmailsNumber>
                  </SentEmailsContainer>
                  <ShareOnlineCheckinManuallyWrapper>
                    <ShareOnlineCheckinManuallyText>
                      {t('share_checkin_manually')}:
                    </ShareOnlineCheckinManuallyText>
                    <SendCheckInOnlineButton
                      secondary
                      type="button"
                      blinking={isSendingEmail}
                      onClick={sendCheckinOnlineEmail}
                      disabled={disabled || !getIsEmailSendingEnabled()}
                      label={
                        <>
                          <EnvelopeImage src={envelopeIcon} alt="Mail" />
                          {isEmailSendingIdle && t('send_online_check_in')}
                          {isSendingEmail && t('sending')}
                          {isEmailSendingSuccess && t('email_sent_exclamation')}
                        </>
                      }
                    />
                    <CopyButton
                      secondary
                      type="button"
                      disabled={disabled || !reservation?.signup_form_link}
                      onClick={copyShareLinkToClipboard}
                      label={
                        <>
                          <img src={signsIcon} alt="Signs" />
                          {isLinkCopied ? t('copied_exclamation') : t('copy_link')}
                        </>
                      }
                    />
                  </ShareOnlineCheckinManuallyWrapper>
                </ShareOnlineCheckInContainer>
              </>
            )}
            {isSendingEnabled && (
              <SelectorsWrapper>
                {ONLINE_CHECK_IN_SELECTORS.map((selector, index) => {
                  return (
                    <SelectorButton
                      type="button"
                      key={index}
                      label={selector.label}
                      active={selectors[selector.name]}
                      disabled={disabled}
                      onClick={() => toggleCheckInSelector(selector.name)}
                    />
                  );
                })}
              </SelectorsWrapper>
            )}
          </Content>
        </Section>
        <ErrorModal />
      </>
    );
  },
);

ReservationOnlineCheckInSection.defaultProps = defaultProps;
export {ReservationOnlineCheckInSection};
