import styled, {css} from 'styled-components';
import {
  Container as HeaderContainer,
  ToggleWrapper as HeaderToggleWrapper,
} from '../HeaderItem/styled';

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  z-index: 300;
  justify-content: space-between;
  background-color: #161643;
  opacity: 1;
  flex-wrap: wrap;
  position: relative;
`;

export const LogoWrapper = styled.div`
  margin-top: 29px;
  margin-left: 20px;
  height: 28px;
`;

export const LogoImage = styled.img`
  max-width: 108px;
  max-height: 50px;
`;

export const UserMenuWrapper = styled.div`
  margin-right: 28px;
  margin-top: 34px;
  display: flex;
  height: 20px;

  & > ${HeaderContainer} {
    &:first-child ${HeaderToggleWrapper}:hover img {
      box-shadow: none;
    }

    &:not(:last-child) {
      border-right: 1px solid #25244e;

      & ${HeaderToggleWrapper} {
        padding-right: 19px;
      }
    }

    &:last-child ${HeaderToggleWrapper} {
      padding-right: 0;
    }
  }
`;

export const HeaderNavigationMenuItem = styled.div`
  text-decoration: none;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  color: #9696b9;
  opacity: 1;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
`;

type HeaderNavigationMenuItemWrapperProps = {
  active: boolean;
};

export const HeaderNavigationMenuItemWrapper = styled.span<
  HeaderNavigationMenuItemWrapperProps
>`
  padding-left: 20px;
  padding-right: 20px;
  ${(props) =>
    props.active &&
    css`
      color: #ffffff;
      font-family: ProximaNova-Bold, sans-serif;
    `};
`;

export const HeaderNavigationMenuWrapper = styled.div`
  margin: 31px 0 20px;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-template-rows: 1fr;

  & > ${HeaderNavigationMenuItem}:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }

  & > ${HeaderNavigationMenuItem}:first-child {
    display: initial;
    text-align: right;
  }

  & > ${HeaderNavigationMenuItem}:last-child {
    display: initial;
    text-align: left;
  }
`;

export const UserMenuItemText = styled.div`
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  color: #9696b9;
  margin-left: 8px;
  align-items: center;
  display: flex;
`;

export const MoreImg = styled.img`
  height: 20px;
  width: 4px;
  margin-left: 14px;
`;

export const UserMenuItemImg = styled.img`
  height: 20px;
  width: 20px;

  &:hover {
    box-shadow: 0 3px 3px #1a8cff1a;
  }
`;
