import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {useQuery} from 'react-query';
import {useLocation} from 'react-router-dom';
import moment, {Moment} from 'moment';
import type {
  GuestGroup,
  Housing,
  Reservation,
  Room,
  SelectOption,
  ShortHousing,
  User,
} from '../../../utils/types';
import {MAX_DEPOSIT_AMOUNT} from '../../../utils/constants';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import {useErrorToast, useIsFormTouched} from '../../../utils/hooks';
import {getRequiredOrOptionalFieldLabel} from '../../../utils/common';
import {
  getStatType,
  getShortHousingsAsOptions,
  getOneHousingAsOption,
  getTimezone,
  getCountryCode,
} from '../../../utils/housing';
import {
  ALL_GROUP_TYPES_OPTIONS,
  COUNTRY_CODES,
  GROUP_GROUP_TYPES_OPTIONS,
  MAX_NUMBER_OF_GUESTS,
  RESPONSIBLE_ME_OPTION,
  SECURITY_DEPOSIT_STATUSES,
  SINGLE_GROUP_TYPES_OPTIONS,
  STAT_TYPES,
  STAT_TYPES_WITHOUT_OCCUPIED_ROOMS,
  TOURIST_GROUP_GROUP_TYPE_OPTION,
} from '../../../utils/constants';
import Section from '../Section';
import Select from '../Select';
import Input from '../Input';
import DateRangePicker from '../DateRangePicker';
import {Layout} from './styled';
import {formatDate, getTimezoneDate} from '../../../utils/date';
import {LightReservation} from '../../../utils/types';
import PhoneInput from '../PhoneInput';

const MIN_NUMBER_OF_NIGHTS = 1;
const MIN_NUMBER_OF_GUESTS = 1;
// const MIN_NUMBER_OF_CHILDREN = 0;
const MIN_PRICE = 0;
const MIN_OCCUPIED_ROOMS = 0;
const MIN_DEPOSIT_AMOUNT = 0;

function fetchShortHousings() {
  return queryFetcher(api.housings.ENDPOINTS.all(`ordering=name&fields=id,name`));
}

function fetchCollaborators() {
  return queryFetcher(api.users.ENDPOINTS.collaborators());
}

function fetchRooms() {
  return queryFetcher(api.rooms.ENDPOINTS.all());
}

function fetchAvailableHousings(key: string, params: string) {
  return queryFetcher(api.housings.ENDPOINTS.all(params));
}

function getCollaboratorsAsOptions(collaborators: User[]): SelectOption[] {
  if (!Array.isArray(collaborators)) {
    return [RESPONSIBLE_ME_OPTION];
  }

  const options = collaborators.map((c) => {
    return {
      label: c.name,
      value: c.id,
    };
  });
  return [RESPONSIBLE_ME_OPTION, ...options];
}

function getRoomsAsOptions(rooms: Room[], housingOptionId = '') {
  if (!Array.isArray(rooms) || !housingOptionId) {
    return [];
  }

  return rooms
    .filter((r) => {
      const id = r?.housing_id?.replace(/-/g, '');
      return id === housingOptionId;
    })
    .map((r) => {
      return {
        value: r?.external_id,
        label: r?.external_name,
      };
    });
}

function getMinCheckInDate(countryCode?: string) {
  let date;

  if (countryCode === COUNTRY_CODES.portugal) {
    date = moment().subtract(4, 'days');
  } else {
    date = moment().subtract(1, 'days').startOf('day');
  }

  return date;
}

/*
function getMinimumChildrenRestrictionAge(countryCode?: string) {
  if (countryCode === COUNTRY_CODES.spain) {
    return 14;
  }
  if (countryCode === COUNTRY_CODES.france) {
    return 15;
  }

  return 16;
}
*/

function getAvailableHousingsCheckInDate(
  date?: Moment | null,
  reservation?: Reservation | LightReservation | null,
) {
  const checkInDate = date || reservation?.check_in_date;

  if (checkInDate) {
    return moment(checkInDate).format('YYYY-MM-DD');
  }
  return '';
}

function getAvailableHousingsAsOptions(
  housings: ShortHousing[],
  reservation?: Reservation | null | LightReservation,
  housing?: Housing,
) {
  let options = getShortHousingsAsOptions(housings);
  const reservationHousing = housing && getOneHousingAsOption(housing);

  if (reservationHousing) {
    const optionsWithoutReservationHousing = options.filter((o) => {
      return o.value !== reservationHousing.value;
    });
    options = [reservationHousing, ...optionsWithoutReservationHousing];
  }

  return options;
}

function getSpacesAsOptions(selectedHousing?: HousingOption) {
  if (selectedHousing?.data?.rooms) {
    return selectedHousing?.data?.rooms?.map((room: Room) => {
      return {
        label: room.number,
        value: room.id,
      };
    });
  }

  return [];
}

export type HousingOption = {
  label: string;
  value: string;
  country: string;
  data: ShortHousing;
};

export enum FORM_NAMES {
  housing = 'housing_id',
  space = 'space',
  responsible = 'assigned_to',
  check_in_date = 'check_in_date',
  number_of_nights = 'nights_of_stay',
  children = 'children',
  number_of_guests = 'number_of_guests',
  guest_leader_name = 'default_leader_full_name',
  type = 'type',
  price = 'price',
  deposit = 'deposit',
  occupied_rooms = 'occupied_rooms_quantity',
  external_room_id = 'external_room_id',
  external_room_id_option = 'external_room_id_option',
  check_out_date = 'check_out_date',
  default_phone_number = 'default_phone_number',
  deposit_amount = 'security_deposit_amount',
}

export type FormTypes = {
  [FORM_NAMES.housing]?: HousingOption;
  [FORM_NAMES.space]?: SelectOption;
  [FORM_NAMES.check_in_date]?: Moment | null;
  [FORM_NAMES.check_out_date]?: Moment | null;
  [FORM_NAMES.number_of_nights]?: string;
  [FORM_NAMES.number_of_guests]?: string;
  [FORM_NAMES.guest_leader_name]?: string;
  [FORM_NAMES.children]?: Number;
  [FORM_NAMES.type]?: SelectOption;
  [FORM_NAMES.responsible]?: SelectOption;
  [FORM_NAMES.price]?: string;
  [FORM_NAMES.deposit]?: string;
  [FORM_NAMES.occupied_rooms]?: string;
  [FORM_NAMES.external_room_id]?: string;
  [FORM_NAMES.external_room_id_option]?: SelectOption;
  [FORM_NAMES.default_phone_number]?: string;
  [FORM_NAMES.deposit_amount]?: string;
};

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.housing]: i18n.t('required'),
  [FORM_NAMES.space]: false,
  [FORM_NAMES.check_in_date]: i18n.t('required'),
  [FORM_NAMES.number_of_nights]: i18n.t('required'),
  [FORM_NAMES.number_of_guests]: i18n.t('required'),
  [FORM_NAMES.type]: i18n.t('required'),
  [FORM_NAMES.occupied_rooms]: i18n.t('required'),
  [FORM_NAMES.external_room_id]: i18n.t('required'),
  [FORM_NAMES.external_room_id_option]: i18n.t('required'),
  [FORM_NAMES.check_in_date]: i18n.t('required'),
  [FORM_NAMES.deposit_amount]: i18n.t('required'),
  [FORM_NAMES.check_out_date]: false,
  [FORM_NAMES.price]: false,
  [FORM_NAMES.deposit]: false,
  [FORM_NAMES.children]: false,
  [FORM_NAMES.responsible]: false,
  [FORM_NAMES.guest_leader_name]: false,
  [FORM_NAMES.default_phone_number]: false,
};

function getRequiredFields(housingOption: HousingOption) {
  return INIT_REQUIRED_FIELDS;
}

const INIT_DISPLAY_FIELDS = {
  [FORM_NAMES.housing]: true,
  [FORM_NAMES.space]: false,
  [FORM_NAMES.responsible]: true,
  [FORM_NAMES.check_in_date]: true,
  [FORM_NAMES.check_out_date]: true,
  [FORM_NAMES.number_of_guests]: true,
  [FORM_NAMES.guest_leader_name]: true,
  [FORM_NAMES.default_phone_number]: true,
  [FORM_NAMES.number_of_nights]: false,
  [FORM_NAMES.children]: false,
  [FORM_NAMES.type]: false,
  [FORM_NAMES.price]: false,
  [FORM_NAMES.occupied_rooms]: false,
  [FORM_NAMES.external_room_id]: false,
  [FORM_NAMES.external_room_id_option]: false,
  [FORM_NAMES.deposit]: false,
};

function getStatDisplayFields(housingOption: HousingOption) {
  const isSTATEnabled =
    housingOption?.data?.stat_account &&
    housingOption?.data?.is_stat_registration_enabled;
  const statType = getStatType(housingOption?.data);
  let fields = {};

  if (isSTATEnabled) {
    if (!STAT_TYPES_WITHOUT_OCCUPIED_ROOMS.includes(statType)) {
      fields = {
        ...fields,
        [FORM_NAMES.occupied_rooms]: true,
      };
    }

    if (statType === STAT_TYPES.siciliaOCR) {
      fields = {
        ...fields,
        [FORM_NAMES.external_room_id]: true,
      };
    }

    if (statType === STAT_TYPES.toscanaRicestat) {
      fields = {
        ...fields,
        [FORM_NAMES.space]: false,
        [FORM_NAMES.external_room_id_option]: true,
      };
    }
  }

  return fields;
}

function getContractsDisplayFields(housingOption: HousingOption) {
  const isContractEnabled = housingOption?.data?.is_contract_enabled;
  const countryCode = getCountryCode(housingOption?.data);

  if (isContractEnabled) {
    if (countryCode === COUNTRY_CODES.italy) {
      return {
        [FORM_NAMES.price]: true,
        [FORM_NAMES.deposit]: true,
      };
    }
    return {
      [FORM_NAMES.price]: true,
    };
  }
  return {};
}

function getDepositDisplayFields(housingOption: HousingOption) {
  const depositStatus = housingOption?.data?.security_deposit_status;
  const isDepositEnabled =
    depositStatus && depositStatus !== SECURITY_DEPOSIT_STATUSES.inactive;

  return {
    [FORM_NAMES.deposit_amount]: isDepositEnabled,
  };
}

function getDisplayFields(
  housingOption: HousingOption,
  collaboratorsOptions?: SelectOption[],
) {
  const country = housingOption?.country;
  const isSpaceVisible = Boolean(housingOption?.data && housingOption?.data.rooms.length);
  const isResponsibleVisible = Boolean(
    collaboratorsOptions && collaboratorsOptions.length > 1,
  );
  const fields = {
    ...INIT_DISPLAY_FIELDS,
    ...getStatDisplayFields(housingOption),
    ...getContractsDisplayFields(housingOption),
    ...getDepositDisplayFields(housingOption),
    [FORM_NAMES.space]: isSpaceVisible,
    [FORM_NAMES.responsible]: isResponsibleVisible,
  };

  switch (country) {
    /*case COUNTRY_CODES.france:
    case COUNTRY_CODES.spain:
    case COUNTRY_CODES.uk: {
      return {
        ...fields,
        [FORM_NAMES.children]: true,
      };
    }*/
    case COUNTRY_CODES.dubai:
    case COUNTRY_CODES.italy:
    case COUNTRY_CODES.austria: {
      return {
        ...fields,
        [FORM_NAMES.type]: true,
      };
    }
    default: {
      return fields;
    }
  }
}

function getFields(housingOption: any = {}, collaboratorsOption?: SelectOption[]) {
  const display = getDisplayFields(housingOption, collaboratorsOption);
  const required = getRequiredFields(housingOption);

  return {display, required};
}

function fetchHousing(key: string, id = '') {
  return queryFetcher(api.housings.ENDPOINTS.one(id));
}

function fetchGuestGroup(key: string, id: string) {
  return queryFetcher(api.guestGroups.ENDPOINTS.all(id));
}

type LocationState = {
  formData?: FormTypes;
  checkInOnlineData?: {
    [key: string]: boolean;
  };
};

type ReservationInfoSectionProps = {
  disabled: boolean;
  isEditing?: boolean;
  setIsSectionTouched?: (isTouched: boolean) => void;
  reservation?: LightReservation;
};

const defaultProps: Partial<ReservationInfoSectionProps> = {
  disabled: false,
  isEditing: false,
  reservation: undefined,
};

function ReservationInfoSection({
  disabled,
  reservation,
  isEditing,
  setIsSectionTouched,
}: ReservationInfoSectionProps) {
  const {t} = useTranslation();
  const {
    control,
    register,
    errors,
    watch,
    triggerValidation,
    formState,
    getValues,
    setValue,
    reset,
    unregister,
  } = useFormContext<FormTypes>();
  const location = useLocation<LocationState>();
  const persistedFormData = location.state?.formData;
  const selectedHousing = watch(FORM_NAMES.housing);
  const selectedHousingCountry = watch(FORM_NAMES.housing)?.country;
  const selectedHousingId = selectedHousing?.value;
  const [groupTypes, setGroupTypes] = React.useState<SelectOption[]>(
    ALL_GROUP_TYPES_OPTIONS,
  );
  const [fields, setFields] = React.useState(() => {
    return getFields(selectedHousing);
  });
  const [isHousingPreloaded, setIsHousingPreloaded] = React.useState(false);
  const [isRoomPreloaded, setIsRoomPreloaded] = React.useState(false);
  const [focusedDateInput, setFocusedDateInput] = React.useState<
    'startDate' | 'endDate' | null
  >(null);
  const [minCheckInDate, setMinCheckInDate] = React.useState<Moment | null>(null);
  const [
    isPersistedFormStatePreloaded,
    setIsPersistedFormStatePreloaded,
  ] = React.useState(false);
  const {isFormTouched, setUntouchedValues} = useIsFormTouched({
    displayFields: fields.display,
    watch,
    defaultValues: {
      [FORM_NAMES.housing]: '',
      [FORM_NAMES.space]: '',
      [FORM_NAMES.responsible]: RESPONSIBLE_ME_OPTION,
      [FORM_NAMES.check_in_date]: '',
      [FORM_NAMES.check_out_date]: '',
      [FORM_NAMES.number_of_guests]: '',
      [FORM_NAMES.guest_leader_name]: '',
      [FORM_NAMES.default_phone_number]: '',
      [FORM_NAMES.number_of_nights]: '',
      [FORM_NAMES.children]: '',
      [FORM_NAMES.type]: '',
      [FORM_NAMES.price]: '',
      [FORM_NAMES.occupied_rooms]: '',
      [FORM_NAMES.external_room_id]: '',
      [FORM_NAMES.external_room_id_option]: '',
      [FORM_NAMES.deposit]: '',
    },
  });

  const numberOfGuests = watch(FORM_NAMES.number_of_guests);
  const numberOfNights = watch(FORM_NAMES.number_of_nights) || '';
  const children = watch(FORM_NAMES.children);
  const typeOfRegistration = watch(FORM_NAMES.type)?.value;
  const housingValue = watch(FORM_NAMES.housing)?.value;
  const housingCountry = getValues(FORM_NAMES.housing)?.country;

  const housingId = reservation?.housing_id;
  const {data: housing, error: housingError} = useQuery<Housing, [string, string]>(
    Boolean(housingId) && ['housing', housingId!],
    fetchHousing,
    {
      refetchOnWindowFocus: false,
    },
  );
  useErrorToast(housingError, {
    notFoundMessage: t('errors.requested_housings_not_found'),
  });

  const guestGroupId = reservation?.guest_group_id;
  const {data: guestGroup, error: guestGroupError} = useQuery<
    GuestGroup,
    [string, string]
  >(Boolean(guestGroupId) && ['guestGroup', guestGroupId!], fetchGuestGroup);
  useErrorToast(guestGroupError, {
    notFoundMessage: t('errors.requested_guest_groups_not_found'),
  });

  const {data: housings, error: housingsError} = useQuery(
    'shortHousings',
    fetchShortHousings,
  );
  useErrorToast(housingsError, {
    notFoundMessage: t('errors.requested_housings_not_found'),
  });

  const {data: collaborators, error: collaboratorsError} = useQuery(
    'collaborators',
    fetchCollaborators,
  );
  useErrorToast(collaboratorsError, {
    notFoundMessage: t('errors.requested_collaborators_not_found'),
  });
  const {data: rooms, error: roomsError} = useQuery('rooms', fetchRooms);
  useErrorToast(roomsError, {
    notFoundMessage: t('errors.requested_rooms_not_found'),
  });

  const availableHousingsCheckInDate = getAvailableHousingsCheckInDate(
    watch(FORM_NAMES.check_in_date),
    reservation,
  );
  const {data: availableHousings, error: availableHousingsError} = useQuery(
    isEditing && [
      'availableHousings',
      `available=1&check_in_date=${availableHousingsCheckInDate}&nights_of_stay=${numberOfNights}&fields=id,name`,
    ],
    fetchAvailableHousings,
  );
  useErrorToast(availableHousingsError, {
    notFoundMessage: t('errors.available_housings_not_found'),
  });

  const housingsOptions = React.useMemo(() => {
    return getShortHousingsAsOptions(housings);
  }, [housings]);
  const collaboratorsOptions = React.useMemo(() => {
    return getCollaboratorsAsOptions(collaborators);
  }, [collaborators]);

  React.useEffect(
    function updateFieldsOnCollaboratorsChange() {
      const nextFields = getFields(selectedHousing, collaboratorsOptions);
      setFields(nextFields);
    },
    [selectedHousing, collaboratorsOptions],
  );

  const roomsOptions = React.useMemo(() => {
    return getRoomsAsOptions(rooms, selectedHousingId);
  }, [rooms, selectedHousingId]);
  const availableHousingsOptions = React.useMemo(() => {
    return getAvailableHousingsAsOptions(availableHousings, reservation, housing);
  }, [availableHousings, housing, reservation]);

  React.useEffect(() => {
    if (typeof setIsSectionTouched === 'function') {
      setIsSectionTouched(isFormTouched);
    }
  }, [isFormTouched, setIsSectionTouched]);

  const spacesOptions = React.useMemo(() => {
    return getSpacesAsOptions(selectedHousing);
  }, [selectedHousing]);

  const checkInDisplayField = fields.display[FORM_NAMES.check_in_date];
  const checkInRequiredField = fields.required[FORM_NAMES.check_in_date];
  const checkOutDisplayField = fields.display[FORM_NAMES.check_out_date];
  const checkOutRequiredField = fields.required[FORM_NAMES.check_out_date];
  React.useEffect(
    function registerDates() {
      if (checkInDisplayField && checkOutDisplayField) {
        register(FORM_NAMES.check_in_date, {
          required: checkInRequiredField,
        });
        register(FORM_NAMES.check_out_date, {
          required: checkOutRequiredField,
        });
      } else {
        unregister([FORM_NAMES.check_in_date]);
        unregister([FORM_NAMES.check_out_date]);
      }
    },
    [
      register,
      checkInRequiredField,
      checkOutRequiredField,
      checkOutDisplayField,
      checkInDisplayField,
      unregister,
    ],
  );

  React.useEffect(
    function clearSpace() {
      setValue(FORM_NAMES.space, undefined);
    },
    [selectedHousing, setValue],
  );

  React.useEffect(() => {
    const minCheckInDate = getMinCheckInDate(housingCountry);
    setMinCheckInDate(minCheckInDate);
  }, [housingCountry]);

  React.useEffect(
    function preloadPersistedFormData() {
      const canPreloadOneTime =
        persistedFormData &&
        reservation &&
        !reservation?.manuallyUpdated &&
        !isPersistedFormStatePreloaded;

      if (canPreloadOneTime) {
        const timeZone = getTimezone(housing);

        reset({
          ...persistedFormData,
          [FORM_NAMES.check_in_date]: persistedFormData![FORM_NAMES.check_in_date]
            ? getTimezoneDate(persistedFormData![FORM_NAMES.check_in_date]!, timeZone)
            : undefined,
          [FORM_NAMES.check_out_date]: persistedFormData![FORM_NAMES.check_out_date]
            ? getTimezoneDate(persistedFormData![FORM_NAMES.check_out_date]!, timeZone)
            : undefined,
        });

        setIsPersistedFormStatePreloaded(true);
      }
    },
    [housing, reset, persistedFormData, reservation, isPersistedFormStatePreloaded],
  );

  React.useEffect(
    function preloadHousing() {
      if (!housing || !availableHousingsOptions.length || isHousingPreloaded) {
        return;
      }

      const nextHousing = availableHousingsOptions.find((h) => {
        return h.value === housing.id;
      });
      if (nextHousing) {
        if (!persistedFormData && !reservation?.manuallyUpdated) {
          setIsHousingPreloaded(true);
          setValue(FORM_NAMES.housing, nextHousing);
        }

        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.housing]: nextHousing,
          };
        });
      }
    },
    [
      reservation,
      availableHousingsOptions,
      setValue,
      isHousingPreloaded,
      persistedFormData,
      housing,
      setUntouchedValues,
    ],
  );

  React.useEffect(
    function preloadSpace() {
      if (persistedFormData || reservation?.manuallyUpdated) {
        return;
      }
      if (
        !spacesOptions.length ||
        isRoomPreloaded ||
        !reservation?.room_id ||
        !fields.display[FORM_NAMES.space]
      ) {
        return;
      }
      const nextRoom = spacesOptions.find((r) => {
        return r.value === reservation?.room_id;
      });
      if (nextRoom) {
        setIsRoomPreloaded(true);
        setValue(FORM_NAMES.space, nextRoom);

        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.space]: nextRoom,
          };
        });
      }
    },
    [
      reservation,
      spacesOptions,
      setValue,
      isRoomPreloaded,
      persistedFormData,
      fields,
      setUntouchedValues,
    ],
  );

  React.useEffect(
    function preloadData() {
      if (!reservation) {
        return;
      }

      const checkInDate = reservation?.check_in_date
        ? moment(formatDate(reservation?.check_in_date))
        : undefined;
      const checkOutDate = reservation?.check_out_date
        ? moment(formatDate(reservation.check_out_date))
        : undefined;
      const groupType = guestGroup?.type
        ? [...ALL_GROUP_TYPES_OPTIONS, TOURIST_GROUP_GROUP_TYPE_OPTION].find(
            (t) => t.value === guestGroup?.type,
          )
        : undefined;

      const formData = [
        {[FORM_NAMES.check_in_date]: checkInDate},
        {[FORM_NAMES.check_out_date]: checkOutDate},
        {[FORM_NAMES.type]: groupType},
        {[FORM_NAMES.price]: reservation?.price ? String(reservation?.price) : ''},
        {
          [FORM_NAMES.deposit]: reservation?.deposit ? String(reservation?.deposit) : '',
        },
        {
          [FORM_NAMES.number_of_guests]: guestGroup?.number_of_guests
            ? String(guestGroup?.number_of_guests)
            : '',
        },
        {
          [FORM_NAMES.number_of_nights]: reservation?.nights_of_stay
            ? String(reservation?.nights_of_stay)
            : '',
        },
        {
          [FORM_NAMES.guest_leader_name]: reservation?.default_leader_full_name,
        },
        {
          [FORM_NAMES.occupied_rooms]: reservation?.occupied_rooms_quantity
            ? String(reservation?.occupied_rooms_quantity)
            : '',
        },
        {
          [FORM_NAMES.default_phone_number]: reservation?.default_phone_number || '',
        },
      ];

      setUntouchedValues((prevState) => {
        let result: {[key: string]: any} = {};

        formData.forEach((data: any) => {
          const field = Object.keys(data)[0];
          result[field] = data[field];
        });

        return {
          ...prevState,
          ...result,
        };
      });

      if (!persistedFormData && !reservation?.manuallyUpdated) {
        setValue(formData);
      }
    },
    [
      reservation,
      setValue,
      isHousingPreloaded,
      persistedFormData,
      guestGroup,
      setUntouchedValues,
    ],
  );

  React.useEffect(
    function preloadResponsible() {
      if (
        !isHousingPreloaded ||
        !collaboratorsOptions.length ||
        persistedFormData ||
        reservation?.manuallyUpdated
      ) {
        return;
      }

      const responsible = collaboratorsOptions.find((c) => {
        return c.value === reservation?.assigned_to;
      });

      if (responsible) {
        setValue(FORM_NAMES.responsible, responsible);
        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.responsible]: responsible,
          };
        });
      } else {
        setValue(FORM_NAMES.responsible, RESPONSIBLE_ME_OPTION);
        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            [FORM_NAMES.responsible]: RESPONSIBLE_ME_OPTION,
          };
        });
      }
    },
    [
      isHousingPreloaded,
      collaboratorsOptions,
      reservation,
      setValue,
      persistedFormData,
      setUntouchedValues,
    ],
  );

  React.useEffect(
    function preloadRoomId() {
      if (
        !roomsOptions.length ||
        !isHousingPreloaded ||
        !reservation?.external_room_id ||
        persistedFormData ||
        reservation?.manuallyUpdated
      ) {
        return;
      }

      const roomId = roomsOptions.find((r) => {
        return r.value === reservation.external_room_id;
      });
      setValue(FORM_NAMES.external_room_id_option, roomId);
      setValue(FORM_NAMES.external_room_id, reservation.external_room_id);

      setUntouchedValues((prevState) => {
        return {
          ...prevState,
          [FORM_NAMES.external_room_id_option]: roomId,
          [FORM_NAMES.external_room_id]: reservation.external_room_id,
        };
      });
    },
    [
      isHousingPreloaded,
      reservation,
      roomsOptions,
      setValue,
      persistedFormData,
      setUntouchedValues,
    ],
  );

  React.useEffect(
    function updateFieldsOnHousingChange() {
      const selectedHousing = watch(FORM_NAMES.housing);
      if (!selectedHousing?.value) {
        return;
      }

      const nextFields = getFields(selectedHousing, collaboratorsOptions);
      setFields(nextFields);
    },
    [watch, collaboratorsOptions],
  );

  React.useEffect(
    function revalidateCheckInDateOnCountryChange() {
      if (formState.isSubmitted) {
        triggerValidation(FORM_NAMES.check_in_date);
      }
    },
    [triggerValidation, selectedHousingCountry, formState.isSubmitted],
  );

  React.useEffect(
    function revalidateTypeOfRegistrationOnChange() {
      if (formState.isSubmitted) {
        triggerValidation(FORM_NAMES.type);
      }
    },
    [formState.isSubmitted, triggerValidation, typeOfRegistration],
  );

  React.useEffect(
    function updateGroupTypesOnNumberOfGuestsChange() {
      let defaultAllGroupTypes = [...ALL_GROUP_TYPES_OPTIONS];

      if (housingCountry === COUNTRY_CODES.austria) {
        defaultAllGroupTypes.push(TOURIST_GROUP_GROUP_TYPE_OPTION);
      }
      if (numberOfGuests === undefined || numberOfGuests === '') {
        setGroupTypes(defaultAllGroupTypes);
        return;
      }

      let totalGuests =
        children !== undefined
          ? Number(numberOfGuests) - Number(children)
          : Number(numberOfGuests);
      totalGuests = totalGuests < 0 ? 0 : totalGuests;

      if (totalGuests > 1) {
        if (housingCountry === COUNTRY_CODES.austria) {
          setGroupTypes([...GROUP_GROUP_TYPES_OPTIONS, TOURIST_GROUP_GROUP_TYPE_OPTION]);
        } else {
          setGroupTypes(GROUP_GROUP_TYPES_OPTIONS);
        }
        return;
      }

      if (totalGuests > 0) {
        setGroupTypes(SINGLE_GROUP_TYPES_OPTIONS);
      }
    },
    [numberOfGuests, children, housingCountry],
  );

  React.useEffect(
    function keepCorrectRegistrationType() {
      if (!typeOfRegistration || !groupTypes.length) {
        return;
      }

      const hasSelectedGroupTypeOption = groupTypes.find(
        (g) => g?.value === typeOfRegistration,
      );
      if (!hasSelectedGroupTypeOption) {
        setValue(FORM_NAMES.type, undefined);
      }
    },
    [typeOfRegistration, groupTypes, setValue, triggerValidation],
  );

  React.useEffect(
    function keepCorrectAvailableHousing() {
      if (
        !isEditing ||
        !housingValue ||
        !availableHousingsOptions.length ||
        availableHousings === undefined
      ) {
        return;
      }

      const hasHousing = availableHousingsOptions.find((h) => {
        return h.value === housingValue;
      });
      if (!hasHousing) {
        setValue(FORM_NAMES.housing, undefined);
      }
    },
    [availableHousingsOptions, housingValue, setValue, isEditing, availableHousings],
  );

  const isDepositAmountVisible = fields.display[FORM_NAMES.deposit_amount];
  React.useEffect(
    function preloadReservationDepositAmount() {
      if (!isDepositAmountVisible || !isEditing) {
        return;
      }

      const reservationDepositAmount = reservation?.security_deposit_amount || '';
      setValue(FORM_NAMES.deposit_amount, reservationDepositAmount);
      setUntouchedValues((prevState) => {
        return {
          ...prevState,
          [FORM_NAMES.deposit_amount]: reservationDepositAmount,
        };
      });
    },
    [
      isHousingPreloaded,
      isDepositAmountVisible,
      reservation,
      setUntouchedValues,
      setValue,
      isEditing,
    ],
  );

  const handleHousingChange = (option: SelectOption) => {
    const housingDepositAmount =
      option?.data?.security_deposit_amount || MIN_DEPOSIT_AMOUNT?.toFixed(2);
    setValue(FORM_NAMES.deposit_amount, housingDepositAmount);

    return option;
  };

  return (
    <Section title={t('booking_information')}>
      <Layout>
        {fields.display[FORM_NAMES.housing] && (
          <div>
            <Controller
              as={<Select />}
              control={control}
              rules={{required: fields.required[FORM_NAMES.housing]}}
              onChange={([option]) => handleHousingChange(option)}
              label={getRequiredOrOptionalFieldLabel(
                t('property'),
                fields.required[FORM_NAMES.housing],
              )}
              name={FORM_NAMES.housing}
              options={isEditing ? availableHousingsOptions : housingsOptions}
              error={(errors[FORM_NAMES.housing] as any)?.message}
              disabled={disabled}
              placeholder={t('select_your_property')}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.space] && (
          <div>
            <Controller
              as={<Select />}
              control={control}
              rules={{required: fields.required[FORM_NAMES.space]}}
              label={getRequiredOrOptionalFieldLabel(
                t('space'),
                fields.required[FORM_NAMES.space],
              )}
              name={FORM_NAMES.space}
              options={spacesOptions}
              error={(errors[FORM_NAMES.space] as any)?.message}
              disabled={disabled}
              placeholder={t('select_your_space')}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.responsible] && (
          <div>
            <Controller
              as={<Select />}
              control={control}
              rules={{required: fields.required[FORM_NAMES.responsible]}}
              label={getRequiredOrOptionalFieldLabel(
                t('responsible'),
                fields.required[FORM_NAMES.responsible],
              )}
              name={FORM_NAMES.responsible}
              options={collaboratorsOptions}
              defaultValue={RESPONSIBLE_ME_OPTION}
              error={(errors[FORM_NAMES.responsible] as any)?.message}
              disabled={disabled}
              placeholder={t('select_your_responsible')}
            />
          </div>
        )}
        {(fields.display[FORM_NAMES.check_in_date] ||
          fields.display[FORM_NAMES.check_out_date]) && (
          <div>
            <DateRangePicker
              startDate={watch(FORM_NAMES.check_in_date) || null}
              startDateId="check-in-date"
              endDate={watch(FORM_NAMES.check_out_date) || null}
              endDateId="check-out-date"
              focusedInput={focusedDateInput}
              disabled={disabled}
              isOutsideRange={(day) => {
                return day.isBefore(minCheckInDate);
              }}
              label={t('check_in_check_out')}
              error={
                errors[FORM_NAMES.check_in_date]?.message ||
                errors[FORM_NAMES.check_out_date]?.message
              }
              onFocusChange={(focusedInput) => setFocusedDateInput(focusedInput)}
              onDatesChange={({startDate, endDate}) => {
                if (startDate && moment(startDate).isBefore(minCheckInDate)) {
                  setValue(FORM_NAMES.check_in_date, null);
                } else {
                  setValue(FORM_NAMES.check_in_date, startDate);
                }

                if (endDate && moment(endDate).isBefore(minCheckInDate)) {
                  setValue(FORM_NAMES.check_out_date, null);
                } else {
                  setValue(FORM_NAMES.check_out_date, endDate);
                }

                if (formState.isSubmitted) {
                  triggerValidation([
                    FORM_NAMES.check_in_date,
                    FORM_NAMES.check_out_date,
                  ]);
                }
              }}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.number_of_nights] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.number_of_nights],
                min: {
                  value: MIN_NUMBER_OF_NIGHTS,
                  message: t('min_number_is', {number: MIN_NUMBER_OF_NIGHTS}),
                },
              })}
              name={FORM_NAMES.number_of_nights}
              type="number"
              label={getRequiredOrOptionalFieldLabel(
                t('number_of_nights'),
                fields.required[FORM_NAMES.number_of_nights],
              )}
              inputMode="numeric"
              placeholder={t('enter_number')}
              error={errors[FORM_NAMES.number_of_nights]?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.number_of_guests] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.number_of_guests],
                min: {
                  value: MIN_NUMBER_OF_GUESTS,
                  message: t('min_number_is', {number: MIN_NUMBER_OF_GUESTS}),
                },
                max: {
                  value: MAX_NUMBER_OF_GUESTS,
                  message: t('max_number_is', {number: MAX_NUMBER_OF_GUESTS}),
                },
              })}
              name={FORM_NAMES.number_of_guests}
              type="number"
              inputMode="numeric"
              label={getRequiredOrOptionalFieldLabel(
                t('guests_to_register'),
                fields.required[FORM_NAMES.number_of_guests],
              )}
              placeholder={t('enter_number')}
              error={errors[FORM_NAMES.number_of_guests]?.message}
              disabled={disabled}
            />
          </div>
        )}
        {/*fields.display[FORM_NAMES.children] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.children],
                min: {
                  value: MIN_NUMBER_OF_CHILDREN,
                  message: t('min_number_is', {number: MIN_NUMBER_OF_CHILDREN}),
                },
              })}
              name={FORM_NAMES.children}
              type="number"
              label={getRequiredOrOptionalFieldLabel(
                t('children_under_number', {
                  number: getMinimumChildrenRestrictionAge(selectedHousingCountry),
                }),
                fields.required[FORM_NAMES.children],
              )}
              placeholder={t('enter_number')}
              error={(errors[FORM_NAMES.children] as any)?.message}
              disabled={disabled}
            />
          </div>
        )*/}
        {fields.display[FORM_NAMES.price] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.price],
                min: {
                  value: MIN_PRICE,
                  message: t('min_number_is', {number: MIN_PRICE}),
                },
              })}
              name={FORM_NAMES.price}
              type="number"
              label={getRequiredOrOptionalFieldLabel(
                t('price'),
                fields.required[FORM_NAMES.price],
              )}
              step="0.01"
              inputMode="decimal"
              placeholder={t('enter_number')}
              error={(errors[FORM_NAMES.price] as any)?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.deposit] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.deposit],
                min: {
                  value: MIN_PRICE,
                  message: t('min_number_is', {number: MIN_PRICE}),
                },
              })}
              name={FORM_NAMES.deposit}
              type="number"
              label={getRequiredOrOptionalFieldLabel(
                t('deposit'),
                fields.required[FORM_NAMES.deposit],
              )}
              step="0.01"
              inputMode="decimal"
              placeholder={t('enter_number')}
              error={(errors[FORM_NAMES.deposit] as any)?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.occupied_rooms] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.occupied_rooms],
                min: {
                  value: MIN_OCCUPIED_ROOMS,
                  message: t('min_number_is', {number: MIN_OCCUPIED_ROOMS}),
                },
              })}
              name={FORM_NAMES.occupied_rooms}
              type="number"
              label={getRequiredOrOptionalFieldLabel(
                t('occupied_rooms'),
                fields.required[FORM_NAMES.occupied_rooms],
              )}
              inputMode="numeric"
              placeholder={t('enter_number')}
              error={(errors[FORM_NAMES.occupied_rooms] as any)?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.type] && (
          <div>
            <Controller
              as={<Select />}
              control={control}
              rules={{
                required: fields.required[FORM_NAMES.type],
              }}
              label={getRequiredOrOptionalFieldLabel(
                t('type_of_registration'),
                fields.required[FORM_NAMES.type],
              )}
              name={FORM_NAMES.type}
              options={groupTypes}
              error={(errors[FORM_NAMES.type] as any)?.message}
              disabled={disabled}
              placeholder={t('select_your_type_of_registration')}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.external_room_id] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.external_room_id],
              })}
              name={FORM_NAMES.external_room_id}
              label={getRequiredOrOptionalFieldLabel(
                t('room_id'),
                fields.required[FORM_NAMES.external_room_id],
              )}
              placeholder={t('enter_number')}
              error={(errors[FORM_NAMES.external_room_id] as any)?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.external_room_id_option] && (
          <div>
            <Controller
              as={<Select />}
              control={control}
              rules={{
                required: fields.required[FORM_NAMES.external_room_id_option],
              }}
              label={getRequiredOrOptionalFieldLabel(
                t('room_id'),
                fields.required[FORM_NAMES.external_room_id_option],
              )}
              name={FORM_NAMES.external_room_id_option}
              options={roomsOptions}
              error={(errors[FORM_NAMES.external_room_id_option] as any)?.message}
              disabled={disabled}
              placeholder={t('select_your_room')}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.guest_leader_name] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.guest_leader_name],
              })}
              name={FORM_NAMES.guest_leader_name}
              label={getRequiredOrOptionalFieldLabel(
                t('guest_leader_name'),
                fields.required[FORM_NAMES.guest_leader_name],
              )}
              placeholder={t('enter_name')}
              error={errors[FORM_NAMES.guest_leader_name]?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.deposit_amount] && (
          <div>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.deposit_amount],
                min: {
                  value: MIN_DEPOSIT_AMOUNT,
                  message: t('min_number_is', {number: MIN_DEPOSIT_AMOUNT}),
                },
                max: {
                  value: MAX_DEPOSIT_AMOUNT,
                  message: t('max_number_is', {number: MAX_DEPOSIT_AMOUNT}),
                },
              })}
              name={FORM_NAMES.deposit_amount}
              label={getRequiredOrOptionalFieldLabel(
                t('deposit_amount'),
                fields.required[FORM_NAMES.deposit_amount],
              )}
              type="number"
              step="0.01"
              inputMode="decimal"
              placeholder={t('enter_amount')}
              defaultValue={selectedHousing?.data?.security_deposit_amount}
              error={errors[FORM_NAMES.deposit_amount]?.message}
              disabled={disabled}
            />
          </div>
        )}
        {fields.display[FORM_NAMES.default_phone_number] && (
          <div>
            <Controller
              control={control}
              as={<PhoneInput />}
              label={getRequiredOrOptionalFieldLabel(
                t('phone_number'),
                fields.required[FORM_NAMES.default_phone_number],
              )}
              placeholder={t('enter_your_phone_number')}
              defaultCode={reservation?.default_phone_number_details?.code}
              defaultInputValue={reservation?.default_phone_number_details?.number}
              name={FORM_NAMES.default_phone_number}
              error={errors[FORM_NAMES.default_phone_number]?.message}
              rules={{
                required: fields.required[FORM_NAMES.default_phone_number],
              }}
              disabled={disabled}
            />
          </div>
        )}
      </Layout>
    </Section>
  );
}

ReservationInfoSection.defaultProps = defaultProps;
export {ReservationInfoSection};
