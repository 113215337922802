import styled from 'styled-components';

export const Content = styled.div``;

export const TooltipContentItem = styled.div`
  font-size: 16px;
  font-family: ProximaNova-Light, sans-serif;
  margin-bottom: 15px;
`;

export const RoomsCountInputWrapper = styled.div`
  margin: 30px 0;
`;

export const RoomsListHeader = styled.div`
  margin-bottom: 30px;
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 16px;
  color: #161643;
`;

export const RoomsList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(auto-fill, minmax(47px, 77px));
  grid-auto-columns: min-content;
  max-height: 231px;

  & > :nth-child(n) {
    padding-bottom: 30px;
    padding-right: 64px;
  }

  & > :nth-child(3n) {
    height: 48px;
    padding-bottom: 0;
  }

  & > :nth-child(n + 4) {
    padding-left: 64px;
  }

  & > :first-child:nth-last-child(n + 4) {
    & ~ :nth-child(2),
    & ~ :nth-child(3) {
      border-right: 1px solid rgba(22, 22, 67, 0.12);
    }
    border-right: 1px solid rgba(22, 22, 67, 0.12);
  }

  & > :first-child:nth-last-child(n + 7) {
    & ~ :nth-child(4),
    & ~ :nth-child(5),
    & ~ :nth-child(6) {
      border-right: 1px solid rgba(22, 22, 67, 0.12);
    }
  }
`;
