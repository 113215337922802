import React from 'react';
import {default as Content} from '../components/onboarding/PmsCloudbedsOriginRegister';

function PmsCloudbedsOriginRegister() {
  return (
    <>
      <Content />
    </>
  );
}

export {PmsCloudbedsOriginRegister};
