import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18n from '../../../i18n';
import {useUser} from '../../../context/user';
import {useAuth} from '../../../context/auth';
import {useOpenModals} from '../../../context/openModals';
import chekinWhiteLogo from '../../../assets/chekin-logo-white.svg';
import helpIcon from '../../../assets/icon-help.svg';
import moreIcon from '../../../assets/icon-more.svg';
import moreActiveIcon from '../../../assets/icon-more-active.svg';
import userIcon from '../../../assets/icon-user.svg';
import userActiveIcon from '../../../assets/icon-user-active.svg';
import helpActiveIcon from '../../../assets/icon-help-active.svg';
import HeaderItem from '../HeaderItem';
import {
  HeaderWrapper,
  LogoWrapper,
  LogoImage,
  UserMenuWrapper,
  HeaderNavigationMenuWrapper,
  HeaderNavigationMenuItem,
  UserMenuItemText,
  MoreImg,
  UserMenuItemImg,
  HeaderNavigationMenuItemWrapper,
} from './styled';
import {useSubscription} from '../../../context/subscription';
import {getIsCollaborator, getMenuOptions, MenuItemsType} from '../../../utils/common';
import ImportStatusTile from '../ImportStatusTile';
import {openHubspotChat} from '../../../analytics/hubspot';

const USER_MENU_OPTIONS: MenuItemsType = {
  account: {
    label: i18n.t('account'),
    value: '/account',
    baseUrl: '/account',
  },
  billing: {
    label: i18n.t('billing'),
    value: '/billing',
    baseUrl: '/billing',
  },
  marketplace: {
    label: i18n.t('marketplace'),
    value: '/marketplace/access-providers',
    baseUrl: '/marketplace/access-providers',
  },
  logout: {
    label: i18n.t('logout'),
    value: '/logout',
    baseUrl: '/logout',
  },
};

const USER_MENU_OPTIONS_WITHOUT_BILLING: MenuItemsType = {
  account: {
    label: i18n.t('account'),
    value: '/account',
    baseUrl: '/account',
  },
  marketplace: {
    label: i18n.t('marketplace'),
    value: '/marketplace/access-providers',
    baseUrl: '/marketplace/access-providers',
  },
  logout: {
    label: i18n.t('logout'),
    value: '/logout',
    baseUrl: '/logout',
  },
};

const HELP_MENU_OPTIONS: MenuItemsType = {
  helpNow: {
    label: i18n.t('get_help_now'),
    value: '/help-now',
    baseUrl: '/help-now',
  },
  helpCenter: {
    label: i18n.t('help_center'),
    value: '/help-center',
    baseUrl: 'https://supportchekin.zendesk.com/hc/en-us',
  },
};

const NAVIGATOR_LINKS: MenuItemsType = {
  bookings: {
    label: i18n.t('bookings'),
    value: '/bookings',
    baseUrl: '/bookings',
  },
  properties: {
    label: i18n.t('properties'),
    value: '/properties',
    baseUrl: '/properties',
  },
  documents: {
    label: i18n.t('documents_text'),
    value: '/documents/entry-form',
    baseUrl: '/documents',
  },
};

function Header() {
  const {t} = useTranslation();
  const location = useLocation();
  const {logout} = useAuth();
  const user = useUser();
  const history = useHistory();
  const openModals = useOpenModals();
  const {isSubscriptionRequired} = useSubscription();
  const [userMenuOptions, setUserMenuOptions] = React.useState<MenuItemsType>(
    USER_MENU_OPTIONS_WITHOUT_BILLING,
  );
  const logo = user?.picture?.src || chekinWhiteLogo;

  React.useEffect(() => {
    if (
      user?.is_integrated ||
      getIsCollaborator(user?.groups) ||
      !isSubscriptionRequired
    ) {
      setUserMenuOptions(USER_MENU_OPTIONS_WITHOUT_BILLING);
    } else {
      setUserMenuOptions(USER_MENU_OPTIONS);
    }
  }, [user, isSubscriptionRequired]);

  React.useEffect(() => {
    if (
      user?.import_status === 'WAITING_FOR_MAPPING' &&
      location.pathname !== '/properties/connect'
    ) {
      history.push('/properties/connect');
    }
  }, [user, history, location]);

  const handleUserItemClick = (value = '') => {
    if (value === USER_MENU_OPTIONS.logout.value) {
      logout();
    } else if (openModals.shouldDoYouWantToSaveModalOpen) {
      openModals.updateLinkToGo(value);
      openModals.openDoYouWantToSaveModal();
      return;
    }

    if (value === USER_MENU_OPTIONS.account.value) {
      history.push(USER_MENU_OPTIONS.account.baseUrl);
    }
    if (value === USER_MENU_OPTIONS.billing.value) {
      history.push(value);
    }
    if (value === USER_MENU_OPTIONS.marketplace.value) {
      history.push(value);
    }
  };
  const handleHelpItemClick = (value = '') => {
    if (value === HELP_MENU_OPTIONS.helpCenter.value) {
      window.open(HELP_MENU_OPTIONS.helpCenter.baseUrl, '_blank');
    }

    if (value === HELP_MENU_OPTIONS.helpNow.value) {
      openHubspotChat();
    }
  };

  const handleMenuItemClick = (value = '') => {
    if (openModals.shouldDoYouWantToSaveModalOpen) {
      openModals.updateLinkToGo(value);
      openModals.openDoYouWantToSaveModal();
      return;
    }

    history.push(value);
  };

  return (
    <>
      <HeaderWrapper>
        <LogoWrapper>
          <LogoImage src={logo} alt="Logo" />
        </LogoWrapper>
        <UserMenuWrapper>
          {/*<HeaderItem*/}
          {/*  onMenuItemClick={() => {}}*/}
          {/*  menuOptions={[]}*/}
          {/*  icon={<UserMenuItemImg src={notificationIcon} alt="Bell" />}*/}
          {/*  activeIcon={*/}
          {/*    <UserMenuItemImg src={notificationActiveIcon} alt="Bell on background" />*/}
          {/*  }*/}
          {/*/>*/}
          <HeaderItem
            onMenuItemClick={handleHelpItemClick}
            menuOptions={getMenuOptions(HELP_MENU_OPTIONS)}
            label={<UserMenuItemText>{t('help')}</UserMenuItemText>}
            icon={<UserMenuItemImg src={helpIcon} alt="Help" />}
            activeIcon={<UserMenuItemImg src={helpActiveIcon} alt="Help on background" />}
          />
          <HeaderItem
            onMenuItemClick={handleUserItemClick}
            menuOptions={getMenuOptions(userMenuOptions)}
            label={<UserMenuItemText>{user?.name}</UserMenuItemText>}
            icon={<UserMenuItemImg src={userIcon} alt="User" />}
            activeIcon={<UserMenuItemImg src={userActiveIcon} alt="User on background" />}
            secondaryIcon={<MoreImg src={moreIcon} alt="Three dots" />}
            activeSecondaryIcon={<MoreImg src={moreActiveIcon} alt="Three dots" />}
          />
        </UserMenuWrapper>
        <HeaderNavigationMenuWrapper>
          {Object.values(NAVIGATOR_LINKS).map((link, index) => {
            return (
              <HeaderNavigationMenuItem
                key={index}
                onClick={() => {
                  handleMenuItemClick(link.value);
                }}
              >
                <HeaderNavigationMenuItemWrapper
                  active={location.pathname.startsWith(link.baseUrl)}
                >
                  {link.label}
                </HeaderNavigationMenuItemWrapper>
              </HeaderNavigationMenuItem>
            );
          })}
        </HeaderNavigationMenuWrapper>
      </HeaderWrapper>
      <ImportStatusTile />
    </>
  );
}

export {Header};
