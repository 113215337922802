import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {
  Wrapper,
  HeaderWrapper,
  Content,
  ContentItem,
  ProviderLogo,
  ProviderLogoWrapper,
  ProviderDescription,
  AccessProviderForm,
  FormHeader,
  SingleFieldWrapper,
  SubmitButtonWrapper,
  SubmitButton,
  ProviderDescriptionLink,
} from './styled';
import alikesIcon from '../../../assets/akiles-logo.png';
import keycafeIcon from '../../../assets/keycafe-logo.png';
import keynestIcon from '../../../assets/keynest-logo.png';
import nukiIcon from '../../../assets/nuki-logo.png';
import omnitecIcon from '../../../assets/omnitec-logo.svg';
import homeitIcon from '../../../assets/homeit-logo.png';
import BackButton from '../BackButton';
import i18n from '../../../i18n';
import api from '../../../api';
import {useHistory} from 'react-router-dom';
import {LOCK_VENDOR_OPTIONS, LOCK_VENDORS} from '../../../utils/constants';
import {useIsMounted, useStatus} from '../../../utils/hooks';
import {getRequiredOrOptionalFieldLabel, toastResponseError} from '../../../utils/common';
import Input from '../Input';
import Loader from '../../common/Loader';
import {toast} from 'react-toastify';

const AKILES_OAUTH_URL = 'https://auth.akiles.app/oauth2/auth';
const ALIKES_CLIENT_ID = process.env.REACT_APP_AKILES_CLIENT_ID;
const AKILES_REDIRECT_URL = process.env.REACT_APP_AKILES_REDIRECT_URL;

const OMNITEC_WEBSITE = 'https://www.omnitecsystems.com/';
const AKILES_WEBSITE = 'https://akiles.app/';
const KEYNEST_WEBSITE = 'https://keynest.com/';
const NUKI_WEBSITE = 'https://nuki.io/';
const HOMEIT_WEBSITE = 'https://homeit.io/';

type AccessProviderConnectProps = {
  type: string;
};

enum FORM_NAMES {
  apiKey = 'token',
  accountName = 'account_name',
  username = 'username',
  email = 'email',
  password = 'password',
  oauthCode = 'oauth_code',
  oauthState = 'oauth_state',
}

type FormTypes = {
  [FORM_NAMES.apiKey]: string;
  [FORM_NAMES.accountName]: string;
  [FORM_NAMES.username]: string;
  [FORM_NAMES.email]: string;
  [FORM_NAMES.password]: string;
  [FORM_NAMES.oauthCode]: string;
  [FORM_NAMES.oauthState]: string;
};

const INIT_DISPLAY_FIELDS: {[key: string]: boolean} = {
  [FORM_NAMES.accountName]: true,
  [FORM_NAMES.apiKey]: false,
  [FORM_NAMES.username]: false,
  [FORM_NAMES.email]: false,
  [FORM_NAMES.password]: false,
  [FORM_NAMES.oauthCode]: false,
  [FORM_NAMES.oauthState]: false,
};

function getDisplayFields(provider: string) {
  switch (provider) {
    case LOCK_VENDORS.nuki:
    case LOCK_VENDORS.homeit:
    case LOCK_VENDORS.keynest: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.apiKey]: true,
      };
    }
    case LOCK_VENDORS.omnitec: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.username]: true,
        [FORM_NAMES.password]: true,
      };
    }
    case LOCK_VENDORS.keyless:
    case LOCK_VENDORS.keycafe: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.email]: true,
        [FORM_NAMES.apiKey]: true,
      };
    }
    default: {
      return INIT_DISPLAY_FIELDS;
    }
  }
}

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.accountName]: false,
  [FORM_NAMES.apiKey]: i18n.t('required'),
  [FORM_NAMES.username]: i18n.t('required'),
  [FORM_NAMES.email]: i18n.t('required'),
  [FORM_NAMES.password]: i18n.t('required'),
  [FORM_NAMES.oauthCode]: i18n.t('required'),
  [FORM_NAMES.oauthState]: i18n.t('required'),
};

function getRequiredFields() {
  return INIT_REQUIRED_FIELDS;
}

function getFields(provider: string) {
  const display = getDisplayFields(provider);
  const required = getRequiredFields();

  return {display, required};
}

const OMNITEC_DESCRIPTION = (
  <>
    <ProviderDescription>
      {i18n.t('remote_access_to_the_property')}
      <ProviderDescriptionLink target="_blank" href={OMNITEC_WEBSITE}>
        ({OMNITEC_WEBSITE})
      </ProviderDescriptionLink>
    </ProviderDescription>
  </>
);

const KEYNEST_DESCRIPTION = (
  <>
    <ProviderDescription>
      {i18n.t('simple_and_secure_key_exchange')}
      <ProviderDescriptionLink target="_blank" href={KEYNEST_WEBSITE}>
        ({KEYNEST_WEBSITE})
      </ProviderDescriptionLink>
    </ProviderDescription>
  </>
);

const AKILES_DESCRIPTION = (
  <>
    <ProviderDescription>
      {i18n.t('smart_lock_system_connected')}
      <ProviderDescriptionLink target="_blank" href={AKILES_WEBSITE}>
        ({AKILES_WEBSITE})
      </ProviderDescriptionLink>
    </ProviderDescription>
  </>
);

const KEYCAFE_DESCRIPTION = (
  <>
    <ProviderDescription>{i18n.t('key_management_system_with')}</ProviderDescription>
  </>
);

const NUKI_DESCRIPTION = (
  <>
    <ProviderDescription>
      {i18n.t('nuki_keyless_access')}
      <ProviderDescriptionLink target="_blank" href={NUKI_WEBSITE}>
        ({NUKI_WEBSITE})
      </ProviderDescriptionLink>
    </ProviderDescription>
  </>
);

const HOMEIT_DESCRIPTION = (
  <>
    <ProviderDescription>
      {i18n.t('a_multi_sided_platform')}
      <ProviderDescriptionLink target="_blank" href={HOMEIT_WEBSITE}>
        ({HOMEIT_WEBSITE})
      </ProviderDescriptionLink>
    </ProviderDescription>
  </>
);

const LOGOS: {[key: string]: string} = {
  [LOCK_VENDORS.omnitec]: omnitecIcon,
  [LOCK_VENDORS.keynest]: keynestIcon,
  [LOCK_VENDORS.akiles]: alikesIcon,
  [LOCK_VENDORS.keycafe]: keycafeIcon,
  [LOCK_VENDORS.nuki]: nukiIcon,
  [LOCK_VENDORS.homeit]: homeitIcon,
};

const PROVIDERS_DESCRIPTIONS: {[key: string]: JSX.Element} = {
  [LOCK_VENDORS.omnitec]: OMNITEC_DESCRIPTION,
  [LOCK_VENDORS.keynest]: KEYNEST_DESCRIPTION,
  [LOCK_VENDORS.akiles]: AKILES_DESCRIPTION,
  [LOCK_VENDORS.keycafe]: KEYCAFE_DESCRIPTION,
  [LOCK_VENDORS.nuki]: NUKI_DESCRIPTION,
  [LOCK_VENDORS.homeit]: HOMEIT_DESCRIPTION,
};

function AccessProviderConnect({type}: AccessProviderConnectProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const {isLoading, setStatus} = useStatus();
  const [fields] = React.useState(() => {
    return getFields(type);
  });
  const {handleSubmit, register, errors} = useForm<FormTypes>();

  const goBack = React.useCallback(() => {
    history.push('/marketplace/access-providers');
  }, [history]);

  const getLockUserPayload = React.useCallback(
    (formData: Partial<FormTypes> | object) => {
      return {
        ...formData,
        vendor: type,
      };
    },
    [type],
  );

  const createLockUser = React.useCallback(
    async (formData: Partial<FormTypes> | object) => {
      const payload = getLockUserPayload(formData);
      const {error} = await api.locks.createLockUser(payload);

      if (!isMounted.current) {
        return;
      }

      setStatus('idle');

      if (error) {
        toastResponseError(error);
      } else {
        toast.success(t('connected'));
        goBack();
      }
    },
    [getLockUserPayload, goBack, isMounted, setStatus, t],
  );

  React.useEffect(() => {
    async function checkUrl() {
      setStatus('loading');
      if (type === LOCK_VENDORS.akiles) {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');
        const state = url.searchParams.get('state');
        if (code && state) {
          const dataToCreateLockUser = {
            account_name: state ? state.split(',')[1] : '',
            oauth_code: code,
          };
          return await createLockUser(dataToCreateLockUser);
        }
      }
      setStatus('idle');
    }

    checkUrl();
  }, [setStatus, history, createLockUser, type]);

  const redirectToOauth = (accountName?: string) => {
    const redirectUrl = `${AKILES_OAUTH_URL}?client_id=${ALIKES_CLIENT_ID}&redirect_url=${AKILES_REDIRECT_URL}&response_type=code&scope=full_read_write offline&state=chekin_state,${accountName}`;
    window.location.replace(redirectUrl);
  };

  const onSubmit = async (formData: Partial<FormTypes>) => {
    setStatus('loading');

    if (type === LOCK_VENDORS.akiles) {
      redirectToOauth(formData.account_name);
      return;
    }

    await createLockUser(formData);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <BackButton onClick={goBack} />
      </HeaderWrapper>
      <Content>
        <ContentItem>
          <ProviderLogoWrapper>
            <ProviderLogo src={LOGOS[type]} />
          </ProviderLogoWrapper>
          {PROVIDERS_DESCRIPTIONS[type]}
        </ContentItem>
        <ContentItem>
          <AccessProviderForm onSubmit={handleSubmit(onSubmit)}>
            <FormHeader>
              {t('lets_connect_with_')}{' '}
              {LOCK_VENDOR_OPTIONS[type as keyof typeof LOCK_VENDOR_OPTIONS].label}
            </FormHeader>
            {fields.display[FORM_NAMES.apiKey] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.apiKey],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('api_key'),
                    fields.required[FORM_NAMES.apiKey],
                  )}
                  placeholder={t('enter_key')}
                  name={FORM_NAMES.apiKey}
                  error={errors[FORM_NAMES.apiKey]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            {fields.display[FORM_NAMES.username] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.username],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('username'),
                    fields.required[FORM_NAMES.username],
                  )}
                  placeholder={t('enter_username')}
                  name={FORM_NAMES.username}
                  error={errors[FORM_NAMES.username]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            {fields.display[FORM_NAMES.email] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.email],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('email'),
                    fields.required[FORM_NAMES.email],
                  )}
                  placeholder={t('enter_email')}
                  name={FORM_NAMES.email}
                  error={errors[FORM_NAMES.email]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            {fields.display[FORM_NAMES.password] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.password],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('password'),
                    fields.required[FORM_NAMES.password],
                  )}
                  type="password"
                  placeholder={t('enter_password')}
                  name={FORM_NAMES.password}
                  error={errors[FORM_NAMES.password]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            {fields.display[FORM_NAMES.oauthState] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.oauthState],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('email'),
                    fields.required[FORM_NAMES.oauthState],
                  )}
                  placeholder={t('enter_email')}
                  name={FORM_NAMES.oauthState}
                  error={errors[FORM_NAMES.oauthState]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            {fields.display[FORM_NAMES.oauthCode] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.oauthCode],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('oauth_code'),
                    fields.required[FORM_NAMES.oauthCode],
                  )}
                  placeholder={t('enter_code')}
                  name={FORM_NAMES.oauthCode}
                  error={errors[FORM_NAMES.oauthCode]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            {fields.display[FORM_NAMES.accountName] && (
              <SingleFieldWrapper>
                <Input
                  ref={register({
                    required: fields.required[FORM_NAMES.accountName],
                  })}
                  label={getRequiredOrOptionalFieldLabel(
                    t('account_name'),
                    fields.required[FORM_NAMES.accountName],
                  )}
                  placeholder={t('enter_name')}
                  name={FORM_NAMES.accountName}
                  error={errors[FORM_NAMES.accountName]?.message}
                  disabled={isLoading}
                />
              </SingleFieldWrapper>
            )}
            <SubmitButtonWrapper>
              {isLoading ? (
                <Loader height={37} width={37} />
              ) : (
                <SubmitButton type={'submit'} label={t('connect')} />
              )}
            </SubmitButtonWrapper>
          </AccessProviderForm>
        </ContentItem>
      </Content>
    </Wrapper>
  );
}

export {AccessProviderConnect};
