import styled from 'styled-components';
import BaseButton from '../Button';

export const Grid = styled.main`
  margin-top: 20px;
  display: grid;
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 16px;
  color: #161643;
  grid-template-rows: minmax(31px, auto);
  align-items: center;
  grid-row-gap: 23px;

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const CopyButton = styled(BaseButton)`
  min-width: 124px;
  height: 31px;
  white-space: nowrap;
  margin-right: 23px;
  cursor: copy;

  &:active {
    border-color: #1a8cff;
  }
`;

export const OpenButton = styled(CopyButton)`
  cursor: pointer;
`;

export const KeyName = styled.div`
  margin-right: 50px;
  min-width: 130px;
`;

export const KeyDetails = styled.div`
  color: #adadcc;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 15px;
  text-align: center;
  min-width: 124px;
  padding: 0 5px;
  box-sizing: border-box;
  margin-right: 23px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const NotFoundText = styled(KeyDetails)`
  width: 100%;
  justify-content: center;
  font-size: 20px;
`;
