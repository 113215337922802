import styled, {css} from 'styled-components';

type StyledButtonProps = {
  disabled?: boolean;
  secondary?: boolean;
  blinking?: boolean;
  type?: 'submit' | 'button';
};

export const StyledButton = styled.button<StyledButtonProps>`
  height: 40px;
  background-color: #1a8cff;
  min-width: 117px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
  color: #ffffff;
  text-align: left;
  padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: all 0.07s ease-in-out;
  user-select: none;
  border: 1px solid #1a8cff;

  &:hover {
    opacity: 0.88;
    box-shadow: 0 7px 7px #00458b2f;
  }

  &:active {
    opacity: 1;
  }

  ${(props) =>
    props.secondary &&
    css`
      border: 1px solid #d7d8e4;
      color: #161643;
      background-color: white;

      &:hover {
        opacity: 0.78;
        box-shadow: 0 4px 4px #2699fb1a;
      }

      &:active {
        opacity: 1;
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      box-shadow: none;
      cursor: not-allowed;
      opacity: 0.1;

      &:hover {
        opacity: 0.1;
        box-shadow: none;
      }

      &:active {
        opacity: 0.1;
      }
    `};

  ${(props) =>
    props.secondary &&
    props.blinking &&
    css`
      &,
      &:hover,
      &:active {
        cursor: progress;
        box-shadow: 0 4px 4px #2699fb1a;
        animation: blink 5s ease-in-out infinite;
        opacity: 1;
      }
    `};

  @keyframes blink {
    0% {
      border-color: #d7d8e4;
    }
    50% {
      border-color: #1a8cff;
    }
    0% {
      border-color: #d7d8e4;
    }
  }
`;

export const Label = styled.div`
  height: 36px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  & > img {
    margin-right: 12px;
  }
`;
