import React from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useAuth} from '../../../context/auth';
import transferIcon from '../../../assets/transfer-icon.svg';
import api, {queryFetcher} from '../../../api';
import i18n from '../../../i18n';
import {useErrorModal, useErrorToast, useModalControls} from '../../../utils/hooks';
import {openHubspotChat} from '../../../analytics/hubspot';
import {PAYMENTS_SETTINGS_STATUSES, PaymentsSettings, User} from '../../../utils/types';
import relaxIcon from '../../../assets/relax-icon.svg';
import Section from '../Section';
import Button from '../Button';
import PaymentsDocumentsVerificationModal from '../PaymentsDocumentsVerificationModal';
import PaymentsTransferModal from '../PaymentsTransferModal';
import Loader from '../../common/Loader';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import PaymentsMovementsPreview from '../PaymentsMovementsPreview';
import {
  Balance,
  BalanceButton,
  BalanceCurrency,
  BalanceGrid,
  BalanceIcon,
  BalanceLabel,
  ContactSupportButton,
  LoaderWrapper,
  StatusDetails,
  StatusWrapper,
  TryAgainButtonWrapper,
  PaymentsStatusDetails,
} from './styled';

const BALANCE_PLACEHOLDER = '0.00';

const STATUS_TEXTS = {
  [PAYMENTS_SETTINGS_STATUSES.new]: i18n.t('waiting_for_verification'),
  [PAYMENTS_SETTINGS_STATUSES.validating]: i18n.t('waiting_for_verification'),
  [PAYMENTS_SETTINGS_STATUSES.invalid]: i18n.t('verification_failed'),
  [PAYMENTS_SETTINGS_STATUSES.valid]: '',
};

function fetchPaymentSettings() {
  return queryFetcher(api.paymentsSettings.ENDPOINTS.all());
}

function getBalance(user?: User | null) {
  if (!user?.balance) {
    return BALANCE_PLACEHOLDER;
  }

  return user.balance?.toFixed(2);
}

type AccountPaymentsProps = {
  disabled: boolean;
};

function AccountPayments({disabled}: AccountPaymentsProps) {
  const {t} = useTranslation();
  const {ErrorModal} = useErrorModal();
  const {accountDetails: user, refreshAccount} = useAuth();
  const isSectionActive = Boolean(user?.is_taxes_or_deposits_activated);

  const {
    isOpen: isPaymentsModalOpen,
    openModal: openPaymentsModal,
    closeModal: closePaymentsModal,
  } = useModalControls();
  const {
    isOpen: isTransferModalOpen,
    openModal: openTransferModal,
    closeModal: closeTransferModal,
  } = useModalControls();
  const {
    isOpen: isStillValidatingModalOpen,
    openModal: openStillValidatingModal,
    closeModal: closeStillValidatingModal,
  } = useModalControls();

  const {
    data: paymentsSettings,
    error: paymentsSettingsError,
    status: paymentsSettingsStatus,
  } = useQuery<PaymentsSettings, string>('paymentsSettings', fetchPaymentSettings);
  useErrorToast(paymentsSettingsError, {notFoundMessage: 'Payments settings not found.'});

  React.useEffect(() => {
    refreshAccount();
  }, [refreshAccount]);

  const hasPaymentSettings = React.useMemo(() => {
    if (!paymentsSettings) {
      return false;
    }
    return Boolean(Object.keys(paymentsSettings)?.length);
  }, [paymentsSettings]);

  const handleTransferButtonClick = () => {
    if (paymentsSettings?.status === PAYMENTS_SETTINGS_STATUSES.valid) {
      openTransferModal();
      return;
    }

    if (
      paymentsSettings?.status === PAYMENTS_SETTINGS_STATUSES.validating ||
      paymentsSettings?.status === PAYMENTS_SETTINGS_STATUSES.new
    ) {
      openStillValidatingModal();
      return;
    }

    openPaymentsModal();
  };

  return (
    <div>
      {isSectionActive && (
        <Section
          title={t('payments_settings_title')}
          subtitle={t('payments_settings_subtitle')}
        >
          {isSectionActive && (
            <div>
              {hasPaymentSettings && (
                <StatusWrapper>
                  {paymentsSettings!.status !== PAYMENTS_SETTINGS_STATUSES.valid && (
                    <span>
                      {t('status').toUpperCase()}: {` `}
                      <StatusDetails status={paymentsSettings!.status}>
                        {STATUS_TEXTS[paymentsSettings!.status]}
                        {paymentsSettings!.status ===
                          PAYMENTS_SETTINGS_STATUSES.invalid && (
                          <span>
                            <PaymentsStatusDetails>
                              {paymentsSettings?.status_details &&
                                ` - ${paymentsSettings?.status_details}`}
                            </PaymentsStatusDetails>
                            <TryAgainButtonWrapper>
                              <Button
                                secondary
                                label={t('try_again')}
                                onClick={openPaymentsModal}
                                disabled={
                                  paymentsSettingsStatus === 'loading' || disabled
                                }
                              />
                            </TryAgainButtonWrapper>
                            <ContactSupportButton onClick={openHubspotChat} type="button">
                              {t('or_contact_support')}
                            </ContactSupportButton>
                          </span>
                        )}
                      </StatusDetails>
                    </span>
                  )}
                </StatusWrapper>
              )}
              <BalanceGrid>
                <BalanceLabel>{t('your_balance')}</BalanceLabel>
                <Balance>
                  {paymentsSettingsStatus === 'loading' ? (
                    <LoaderWrapper>
                      <Loader height={35} width={35} />
                    </LoaderWrapper>
                  ) : (
                    <>
                      {getBalance(user)} <BalanceCurrency>€</BalanceCurrency>
                    </>
                  )}
                </Balance>
                <BalanceButton>
                  <Button
                    disabled={paymentsSettingsStatus === 'loading' || disabled}
                    label={
                      <>
                        <BalanceIcon src={transferIcon} alt="Transfer coin" />
                        {t('transfer_to_my_bank')}
                      </>
                    }
                    onClick={handleTransferButtonClick}
                  />
                </BalanceButton>
              </BalanceGrid>
              <PaymentsMovementsPreview />
            </div>
          )}
        </Section>
      )}
      {isPaymentsModalOpen && (
        <PaymentsDocumentsVerificationModal
          open
          onClose={closePaymentsModal}
          paymentsSettingsStatus={paymentsSettingsStatus}
          paymentsSettings={paymentsSettings}
        />
      )}
      {isTransferModalOpen && (
        <PaymentsTransferModal
          open={isTransferModalOpen}
          onClose={closeTransferModal}
          paymentSettings={paymentsSettings}
        />
      )}
      <Modal
        closeOnDocumentClick
        closeOnEscape
        iconSrc={relaxIcon}
        iconAlt="Relaxing man"
        iconProps={{
          height: 97,
          width: 80,
        }}
        title={t('validating')}
        text={t('your_payment_account_validating')}
        open={isStillValidatingModalOpen}
        onClose={closeStillValidatingModal}
      >
        <ModalButton label={t('ok')} onClick={closeStillValidatingModal} />
      </Modal>
      <ErrorModal />
    </div>
  );
}

export {AccountPayments};
