import styled from 'styled-components';
import Button from '../Button';

export const TableWrapper = styled.div`
  width: 100%;
  margin: 20px auto 0;
  box-shadow: 0 5px 5px #2699fb1a;

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    & > thead {
      cursor: default;
      background-color: #cbe9ff;
      text-transform: uppercase;
      font-family: ProximaNova-Bold, sans-serif;
      font-size: 10px;
      color: #161643;

      & > tr {
        height: 60px;
        text-align: left;

        & th:nth-child(1) {
          padding-left: 14px;
          width: 25%;
        }

        & th:nth-child(3) {
          min-width: 105px;
        }

        & th:last-child {
          width: 55%;
        }
      }
    }

    & > tbody > tr {
      font-family: ProximaNova-Semibold, sans-serif;
      font-size: 14px;
      color: #161643;
      height: 60px;
      border-bottom: 1px solid #f0f0f3;
      transition: background-color 0.07s ease-in-out;

      & td:nth-child(1) {
        padding-left: 14px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const Content = styled.div`
  max-width: 1040px;
  padding: 0 20px;
  margin: 20px auto 76px;
`;

export const HeaderText = styled.div`
  font-size: 18px;
  margin-bottom: 27px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
`;

export const NoInvoicesText = styled.td`
  font-size: 20px;
  margin: 27px 14px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
`;

export const PDFButton = styled(Button)`
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #1a8cff;
  min-width: 71px;
  height: 22px;
  div {
    height: auto;
    font-size: 14px;
    color: #161643;
    font-family: ProximaNova-Medium, sans-serif;
    margin: 0 auto;
  }
`;

export const LoaderWrapper = styled.div`
  margin-top: 30px;
`;
