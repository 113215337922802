import React from 'react';
import {useTranslation} from 'react-i18next';
import i18n from '../../../i18n';
import {GuestPayment, LightReservation, TaxExemption} from '../../../utils/types';
import api, {queryFetcher} from '../../../api';
import {useQuery} from 'react-query';
import {useErrorToast} from '../../../utils/hooks';
import Section from '../Section';
import Loader from '../../common/Loader';
import {
  Main,
  Status,
  StatusDot,
  Currency,
  PaymentAmount,
  TotalPayment,
  Title,
  Summary,
  SummaryRow,
  SummaryTitle,
} from './styled';

function fetchGuestPayment(key: string, params = '') {
  return queryFetcher(api.seasonGuests.ENDPOINTS.all(params));
}

function fetchTaxExemptions() {
  return queryFetcher(api.statTaxExemptions.ENDPOINTS.all());
}

function getSummary(guestPayment?: GuestPayment, taxExemptions?: TaxExemption[]) {
  if (!guestPayment || !taxExemptions) {
    return null;
  }

  const guestList = guestPayment?.guest_list;
  if (!guestList?.length) {
    return null;
  }

  let reducedGuests: {
    [key: string]: {
      count: number;
      ages: number[];
    };
  } = {};
  guestList.forEach((guest) => {
    if (!reducedGuests[guest.exemption]) {
      reducedGuests[guest.exemption] = {
        ages: [guest.age],
        count: 1,
      };
      return;
    }

    reducedGuests[guest.exemption] = {
      ages: [...reducedGuests[guest.exemption].ages, guest.age],
      count: reducedGuests[guest.exemption].count + 1,
    };
  });

  return (
    <div>
      {Object.keys(reducedGuests).map((exemption, index) => {
        const taxExemption = taxExemptions.find((ex) => ex.id === exemption);
        const exemptionAges = [...Array.from(new Set(reducedGuests[exemption].ages))];

        return (
          <SummaryRow key={index}>
            <b>
              {taxExemption?.name} ({reducedGuests[exemption].count}):
            </b>{' '}
            {exemptionAges?.length > 1 ? i18n.t('ages') : i18n.t('age')}{' '}
            {exemptionAges.sort().map((age, index, initArr) => {
              return (
                <span key={index}>
                  {age} {index + 1 !== initArr?.length && ', '}
                </span>
              );
            })}
          </SummaryRow>
        );
      })}
    </div>
  );
}

type ReservationTaxesSectionProps = {
  reservation?: LightReservation;
};

function ReservationTaxesSection({reservation}: ReservationTaxesSectionProps) {
  const {t} = useTranslation();
  const isPaid = Boolean(reservation?.have_taxes_been_paid);
  const {
    data: guestPayments,
    error: guestPaymentsError,
    status: guestPaymentsStatus,
  } = useQuery<GuestPayment[], [string, string]>(
    Boolean(reservation?.id && isPaid) && [
      'guestPayments',
      `reservation_id=${reservation?.id}`,
    ],
    fetchGuestPayment,
    {
      refetchOnWindowFocus: false,
    },
  );
  useErrorToast(guestPaymentsError, {
    notFoundMessage: 'Guest payment not found.',
  });
  const {
    data: taxExemptions,
    error: taxExemptionsError,
    status: taxExemptionsStatus,
  } = useQuery<TaxExemption[], string>('taxExemptions', fetchTaxExemptions, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(taxExemptionsError, {
    notFoundMessage:
      'Requested tax exemptions could not be found. Please contact support.',
  });

  const summary = React.useMemo(() => {
    return getSummary(guestPayments?.[0], taxExemptions);
  }, [guestPayments, taxExemptions]);

  const amount = guestPayments?.[0]?.amount || 0;
  const isLoading =
    guestPaymentsStatus === 'loading' || taxExemptionsStatus === 'loading';

  const getStatusLabel = () => {
    if (isPaid) {
      return t('paid');
    }

    return t('pending');
  };

  return (
    <Section title={t('tourist_taxes')}>
      {isLoading ? (
        <>
          <Loader height={45} width={45} label={t('loading')} />
        </>
      ) : (
        <div>
          <Status>
            <StatusDot success={isPaid} /> {getStatusLabel()}
          </Status>
          {isPaid ? (
            <Main>
              <TotalPayment>
                <Title>{t('total_payment')}:</Title>
                <PaymentAmount>
                  {amount.toFixed(2)}
                  <Currency>€</Currency>
                </PaymentAmount>
              </TotalPayment>
              {Boolean(guestPayments?.length) && (
                <Summary>
                  <SummaryTitle>{t('summary')}:</SummaryTitle>
                  <SummaryRow>{summary}</SummaryRow>
                </Summary>
              )}
            </Main>
          ) : (
            <Main>
              <Title>{t('taxes_will_be_calculated')}</Title>
            </Main>
          )}
        </div>
      )}
    </Section>
  );
}

export {ReservationTaxesSection};
