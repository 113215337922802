import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useQuery, queryCache} from 'react-query';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import {isBefore, subYears} from 'date-fns';
import {Controller, useForm} from 'react-hook-form';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import type {
  City,
  Country,
  DatepickerDate,
  District,
  Province,
  PurposeOfStay,
  SelectOption,
  TaxExemption,
  LightReservation,
} from '../../../utils/types';
import type {WebcamRefTypes} from '../Webcam/Webcam';
import type {Guest} from '../../../utils/types';
import {getGuestLeader, getGroupMembersNumber} from '../../../utils/guestGroup';
import {
  CARD_BRANDS,
  COUNTRIES_WITH_PURPOSES_OF_STAY,
  COUNTRY_CODES,
  EU_MEMBERS,
  FAMILY_GROUP_TYPE,
  GENDERS_OPTIONS,
  PATTERNS,
  STAT_TYPES,
  STAT_TYPES_WITH_TAX_EXEMPTIONS,
  MIN_MEMBERS_NUMBER,
} from '../../../utils/constants';
import {
  addSupportEmailToMessage,
  getBase64,
  getRequiredOrOptionalFieldLabel,
  toastResponseError,
} from '../../../utils/common';
import {getCountryCode} from '../../../utils/housing';
import {
  getIsCaptureStatFieldsEnabled,
  getStatTypeIfStatActive,
} from '../../../utils/reservations';
import documents, {
  getDocTypes,
  getRemappedDocType,
  getShouldResetDocTypes,
} from '../../../utils/docTypes';
import {
  useAbortController,
  useErrorModal,
  useErrorToast,
  useIsMounted,
  useModalControls,
  usePrevious,
  useStatus,
} from '../../../utils/hooks';
import {useComputedDetails} from '../../../context/computedDetails';
import {useConfirmLeaveModal} from '../../../context/openModals';
import type {GuestGroup} from '../../../utils/types';
import {buildGuest, getGuestPoliceStatusInCode} from '../../../utils/guest';
import {hasAnyCompletedGuest} from '../../../utils/guestGroup';
import {toast} from 'react-toastify';
import {Housing} from '../../../utils/types';
import viewIconBlue from '../../../assets/view-icon-blue.svg';
import plusIcon from '../../../assets/plus.svg';
import plusBlueIcon from '../../../assets/plus-blue.svg';
import cameraIcon from '../../../assets/cam_icn.svg';
import blueCloseIcon from '../../../assets/close-blue.svg';
import scanningIcon from '../../../assets/scanning_icn.svg';
import successIcon from '../../../assets/icon_success.svg';
import warningIcon from '../../../assets/warning-icon.svg';
import guestIcon from '../../../assets/guest-icon.svg';
import ReactSignatureCanvas from 'react-signature-canvas';
import rubbishIcon from '../../../assets/rubbish.svg';
import missingDataIcon from '../../../assets/icon-data-missing.svg';
import deleteGuestIcon from '../../../assets/icon-delete-guest.svg';
import floppyIcon from '../../../assets/floppy-disk.svg';
import Input from '../Input';
import Select from '../Select';
import Datepicker from '../Datepicker';
import AsyncSelect from '../AsyncSelect';
import YouHaveMadeChangesModal from '../YouHaveMadeChangesModal';
import Modal from '../Modal';
import Loader from '../../common/Loader';
import SignatureCanvas from '../SignatureCanvas';
import Button from '../Button';
import PhoneInput from '../../dashboard/PhoneInput';
import BackButton from '../BackButton';
import ModalButton from '../ModalButton';
import Tooltip from '../Tooltip';
import {ModalTwoButtonsWrapper, Heading} from '../../../styled/common';
import {
  Content,
  ButtonLabelIcon,
  ButtonLabelText,
  ButtonLabelWrapper,
  Title,
  MissingNameTitle,
  FieldsContainer,
  FormContent,
  SignaturePlaceholder,
  AbortRequestButtonWrapper,
  AddDataManuallyText,
  DetectionWebcam,
  Divider,
  FieldWrapper,
  ScanModalButtonsWrapper,
  ModalCloseButton,
  ModalContent,
  scanModalContentStyle,
  ScanModalHint,
  ModalTitle,
  ScanModalTriggerButtonWrapper,
  BottomButton,
  PlusImage,
  BottomButtonText,
  BottomButtonError,
  SignatureImg,
  SignatureModalButtonsWrapper,
  SignatureModalContent,
  BottomButtonWrapper,
  DeleteButtonLabelIcon,
  DeleteButtonLabelText,
  BlueBottomButtonText,
  Form,
  ModalButtonWrapper,
  CenteredWrapper,
  ErrorText,
  BottomSection,
  DocumentPhotosWrapper,
  DocumentPhoto,
  DocumentsPhotosContent,
  viewDocumentsModalContentStyle,
  DeleteDocumentButtonWrapper,
  DocumentInfo,
  ScanModalOrText,
  FileDragZone,
  FileTypes,
  DragZoneText,
  SelectFileDragZoneContainer,
  UploadedPDFWrapper,
  UploadedImageWrapper,
  SelectAnotherFileButton,
  UploadModalButtonsWrapper,
  ViewDocumentLink,
  SaveGuestText,
} from './styled';

const CACHE_TIME = 60;
const STALE_TIME = 30;
const DETECTING_REQUESTS_GAP_S = 1;
const SUCCESS_MODAL_TIMEOUT_S = 1.5;
const MAX_NAMES_INPUT_LENGTH = 30;
const MIN_TAX = 0;
const MAX_TAX = 5;

const PDF_UPLOADING_ALLOWED_COUNTRIES = [COUNTRY_CODES.dubai];

enum DETECTING_STATUSES {
  completed = 'COM',
  error = 'ERR',
  processing = 'PRO',
  new = 'NEW',
}

export const getBestMatches = (
  arr: Array<{label: string; value: string}>,
  inputValue: any,
) => {
  return arr
    .filter((x: any) => x.label.toLowerCase().includes(inputValue.toLowerCase()))
    .slice(0, 50)
    .sort((wo, yo) => {
      const w = wo.label.toLowerCase();
      const y = yo.label.toLowerCase();
      if (w.startsWith(inputValue) && y.startsWith(inputValue)) {
        return w > y ? 1 : -1;
      }
      if (w.startsWith(inputValue)) {
        return -1;
      }
      if (y.startsWith(inputValue)) {
        return 1;
      }
      return w > y ? 1 : -1;
    });
};

function fetchReservation(key: string) {
  return queryFetcher(api.reservations.ENDPOINTS.oneLight(key));
}

function fetchLocations(key: string, params = 'ordering=name') {
  return queryFetcher(api.locations.ENDPOINTS.all(params));
}

function fetchPurposesOfStay(key: string, params = '') {
  return queryFetcher(api.purposesOfStay.ENDPOINTS.all(params));
}

function fetchTaxExemptions(key: string, params = '') {
  return queryFetcher(api.statTaxExemptions.ENDPOINTS.all(params));
}

function getIsNeedToFetchDistricts(countryCode = '') {
  return countryCode === COUNTRY_CODES.colombia;
}

function getIsNeedToFetchProvinces(countryCode = '', isCaptureStatFieldsEnabled = false) {
  return countryCode === COUNTRY_CODES.spain && isCaptureStatFieldsEnabled;
}

function getIsNeedToFetchCities(countryCode = '') {
  return countryCode === COUNTRY_CODES.italy;
}

function getIsNeedToFetchMunicipalities(countryCode = '') {
  return countryCode === COUNTRY_CODES.colombia;
}

function getIsNeedToFetchPurposesOfStay(countryCode = '', isGuestLeader = false) {
  return (
    (countryCode === COUNTRY_CODES.czech && isGuestLeader) ||
    COUNTRIES_WITH_PURPOSES_OF_STAY.includes(countryCode)
  );
}

function getStatTypeExemptionTypeParams(countryCode = '') {
  return countryCode === COUNTRY_CODES.slovenia
    ? 'stat_type=AJP'
    : countryCode === COUNTRY_CODES.italy
    ? 'stat_type=ITVA'
    : '';
}

function getIsNeedToFetchTaxExemptions(countryCode = '', housing?: Housing) {
  const statType = getStatTypeIfStatActive(housing);
  return (
    countryCode === COUNTRY_CODES.slovenia ||
    (countryCode === COUNTRY_CODES.italy &&
      STAT_TYPES_WITH_TAX_EXEMPTIONS.includes(statType))
  );
}

type Provinces = {
  results: Province[];
};
function getProvincesAsOptions(provinces: Provinces) {
  if (!provinces?.results) {
    return [];
  }

  return provinces?.results?.map((c) => {
    return {
      label: c?.division_level_2?.name,
      value: c?.division_level_2?.code,
    };
  });
}

function getLocationsAsOptions(locations: {results: {country: Country}[]}) {
  if (!locations?.results) {
    return [];
  }

  return locations?.results?.map((c: any) => {
    return {
      label: c?.country?.name,
      value: c?.country?.code,
    };
  });
}

function getDistrictsAsOptions(data: {results: District[]}) {
  const districts = data?.results;

  if (!districts) {
    return [];
  }
  return districts.map((d) => {
    return {
      value: d?.division_level_1?.code,
      label: d?.division_level_1?.name,
    };
  });
}

function getCitiesAsOptions(data: {results: City[]}) {
  const results = data?.results;

  if (!results) {
    return [];
  }
  return results.map((l) => {
    return {
      value: l?.division_level_3?.code,
      label: l?.division_level_3?.name,
    };
  });
}

function getPurposesOfStayAsOptions(data: PurposeOfStay[]) {
  if (!data) {
    return [];
  }
  return data.map((p) => {
    return {
      value: p.id,
      label: p.name,
    };
  });
}

function getTaxExemptionsAsOptions(data: TaxExemption[]) {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map((t) => {
    return {
      value: t.id,
      label: t.name,
    };
  });
}

function getNamePattern(country?: string) {
  if (country === COUNTRY_CODES.italy) {
    return PATTERNS.nameWithoutAccents;
  }

  return PATTERNS.name;
}

function validate(number = '', type = '') {
  const DNI_STRING = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const DNI_DIVISION_NUMBER = 23;

  if (!PATTERNS.dniAndDl.test(number)) {
    return i18n.t('doc_number_dni_format_error');
  }

  const extractedNumbers = Number(number.slice(0, -1));
  const extractedChar = number.substr(number.length - 1).toUpperCase();

  const expectedChar = DNI_STRING[extractedNumbers % DNI_DIVISION_NUMBER];

  if (expectedChar !== extractedChar) {
    if (type === documents.drivingLicenseES) {
      return i18n.t('doc_number_dl_letter_error');
    }

    return i18n.t('doc_number_dni_letter_error');
  }

  return true;
}

function validateNIE(number = '') {
  const NIE_STRING = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const NIE_DIVISION_NUMBER = 23;
  const firstLetterValue: {[key: string]: string} = {
    X: '0',
    Y: '1',
    Z: '2',
  };

  if (!PATTERNS.nie.test(number)) {
    return i18n.t('doc_number_nie_format_error');
  }

  const withoutLetters = number.slice(1, -1);
  const lastSymbol = number[number.length - 1].toUpperCase();

  const firstLetter: string = number[0].toUpperCase();
  const replacedFirstLetter = firstLetterValue[firstLetter];

  if (replacedFirstLetter === undefined) {
    return i18n.t('doc_number_nie_letter_error');
  }

  const numberWithReplacedFirstLetter = Number(replacedFirstLetter + withoutLetters);
  const expectedChar = NIE_STRING[numberWithReplacedFirstLetter % NIE_DIVISION_NUMBER];

  if (lastSymbol !== expectedChar) {
    return i18n.t('doc_number_nie_letter_error');
  }

  return true;
}

function validateDocNumber(number = '', docType: SelectOption | null) {
  const type = docType?.value;

  if (type && documents.dniValidation.includes(String(type))) {
    return validate(number, type);
  }

  if (type && documents.nieValidation.includes(String(type))) {
    return validateNIE(number);
  }

  return true;
}

function getIsBirthDateBeforeIssueDate(birthDate: Date, issueDate: Date) {
  if (birthDate && issueDate) {
    return isBefore(new Date(birthDate), new Date(issueDate));
  }
  return true;
}

function getIsDateBeforeToday(date: Date) {
  return isBefore(new Date(date), new Date());
}

function validateBirthDate(birthDate: Date, issueDate: Date) {
  if (!birthDate) {
    return true;
  }

  let error = '';
  const isBeforeIssueDate = getIsBirthDateBeforeIssueDate(birthDate, issueDate);
  const isBeforeToday = getIsDateBeforeToday(birthDate);

  if (!isBeforeIssueDate) {
    error = i18n.t('cant_be_equal_or_after_than_issue_date');
  }
  if (!isBeforeToday) {
    error += ` ${i18n.t('cant_be_equal_or_greater_than_today')}`;
  }

  return error || true;
}

function validateIssueDate(issueDate: Date, birthDate: Date) {
  if (!issueDate) {
    return true;
  }

  let error = '';
  const isBeforeIssueDate = getIsBirthDateBeforeIssueDate(birthDate, issueDate);
  const isBeforeToday = getIsDateBeforeToday(issueDate);

  if (!isBeforeIssueDate) {
    error = i18n.t('cant_be_equal_or_smaller_than_birth_date');
  }
  if (!isBeforeToday) {
    error += ` ${i18n.t('cant_be_equal_or_greater_than_today')}`;
  }

  return error || true;
}

function getValidName(name?: string) {
  if (!name) {
    return undefined;
  }
  return PATTERNS.name.test(name) ? name : undefined;
}

function getAllowedFileTypes(countryCode: string) {
  if (PDF_UPLOADING_ALLOWED_COUNTRIES.includes(countryCode)) {
    return '.jpg, .png, .pdf';
  }

  return '.jpg, .png';
}

type GuestDetailsTypes = {
  guestId: string;
  guestGroup?: GuestGroup;
};

function getGuestDetails({guestGroup, guestId}: GuestDetailsTypes) {
  if (!guestGroup || !guestId) {
    return {
      data: null,
      isLeader: false,
    };
  }

  const groupMembers = guestGroup?.members || [];
  const guest = groupMembers.find((m) => {
    return m.id === guestId;
  });

  const leaderId = guestGroup?.leader_id || '';
  const isLeader = guest?.id === leaderId || !groupMembers.length;

  return {
    isLeader,
    data: guest || null,
  };
}

export enum FORM_NAMES {
  sex = 'sex',
  name = 'name',
  surname = 'surname',
  secondSurname = 'second_surname',
  birthDate = 'birthDate',
  nationality = 'nationality',
  docType = 'docType',
  docNumber = 'docNumber',
  docDateOfIssue = 'docDateOfIssue',
  birthCountry = 'birthCountry',
  residenceCountry = 'residenceCountry',
  residenceCity = 'residenceCity',
  residenceAddress = 'residenceAddress',
  countryOfIssue = 'countryOfIssue',
  cityOfBirth = 'cityOfBirth',
  cityOfIssue = 'cityOfIssue',
  nextDestinationCountry = 'nextDestinationCountry',
  nextDestinationCity = 'nextDestinationCity',
  nextDestinationAddress = 'nextDestinationAddress',
  residencePostalCode = 'residencePostalCode',
  citizenship = 'citizenship',
  purposeOfStay = 'purposeOfStay',
  visaNumber = 'visaNumber',
  arrivalCountry = 'arrivalCountry',
  arrivalDistrict = 'arrivalDistrict',
  arrivalMunicipality = 'arrivalMunicipality',
  nextDestinationDistrict = 'nextDestinationDistrict',
  nextDestinationMunicipality = 'nextDestinationMunicipality',
  terms = 'terms',
  taxExemption = 'taxExemption',
  residenceProvince = 'residenceProvince',
  signature = 'signature',
  frontSideScan = 'front_side_scan',
  backSideScan = 'back_side_scan',
  email = 'email',
  phone = 'phone',
  fiscalCode = 'fiscal_code',
  fullTouristTax = 'full_tourist_tax',
}

type FormTypes = Partial<{
  [FORM_NAMES.name]: string;
  [FORM_NAMES.surname]: string;
  [FORM_NAMES.secondSurname]: string;
  [FORM_NAMES.sex]: SelectOption;
  [FORM_NAMES.birthDate]: DatepickerDate;
  [FORM_NAMES.nationality]: SelectOption;
  [FORM_NAMES.docType]: SelectOption;
  [FORM_NAMES.docNumber]: string;
  [FORM_NAMES.docDateOfIssue]: DatepickerDate;
  [FORM_NAMES.birthCountry]: SelectOption;
  [FORM_NAMES.residenceCountry]: SelectOption;
  [FORM_NAMES.residenceCity]: string | SelectOption;
  [FORM_NAMES.countryOfIssue]: SelectOption;
  [FORM_NAMES.cityOfBirth]: string | SelectOption;
  [FORM_NAMES.cityOfIssue]: string | SelectOption;
  [FORM_NAMES.residenceAddress]: string;
  [FORM_NAMES.nextDestinationCountry]: SelectOption;
  [FORM_NAMES.nextDestinationCity]: string;
  [FORM_NAMES.nextDestinationAddress]: string;
  [FORM_NAMES.residencePostalCode]: string;
  [FORM_NAMES.citizenship]: SelectOption;
  [FORM_NAMES.purposeOfStay]: SelectOption;
  [FORM_NAMES.visaNumber]: string;
  [FORM_NAMES.arrivalCountry]: SelectOption;
  [FORM_NAMES.arrivalDistrict]: SelectOption;
  [FORM_NAMES.arrivalMunicipality]: SelectOption;
  [FORM_NAMES.nextDestinationDistrict]: SelectOption;
  [FORM_NAMES.nextDestinationMunicipality]: SelectOption;
  [FORM_NAMES.taxExemption]: SelectOption;
  [FORM_NAMES.residenceProvince]: SelectOption;
  [FORM_NAMES.signature]: string | null;
  [FORM_NAMES.frontSideScan]: string | null;
  [FORM_NAMES.backSideScan]: string | null;
  [FORM_NAMES.email]: string;
  [FORM_NAMES.phone]: string | null | number;
  [FORM_NAMES.fiscalCode]: string | null;
  [FORM_NAMES.fullTouristTax]: number | null;
}>;

type RequiredFields = {
  [key: string]: string | boolean;
};

type FormFieldsTypes = {
  [FORM_NAMES.birthCountry]: string;
  [FORM_NAMES.countryOfIssue]: string;
  [FORM_NAMES.citizenship]: string;
  [FORM_NAMES.arrivalCountry]: string;
  [FORM_NAMES.nextDestinationCountry]: string;
  [FORM_NAMES.residenceCountry]: string;
  [FORM_NAMES.docType]: string;
  [FORM_NAMES.email]: string;
  [FORM_NAMES.phone]: string;
};

type FieldsDataTypes = {
  housing?: Housing;
  housingCountry?: string;
  nationalityCode?: string | number;
  isGuestLeader: boolean;
  isGuestWithDoc?: boolean;
  isEditingGuest?: boolean;
  hasAnyCompletePoliceGuest?: boolean;
  guestGroupType?: string | null;
  formFields?: Partial<FormFieldsTypes>;
};

const INIT_REQUIRED_FIELDS: RequiredFields = {
  [FORM_NAMES.name]: i18n.t('required') as string,
  [FORM_NAMES.surname]: i18n.t('required') as string,
  [FORM_NAMES.secondSurname]: i18n.t('required') as string,
  [FORM_NAMES.sex]: i18n.t('required') as string,
  [FORM_NAMES.birthDate]: i18n.t('required') as string,
  [FORM_NAMES.nationality]: i18n.t('required') as string,
  [FORM_NAMES.docType]: i18n.t('required') as string,
  [FORM_NAMES.docNumber]: i18n.t('required') as string,
  [FORM_NAMES.docDateOfIssue]: i18n.t('required') as string,
  [FORM_NAMES.birthCountry]: i18n.t('required') as string,
  [FORM_NAMES.residenceCountry]: i18n.t('required') as string,
  [FORM_NAMES.residenceCity]: i18n.t('required') as string,
  [FORM_NAMES.countryOfIssue]: i18n.t('required') as string,
  [FORM_NAMES.cityOfBirth]: i18n.t('required') as string,
  [FORM_NAMES.cityOfIssue]: i18n.t('required') as string,
  [FORM_NAMES.residenceAddress]: i18n.t('required') as string,
  [FORM_NAMES.nextDestinationCountry]: i18n.t('required') as string,
  [FORM_NAMES.nextDestinationCity]: i18n.t('required') as string,
  [FORM_NAMES.nextDestinationAddress]: i18n.t('required') as string,
  [FORM_NAMES.residencePostalCode]: i18n.t('required') as string,
  [FORM_NAMES.citizenship]: i18n.t('required') as string,
  [FORM_NAMES.purposeOfStay]: i18n.t('required') as string,
  [FORM_NAMES.visaNumber]: i18n.t('required') as string,
  [FORM_NAMES.arrivalCountry]: i18n.t('required') as string,
  [FORM_NAMES.arrivalDistrict]: i18n.t('required') as string,
  [FORM_NAMES.arrivalMunicipality]: i18n.t('required') as string,
  [FORM_NAMES.nextDestinationDistrict]: i18n.t('required') as string,
  [FORM_NAMES.nextDestinationMunicipality]: i18n.t('required') as string,
  [FORM_NAMES.taxExemption]: i18n.t('required') as string,
  [FORM_NAMES.residenceProvince]: i18n.t('required') as string,
  [FORM_NAMES.signature]: i18n.t('required') as string,
  [FORM_NAMES.frontSideScan]: i18n.t('required') as string,
  [FORM_NAMES.backSideScan]: i18n.t('required') as string,
  [FORM_NAMES.phone]: i18n.t('required') as string,
  [FORM_NAMES.fiscalCode]: i18n.t('required') as string,
  [FORM_NAMES.fullTouristTax]: i18n.t('required') as string,
};

/**
 * These functions below are to prevent caching at the <Controller/>s rules.
 * Create such function for an each <Controller /> if it has any dynamic rule
 */

function getIsDocTypeRequired({housingCountry, nationalityCode}: FieldsDataTypes) {
  switch (housingCountry) {
    case COUNTRY_CODES.uk: {
      if (nationalityCode === COUNTRY_CODES.uk) {
        return false;
      }

      return INIT_REQUIRED_FIELDS[FORM_NAMES.docType];
    }
    default: {
      return INIT_REQUIRED_FIELDS[FORM_NAMES.docType];
    }
  }
}

function getIsDocDateOfIssueRequired({housingCountry}: FieldsDataTypes) {
  switch (housingCountry) {
    default: {
      return INIT_REQUIRED_FIELDS[FORM_NAMES.docType];
    }
  }
}

function getIsCountryOfIssueRequired({
  housingCountry,
  guestGroupType,
  isGuestLeader,
  formFields,
}: FieldsDataTypes) {
  switch (housingCountry) {
    case COUNTRY_CODES.austria: {
      if (guestGroupType === FAMILY_GROUP_TYPE) {
        if (isGuestLeader) {
          return formFields?.residenceCountry === COUNTRY_CODES.austria
            ? false
            : i18n.t('required');
        }
      }

      return formFields?.residenceCountry === COUNTRY_CODES.austria
        ? false
        : (i18n.t('required') as string);
    }
    default: {
      return INIT_REQUIRED_FIELDS[FORM_NAMES.docType];
    }
  }
}

function getRequiredFields({
  housing,
  nationalityCode,
  formFields,
  housingCountry,
  isGuestLeader,
  guestGroupType,
}: FieldsDataTypes) {
  const isContractEnabled = housing?.is_contract_enabled;
  let fields: RequiredFields = {
    ...INIT_REQUIRED_FIELDS,
    [FORM_NAMES.docType]: getIsDocTypeRequired({
      formFields,
      guestGroupType,
      nationalityCode,
      isGuestLeader,
      housingCountry,
    }),
    [FORM_NAMES.docDateOfIssue]: getIsDocDateOfIssueRequired({
      formFields,
      guestGroupType,
      nationalityCode,
      isGuestLeader,
      housingCountry,
    }),
    [FORM_NAMES.countryOfIssue]: getIsCountryOfIssueRequired({
      formFields,
      guestGroupType,
      nationalityCode,
      isGuestLeader,
      housingCountry,
    }),
  };

  switch (housingCountry) {
    case COUNTRY_CODES.uk: {
      fields = {
        ...fields,
        [FORM_NAMES.nextDestinationAddress]: false,
        [FORM_NAMES.phone]: false,
      };

      if (nationalityCode === COUNTRY_CODES.uk) {
        fields = {
          ...fields,
          [FORM_NAMES.docNumber]: false,
        };
      }
      return fields;
    }
    case COUNTRY_CODES.belgium: {
      return {
        ...fields,
        [FORM_NAMES.residenceAddress]: false,
        [FORM_NAMES.residenceCity]: false,
      };
    }
    case COUNTRY_CODES.france: {
      return {
        ...fields,
        [FORM_NAMES.cityOfBirth]: false,
      };
    }
    case COUNTRY_CODES.germany: {
      return {
        ...fields,
        [FORM_NAMES.phone]: false,
      };
    }
    case COUNTRY_CODES.portugal: {
      return {
        ...fields,
        [FORM_NAMES.birthCountry]: false,
      };
    }
    case COUNTRY_CODES.colombia: {
      return {
        ...fields,
        [FORM_NAMES.secondSurname]: false,
      };
    }
    case COUNTRY_CODES.spain: {
      if (nationalityCode !== COUNTRY_CODES.spain) {
        return {
          ...fields,
          [FORM_NAMES.secondSurname]: false,
        };
      }
      return fields;
    }
    case COUNTRY_CODES.italy: {
      if (isContractEnabled) {
        return {
          ...fields,
          [FORM_NAMES.phone]: false,
        };
      }
      return fields;
    }
    case COUNTRY_CODES.greece: {
      fields = {
        ...fields,
        [FORM_NAMES.cityOfIssue]: false,
      };

      if (!isGuestLeader) {
        return {
          ...fields,
          [FORM_NAMES.signature]: false,
        };
      }
      return fields;
    }
    case COUNTRY_CODES.austria: {
      fields = {
        ...fields,
        [FORM_NAMES.email]: false,
        [FORM_NAMES.phone]: false,
        [FORM_NAMES.residencePostalCode]: [
          COUNTRY_CODES.austria,
          COUNTRY_CODES.germany,
        ].includes(formFields?.residenceCountry || '')
          ? (i18n.t('required') as string)
          : false,
      };

      if (guestGroupType === FAMILY_GROUP_TYPE) {
        if (!isGuestLeader) {
          return {
            ...fields,
            [FORM_NAMES.residenceCity]: false,
            [FORM_NAMES.residenceAddress]: false,
          };
        }
      }

      return fields;
    }
    case COUNTRY_CODES.slovenia: {
      return {
        ...INIT_REQUIRED_FIELDS,
        [FORM_NAMES.taxExemption]: false,
      };
    }
    case COUNTRY_CODES.croatia: {
      return {
        ...INIT_REQUIRED_FIELDS,
        [FORM_NAMES.residenceAddress]: false,
        [FORM_NAMES.cityOfBirth]: false,
      };
    }
    default: {
      return INIT_REQUIRED_FIELDS;
    }
  }
}

const INIT_DISPLAY_FIELDS = {
  isItalianCityOfBirthFieldVisible: false,
  isItalianCityOfIssueFieldVisible: false,
  isItalianResidenceCityFieldVisible: false,
  [FORM_NAMES.name]: true,
  [FORM_NAMES.sex]: true,
  [FORM_NAMES.birthDate]: true,
  [FORM_NAMES.nationality]: true,
  [FORM_NAMES.surname]: true,
  [FORM_NAMES.docType]: true,
  [FORM_NAMES.docNumber]: true,
  [FORM_NAMES.residenceCountry]: true,
  [FORM_NAMES.signature]: true,
  [FORM_NAMES.email]: true,
  [FORM_NAMES.docDateOfIssue]: false,
  [FORM_NAMES.secondSurname]: false,
  [FORM_NAMES.birthCountry]: false,
  [FORM_NAMES.residenceCity]: false,
  [FORM_NAMES.residenceAddress]: false,
  [FORM_NAMES.countryOfIssue]: false,
  [FORM_NAMES.cityOfIssue]: false,
  [FORM_NAMES.cityOfBirth]: false,
  [FORM_NAMES.nextDestinationCountry]: false,
  [FORM_NAMES.nextDestinationCity]: false,
  [FORM_NAMES.nextDestinationAddress]: false,
  [FORM_NAMES.residencePostalCode]: false,
  [FORM_NAMES.citizenship]: false,
  [FORM_NAMES.purposeOfStay]: false,
  [FORM_NAMES.visaNumber]: false,
  [FORM_NAMES.arrivalCountry]: false,
  [FORM_NAMES.arrivalDistrict]: false,
  [FORM_NAMES.arrivalMunicipality]: false,
  [FORM_NAMES.nextDestinationDistrict]: false,
  [FORM_NAMES.nextDestinationMunicipality]: false,
  [FORM_NAMES.terms]: false,
  [FORM_NAMES.taxExemption]: false,
  [FORM_NAMES.residenceProvince]: false,
  [FORM_NAMES.frontSideScan]: false,
  [FORM_NAMES.backSideScan]: false,
  [FORM_NAMES.phone]: false,
  [FORM_NAMES.fiscalCode]: false,
};

function getDisplayFields({
  housing,
  nationalityCode,
  formFields,
  isGuestLeader,
  hasAnyCompletePoliceGuest,
  guestGroupType,
  housingCountry,
  isGuestWithDoc,
  isEditingGuest,
}: FieldsDataTypes) {
  const housingCountryCode = housingCountry || getCountryCode(housing);
  const isContractEnabled = Boolean(housing?.is_contract_enabled);

  const isSignatureVisible = isGuestLeader && isContractEnabled;
  let fields = {
    ...INIT_DISPLAY_FIELDS,
    [FORM_NAMES.signature]: isSignatureVisible,
  };

  switch (housingCountryCode) {
    case COUNTRY_CODES.spain: {
      const isCaptureStatFieldsEnabled = getIsCaptureStatFieldsEnabled(housing);
      fields = {
        ...fields,
        [FORM_NAMES.secondSurname]: true,
        [FORM_NAMES.residenceCountry]: false,
        [FORM_NAMES.docDateOfIssue]: true,
        [FORM_NAMES.signature]: true,
      };

      if (isCaptureStatFieldsEnabled) {
        fields = {
          ...fields,
          [FORM_NAMES.residenceCountry]: true,
        };

        if (formFields?.residenceCountry === COUNTRY_CODES.spain) {
          fields = {
            ...fields,
            [FORM_NAMES.residenceCity]: true,
            [FORM_NAMES.residenceProvince]: true,
          };
        }
      }

      return fields;
    }
    case COUNTRY_CODES.portugal: {
      return {
        ...fields,
        [FORM_NAMES.birthCountry]: true,
        [FORM_NAMES.countryOfIssue]: true,
        [FORM_NAMES.sex]: false,
      };
    }
    case COUNTRY_CODES.italy: {
      const statType = getStatTypeIfStatActive(housing);

      fields = {
        ...fields,
        [FORM_NAMES.terms]: true,
        [FORM_NAMES.birthCountry]: true,
        [FORM_NAMES.docType]: false,
        [FORM_NAMES.docNumber]: false,
        [FORM_NAMES.residenceCountry]: false,
      };

      if (statType) {
        fields = {
          ...fields,
          [FORM_NAMES.residenceCountry]: true,
        };

        if (formFields?.residenceCountry === COUNTRY_CODES.italy) {
          fields = {
            ...fields,
            isItalianResidenceCityFieldVisible: true,
          };
        }

        if (statType === STAT_TYPES.valtellinaAbit) {
          fields = {
            ...fields,
            [FORM_NAMES.taxExemption]: true,
          };
        }
      }

      if (formFields?.birthCountry === COUNTRY_CODES.italy) {
        fields = {
          ...fields,
          isItalianCityOfBirthFieldVisible: true,
        };
      }

      const shouldShowDocFieldsWhileAddingNewGuest =
        hasAnyCompletePoliceGuest && !isEditingGuest;
      const shouldShowDocFields =
        isGuestLeader || isGuestWithDoc || shouldShowDocFieldsWhileAddingNewGuest;

      if (shouldShowDocFields) {
        fields = {
          ...fields,
          [FORM_NAMES.docType]: true,
          [FORM_NAMES.docNumber]: true,
          [FORM_NAMES.countryOfIssue]: true,
        };

        if (formFields?.countryOfIssue === COUNTRY_CODES.italy) {
          fields = {
            ...fields,
            isItalianCityOfIssueFieldVisible: true,
          };
        }
      }

      if (isContractEnabled && isGuestLeader) {
        fields = {
          ...fields,
          [FORM_NAMES.phone]: true,
          [FORM_NAMES.signature]: true,
          [FORM_NAMES.residenceCountry]: true,
          [FORM_NAMES.residenceCity]: true,
          [FORM_NAMES.residenceAddress]: true,
          [FORM_NAMES.residencePostalCode]: true,
        };

        if (nationalityCode === COUNTRY_CODES.italy) {
          fields = {
            ...fields,
            [FORM_NAMES.fiscalCode]: true,
          };
        }

        if (formFields?.residenceCountry === COUNTRY_CODES.italy) {
          fields = {
            ...fields,
            isItalianResidenceCityFieldVisible: true,
            [FORM_NAMES.residenceCity]: false,
          };
        }
      }

      return fields;
    }
    case COUNTRY_CODES.germany: {
      return {
        ...fields,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.phone]: true,
      };
    }
    case COUNTRY_CODES.dubai: {
      fields = {
        ...fields,
        [FORM_NAMES.terms]: true,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.residenceCountry]: false,
        [FORM_NAMES.frontSideScan]: true,
      };

      const docWithBackSideScan =
        formFields?.docType === documents.types.id ||
        formFields?.docType === documents.types.uaeResidencePermit;
      if (docWithBackSideScan) {
        fields = {
          ...fields,
          [FORM_NAMES.backSideScan]: true,
        };
      }

      return fields;
    }
    case COUNTRY_CODES.netherlands: {
      return {
        ...fields,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
      };
    }
    case COUNTRY_CODES.belgium: {
      return {
        ...fields,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
      };
    }
    case COUNTRY_CODES.france: {
      return {
        ...fields,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.birthCountry]: true,
        [FORM_NAMES.cityOfBirth]: true,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.docType]: false,
        [FORM_NAMES.docNumber]: false,
      };
    }
    case COUNTRY_CODES.uk: {
      fields = {
        ...fields,
        [FORM_NAMES.countryOfIssue]: true,
        [FORM_NAMES.nextDestinationCountry]: true,
        [FORM_NAMES.nextDestinationAddress]: true,
        [FORM_NAMES.nextDestinationCity]: true,
        [FORM_NAMES.phone]: true,
        [FORM_NAMES.residenceCountry]: false,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.birthDate]: false,
      };

      if (nationalityCode === COUNTRY_CODES.uk) {
        fields = {
          ...fields,
          [FORM_NAMES.countryOfIssue]: false,
          [FORM_NAMES.nextDestinationCountry]: false,
          [FORM_NAMES.nextDestinationCity]: false,
          [FORM_NAMES.nextDestinationAddress]: false,
        };
      }

      return fields;
    }
    case COUNTRY_CODES.austria: {
      fields = {
        ...fields,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.residencePostalCode]: true,
        [FORM_NAMES.countryOfIssue]: true,
        [FORM_NAMES.docDateOfIssue]: true,
        [FORM_NAMES.email]: true,
        [FORM_NAMES.phone]: true,
      };

      if (guestGroupType === FAMILY_GROUP_TYPE && !isGuestLeader) {
        fields = {
          ...fields,
          [FORM_NAMES.sex]: false,
          [FORM_NAMES.nationality]: false,
          [FORM_NAMES.signature]: false,
          [FORM_NAMES.docDateOfIssue]: false,
          [FORM_NAMES.countryOfIssue]: false,
          [FORM_NAMES.residenceAddress]: false,
        };
      }

      return fields;
    }
    case COUNTRY_CODES.czech: {
      fields = {
        ...fields,
        [FORM_NAMES.citizenship]: true,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.sex]: false,
      };

      if (
        formFields?.citizenship &&
        !EU_MEMBERS.includes(String(formFields.citizenship))
      ) {
        fields = {
          ...fields,
          [FORM_NAMES.visaNumber]: true,
        };
      }

      if (isGuestLeader) {
        fields = {
          ...fields,
          [FORM_NAMES.purposeOfStay]: true,
        };
      }

      return fields;
    }
    case COUNTRY_CODES.colombia: {
      fields = {
        ...fields,
        [FORM_NAMES.secondSurname]: true,
        [FORM_NAMES.arrivalCountry]: true,
        [FORM_NAMES.nextDestinationCountry]: true,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.residenceCountry]: false,
      };

      if (formFields?.arrivalCountry === COUNTRY_CODES.colombia) {
        fields = {
          ...fields,
          [FORM_NAMES.arrivalDistrict]: true,
          [FORM_NAMES.arrivalMunicipality]: true,
        };
      }

      if (formFields?.nextDestinationCountry === COUNTRY_CODES.colombia) {
        fields = {
          ...fields,
          [FORM_NAMES.nextDestinationDistrict]: true,
          [FORM_NAMES.nextDestinationMunicipality]: true,
        };
      }

      return fields;
    }
    case COUNTRY_CODES.romania: {
      return {
        ...fields,
        [FORM_NAMES.sex]: false,
        [FORM_NAMES.birthCountry]: true,
        [FORM_NAMES.cityOfBirth]: true,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.purposeOfStay]: true,
      };
    }
    case COUNTRY_CODES.thailand: {
      fields = {
        ...fields,
        [FORM_NAMES.terms]: true,
        [FORM_NAMES.residenceCountry]: false,
        [FORM_NAMES.frontSideScan]: true,
      };
      if (isGuestLeader) {
        fields = {
          ...fields,
          [FORM_NAMES.email]: true,
          [FORM_NAMES.phone]: true,
        };
      }
      return fields;
    }
    case COUNTRY_CODES.greece: {
      return {
        ...fields,
        [FORM_NAMES.countryOfIssue]: true,
        [FORM_NAMES.email]: true,
        [FORM_NAMES.phone]: true,
        [FORM_NAMES.docDateOfIssue]: true,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.residencePostalCode]: true,
        [FORM_NAMES.cityOfIssue]: formFields?.countryOfIssue === COUNTRY_CODES.greece,
      };
    }
    case COUNTRY_CODES.slovenia: {
      return {
        ...fields,
        [FORM_NAMES.residenceCountry]: false,
        [FORM_NAMES.taxExemption]: true,
        [FORM_NAMES.fullTouristTax]: true,
      };
    }
    case COUNTRY_CODES.croatia: {
      return {
        ...fields,
        [FORM_NAMES.residenceCity]: true,
        [FORM_NAMES.residenceAddress]: true,
        [FORM_NAMES.birthCountry]: true,
        [FORM_NAMES.cityOfBirth]: true,
        [FORM_NAMES.citizenship]: true,
        [FORM_NAMES.nationality]: false,
        [FORM_NAMES.email]: false,
      };
    }
    default: {
      return fields;
    }
  }
}

type DisplayFields = {
  [key: string]: boolean;
};

function getFields(data: FieldsDataTypes) {
  const display: DisplayFields = getDisplayFields(data);
  const required: RequiredFields = getRequiredFields(data);

  return {display, required};
}

type GuestDetailsFormProps = {
  disabled?: boolean;
  initGuestData?: Guest;
};

const defaultProps: Partial<GuestDetailsFormProps> = {
  disabled: false,
  initGuestData: undefined,
};

type LocationState = {
  formData?: any;
};

function fetchGuestGroup(key: string, id: string) {
  return queryFetcher(api.guestGroups.ENDPOINTS.all(id));
}

function fetchHousing(key: string, id = '') {
  return queryFetcher(api.housings.ENDPOINTS.one(id));
}

function getIsItalianLeaderWithMembers(
  housingCountry?: string,
  guestGroup?: GuestGroup,
  guestId?: string,
) {
  if (!housingCountry || !guestGroup || !guestId) {
    return false;
  }

  const guestLeader = getGuestLeader(guestGroup);
  return (
    guestLeader?.id === guestId &&
    getGroupMembersNumber(guestGroup) > MIN_MEMBERS_NUMBER &&
    housingCountry === COUNTRY_CODES.italy
  );
}

// const RESERVATION_ID_UPDATE_FIELDS = [
//   'arrived_from',
//   'tax_exemption',
//   'purpose_of_stay',
//   'next_destination',
// ];

function GuestDetailsForm({disabled}: GuestDetailsFormProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const {isNeedToAskForSubscription} = useComputedDetails();

  const {id: reservationId, guestId} = useParams();

  React.useLayoutEffect(
    function redirect() {
      const isAccessDenied = isNeedToAskForSubscription || !reservationId;
      if (isAccessDenied) {
        history.push('/bookings');
      }
    },
    [history, isNeedToAskForSubscription, reservationId],
  );

  const {
    formState,
    control,
    watch,
    register,
    errors,
    getValues,
    triggerValidation,
    setValue,
    unregister,
    reset,
    handleSubmit,
  } = useForm<FormTypes>();
  const prevFormState = usePrevious<typeof formState>(formState);
  const isMounted = useIsMounted();
  const {ErrorModal, displayError, closeErrorModal} = useErrorModal();
  const {
    ErrorModal: SavingTryAgainErrorModal,
    displayError: displayTryAgainSavingError,
    closeErrorModal: closeSavingTryAgainErrorModal,
  } = useErrorModal();
  const {
    ErrorModal: SavingErrorModal,
    displayError: displaySavingError,
  } = useErrorModal();
  const {setupAbortController, abortController} = useAbortController();

  const {
    openModal: openDataIncompleteModal,
    closeModal: closeDataIncompleteModal,
    isOpen: isDataIncompleteModalOpen,
  } = useModalControls();
  const {
    isOpen: isScanDocumentsModalOpen,
    closeModal: closeScanDocumentsModal,
    openModal: openScanDocumentsModal,
  } = useModalControls();
  const {
    isOpen: isUploadDocumentsModalOpen,
    closeModal: closeUploadDocumentsModal,
    openModal: openUploadDocumentsModal,
  } = useModalControls();
  const {
    isOpen: isScanModalOpen,
    closeModal: closeScanModal,
    openModal: openScanModal,
  } = useModalControls();
  const {
    isOpen: isScanProcessingModalOpen,
    closeModal: closeScanProcessingModal,
    openModal: openScanProcessingModal,
  } = useModalControls();
  const {
    isOpen: isScanSuccessModalOpen,
    closeModal: closeScanSuccessModal,
    openModal: openScanSuccessModal,
  } = useModalControls();
  const {
    isOpen: isSignatureModalOpen,
    closeModal: closeSignatureModal,
    openModal: openSignatureModal,
  } = useModalControls();
  const {
    openModal: openDeleteGuestModal,
    closeModal: closeDeleteGuestModal,
    isOpen: isDeleteGuestModalOpen,
  } = useModalControls();
  const {
    openModal: openSavingGuestModal,
    closeModal: closeSavingGuestModal,
    isOpen: isSavingGuestModalOpen,
  } = useModalControls();
  const {
    setStatus: setDeleteGuestStatus,
    isLoading: isDeletingGuest,
    isSuccess: isGuestDeleted,
    isIdle: isGuestDeletionIdle,
  } = useStatus();
  const {
    setStatus: setSavingGuestStatus,
    isLoading: isSavingGuest,
    isSuccess: isGuestSaved,
  } = useStatus();

  const {
    openModal: openViewDocumentsModal,
    closeModal: closeViewDocumentsModal,
    isOpen: isViewDocumentsModalOpen,
  } = useModalControls();
  const {
    openModal: openConfirmDocumentDeletionModal,
    closeModal: closeConfirmDocumentDeletionModal,
    isOpen: isConfirmDocumentDeletionModalOpen,
  } = useModalControls();

  const timeoutRef = React.useRef<number>();
  const signatureRef = React.useRef<ReactSignatureCanvas>(null);
  const webcamRef = React.useRef<WebcamRefTypes>(null);

  const [isValidGuestId, setIsValidGuestId] = React.useState(true);
  const [isSignatureEnabled, setIsSignatureEnabled] = React.useState(false);
  const [initGuestData, setInitGuestData] = React.useState<Guest | null>(null);
  const [isGuestWithDoc, setIsGuestWithDoc] = React.useState(false);
  const [isGuestLeader, setIsGuestLeader] = React.useState(false);
  const [hasAnyCompletePoliceGuest, setHasAnyCompletePoliceGuest] = React.useState(false);
  const [areFieldsLoaded, setAreFieldsLoaded] = React.useState(false);
  const [isNationalityPreloaded, setIsNationalityPreloaded] = React.useState(false);
  const [isWebcamReady, setIsWebcamReady] = React.useState(false);
  const [wasDataIncompleteModalOpened, setWasDataIncompleteModalOpened] = React.useState(
    false,
  );
  const [documentSaveStep, setDocumentSaveStep] = React.useState(0);
  const [isLocationDataPreloaded, setIsLocationDataPreloaded] = React.useState(false);
  const [ocrWasUsed, setOcrWasUsed] = React.useState(false);

  const docType = watch(FORM_NAMES.docType)?.value;
  const nationality = watch(FORM_NAMES.nationality)?.value;
  const arrivalDistrict = watch(FORM_NAMES.arrivalDistrict)?.value || '';
  const arrivalMunicipality = watch(FORM_NAMES.arrivalMunicipality)?.value;
  const nextDestinationDistrict = watch(FORM_NAMES.nextDestinationDistrict)?.value || '';
  const nextDestinationMunicipality = watch(FORM_NAMES.nextDestinationMunicipality)
    ?.value;
  const birthCountry = watch(FORM_NAMES.birthCountry)?.value;
  const countryOfIssue = watch(FORM_NAMES.countryOfIssue)?.value;
  const arrivalCountry = watch(FORM_NAMES.arrivalCountry)?.value;
  const nextDestinationCountry = watch(FORM_NAMES.nextDestinationCountry)?.value;
  const residenceCountry = watch(FORM_NAMES.residenceCountry)?.value;
  const citizenship = watch(FORM_NAMES.citizenship)?.value;
  const signature = watch(FORM_NAMES.signature);
  const guestName = watch(FORM_NAMES.name);
  const frontSideScan = watch(FORM_NAMES.frontSideScan);
  const backSideScan = watch(FORM_NAMES.backSideScan);

  const isEditingGuest = guestId && isValidGuestId;

  const {
    status: reservationStatus,
    data: reservation,
    error: reservationError,
    refetch: refetchReservation,
  } = useQuery<LightReservation, string>(reservationId, fetchReservation, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(reservationError, {
    notFoundMessage: t('errors.requested_booking_not_found'),
  });

  const guestGroupId = reservation?.guest_group_id;
  const {
    data: guestGroup,
    refetch: refetchGuestGroup,
    error: guestGroupError,
    status: guestGroupStatus,
  } = useQuery<GuestGroup, [string, string]>(
    Boolean(guestGroupId) && ['guestGroup', guestGroupId!],
    fetchGuestGroup,
  );
  useErrorToast(guestGroupError, {
    notFoundMessage: t('errors.requested_guest_group_not_found'),
  });

  const housingId = reservation?.housing_id;
  const {data: queryHousing, status: housingStatus, error: housingError} = useQuery<
    Housing,
    [string, string]
  >(Boolean(housingId) && ['housing', housingId!], fetchHousing, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(housingError, {
    notFoundMessage: t('errors.requested_housing_not_found'),
  });

  const housingCountry =
    location.state?.formData?.housing_id?.country || getCountryCode(queryHousing);
  const housing = location.state?.formData?.housing_id?.data || queryHousing || null;
  const guestGroupType = guestGroup?.type;
  const hasItalianLeaderWithGuestMembers = getIsItalianLeaderWithMembers(
    housingCountry,
    guestGroup,
    guestId,
  );

  const {data: locations, error: locationsError, status: locationsStatus} = useQuery(
    'locations',
    fetchLocations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  useErrorToast(locationsError, {
    notFoundMessage: t('errors.requested_locations_not_found'),
  });
  const {data: provinces, error: provincesError} = useQuery(
    getIsNeedToFetchProvinces(housingCountry, getIsCaptureStatFieldsEnabled(housing)) && [
      'provinces',
      `country=${housingCountry}&ordering=name`,
    ],
    fetchLocations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  useErrorToast(provincesError, {
    notFoundMessage: t('errors.requested_provinces_not_found'),
  });
  const {data: districts, error: districtsError} = useQuery(
    getIsNeedToFetchDistricts(housingCountry) && [
      'districts',
      `country=${housingCountry}&ordering=name&division_level=1`,
    ],
    fetchLocations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  useErrorToast(districtsError, {
    notFoundMessage: t('errors.requested_districts_not_found'),
  });
  const {data: cities, error: citiesError, status: citiesStatus} = useQuery(
    getIsNeedToFetchCities(housingCountry) && [
      'cities',
      `country=${housingCountry}&ordering=name&division_level=3`,
    ],
    fetchLocations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  useErrorToast(citiesError, {
    notFoundMessage: t('errors.requested_cities_not_found'),
  });
  const {data: purposesOfStay, error: purposesOfStayError} = useQuery(
    getIsNeedToFetchPurposesOfStay(housingCountry, isGuestLeader) && [
      'purposesOfStay',
      `country=${housingCountry}`,
    ],
    fetchPurposesOfStay,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  useErrorToast(purposesOfStayError, {
    notFoundMessage: t('errors.requested_purposes_of_stay_not_found'),
  });
  const {data: taxExemptions, error: taxExemptionsError} = useQuery(
    getIsNeedToFetchTaxExemptions(housingCountry, housing) && [
      'taxExemptions',
      getStatTypeExemptionTypeParams(housingCountry),
    ],
    fetchTaxExemptions,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  useErrorToast(taxExemptionsError, {
    notFoundMessage: t('errors.requested_tax_exemptions_not_found'),
  });
  const {data: arrivalMunicipalities, error: arrivalMunicipalitiesError} = useQuery(
    getIsNeedToFetchMunicipalities(housingCountry) && [
      'arrivalMunicipalities',
      `country=${housingCountry}&ordering=name&division_level=2&department=${arrivalDistrict}`,
    ],
    fetchLocations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  const prevArrivalMunicipalities = usePrevious(arrivalMunicipalities);
  useErrorToast(arrivalMunicipalitiesError, {
    notFoundMessage: t('errors.requested_municipalities_not_found'),
  });
  const {
    data: nextDestinationMunicipalities,
    error: nextDestinationMunicipalitiesError,
  } = useQuery(
    getIsNeedToFetchMunicipalities(housingCountry) && [
      'nextDestinationMunicipalities',
      `country=${housingCountry}&ordering=name&division_level=2&department=${nextDestinationDistrict}`,
    ],
    fetchLocations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );
  const prevNextDestinationMunicipalities = usePrevious(nextDestinationMunicipalities);
  useErrorToast(nextDestinationMunicipalitiesError, {
    notFoundMessage: t('errors.requested_municipalities_not_found'),
  });

  const locationsAsOptions = React.useMemo(() => {
    return getLocationsAsOptions(locations);
  }, [locations]);
  const provincesAsOptions = React.useMemo(() => {
    return getProvincesAsOptions(provinces);
  }, [provinces]);
  const districtsAsOptions = React.useMemo(() => {
    return getDistrictsAsOptions(districts);
  }, [districts]);
  const citiesAsOptions = React.useMemo(() => {
    return getCitiesAsOptions(cities);
  }, [cities]);
  const arrivalMunicipalitiesAsOptions = React.useMemo(() => {
    return getProvincesAsOptions(arrivalMunicipalities);
  }, [arrivalMunicipalities]);
  const nextDestinationMunicipalitiesAsOptions = React.useMemo(() => {
    return getProvincesAsOptions(nextDestinationMunicipalities);
  }, [nextDestinationMunicipalities]);
  const purposesOfStayAsOptions = React.useMemo(() => {
    return getPurposesOfStayAsOptions(purposesOfStay);
  }, [purposesOfStay]);
  const taxExemptionsAsOptions = React.useMemo(() => {
    return getTaxExemptionsAsOptions(taxExemptions);
  }, [taxExemptions]);

  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const handleDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (PDF_UPLOADING_ALLOWED_COUNTRIES.includes(housingCountry)) {
        if (!/(\.jpg|\.png|\.pdf)/.test(file?.name)) {
          toast.error(
            addSupportEmailToMessage(
              t('errors.incorrect_file_type_only_pdf_and_file_types_allowed'),
            ),
          );
          return;
        }

        setUploadedFile(file);
        return;
      }

      if (!/(\.jpg|\.png)/.test(file?.name)) {
        toast.error(
          addSupportEmailToMessage(
            t('errors.incorrect_file_type_only_file_types_allowed'),
          ),
        );
        return;
      }

      setUploadedFile(file);
    },
    [t, housingCountry],
  );

  const {
    getInputProps: getDropZoneInputProps,
    getRootProps: getDropZoneRootProps,
    isDragActive: isDropZoneDragActive,
  } = useDropzone({
    onDrop: handleDrop,
  });
  const {getInputProps, getRootProps, isDragActive} = useDropzone({
    onDrop: handleDrop,
  });

  const [fields, setFields] = React.useState(() => {
    return getFields({
      housing,
      isGuestLeader,
      hasAnyCompletePoliceGuest,
      housingCountry,
      nationalityCode: nationality,
      guestGroupType,
      isGuestWithDoc,
      isEditingGuest,
      formFields: {
        [FORM_NAMES.birthCountry]: birthCountry,
        [FORM_NAMES.countryOfIssue]: countryOfIssue,
        [FORM_NAMES.citizenship]: citizenship,
        [FORM_NAMES.arrivalCountry]: arrivalCountry,
        [FORM_NAMES.nextDestinationCountry]: nextDestinationCountry,
        [FORM_NAMES.residenceCountry]: residenceCountry,
        [FORM_NAMES.docType]: docType,
      },
    });
  });
  const [docTypes, setDocTypes] = React.useState(() => {
    return getDocTypes(housingCountry, nationality, residenceCountry);
  });

  React.useEffect(
    function updateFields() {
      if (!reservation) {
        return;
      }

      const nextFields = getFields({
        isGuestLeader,
        hasAnyCompletePoliceGuest,
        housing,
        housingCountry,
        guestGroupType,
        nationalityCode: nationality,
        isGuestWithDoc,
        isEditingGuest,
        formFields: {
          [FORM_NAMES.birthCountry]: birthCountry,
          [FORM_NAMES.countryOfIssue]: countryOfIssue,
          [FORM_NAMES.citizenship]: citizenship,
          [FORM_NAMES.arrivalCountry]: arrivalCountry,
          [FORM_NAMES.nextDestinationCountry]: nextDestinationCountry,
          [FORM_NAMES.residenceCountry]: residenceCountry,
          [FORM_NAMES.docType]: docType,
        },
      });
      setFields(nextFields);
      setAreFieldsLoaded(true);
    },
    [
      housingCountry,
      birthCountry,
      countryOfIssue,
      citizenship,
      arrivalCountry,
      nextDestinationCountry,
      residenceCountry,
      housing,
      nationality,
      isGuestLeader,
      areFieldsLoaded,
      reservation,
      guestGroupType,
      docType,
      hasAnyCompletePoliceGuest,
      isGuestWithDoc,
      isEditingGuest,
    ],
  );

  React.useEffect(
    function preloadInitGuestData() {
      if (!guestId || !guestGroup) {
        return;
      }

      const {isLeader, data} = getGuestDetails({guestGroup, guestId});
      setIsGuestLeader(isLeader);

      const hasAnyComplete = hasAnyCompletedGuest(getGuestPoliceStatusInCode, guestGroup);
      setHasAnyCompletePoliceGuest(hasAnyComplete);

      if (!data) {
        setIsValidGuestId(false);
        return;
      }
      setInitGuestData(data);
    },
    [guestGroup, guestId],
  );

  React.useEffect(
    function revalidateFormOnFieldsChange() {
      if (formState.isSubmitted) {
        triggerValidation();
      }
    },
    [triggerValidation, fields, formState.isSubmitted],
  );

  React.useEffect(
    function preloadGuestData() {
      if (!initGuestData) {
        return;
      }

      const visa = initGuestData?.documents?.find((d) => {
        return d.type === CARD_BRANDS.visa;
      });
      setIsGuestWithDoc(Boolean(initGuestData?.document));

      reset({
        ...getValues(),
        [FORM_NAMES.sex]: GENDERS_OPTIONS.find((o) => o.value === initGuestData?.gender),
        [FORM_NAMES.name]: initGuestData?.name,
        [FORM_NAMES.surname]: initGuestData?.surname,
        [FORM_NAMES.secondSurname]: initGuestData?.second_surname,
        [FORM_NAMES.birthDate]: initGuestData?.birth_date
          ? new Date(initGuestData?.birth_date)
          : undefined,
        [FORM_NAMES.docDateOfIssue]: initGuestData?.document?.issue_date
          ? new Date(initGuestData?.document?.issue_date)
          : undefined,
        [FORM_NAMES.docNumber]: initGuestData?.document?.number,
        [FORM_NAMES.residenceCity]: initGuestData?.residence?.city,
        [FORM_NAMES.cityOfBirth]: initGuestData?.birth_place?.city,
        [FORM_NAMES.residenceAddress]: initGuestData?.residence?.address,
        [FORM_NAMES.nextDestinationAddress]: initGuestData?.next_destination?.address,
        [FORM_NAMES.nextDestinationCity]: initGuestData?.next_destination?.city,
        [FORM_NAMES.residencePostalCode]: initGuestData?.residence?.postal_code,
        [FORM_NAMES.visaNumber]: visa?.number,
        [FORM_NAMES.cityOfIssue]: initGuestData?.document?.issue_place?.city,
        [FORM_NAMES.email]: initGuestData?.email,
        [FORM_NAMES.phone]: initGuestData?.phone,
        [FORM_NAMES.fiscalCode]: initGuestData?.fiscal_code,
        [FORM_NAMES.fullTouristTax]: initGuestData?.full_tourist_tax,
      });
    },
    [initGuestData, setValue, areFieldsLoaded, reset, getValues],
  );

  React.useEffect(
    function preloadNationality() {
      if (!locationsAsOptions?.length || !areFieldsLoaded) {
        return;
      }

      const nationality = locationsAsOptions.find(
        (l) => l.value === initGuestData?.nationality?.code,
      );
      reset({
        ...getValues(),
        [FORM_NAMES.nationality]: nationality,
      });

      setIsNationalityPreloaded(true);
    },
    [locationsAsOptions, initGuestData, setValue, areFieldsLoaded, reset, getValues],
  );

  React.useEffect(
    function preloadLocationData() {
      if (!initGuestData || !isNationalityPreloaded) {
        return;
      }

      const birthCountry = locationsAsOptions.find(
        (l) => l.value === initGuestData?.birth_place?.country?.code,
      );
      const docType = docTypes.find((t) => t?.value === initGuestData?.document?.type);
      const residenceCountry = locationsAsOptions.find(
        (l) => l.value === initGuestData?.residence?.country?.code,
      );
      const countryOfIssue = locationsAsOptions.find(
        (l) => l.value === initGuestData?.document?.issue_place?.country?.code,
      );
      const nextDestinationCountry = locationsAsOptions.find(
        (l) => l.value === initGuestData?.next_destination?.country?.code,
      );
      const citizenship = locationsAsOptions.find(
        (l) => l.value === initGuestData?.citizenship?.code,
      );
      const arrivalCountry = locationsAsOptions.find(
        (l) => l.value === initGuestData?.arrived_from?.country?.code,
      );

      reset({
        ...getValues(),
        [FORM_NAMES.birthCountry]: birthCountry,
        [FORM_NAMES.docType]: docType,
        [FORM_NAMES.residenceCountry]: residenceCountry,
        [FORM_NAMES.countryOfIssue]: countryOfIssue,
        [FORM_NAMES.nextDestinationCountry]: nextDestinationCountry,
        [FORM_NAMES.citizenship]: citizenship,
        [FORM_NAMES.arrivalCountry]: arrivalCountry,
      });
      setIsLocationDataPreloaded(true);
    },
    [
      docTypes,
      getValues,
      initGuestData,
      isNationalityPreloaded,
      locationsAsOptions,
      reset,
      setValue,
    ],
  );

  React.useEffect(
    function preloadCitiesData() {
      if (
        !citiesAsOptions?.length ||
        !isNationalityPreloaded ||
        !initGuestData ||
        !isLocationDataPreloaded
      ) {
        return;
      }

      const cityOfBirth = citiesAsOptions.find(
        (c) => c.value === initGuestData?.birth_place?.division_level_3,
      );
      if (cityOfBirth) {
        reset({
          ...getValues(),
          [FORM_NAMES.cityOfBirth]: cityOfBirth,
        });
      }

      const residenceCity = citiesAsOptions.find(
        (c) => c.value === initGuestData?.residence?.division_level_3,
      );
      if (residenceCity) {
        reset({
          ...getValues(),
          [FORM_NAMES.residenceCity]: residenceCity,
        });
      }

      const cityOfIssue = citiesAsOptions.find(
        (c) => c.value === initGuestData?.document?.issue_place?.division_level_3,
      );
      if (cityOfIssue) {
        reset({
          ...getValues(),
          [FORM_NAMES.cityOfIssue]: cityOfIssue,
        });
      }
    },
    [
      citiesAsOptions,
      getValues,
      initGuestData,
      isNationalityPreloaded,
      reset,
      setValue,
      citiesStatus,
      isLocationDataPreloaded,
      citiesStatus,
    ],
  );

  React.useEffect(
    function preloadDistrictsData() {
      if (!districtsAsOptions?.length || !isNationalityPreloaded) {
        return;
      }

      const arrivalDistrict = districtsAsOptions.find(
        (d) => d.value === initGuestData?.arrived_from?.division_lvl_1?.code,
      );
      if (arrivalDistrict) {
        reset({
          ...getValues(),
          [FORM_NAMES.arrivalDistrict]: arrivalDistrict,
        });
      }

      const nextDestinationDistrict = districtsAsOptions.find(
        (d) => d.value === initGuestData?.next_destination?.divistion_level_1?.code,
      );
      if (nextDestinationDistrict) {
        reset({
          ...getValues(),
          [FORM_NAMES.nextDestinationDistrict]: nextDestinationDistrict,
        });
      }
    },
    [
      districtsAsOptions,
      getValues,
      initGuestData,
      isNationalityPreloaded,
      reset,
      setValue,
    ],
  );

  React.useEffect(
    function preloadProvincesData() {
      if (!provincesAsOptions?.length || !isNationalityPreloaded) {
        return;
      }

      const residenceProvince = provincesAsOptions.find(
        (p) => p?.value === initGuestData?.residence?.division_level_2,
      );
      if (residenceProvince) {
        reset({
          ...getValues(),
          [FORM_NAMES.residenceProvince]: residenceProvince,
        });
      }
    },
    [
      getValues,
      initGuestData,
      isNationalityPreloaded,
      provincesAsOptions,
      reset,
      setValue,
    ],
  );

  React.useEffect(
    function preloadPurposeOfStay() {
      if (!purposesOfStayAsOptions?.length || !isNationalityPreloaded) {
        return;
      }
      const purposeOfStay = purposesOfStayAsOptions.find(
        (p) => p?.value === initGuestData?.purpose_of_stay,
      );
      if (purposeOfStay) {
        reset({
          ...getValues(),
          [FORM_NAMES.purposeOfStay]: purposeOfStay,
        });
      }
    },
    [initGuestData, isNationalityPreloaded, purposesOfStayAsOptions, reset, getValues],
  );

  React.useEffect(
    function preloadTaxExemptions() {
      if (!taxExemptionsAsOptions?.length || !isNationalityPreloaded) {
        return;
      }
      const taxExemption = taxExemptionsAsOptions.find(
        (t) => t?.value === initGuestData?.tax_exemption,
      );
      if (taxExemption) {
        reset({
          ...getValues(),
          [FORM_NAMES.taxExemption]: taxExemption,
        });
      }
    },
    [taxExemptionsAsOptions, isNationalityPreloaded, initGuestData, reset, getValues],
  );

  React.useEffect(
    function preloadSignature() {
      if (!initGuestData?.signature) {
        return;
      }

      reset({
        ...getValues(),
        [FORM_NAMES.signature]: initGuestData.signature,
      });
    },
    [initGuestData, getValues, reset],
  );

  React.useEffect(
    function preloadDocuments() {
      if (
        (!initGuestData?.document?.front_side_scan &&
          !initGuestData?.document?.back_side_scan) ||
        !isNationalityPreloaded ||
        !isLocationDataPreloaded
      ) {
        return;
      }

      reset({
        ...getValues(),
        [FORM_NAMES.frontSideScan]: initGuestData?.document?.front_side_scan,
        [FORM_NAMES.backSideScan]: initGuestData?.document?.back_side_scan,
      });
    },
    [
      initGuestData,
      setValue,
      getValues,
      reset,
      isNationalityPreloaded,
      isLocationDataPreloaded,
    ],
  );

  const isSignatureVisible = fields.display[FORM_NAMES.signature];
  const isSignatureRequired = fields.required[FORM_NAMES.signature];
  React.useEffect(
    function registerSignature() {
      if (isSignatureVisible) {
        register(FORM_NAMES.signature, {
          required: isSignatureRequired,
        });
      } else {
        unregister(FORM_NAMES.signature);
      }
    },
    [register, isSignatureRequired, isSignatureVisible, unregister],
  );

  const isFrontSideScanVisible = fields.display[FORM_NAMES.frontSideScan];
  const isFrontSideScanRequired = fields.required[FORM_NAMES.frontSideScan];
  React.useEffect(
    function registerFrontSideScan() {
      if (isFrontSideScanVisible) {
        register(FORM_NAMES.frontSideScan, {
          required: isFrontSideScanRequired,
        });
      } else {
        unregister(FORM_NAMES.frontSideScan);
      }
    },
    [register, t, isFrontSideScanVisible, unregister, isFrontSideScanRequired],
  );

  const isBackSideScanVisible = fields.display[FORM_NAMES.backSideScan];
  const isBackSideScanRequired = fields.required[FORM_NAMES.backSideScan];
  React.useEffect(
    function registerBackSideScan() {
      if (isBackSideScanVisible) {
        register(FORM_NAMES.backSideScan, {
          required: isBackSideScanRequired,
        });
      } else {
        unregister(FORM_NAMES.backSideScan);
      }
    },
    [register, t, unregister, isBackSideScanVisible, isBackSideScanRequired],
  );

  React.useEffect(
    function revalidateSignatureOnChange() {
      if (formState.isSubmitted) {
        triggerValidation(FORM_NAMES.signature);
      }
    },
    [formState.isSubmitted, triggerValidation, signature],
  );

  React.useEffect(
    function setDocTypesOnNationalityOrReservationChange() {
      const nextDocTypes = getDocTypes(
        housingCountry,
        nationality,
        residenceCountry,
        citizenship,
      );
      const shouldResetDocType = getShouldResetDocTypes(
        nextDocTypes,
        getValues()[FORM_NAMES.docType] as SelectOption,
      );

      if (shouldResetDocType) {
        setValue(FORM_NAMES.docType, undefined);
      }
      setDocTypes(nextDocTypes);
    },
    [citizenship, nationality, setValue, getValues, housingCountry, residenceCountry],
  );

  React.useEffect(
    function revalidateDocNumberOnDocTypeChange() {
      if (formState.isSubmitted) {
        triggerValidation(FORM_NAMES.docNumber);
      }
    },
    [docType, formState.isSubmitted, triggerValidation],
  );

  React.useEffect(
    function revalidateDocumentPhotosOnChange() {
      if (formState.isSubmitted) {
        triggerValidation([FORM_NAMES.frontSideScan, FORM_NAMES.backSideScan]);
      }
    },
    [frontSideScan, backSideScan, formState.isSubmitted, triggerValidation],
  );

  React.useEffect(
    function resetArrivalMunicipalityIfNotFoundInNewOptions() {
      const isLoadingMunicipalities =
        arrivalMunicipality &&
        prevArrivalMunicipalities &&
        !arrivalMunicipalitiesAsOptions.length;

      if (isLoadingMunicipalities) {
        return;
      }

      const hasOption = arrivalMunicipalitiesAsOptions.some((m) => {
        return m.value === arrivalMunicipality;
      });

      if (!hasOption) {
        setValue(FORM_NAMES.arrivalMunicipality, undefined);
      }
    },
    [
      setValue,
      arrivalMunicipalitiesAsOptions,
      arrivalMunicipality,
      prevArrivalMunicipalities,
    ],
  );

  React.useEffect(
    function resetNextDestinationMunicipalityIfNotFoundInNewOptions() {
      const isLoadingMunicipalities =
        nextDestinationMunicipality &&
        prevNextDestinationMunicipalities &&
        !nextDestinationMunicipalitiesAsOptions.length;

      if (isLoadingMunicipalities) {
        return;
      }

      const hasOption = nextDestinationMunicipalitiesAsOptions.some((m) => {
        return m.value === nextDestinationMunicipality;
      });

      if (!hasOption) {
        setValue(FORM_NAMES.nextDestinationMunicipality, undefined);
      }
    },
    [
      setValue,
      nextDestinationMunicipalitiesAsOptions,
      nextDestinationMunicipality,
      prevNextDestinationMunicipalities,
    ],
  );

  React.useLayoutEffect(
    function showIncompleteModalOneTime() {
      const shouldOpenModal =
        formState.isSubmitted && !prevFormState?.isValid && !formState.isValid;

      if (shouldOpenModal && !wasDataIncompleteModalOpened) {
        openDataIncompleteModal();
        setWasDataIncompleteModalOpened(true);
      }
    },
    [
      formState.isSubmitted,
      formState.isValid,
      prevFormState,
      wasDataIncompleteModalOpened,
      openDataIncompleteModal,
    ],
  );

  const goBack = () => {
    history.push(`/bookings/${reservationId}`, location.state);
  };

  const renderScanView = (document: string) => {
    if (document.startsWith('data:application/pdf;')) {
      return <SaveGuestText>{t('save_guest_to_open_doc')}</SaveGuestText>;
    }

    if (/\.pdf/.test(document)) {
      return (
        <ViewDocumentLink href={document} target="_blank">
          {t('open_document')}
        </ViewDocumentLink>
      );
    }
    return <DocumentPhoto src={document} alt="Document" />;
  };

  const renderUploadedFile = () => {
    if (!uploadedFile) {
      return null;
    }

    if (/\.pdf/.test(uploadedFile?.name)) {
      return <UploadedPDFWrapper>{uploadedFile.name}</UploadedPDFWrapper>;
    }

    const url = URL.createObjectURL(uploadedFile);
    return (
      <UploadedImageWrapper>
        <img src={url} alt="Document" />
      </UploadedImageWrapper>
    );
  };

  const saveDocumentFile = async () => {
    if (!uploadedFile) {
      displayError('Uploaded file is missing. Please contact support.');
      return;
    }

    getBase64(uploadedFile).then(
      (file) => {
        if (documentSaveStep === 0) {
          setUploadedFile(null);
          setValue(FORM_NAMES.frontSideScan, file);

          if (fields.display[FORM_NAMES.backSideScan]) {
            setDocumentSaveStep(1);
            return;
          }
        }

        if (fields.display[FORM_NAMES.backSideScan] && documentSaveStep === 1) {
          setUploadedFile(null);
          setValue(FORM_NAMES.backSideScan, file);
        }

        closeUploadDocumentsModal();
      },
      (error) => {
        displayError(error);
      },
    );
  };

  const handleWebcamReady = () => {
    setIsWebcamReady(true);
  };

  const closeSaveDocumentsModalAndResetDocumentStep = () => {
    setDocumentSaveStep(0);
    closeScanDocumentsModal();
  };

  const openSaveDocumentModalAndResetActiveStep = () => {
    setDocumentSaveStep(0);
    openScanDocumentsModal();
  };

  const saveDocumentPhoto = () => {
    const photo = webcamRef?.current?.getScreenshot();

    if (documentSaveStep === 0) {
      setValue(FORM_NAMES.frontSideScan, photo);

      if (fields.display[FORM_NAMES.backSideScan]) {
        setDocumentSaveStep(1);
        return;
      }
    }

    if (fields.display[FORM_NAMES.backSideScan] && documentSaveStep === 1) {
      setValue(FORM_NAMES.backSideScan, photo);
    }

    closeSaveDocumentsModalAndResetDocumentStep();
  };

  const clearDocumentPhotosAndCloseDeleteConfirmationModal = () => {
    setValue(FORM_NAMES.frontSideScan, null);
    setValue(FORM_NAMES.backSideScan, null);
    closeConfirmDocumentDeletionModal();
  };

  const abortRequestAndOpenScanModal = () => {
    abortController.abort();
    openScanModal();
  };

  const closeErrorModalAndReopenScanModal = () => {
    closeErrorModal();
    openScanModal();
  };

  const handleSignatureEnable = () => {
    setIsSignatureEnabled(true);
  };

  const saveSignature = () => {
    const canvasSignature = signatureRef.current?.getCanvas()?.toDataURL();
    setValue(FORM_NAMES.signature, canvasSignature);
  };

  const clearSignature = () => {
    signatureRef.current?.clear();
    setValue(FORM_NAMES.signature, null);
  };

  const onSignatureModalClose = () => {
    clearSignature();

    closeSignatureModal();
    setIsSignatureEnabled(false);
  };

  const onSignatureSubmit = () => {
    closeSignatureModal();
    setIsSignatureEnabled(false);
  };

  const handleDetectionError = (error: any = null, aborted = false) => {
    closeScanProcessingModal();
    if (!aborted) {
      displayError(error);
    }
  };

  const buildFormData = (detectedData: any) => {
    if (!detectedData) {
      displayError({
        message: 'Data is missing',
      });
      return [];
    }

    const birthDate =
      detectedData?.valid_date_of_birth && detectedData?.date_of_birth
        ? new Date(detectedData.date_of_birth)
        : undefined;
    const docDateOfIssue =
      detectedData?.valid_expiration_date && detectedData?.expiration_date
        ? subYears(new Date(detectedData.expiration_date), 10)
        : undefined;

    const sex = GENDERS_OPTIONS.find((g) => g.value === detectedData?.sex);
    const nationality = locationsAsOptions.find((l) => {
      return l?.value === detectedData?.nationality;
    });

    const remappedDocType = getRemappedDocType({
      docType: detectedData?.type_doc,
      countryCode: housingCountry,
    });

    const docType = getDocTypes(
      housingCountry,
      detectedData?.nationality,
      detectedData?.residence,
    ).find((o) => {
      return o?.value === remappedDocType;
    });

    return [
      {[FORM_NAMES.sex]: sex},
      {[FORM_NAMES.docDateOfIssue]: docDateOfIssue},
      {[FORM_NAMES.birthDate]: birthDate},
      {[FORM_NAMES.nationality]: nationality},
      {[FORM_NAMES.docType]: docType},
      {[FORM_NAMES.residenceCountry]: nationality},
      {[FORM_NAMES.docNumber]: detectedData?.number},
      {[FORM_NAMES.name]: getValidName(detectedData?.names)},
      {[FORM_NAMES.surname]: getValidName(detectedData?.first_surname)},
      {[FORM_NAMES.secondSurname]: getValidName(detectedData?.second_surname)},
    ];
  };

  const handleDetectionSuccess = (data: any) => {
    const formData = buildFormData(data);
    setValue(formData);
    closeScanProcessingModal();
    openScanSuccessModal();
    setOcrWasUsed(true);

    timeoutRef.current = setTimeout(() => {
      closeScanSuccessModal();
    }, 1000 * SUCCESS_MODAL_TIMEOUT_S);
  };

  const checkMRZDetection = async (id: string) => {
    if (!id) {
      handleDetectionError({
        message: 'MRZ Check Id is missing.',
      });
      return;
    }

    const {data, error, aborted} = await api.ocr.checkImageMRZDetection(id, {
      signal: setupAbortController(),
    });

    if (!isMounted.current) {
      return;
    }

    if (error) {
      handleDetectionError(error, aborted);
      return;
    }

    if (data?.status === DETECTING_STATUSES.completed) {
      if (data?.mrz_detected && data?.valid_composite) {
        handleDetectionSuccess(data);
      } else {
        handleDetectionError({
          message: t('could_not_detect'),
        });
      }

      return;
    }

    if (data?.status === DETECTING_STATUSES.error) {
      handleDetectionError({
        message: t('could_not_detect'),
      });
      return;
    }

    return new Promise((resolve) => {
      timeoutRef.current = setTimeout(() => {
        resolve(checkMRZDetection(id));
      }, 1000 * DETECTING_REQUESTS_GAP_S);
    });
  };

  const startMRZDetection = async (image = '') => {
    openScanProcessingModal();
    closeScanModal();
    const {data, error, aborted} = await api.ocr.sendImageForMRZDetection(image, {
      signal: setupAbortController(),
    });

    if (!isMounted.current) {
      return;
    }

    if (error) {
      handleDetectionError(error, aborted);
      return;
    }

    if (data?.status === DETECTING_STATUSES.completed) {
      if (data?.mrz_detected && data?.valid_composite) {
        handleDetectionSuccess(data);
      } else {
        handleDetectionError({
          message: t('could_not_detect'),
        });
      }

      return;
    }

    if (data?.status === DETECTING_STATUSES.error) {
      handleDetectionError({
        message: t('could_not_detect'),
      });
      return;
    }

    await checkMRZDetection(data?.id);
  };

  const captureAndStartValidation = async () => {
    try {
      const image = webcamRef?.current?.getScreenshot();
      await startMRZDetection(image);
    } catch (err) {
      displayError(err);
    }
  };

  const resetFormData = () => {
    setValue([
      {[FORM_NAMES.sex]: undefined},
      {[FORM_NAMES.nationality]: undefined},
      {[FORM_NAMES.docType]: undefined},
      {[FORM_NAMES.birthCountry]: undefined},
      {[FORM_NAMES.residenceCountry]: undefined},
      {[FORM_NAMES.residenceCity]: undefined},
      {[FORM_NAMES.countryOfIssue]: undefined},
      {[FORM_NAMES.cityOfBirth]: undefined},
      {[FORM_NAMES.cityOfIssue]: undefined},
      {[FORM_NAMES.nextDestinationCountry]: undefined},
      {[FORM_NAMES.citizenship]: undefined},
      {[FORM_NAMES.purposeOfStay]: undefined},
      {[FORM_NAMES.arrivalCountry]: undefined},
      {[FORM_NAMES.arrivalDistrict]: undefined},
      {[FORM_NAMES.arrivalMunicipality]: undefined},
      {[FORM_NAMES.nextDestinationDistrict]: undefined},
      {[FORM_NAMES.nextDestinationMunicipality]: undefined},
      {[FORM_NAMES.taxExemption]: undefined},
      {[FORM_NAMES.residenceProvince]: undefined},
      {[FORM_NAMES.email]: undefined},
      {[FORM_NAMES.birthDate]: null},
      {[FORM_NAMES.docDateOfIssue]: null},
      {[FORM_NAMES.phone]: null},
    ]);

    reset({});
    setIsValidGuestId(false);
  };

  const removeDeletedGuestFromReservation = async () => {
    const guestGroup = await refetchGuestGroup();

    if (guestGroup) {
      queryCache.setQueryData(['guestGroup', guestGroupId!], guestGroup);
    } else {
      queryCache.setQueryData(['guestGroup', guestGroupId!], (oldData?: GuestGroup) => {
        if (!oldData) {
          return oldData;
        }

        if (guestGroup) {
          return {
            ...oldData,
            guest_group: guestGroup,
          };
        }

        const newMembers = oldData?.members?.filter((m) => {
          return m.id !== guestId;
        });

        return {
          ...oldData,
          members: newMembers,
        };
      });
    }
  };

  const deleteGuest = async () => {
    setDeleteGuestStatus('loading');
    const {error} = await api.guests.deleteById(guestId);

    if (!isMounted.current) {
      return;
    }

    if (error) {
      toastResponseError(error);
      displayError(error);
      setDeleteGuestStatus('idle');
      return;
    }

    resetFormData();
    await removeDeletedGuestFromReservation();
    setDeleteGuestStatus('success');
  };

  // const checkFieldsToUpdate = (payload: any) => {
  //   if (!payload) {
  //     return false;
  //   }
  //   return Object.keys(payload).some((key) => {
  //     return payload[key] ? RESERVATION_ID_UPDATE_FIELDS.includes(key) : false;
  //   });
  // };

  const getGuestPayload = (data: FormTypes) => {
    return buildGuest(data, {reservation, ocrWasUsed});
  };

  const updateGuestGroup = async (updatedGuest: Guest) => {
    reset(getValues());
    const guestGroup = await refetchGuestGroup();

    if (guestGroup) {
      queryCache.setQueryData(['guestGroup', guestGroupId!], guestGroup);
    } else {
      queryCache.setQueryData(['guestGroup', guestGroupId!], (oldData?: GuestGroup) => {
        if (!oldData) {
          return oldData;
        }

        if (guestGroup) {
          return {
            ...oldData,
            guest_group: guestGroup,
          };
        }

        const guestId = updatedGuest?.id;
        const nextMembers = oldData?.members?.filter((g) => {
          return g?.id !== guestId;
        });

        return {
          ...oldData,
          members: nextMembers,
        };
      });
    }
  };

  const saveGuest = async (data: FormTypes) => {
    const payload = getGuestPayload(data);
    let savingError: any;
    let updatedGuest: Guest;

    closeSavingTryAgainErrorModal();
    openSavingGuestModal();

    setSavingGuestStatus('loading');
    if (isValidGuestId) {
      const {error, data} = await api.guests.patchById(guestId, payload);
      savingError = error;
      updatedGuest = data;
    } else {
      const {error, data} = await api.guests.post(payload);
      savingError = error;
      updatedGuest = data;
    }

    const isPhoneSavingError =
      savingError?.errors.length && savingError.errors[0]?.field === FORM_NAMES.phone;
    if (isPhoneSavingError) {
      displaySavingError(savingError);
      closeSavingGuestModal();
      return;
    }

    if (savingError) {
      displayTryAgainSavingError(savingError);
      closeSavingGuestModal();
      return;
    }

    if (updatedGuest) {
      await updateGuestGroup(updatedGuest);
    }

    setSavingGuestStatus('success');
  };

  const isAnySectionTouched = formState.dirty;

  const {
    goThroughConfirm,
    handleModalSave,
    handleModalDontSave,
    handleModalCancel,
    isDoYouWantToSaveModalOpen,
  } = useConfirmLeaveModal(isAnySectionTouched);

  const onSubmit = async (data: FormTypes) => {
    await saveGuest(data);
  };

  if (reservationStatus === 'error') {
    return (
      <CenteredWrapper>
        <ErrorText>Oops! Error</ErrorText>
        <ModalButton
          secondary
          onClick={() => refetchReservation({force: true, throwOnError: true})}
          label={t('try_again')}
        />
      </CenteredWrapper>
    );
  }

  if (
    reservationStatus === 'loading' ||
    citiesStatus === 'loading' ||
    housingStatus === 'loading' ||
    guestGroupStatus === 'loading' ||
    locationsStatus === 'loading' ||
    (guestId && isValidGuestId && !initGuestData)
  ) {
    return (
      <CenteredWrapper>
        <Loader label={t('loading')} height={45} width={45} />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormContent>
            <Heading>
              <div>
                <BackButton
                  onClick={() => goThroughConfirm(`/bookings/${reservationId}`)}
                />
              </div>
              {guestName ? (
                <Title>{guestName}</Title>
              ) : (
                <MissingNameTitle>[{t('name_missing')}]</MissingNameTitle>
              )}
              <div>
                {isValidGuestId && initGuestData && !formState.dirty ? (
                  <Tooltip
                    isMouseOver={hasItalianLeaderWithGuestMembers}
                    content={
                      hasItalianLeaderWithGuestMembers &&
                      t('why_i_cant_delete_guest_leader')
                    }
                    trigger={
                      <Button
                        disabled={hasItalianLeaderWithGuestMembers}
                        secondary
                        type="button"
                        onClick={openDeleteGuestModal}
                        label={
                          <ButtonLabelWrapper>
                            <DeleteButtonLabelIcon src={rubbishIcon} alt="Plus" />
                            <DeleteButtonLabelText>
                              {t('delete_guest')}
                            </DeleteButtonLabelText>
                          </ButtonLabelWrapper>
                        }
                      />
                    }
                  />
                ) : (
                  <Button
                    type="submit"
                    label={
                      <ButtonLabelWrapper>
                        <ButtonLabelIcon
                          src={isValidGuestId ? floppyIcon : plusIcon}
                          alt="Plus"
                        />
                        <ButtonLabelText>
                          {isValidGuestId ? t('save_changes') : t('register_guest')}
                        </ButtonLabelText>
                      </ButtonLabelWrapper>
                    }
                  />
                )}
              </div>
            </Heading>
            <>
              {!isValidGuestId && (
                <>
                  <ScanModalTriggerButtonWrapper>
                    <Button
                      secondary
                      type="button"
                      onClick={openScanModal}
                      label={
                        <>
                          <img src={cameraIcon} alt="Camera" />
                          {t('scan_id_doc')}
                        </>
                      }
                    />
                  </ScanModalTriggerButtonWrapper>
                  <AddDataManuallyText>
                    {t('or_you_can_add_data_manually')}:
                  </AddDataManuallyText>
                  {isScanModalOpen && (
                    <Modal open>
                      <ModalCloseButton type="button" onClick={closeScanModal}>
                        <img src={blueCloseIcon} alt="Cross" />
                      </ModalCloseButton>
                      <ModalContent>
                        <ModalTitle>
                          <Trans i18nKey="scan_your_doc_where_the_mrz">
                            Scan guest ID or passport by the side where the{' '}
                            <b>MRZ code</b> is.
                          </Trans>
                        </ModalTitle>
                        <ScanModalHint>{t('keep_your_doc_inside_the_box')}</ScanModalHint>
                        <DetectionWebcam
                          isMobile={false}
                          mirrored={false}
                          ref={webcamRef}
                          onUserMedia={handleWebcamReady}
                        />
                        <ScanModalButtonsWrapper>
                          <ModalButton
                            onClick={captureAndStartValidation}
                            disabled={!isWebcamReady}
                            label={t('capture')}
                          />
                          <ModalButton
                            secondary
                            label={t('add_data_manually')}
                            onClick={closeScanModal}
                          />
                        </ScanModalButtonsWrapper>
                      </ModalContent>
                    </Modal>
                  )}
                  {isScanProcessingModalOpen && (
                    <Modal
                      open
                      contentStyle={scanModalContentStyle}
                      iconAlt="Document scanning"
                      iconSrc={scanningIcon}
                      iconProps={{
                        height: 84,
                        width: 84,
                      }}
                      title={`${t('scanning')}...`}
                      text={t('it_takes_seconds')}
                    >
                      <AbortRequestButtonWrapper>
                        <ModalButton
                          secondary
                          onClick={abortRequestAndOpenScanModal}
                          label={t('cancel')}
                        />
                      </AbortRequestButtonWrapper>
                    </Modal>
                  )}
                  {isScanSuccessModalOpen && (
                    <Modal
                      open
                      contentStyle={scanModalContentStyle}
                      iconAlt="Check mark"
                      iconSrc={successIcon}
                      iconProps={{
                        height: 84,
                        width: 84,
                      }}
                      title={t('success_exclamation')}
                    />
                  )}
                </>
              )}
            </>
            <FieldsContainer>
              {fields.display[FORM_NAMES.name] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('name'),
                        fields.required[FORM_NAMES.name],
                      )}
                      placeholder={t('enter_name')}
                      ref={register({
                        required: fields.required[FORM_NAMES.name],
                        pattern: {
                          value: getNamePattern(housingCountry),
                          message: t('cant_contain_number_and_symbols'),
                        },
                        maxLength: {
                          value: MAX_NAMES_INPUT_LENGTH,
                          message: t('max_length', {length: MAX_NAMES_INPUT_LENGTH}),
                        },
                      })}
                      name={FORM_NAMES.name}
                      error={errors[FORM_NAMES.name]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.surname] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('surname'),
                        fields.required[FORM_NAMES.surname],
                      )}
                      placeholder={t('enter_surname')}
                      ref={register({
                        required: fields.required[FORM_NAMES.surname],
                        pattern: {
                          value: getNamePattern(housingCountry),
                          message: t('cant_contain_number_and_symbols'),
                        },
                        maxLength: {
                          value: MAX_NAMES_INPUT_LENGTH,
                          message: t('max_length', {length: MAX_NAMES_INPUT_LENGTH}),
                        },
                      })}
                      name={FORM_NAMES.surname}
                      error={errors[FORM_NAMES.surname]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.secondSurname] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('second_surname'),
                        fields.required[FORM_NAMES.secondSurname],
                      )}
                      placeholder={t('enter_second_surname')}
                      ref={register({
                        required: fields.required[FORM_NAMES.secondSurname],
                        pattern: {
                          value: getNamePattern(housingCountry),
                          message: t('cant_contain_number_and_symbols'),
                        },
                        maxLength: {
                          value: MAX_NAMES_INPUT_LENGTH,
                          message: t('max_length', {length: MAX_NAMES_INPUT_LENGTH}),
                        },
                      })}
                      name={FORM_NAMES.secondSurname}
                      error={errors[FORM_NAMES.secondSurname]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.sex] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('sex'),
                        fields.required[FORM_NAMES.sex],
                      )}
                      options={GENDERS_OPTIONS}
                      rules={{required: fields.required[FORM_NAMES.sex]}}
                      name={FORM_NAMES.sex}
                      error={errors[FORM_NAMES.sex]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.nationality] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('nationality'),
                        fields.required[FORM_NAMES.nationality],
                      )}
                      rules={{required: fields.required[FORM_NAMES.nationality]}}
                      options={locationsAsOptions}
                      name={FORM_NAMES.nationality}
                      disabled={disabled}
                      error={(errors[FORM_NAMES.nationality] as any)?.message}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.citizenship] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.citizenship]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('citizenship'),
                        fields.required[FORM_NAMES.citizenship],
                      )}
                      options={locationsAsOptions}
                      name={FORM_NAMES.citizenship}
                      disabled={disabled}
                      error={(errors[FORM_NAMES.citizenship] as any)?.message}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.birthDate] && (
                <>
                  <FieldWrapper>
                    <Controller
                      control={control}
                      name={FORM_NAMES.birthDate}
                      as={<Datepicker />}
                      label={getRequiredOrOptionalFieldLabel(
                        t('birth_date'),
                        fields.required[FORM_NAMES.birthDate],
                      )}
                      error={errors[FORM_NAMES.birthDate]?.message}
                      disabled={disabled}
                      rules={{
                        required: fields.required[FORM_NAMES.birthDate],
                        validate: (value) =>
                          validateBirthDate(
                            value,
                            getValues()[FORM_NAMES.docDateOfIssue] as Date,
                          ),
                      }}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.birthCountry] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('country_of_birth'),
                        fields.required[FORM_NAMES.birthCountry],
                      )}
                      options={locationsAsOptions}
                      rules={{required: fields.required[FORM_NAMES.birthCountry]}}
                      name={FORM_NAMES.birthCountry}
                      error={(errors[FORM_NAMES.birthCountry] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display.isItalianCityOfBirthFieldVisible && (
                <>
                  <FieldWrapper>
                    <Controller
                      cacheOptions
                      defaultValues
                      as={
                        <AsyncSelect
                          loadOptions={(inputValue, callback) => {
                            callback(getBestMatches(citiesAsOptions, inputValue));
                          }}
                        />
                      }
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.cityOfBirth]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('city_of_birth'),
                        fields.required[FORM_NAMES.cityOfBirth],
                      )}
                      name={FORM_NAMES.cityOfBirth}
                      error={errors[FORM_NAMES.cityOfBirth]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.cityOfBirth] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('city_of_birth'),
                        fields.required[FORM_NAMES.cityOfBirth],
                      )}
                      placeholder={t('enter_city_of_birth')}
                      ref={register({
                        required: fields.required[FORM_NAMES.cityOfBirth],
                      })}
                      name={FORM_NAMES.cityOfBirth}
                      error={errors[FORM_NAMES.cityOfBirth]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.residenceCountry] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('residence_country'),
                        fields.required[FORM_NAMES.residenceCountry],
                      )}
                      options={locationsAsOptions}
                      rules={{required: fields.required[FORM_NAMES.residenceCountry]}}
                      name={FORM_NAMES.residenceCountry}
                      error={(errors[FORM_NAMES.residenceCountry] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display.isItalianResidenceCityFieldVisible && (
                <>
                  <FieldWrapper>
                    <Controller
                      cacheOptions
                      defaultValues
                      as={
                        <AsyncSelect
                          loadOptions={(inputValue, callback) => {
                            callback(getBestMatches(citiesAsOptions, inputValue));
                          }}
                        />
                      }
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.residenceCity]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('residence_city'),
                        fields.required[FORM_NAMES.residenceCity],
                      )}
                      name={FORM_NAMES.residenceCity}
                      error={errors[FORM_NAMES.residenceCity]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.residenceProvince] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('residence_province'),
                        fields.required[FORM_NAMES.residenceProvince],
                      )}
                      options={provincesAsOptions}
                      rules={{required: fields.required[FORM_NAMES.residenceProvince]}}
                      name={FORM_NAMES.residenceProvince}
                      error={(errors[FORM_NAMES.residenceProvince] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.residenceCity] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('residence_city'),
                        fields.required[FORM_NAMES.residenceCity],
                      )}
                      placeholder={t('enter_residence_city')}
                      ref={register({
                        required: fields.required[FORM_NAMES.residenceCity],
                      })}
                      name={FORM_NAMES.residenceCity}
                      error={errors[FORM_NAMES.residenceCity]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.residenceAddress] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('residence_address'),
                        fields.required[FORM_NAMES.residenceAddress],
                      )}
                      placeholder={t('enter_residence_address')}
                      ref={register({
                        required: fields.required[FORM_NAMES.residenceAddress],
                      })}
                      name={FORM_NAMES.residenceAddress}
                      error={errors[FORM_NAMES.residenceAddress]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.residencePostalCode] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('residence_postal_code'),
                        fields.required[FORM_NAMES.residencePostalCode],
                      )}
                      placeholder={t('enter_residence_postal_code')}
                      ref={register({
                        required: fields.required[FORM_NAMES.residencePostalCode],
                      })}
                      name={FORM_NAMES.residencePostalCode}
                      error={errors[FORM_NAMES.residencePostalCode]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.docType] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('doc_type'),
                        fields.required[FORM_NAMES.docType],
                      )}
                      options={docTypes}
                      rules={{
                        validate: (value) => {
                          const residenceCountry = (getValues()[
                            FORM_NAMES.residenceCountry
                          ] as SelectOption)?.value;
                          const nationalityCode = (getValues()[
                            FORM_NAMES.nationality
                          ] as SelectOption)?.value;

                          const required = getIsDocTypeRequired({
                            housingCountry,
                            nationalityCode,
                            isGuestLeader,
                            guestGroupType,
                            formFields: {
                              residenceCountry,
                            },
                          });

                          return (!value && required) || true;
                        },
                      }}
                      name={FORM_NAMES.docType}
                      error={(errors[FORM_NAMES.docType] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.docNumber] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('doc_number'),
                        fields.required[FORM_NAMES.docNumber],
                      )}
                      placeholder={t('enter_doc_number')}
                      ref={register({
                        required: fields.required[FORM_NAMES.docNumber],
                        pattern: {
                          value: PATTERNS.docNumber,
                          message: t('only_basic_letters_are_allowed') as string,
                        },
                        validate: (value) =>
                          validateDocNumber(
                            value,
                            getValues()[FORM_NAMES.docType] as SelectOption,
                          ),
                      })}
                      name={FORM_NAMES.docNumber}
                      error={errors[FORM_NAMES.docNumber]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.docDateOfIssue] && (
                <>
                  <FieldWrapper>
                    <Controller
                      control={control}
                      name={FORM_NAMES.docDateOfIssue}
                      as={<Datepicker />}
                      tooltipContent={
                        <Trans i18nKey="date_when_the_doc_created">
                          Date when the document was created. (It is <b>not</b> the
                          expiration date)
                        </Trans>
                      }
                      label={getRequiredOrOptionalFieldLabel(
                        t('date_of_issue'),
                        fields.required[FORM_NAMES.docDateOfIssue],
                      )}
                      error={errors[FORM_NAMES.docDateOfIssue]?.message}
                      disabled={disabled}
                      rules={{
                        validate: (value) => {
                          const residenceCountry = (getValues()[
                            FORM_NAMES.residenceCountry
                          ] as SelectOption)?.value;

                          const required = getIsDocDateOfIssueRequired({
                            housingCountry,
                            isGuestLeader,
                            guestGroupType,
                            formFields: {
                              residenceCountry,
                            },
                          });

                          const error = validateIssueDate(
                            value,
                            getValues()[FORM_NAMES.birthDate] as Date,
                          );

                          return (!value && required) || error || true;
                        },
                      }}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.countryOfIssue] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      label={getRequiredOrOptionalFieldLabel(
                        t('country_of_issue'),
                        fields.required[FORM_NAMES.countryOfIssue],
                      )}
                      options={locationsAsOptions}
                      rules={{
                        validate: (value) => {
                          const residenceCountry = (getValues()[
                            FORM_NAMES.residenceCountry
                          ] as SelectOption)?.value;

                          const required = getIsCountryOfIssueRequired({
                            housingCountry,
                            isGuestLeader,
                            guestGroupType,
                            formFields: {
                              residenceCountry,
                            },
                          });
                          return (!value && required) || true;
                        },
                      }}
                      name={FORM_NAMES.countryOfIssue}
                      error={(errors[FORM_NAMES.countryOfIssue] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.cityOfIssue] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('city_of_issue'),
                        fields.required[FORM_NAMES.cityOfIssue],
                      )}
                      placeholder={t('enter_city')}
                      ref={register({
                        required: fields.required[FORM_NAMES.cityOfIssue],
                      })}
                      name={FORM_NAMES.cityOfIssue}
                      error={errors[FORM_NAMES.cityOfIssue]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display.isItalianCityOfIssueFieldVisible && (
                <>
                  <FieldWrapper>
                    <Controller
                      cacheOptions
                      defaultValues
                      as={
                        <AsyncSelect
                          loadOptions={(inputValue, callback) => {
                            callback(getBestMatches(citiesAsOptions, inputValue));
                          }}
                        />
                      }
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.cityOfIssue]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('city_of_issue'),
                        fields.required[FORM_NAMES.cityOfIssue],
                      )}
                      name={FORM_NAMES.cityOfIssue}
                      error={errors[FORM_NAMES.cityOfIssue]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.arrivalCountry] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.arrivalCountry]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('arrived_from_country'),
                        fields.required[FORM_NAMES.arrivalCountry],
                      )}
                      options={locationsAsOptions}
                      name={FORM_NAMES.arrivalCountry}
                      error={(errors[FORM_NAMES.arrivalCountry] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.arrivalDistrict] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.arrivalDistrict]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('arrived_from_district'),
                        fields.required[FORM_NAMES.arrivalDistrict],
                      )}
                      options={districtsAsOptions}
                      name={FORM_NAMES.arrivalDistrict}
                      error={(errors[FORM_NAMES.arrivalDistrict] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.arrivalMunicipality] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.arrivalMunicipality]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('arrived_from_municipality'),
                        fields.required[FORM_NAMES.arrivalMunicipality],
                      )}
                      options={arrivalMunicipalitiesAsOptions}
                      name={FORM_NAMES.arrivalMunicipality}
                      error={(errors[FORM_NAMES.arrivalMunicipality] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.nextDestinationCountry] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{
                        required: fields.required[FORM_NAMES.nextDestinationCountry],
                      }}
                      label={getRequiredOrOptionalFieldLabel(
                        t('next_destination_country'),
                        fields.required[FORM_NAMES.nextDestinationCountry],
                      )}
                      options={locationsAsOptions}
                      name={FORM_NAMES.nextDestinationCountry}
                      error={(errors[FORM_NAMES.nextDestinationCountry] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.nextDestinationCity] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('next_destination_city'),
                        fields.required[FORM_NAMES.nextDestinationCity],
                      )}
                      placeholder={t('enter_next_destination_city')}
                      ref={register({
                        required: fields.required[FORM_NAMES.nextDestinationCity],
                      })}
                      name={FORM_NAMES.nextDestinationCity}
                      error={errors[FORM_NAMES.nextDestinationCity]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.nextDestinationAddress] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('next_destination_address'),
                        fields.required[FORM_NAMES.nextDestinationAddress],
                      )}
                      placeholder={t('enter_next_destination_address')}
                      ref={register({
                        required: fields.required[FORM_NAMES.nextDestinationAddress],
                      })}
                      name={FORM_NAMES.nextDestinationAddress}
                      error={errors[FORM_NAMES.nextDestinationAddress]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.nextDestinationDistrict] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{
                        required: fields.required[FORM_NAMES.nextDestinationDistrict],
                      }}
                      label={getRequiredOrOptionalFieldLabel(
                        t('next_destination_district'),
                        fields.required[FORM_NAMES.nextDestinationDistrict],
                      )}
                      options={districtsAsOptions}
                      name={FORM_NAMES.nextDestinationDistrict}
                      error={(errors[FORM_NAMES.nextDestinationDistrict] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.nextDestinationMunicipality] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{
                        required: fields.required[FORM_NAMES.nextDestinationMunicipality],
                      }}
                      label={getRequiredOrOptionalFieldLabel(
                        t('next_destination_municipality'),
                        fields.required[FORM_NAMES.nextDestinationMunicipality],
                      )}
                      options={nextDestinationMunicipalitiesAsOptions}
                      name={FORM_NAMES.nextDestinationMunicipality}
                      error={
                        (errors[FORM_NAMES.nextDestinationMunicipality] as any)?.message
                      }
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.purposeOfStay] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.purposeOfStay]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('purpose_of_stay'),
                        fields.required[FORM_NAMES.purposeOfStay],
                      )}
                      options={purposesOfStayAsOptions}
                      name={FORM_NAMES.purposeOfStay}
                      error={(errors[FORM_NAMES.purposeOfStay] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.visaNumber] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('visa_number_eu'),
                        fields.required[FORM_NAMES.visaNumber],
                      )}
                      placeholder={t('enter_visa_number')}
                      ref={register({
                        required: fields.required[FORM_NAMES.visaNumber],
                      })}
                      name={FORM_NAMES.visaNumber}
                      error={errors[FORM_NAMES.visaNumber]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.fullTouristTax] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('full_tourist_tax'),
                        fields.required[FORM_NAMES.fullTouristTax],
                      )}
                      placeholder={t('full_tourist_tax')}
                      ref={register({
                        required: fields.required[FORM_NAMES.fullTouristTax],
                        min: {
                          value: MIN_TAX,
                          message: t('min_number_is', {number: MIN_TAX}),
                        },
                        max: {
                          value: MAX_TAX,
                          message: t('max_number_is', {number: MAX_TAX}),
                        },
                      })}
                      step="0.01"
                      name={FORM_NAMES.fullTouristTax}
                      error={errors[FORM_NAMES.fullTouristTax]?.message}
                      disabled={disabled}
                      type="number"
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.taxExemption] && (
                <>
                  <FieldWrapper>
                    <Controller
                      as={<Select />}
                      control={control}
                      rules={{required: fields.required[FORM_NAMES.taxExemption]}}
                      label={getRequiredOrOptionalFieldLabel(
                        t('tax_exemption'),
                        fields.required[FORM_NAMES.taxExemption],
                      )}
                      options={taxExemptionsAsOptions}
                      name={FORM_NAMES.taxExemption}
                      error={(errors[FORM_NAMES.taxExemption] as any)?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.email] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('email'),
                        fields.required[FORM_NAMES.email],
                      )}
                      inputMode="email"
                      placeholder={t('enter_email')}
                      ref={register({
                        pattern: {
                          value: PATTERNS.email,
                          message: t('invalid_email'),
                        },
                        required: fields.required[FORM_NAMES.email],
                      })}
                      name={FORM_NAMES.email}
                      error={errors[FORM_NAMES.email]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.phone] && (
                <>
                  <FieldWrapper>
                    <Controller
                      control={control}
                      as={<PhoneInput />}
                      label={getRequiredOrOptionalFieldLabel(
                        t('phone_number'),
                        fields.required[FORM_NAMES.phone],
                      )}
                      placeholder={t('enter_your_phone_number')}
                      defaultCode={initGuestData?.phone_details?.code}
                      defaultInputValue={initGuestData?.phone_details?.number}
                      name={FORM_NAMES.phone}
                      error={errors[FORM_NAMES.phone]?.message}
                      rules={{
                        required: fields.required[FORM_NAMES.phone],
                      }}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
              {fields.display[FORM_NAMES.fiscalCode] && (
                <>
                  <FieldWrapper>
                    <Input
                      label={getRequiredOrOptionalFieldLabel(
                        t('fiscal_code'),
                        fields.required[FORM_NAMES.fiscalCode],
                      )}
                      placeholder={t('enter_fiscal_code')}
                      ref={register({
                        pattern: {
                          value: PATTERNS.fiscalCode,
                          message: t('invalid_fiscal_code'),
                        },
                        required: fields.required[FORM_NAMES.fiscalCode],
                      })}
                      name={FORM_NAMES.fiscalCode}
                      error={errors[FORM_NAMES.fiscalCode]?.message}
                      disabled={disabled}
                    />
                  </FieldWrapper>
                  <Divider />
                </>
              )}
            </FieldsContainer>
            <BottomSection>
              <div>
                {(fields.display[FORM_NAMES.frontSideScan] ||
                  fields.display[FORM_NAMES.backSideScan]) && (
                  <>
                    {(!fields.display[FORM_NAMES.backSideScan] && frontSideScan) ||
                    (backSideScan && frontSideScan) ? (
                      <>
                        <BottomButton onClick={openViewDocumentsModal} type="button">
                          <PlusImage src={viewIconBlue} alt="Plus" />
                          <BlueBottomButtonText>
                            ({t('view_identity_doc')})
                          </BlueBottomButtonText>
                        </BottomButton>
                        <DocumentInfo>{t('is_the_doc_wrong')}</DocumentInfo>
                        <DeleteDocumentButtonWrapper>
                          <Button
                            secondary
                            type="button"
                            onClick={openConfirmDocumentDeletionModal}
                            label={
                              <ButtonLabelWrapper>
                                <DeleteButtonLabelIcon src={rubbishIcon} alt="Plus" />
                                <DeleteButtonLabelText>
                                  {t('delete_document')}
                                </DeleteButtonLabelText>
                              </ButtonLabelWrapper>
                            }
                          />
                        </DeleteDocumentButtonWrapper>
                      </>
                    ) : (
                      <>
                        <BottomButton
                          onClick={openSaveDocumentModalAndResetActiveStep}
                          type="button"
                        >
                          <PlusImage src={plusBlueIcon} alt="Plus" />
                          <BottomButtonText>{t('add_document')}</BottomButtonText>
                        </BottomButton>
                        <BottomButtonError>
                          {errors[FORM_NAMES.frontSideScan]?.message ||
                            errors[FORM_NAMES.backSideScan]?.message}
                        </BottomButtonError>
                      </>
                    )}
                  </>
                )}
              </div>
              <div>
                {fields.display[FORM_NAMES.signature] && (
                  <>
                    <BottomButton onClick={openSignatureModal} type="button">
                      <PlusImage src={plusBlueIcon} alt="Plus" />
                      <BottomButtonText>
                        {signature ? t('repeat_signature') : t('add_signature')}
                      </BottomButtonText>
                    </BottomButton>
                    <BottomButtonError>
                      {errors[FORM_NAMES.signature]?.message}
                    </BottomButtonError>
                    {signature ? (
                      <SignatureImg src={signature} alt="Signature" />
                    ) : (
                      <SignaturePlaceholder />
                    )}
                  </>
                )}
              </div>
            </BottomSection>
            {isUploadDocumentsModalOpen && (
              <Modal>
                <ModalCloseButton type="button" onClick={closeUploadDocumentsModal}>
                  <img src={blueCloseIcon} alt="Cross" />
                </ModalCloseButton>
                <ModalContent>
                  <ModalTitle>
                    {fields.display[FORM_NAMES.backSideScan] ? (
                      <>{documentSaveStep === 0 ? t('frontside') : t('backside')}</>
                    ) : (
                      t('single_page_that_contains_data')
                    )}
                    <br />
                    {documentSaveStep === 0
                      ? t('where_the_photo_is')
                      : t('where_the_mrz_is')}
                  </ModalTitle>
                  {uploadedFile ? (
                    renderUploadedFile()
                  ) : (
                    <FileDragZone {...getDropZoneRootProps()}>
                      <SelectFileDragZoneContainer>
                        <img src={plusBlueIcon} alt="Plus" />{' '}
                        {!isDropZoneDragActive && t('select_file')}
                      </SelectFileDragZoneContainer>
                      <DragZoneText>
                        {isDropZoneDragActive
                          ? t('drag_file_here')
                          : t('or_drag_and_drop_the_photo_here')}
                      </DragZoneText>
                      <input
                        {...getDropZoneInputProps({
                          multiple: false,
                          accept: getAllowedFileTypes(housingCountry),
                        })}
                      />
                    </FileDragZone>
                  )}
                  <FileTypes>
                    {PDF_UPLOADING_ALLOWED_COUNTRIES.includes(housingCountry)
                      ? t('only_pdf_and_doc_file_types')
                      : t('only_doc_file_types')}
                  </FileTypes>
                  <UploadModalButtonsWrapper>
                    <SelectAnotherFileButton
                      {...getRootProps()}
                      visible={Boolean(uploadedFile)}
                      type="button"
                    >
                      <img src={plusBlueIcon} alt="Plus" />
                      {isDragActive ? t('drag_file_here') : t('select_another_file')}
                      <input
                        {...getInputProps({
                          multiple: false,
                          accept: getAllowedFileTypes(housingCountry),
                        })}
                      />
                    </SelectAnotherFileButton>
                    <ModalButton
                      onClick={saveDocumentFile}
                      disabled={!uploadedFile}
                      label={t('upload')}
                    />
                  </UploadModalButtonsWrapper>
                </ModalContent>
              </Modal>
            )}
            {isScanDocumentsModalOpen && (
              <Modal open>
                <ModalCloseButton
                  type="button"
                  onClick={closeSaveDocumentsModalAndResetDocumentStep}
                >
                  <img src={blueCloseIcon} alt="Cross" />
                </ModalCloseButton>
                <ModalContent>
                  <ModalTitle>
                    {fields.display[FORM_NAMES.backSideScan] ? (
                      <>{documentSaveStep === 0 ? t('frontside') : t('backside')}</>
                    ) : (
                      t('single_page_that_contains_data')
                    )}
                    <br />
                    {documentSaveStep === 0
                      ? t('where_the_photo_is')
                      : t('where_the_mrz_is')}
                  </ModalTitle>
                  <ScanModalHint>{t('keep_your_doc_inside_the_box')}</ScanModalHint>
                  <DetectionWebcam
                    isMobile={false}
                    mirrored={false}
                    ref={webcamRef}
                    onUserMedia={handleWebcamReady}
                  />
                  <ScanModalButtonsWrapper>
                    <ModalButton
                      onClick={saveDocumentPhoto}
                      disabled={!isWebcamReady}
                      label={t('capture')}
                    />
                    <ScanModalOrText>{t('or')}</ScanModalOrText>
                    <ModalButton
                      secondary
                      label={t('upload_manually')}
                      onClick={() => {
                        openUploadDocumentsModal();
                        closeScanDocumentsModal();
                      }}
                    />
                  </ScanModalButtonsWrapper>
                </ModalContent>
              </Modal>
            )}
            {isConfirmDocumentDeletionModalOpen && (
              <Modal
                iconSrc={warningIcon}
                iconAlt="Warning"
                iconProps={{
                  height: 84,
                  width: 84,
                }}
                title={t('delete_document_question')}
                text={t('this_cannot_be_reverted')}
              >
                <ModalTwoButtonsWrapper>
                  <ModalButton
                    onClick={clearDocumentPhotosAndCloseDeleteConfirmationModal}
                    label={t('delete')}
                  />
                  <ModalButton
                    secondary
                    onClick={closeConfirmDocumentDeletionModal}
                    label={t('cancel')}
                  />
                </ModalTwoButtonsWrapper>
              </Modal>
            )}
            {isViewDocumentsModalOpen && (
              <Modal
                closeOnEscape
                closeOnDocumentClick
                contentStyle={viewDocumentsModalContentStyle}
                title={t('document')}
                onClose={closeViewDocumentsModal}
              >
                <DocumentsPhotosContent>
                  <DocumentPhotosWrapper>
                    {frontSideScan && renderScanView(frontSideScan)}
                    {backSideScan &&
                      fields.display[FORM_NAMES.backSideScan] &&
                      renderScanView(backSideScan)}
                  </DocumentPhotosWrapper>
                  <ModalButtonWrapper>
                    <ModalButton label={t('ok')} onClick={closeViewDocumentsModal} />
                  </ModalButtonWrapper>
                </DocumentsPhotosContent>
              </Modal>
            )}
            {isSignatureModalOpen && (
              <Modal open onClose={onSignatureModalClose}>
                <SignatureModalContent>
                  <SignatureCanvas
                    ref={signatureRef}
                    onClear={clearSignature}
                    onEnable={handleSignatureEnable}
                    enabled={isSignatureEnabled}
                    hasSignature={Boolean(signature)}
                    onEnd={saveSignature}
                  />
                  <SignatureModalButtonsWrapper>
                    <ModalButton onClick={onSignatureSubmit} label={t('done')} />
                    <ModalButton
                      secondary
                      onClick={onSignatureModalClose}
                      label={t('cancel')}
                    />
                  </SignatureModalButtonsWrapper>
                </SignatureModalContent>
              </Modal>
            )}
          </FormContent>
          <BottomButtonWrapper>
            {isValidGuestId && initGuestData && !formState.dirty ? (
              <Button
                secondary
                type="button"
                onClick={openDeleteGuestModal}
                disabled={hasItalianLeaderWithGuestMembers}
                label={
                  <ButtonLabelWrapper>
                    <DeleteButtonLabelIcon src={rubbishIcon} alt="Plus" />
                    <DeleteButtonLabelText>{t('delete_guest')}</DeleteButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
            ) : (
              <Button
                type="submit"
                label={
                  <ButtonLabelWrapper>
                    <ButtonLabelIcon
                      src={isValidGuestId ? floppyIcon : plusIcon}
                      alt="Plus"
                    />
                    <ButtonLabelText>
                      {isValidGuestId ? t('save_changes') : t('register_guest')}
                    </ButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
            )}
          </BottomButtonWrapper>
        </Form>
      </Content>
      {isDataIncompleteModalOpen && (
        <Modal
          open
          iconSrc={missingDataIcon}
          iconAlt="Form with red fields"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={t('data_missing')}
          text={
            <>
              {t('you_cant_add_this_booking_until')}
              <p />
              {t('we_have_outlined_fields')}
            </>
          }
        >
          <ModalButtonWrapper>
            <ModalButton label={t('ok')} onClick={closeDataIncompleteModal} />
          </ModalButtonWrapper>
        </Modal>
      )}
      {isDeleteGuestModalOpen && (
        <Modal
          open
          iconSrc={deleteGuestIcon}
          iconAlt="Guest in trash"
          iconProps={{
            height: 95,
            width: 84,
          }}
          title={isGuestDeleted ? t('success_exclamation') : t('are_you_sure')}
          text={
            isDeletingGuest ? (
              <div>
                {t('deleting_guest')}...
                <p />
                <div>{t('it_takes_seconds')}</div>
              </div>
            ) : isGuestDeleted ? (
              t('successfully_deleted')
            ) : (
              t('all_info_associated_will_be_deleted')
            )
          }
        >
          {isGuestDeleted && (
            <ModalTwoButtonsWrapper>
              <ModalButton label={t('back_to_booking')} onClick={goBack} />
              <ModalButton
                secondary
                onClick={() => {
                  setSavingGuestStatus('idle');
                  closeDeleteGuestModal();
                }}
                label={t('add_guest')}
              />
            </ModalTwoButtonsWrapper>
          )}
          {isGuestDeletionIdle && (
            <ModalTwoButtonsWrapper>
              <ModalButton onClick={deleteGuest} label={t('delete_guest')} />
              <ModalButton
                secondary
                onClick={closeDeleteGuestModal}
                label={t('cancel')}
              />
            </ModalTwoButtonsWrapper>
          )}
        </Modal>
      )}
      {isSavingGuestModalOpen && (
        <Modal
          open
          iconSrc={guestIcon}
          iconAlt="A guest"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={isGuestSaved ? t('success_exclamation') : t('saving_guest')}
          text={isSavingGuest ? `${t('it_takes_seconds')}...` : t('guest_saved')}
        >
          {isGuestSaved && (
            <ModalTwoButtonsWrapper>
              <ModalButton label={t('back_to_booking')} onClick={goBack} />
              <ModalButton
                secondary
                onClick={() => {
                  resetFormData();
                  setSavingGuestStatus('idle');
                  closeSavingGuestModal();
                  setOcrWasUsed(false);
                }}
                label={t('add_another_guest')}
              />
            </ModalTwoButtonsWrapper>
          )}
        </Modal>
      )}
      <ErrorModal onClose={closeErrorModalAndReopenScanModal} />
      <SavingErrorModal />
      <SavingTryAgainErrorModal>
        <ModalTwoButtonsWrapper>
          <ModalButton onClick={() => saveGuest(getValues())} label={t('try_again')} />
          <ModalButton
            secondary
            onClick={closeSavingTryAgainErrorModal}
            label={t('cancel')}
          />
        </ModalTwoButtonsWrapper>
      </SavingTryAgainErrorModal>
      {isDoYouWantToSaveModalOpen && (
        <YouHaveMadeChangesModal
          handleModalSave={() => handleModalSave(handleSubmit(onSubmit))}
          handleModalDontSave={handleModalDontSave}
          handleModalCancel={handleModalCancel}
        />
      )}
    </>
  );
}

GuestDetailsForm.defaultProps = defaultProps;
export {GuestDetailsForm};
