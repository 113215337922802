import React from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import api, {queryFetcher} from '../../../api';
import {useErrorToast} from '../../../utils/hooks';
import {LightReservation, Lock, UnlockLink} from '../../../utils/types';
import {LOCK_TYPES, LOCK_TYPES_OPTIONS} from '../../../utils/constants';
import {copyToClipboard} from '../../../utils/common';
import signsIcon from '../../../assets/signs.svg';
import keyIcon from '../../../assets/key_blue.svg';
import Section from '../Section';
import Loader from '../../common/Loader';
import {
  CopyButton,
  Grid,
  KeyDetails,
  KeyName,
  LoaderWrapper,
  NotFoundText,
  OpenButton,
} from './styled';

const COPY_TIMEOUT = 1500;

function fetchUnlockLinks(key: string, reservationId: string) {
  return queryFetcher(api.locks.ENDPOINTS.unlockLinks(`reservation_id=${reservationId}`));
}

function fetchLocks(key: string, reservationId: string) {
  return queryFetcher(
    api.locks.ENDPOINTS.locks(null, `unlock_links__reservation_id=${reservationId}`),
  );
}

type Unlock = Lock & {
  link: UnlockLink | null;
};

type ReservationKeysSectionProps = {
  reservation?: LightReservation;
};

function ReservationKeysSection({reservation}: ReservationKeysSectionProps) {
  const {t} = useTranslation();
  const reservationId = reservation?.id;
  const {
    data: unlockLinks,
    status: unlocksLinksStatus,
    error: unlocksLinksError,
  } = useQuery<UnlockLink[], [string, string]>(
    Boolean(reservationId) && ['unlockLinks', reservationId!],
    fetchUnlockLinks,
  );
  useErrorToast(unlocksLinksError, {
    notFoundMessage: 'Requested unlocks could not be found. Please contact support',
  });

  const {data: locks, error: locksError, status: locksStatus} = useQuery<
    Lock[],
    [string, string]
  >(Boolean(reservation?.id) && ['locks', reservation!.id], fetchLocks);
  useErrorToast(locksError, {
    notFoundMessage: 'Locks not found. Please contact support',
  });

  const isLoading = unlocksLinksStatus === 'loading' || locksStatus === 'loading';
  const unlocks: Unlock[] = React.useMemo(() => {
    if (!locks?.length || !unlockLinks?.length) {
      return [];
    }

    return locks.map(
      (lock): Unlock => {
        const link = unlockLinks.find((link) => {
          return link.lock_id === lock.id;
        });

        return {
          ...lock,
          link: link || null,
        };
      },
    );
  }, [locks, unlockLinks]);

  return (
    <Section title={t('keys')}>
      <Grid>
        {isLoading ? (
          <LoaderWrapper>
            <Loader height={45} width={45} label={t('loading')} />
          </LoaderWrapper>
        ) : unlockLinks?.length ? (
          unlocks?.map((unlock) => {
            return <KeyRow key={unlock.id} unlock={unlock} />;
          })
        ) : (
          <NotFoundText>{t('not_found')}</NotFoundText>
        )}
      </Grid>
    </Section>
  );
}

type KeyRowProps = {
  unlock: Unlock;
};

function KeyRow({unlock}: KeyRowProps) {
  const {t} = useTranslation();
  const [isTextCopied, setIsTextCopied] = React.useState(false);
  const lockType =
    LOCK_TYPES_OPTIONS[unlock.type as keyof typeof LOCK_TYPES_OPTIONS]?.label || '';
  const copyTimeoutRef = React.useRef(0);
  const name = lockType
    ? `${lockType}${
        lockType !== LOCK_TYPES_OPTIONS[LOCK_TYPES.buildingDoor]?.label
          ? ` ${t('door').toLowerCase()}`
          : ''
      } ${unlock?.name || unlock?.external_id}`
    : `${t('door')} ${unlock?.name || unlock?.external_id}`;

  const openLink = (link: string) => {
    window.open(link, '_blank');
  };

  const copyTextToClipboard = (text: string) => {
    if (copyTimeoutRef.current) {
      clearTimeout(copyTimeoutRef.current);
    }

    copyToClipboard(text);

    setIsTextCopied(true);
    copyTimeoutRef.current = setTimeout(() => {
      setIsTextCopied(false);
    }, COPY_TIMEOUT);
  };

  if (unlock?.link?.unlock_link) {
    return (
      <div>
        <KeyName>{name}: </KeyName>
        <OpenButton
          secondary
          onClick={() => openLink(unlock.link!.unlock_link)}
          label={
            <>
              <img src={keyIcon} alt="Key" />
              {t('open_door')}
            </>
          }
        />
        <CopyButton
          secondary
          onClick={() => copyTextToClipboard(unlock.link!.unlock_link)}
          label={
            <>
              <img src={signsIcon} alt="Signs" />
              {isTextCopied ? t('copied_exclamation') : t('copy_link')}
            </>
          }
        />
      </div>
    );
  }

  const unlockCode = unlock?.link?.collection_code || unlock?.link?.pass_code;
  if (unlockCode) {
    return (
      <div>
        <KeyName>{name}: </KeyName>
        <KeyDetails>
          {t('code')}: {unlockCode}
        </KeyDetails>
        <CopyButton
          secondary
          onClick={() => copyTextToClipboard(unlockCode)}
          label={
            <>
              <img src={signsIcon} alt="Signs" />
              {isTextCopied ? t('copied_exclamation') : t('copy_code')}
            </>
          }
        />
      </div>
    );
  }

  return (
    <div>
      <KeyName>{name}: </KeyName>
      <KeyDetails>{t('details_missing')}</KeyDetails>
    </div>
  );
}

export {ReservationKeysSection};
