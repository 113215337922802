import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm, FormContext} from 'react-hook-form';
import i18n from '../../../i18n';
import api from '../../../api';
import {useUser} from '../../../context/user';
import {useAuth} from '../../../context/auth';
import {useComputedDetails} from '../../../context/computedDetails';
import {getBase64, getErrorMessage, toastResponseError} from '../../../utils/common';
import {
  useErrorModal,
  useIsMounted,
  useModalControls,
  useStatus,
} from '../../../utils/hooks';
import {
  FORM_NAMES as ACCOUNT_DETAILS_FORM_NAMES,
  FormTypes,
} from '../AccountDetailsSection/AccountDetailsSection';
import logoutIcon from '../../../assets/logout-icon.svg';
import rubbishIcon from '../../../assets/rubbish.svg';
import deleteAccountIcon from '../../../assets/icon-delete-account.svg';
import {FORM_NAMES} from '../AccountDetailsSection/AccountDetailsSection';
import AccountDetailsSection from '../AccountDetailsSection';
import AccountTeamSection from '../AccountTeamSection';
import AccountPayments from '../AccountPayments';
import FloatingSaveButton from '../FloatingSaveButton';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import Loader from '../../common/Loader';
import {ContentWrapper, ModalTwoButtonsWrapper} from '../../../styled/common';
import {
  Heading,
  LogoutButton,
  Title,
  AccountCell,
  AccountType,
  DeleteAccountButton,
  DeleteAccountModalButton,
  AccountTypeTooltip,
} from './styled';

function AccountSections() {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const user = useUser();
  const formMethods = useForm<FormTypes>();
  const {formState, handleSubmit, setError} = formMethods;
  const {logout, updateAccount} = useAuth();
  const {isAccountOwner, isAccountCollaborator} = useComputedDetails();
  const {ErrorModal, isErrorModalOpen, displayError} = useErrorModal();
  const {status, isIdle, setStatus} = useStatus({
    autoReset: true,
  });
  const {setStatus: setDeletingAccountStatus, isLoading: isDeletingAccount} = useStatus();
  const {
    isOpen: isDeleteAccountModalOpen,
    closeModal: closeDeleteAccountModal,
    openModal: openDeleteAccountModal,
  } = useModalControls();

  const getAccountTypeText = () => {
    if (!user) {
      return '...';
    }

    if (isAccountOwner) {
      return i18n.t('owner');
    }

    if (isAccountCollaborator) {
      return i18n.t('collaborator');
    }

    return i18n.t('manager');
  };

  const getAccountTypeTooltipText = () => {
    if (!user) {
      return '...';
    }

    if (isAccountOwner) {
      return i18n.t('what_owner_can');
    }

    if (isAccountCollaborator) {
      return i18n.t('what_collaborator_can');
    }

    return i18n.t('what_manager_can');
  };

  const getLogoPayload = async (formData: FormTypes) => {
    const logo = formData[ACCOUNT_DETAILS_FORM_NAMES.logo];

    if (typeof logo === 'string') {
      if (!logo && user?.picture) {
        return {
          delete_picture: true,
        };
      }

      if (!logo || logo.startsWith('https://')) {
        return {};
      }
    }

    const encodedLogo = await getBase64(
      formData[ACCOUNT_DETAILS_FORM_NAMES.logo] as File,
    );

    return {
      picture_file: encodedLogo,
    };
  };

  const getAccountPayload = async (formData: FormTypes) => {
    const logoPayload = await getLogoPayload(formData);

    return {
      ...formData,
      ...logoPayload,
      phone: formData[ACCOUNT_DETAILS_FORM_NAMES.phoneNumber],
      [ACCOUNT_DETAILS_FORM_NAMES.phoneNumber]: undefined,
      [ACCOUNT_DETAILS_FORM_NAMES.repeatedNewPassword]: undefined,
      [ACCOUNT_DETAILS_FORM_NAMES.logo]: undefined,
    };
  };

  const updateAccountDetails = async (formData: FormTypes) => {
    setStatus('loading');

    const accountPayload = await getAccountPayload(formData);
    const {error} = await updateAccount(accountPayload);

    if (!isMounted.current) {
      return;
    }

    if (error) {
      const extractedError = getErrorMessage(error);
      const IsErrorAssociatedWithPassword = extractedError.includes(
        FORM_NAMES.newPassword,
      );
      setStatus('idle');

      if (IsErrorAssociatedWithPassword) {
        setError(FORM_NAMES.newPassword, {
          message: extractedError,
        });
        return;
      }

      displayError(error);
      return;
    }

    setStatus('success');
  };

  const deleteAccount = async () => {
    setDeletingAccountStatus('loading');

    const {error} = await api.users.deleteMe();

    if (error) {
      setDeletingAccountStatus('idle');
      toastResponseError(error);
      return;
    }
    logout();
  };

  const onSubmit = (formData: FormTypes) => {
    updateAccountDetails(formData);
  };

  return (
    <>
      <ContentWrapper>
        <Heading>
          <div />
          <AccountCell>
            <Title>{t('account')}</Title>
            <AccountType>
              {t('type_of_account')}: {getAccountTypeText()}{' '}
              <AccountTypeTooltip content={getAccountTypeTooltipText()} />
            </AccountType>
          </AccountCell>
          <div>
            <LogoutButton
              secondary
              onClick={logout}
              label={
                <>
                  <img src={logoutIcon} alt="Exit" />
                  {t('logout')}
                </>
              }
            />
          </div>
        </Heading>
        <main>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContext {...formMethods}>
              <AccountDetailsSection status={status} />
              <AccountPayments disabled={status === 'loading'} />
              {isAccountOwner && (
                <>
                  <AccountTeamSection />
                  <DeleteAccountButton
                    secondary
                    onClick={openDeleteAccountModal}
                    label={
                      <>
                        <img src={rubbishIcon} alt="Rubbish" />
                        {t('delete_account')}
                      </>
                    }
                  />
                </>
              )}
              {(formState.dirty || !isIdle) && !isErrorModalOpen && (
                <FloatingSaveButton status={status} />
              )}
            </FormContext>
          </form>
        </main>
      </ContentWrapper>
      {isDeleteAccountModalOpen && (
        <Modal
          closeOnDocumentClick
          closeOnEscape
          iconSrc={deleteAccountIcon}
          iconAlt="User icon in trash"
          iconProps={{
            height: 101,
            width: 84,
          }}
          onClose={closeDeleteAccountModal}
          title={t('are_you_sure')}
          text={t('all_the_info_will_be_lost_this_cannot_be_undone')}
        >
          <ModalTwoButtonsWrapper>
            {isDeletingAccount ? (
              <Loader height={40} width={40} />
            ) : (
              <>
                <DeleteAccountModalButton
                  label={t('delete_my_account')}
                  onClick={deleteAccount}
                />
                <ModalButton
                  secondary
                  label={t('cancel')}
                  onClick={closeDeleteAccountModal}
                />
              </>
            )}
          </ModalTwoButtonsWrapper>
        </Modal>
      )}
      <ErrorModal />
    </>
  );
}

export {AccountSections};
