import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import AddHousingSections from '../components/dashboard/AddHousingSections';

function HousingsAdd() {
  return (
    <>
      <TrialHeader />
      <Header />
      <AddHousingSections />
    </>
  );
}

export {HousingsAdd};
