import React from 'react';
import ReactSelect, {createFilter, components} from 'react-select';
import {ErrorMessage} from '../../../styled/common';
import {SelectOption} from '../../../utils/types';
import {Wrapper, Label, ClearIcon} from './styled';
import removeIcon from '../../../assets/remove.svg';

const MIN_SEARCH_ENABLE_OPTIONS_LENGTH = 4;

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <ClearIcon src={removeIcon} alt="Remove" />
    </components.MultiValueRemove>
  );
};

type MultiSelectProps = {
  onChange?: (option: any, info: any) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  options?: SelectOption[];
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
  value?: SelectOption[];
  name?: string;
  placeholder?: string;
  error?: any;
  className?: string;
  isSearchable?: boolean;
  defaultValue?: SelectOption[];
  defaultMenuIsOpen?: boolean;
};

const defaultProps: MultiSelectProps = {
  onChange: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
  options: [],
  value: [],
  label: '',
  invalid: false,
  disabled: false,
  name: '',
  placeholder: '- -',
  error: '',
  className: undefined,
  isSearchable: undefined,
  defaultValue: undefined,
  defaultMenuIsOpen: false,
};

function MultiSelect({
  onChange,
  options,
  value,
  label,
  invalid,
  disabled,
  name,
  placeholder,
  error,
  className,
  onMenuOpen,
  onMenuClose,
  isSearchable,
  defaultValue,
  defaultMenuIsOpen,
}: MultiSelectProps) {
  const ref = React.useRef<any>();
  const onChangeHandler = disabled ? undefined : onChange;
  const isSearchEnabled =
    isSearchable || options?.length! > MIN_SEARCH_ENABLE_OPTIONS_LENGTH;

  React.useEffect(() => {
    // Disable first menu item autofocus
    ref.current.select.getNextFocusedOption = () => null;
  }, []);

  return (
    <Wrapper
      disabled={disabled}
      invalid={invalid || Boolean(error)}
      className={className}
    >
      {label && <Label>{label}</Label>}
      <ReactSelect
        isMulti
        ref={ref}
        components={{MultiValueRemove}}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        className="select"
        classNamePrefix="select"
        value={value}
        onChange={onChangeHandler}
        options={options}
        placeholder={placeholder}
        aria-label={name}
        name={name}
        isDisabled={disabled}
        isClearable={false}
        isSearchable={isSearchEnabled}
        filterOption={createFilter({ignoreAccents: false})}
        defaultValue={defaultValue}
        defaultMenuIsOpen={defaultMenuIsOpen}
      />
      {error && <ErrorMessage data-testid={`${name}-error`}>{error}</ErrorMessage>}
    </Wrapper>
  );
}

MultiSelect.defaultProps = defaultProps;
export {MultiSelect};
