import styled, {css} from 'styled-components';
import Button from '../Button';

export const GuestList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

type GuestNameProps = {
  active: boolean;
};

export const GuestName = styled.button<GuestNameProps>`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px 0;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 16px;
  height: 20px;
  color: #9696b9;
  text-align: left;

  ${(props) =>
    props.active &&
    css`
      font-family: ProximaNova-Bold, sans-serif;
      color: #161643;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const FailedGuestsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
`;

export const GuestPhotosApproval = styled.div`
  width: 415px;
  height: 225px;
  box-shadow: 0 5px 5px #2699fb1a;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 23px;
  background-color: transparent;
  position: relative;
`;

export const SingleGuestPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 153px;
`;

export const GuestPhotoLabel = styled.div`
  font-family: ProximaNova-Light, sans-serif;
  font-size: 11px;
  color: #161643;
  text-align: center;
`;

type GuestPhotoProps = {
  image: string | null;
};
export const GuestPhoto = styled.div<GuestPhotoProps>`
  background-image: url(${(props) => props.image});
  box-shadow: 0 16px 16px #2699fb1a;
  width: 100%;
  height: 152px;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
`;

export const GuestPhotosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const GuestDescription = styled.div`
  font-family: ProximaNova-Light, sans-serif;
  font-size: 16px;
  color: #161643;
  text-align: left;

  & > b {
    font-family: ProximaNova-Medium, sans-serif;
    font-style: italic;
  }
`;

export const ApproveButton = styled(Button)`
  position: absolute;
  bottom: -65px;
  right: 0;
  left: 0;
  margin: 0 auto;
  border-color: #1a8cff;

  & > div {
    flex: 1;
    justify-content: center;
  }
`;

export const ApprovalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(255 255 255 / 0.96);
  z-index: 8;
`;

export const OverlayText = styled.div`
  position: absolute;
  top: 74px;
  left: 0;
  right: 0;
  text-align: center;
  font-family: ProximaNova-Bold, sans-serif;
  font-size: 18px;
  color: #161643;
`;

export const OverlayButton = styled(ApproveButton)`
  position: static;
  margin-top: 37px;
`;
