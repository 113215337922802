import {getAnonymousHeaders, resolver} from './index';

const ENDPOINTS = {
  all: (params = '') => `locations/?${params}`,
};

function getLocations(params = '') {
  return resolver(ENDPOINTS.all(params), {
    method: 'GET',
    headers: getAnonymousHeaders(),
  });
}

export {ENDPOINTS, getLocations};
