import React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import i18n from '../../../i18n';
import {getRequiredOrOptionalFieldLabel} from '../../../utils/common';
import {useIsFormTouched} from '../../../utils/hooks';
import {ExternalSource} from '../../../utils/types';
import Section from '../Section';
import Switch from '../Switch';
import Input from '../Input';
import {FieldWrapper} from '../../../styled/common';
import {TooltipContentItem, Content} from './styled';

export enum FORM_NAMES {
  airbnb = 'AIRBNB_CALENDAR',
  booking = 'BOOKING_CALENDAR',
  other = 'GENERIC_CALENDAR',
}

const TOOLTIP_CONTENT = (
  <>
    <TooltipContentItem>{i18n.t('icals_section_tooltip_first_part')}</TooltipContentItem>
    <TooltipContentItem>{i18n.t('icals_section_tooltip_second_part')}</TooltipContentItem>
  </>
);

const displayFields = {
  [FORM_NAMES.airbnb]: true,
  [FORM_NAMES.booking]: true,
  [FORM_NAMES.other]: true,
};

const defaultValues = {
  [FORM_NAMES.airbnb]: '',
  [FORM_NAMES.other]: '',
  [FORM_NAMES.booking]: '',
};

type HousingICalsSectionProps = {
  disabled: boolean;
  initICals?: Array<ExternalSource>;
  setIsSectionTouched?: (isTouched: boolean) => void;
};

function HousingICalsSection({
  disabled,
  initICals,
  setIsSectionTouched,
}: HousingICalsSectionProps) {
  const {t} = useTranslation();
  const [isSectionActive, setIsSectionActive] = React.useState(false);
  const {register, errors, setValue, watch} = useFormContext();
  const {isFormTouched, setUntouchedValues} = useIsFormTouched({
    displayFields,
    defaultValues,
    watch,
  });

  React.useEffect(() => {
    if (typeof setIsSectionTouched === 'function') {
      setIsSectionTouched(isFormTouched);
    }
  }, [isFormTouched, setIsSectionTouched]);

  React.useEffect(() => {
    if (initICals?.length) {
      setIsSectionActive(true);
    }
  }, [initICals]);

  React.useEffect(() => {
    if (initICals?.length && isSectionActive) {
      const formValues = initICals.map((item) => {
        const iCal = {
          [item.type]: item.url,
        };

        setUntouchedValues((prevState) => {
          return {
            ...prevState,
            ...iCal,
          };
        });
        return iCal;
      });

      setValue(formValues);
    }
  }, [isSectionActive, initICals, setValue, setUntouchedValues]);

  const toggleActiveICals = () => {
    setIsSectionActive((prevState) => {
      return !prevState;
    });

    if (typeof setIsSectionTouched === 'function') {
      setIsSectionTouched(true);
    }
  };

  return (
    <Section
      title={t('icals_connection_title')}
      subtitle={t('icals_connection_subtitle')}
      tooltipContent={TOOLTIP_CONTENT}
    >
      <Content>
        <Switch
          checked={isSectionActive}
          onChange={toggleActiveICals}
          label={t('activate_icals')}
          disabled={disabled}
        />
        {isSectionActive && (
          <div>
            <FieldWrapper>
              <Input
                ref={register}
                label={getRequiredOrOptionalFieldLabel(t('booking'), false)}
                name={FORM_NAMES.booking}
                error={errors[FORM_NAMES.booking]?.message}
                placeholder={t('enter_url')}
                disabled={disabled}
                inputMode="url"
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                ref={register}
                label={getRequiredOrOptionalFieldLabel(t('airbnb'), false)}
                name={FORM_NAMES.airbnb}
                error={errors[FORM_NAMES.airbnb]?.message}
                placeholder={t('enter_url')}
                disabled={disabled}
                inputMode="url"
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                ref={register}
                label={getRequiredOrOptionalFieldLabel(t('other'), false)}
                name={FORM_NAMES.other}
                error={errors[FORM_NAMES.other]?.message}
                placeholder={t('enter_url')}
                inputMode="url"
                disabled={disabled}
              />
            </FieldWrapper>
          </div>
        )}
      </Content>
    </Section>
  );
}

export {HousingICalsSection};
