import styled from 'styled-components';

export const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-rows: auto;

  & > :nth-child(n) {
    padding-bottom: 30px;
    border-right: 1px solid rgba(22, 22, 67, 0.12);
  }

  & > :nth-child(3n + 1) {
    padding-right: 64px;
  }

  & > :nth-child(3n + 2) {
    padding-right: 64px;
    padding-left: 64px;
  }

  & > :nth-child(3n) {
    padding-left: 64px;
    border-right: none;
  }

  & > :nth-last-child(-n + 3) {
    height: 49px;
    padding-bottom: 0;
  }
`;
