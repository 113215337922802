import React, {Dispatch, SetStateAction} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import type {InputEventType, Plan} from '../../../utils/types';
import {useErrorToast, useModalControls} from '../../../utils/hooks';
import {useSubscription} from '../../../context/subscription';
import {openHubspotChat} from '../../../analytics/hubspot';
import {ErrorMessage} from '../../../styled/common';
import {
  SUBSCRIPTION_INTERVALS,
  SUBSCRIPTION_PRODUCT_TYPES,
} from '../../../utils/constants';
import {LockUser, PlanTotalPrice} from '../../../utils/types';
import {getSubscriptionInterval, getSubscriptionPlan} from '../../../utils/subscription';
import {useDebounce} from '../../../utils/hooks';
import {useUser} from '../../../context/user';
import api, {queryFetcher} from '../../../api';
import minusIcon from '../../../assets/minus.svg';
import plusIcon from '../../../assets/plus.svg';
import xIcon from '../../../assets/x_blue.svg';
import checkIcon from '../../../assets/check-green.svg';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import Loader from '../../common/Loader';
import SelfCheckinSelectProviderModal from '../SelfCheckinSelectProviderModal';
import {
  contentStyle,
  Subtitle,
  Title,
  Input,
  InputLabel,
  SpinnerButton,
  SpinnerButtonsWrapper,
  SpinnerIcon,
  InputWrapper,
  PriceContainer,
  Price,
  PriceTitle,
  Main,
  Currency,
  PriceSubtitle,
  LoaderWrapper,
  CloseButton,
  ContactSupportButton,
  FinishModalButtonWrapper,
} from './styled';

const MIN_ACCOMMODATIONS_NUMBER = 1;
const MAX_ACCOMMODATIONS_NUMBER = 99999;
const MAX_ACCOMMODATIONS_NUMBER_LENGTH = 5;
const ACCOMMODATIONS_NUMBER_DEBOUNCE_DELAY_MS = 500;

function fetchSelfCheckinPlan(
  key: string,
  subscriptionType: string,
  subscriptionInterval: string,
) {
  const params = `type=${subscriptionType}&interval=${subscriptionInterval}&product=${SUBSCRIPTION_PRODUCT_TYPES.selfCheckin}`;
  return queryFetcher(api.payments.ENDPOINTS.plans(params));
}

function fetchLockUsers() {
  return queryFetcher(api.locks.ENDPOINTS.lockUsers());
}

function fetchSelfCheckinPrice(key: string, planId: string, quantity = 0) {
  return queryFetcher(
    api.payments.ENDPOINTS.planTotalPrice(planId, `quantity=${quantity}`),
  );
}

type SelfCheckinSubscriptionModalProps = {
  open: boolean;
  isSectionActive: boolean;
  onClose: () => void;
  setIsSectionActive?: Dispatch<SetStateAction<boolean>>;
  setSectionTouched?: () => void;
};

const defaultProps: Partial<SelfCheckinSubscriptionModalProps> = {
  open: false,
  isSectionActive: false,
  onClose: () => {},
  setIsSectionActive: () => {},
  setSectionTouched: () => {},
};

function SelfCheckinSubscriptionAndProviderModals({
  open,
  onClose,
  setIsSectionActive,
  setSectionTouched,
  isSectionActive,
}: SelfCheckinSubscriptionModalProps) {
  const {t} = useTranslation();
  const user = useUser();
  const {
    isHotelSubscription,
    subscription,
    isSelfCheckinActive,
    isTrialMode,
  } = useSubscription();
  const [accommodationsNumber, setAccommodationsNumber] = React.useState(() => {
    return user?.housings_quantity || '';
  });
  const [accommodationsNumberError, setAccommodationsNumberError] = React.useState('');
  const debouncedAccommodationsNumber = useDebounce(
    accommodationsNumber,
    ACCOMMODATIONS_NUMBER_DEBOUNCE_DELAY_MS,
  );

  const {
    isOpen: isProviderModalOpen,
    closeModal: closeProviderModal,
    openModal: openProviderModal,
  } = useModalControls();
  const {
    isOpen: isFinishModalOpen,
    closeModal: closeFinishModal,
    openModal: openFinishModal,
  } = useModalControls();

  const subscriptionType = user?.subscription_type;
  const subscriptionInterval =
    getSubscriptionInterval(subscription) || SUBSCRIPTION_INTERVALS.month;
  const {
    data: selfCheckinPlan,
    error: selfCheckinPlanError,
    status: selfCheckinPlanStatus,
  } = useQuery<Plan, [string, string, string]>(
    Boolean(subscriptionType && subscriptionInterval) && [
      'selfCheckInPlan',
      subscriptionType!,
      subscriptionInterval!,
    ],
    fetchSelfCheckinPlan,
  );
  useErrorToast(selfCheckinPlanError, {
    notFoundMessage: 'Self-checkin plan could not be found. Please contact support.',
  });

  const {data: lockUsers, error: lockUsersError, status: lockUsersStatus} = useQuery<
    LockUser[],
    string
  >('lockUsers', fetchLockUsers);
  useErrorToast(lockUsersError, {
    notFoundMessage: 'Lock users not found. Please contact support',
  });

  const activeSelfCheckinPlanId = getSubscriptionPlan(
    subscription,
    SUBSCRIPTION_PRODUCT_TYPES.selfCheckin,
  );
  const selfCheckinPlanId = isSelfCheckinActive
    ? activeSelfCheckinPlanId
    : selfCheckinPlan?.unique_id;

  const {
    data: planTotalPrice,
    error: planTotalPriceError,
    status: planTotalPriceStatus,
  } = useQuery<PlanTotalPrice, [string, string, number?]>(
    Boolean(selfCheckinPlanId) && [
      'selfCheckinPrice',
      selfCheckinPlanId!,
      Number(debouncedAccommodationsNumber),
    ],
    fetchSelfCheckinPrice,
  );
  useErrorToast(planTotalPriceError, {
    notFoundMessage: 'Requested plan price could not be found. Please contact support',
  });

  React.useLayoutEffect(() => {
    const shouldOpenProviderModal =
      (open && isSelfCheckinActive) || isSectionActive || isTrialMode;

    if (shouldOpenProviderModal) {
      openProviderModal();
    } else {
      closeProviderModal();
    }
  }, [
    closeProviderModal,
    isSectionActive,
    isSelfCheckinActive,
    isTrialMode,
    open,
    openProviderModal,
  ]);

  React.useEffect(() => {
    if (
      accommodationsNumber &&
      Number(accommodationsNumber) < MIN_ACCOMMODATIONS_NUMBER
    ) {
      setAccommodationsNumberError(
        t('min_number_is', {number: MIN_ACCOMMODATIONS_NUMBER}),
      );
    } else {
      setAccommodationsNumberError('');
    }
  }, [accommodationsNumber, t]);

  const incrementAccommodationsNumber = () => {
    setAccommodationsNumber((prevState) => {
      const number = Number(prevState);

      if (number < MIN_ACCOMMODATIONS_NUMBER) {
        return String(MIN_ACCOMMODATIONS_NUMBER);
      }
      if (number >= MAX_ACCOMMODATIONS_NUMBER) {
        return prevState;
      }
      return String(number + 1);
    });
  };

  const decrementAccommodationsNumber = () => {
    setAccommodationsNumber((prevState) => {
      const number = Number(prevState);

      if (number <= MIN_ACCOMMODATIONS_NUMBER) {
        return String(MIN_ACCOMMODATIONS_NUMBER);
      }
      return String(number - 1);
    });
  };

  const handleAccommodationsNumberChange = (event: InputEventType) => {
    const number = event.target.value;

    if (
      number.length > MAX_ACCOMMODATIONS_NUMBER_LENGTH ||
      Number(number) > MAX_ACCOMMODATIONS_NUMBER
    ) {
      return;
    }
    setAccommodationsNumber(number);
  };

  const getPlanSubtitle = () => {
    const isYearlySubscriptionInterval =
      subscriptionInterval === SUBSCRIPTION_INTERVALS.year;
    const displayNumber =
      accommodationsNumber === '' || accommodationsNumber < 0 ? 0 : accommodationsNumber;

    if (isYearlySubscriptionInterval) {
      return isHotelSubscription
        ? t('year_per_number_rooms', {number: displayNumber})
        : t('year_per_number_properties', {number: displayNumber});
    }

    return isHotelSubscription
      ? t('month_per_number_rooms', {number: displayNumber})
      : t('month_per_number_properties', {number: displayNumber});
  };

  const getPlanTotalPrice = () => {
    if (planTotalPriceStatus === 'loading') {
      return <Loader height={30} width={30} />;
    }

    return (
      <div>
        {planTotalPrice?.price_with_tax || planTotalPrice?.price || '0.00'}{' '}
        <Currency>€</Currency>
      </div>
    );
  };

  const handleFinish = async () => {
    if (setIsSectionActive && setSectionTouched) {
      setIsSectionActive(true);
      setSectionTouched();
    }

    closeProviderModal();
    openFinishModal();
  };

  const handleProviderModalClose = () => {
    if (isSelfCheckinActive || isTrialMode || lockUsers?.length) {
      closeProviderModal();
      onClose();
    } else {
      closeProviderModal();
    }
  };

  const handleFinishModalClose = () => {
    closeFinishModal();
    closeProviderModal();
    onClose();
  };

  const handleSubmit = async () => {
    if (!lockUsers?.length) {
      openProviderModal();
      return;
    }

    handleFinish();
  };

  if (!open) {
    return null;
  }

  if (isFinishModalOpen) {
    return (
      <Modal
        iconSrc={checkIcon}
        iconAlt="Green checkmark"
        iconProps={{height: 84, width: 84}}
        title={t('successfully_added_exclamation')}
        text={
          <>
            <Trans i18nKey="if_youd_like_to_modify_do_in_account">
              If you’d like to modify anything, please do it from your <b>account</b>{' '}
              section here in CheKin. If you have any question related to this, please
              contact support.
            </Trans>
            <p />
            {t('if_you_have_any_questions_please')}
            <ContactSupportButton type="button" onClick={openHubspotChat}>
              {t('contact_support')}
            </ContactSupportButton>
            .
          </>
        }
      >
        <FinishModalButtonWrapper>
          <ModalButton label={t('ok')} onClick={handleFinishModalClose} />
        </FinishModalButtonWrapper>
      </Modal>
    );
  }

  if (isProviderModalOpen) {
    return (
      <SelfCheckinSelectProviderModal
        open={isProviderModalOpen}
        onClose={handleProviderModalClose}
        onFinish={handleFinish}
      />
    );
  }

  return (
    <Modal
      open
      closeOnDocumentClick
      closeOnEscape
      onClose={onClose}
      contentStyle={contentStyle}
    >
      <React.Suspense
        fallback={
          <LoaderWrapper>
            <Loader height={45} width={45} label={t('loading')} />
          </LoaderWrapper>
        }
      >
        <CloseButton onClick={onClose}>
          <img src={xIcon} alt="Cross" />
        </CloseButton>
        <Title>{t('upgrade_to_premium')}</Title>
        <Subtitle>{t('with_premium_you_will_have_self_checkin')}</Subtitle>
        <Main>
          <div>
            <InputLabel htmlFor="propertiesNumber">
              {isHotelSubscription
                ? t('enter_number_of_rooms')
                : t('enter_number_of_properties')}
            </InputLabel>
            <InputWrapper>
              <Input
                id="propertiesNumber"
                error={accommodationsNumberError}
                placeholder={t('enter_number')}
                onChange={handleAccommodationsNumberChange}
                value={accommodationsNumber}
                type="number"
                min={MIN_ACCOMMODATIONS_NUMBER}
                max={MAX_ACCOMMODATIONS_NUMBER}
              />
              <SpinnerButtonsWrapper>
                <SpinnerButton onClick={decrementAccommodationsNumber} type="button">
                  <SpinnerIcon src={minusIcon} alt="Minus" />
                </SpinnerButton>
                <SpinnerButton onClick={incrementAccommodationsNumber} type="button">
                  <SpinnerIcon src={plusIcon} alt="Plus" />
                </SpinnerButton>
              </SpinnerButtonsWrapper>
              <ErrorMessage>{accommodationsNumberError}</ErrorMessage>
            </InputWrapper>
          </div>
          <div>
            <PriceContainer>
              <PriceTitle>{t('price')}:</PriceTitle>
              <Price>
                {selfCheckinPlanStatus === 'loading' ? (
                  <Loader height={20} width={20} />
                ) : (
                  <div>{getPlanTotalPrice()}</div>
                )}
              </Price>
              <PriceSubtitle>{getPlanSubtitle()}</PriceSubtitle>
              <ModalButton
                disabled={
                  !accommodationsNumber ||
                  Boolean(accommodationsNumberError) ||
                  lockUsersStatus === 'loading'
                }
                onClick={handleSubmit}
                label={t('upgrade_to_premium')}
              />
            </PriceContainer>
          </div>
        </Main>
      </React.Suspense>
    </Modal>
  );
}

SelfCheckinSubscriptionAndProviderModals.defaultProps = defaultProps;
export {SelfCheckinSubscriptionAndProviderModals};
