import {resolver} from './index';
import {ReportFieldsAndDate} from '../utils/types';

const ENDPOINTS = {
  allContracts: (params = '') => `documents/contracts/?${params}`,
  availableContractsYears: (id = '') => `documents/contracts/available-years/${id}/`,
  contract: (id = '') => `documents/contracts/${id}/`,
  archiveGenerationTask: (params = '') =>
    `documents/contract-archive-generation-task/?${params}`,
  entryForm: (id = '') => `documents/entry-forms/${id}/`,
  entryForms: (params = '') => `documents/entry-forms/?${params}`,
  allContractsDownload: (housingId: string, year: string) =>
    `documents/contracts/download-all/${housingId}/${year}/`,
  guestbookSettings: (housingId: string) => `documents/guest-book-settings/${housingId}/`,
  allGuestbooks: (params = '') => `documents/guest-books/?${params}`,
  guestReportFields: () => 'documents/guest-report-fields',
  guestReports: (id = '', params = '') =>
    `documents/guest-reports/${id ? `${id}/?${params}` : `?${params}`}`,
  guestReportsTask: (params = '') =>
    `documents/guest-reports-excel-generation-task/?${params}`,
  guestReportsExcel: (reportId: string, housingId: string, params = '') =>
    `documents/guest-reports/${reportId}/download-excel/${
      housingId ? `${housingId}/?${params}` : `all/?${params}`
    }`,
  entryFormsGenerationTask: (params = '') =>
    `documents/entry-forms-archive-generation-task/?${params}`,
  allEntryFormsDownload: (reservationId: string) =>
    `documents/entry-forms/download-all/${reservationId}`,
  guestbookGenerationTask: (params = '') =>
    `documents/guest-book-generation-task/?${params}`,
  reservationReportFields: () => 'documents/reservation-report-fields',
  reservationReports: (id?: string) => `documents/reservation-reports/${id ? id : ''}`,
  reservationReportTasks: () => 'documents/reservation-report-tasks/',
  reservationReportsExcel: (housingId: string, housingName: string) =>
    `documents/reservation-reports/download-excel/${housingId}/?housing_name=${housingName}`,
  reservationReportsExcelAll: () => 'documents/reservation-reports/download-excel/all/',
};

function getContract(id: string) {
  return resolver(ENDPOINTS.contract(id));
}

function getAllContracts(params = '') {
  return resolver(ENDPOINTS.allContracts(params));
}

function getAvailableContractsYears(id: string) {
  return resolver(ENDPOINTS.availableContractsYears(id));
}

type GenerationTaskPayload = {
  housing: string;
  is_force: boolean;
  year: string;
};
function startArchiveGenerationTask(payload: GenerationTaskPayload) {
  return resolver(ENDPOINTS.archiveGenerationTask(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function deleteContract(id: string) {
  return resolver(ENDPOINTS.contract(id), {
    method: 'DELETE',
  });
}

function getEntryForms(params = '') {
  return resolver(ENDPOINTS.entryForms(params));
}

function deleteEntryForm(id: string) {
  return resolver(ENDPOINTS.entryForm(id), {
    method: 'DELETE',
  });
}

type DownloadAllContractsTypes = {
  housingId: string;
  year: string;
};
function getDownloadAllContractsLink({housingId, year}: DownloadAllContractsTypes) {
  return resolver(ENDPOINTS.allContractsDownload(housingId, year));
}

function getGuestbookSettings(housingId: string) {
  return resolver(ENDPOINTS.guestbookSettings(housingId));
}

type UpdateGuestbookSettingsTypes = {
  housingId: string;
  startingNumber: number;
};
function updateGuestbookSettings({
  housingId,
  startingNumber,
}: UpdateGuestbookSettingsTypes) {
  const payload = {
    starting_num: startingNumber,
  };

  return resolver(ENDPOINTS.guestbookSettings(housingId), {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

function getGuestbooks(params = '') {
  return resolver(ENDPOINTS.allGuestbooks(params));
}

function getGuestReports(params = '') {
  return resolver(ENDPOINTS.guestReports('', params));
}

function downloadGuestReport(id: string, housingId: string, params = '') {
  return resolver(ENDPOINTS.guestReportsExcel(id, housingId, params));
}

function patchGuestReport(id: string, payload: ReportFieldsAndDate, params = '') {
  return resolver(ENDPOINTS.guestReports(id, params), {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
}

function postGuestReport(payload: ReportFieldsAndDate, params = '') {
  return resolver(ENDPOINTS.guestReports('', params), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

type ReportGenerationTaskPayload = {
  housing_id?: string;
};

function startGuestReportGenerationTask(
  payload: ReportGenerationTaskPayload | {},
  params = '',
) {
  return resolver(ENDPOINTS.guestReportsTask(params), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

type EntryFormsTaskPayload = {
  reservation: string;
  is_force: boolean;
};

function startEntryFormsGenerationTask(payload: EntryFormsTaskPayload) {
  return resolver(ENDPOINTS.entryFormsGenerationTask(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function downloadEntryFormsArchive(reservationId: string) {
  return resolver(ENDPOINTS.allEntryFormsDownload(reservationId));
}

function startGuestbookGenerationTask(housingId: string, params = '') {
  const payload = {
    housing_id: housingId,
  };

  return resolver(ENDPOINTS.guestbookGenerationTask(params), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function postReservationReport(payload: ReportFieldsAndDate) {
  return resolver(ENDPOINTS.reservationReports(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function patchReservationReport(id: string, payload: ReportFieldsAndDate) {
  return resolver(ENDPOINTS.reservationReports(id), {
    body: JSON.stringify(payload),
    method: 'PATCH',
  });
}

function startReservationReportGenerationTask(payload: ReportGenerationTaskPayload | {}) {
  return resolver(ENDPOINTS.reservationReportTasks(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function downloadReservationReport(housingId: string, housingName: string) {
  return resolver(ENDPOINTS.reservationReportsExcel(housingId, housingName));
}

function downloadReservationReportsAll() {
  return resolver(ENDPOINTS.reservationReportsExcelAll());
}

export {
  ENDPOINTS,
  getGuestReports,
  patchGuestReport,
  postGuestReport,
  startGuestReportGenerationTask,
  getContract,
  getEntryForms,
  getAllContracts,
  getAvailableContractsYears,
  startArchiveGenerationTask,
  getDownloadAllContractsLink,
  updateGuestbookSettings,
  getGuestbooks,
  getGuestbookSettings,
  downloadGuestReport,
  startEntryFormsGenerationTask,
  downloadEntryFormsArchive,
  startGuestbookGenerationTask,
  deleteContract,
  deleteEntryForm,
  postReservationReport,
  patchReservationReport,
  startReservationReportGenerationTask,
  downloadReservationReport,
  downloadReservationReportsAll,
};
