import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import SubscriptionSelectPlanStep from '../components/dashboard/SubscriptionSelectPlanStep';

function SubscriptionSelectPlan() {
  return (
    <>
      <TrialHeader />
      <Header />
      <SubscriptionSelectPlanStep />
    </>
  );
}

export {SubscriptionSelectPlan};
