import React from 'react';
import {usePrevious} from '../../../utils/hooks';
import {InputEventType} from '../../../utils/types';
import {ErrorMessage} from '../../../styled/common';
import minusIcon from '../../../assets/minus.svg';
import plusIcon from '../../../assets/plus.svg';
import eyeIcon from '../../../assets/eye.svg';
import pinkEyeIcon from '../../../assets/eye-pink.svg';
import {
  Wrapper,
  StyledInput,
  Label,
  SpinnerButtonsWrapper,
  SpinnerButton,
  SpinnerIcon,
  RevealPasswordIcon,
} from './styled';

export type InputProps = {
  onChange?: (e: InputEventType) => void;
  type?: string;
  label?: string;
  value?: string | number;
  invalid?: boolean;
  disabled?: boolean;
  className?: string;
  defaultValue?: string | number;
  name?: string;
  placeholder?: string;
  max?: number;
  error?: any;
  autoComplete?: string;
  readOnly?: boolean;
  step?: string;
  showNumberButtons?: boolean;
  onIncrement?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDecrement?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  empty?: boolean;
  hideReveal?: boolean;
  [key: string]: any;
};

const defaultProps: InputProps = {
  onChange: undefined,
  label: '',
  value: undefined,
  disabled: false,
  defaultValue: undefined,
  invalid: undefined,
  className: undefined,
  type: 'text',
  max: undefined,
  error: '',
  autoComplete: 'chrome-off',
  readOnly: undefined,
  step: undefined,
  showNumberButtons: undefined,
  onIncrement: () => {},
  onDecrement: () => {},
  empty: undefined,
  hideReveal: false,
  inputMode: undefined,
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      className,
      label,
      invalid,
      defaultValue,
      disabled,
      name,
      type,
      max,
      error,
      autoComplete,
      readOnly,
      step,
      showNumberButtons,
      onIncrement,
      onDecrement,
      empty,
      hideReveal,
      inputMode,
      ...props
    },
    ref,
  ) => {
    const [inputType, setInputType] = React.useState(type);
    const [isPasswordRevealed, setIsPasswordRevealed] = React.useState(false);
    const prevInputType = usePrevious(inputType);

    const isEmpty = typeof empty === 'undefined' ? !value : empty;
    const isRevealPasswordIconVisible =
      (prevInputType === 'password' || type === 'password') && !isEmpty;

    const revealPassword = () => {
      setInputType('text');
      setIsPasswordRevealed(true);
    };

    const hidePassword = () => {
      setInputType('password');
      setIsPasswordRevealed(false);
    };

    const togglePasswordRevealing = () => {
      if (isPasswordRevealed) {
        hidePassword();
        return;
      }
      revealPassword();
    };

    return (
      <Wrapper className={className} disabled={disabled}>
        <Label>{label}</Label>
        <StyledInput
          ref={ref}
          type={inputType}
          data-testid="input"
          value={value}
          onChange={onChange}
          invalid={invalid || Boolean(error)}
          disabled={disabled}
          defaultValue={defaultValue}
          aria-label={name}
          name={name}
          max={max}
          autoComplete={autoComplete}
          readOnly={readOnly}
          step={step}
          inputMode={inputMode}
          {...props}
        />
        {type === 'number' && showNumberButtons && (
          <SpinnerButtonsWrapper>
            <SpinnerButton onClick={onDecrement as any} type="button">
              <SpinnerIcon src={minusIcon} alt="Minus" />
            </SpinnerButton>
            <SpinnerButton onClick={onIncrement as any} type="button">
              <SpinnerIcon src={plusIcon} alt="Plus" />
            </SpinnerButton>
          </SpinnerButtonsWrapper>
        )}
        {!hideReveal && isRevealPasswordIconVisible && (
          <RevealPasswordIcon
            src={isPasswordRevealed ? pinkEyeIcon : eyeIcon}
            onClick={togglePasswordRevealing}
            alt="Eye"
          />
        )}
        {error && <ErrorMessage data-testid={`${name}-error`}>{error}</ErrorMessage>}
      </Wrapper>
    );
  },
);

Input.defaultProps = defaultProps;
export {Input};
