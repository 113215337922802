import React from 'react';
import {useTranslation} from 'react-i18next';
import backIcon from '../../../assets/icon-arrow-left.svg';
import {Button} from './styled';

type BackButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

function BackButton({onClick, disabled}: BackButtonProps) {
  const {t} = useTranslation();

  return (
    <Button type="button" disabled={disabled} onClick={onClick}>
      <img src={backIcon} alt="Back" />
      {t('back')}
    </Button>
  );
}

export {BackButton};
