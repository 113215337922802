import React from 'react';
import {FormContext, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {toastResponseError} from '../../../utils/common';
import {useHistory} from 'react-router-dom';
import {queryCache} from 'react-query';
import api from '../../../api';
import {
  useIsMounted,
  useModalControls,
  usePrevious,
  useStatus,
} from '../../../utils/hooks';
import {useConfirmLeaveModal} from '../../../context/openModals';
import {buildReservationPayload} from '../../../utils/reservations';
import {useComputedDetails} from '../../../context/computedDetails';
import {DEFAULT_OCCUPIED_ROOMS_NUMBER} from '../../../utils/constants';
import {FORM_NAMES, FormTypes} from '../ReservationInfoSection/ReservationInfoSection';
import floppyDiskIcon from '../../../assets/floppy-disk.svg';
import addBookingIcon from '../../../assets/add-booking-icon.svg';
import missingDataIcon from '../../../assets/icon-data-missing.svg';
import ReservationOnlineCheckInSection from '../ReservationOnlineCheckInSection';
import YouHaveMadeChangesModal from '../YouHaveMadeChangesModal';
import Button from '../Button';
import BackButton from '../BackButton';
import ReservationInfoSection from '../ReservationInfoSection';
import ModalButton from '../ModalButton';
import Modal from '../Modal';
import {ModalTwoButtonsWrapper, Heading} from '../../../styled/common';
import {
  BottomButtonWrapper,
  ButtonLabelIcon,
  ButtonLabelText,
  ButtonLabelWrapper,
  Content,
  Wrapper,
  ModalButtonWrapper,
  Title,
} from './styled';

type OnlineCheckInSectionRefType = {
  active: boolean;
  data: {
    [key: string]: boolean;
  };
};

function AddReservationSections() {
  const {t} = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const {isNeedToAskForSubscription} = useComputedDetails();
  const formMethods = useForm<FormTypes>({
    defaultValues: {
      [FORM_NAMES.occupied_rooms]: String(DEFAULT_OCCUPIED_ROOMS_NUMBER),
    },
  });
  const formState = formMethods.formState;
  const prevFormState = usePrevious<typeof formState>(formState);
  const {
    openModal: openDataIncompleteModal,
    closeModal: closeDataIncompleteModal,
    isOpen: isDataIncompleteModalOpen,
  } = useModalControls();
  const {
    openModal: openLoadingModal,
    closeModal: closeLoadingModal,
    isOpen: isLoadingModalOpen,
  } = useModalControls();
  const {isLoading, setStatus, isSuccess} = useStatus();
  const [loadingDetails, setLoadingDetails] = React.useState('');
  const [createdReservationId, setCreatedReservationId] = React.useState('');
  const [wasDataIncompleteModalOpened, setWasDataIncompleteModalOpened] = React.useState(
    false,
  );
  const [isInfoSectionTouched, setIsInfoSectionTouched] = React.useState(false);
  const [
    isOnlineCheckInSectionTouched,
    setIsOnlineCheckInSectionTouched,
  ] = React.useState(false);

  const isAnySectionTouched = isInfoSectionTouched || isOnlineCheckInSectionTouched;

  const reservationName = formMethods.watch(FORM_NAMES.housing)?.label;
  const reservationLanguage = formMethods.watch(FORM_NAMES.housing)?.data
    .default_email_language;

  const onlineCheckInSectionRef = React.useRef<OnlineCheckInSectionRefType>({
    active: false,
    data: {},
  });

  const openIncompleteModal = React.useCallback(() => {
    if (!wasDataIncompleteModalOpened) {
      openDataIncompleteModal();
      setWasDataIncompleteModalOpened(true);
    }
  }, [openDataIncompleteModal, wasDataIncompleteModalOpened]);

  React.useLayoutEffect(
    function showIncompleteModalOneTime() {
      const shouldOpenModal =
        formState.isSubmitted && !prevFormState?.isValid && !formState.isValid;

      if (shouldOpenModal) {
        openIncompleteModal();
      }
    },
    [
      formState.isSubmitted,
      formState.isValid,
      prevFormState,
      wasDataIncompleteModalOpened,
      openIncompleteModal,
    ],
  );

  React.useEffect(
    function redirect() {
      if (isNeedToAskForSubscription) {
        history.push('/bookings');
      }
    },
    [history, isNeedToAskForSubscription],
  );

  const goToReservations = () => {
    history.push('/bookings');
  };

  const goToCreatedReservation = () => {
    history.push(`/bookings/${createdReservationId}`);
  };

  const handleResponseError = (error?: any) => {
    setLoadingDetails('');
    setStatus('error');
    toastResponseError(error);
    closeLoadingModal();
  };

  const getCheckInOnlineRemindersPayload = () => {
    const isActive = onlineCheckInSectionRef.current?.active;
    const data = onlineCheckInSectionRef.current?.data;

    if (!isActive && data) {
      let inactiveData: {[key: string]: boolean} = {};
      Object.keys(data).forEach((key: string) => {
        inactiveData[key] = false;
      });

      return inactiveData;
    }
    return data;
  };

  const updateHousing = async () => {
    const checkInOnlinePayload = getCheckInOnlineRemindersPayload();
    const housingId = formMethods.watch(FORM_NAMES.housing)!.value;

    const {error} = await api.housings.patchById(housingId, checkInOnlinePayload);

    if (isMounted.current && error) {
      handleResponseError(error);
    }
    return error;
  };

  const createReservation = async (formData: FormTypes) => {
    const payload = buildReservationPayload(formData);
    const {error, data} = await api.reservations.create(payload);

    if (data?.id) {
      setCreatedReservationId(data.id);
      queryCache.setQueryData(data.id, data);
    }

    if (isMounted.current && error) {
      handleResponseError(error);
    }
    return error;
  };

  const {
    goThroughConfirm,
    handleModalSave,
    handleModalDontSave,
    handleModalCancel,
    isDoYouWantToSaveModalOpen,
  } = useConfirmLeaveModal(isAnySectionTouched);

  const onSubmit = async (formData: FormTypes) => {
    setStatus('loading');
    openLoadingModal();

    setLoadingDetails(`${t('updating_property')}...`);
    const housingError = await updateHousing();
    if (!isMounted.current || housingError) {
      return;
    }

    setLoadingDetails(`${t('creating')}...`);
    const reservationError = await createReservation(formData);
    if (!isMounted.current || reservationError) {
      return;
    }

    await queryCache.refetchQueries('reservations');
    setStatus('success');
  };

  return (
    <>
      <Content>
        <FormContext {...formMethods}>
          <Wrapper>
            <Heading>
              <div>
                <BackButton onClick={() => goThroughConfirm('/bookings')} />
              </div>
              <Title>{reservationName || t('new_booking')}</Title>
              <div>
                <Button
                  onClick={formMethods.handleSubmit(onSubmit)}
                  label={
                    <ButtonLabelWrapper>
                      <ButtonLabelIcon src={floppyDiskIcon} alt="Plus" />
                      <ButtonLabelText>{t('save_booking')}</ButtonLabelText>
                    </ButtonLabelWrapper>
                  }
                />
              </div>
            </Heading>
            <ReservationInfoSection
              disabled={isLoading}
              setIsSectionTouched={setIsInfoSectionTouched}
            />
            <ReservationOnlineCheckInSection
              isSectionTouched={isOnlineCheckInSectionTouched}
              setIsSectionTouched={setIsOnlineCheckInSectionTouched}
              disabled={isLoading}
              defaultLanguage={reservationLanguage}
              ref={onlineCheckInSectionRef}
            />
            <BottomButtonWrapper>
              <Button
                onClick={formMethods.handleSubmit(onSubmit)}
                label={
                  <ButtonLabelWrapper>
                    <ButtonLabelIcon src={floppyDiskIcon} alt="Plus" />
                    <ButtonLabelText>{t('save_booking')}</ButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
            </BottomButtonWrapper>
          </Wrapper>
        </FormContext>
      </Content>
      {/* <Prompt when={isBlocking} message={location => `Are you sure you wanna go to ${location.pathname}`} /> */}
      {isDataIncompleteModalOpen && (
        <Modal
          open
          iconProps={{
            height: 84,
            width: 84,
          }}
          iconSrc={missingDataIcon}
          iconAlt="Form with red fields"
          title={t('data_missing')}
          text={
            <>
              {t('you_cant_add_this_booking_until')}
              <p />
              {t('we_have_outlined_fields')}
            </>
          }
        >
          <ModalButtonWrapper>
            <ModalButton label={t('ok')} onClick={closeDataIncompleteModal} />
          </ModalButtonWrapper>
        </Modal>
      )}
      {isLoadingModalOpen && (
        <Modal
          open
          iconSrc={addBookingIcon}
          iconAlt="Guest group"
          iconProps={{
            height: 86,
            width: 89,
          }}
          title={isLoading ? t('creating_booking') : t('booking_created')}
          text={
            isLoading ? (
              <div>{loadingDetails}</div>
            ) : (
              <Trans values={{name: reservationName}} i18nKey="your_booking_name_added">
                Your booking <b>Name</b> has been added successfully!
              </Trans>
            )
          }
        >
          {isSuccess && (
            <ModalTwoButtonsWrapper>
              <ModalButton
                label={t('view_my_booking')}
                onClick={goToCreatedReservation}
              />
              <ModalButton
                secondary
                label={t('go_to_bookings')}
                onClick={goToReservations}
              />
            </ModalTwoButtonsWrapper>
          )}
        </Modal>
      )}
      {isDoYouWantToSaveModalOpen && (
        <YouHaveMadeChangesModal
          handleModalSave={() => handleModalSave(formMethods.handleSubmit(onSubmit))}
          handleModalDontSave={handleModalDontSave}
          handleModalCancel={handleModalCancel}
        />
      )}
    </>
  );
}

export {AddReservationSections};
