import styled from 'styled-components';

export const Layout = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(auto-fit, minmax(47px, 77px));
  grid-auto-columns: min-content;
  max-height: 231px;
  margin-bottom: -30px;

  & > :nth-child(n) {
    padding-bottom: 30px;
    padding-right: 64px;
  }

  & > :nth-child(3n) {
    height: 48px;
    padding-bottom: 0;
  }

  & > :nth-child(n + 4) {
    padding-left: 64px;
  }

  & > :first-child:nth-last-child(n + 4) {
    & ~ :nth-child(2),
    & ~ :nth-child(3) {
      border-right: 1px solid rgba(22, 22, 67, 0.12);
    }
    border-right: 1px solid rgba(22, 22, 67, 0.12);
  }

  & > :first-child:nth-last-child(n + 7) {
    & ~ :nth-child(4),
    & ~ :nth-child(5),
    & ~ :nth-child(6) {
      border-right: 1px solid rgba(22, 22, 67, 0.12);
    }
  }
`;
