import styled from 'styled-components';

export const PlanInfoPrice = styled.div`
  padding-left: 63px;
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 16px;
  cursor: default;

  & > b {
    font-family: ProximaNova-Bold, sans-serif;
    font-weight: normal;
  }
`;

export const DueDateSection = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgb(246 247 250);

  & > b {
    font-family: ProximaNova-Bold, sans-serif;
    font-weight: normal;
  }
`;

export const PaymentDetailsSection = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgb(246 247 250);

  & b {
    font-family: ProximaNova-Semibold, sans-serif;
    font-weight: normal;
  }

  & span {
    font-family: ProximaNova-Regular, sans-serif;
  }
`;

export const LoaderWrapper = styled.div`
  margin-top: 35%;
  text-align: center;
`;

export const PriceDetail = styled.div`
  margin-top: 2px;
`;
