import styled, {css} from 'styled-components';

type StyledButtonProps = {
  secondary?: boolean;
  disabled?: boolean;
};

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: 2px;
  min-width: 148px;
  min-height: 40px;
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 16px;
  color: #161643;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #1a8cff;
  transition: all 0.02s ease-in-out;
  padding: 10px 30px;
  outline: none;
  user-select: none;
  opacity: ${(props) => props.disabled && 0.1};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: #1a8cff;
        border-color: #1a8cff;
        color: #ffffff;
      }

      &:active {
        opacity: 0.95;
      }
    `};

  ${(props) =>
    props.secondary &&
    css`
      border-color: transparent;
      background-color: transparent;
      color: #9696b9;

      ${!props.disabled &&
      css`
        &:hover {
          border-color: transparent;
          background-color: transparent;
          color: #1a8cff;
        }

        &:active {
          opacity: 0.9;
        }
      `};
    `};
`;
