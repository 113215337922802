import {resolver} from './index';

const ENDPOINTS = {
  all: (params = '') => `reservations/?${params}`,
  one: (id = '', params = '') => `reservations/${id}/?${params}`,
  sendSync: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/users/${userId}/sync-user-reservations/`,
  syncTasks: (integrationUrl = '', userId = '') =>
    `${integrationUrl}/reservations-syncronize-tasks/?user_id=${userId}`,
  email: () => `reservation-emails/`,
  emailStatistics: (id: string) => `reservation-emails/statistics/${id}/`,
  emailLanguage: (id: string) => `reservation-emails/language/${id}/`,
  sendToRegistration: (id = '', target = '') =>
    `reservations/${id}/resend-reservation-to-${target}/`,
  forceSendToRegistration: (id = '', target = '') =>
    `reservations/${id}/hard-restart-${target}-registration/`,
  sendGuestToRegistration: (id = '', guest_id = '') =>
    `reservations/${id}/restart-police-registration/?guest=${guest_id}`,
  light: (params = '') => `light/reservations/?${params}`,
  oneLight: (id = '', params = '') => `light/reservations/${id}/?${params}`,
};

function get(params = '') {
  return resolver(ENDPOINTS.all(params), {
    mode: 'no-cors',
  });
}

function getOne(id: string, params = '') {
  return resolver(ENDPOINTS.one(id, params));
}

function deleteOne(id: string) {
  return resolver(ENDPOINTS.one(id), {
    method: 'DELETE',
  });
}

function create(payload: any) {
  return resolver(ENDPOINTS.all(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function sendSyncTask(integrationUrl = '', userId = '') {
  return resolver(ENDPOINTS.sendSync(integrationUrl, userId), {
    method: 'POST',
    body: JSON.stringify({}),
  });
}

function getSyncTasks(integrationUrl = '', userId = '') {
  return resolver(ENDPOINTS.syncTasks(integrationUrl, userId));
}

function sendReservationEmail(payload: any) {
  return resolver(ENDPOINTS.email(), {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function patch(id: string, payload: any) {
  return resolver(ENDPOINTS.one(id), {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

function put(id: string, payload: any) {
  return resolver(ENDPOINTS.one(id), {
    method: 'PUT',
    body: JSON.stringify(payload),
  });
}

function sendToRegistration(id: string, target: string) {
  return resolver(ENDPOINTS.sendToRegistration(id, target), {
    method: 'POST',
  });
}

function forceSendToRegistration(id: string, target: string) {
  return resolver(ENDPOINTS.forceSendToRegistration(id, target), {
    method: 'POST',
  });
}

function sendGuestToRegistration(id: string, guest_id: string) {
  return resolver(ENDPOINTS.sendGuestToRegistration(id, guest_id), {
    method: 'POST',
  });
}

function getEmailStatistics(id: string) {
  return resolver(ENDPOINTS.emailStatistics(id));
}

function getLight(params: string) {
  return resolver(ENDPOINTS.light(params), {
    mode: 'no-cors',
  });
}

function getOneLight(id: string, params = '') {
  return resolver(ENDPOINTS.one(id, params));
}

function putLight(id: string, payload: any) {
  return resolver(ENDPOINTS.oneLight(id), {
    method: 'PUT',
    body: JSON.stringify(payload),
  });
}

export {
  ENDPOINTS,
  getOneLight,
  get,
  getOne,
  deleteOne,
  create,
  patch,
  put,
  putLight,
  sendReservationEmail,
  sendSyncTask,
  getSyncTasks,
  sendToRegistration,
  sendGuestToRegistration,
  getEmailStatistics,
  getLight,
  forceSendToRegistration,
};
