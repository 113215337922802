import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import HousingsTable from '../components/dashboard/HousingsTable';

function Housings() {
  return (
    <>
      <TrialHeader />
      <Header />
      <HousingsTable />
    </>
  );
}

export {Housings};
