import {resolver} from './index';

const ENDPOINTS = {
  me: () => `users/me/`,
  collaborators: (params = '') => `users/me/collaborators/?${params}`,
  lockUsers: (params = '') => `lock-users/?${params}`,
};

function getMe() {
  return resolver(ENDPOINTS.me());
}

function getLockUsers() {
  return resolver(ENDPOINTS.lockUsers());
}

function patchMe(payload = {}) {
  return resolver(ENDPOINTS.me(), {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

function deleteMe() {
  return resolver(ENDPOINTS.me(), {
    method: 'DELETE',
  });
}

export {ENDPOINTS, patchMe, getMe, deleteMe, getLockUsers};
