import React from 'react';
import {queryCache, useQuery} from 'react-query';
import {Trans, useTranslation} from 'react-i18next';
import api, {queryFetcher} from '../../../api';
import {
  useErrorModal,
  useErrorToast,
  useIsMounted,
  useStatus,
} from '../../../utils/hooks';
import {
  EmailStatistics,
  Guest,
  GuestGroup,
  Housing,
  LightReservation,
} from '../../../utils/types';
import {USER_ORIGINS} from '../../../utils/constants';
import {getGuestLeader} from '../../../utils/guestGroup';
import AccessProgressBar, {Progress, STATUSES} from '../AccessProgressBar';
import Section from '../Section';
import {
  ApprovalOverlay,
  ApproveButton,
  FailedGuestsLayout,
  GuestDescription,
  GuestList,
  GuestName,
  GuestPhoto,
  GuestPhotoLabel,
  GuestPhotosApproval,
  GuestPhotosWrapper,
  OverlayButton,
  OverlayText,
  SingleGuestPhotoWrapper,
} from './styled';

function getBiomatchGuestLeader(guestGroup: GuestGroup) {
  const guestLeader = getGuestLeader(guestGroup);
  const isLeaderHasPhotos = guestLeader?.biomatch_selfie && guestLeader?.biomatch_doc;

  if (guestLeader && isLeaderHasPhotos) {
    return guestLeader;
  }
  return null;
}

function getBiomatchGuests(guestGroup: GuestGroup, isBiomatchForAllEnabled = false) {
  if (!guestGroup?.members?.length) {
    return [];
  }

  if (isBiomatchForAllEnabled) {
    return guestGroup.members.filter((guest) => {
      return guest?.biomatch_doc && guest?.biomatch_selfie;
    });
  }

  const biomatchGuestLeader = getBiomatchGuestLeader(guestGroup);
  if (biomatchGuestLeader) {
    return [biomatchGuestLeader];
  }

  return [];
}

function fetchEmailStatistics(key: string, id: string) {
  return queryFetcher(api.reservations.ENDPOINTS.emailStatistics(id));
}

type ReservationAccessSectionProps = {
  housing?: Housing;
  guestGroup?: GuestGroup;
  reservation?: LightReservation;
};

function ReservationAccessSection({
  housing,
  guestGroup,
  reservation,
}: ReservationAccessSectionProps) {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {isLoading, setStatus} = useStatus();
  const {ErrorModal, displayError} = useErrorModal();

  const [progress, setProgress] = React.useState<Progress>({
    sendCheckIn: STATUSES.idle,
    guestVerification: STATUSES.idle,
    sendKey: STATUSES.idle,
  });
  const [biomatchGuests, setBiomatchGuests] = React.useState<Guest[]>([]);
  const [activeGuest, setActiveGuest] = React.useState<Guest | null>(null);
  const [isApprovalOverlayVisible, setIsApprovalOverlayVisible] = React.useState(false);

  const {data: emailStatistics, error: emailStatisticsError} = useQuery<
    EmailStatistics,
    [string, string]
  >(
    Boolean(reservation?.id) && ['emailStatistics', reservation!.id],
    fetchEmailStatistics,
  );
  useErrorToast(emailStatisticsError);

  const isBiometricMatchForAllEnabled = housing?.is_biometric_match_for_all_enabled;
  React.useEffect(
    function setGuestsProgressAndPreloadFailedGuests() {
      if (!guestGroup) {
        return;
      }

      const nextBiomatchGuests = getBiomatchGuests(
        guestGroup,
        isBiometricMatchForAllEnabled,
      );

      if (!guestGroup.members?.length) {
        setBiomatchGuests([]);
        setIsApprovalOverlayVisible(false);
        setProgress((prevState) => {
          return {
            ...prevState,
            guestVerification: STATUSES.idle,
            sendKey: STATUSES.idle,
          };
        });
        return;
      }

      if (isBiometricMatchForAllEnabled) {
        setProgress((prevState) => {
          const isEveryGuestPassed = guestGroup.members.every((guest) => {
            return guest.biomatch_passed;
          });

          if (isEveryGuestPassed) {
            return {
              ...prevState,
              guestVerification: STATUSES.complete,
              sendKey: STATUSES.complete,
            };
          }

          if (nextBiomatchGuests.length) {
            return {
              ...prevState,
              guestVerification: STATUSES.error,
              sendKey: STATUSES.idle,
            };
          }

          return {
            ...prevState,
            guestVerification: STATUSES.idle,
            sendKey: STATUSES.idle,
          };
        });

        setBiomatchGuests(nextBiomatchGuests);
        return;
      }

      const guestLeader = getGuestLeader(guestGroup);
      setProgress((prevState) => {
        if (guestLeader?.biomatch_passed) {
          return {
            ...prevState,
            guestVerification: STATUSES.complete,
            sendKey: STATUSES.complete,
          };
        }

        if (nextBiomatchGuests.length) {
          return {
            ...prevState,
            guestVerification: STATUSES.error,
            sendKey: STATUSES.idle,
          };
        }

        return {
          ...prevState,
          guestVerification: STATUSES.idle,
          sendKey: STATUSES.idle,
        };
      });

      setBiomatchGuests(nextBiomatchGuests);
    },
    [guestGroup, isBiometricMatchForAllEnabled],
  );

  React.useEffect(
    function setEmailProgress() {
      if (emailStatistics?.error) {
        setProgress((prevProgress) => {
          return {
            ...prevProgress,
            sendCheckIn: STATUSES.error,
          };
        });
        return;
      }

      if (emailStatistics?.success) {
        setProgress((prevProgress) => {
          return {
            ...prevProgress,
            sendCheckIn: STATUSES.complete,
          };
        });
        return;
      }

      const hasAnyCheckinGuest = guestGroup?.members?.some((guest) => {
        return guest.origin === USER_ORIGINS.checkinOnline;
      });
      if (hasAnyCheckinGuest) {
        setProgress((prevProgress) => {
          return {
            ...prevProgress,
            sendCheckIn: STATUSES.complete,
          };
        });
        return;
      }

      setProgress({
        sendCheckIn: STATUSES.idle,
        sendKey: STATUSES.idle,
        guestVerification: STATUSES.idle,
      });
    },
    [emailStatistics, guestGroup],
  );

  React.useEffect(
    function keepCorrectActiveGuest() {
      const isGuestExist = guestGroup?.members?.find((guest) => {
        return guest?.id === activeGuest?.id;
      });

      if (!isGuestExist && !isApprovalOverlayVisible) {
        setActiveGuest(null);
      }
    },
    [activeGuest, guestGroup, isApprovalOverlayVisible],
  );

  const handleGuestNameClick = (guest: Guest) => {
    if (guest?.id === activeGuest?.id) {
      setActiveGuest(null);
      return;
    }
    setActiveGuest(guest);
  };

  const getApproveGuestBiomatchPayload = () => {
    return {
      biomatch_passed: true,
      reservation_id: reservation?.id,
    };
  };

  const approveGuestBiomatch = async () => {
    setStatus('loading');
    const {error} = await api.guests.patchById(
      activeGuest!.id,
      getApproveGuestBiomatchPayload(),
    );

    if (!isMounted.current) {
      return;
    }

    if (error) {
      setStatus('idle');
      displayError(error);
      return;
    }

    await queryCache.refetchQueries(['guestGroup', reservation?.guest_group_id]);
    setStatus('idle');
    setIsApprovalOverlayVisible(true);
  };

  const removeApprovedGuestAndHideOverlay = async () => {
    setIsApprovalOverlayVisible(false);
    setActiveGuest(null);
  };

  return (
    <Section title={t('access')}>
      <AccessProgressBar progress={progress} />
      {(activeGuest || Boolean(biomatchGuests?.length)) && (
        <FailedGuestsLayout>
          <div>
            {biomatchGuests.map((guest) => {
              return (
                <GuestList key={guest?.id}>
                  <GuestName
                    type="button"
                    active={guest.id === activeGuest?.id}
                    onClick={() => handleGuestNameClick(guest)}
                    disabled={isApprovalOverlayVisible || isLoading}
                  >
                    {guest.full_name}
                  </GuestName>
                </GuestList>
              );
            })}
          </div>
          {activeGuest && (
            <>
              <GuestPhotosApproval>
                {isApprovalOverlayVisible && (
                  <ApprovalOverlay>
                    <OverlayText>
                      {t('guest_verification_approved_manually')}
                      <OverlayButton
                        secondary
                        label={t('ok')}
                        onClick={removeApprovedGuestAndHideOverlay}
                      />
                    </OverlayText>
                  </ApprovalOverlay>
                )}
                <GuestPhotosWrapper>
                  <SingleGuestPhotoWrapper>
                    <GuestPhoto image={activeGuest.biomatch_doc} />
                    <GuestPhotoLabel>{t('photo_from_id_passport')}</GuestPhotoLabel>
                  </SingleGuestPhotoWrapper>
                  <SingleGuestPhotoWrapper>
                    <GuestPhoto image={activeGuest.biomatch_selfie} />
                    <GuestPhotoLabel>{t('photo_from_selfie')}</GuestPhotoLabel>
                  </SingleGuestPhotoWrapper>
                </GuestPhotosWrapper>
                {!isApprovalOverlayVisible && !activeGuest.biomatch_passed && (
                  <ApproveButton
                    secondary
                    type="button"
                    blinking={isLoading}
                    label={isLoading ? `${t('loading')}...` : t('yes_i_approve')}
                    onClick={approveGuestBiomatch}
                  />
                )}
              </GuestPhotosApproval>
              {!activeGuest.biomatch_passed && (
                <GuestDescription>
                  <Trans
                    i18nKey="biomatch_of_name_failed"
                    values={{name: activeGuest?.full_name}}
                  >
                    The biometric match of your guest <b>Name</b> has failed. To resolve
                    this, you can review both photos and approve them. After approving,
                    the virtual key will be sent to the guest.
                  </Trans>
                </GuestDescription>
              )}
            </>
          )}
        </FailedGuestsLayout>
      )}
      <ErrorModal />
    </Section>
  );
}

export {ReservationAccessSection};
