import styled from 'styled-components';

export const GridHeader = styled.div`
  color: #1a8cff;
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 12px;
  border-bottom: 1px solid rgb(22 22 67 / 12%);
  padding-bottom: 10px;
  margin-top: 28px;
`;

export const GridRow = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid rgb(22 22 67 / 12%);
  font-family: ProximaNova-Semibold, sans-serif;
  color: #161643;
  text-align: left;
  display: grid;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  grid-template-columns: 1fr 1fr 0.7fr;

  & > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > :nth-child(1) {
    font-size: 14px;
  }

  & > :nth-child(2) {
    color: #9696b9;
    font-size: 12px;
  }

  & > :nth-child(3) {
    font-size: 20px;
    text-align: right;
  }
`;

export const SpannedRow = styled.div`
  grid-column: 1 / 3;
`;

export const WriteOffRow = styled.div`
  &&& {
    color: #1a8cff;
    text-align: right;
    font-size: 20px;
  }
`;

export const Wrapper = styled.div`
  width: 472px;
`;
