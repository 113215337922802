import React from 'react';
import {useTranslation} from 'react-i18next';
import api from '../../../api';
import {toastResponseError} from '../../../utils/common';
import checkIcon from '../../../assets/check-filled.svg';
import {
  Wrapper,
  HeaderWrapper,
  Name,
  ComingSoonText,
  ConnectedWrapper,
  ConnectedImg,
  ConnectedText,
  DescriptionText,
  MarketplaceButton,
  InfoWrapper,
  MarketplaceLogo,
  MarketplaceType,
  MarketplaceLogoWrapper,
} from './styled';
import {MARKETPLACE_STATUSES, MARKETPLACE_TYPES} from '../../../utils/constants';
import {useHistory} from 'react-router-dom';

type MarketplaceItemProps = {
  type: string;
  logoSrc?: string;
  descriptionText?: string;
  status: string;
  name: string;
  id?: string;
};

const defaultProps = {
  type: MARKETPLACE_TYPES.access_provider,
  logoSrc: null,
  descriptionText: '',
  status: MARKETPLACE_STATUSES.unconnected,
  name: 'unnamed',
  id: null,
};

function MarketplaceItem({
  type,
  logoSrc,
  descriptionText,
  status,
  name,
  id,
}: MarketplaceItemProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState(status);

  React.useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  const deleteMarkeplaceConnection = async () => {
    if (id) {
      setIsDeleting(true);
      const {error} = await api.locks.deleteLockUser(id);
      if (!error) {
        setCurrentStatus(MARKETPLACE_STATUSES.unconnected);
      } else {
        toastResponseError(error);
      }
      setIsDeleting(false);
    }
  };

  const goConnectPage = () => {
    history.push(`/marketplace/access-providers/${name}`);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Name status={currentStatus}>{name}</Name>
        {currentStatus === MARKETPLACE_STATUSES.coming && (
          <ComingSoonText>{t('coming_soon')}</ComingSoonText>
        )}
        {currentStatus === MARKETPLACE_STATUSES.connected && (
          <ConnectedWrapper>
            <ConnectedImg src={checkIcon} />
            <ConnectedText>{t('connected')}</ConnectedText>
          </ConnectedWrapper>
        )}
      </HeaderWrapper>
      <InfoWrapper status={currentStatus}>
        <MarketplaceLogoWrapper>
          <MarketplaceLogo src={logoSrc} />
        </MarketplaceLogoWrapper>
        <DescriptionText>{descriptionText}</DescriptionText>
        {currentStatus === MARKETPLACE_STATUSES.unconnected && (
          <MarketplaceButton secondary onClick={goConnectPage} label={t('connect')} />
        )}
        {currentStatus === MARKETPLACE_STATUSES.connected &&
          type === MARKETPLACE_TYPES.access_provider && (
            <MarketplaceButton
              secondary
              disabled={isDeleting}
              onClick={deleteMarkeplaceConnection}
              label={t('disconnect')}
            />
          )}
      </InfoWrapper>
      <MarketplaceType>{type}</MarketplaceType>
    </Wrapper>
  );
}

MarketplaceItem.defaultProps = defaultProps;
export {MarketplaceItem};
