import React from 'react';
import Modal from '../Modal';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import api, {queryFetcher} from '../../../api';
import {
  Exemption,
  Housing,
  Season,
  SeasonRule,
  SelectOption,
  ShortHousing,
} from '../../../utils/types';
import {useErrorToast} from '../../../utils/hooks';
import {getShortHousingsAsOptions} from '../../../utils/housing';
import {useTaxesExport} from '../../../context/taxesExport';
import Loader from '../../common/Loader';
import ModalButton from '../ModalButton';
import {ModalTwoButtonsWrapper} from '../../../styled/common';
import {
  Checkbox,
  Container,
  SelectAllWrapper,
  CheckboxesWrapper,
  LoaderWrapper,
} from './styled';

function fetchShortHousings() {
  return queryFetcher(api.housings.ENDPOINTS.all(`ordering=name&fields=id,name`));
}

function setChecked(housings: SelectOption[], state: boolean) {
  if (!housings) {
    return {};
  }

  const checkboxes: {[key: string]: boolean} = {};
  housings.forEach((housing) => {
    checkboxes[housing.value] = state;
  });

  return checkboxes;
}

function getIsAllCheckboxesChecked(checkboxes: {[key: string]: boolean}) {
  const values = Object.values(checkboxes);
  if (!values.length) {
    return false;
  }

  return values.every((checked) => {
    return checked;
  });
}

function getIsAnyCheckboxChecked(checkboxes: {[key: string]: boolean}) {
  const values = Object.values(checkboxes);
  if (!values.length) {
    return false;
  }

  return values.some((checked) => {
    return checked;
  });
}

type ExportTaxesSettingsModalProps = {
  onClose: () => void;
  getHighSeasonPayload: () => any;
  getLowSeasonPayload: () => any;
  open?: boolean;
  housing?: Housing;
};

const defaultProps: Partial<ExportTaxesSettingsModalProps> = {
  open: false,
  housing: undefined,
};

function ExportTaxesSettingsModal({
  open,
  onClose,
  getHighSeasonPayload,
  getLowSeasonPayload,
  housing,
}: ExportTaxesSettingsModalProps) {
  const {t} = useTranslation();
  const {startTaxesExport} = useTaxesExport();
  const {
    data: shortHousings,
    error: shortHousingsError,
    status: shortHousingsStatus,
  } = useQuery<ShortHousing[], string>('shortHousings', fetchShortHousings, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  useErrorToast(shortHousingsError, {
    notFoundMessage:
      'Requested short shortHousings could not be found. Please contact support.',
  });

  const housingId = housing?.id;
  const shortHousingsOptions = React.useMemo(() => {
    return getShortHousingsAsOptions(shortHousings).filter((option) => {
      return option.value !== housingId;
    });
  }, [housingId, shortHousings]);

  const [isAllChecked, setIsAllChecked] = React.useState(false);
  const [checkboxes, setCheckboxes] = React.useState(() => {
    return setChecked(shortHousingsOptions, false);
  });

  React.useEffect(() => {
    if (shortHousingsOptions) {
      const unchecked = setChecked(shortHousingsOptions, false);
      setCheckboxes(unchecked);
    }
  }, [shortHousingsOptions]);

  const toggleSelectAll = () => {
    setIsAllChecked((prevState) => {
      const nextState = !prevState;
      const allChecked = setChecked(shortHousingsOptions, nextState);

      setCheckboxes(allChecked);
      return nextState;
    });
  };

  const toggleIsChecked = (id: string) => {
    if (isAllChecked) {
      setIsAllChecked(false);
    }

    setCheckboxes((prevState) => {
      return {
        ...prevState,
        [id]: !prevState[id],
      };
    });
  };

  const removeIdsFromRules = (rules: SeasonRule[]) => {
    return rules.map((rule) => {
      return {
        ...rule,
        id: undefined,
        season_id: undefined,
      };
    });
  };

  const removeIdsFromExemptions = (exemptions: Exemption[]) => {
    return exemptions.map((exemption) => {
      return {
        ...exemption,
        id: undefined,
        season_id: undefined,
      };
    });
  };

  const removeIdsFromSeason = (season: Season) => {
    return {
      ...season,
      id: undefined,
      season_links: undefined,
      housings: undefined,
      rules: removeIdsFromRules(season?.rules),
      exemptions: removeIdsFromExemptions(season?.exemptions),
    };
  };

  const getPayload = () => {
    const highSeason = getHighSeasonPayload();
    const lowSeason = getLowSeasonPayload();
    const highSeasonWithoutIds = removeIdsFromSeason(highSeason);
    const lowSeasonWithoutIds = lowSeason ? removeIdsFromSeason(lowSeason) : null;

    return {
      highSeason: highSeasonWithoutIds,
      lowSeason: lowSeasonWithoutIds,
    };
  };

  const applyContractDetailsToHousings = async () => {
    const payload = getPayload();
    const housingIds = Object.keys(checkboxes).filter((key) => {
      return checkboxes[key] === true;
    });

    startTaxesExport(housingIds, payload);
    onClose();
  };

  return (
    <div>
      <Modal
        closeOnDocumentClick
        closeOnEscape
        open={open}
        title={t('export_settings')}
        onClose={onClose}
      >
        <Container>
          <SelectAllWrapper>
            <Checkbox
              onChange={toggleSelectAll}
              checked={getIsAllCheckboxesChecked(checkboxes)}
              label={t('select_all')}
              disabled={shortHousingsStatus === 'loading'}
            />
          </SelectAllWrapper>
          <CheckboxesWrapper>
            {shortHousingsStatus === 'loading' ? (
              <LoaderWrapper>
                <Loader height={35} width={35} label={t('loading')} />
              </LoaderWrapper>
            ) : (
              shortHousingsOptions.map((option) => {
                return (
                  <Checkbox
                    checked={Boolean(checkboxes[option.value])}
                    label={option.label}
                    key={option.value}
                    onChange={() => toggleIsChecked(option?.value)}
                  />
                );
              })
            )}
          </CheckboxesWrapper>
          <ModalTwoButtonsWrapper>
            <ModalButton
              onClick={applyContractDetailsToHousings}
              disabled={!getIsAnyCheckboxChecked(checkboxes)}
              label={t('export_settings')}
            />
            <div>
              <ModalButton secondary label={t('cancel')} onClick={onClose} />
            </div>
          </ModalTwoButtonsWrapper>
        </Container>
      </Modal>
    </div>
  );
}

ExportTaxesSettingsModal.defaultProps = defaultProps;
export {ExportTaxesSettingsModal};
