import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import AccountSections from '../components/dashboard/AccountSections';

function Account() {
  return (
    <>
      <TrialHeader />
      <Header />
      <AccountSections />
    </>
  );
}

export {Account};
