import styled from 'styled-components';
import Button from '../Button';

export const Wrapper = styled.div`
  max-width: 1040px;
  padding: 0 20px;
  margin: 20px auto 76px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 42px;
`;

export const Content = styled.div`
  display: flex;

  & > div:first-child {
    padding-right: 10px;
    border-right: 1px solid #e5ecf5;
    padding-left: 109px;
    box-sizing: border-box;
  }
`;

export const ContentItem = styled.div`
  width: 50%;
`;

export const ProviderLogo = styled.img`
  width: 213px;
  object-fit: contain;
  margin-bottom: 36px;
`;

export const ProviderLogoWrapper = styled.div`
  height: 71px;
  margin-bottom: 36px;
`;

export const ProviderDescription = styled.div`
  width: 300px;
  font-family: ProximaNova-Medium, sans-serif;
  margin-bottom: 20px;
  color: #161643;
`;

export const ProviderDescriptionLink = styled.a`
  color: #0081ec;
  cursor: pointer;
`;

export const AccessProviderForm = styled.form`
  width: 244px;
  box-shadow: 0 5px 5px #2699fb1a;
  border-radius: 6px;
  margin: 0 auto;
  text-align: center;

  padding: 29px 44px 49px;
`;

export const FormHeader = styled.div`
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 21px;
  margin-bottom: 50px;
`;

export const SingleFieldWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const SubmitButton = styled(Button)`
  width: 90%;
  display: flex;
  justify-content: center;
`;
