import React from 'react';
import {Filter, RemoveIcon} from './styled';
import removeIcon from '../../../assets/remove.svg';

type TableFilterProps = {
  onRemove: () => void;
  children: string | JSX.Element | React.ReactNode;
  className?: string;
};

function TableFilter({onRemove, children, className}: TableFilterProps) {
  return (
    <Filter className={className}>
      {children}
      <RemoveIcon onClick={onRemove} src={removeIcon} alt="Cross" />
    </Filter>
  );
}

export {TableFilter};
