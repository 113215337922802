import React from 'react';
import {format, isValid} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {Column, Row, useTable} from 'react-table';
import {useInfiniteScroll} from 'react-infinite-scroll-hook';
import {queryCache, useInfiniteQuery, useQuery} from 'react-query';
import {
  useCorrectOptionSelection,
  useErrorModal,
  useErrorToast,
  useStatus,
  useIsMounted,
} from '../../../utils/hooks';
import {PoliceReceipt, SelectOption, ShortHousing} from '../../../utils/types';
import api, {queryFetcher} from '../../../api';
import notFoundIcon from '../../../assets/notfound-icon.svg';
import directDownloadIcon from '../../../assets/direct-download.svg';
import HeadingSelect from '../HeadingSelect';
import Loader from '../../common/Loader';
import TablePlaceholder, {EMPTY_TABLE_ROWS_NUMBER} from '../TablePlaceholder';
import TableLoader from '../TableLoader';
import {
  DocumentButton,
  TableHeader,
  TablePlaceholderWrapper,
} from '../../../styled/common';
import {
  Content,
  Heading,
  HeadingSection,
  DownloadAllButton,
  TableWrapper,
} from './styled';

const COLUMNS_IDS = {
  registrationDate: 'REGISTRATION_DATE',
  documents: 'DOCUMENTS',
};
const MOSSOS_POLICE = 'MOS';
const STORAGE_POLICE_RECEIPTS_MOSSOS_HOUSING_FILTER = 'policeReceiptsMossosHousingFilter';
const STORAGE_POLICE_RECEIPTS_MOSSOS_AVAILABLE_YEAR_FILTER =
  'policeReceiptsMossosAvailableYearFilter';
const HOUSING_PLACEHOLDER = {
  value: '',
  label: '...',
};
const DEFAULT_AVAILABLE_YEAR_OPTION = {
  value: String(new Date().getFullYear()),
  label: String(new Date().getFullYear()),
};

function fetchHousings() {
  return queryFetcher(
    api.housings.ENDPOINTS.all(`fields=id,name,country&police_type=MOS`),
  );
}

function fetchPoliceReceiptsYears(key: string, policeAccountId: string) {
  return queryFetcher(api.policeReceipts.ENDPOINTS.availableYears(policeAccountId));
}

function fetchMosPoliceReceipts(key: string, params = '') {
  return queryFetcher(api.policeReceipts.ENDPOINTS.all(params));
}

function getParams(page = 1, policeAccountId = '', year = '', type = '') {
  return `ordering=-protocol_date&page=${page}&police_account=${policeAccountId}&year=${year}&police_type=${type}`;
}

function getHousingsAsOptions(housings: ShortHousing[]) {
  if (!housings) {
    return [];
  }

  return housings.map((h) => {
    return {
      value: h?.police_account_id || h?.id,
      label: h.name,
    };
  });
}

function getAvailableYearsAsOptions(years?: number[]) {
  if (!years || !years.length) {
    return [];
  }
  return years.map((y) => {
    return {
      value: String(y),
      label: String(y),
    };
  });
}

function preloadHousingFilter() {
  const prevFilter = sessionStorage.getItem(
    STORAGE_POLICE_RECEIPTS_MOSSOS_HOUSING_FILTER,
  );

  if (prevFilter) {
    return JSON.parse(prevFilter);
  }
  return HOUSING_PLACEHOLDER;
}

function preloadAvailableYearFilter() {
  const prevFilter = sessionStorage.getItem(
    STORAGE_POLICE_RECEIPTS_MOSSOS_AVAILABLE_YEAR_FILTER,
  );

  if (prevFilter) {
    return JSON.parse(prevFilter);
  }
  return DEFAULT_AVAILABLE_YEAR_OPTION;
}

function PoliceReceiptsMossosTable() {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {ErrorModal, displayError} = useErrorModal();
  const [page, setPage] = React.useState(1);
  const [housingFilter, setHousingFilter] = React.useState<SelectOption>(
    preloadHousingFilter,
  );
  const [availableYearFilter, setAvailableYearFilter] = React.useState<SelectOption>(
    preloadAvailableYearFilter,
  );

  const {data: housings, error: housingsError, status: housingsStatus} = useQuery(
    'shortMossosHousings',
    fetchHousings,
  );
  const {
    data: availableYears,
    error: availableYearsError,
    status: availableYearsStatus,
  } = useQuery(
    Boolean(housingFilter?.value) && [
      'availableMossosHousingsYears',
      housingFilter.value,
    ],
    fetchPoliceReceiptsYears,
  );
  useErrorToast(housingsError, {
    notFoundMessage:
      //todo: Move to errors translation
      'Requested housings could not be found. Please contact support.',
  });
  useErrorToast(availableYearsError, {
    //todo: Move to errors translation
    notFoundMessage: 'Available years could not be found. Please contact support.',
  });
  const availableYearsOptions = React.useMemo(() => {
    return getAvailableYearsAsOptions(availableYears);
  }, [availableYears]);
  const mossosHousingsOptions = React.useMemo(() => {
    return getHousingsAsOptions(housings);
  }, [housings]);

  const {
    setStatus: setDownloadAllStatus,
    isLoading: isGeneratingDownloadLink,
  } = useStatus();

  const restartQuery = React.useCallback(() => {
    setPage(1);
    queryCache.removeQueries('mosPoliceReceipts');
    queryCache.refetchQueries('mosPoliceReceipts');
  }, []);

  const handleHousingSelect = React.useCallback(
    async (option: SelectOption) => {
      setHousingFilter(option);

      sessionStorage.setItem(
        STORAGE_POLICE_RECEIPTS_MOSSOS_HOUSING_FILTER,
        JSON.stringify(option),
      );
      restartQuery();
    },
    [restartQuery],
  );

  const handleAvailableYearSelect = React.useCallback(
    (option: SelectOption) => {
      setAvailableYearFilter(option);
      sessionStorage.setItem(
        STORAGE_POLICE_RECEIPTS_MOSSOS_AVAILABLE_YEAR_FILTER,
        JSON.stringify(option),
      );
      restartQuery();
    },
    [restartQuery],
  );

  const params = getParams(
    page,
    housingFilter?.value,
    availableYearFilter?.value,
    MOSSOS_POLICE,
  );
  const {
    isFetching,
    isFetchingMore,
    data,
    fetchMore,
    canFetchMore,
    error,
    status,
  } = useInfiniteQuery(
    Boolean(housingFilter?.value && housingFilter?.value) && 'mosPoliceReceipts',
    [params],
    fetchMosPoliceReceipts as any,
    {
      getFetchMore: (lastGroup: any = {}) => {
        if (lastGroup.next) {
          return params;
        }
        return false;
      },
    },
  );

  useErrorToast(error, {
    notFoundMessage:
      //todo: Move to errors translation
      'Requested police receipts could not be found. Please contact support.',
  });

  const infiniteRef = useInfiniteScroll<HTMLTableElement>({
    loading: isFetching || isFetchingMore,
    hasNextPage: Boolean(canFetchMore),
    onLoadMore: () => {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMore(params);
    },
  });

  useCorrectOptionSelection({
    array: mossosHousingsOptions,
    option: housingFilter,
    handler: handleHousingSelect,
    defaultOption: HOUSING_PLACEHOLDER,
  });
  useCorrectOptionSelection({
    array: availableYearsOptions,
    option: availableYearFilter,
    handler: handleAvailableYearSelect,
    defaultOption: DEFAULT_AVAILABLE_YEAR_OPTION,
  });

  const tableData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .map((g) => {
        return g?.results || [];
      })
      .flat();
  }, [data]);

  const downloadAllDocuments = async () => {
    setDownloadAllStatus('loading');

    const {data, error} = await api.policeReceipts.getDownloadAllLink(
      housingFilter.value,
      availableYearFilter.value,
    );

    if (!isMounted.current) {
      return;
    }

    if (error) {
      displayError(error);
    }

    if (data?.link) {
      window.open(data.link, '_blank');
    } else {
      displayError({message: 'Download link is missing.'});
    }
    setDownloadAllStatus('idle');
  };

  const downloadOneDocument = React.useCallback(
    async (id: string) => {
      const {data, error} = await api.policeReceipts.getDownloadOneReceiptLink(id);

      if (!isMounted.current) {
        return;
      }

      if (error) {
        displayError(error);
      }

      if (data?.link) {
        window.open(data.link, '_blank');
      } else {
        displayError({message: 'Download link is missing.'});
      }
    },
    [displayError, isMounted],
  );

  const columns = React.useMemo<Column<PoliceReceipt>[]>(() => {
    return [
      {
        id: COLUMNS_IDS.registrationDate,
        Header: <TableHeader>{t('check_in_date')}</TableHeader>,
        Cell: ({row}: {row: Row<PoliceReceipt>}) => {
          const checkInDate = row.original.protocol_date;

          if (!checkInDate || !isValid(new Date(checkInDate))) {
            return null;
          }
          return checkInDate && format(new Date(checkInDate), 'dd MMM yyyy');
        },
      },
      {
        id: COLUMNS_IDS.documents,
        Header: t('documents_text') as string,
        Cell: ({row}: {row: Row<PoliceReceipt>}) => {
          const [isLoading, setIsLoading] = React.useState(false);
          const id = row.original.id;

          const downloadReceipt = async () => {
            setIsLoading(true);
            await downloadOneDocument(id);
            setIsLoading(false);
          };

          return (
            <DocumentButton
              blinkingBackwards={isLoading}
              disabled={isLoading}
              onClick={downloadReceipt}
            >
              {isLoading ? <Loader height={10} width={10} color="#1a8cff" /> : `ZIP`}
            </DocumentButton>
          );
        },
      },
    ];
  }, [t, downloadOneDocument]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data: tableData,
  });
  const isInitiallyLoading = status === 'loading';
  const isLoaderVisible =
    isInitiallyLoading ||
    isFetchingMore ||
    availableYearsStatus === 'loading' ||
    housingsStatus === 'loading';

  return (
    <Content>
      <Heading>
        <HeadingSection>
          <HeadingSelect
            isSearchable
            onChange={handleHousingSelect}
            options={mossosHousingsOptions}
            value={housingFilter}
          />
          <HeadingSelect
            onChange={handleAvailableYearSelect}
            options={availableYearsOptions}
            value={availableYearFilter}
          />
        </HeadingSection>
        {Boolean(tableData?.length) && (
          <DownloadAllButton
            secondary
            onClick={downloadAllDocuments}
            disabled={isGeneratingDownloadLink}
            blinking={isGeneratingDownloadLink}
            label={
              <>
                <img src={directDownloadIcon} alt="Arrow with a line" />
                {isGeneratingDownloadLink ? `${t('generating')}...` : t('download_all')}
              </>
            }
          />
        )}
      </Heading>
      <TableWrapper hasLastRowBorder={tableData?.length < EMPTY_TABLE_ROWS_NUMBER}>
        <table ref={infiniteRef} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoaderVisible && (
          <TableLoader
            hideBorder
            label={data?.length ? t('loading_more') : t('loading')}
          />
        )}
        <TablePlaceholderWrapper>
          <TablePlaceholder
            isInitiallyLoading={isInitiallyLoading}
            isLoaderVisible={isLoaderVisible}
            modalIconSrc={notFoundIcon}
            modalIconAlt="Sad face"
            modalIconProps={{height: 31, width: 31}}
            modalTitle={`${t('not_found').toUpperCase()}...`}
            tableDataLength={tableData?.length}
          />
        </TablePlaceholderWrapper>
      </TableWrapper>
      <ErrorModal />
    </Content>
  );
}

export {PoliceReceiptsMossosTable};
