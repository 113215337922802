import styled from 'styled-components';
import ModalButton from '../ModalButton';

export const Content = styled.div`
  max-width: 1047px;
  margin: 21px auto 0;
  cursor: default;
  padding: 0 120px 230px;
`;

export const Title = styled.div`
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
  font-size: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ButtonLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RemoveButtonLabelIcon = styled.img`
  width: 12px;
  height: 16px;
  margin-right: 9px;
`;

export const RemoveButtonLabelText = styled.div`
  font-family: ProximaNova-Medium, sans-serif;
  font-size: 15px;
`;

export const ButtonLabelIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 9px;
`;

export const ButtonLabelText = styled.div`
  font-family: ProximaNova-Semibold, sans-serif;
  font-size: 14px;
  color: #ffffff;
`;

export const Wrapper = styled.div``;

export const RemoveHousingButtonsWrapper = styled.div`
  margin: 44px auto 34px;
  width: fit-content;
  display: flex;
  flex-direction: column;

  & button:first-child {
    margin-bottom: 33px;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const DeleteModalButton = styled(ModalButton)`
  border-color: #9696b9;
`;

export const ModalButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 52px;
  margin-top: -20px;
`;
