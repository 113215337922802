import React from 'react';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import {useTranslation} from 'react-i18next';
import {ModalTwoButtonsWrapper} from '../../../styled/common';
import warningIcon from '../../../assets/warning-icon.svg';
import {sendToRegistration, forceSendToRegistration} from '../../../api/reservations';
import {queryCache} from 'react-query';
import {useIsMounted, useStatus} from '../../../utils/hooks';

enum REGISTRATION_TARGET {
  police = 'police',
  stat = 'stat',
}

type YouHaveMadeChangesModalProps = {
  handleModalCancel: () => void;
  isPoliceRegistration: boolean;
  shouldShowWarning: boolean;
  reservationId: string;
  guestGroupId: string;
};

const defaultProps: YouHaveMadeChangesModalProps = {
  handleModalCancel: () => {},
  isPoliceRegistration: true,
  shouldShowWarning: false,
  reservationId: '',
  guestGroupId: '',
};

function SendToRegistrationModal({
  handleModalCancel,
  isPoliceRegistration,
  shouldShowWarning,
  reservationId,
  guestGroupId,
}: YouHaveMadeChangesModalProps) {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {isLoading, setStatus} = useStatus();

  const getTitleToShow = () => {
    return shouldShowWarning && isPoliceRegistration
      ? t('warning_police_message_title')
      : t('warning');
  };

  const getTextToShow = () => {
    return shouldShowWarning && isPoliceRegistration
      ? t('warning_police_message')
      : t('default_registration_message');
  };

  const sendReservationToRegistration = async (target: REGISTRATION_TARGET) => {
    setStatus('loading');
    if (target === REGISTRATION_TARGET.police) {
      await forceSendToRegistration(reservationId, target);
    } else {
      await sendToRegistration(reservationId, target);
    }

    if (isMounted.current) {
      await queryCache.refetchQueries(['guestGroup', guestGroupId]);
      setStatus('idle');
      handleModalCancel();
    }
  };

  const sendToPolice = async () => {
    await sendReservationToRegistration(REGISTRATION_TARGET.police);
  };

  const sendToStat = async () => {
    await sendReservationToRegistration(REGISTRATION_TARGET.stat);
  };

  const handleModalSend = () => {
    return isPoliceRegistration ? sendToPolice() : sendToStat();
  };

  return (
    <Modal
      open
      iconSrc={warningIcon}
      iconAlt="Warning"
      iconProps={{
        height: 84,
        width: 84,
      }}
      title={getTitleToShow()}
      text={getTextToShow()}
    >
      <ModalTwoButtonsWrapper>
        <ModalButton onClick={handleModalSend} label={t('send')} disabled={isLoading} />
        <ModalButton secondary onClick={handleModalCancel} label={t('cancel')} />
      </ModalTwoButtonsWrapper>
    </Modal>
  );
}

SendToRegistrationModal.defaultProps = defaultProps;
export {SendToRegistrationModal};
