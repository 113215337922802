import styled from 'styled-components';

export const Content = styled.div`
  max-width: 1040px;
  padding: 0 20px;
  margin: 27px auto 76px;
`;

export const ContentItem = styled.div`
  margin-top: 43px;
  padding-bottom: 53px;
  border-bottom: 1px solid #d5e0ee;

  &:last-child {
    border-bottom: none !important;
  }
`;

export const Header = styled.div`
  margin: 8px auto 4px;
  font-size: 21px;
  font-family: ProximaNova-Bold, sans-serif;
  color: #161643;
  text-align: center;
  user-select: none;
`;
