import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {FormContext, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {queryCache, useQuery} from 'react-query';
import {format, isValid} from 'date-fns';
import api, {queryFetcher, ResolverTypes} from '../../../api';
import {useAuth} from '../../../context/auth';
import {scrollToTop, toastResponseError} from '../../../utils/common';
import {Housing, Room, Season, SelectOption, TempLock} from '../../../utils/types';
import {
  buildExternalSourcesPayload,
  buildHousingPayload,
  buildPolicePayload,
  buildStatPayload,
} from '../../../utils/housing';
import {
  useErrorModal,
  useErrorToast,
  useIsMounted,
  useModalControls,
  usePrevious,
  useScrollToTop,
  useStatus,
} from '../../../utils/hooks';
import {useConfirmLeaveModal} from '../../../context/openModals';
import {useComputedDetails} from '../../../context/computedDetails';
import {useSubscription} from '../../../context/subscription';
import {
  COUNTRIES_WITH_STAT,
  LOCK_ACCESS_TYPES,
  POLICE_TYPES,
  REMINDER_OPTIONS,
  SECURITY_DEPOSIT_STATUSES,
  SUBSCRIPTION_TYPES,
} from '../../../utils/constants';
import rubbishIcon from '../../../assets/rubbish.svg';
import deletePropertyIcon from '../../../assets/delete-property.svg';
import missingDataIcon from '../../../assets/icon-data-missing.svg';
import Button from '../Button';
import BackButton from '../BackButton';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import HousingOnlineCheckInSection from '../HousingOnlineCheckInSection';
import HousingInfoSection from '../HousingInfoSection';
import HousingContractsSection from '../HousingContractsSection';
import HousingPoliceConnectionSection from '../HousingPoliceConnectionSection';
import HousingSpacesSection from '../HousingSpacesSection';
import HousingSelfCheckinSection from '../HousingSelfCheckinSection';
import HousingTaxesSection, {
  FORM_NAMES as TAXES_FORM_NAMES,
} from '../HousingTaxesSection';
import HousingICalsSection, {
  FORM_NAMES as ICALS_FORM_NAMES,
} from '../HousingICalsSection';
import HousingStatConnectionSection from '../HousingStatConnectionSection';
import Loader from '../../common/Loader';
import FloatingSaveButton from '../FloatingSaveButton';
import YouHaveMadeChangesModal from '../YouHaveMadeChangesModal';
import HousingSecurityDepositSection from '../HousingSecurityDepositSection';
import {Heading, ModalTwoButtonsWrapper} from '../../../styled/common';
import {
  ButtonLabelWrapper,
  Content,
  DeleteModalButton,
  LoaderWrapper,
  ModalButtonWrapper,
  RemoveButtonLabelIcon,
  RemoveButtonLabelText,
  Title,
  Wrapper,
} from './styled';

const LOADER_PROPS = {
  height: 45,
  width: 45,
};

export enum FORM_NAMES {
  name = 'name',
  country = 'country',
  province = 'province',
  address = 'address',
  city = 'municipality',
  vatin = 'vatin',
  contract_type = 'contract_type',
  tourism_registration_number = 'tourism_registration_number',
  manager_name = 'manager_name',
  manager_phone = 'manager_phone',
  manager_birth_date = 'manager_birth_date',
  rooms_quantity = 'rooms_quantity',
  number_of_spaces = 'number_of_spaces',
  depositAmount = 'security_deposit_amount',
}

type FormTypes = {
  [FORM_NAMES.name]: string;
  [FORM_NAMES.country]: SelectOption;
  [FORM_NAMES.province]: SelectOption;
  [FORM_NAMES.address]: string;
  [FORM_NAMES.city]: string;
  [FORM_NAMES.vatin]: string;
  [FORM_NAMES.contract_type]: SelectOption;
  [FORM_NAMES.tourism_registration_number]: string;
  [FORM_NAMES.manager_name]: string;
  [FORM_NAMES.manager_phone]: string;
  [FORM_NAMES.manager_birth_date]: Date;
  [FORM_NAMES.rooms_quantity]: number;
  [FORM_NAMES.number_of_spaces]: number;
  [FORM_NAMES.depositAmount]?: string;
};

type OnlineCheckInSectionRefType = {
  active: boolean;
  data: {
    [key: string]: boolean;
  };
};

type SpacesSectionRefType = {
  active: boolean;
};

type ContractsSectionRefType = {
  active: boolean;
  extraClauses: [];
  signature: string;
};

type PoliceConnectionSectionRefType = {
  active: boolean;
  submit: () => boolean;
  getValues: () => {
    [key: string]: string | SelectOption;
  };
};

type StatConnectionSectionRefType = {
  active: boolean;
  submit: () => boolean;
  getValues: () => {
    [key: string]: string | SelectOption;
  };
};

type SelfCheckinSectionRefType = {
  active: boolean;
  tempLocks: TempLock[];
  lockUser: SelectOption | null;
  data: {
    [key: string]: boolean;
  };
};

type DepositsSectionRefType = {
  active: boolean;
  depositStatus: SECURITY_DEPOSIT_STATUSES;
};

type TaxesSectionRefType = {
  getHighSeasonPayload: () => any;
  getLowSeasonPayload: () => any;
  resetTaxesDetails: () => void;
  active: boolean;
};

function fetchHousing(key: string, id = '') {
  return queryFetcher(api.housings.ENDPOINTS.one(id));
}

function fetchSeason(key: string, id: string) {
  return queryFetcher(api.seasons.ENDPOINTS.one(id));
}

function EditHousingSections() {
  useScrollToTop();
  const {t} = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const {isAccountCollaborator} = useComputedDetails();
  const {
    openModal: openExportTaxesModal,
    closeModal: closeExportTaxesModal,
    isOpen: isExportTaxesModalOpen,
  } = useModalControls();
  const {isHotelSubscription} = useSubscription();
  const {accountDetails: user} = useAuth();
  const subscriptionType = user?.subscription_type;
  const {id: housingId} = useParams();
  const formMethods = useForm<FormTypes>();
  const formState = formMethods.formState;
  const prevFormState = usePrevious<typeof formState>(formState);
  const policeViewRef = React.useRef<HTMLDivElement>(null);
  const statViewRef = React.useRef<HTMLDivElement>(null);
  const {ErrorModal, displayError} = useErrorModal();
  const {setStatus, isLoading, resetStatus, status, isIdle} = useStatus({
    autoReset: true,
  });
  const [isRemoveHousingModalOpen, setIsRemoveHousingModalOpen] = React.useState(false);
  const [isContractsSectionActive, setIsContractsSectionActive] = React.useState(false);
  const [wasDataIncompleteModalOpened, setWasDataIncompleteModalOpened] = React.useState(
    false,
  );
  const [isDataIncompleteModalOpen, setIsDataIncompleteModalOpen] = React.useState(false);

  const [isInfoSectionTouched, setIsInfoSectionTouched] = React.useState(false);
  const [isPoliceSectionTouched, setIsPoliceSectionTouched] = React.useState(false);
  const [isStatSectionTouched, setIsStatSectionTouched] = React.useState(false);
  const [isContractsSectionTouched, setIsContractsSectionTouched] = React.useState(false);
  const [isICalsSectionTouched, setIsICalsSectionTouched] = React.useState(false);
  const [isSelfCheckinSectionTouched, setIsSelfCheckinSectionTouched] = React.useState(
    false,
  );
  const [
    isOnlineCheckInSectionTouched,
    setIsOnlineCheckInSectionTouched,
  ] = React.useState(false);
  const [isDepositsSectionTouched, setIsDepositsSectionTouched] = React.useState(false);
  const [isHousingDeleted, setIsHousingDeleted] = React.useState(false);
  const [setIsSpacesSectionTouched, setSetIsSpacesSectionTouched] = React.useState(false);
  const [isTaxesSectionTouched, setIsTaxesSectionTouched] = React.useState(false);
  const [highSeasonId, setHighSeasonId] = React.useState('');
  const [lowSeasonId, setLowSeasonId] = React.useState('');
  const [rooms, setRooms] = React.useState<Room[]>([]);

  const isAnySectionTouched =
    isInfoSectionTouched ||
    isPoliceSectionTouched ||
    isStatSectionTouched ||
    isContractsSectionTouched ||
    isICalsSectionTouched ||
    isOnlineCheckInSectionTouched ||
    setIsSpacesSectionTouched ||
    isSelfCheckinSectionTouched ||
    isTaxesSectionTouched ||
    isDepositsSectionTouched;

  const onlineCheckInSectionRef = React.useRef<OnlineCheckInSectionRefType>({
    active: false,
    data: {},
  });
  const policeConnectionSectionRef = React.useRef<PoliceConnectionSectionRefType>({
    active: false,
    submit: () => false,
    getValues: () => ({}),
  });
  const spacesSectionRef = React.useRef<SpacesSectionRefType>({
    active: false,
  });
  const contractsSectionRef = React.useRef<ContractsSectionRefType>({
    active: false,
    extraClauses: [],
    signature: '',
  });
  const statConnectionSectionRef = React.useRef<StatConnectionSectionRefType>({
    active: false,
    submit: () => false,
    getValues: () => ({}),
  });
  const selfCheckinSectionRef = React.useRef<SelfCheckinSectionRefType>({
    active: false,
    data: {},
    tempLocks: [],
    lockUser: null,
  });
  const taxesSectionRef = React.useRef<TaxesSectionRefType>({
    getHighSeasonPayload: () => {},
    getLowSeasonPayload: () => {},
    resetTaxesDetails: () => {},
    active: false,
  });
  const depositsSectionRef = React.useRef<DepositsSectionRefType>({
    active: false,
    depositStatus: SECURITY_DEPOSIT_STATUSES.optional,
  });

  const country = formMethods.watch(FORM_NAMES.country)?.value;
  const housingName = formMethods.watch(FORM_NAMES.name);
  const numberOfSpaces = formMethods.watch(FORM_NAMES.number_of_spaces) || 0;

  const {data: housing, status: housingStatus} = useQuery<Housing, [string, string]>(
    Boolean(housingId && !isHousingDeleted) && ['housing', housingId],
    fetchHousing,
    {
      refetchOnWindowFocus: false,
    },
  );

  const {data: highSeason, error: highSeasonError, status: highSeasonStatus} = useQuery<
    Season,
    [string, string]
  >(Boolean(highSeasonId && !isLoading) && ['highSeason', highSeasonId], fetchSeason, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(highSeasonError, {
    notFoundMessage: t('season_not_found'),
  });
  const {data: lowSeason, error: lowSeasonError, status: lowSeasonStatus} = useQuery<
    Season,
    [string, string]
  >(Boolean(lowSeasonId && !isLoading) && ['lowSeason', lowSeasonId], fetchSeason, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(lowSeasonError, {
    notFoundMessage: t('season_not_found'),
  });

  const areSeasonsLoading =
    highSeasonStatus === 'loading' || lowSeasonStatus === 'loading';
  const areSectionsDisabled = isLoading || housingStatus === 'loading';

  const openIncompleteModal = React.useCallback(() => {
    if (!wasDataIncompleteModalOpened) {
      setIsDataIncompleteModalOpen(true);
      setWasDataIncompleteModalOpened(true);
    }
  }, [wasDataIncompleteModalOpened]);

  React.useEffect(
    function redirect() {
      if (isAccountCollaborator) {
        history.push('/bookings');
      }
    },
    [history, isAccountCollaborator],
  );

  React.useLayoutEffect(
    function showIncompleteModalOneTime() {
      const shouldOpenModal =
        formState.isSubmitted && !prevFormState?.isValid && !formState.isValid;

      if (shouldOpenModal) {
        openIncompleteModal();
      }
    },
    [
      formState.isSubmitted,
      formState.isValid,
      prevFormState,
      wasDataIncompleteModalOpened,
      openIncompleteModal,
    ],
  );

  React.useEffect(() => {
    if (!housing?.seasons?.length) {
      return;
    }

    const highSeasonId = housing.seasons[0];
    const lowSeasonId = housing.seasons[1] || '';
    setHighSeasonId(highSeasonId);
    setLowSeasonId(lowSeasonId);
  }, [housing]);

  const openRemoveHousingModal = () => {
    setIsRemoveHousingModalOpen(true);
  };

  const closeRemoveHousingModal = () => {
    setIsRemoveHousingModalOpen(false);
  };

  const goToHousings = () => {
    history.push('/properties');
  };

  const deleteHousing = async () => {
    setStatus('loading');

    const seasonsError = await deleteAllSeasons();
    if (!isMounted.current) {
      return;
    }

    if (seasonsError) {
      toastResponseError(seasonsError);
      return;
    }

    const {error} = await api.housings.deleteById(housingId);
    if (!isMounted.current) {
      return;
    }

    if (error) {
      toastResponseError(error);
      setStatus('error');
    } else {
      setIsHousingDeleted(true);
    }

    queryCache.removeQueries([`housing`, housingId]);
    await queryCache.refetchQueries('housings');
    toast.success(t('property_deleted'));
    goToHousings();
  };

  const getInitOnlineCheckinSettings = () => {
    const options: {[key: string]: boolean} = {};

    Object.values(REMINDER_OPTIONS).forEach((value) => {
      options[value] = Boolean((housing as any)?.[value]);
    });
    return options;
  };

  const getInitICals = () => {
    return housing?.external_sources;
  };

  const getPolicePayload = () => {
    const data = policeConnectionSectionRef.current?.getValues();
    const isActive = POLICE_TYPES[country] && policeConnectionSectionRef.current?.active;

    if (!isActive) {
      return {
        is_auto_police_registration_enabled: false,
      };
    }
    return buildPolicePayload(data);
  };

  const getStatPayload = () => {
    const data = statConnectionSectionRef.current?.getValues();
    const isActive =
      COUNTRIES_WITH_STAT.includes(country) && statConnectionSectionRef.current?.active;

    if (!isActive) {
      return {
        is_stat_registration_enabled: false,
      };
    }
    return buildStatPayload(data);
  };

  const getContractsPayload = (data: FormTypes) => {
    const isActive = contractsSectionRef.current?.active;

    if (!isActive) {
      return {
        is_contract_enabled: false,
      };
    }

    const signature = contractsSectionRef.current?.signature || undefined;

    return {
      is_contract_enabled: true,
      manager_signature: signature?.startsWith('https') ? undefined : signature,
      extra_clauses: contractsSectionRef.current?.extraClauses,
      [FORM_NAMES.manager_birth_date]: isValid(data[FORM_NAMES.manager_birth_date])
        ? format(data[FORM_NAMES.manager_birth_date], 'yyyy-MM-dd')
        : undefined,
      [FORM_NAMES.contract_type]: (data[FORM_NAMES.contract_type] as SelectOption)?.value,
      [FORM_NAMES.manager_name]: data[FORM_NAMES.manager_name] || undefined,
    };
  };

  const getSelfCheckinRemindersPayload = () => {
    const isActive = selfCheckinSectionRef.current?.active;
    const data = selfCheckinSectionRef.current?.data;

    if (!isActive && data) {
      let inactiveData: {[key: string]: boolean} = {};
      Object.keys(data).forEach((key: string) => {
        inactiveData[key] = false;
      });

      return inactiveData;
    }
    return data;
  };

  const getCheckInOnlineRemindersPayload = () => {
    const isActive = onlineCheckInSectionRef.current?.active;
    const data = onlineCheckInSectionRef.current?.data;

    if (!isActive && data) {
      let inactiveData: {[key: string]: boolean} = {};
      Object.keys(data).forEach((key: string) => {
        inactiveData[key] = false;
      });

      return inactiveData;
    }
    return data;
  };

  const getTaxesPayload = () => {
    const isActive = taxesSectionRef?.current?.active;
    const result: {[key: string]: undefined} = {};

    if (!isActive) {
      return {};
    }

    Object.keys(TAXES_FORM_NAMES).forEach((key) => {
      result[key] = undefined;
    });
    return result;
  };

  const getDepositsPayload = (data: FormTypes) => {
    const isActive = depositsSectionRef.current?.active;
    const securityDepositStatus = depositsSectionRef.current?.depositStatus;

    if (!isActive) {
      return {
        security_deposit_status: SECURITY_DEPOSIT_STATUSES.inactive,
      };
    }

    return {
      security_deposit_status: securityDepositStatus,
      [FORM_NAMES.depositAmount]: data[FORM_NAMES.depositAmount],
    };
  };

  const getRoomsPayload = () => {
    const isActive = spacesSectionRef.current?.active;
    if (!isActive) {
      return {
        rooms: [],
      };
    }

    const roomsWithDefaultNumbers = rooms.map((room: Room, i) => {
      if (!room.number) {
        return {
          ...room,
          number: `Space ${i + 1}`,
        };
      }

      return room;
    });
    return {rooms: roomsWithDefaultNumbers};
  };

  const getICalsPayload = (data: any) => {
    const isActive =
      data[ICALS_FORM_NAMES.booking] ||
      data[ICALS_FORM_NAMES.airbnb] ||
      data[ICALS_FORM_NAMES.other];

    if (!isActive) {
      return {
        external_sources: [],
      };
    }
    return buildExternalSourcesPayload(data);
  };

  const getHousingPayload = (data: FormTypes) => {
    const payload = buildHousingPayload(data, country);
    return {
      ...payload,
      is_self_online_check_in_enabled: selfCheckinSectionRef.current?.active,
    };
  };

  const getPayload = (data: FormTypes) => {
    return {
      ...getHousingPayload(data),
      ...getICalsPayload(data),
      ...getPolicePayload(),
      ...getStatPayload(),
      ...getContractsPayload(data),
      ...getCheckInOnlineRemindersPayload(),
      ...getSelfCheckinRemindersPayload(),
      ...getRoomsPayload(),
      ...getTaxesPayload(),
      ...getDepositsPayload(data),
    };
  };

  const deleteOddRooms = async () => {
    let error = null;
    let rooms = getRoomsPayload()?.rooms;
    const isSpacesSectionActive = spacesSectionRef.current?.active;

    if (!rooms || !isSpacesSectionActive) {
      rooms = [];
    }

    if (housing && rooms.length < housing.rooms?.length) {
      const oddRooms = housing.rooms.slice(rooms.length);

      for await (let room of oddRooms) {
        if (error || !isMounted.current) {
          return;
        }

        const {error: roomError} = await api.rooms.deleteOne(room.id!);
        error = roomError;
      }
    }

    return error;
  };

  const handleResponseError = (error: any) => {
    if (!isMounted.current) {
      return;
    }

    setStatus('error');
    displayError(error);
  };

  const createLocks = async (
    tempLocks: TempLock[],
    housingId: string,
    housingRooms: Room[] = [],
  ) => {
    let hasError: any = null;

    for await (let lock of tempLocks) {
      if (!hasError && isMounted.current) {
        const isLockAssignedToAJustCreatedRoom =
          lock.access_type === LOCK_ACCESS_TYPES.private && !lock?.room_id;

        const payload = {
          ...lock,
          housing: housingId,
        };

        if (isLockAssignedToAJustCreatedRoom) {
          const lockRoom = housingRooms.find((room) => {
            return room?.number === lock?.room_number;
          });

          if (!lockRoom?.id) {
            return;
          }

          payload.room_id = lockRoom.id;
        }

        const {error} = await api.locks.createLock(payload);
        hasError = error;
      }
    }
    return hasError;
  };

  const submitTempLocks = async (housingId: string, housingRooms: Room[] = []) => {
    const active = selfCheckinSectionRef.current?.active;
    const lockUser = selfCheckinSectionRef.current?.lockUser;
    const tempLocks = selfCheckinSectionRef.current?.tempLocks;

    if (!active || !tempLocks?.length || !lockUser) {
      return;
    }

    const hasError = await createLocks(tempLocks, housingId, housingRooms);
    if (!hasError) {
      await queryCache.refetchQueries(['locks', housingId, lockUser?.value]);
    }

    return hasError;
  };

  const validatePoliceAndGetResult = async () => {
    policeViewRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    return policeConnectionSectionRef.current.submit();
  };

  const validateStatAndGetResult = async () => {
    statViewRef.current?.scrollIntoView({behavior: 'smooth'});
    return statConnectionSectionRef.current.submit();
  };

  const getSeasonsIds = () => {
    const ids: string[] = [];

    if (highSeasonId) {
      ids.push(highSeasonId);
    }
    if (lowSeasonId) {
      ids.push(lowSeasonId);
    }

    return ids;
  };

  const deleteAllSeasons = async () => {
    let error: any = null;
    const ids = getSeasonsIds();

    for await (const id of ids) {
      const result = await api.seasons.deleteOne(id);

      if (!isMounted.current) {
        break;
      }
      if (result.error) {
        error = result.error;
        break;
      }

      if (id === highSeasonId) {
        setHighSeasonId('');
        queryCache.removeQueries(['highSeason', id]);
      }
      if (id === lowSeasonId) {
        setLowSeasonId('');
        queryCache.removeQueries(['lowSeason', id]);
      }
    }

    return error;
  };

  const deleteSeasonsAndResetData = async () => {
    const deleteError = await deleteAllSeasons();

    if (deleteError) {
      handleResponseError(deleteError);
    } else {
      taxesSectionRef?.current?.resetTaxesDetails();
    }

    return deleteError;
  };

  const refreshOneSeason = async (id: string) => {
    const payload = {
      rules: [],
      exemptions: [],
    };

    const result = await api.seasons.patch(id, payload);
    if (result?.error) {
      return result.error;
    }

    return null;
  };

  const refreshSeasons = async (highSeasonId: string, lowSeasonId: string) => {
    if (highSeasonId) {
      const error = await refreshOneSeason(highSeasonId);
      if (error) {
        return error;
      }
    }

    const isGoingToDeleteLowSeason = !taxesSectionRef?.current?.getLowSeasonPayload();
    if (lowSeasonId && !isGoingToDeleteLowSeason) {
      const error = await refreshOneSeason(lowSeasonId);
      if (error) {
        return error;
      }
    }

    return null;
  };

  const deleteSeason = async (id: string) => {
    const result = await api.seasons.deleteOne(id);

    if (result.error && isMounted.current) {
      displayError(result.error);
    }
    return result;
  };

  const saveSeason = async (id: string, payload: any) => {
    let result: ResolverTypes;

    if (id) {
      result = await api.seasons.patch(id, payload);
    } else {
      result = await api.seasons.post(payload);
    }

    if (result.error && isMounted.current) {
      displayError(result.error);
    }
    return result;
  };

  const saveHighSeason = async (id: string) => {
    const payload = taxesSectionRef?.current?.getHighSeasonPayload();
    const result = await saveSeason(id, payload);

    if (result.data?.id) {
      queryCache.setQueryData(['highSeason', result.data?.id], result.data);
    }
    return result;
  };

  const saveOrDeleteLowSeason = async (id: string) => {
    const payload = taxesSectionRef?.current?.getLowSeasonPayload();

    if (!payload) {
      if (id) {
        const result = await deleteSeason(id);
        if (!result.error) {
          queryCache.removeQueries(['lowSeason', id]);
        }
      }

      return;
    }

    const result = await saveSeason(id, payload);
    if (result.data?.id) {
      queryCache.setQueryData(['lowSeason', result.data.id], result.data);
    }
    return result;
  };

  const updateSeasons = async () => {
    const refreshResult = await refreshSeasons(highSeasonId, lowSeasonId);
    if (refreshResult?.error) {
      return refreshResult.error;
    }

    const highSeasonResult = await saveHighSeason(highSeasonId);
    if (highSeasonResult?.error) {
      return highSeasonResult.error;
    }

    const lowSeasonResult = await saveOrDeleteLowSeason(lowSeasonId);
    if (lowSeasonResult?.error) {
      return lowSeasonResult.error;
    }

    return null;
  };

  const saveSeasons = async () => {
    if (!taxesSectionRef?.current?.active) {
      return null;
    }

    const seasonsUpdateError = await updateSeasons();
    if (seasonsUpdateError) {
      handleResponseError(seasonsUpdateError);
      return seasonsUpdateError;
    }

    return null;
  };

  const deleteOrSaveSeasons = async () => {
    if (housing?.seasons?.length && !taxesSectionRef?.current?.active) {
      return deleteSeasonsAndResetData();
    }

    return saveSeasons();
  };

  const updateHousing = async (housingData: FormTypes) => {
    const payload = getPayload(housingData);
    if (subscriptionType === SUBSCRIPTION_TYPES.hotel) {
      const roomsError = await deleteOddRooms();
      if (roomsError) {
        handleResponseError(roomsError);
        return roomsError;
      }
    }

    const {data, error} = await api.housings.patchById(housing!.id, payload);
    if (error) {
      handleResponseError(error);
      return error;
    }
    if (data) {
      const housingRooms = data?.rooms;
      const lockError = await submitTempLocks(data.id, housingRooms);
      if (lockError) {
        handleResponseError(lockError);
        return;
      }

      queryCache.setQueryData([`housing`, housingId], data);
      await queryCache.refetchQueries('housings');
      await queryCache.refetchQueries('upcomingInvoices');
    }

    return null;
  };

  const {
    linkToGo,
    goThroughConfirm,
    handleModalSave,
    handleModalDontSave,
    handleModalCancel,
    isDoYouWantToSaveModalOpen,
  } = useConfirmLeaveModal(isAnySectionTouched);

  const onSubmit = async (data: FormTypes) => {
    const isPoliceSectionActive =
      POLICE_TYPES[country] && policeConnectionSectionRef.current?.active;
    const isStatSectionActive =
      COUNTRIES_WITH_STAT.includes(country) && statConnectionSectionRef.current?.active;
    let isPoliceAccountValid = false;
    let isStatAccountValid = false;

    scrollToTop();
    setStatus('loading');
    if (isPoliceSectionActive) {
      isPoliceAccountValid = await validatePoliceAndGetResult();

      if (!isPoliceAccountValid) {
        resetStatus();
        return;
      }
    }

    const canValidateStat = isPoliceSectionActive
      ? isPoliceAccountValid && isStatSectionActive
      : isStatSectionActive;
    if (canValidateStat) {
      isStatAccountValid = await validateStatAndGetResult();

      if (!isStatAccountValid) {
        resetStatus();
        return;
      }
    }

    const seasonsError = await deleteOrSaveSeasons();
    if (seasonsError) {
      return;
    }

    const housingUpdateError = await updateHousing(data);
    if (housingUpdateError) {
      return;
    }

    setStatus('success');

    if (!housingUpdateError && linkToGo) {
      history.push(linkToGo);
    }
  };

  return (
    <>
      <ErrorModal />
      <Content>
        <FormContext {...formMethods}>
          <Wrapper>
            <Heading>
              <div>
                <BackButton onClick={() => goThroughConfirm('/properties')} />
              </div>
              <Title>{housingName}</Title>
              <div>
                {user?.show_buttons_add_delete_import_housings && (
                  <Button
                    secondary
                    type="button"
                    disabled={isLoading}
                    onClick={openRemoveHousingModal}
                    label={
                      <ButtonLabelWrapper>
                        <RemoveButtonLabelIcon src={rubbishIcon} alt="Plus" />
                        <RemoveButtonLabelText>
                          {t('delete_property')}
                        </RemoveButtonLabelText>
                      </ButtonLabelWrapper>
                    }
                  />
                )}
              </div>
            </Heading>
            {!housing && (
              <LoaderWrapper>
                <Loader {...LOADER_PROPS} />
              </LoaderWrapper>
            )}
            {housing && (
              <>
                <HousingInfoSection
                  setIsSectionTouched={setIsInfoSectionTouched}
                  disabled={!housing || areSectionsDisabled}
                  isContractsSectionActive={isContractsSectionActive}
                  housing={housing}
                  spacesCount={numberOfSpaces}
                />
                <div ref={policeViewRef}>
                  <HousingPoliceConnectionSection
                    setIsSectionTouched={setIsPoliceSectionTouched}
                    disabled={!housing || areSectionsDisabled}
                    country={country}
                    ref={policeConnectionSectionRef}
                    housing={housing}
                    openIncompleteModal={openIncompleteModal}
                  />
                </div>
                <div ref={statViewRef}>
                  <HousingStatConnectionSection
                    setIsSectionTouched={setIsStatSectionTouched}
                    disabled={!housing || areSectionsDisabled}
                    country={country}
                    ref={statConnectionSectionRef}
                    housing={housing}
                    openIncompleteModal={openIncompleteModal}
                  />
                </div>
                {isHotelSubscription && (
                  <HousingSpacesSection
                    setIsSectionTouched={setSetIsSpacesSectionTouched}
                    ref={spacesSectionRef}
                    disabled={!housing || areSectionsDisabled}
                    initSpaces={housing?.rooms}
                    setRooms={setRooms}
                    rooms={rooms}
                  />
                )}
                <HousingOnlineCheckInSection
                  setIsSectionTouched={setIsOnlineCheckInSectionTouched}
                  ref={onlineCheckInSectionRef}
                  disabled={!housing || areSectionsDisabled}
                  initSelectors={getInitOnlineCheckinSettings()}
                  housing={housing}
                />
                <HousingSelfCheckinSection
                  setIsSectionTouched={setIsSelfCheckinSectionTouched}
                  disabled={!housing || areSectionsDisabled}
                  housing={housing}
                  rooms={getRoomsPayload().rooms}
                  ref={selfCheckinSectionRef}
                />
                <HousingContractsSection
                  setIsSectionTouched={setIsContractsSectionTouched}
                  ref={contractsSectionRef}
                  disabled={!housing || areSectionsDisabled}
                  setIsContractsSectionActive={setIsContractsSectionActive}
                  housing={housing}
                  country={country}
                />
                <HousingTaxesSection
                  openExportTaxesModal={openExportTaxesModal}
                  closeExportTaxesModal={closeExportTaxesModal}
                  isExportTaxesModalOpen={isExportTaxesModalOpen}
                  setIsSectionTouched={setIsTaxesSectionTouched}
                  isSectionTouched={isTaxesSectionTouched}
                  ref={taxesSectionRef}
                  disabled={!housing || areSectionsDisabled}
                  areSeasonsLoading={areSeasonsLoading}
                  highSeason={highSeason}
                  lowSeason={lowSeason}
                  housing={housing}
                  country={country}
                />
                <HousingSecurityDepositSection
                  ref={depositsSectionRef}
                  disabled={!housing || areSectionsDisabled}
                  setIsSectionTouched={setIsDepositsSectionTouched}
                  housing={housing}
                />
                {user?.is_icals_allowed && (
                  <HousingICalsSection
                    setIsSectionTouched={setIsICalsSectionTouched}
                    disabled={!housing || areSectionsDisabled}
                    initICals={getInitICals()}
                  />
                )}
              </>
            )}
            {housing && !isRemoveHousingModalOpen && (isAnySectionTouched || !isIdle) && (
              <FloatingSaveButton
                status={status}
                onClick={formMethods.handleSubmit(onSubmit)}
              />
            )}
          </Wrapper>
        </FormContext>
      </Content>
      {isDataIncompleteModalOpen && (
        <Modal
          open
          iconSrc={missingDataIcon}
          iconAlt="Form with a red fields"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={t('data_missing')}
          text={
            <>
              {t('you_cant_add_this_property_until')}
              <p />
              {t('we_have_outlined_fields')}
            </>
          }
        >
          <ModalButtonWrapper>
            <ModalButton
              label={t('ok')}
              onClick={() => setIsDataIncompleteModalOpen(false)}
            />
          </ModalButtonWrapper>
        </Modal>
      )}
      {isRemoveHousingModalOpen && (
        <Modal
          open
          iconProps={{
            height: 94,
            width: 84,
          }}
          iconSrc={deletePropertyIcon}
          iconAlt="Delete housing"
          title={isLoading ? t('deleting') : t('are_you_sure')}
          text={
            isLoading
              ? `${t('it_takes_seconds')}...`
              : t('you_have_to_know_that_all_information')
          }
        >
          {isIdle && (
            <ModalTwoButtonsWrapper>
              <DeleteModalButton
                onClick={deleteHousing}
                label={
                  <ButtonLabelWrapper>
                    <RemoveButtonLabelIcon src={rubbishIcon} alt="Plus" />
                    <RemoveButtonLabelText>{t('delete_property')}</RemoveButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
              <ModalButton
                secondary
                onClick={closeRemoveHousingModal}
                label={t('cancel')}
              />
            </ModalTwoButtonsWrapper>
          )}
        </Modal>
      )}
      {isDoYouWantToSaveModalOpen && (
        <YouHaveMadeChangesModal
          handleModalSave={() => handleModalSave(formMethods.handleSubmit(onSubmit))}
          handleModalDontSave={handleModalDontSave}
          handleModalCancel={handleModalCancel}
        />
      )}
    </>
  );
}

export {EditHousingSections};
