import styled, {css} from 'styled-components';
import {device} from '../../../styled/device';
import Input from '../Input';
import Select from '../Select';
import {DisplayIcon as BaseDisplayIcon} from '../Select/styled';
import {ErrorMessage as BaseErrorMessage} from '../../../styled/common';
import {Label as BaseLabel} from '../Input/styled';
import {StyledInput} from '../Input/styled';
import {MRZModalTrigger} from '../QuestionMarkButton/styled';

export const DisplayIcon = styled(BaseDisplayIcon)`
  position: absolute;
  right: 7px;
  top: 8px;
  z-index: 1;
`;

export const Label = styled(BaseLabel)`
  margin-right: 5px;
`;

type WrapperProps = {
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 264px;
  min-height: 48px;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;

      & ${DisplayIcon}, & ${Label} {
        opacity: 0.3;
      }
    `};

  @media (max-width: ${device.mobileS}) {
    width: 302px;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;

  & ${StyledInput} {
    padding-bottom: 3px;
  }
`;

export const DateInput = styled(Input)`
  width: 50px;
  min-height: 23px;

  & ${BaseLabel} {
    display: none;
  }
`;

export const DateSelectWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const DateSelect = styled(Select)`
  width: 133px;
  min-height: 23px;

  & ${BaseDisplayIcon} {
    display: none;
  }

  & .select {
    height: 23px !important;
  }

  & .select__placeholder {
    font-family: ProximaNova-Medium, sans-serif !important;
  }

  & .select__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    & > div {
      padding-top: 0 !important;
      margin: 0 !important;
    }
  }

  & .select__input {
    padding-top: 0 !important;
    top: 0 !important;

    & input {
      width: 100px !important;
      top: 0 !important;
    }
  }

  & .select__menu,
  & .select__menu-list {
    width: 225px !important;
  }

  & .select__control {
    min-height: unset !important;
    padding-bottom: 2px !important;
  }
`;

export const ErrorMessage = styled(BaseErrorMessage)`
  padding-top: 1px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${MRZModalTrigger} {
    position: relative;
    top: -2px;
  }
`;
