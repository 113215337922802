import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {queryCache} from 'react-query';
import i18n from '../../../i18n';
import {CREDITOR_TYPES, CREDITOR_TYPES_OPTIONS} from '../../../utils/constants';
import {
  getBase64,
  getRequiredOrOptionalFieldLabel,
  toastResponseError,
} from '../../../utils/common';
import {useIsMounted, useStatus} from '../../../utils/hooks';
import {PaymentsSettings} from '../../../utils/types';
import api from '../../../api';
import xIcon from '../../../assets/x_blue.svg';
import transferIcon from '../../../assets/bank-transfer-icon.png';
import transferIcon2x from '../../../assets/bank-transfer-icon@2x.png';
import checkIcon from '../../../assets/check-filled.svg';
import documentsIcon from '../../../assets/documents-icon.svg';
import Select from '../Select';
import Input from '../Input';
import Modal from '../Modal';
import Loader from '../../common/Loader';
import Button from '../ModalButton';
import FileInput from '../FileInput';
import {FieldWrapper} from '../../../styled/common';
import {
  AccountDetailsContent,
  CloseButton,
  Content,
  DocumentsInfoBoldText,
  DocumentsInfoContent,
  DocumentsInfoNextButtonWrapper,
  DocumentsInfoText,
  DocumentsInfoTitle,
  DocumentsUploadContent,
  DocumentsVerificationNextButtonWrapper,
  DocumentsVerificationSubtitle,
  DocumentsVerificationTitle,
  FileInputWrapper,
  NextButton,
  SuccessText,
  SuccessTitle,
  TaxesIcon,
  TransferIcon,
  TwoButtonsWrapper,
} from './styled';

const ACCEPTABLE_DOCUMENTS = '.jpg, .png, .pdf';

enum FORM_NAMES {
  companyName = 'company_name',
  creditorType = 'creditor_type',
  iban = 'iban',
  swift = 'swift',
  dniFrontSide = 'DNI_frontside',
  dniBackSide = 'DNI_backside',
  certificateOfAccountOwnership = 'PC_CAO',
  taxIdentificationNumber = 'PC_CIF',
  articlesOfIncorporations = 'PC_AIO',
  freelancerReceiptForm = 'PC_FRF',
}

type FormTypes = {
  [FORM_NAMES.creditorType]: {
    value: CREDITOR_TYPES;
    label: string;
  };
  [FORM_NAMES.companyName]: string;
  [FORM_NAMES.iban]: string;
  [FORM_NAMES.swift]: string;
  [FORM_NAMES.certificateOfAccountOwnership]: File;
  [FORM_NAMES.dniFrontSide]: File;
  [FORM_NAMES.dniBackSide]: File;
  [FORM_NAMES.articlesOfIncorporations]: File;
  [FORM_NAMES.taxIdentificationNumber]: File;
  [FORM_NAMES.freelancerReceiptForm]: File;
};

const INIT_DISPLAY_FIELDS = {
  [FORM_NAMES.companyName]: true,
  [FORM_NAMES.creditorType]: true,
  [FORM_NAMES.iban]: true,
  [FORM_NAMES.swift]: true,
  [FORM_NAMES.certificateOfAccountOwnership]: false,
  [FORM_NAMES.dniFrontSide]: false,
  [FORM_NAMES.dniBackSide]: false,
  [FORM_NAMES.articlesOfIncorporations]: false,
  [FORM_NAMES.taxIdentificationNumber]: false,
  [FORM_NAMES.freelancerReceiptForm]: false,
};

function getDisplayFields(creditorType?: CREDITOR_TYPES) {
  switch (creditorType) {
    case CREDITOR_TYPES.company: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.certificateOfAccountOwnership]: true,
        [FORM_NAMES.dniFrontSide]: true,
        [FORM_NAMES.dniBackSide]: true,
        [FORM_NAMES.articlesOfIncorporations]: true,
        [FORM_NAMES.taxIdentificationNumber]: true,
      };
    }
    case CREDITOR_TYPES.freelance: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.certificateOfAccountOwnership]: true,
        [FORM_NAMES.dniFrontSide]: true,
        [FORM_NAMES.dniBackSide]: true,
        [FORM_NAMES.freelancerReceiptForm]: true,
      };
    }
    case CREDITOR_TYPES.individual: {
      return {
        ...INIT_DISPLAY_FIELDS,
        [FORM_NAMES.certificateOfAccountOwnership]: true,
        [FORM_NAMES.dniFrontSide]: true,
        [FORM_NAMES.dniBackSide]: true,
      };
    }
    default: {
      return INIT_DISPLAY_FIELDS;
    }
  }
}

const INIT_REQUIRED_FIELDS = {
  [FORM_NAMES.companyName]: i18n.t('required'),
  [FORM_NAMES.creditorType]: i18n.t('required'),
  [FORM_NAMES.iban]: i18n.t('required'),
  [FORM_NAMES.certificateOfAccountOwnership]: i18n.t('required'),
  [FORM_NAMES.dniFrontSide]: i18n.t('required'),
  [FORM_NAMES.dniBackSide]: i18n.t('required'),
  [FORM_NAMES.articlesOfIncorporations]: i18n.t('required'),
  [FORM_NAMES.taxIdentificationNumber]: i18n.t('required'),
  [FORM_NAMES.freelancerReceiptForm]: i18n.t('required'),
  [FORM_NAMES.swift]: false,
};

function getRequiredFields(creditorType?: CREDITOR_TYPES) {
  switch (creditorType) {
    default: {
      return INIT_REQUIRED_FIELDS;
    }
  }
}

function getFields(creditorType?: CREDITOR_TYPES) {
  const display = getDisplayFields(creditorType);
  const required = getRequiredFields(creditorType);

  return {
    display,
    required,
  };
}

type PaymentsDocumentsVerificationModalProps = {
  onClose: () => void;
  open: boolean;
  paymentsSettingsStatus: 'loading' | 'error' | 'success';
  paymentsSettings: PaymentsSettings;
};

const defaultProps: Partial<PaymentsDocumentsVerificationModalProps> = {
  open: false,
};

function PaymentsDocumentsVerificationModal({
  onClose,
  open,
  paymentsSettings,
  paymentsSettingsStatus,
}: PaymentsDocumentsVerificationModalProps) {
  const [step, setStep] = React.useState(1);
  const [data, setData] = React.useState<Partial<FormTypes>>({});

  React.useEffect(
    function preloadData() {
      if (!paymentsSettings) {
        return;
      }

      const creditorType = Object.values(CREDITOR_TYPES_OPTIONS).find((type) => {
        return type.value === paymentsSettings.creditor_type;
      });

      const nextData = {
        ...paymentsSettings,
        documents: undefined,
        [FORM_NAMES.creditorType]: creditorType,
      };
      setData(nextData);
    },
    [paymentsSettings],
  );

  const goNext = () => {
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const goBack = () => {
    setStep((prevState) => {
      return prevState - 1;
    });
  };

  return (
    <Modal open={open}>
      <Content>
        <CloseButton type="button" onClick={onClose}>
          <img src={xIcon} alt="Cross" />
        </CloseButton>
        {step === 1 && <DocumentsInfoStep goNext={goNext} />}
        {step === 2 && (
          <AccountDetailsStep goNext={goNext} saveData={setData} data={data} />
        )}
        {step === 3 && (
          <DocumentsUploadStep
            goBack={goBack}
            goNext={goNext}
            data={data}
            saveData={setData}
            paymentsSettings={paymentsSettings}
            paymentsSettingsStatus={paymentsSettingsStatus}
          />
        )}
        {step === 4 && <SuccessStep onOk={onClose} />}
      </Content>
    </Modal>
  );
}

type DocumentsInfoStepProps = {
  goNext: () => void;
};

function DocumentsInfoStep({goNext}: DocumentsInfoStepProps) {
  const {t} = useTranslation();
  return (
    <div>
      <TransferIcon
        src={transferIcon2x}
        srcSet={`${transferIcon} 1x, ${transferIcon2x} 2x`}
      />
      <DocumentsInfoContent>
        <DocumentsInfoTitle>{t('documents_info_step_title')}</DocumentsInfoTitle>
        <p />
        <DocumentsInfoText>{t('documents_info_top_text')}</DocumentsInfoText>
        <p />
        <DocumentsInfoBoldText>{t('documents_info_middle_text')}</DocumentsInfoBoldText>
        <p />
        <DocumentsInfoText>{t('documents_info_bottom_text')}</DocumentsInfoText>
      </DocumentsInfoContent>
      <DocumentsInfoNextButtonWrapper>
        <NextButton label={t('start').toUpperCase()} type="button" onClick={goNext} />
      </DocumentsInfoNextButtonWrapper>
    </div>
  );
}

type AccountDetailsStepProps = {
  goNext: () => void;
  saveData: React.Dispatch<React.SetStateAction<Partial<FormTypes>>>;
  data: Partial<FormTypes>;
};

function AccountDetailsStep({goNext, saveData, data}: AccountDetailsStepProps) {
  const {t} = useTranslation();
  const {register, errors, control, watch, handleSubmit} = useForm<FormTypes>();
  const creditorType = watch(FORM_NAMES.creditorType)?.value;
  const [fields, setFields] = React.useState(() => {
    return getFields(creditorType);
  });

  const companyNameLabel =
    creditorType === CREDITOR_TYPES.company
      ? t('company_name')
      : t('creditor_name_surname');

  React.useEffect(() => {
    const nextFields = getFields(creditorType);
    setFields(nextFields);
  }, [creditorType]);

  const onSubmit = (data: Partial<FormTypes>) => {
    saveData((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
    goNext();
  };

  return (
    <div>
      <DocumentsVerificationTitle>
        {t('documents_account_details_title')}
      </DocumentsVerificationTitle>
      <DocumentsVerificationSubtitle>
        {t('documents_account_details_subtitle')}
      </DocumentsVerificationSubtitle>
      <AccountDetailsContent>
        {fields.display[FORM_NAMES.creditorType] && (
          <FieldWrapper>
            <Controller
              as={<Select />}
              control={control}
              name={FORM_NAMES.creditorType}
              options={Object.values(CREDITOR_TYPES_OPTIONS)}
              label={getRequiredOrOptionalFieldLabel(
                t('creditor_type'),
                fields.required[FORM_NAMES.creditorType],
              )}
              rules={{required: fields.required[FORM_NAMES.creditorType]}}
              placeholder={t('select_your_type')}
              error={(errors[FORM_NAMES.creditorType] as any)?.message}
              defaultValue={data?.[FORM_NAMES.creditorType]}
            />
          </FieldWrapper>
        )}
        {fields.display[FORM_NAMES.iban] && (
          <FieldWrapper>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.iban],
              })}
              name={FORM_NAMES.iban}
              error={errors[FORM_NAMES.iban]?.message}
              label={getRequiredOrOptionalFieldLabel(
                t('iban'),
                fields.required[FORM_NAMES.iban],
              )}
              placeholder={t('enter_your_iban')}
              defaultValue={data?.[FORM_NAMES.iban]}
            />
          </FieldWrapper>
        )}
        {fields.display[FORM_NAMES.companyName] && (
          <FieldWrapper>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.companyName],
              })}
              name={FORM_NAMES.companyName}
              error={errors[FORM_NAMES.companyName]?.message}
              label={getRequiredOrOptionalFieldLabel(
                companyNameLabel,
                fields.required[FORM_NAMES.companyName],
              )}
              placeholder={t('enter_name')}
              defaultValue={data?.[FORM_NAMES.companyName]}
            />
          </FieldWrapper>
        )}
        {fields.display[FORM_NAMES.swift] && (
          <FieldWrapper>
            <Input
              ref={register({
                required: fields.required[FORM_NAMES.swift],
              })}
              name={FORM_NAMES.swift}
              error={errors[FORM_NAMES.swift]?.message}
              label={t('swift')}
              placeholder={t('enter_your_swift')}
              defaultValue={data?.[FORM_NAMES.swift]}
            />
          </FieldWrapper>
        )}
      </AccountDetailsContent>
      <DocumentsVerificationNextButtonWrapper>
        <NextButton
          type="button"
          onClick={handleSubmit(onSubmit)}
          label={t('next').toUpperCase()}
        />
      </DocumentsVerificationNextButtonWrapper>
    </div>
  );
}

type DocumentsUploadStepProps = {
  data: Partial<FormTypes>;
  saveData: React.Dispatch<React.SetStateAction<Partial<FormTypes>>>;
  goNext: () => void;
  goBack: () => void;
  paymentsSettingsStatus: 'loading' | 'error' | 'success';
  paymentsSettings: PaymentsSettings;
};

function DocumentsUploadStep({
  goBack,
  goNext,
  saveData,
  data,
  paymentsSettingsStatus,
  paymentsSettings,
}: DocumentsUploadStepProps) {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {isLoading, setStatus} = useStatus();
  const {control, errors, handleSubmit, getValues} = useForm();
  const creditorType = data?.[FORM_NAMES.creditorType]?.value;
  const [fields] = React.useState(() => {
    return getFields(creditorType);
  });

  const dniFrontLabel =
    creditorType === CREDITOR_TYPES.company
      ? t('dni_passport_front_admin')
      : t('dni_passport_front_true_holder');
  const dniBackLabel =
    creditorType === CREDITOR_TYPES.company
      ? t('dni_passport_back_admin')
      : t('dni_passport_back_true_holder');

  const handleGoBack = () => {
    saveData((prevState) => {
      return {
        ...prevState,
        ...getValues(),
      };
    });
    goBack();
  };

  const handleFileChange = ([event]: any) => {
    if (!event) {
      return event;
    }

    const {target} = event;
    if (target?.files.length) {
      return target.files[0];
    }
  };

  const getDocumentsPayload = async (formData: Partial<FormTypes>) => {
    const creditorType = data[FORM_NAMES.creditorType]?.value;

    if (creditorType === CREDITOR_TYPES.individual) {
      const dniFrontSide = await getBase64(formData[FORM_NAMES.dniFrontSide]!);
      const dniBackSide = await getBase64(formData[FORM_NAMES.dniBackSide]!);
      const certificate = await getBase64(
        formData[FORM_NAMES.certificateOfAccountOwnership]!,
      );

      return [
        {
          type: 'PC_DNI',
          front_side_scan: dniFrontSide,
          back_side_scan: dniBackSide,
        },
        {
          type: 'PC_CAO',
          front_side_scan: certificate,
        },
      ];
    }

    if (creditorType === CREDITOR_TYPES.freelance) {
      const dniFrontSide = await getBase64(formData[FORM_NAMES.dniFrontSide]!);
      const dniBackSide = await getBase64(formData[FORM_NAMES.dniBackSide]!);
      const certificate = await getBase64(
        formData[FORM_NAMES.certificateOfAccountOwnership]!,
      );
      const freelancerReceipt = await getBase64(
        formData[FORM_NAMES.freelancerReceiptForm]!,
      );

      return [
        {
          type: 'PC_DNI',
          front_side_scan: dniFrontSide,
          back_side_scan: dniBackSide,
        },
        {
          type: 'PC_CAO',
          front_side_scan: certificate,
        },
        {
          type: 'PC_FRF',
          front_side_scan: freelancerReceipt,
        },
      ];
    }

    if (creditorType === CREDITOR_TYPES.company) {
      const dniFrontSide = await getBase64(formData[FORM_NAMES.dniFrontSide]!);
      const dniBackSide = await getBase64(formData[FORM_NAMES.dniBackSide]!);
      const certificate = await getBase64(
        formData[FORM_NAMES.certificateOfAccountOwnership]!,
      );
      const articlesOfIncorporation = await getBase64(
        formData[FORM_NAMES.articlesOfIncorporations]!,
      );

      return [
        {
          type: 'PC_DNI',
          front_side_scan: dniFrontSide,
          back_side_scan: dniBackSide,
        },
        {
          type: 'PC_AIO',
          front_side_scan: articlesOfIncorporation,
        },
        {
          type: 'PC_CIF',
          front_side_scan: certificate,
        },
      ];
    }

    return [];
  };

  const getFieldValueIfVisible = (name: FORM_NAMES) => {
    return fields.display[name] ? data[name] : undefined;
  };

  const getPayload = async (formData: Partial<FormTypes>) => {
    const documents = await getDocumentsPayload(formData);

    return {
      ...data,
      ...formData,
      documents,
      [FORM_NAMES.iban]: getFieldValueIfVisible(FORM_NAMES.iban),
      [FORM_NAMES.swift]: getFieldValueIfVisible(FORM_NAMES.swift),
      [FORM_NAMES.companyName]: getFieldValueIfVisible(FORM_NAMES.companyName),
      [FORM_NAMES.creditorType]: creditorType,
      [FORM_NAMES.dniFrontSide]: undefined,
      [FORM_NAMES.dniBackSide]: undefined,
      [FORM_NAMES.certificateOfAccountOwnership]: undefined,
      [FORM_NAMES.taxIdentificationNumber]: undefined,
      [FORM_NAMES.articlesOfIncorporations]: undefined,
      [FORM_NAMES.freelancerReceiptForm]: undefined,
    };
  };

  const refetchQueries = async () => {
    await queryCache.refetchQueries('paymentsSettings');
    await queryCache.refetchQueries('movementsPreview');
  };

  const onSubmit = async (formData: Partial<FormTypes>) => {
    let result;

    setStatus('loading');
    const payload = await getPayload(formData);

    const hasPrevSettings = Object.keys(paymentsSettings)?.length;
    if (hasPrevSettings) {
      result = await api.paymentsSettings.patch(paymentsSettings?.id, payload);
    } else {
      result = await api.paymentsSettings.post(payload);
    }

    if (!isMounted.current) {
      return;
    }

    if (result.error) {
      toastResponseError(result.error);
    } else {
      await refetchQueries();
    }
    setStatus('idle');

    if (result.data) {
      goNext();
    }
  };

  return (
    <div>
      <DocumentsVerificationTitle>
        {t('documents_upload_step_title')}
      </DocumentsVerificationTitle>
      <DocumentsVerificationSubtitle>
        {t('documents_upload_step_subtitle')}
      </DocumentsVerificationSubtitle>
      <DocumentsUploadContent>
        {fields.display[FORM_NAMES.certificateOfAccountOwnership] && (
          <FileInputWrapper>
            <Controller
              control={control}
              name={FORM_NAMES.certificateOfAccountOwnership}
              onChange={handleFileChange}
              as={<FileInput />}
              rules={{
                required: fields.required[FORM_NAMES.certificateOfAccountOwnership],
              }}
              error={errors[FORM_NAMES.certificateOfAccountOwnership]?.message}
              label={getRequiredOrOptionalFieldLabel(
                t('account_ownership_cert'),
                fields.required[FORM_NAMES.certificateOfAccountOwnership],
              )}
              accept={ACCEPTABLE_DOCUMENTS}
              defaultValue={data?.[FORM_NAMES.certificateOfAccountOwnership]}
              disabled={isLoading}
            />
          </FileInputWrapper>
        )}
        {fields.display[FORM_NAMES.articlesOfIncorporations] && (
          <FileInputWrapper>
            <Controller
              control={control}
              name={FORM_NAMES.articlesOfIncorporations}
              onChange={handleFileChange}
              as={<FileInput />}
              rules={{required: fields.required[FORM_NAMES.articlesOfIncorporations]}}
              label={getRequiredOrOptionalFieldLabel(
                t('articles_of_incorporations_and_other'),
                fields.required[FORM_NAMES.articlesOfIncorporations],
              )}
              error={errors[FORM_NAMES.articlesOfIncorporations]?.message}
              accept={ACCEPTABLE_DOCUMENTS}
              defaultValue={data?.[FORM_NAMES.articlesOfIncorporations]}
              disabled={isLoading}
            />
          </FileInputWrapper>
        )}
        {fields.display[FORM_NAMES.dniFrontSide] && (
          <FileInputWrapper>
            <Controller
              control={control}
              name={FORM_NAMES.dniFrontSide}
              onChange={handleFileChange}
              as={<FileInput />}
              rules={{required: fields.required[FORM_NAMES.dniFrontSide]}}
              label={getRequiredOrOptionalFieldLabel(
                dniFrontLabel,
                fields.required[FORM_NAMES.dniFrontSide],
              )}
              error={errors[FORM_NAMES.dniFrontSide]?.message}
              accept={ACCEPTABLE_DOCUMENTS}
              defaultValue={data?.[FORM_NAMES.dniFrontSide]}
              disabled={isLoading}
            />
          </FileInputWrapper>
        )}
        {fields.display[FORM_NAMES.taxIdentificationNumber] && (
          <FileInputWrapper>
            <Controller
              control={control}
              name={FORM_NAMES.taxIdentificationNumber}
              onChange={handleFileChange}
              as={<FileInput />}
              rules={{required: fields.required[FORM_NAMES.taxIdentificationNumber]}}
              label={getRequiredOrOptionalFieldLabel(
                t('cif_tax_id'),
                fields.required[FORM_NAMES.taxIdentificationNumber],
              )}
              error={errors[FORM_NAMES.taxIdentificationNumber]?.message}
              accept={ACCEPTABLE_DOCUMENTS}
              defaultValue={data?.[FORM_NAMES.taxIdentificationNumber]}
              disabled={isLoading}
            />
          </FileInputWrapper>
        )}
        {fields.display[FORM_NAMES.freelancerReceiptForm] && (
          <FileInputWrapper>
            <Controller
              control={control}
              name={FORM_NAMES.freelancerReceiptForm}
              onChange={handleFileChange}
              as={<FileInput />}
              rules={{required: fields.required[FORM_NAMES.freelancerReceiptForm]}}
              label={getRequiredOrOptionalFieldLabel(
                t('freelancer_receipt_form'),
                fields.required[FORM_NAMES.freelancerReceiptForm],
              )}
              error={errors[FORM_NAMES.freelancerReceiptForm]?.message}
              accept={ACCEPTABLE_DOCUMENTS}
              defaultValue={data?.[FORM_NAMES.freelancerReceiptForm]}
              disabled={isLoading}
            />
          </FileInputWrapper>
        )}
        {fields.display[FORM_NAMES.dniBackSide] && (
          <FileInputWrapper>
            <Controller
              control={control}
              name={FORM_NAMES.dniBackSide}
              onChange={handleFileChange}
              as={<FileInput />}
              rules={{required: fields.required[FORM_NAMES.dniBackSide]}}
              error={errors[FORM_NAMES.dniBackSide]?.message}
              label={getRequiredOrOptionalFieldLabel(
                dniBackLabel,
                fields.required[FORM_NAMES.dniBackSide],
              )}
              accept={ACCEPTABLE_DOCUMENTS}
              defaultValue={data?.[FORM_NAMES.dniBackSide]}
              disabled={isLoading}
            />
          </FileInputWrapper>
        )}
      </DocumentsUploadContent>
      {isLoading ? (
        <Loader height={40} width={40} label={t('loading')} />
      ) : (
        <TwoButtonsWrapper>
          <div>
            <NextButton
              disabled={paymentsSettingsStatus === 'loading'}
              label={t('next').toUpperCase()}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
          <div>
            <Button secondary label={t('back')} onClick={handleGoBack} />
          </div>
        </TwoButtonsWrapper>
      )}
    </div>
  );
}

type SuccessStepProps = {
  onOk: () => void;
};

function SuccessStep({onOk}: SuccessStepProps) {
  const {t} = useTranslation();

  return (
    <div>
      <TaxesIcon src={documentsIcon} alt="Documents" />
      <SuccessTitle>
        <img src={checkIcon} alt="Check" /> {t('uploaded')}
      </SuccessTitle>
      <SuccessText>{t('payments_docs_uploaded')}</SuccessText>
      <TwoButtonsWrapper>
        <div>
          <NextButton label={t('ok').toUpperCase()} onClick={onOk} />
        </div>
      </TwoButtonsWrapper>
    </div>
  );
}

PaymentsDocumentsVerificationModal.defaultProps = defaultProps;
export {PaymentsDocumentsVerificationModal};
