import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {queryCache, useQuery} from 'react-query';
import i18n from '../../../i18n';
import api, {queryFetcher} from '../../../api';
import {
  useErrorModal,
  useErrorToast,
  useIsMounted,
  useModalControls,
  useStatus,
} from '../../../utils/hooks';
import {useForm, Controller} from 'react-hook-form';
import {SelectOption, User} from '../../../utils/types';
import {COLLABORATOR_GROUPS, PATTERNS} from '../../../utils/constants';
import {toastResponseError} from '../../../utils/common';
import {getIsAccountCollaborator} from '../../../utils/user';
import accountIcon from '../../../assets/account.svg';
import collaboratorsIcon from '../../../assets/collaborators.svg';
import editIcon from '../../../assets/edit-button.svg';
import rubbishIcon from '../../../assets/rubbish.svg';
import deleteCollaboratorIcon from '../../../assets/icon-delete-guest.svg';
import collaboratorIcon from '../../../assets/guest-icon.svg';
import Loader from '../../common/Loader';
import Section from '../Section';
import Button from '../Button';
import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import ModalButton from '../ModalButton';
import {
  FieldWrapper,
  MissingDataText,
  ModalTwoButtonsWrapper,
} from '../../../styled/common';
import {
  Title,
  Subtitle,
  ModalContent,
  SendInvitationTwoButtonsWrapper,
  ActionButton,
  ActionButtonsWrapper,
  CollaboratorsContainer,
  CollaboratorType,
  CollaboratorName,
  CollaboratorEmailCell,
  CollaboratorsLoaderWrapper,
} from './styled';

const ACCOUNT_TYPES_OPTIONS = [
  {
    value: COLLABORATOR_GROUPS.manager,
    label: i18n.t('manager'),
  },
  {
    value: COLLABORATOR_GROUPS.collaborator,
    label: i18n.t('collaborator'),
  },
];

enum FORM_NAMES {
  email = 'email',
  accountType = 'accountType',
}

type FormTypes = {
  [FORM_NAMES.email]: string;
  [FORM_NAMES.accountType]: SelectOption;
};

function fetchCollaborators() {
  return queryFetcher(api.users.ENDPOINTS.collaborators('active_only=false'));
}

function getCollaboratorTypeText(collaborator: User | null) {
  if (!collaborator?.is_active) {
    return null;
  }

  if (getIsAccountCollaborator(collaborator)) {
    return i18n.t('collaborator');
  }
  return i18n.t('manager');
}

function AccountTeamSection() {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {register, control, errors, handleSubmit} = useForm<FormTypes>();
  const {isLoading, setStatus} = useStatus();
  const {ErrorModal, displayError} = useErrorModal();

  const {
    isOpen: isAddCollaboratorModalOpen,
    closeModal: closeAddCollaboratorModal,
    openModal: openAddCollaboratorModal,
  } = useModalControls();
  const {
    isOpen: isRemoveCollaboratorModalOpen,
    closeModal: closeRemoveCollaboratorModal,
    openModal: openRemoveCollaboratorModal,
  } = useModalControls();
  const {
    isOpen: isChangeCollaboratorTypeModalOpen,
    closeModal: closeChangeCollaboratorTypeModal,
    openModal: openChangeCollaboratorTypeModal,
  } = useModalControls();

  const [activeCollaborator, setActiveCollaborator] = React.useState<User | null>(null);

  const {data: collaborators, error, status} = useQuery<User[], string>(
    'collaborators',
    fetchCollaborators,
  );
  useErrorToast(error, {
    notFoundMessage: t('errors.requested_collaborators_not_found'),
  });

  const getCollaboratorPayload = (formData: FormTypes) => {
    return {
      ...formData,
      [FORM_NAMES.accountType]: undefined,
      groups: [formData[FORM_NAMES.accountType]?.value],
    };
  };

  const setActiveCollaboratorAndOpenRemoveModal = (collaborator: User) => {
    setActiveCollaborator(collaborator);
    openRemoveCollaboratorModal();
  };

  const resetActiveCollaboratorAndCloseRemoveModal = () => {
    setActiveCollaborator(null);
    closeRemoveCollaboratorModal();
  };

  const removeCollaborator = async () => {
    if (!activeCollaborator?.email) {
      displayError({message: 'Collaborator email is missing.'});
      return;
    }

    setStatus('loading');

    const {error} = await api.auth.removeCollaborator(activeCollaborator.email);
    if (!isMounted.current) {
      return;
    }

    if (error) {
      setStatus('idle');
      toastResponseError(error);
      return;
    }

    await queryCache.refetchQueries('collaborators');
    if (!isMounted.current) {
      return;
    }

    setStatus('idle');
    resetActiveCollaboratorAndCloseRemoveModal();
  };

  const setActiveCollaboratorAndOpenChangeCollaboratorTypeModal = (
    collaborator: User,
  ) => {
    setActiveCollaborator(collaborator);
    openChangeCollaboratorTypeModal();
  };

  const resetActiveCollaboratorAndCloseChangeCollaboratorTypeModal = () => {
    setActiveCollaborator(null);
    closeChangeCollaboratorTypeModal();
  };

  const getChangeCollaboratorTypePayload = () => {
    return {
      email: activeCollaborator!.email,
      groups: getIsAccountCollaborator(activeCollaborator!)
        ? [COLLABORATOR_GROUPS.manager]
        : [COLLABORATOR_GROUPS.collaborator],
    };
  };

  const changeCollaboratorType = async () => {
    setStatus('loading');

    const payload = getChangeCollaboratorTypePayload();
    const {error} = await api.auth.updateCollaborator(payload);
    if (!isMounted.current) {
      return;
    }

    if (error) {
      setStatus('idle');
      toastResponseError(error);
      return;
    }

    await queryCache.refetchQueries('collaborators');
    if (!isMounted.current) {
      return;
    }

    setStatus('idle');
    resetActiveCollaboratorAndCloseChangeCollaboratorTypeModal();
  };

  const createCollaborator = async (formData: FormTypes) => {
    setStatus('loading');

    const payload = getCollaboratorPayload(formData);
    const {error} = await api.auth.createCollaborator(payload);
    if (!isMounted.current) {
      return;
    }

    if (error) {
      setStatus('idle');
      toastResponseError(error);
      return;
    }

    await queryCache.refetchQueries('collaborators');
    if (!isMounted.current) {
      return;
    }

    setStatus('idle');
    closeAddCollaboratorModal();
  };

  const onSubmit = (formData: FormTypes) => {
    createCollaborator(formData);
  };

  return (
    <>
      <Section
        showTooltip
        title={t('team')}
        subtitle={t('as_owner_you_can')}
        tooltipContent={
          <Trans i18nKey="you_have_to_select_between_two_types_of_accounts">
            As owner, you can add team members to your account. You have to select between
            two types of accounts:
            <p />
            <b>Manager</b>: They have full access to the platform, except edit or cancel
            the subscription or delete the account.
            <p />
            <b>Collaborator</b>: They have limited access to the platform, only to
            reservations assigned. Collaborators cannot edit or delete account settings
            such as billing or properties.
          </Trans>
        }
      >
        <>
          <Title>{t('members')}</Title>
          {status === 'loading' ? (
            <CollaboratorsLoaderWrapper>
              <Loader label={t('loading')} />
            </CollaboratorsLoaderWrapper>
          ) : Boolean(collaborators?.length) ? (
            <CollaboratorsContainer>
              {collaborators!.map((collaborator) => {
                return (
                  <React.Fragment key={collaborator.id}>
                    <CollaboratorName>
                      <div>
                        {collaborator.name}
                        <div>
                          <CollaboratorEmailCell href={`mailto:${collaborator.email}`}>
                            {collaborator.email}
                          </CollaboratorEmailCell>
                        </div>
                      </div>
                    </CollaboratorName>
                    <CollaboratorType>
                      {getCollaboratorTypeText(collaborator) || (
                        <MissingDataText>({t('invitation_sent')})</MissingDataText>
                      )}
                    </CollaboratorType>
                    <ActionButtonsWrapper>
                      {Boolean(getCollaboratorTypeText(collaborator)) && (
                        <ActionButton
                          secondary
                          onClick={() =>
                            setActiveCollaboratorAndOpenChangeCollaboratorTypeModal(
                              collaborator,
                            )
                          }
                          label={<img src={editIcon} alt="Pen" />}
                        />
                      )}
                      <ActionButton
                        secondary
                        onClick={() =>
                          setActiveCollaboratorAndOpenRemoveModal(collaborator)
                        }
                        label={<img src={rubbishIcon} alt="Rubbish" />}
                      />
                    </ActionButtonsWrapper>
                  </React.Fragment>
                );
              })}
            </CollaboratorsContainer>
          ) : (
            <Subtitle>{t('you_have_no_team_members')}</Subtitle>
          )}
          <Button
            secondary
            onClick={openAddCollaboratorModal}
            label={
              <>
                <img src={accountIcon} alt="Account" />
                {t('invite_team_member')}
              </>
            }
          />
        </>
      </Section>
      {isAddCollaboratorModalOpen && (
        <Modal
          closeOnDocumentClick
          closeOnEscape
          iconSrc={collaboratorsIcon}
          iconAlt="Collaborators"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={t('add_team_member')}
          onClose={closeAddCollaboratorModal}
        >
          <ModalContent>
            <FieldWrapper>
              <Input
                label={t('email')}
                placeholder={t('enter_email')}
                name={FORM_NAMES.email}
                inputMode="email"
                ref={register({
                  required: t('required') as string,
                  pattern: {
                    value: PATTERNS.email,
                    message: t('invalid_email'),
                  },
                })}
                type="email"
                error={errors[FORM_NAMES.email]?.message}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Controller
                control={control}
                label={t('type_of_account')}
                placeholder={t('select_type_of_account')}
                name={FORM_NAMES.accountType}
                options={ACCOUNT_TYPES_OPTIONS}
                as={<Select />}
                error={(errors[FORM_NAMES.accountType] as any)?.message}
                rules={{required: t('required') as string}}
              />
            </FieldWrapper>
            <SendInvitationTwoButtonsWrapper>
              {isLoading ? (
                <Loader height={40} width={40} />
              ) : (
                <>
                  <ModalButton
                    onClick={handleSubmit(onSubmit)}
                    label={t('send_invitation')}
                  />
                  <ModalButton
                    secondary
                    label={t('cancel')}
                    onClick={closeAddCollaboratorModal}
                  />
                </>
              )}
            </SendInvitationTwoButtonsWrapper>
          </ModalContent>
        </Modal>
      )}
      {isRemoveCollaboratorModalOpen && (
        <Modal
          closeOnDocumentClick
          closeOnEscape
          onClose={resetActiveCollaboratorAndCloseRemoveModal}
          iconSrc={deleteCollaboratorIcon}
          iconAlt="A person in the trash"
          iconProps={{
            height: 95,
            width: 84,
          }}
          title={t('are_you_sure')}
          text={
            <>
              {activeCollaborator?.name ? (
                <Trans
                  i18nKey="your_team_member_name_will_be_removed"
                  values={{name: activeCollaborator.name}}
                >
                  Your team member <b>Name</b> will be removed.
                </Trans>
              ) : (
                t('your_inactive_team_member_will_be_removed')
              )}
            </>
          }
        >
          <ModalTwoButtonsWrapper>
            {isLoading ? (
              <Loader height={40} width={40} />
            ) : (
              <>
                <ModalButton label={t('remove')} onClick={removeCollaborator} />
                <ModalButton
                  secondary
                  label={t('cancel')}
                  onClick={resetActiveCollaboratorAndCloseRemoveModal}
                />
              </>
            )}
          </ModalTwoButtonsWrapper>
        </Modal>
      )}
      {isChangeCollaboratorTypeModalOpen && (
        <Modal
          closeOnDocumentClick
          closeOnEscape
          iconSrc={collaboratorIcon}
          iconAlt="A person"
          iconProps={{
            height: 84,
            width: 84,
          }}
          onClose={closeChangeCollaboratorTypeModal}
          title={getCollaboratorTypeText(activeCollaborator)}
          text={
            <Trans
              i18nKey="your_team_member_name_is_type"
              values={{
                name: activeCollaborator?.name,
                type: getCollaboratorTypeText(activeCollaborator),
              }}
            >
              Your team member <b>Name</b> is a <b>Type</b>
            </Trans>
          }
        >
          <ModalTwoButtonsWrapper>
            {isLoading ? (
              <Loader height={40} width={40} />
            ) : (
              <>
                <ModalButton
                  label={
                    getIsAccountCollaborator(activeCollaborator!)
                      ? t('make_a_manager')
                      : t('make_a_collaborator')
                  }
                  onClick={changeCollaboratorType}
                />
                <ModalButton
                  secondary
                  label={t('cancel')}
                  onClick={resetActiveCollaboratorAndCloseChangeCollaboratorTypeModal}
                />
              </>
            )}
          </ModalTwoButtonsWrapper>
        </Modal>
      )}
      <ErrorModal />
    </>
  );
}

export {AccountTeamSection};
