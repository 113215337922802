import React from 'react';
import {useHistory} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {FormContext, useForm} from 'react-hook-form';
import {format, isValid} from 'date-fns';
import {queryCache} from 'react-query';
import api from '../../../api';
import {Housing, Room, SelectOption, TempLock} from '../../../utils/types';
import {
  COUNTRIES_WITH_STAT,
  LOCK_ACCESS_TYPES,
  POLICE_TYPES,
  SECURITY_DEPOSIT_STATUSES,
} from '../../../utils/constants';
import {scrollToTop} from '../../../utils/common';
import {
  buildExternalSourcesPayload,
  buildHousingPayload,
  buildPolicePayload,
  buildStatPayload,
} from '../../../utils/housing';
import {useComputedDetails} from '../../../context/computedDetails';
import {useUser} from '../../../context/user';
import {
  useErrorModal,
  useIsMounted,
  useModalControls,
  usePrevious,
  useScrollToTop,
  useStatus,
} from '../../../utils/hooks';
import {useConfirmLeaveModal} from '../../../context/openModals';
import {useSubscription} from '../../../context/subscription';
import propertyIcon from '../../../assets/icon-property.svg';
import missingDataIcon from '../../../assets/icon-data-missing.svg';
import floppyDiskIcon from '../../../assets/floppy-disk.svg';
import HousingICalsSection, {
  FORM_NAMES as ICALS_FORM_NAMES,
} from '../HousingICalsSection';
import Button from '../Button';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import BackButton from '../BackButton';
import YouHaveMadeChangesModal from '../YouHaveMadeChangesModal';
import HousingSelfCheckinSection from '../HousingSelfCheckinSection';
import HousingOnlineCheckInSection from '../HousingOnlineCheckInSection';
import HousingSpacesSection from '../HousingSpacesSection';
import HousingInfoSection from '../HousingInfoSection';
import HousingPoliceConnectionSection from '../HousingPoliceConnectionSection';
import HousingStatConnectionSection from '../HousingStatConnectionSection';
import HousingContractsSection from '../HousingContractsSection';
import HousingTaxesSection, {
  FORM_NAMES as TAXES_FORM_NAMES,
} from '../HousingTaxesSection';
import HousingSecurityDepositSection from '../HousingSecurityDepositSection';
import {Heading, ModalTwoButtonsWrapper} from '../../../styled/common';
import {
  BottomButtonWrapper,
  ButtonLabelIcon,
  ButtonLabelText,
  ButtonLabelWrapper,
  Content,
  ModalButtonWrapper,
  Title,
} from './styled';

const IS_UNDER_MAINTENANCE = process.env.REACT_APP_MAINTENANCE_MODE;

export enum FORM_NAMES {
  name = 'name',
  country = 'country',
  province = 'province',
  address = 'address',
  city = 'city',
  vatin = 'vatin',
  contract_type = 'contract_type',
  tourism_registration_number = 'tourism_registration_number',
  manager_name = 'manager_name',
  manager_phone = 'manager_phone',
  manager_birth_date = 'manager_birth_date',
  rooms_quantity = 'rooms_quantity',
  number_of_spaces = 'number_of_spaces',
  depositAmount = 'security_deposit_amount',
  email_language = 'default_email_language',
}

type FormTypes = {
  [FORM_NAMES.name]: string;
  [FORM_NAMES.country]: SelectOption;
  [FORM_NAMES.province]: SelectOption;
  [FORM_NAMES.address]: string;
  [FORM_NAMES.city]: string;
  [FORM_NAMES.vatin]: string;
  [FORM_NAMES.contract_type]: SelectOption;
  [FORM_NAMES.tourism_registration_number]: string;
  [FORM_NAMES.manager_name]: string;
  [FORM_NAMES.manager_phone]: string;
  [FORM_NAMES.manager_birth_date]: Date;
  [FORM_NAMES.rooms_quantity]: number;
  [FORM_NAMES.number_of_spaces]: number;
  [FORM_NAMES.depositAmount]: string;
};

type OnlineCheckInSectionRefType = {
  active: boolean;
  data: {
    [key: string]: boolean;
  };
};

type SpacesSectionRefType = {
  active: boolean;
};

type ContractsSectionRefType = {
  active: boolean;
  extraClauses: [];
  signature: string;
};

type PoliceConnectionSectionRefType = {
  active: boolean;
  submit: () => boolean;
  getValues: () => {
    [key: string]: string | SelectOption;
  };
};

type StatConnectionSectionRefType = {
  active: boolean;
  submit: () => boolean;
  getValues: () => {
    [key: string]: string | SelectOption;
  };
};

type SelfCheckinSectionRefType = {
  active: boolean;
  tempLocks: TempLock[];
  lockUser: SelectOption | null;
  data: {
    [key: string]: boolean;
  };
};

type TaxesSectionRefType = {
  getHighSeasonPayload: () => any;
  getLowSeasonPayload: () => any;
  active: boolean;
};

type DepositsSectionRefType = {
  active: boolean;
  depositStatus: SECURITY_DEPOSIT_STATUSES;
};

function AddHousingSections() {
  useScrollToTop();
  const {t} = useTranslation();
  const user = useUser();
  const history = useHistory();
  const isMounted = useIsMounted();
  const formMethods = useForm<FormTypes>();
  const formState = formMethods.formState;
  const {isHotelSubscription} = useSubscription();
  const {ErrorModal, displayError} = useErrorModal();
  const {
    openModal: openExportTaxesModal,
    closeModal: closeExportTaxesModal,
    isOpen: isExportTaxesModalOpen,
  } = useModalControls();
  const {isLoading, setStatus, resetStatus} = useStatus();
  const {isAccountCollaborator, isNeedToAskForSubscription} = useComputedDetails();
  const prevFormState = usePrevious<typeof formState>(formState);
  const policeViewRef = React.useRef<HTMLDivElement>(null);
  const statViewRef = React.useRef<HTMLDivElement>(null);
  const [isContractsSectionActive, setIsContractsSectionActive] = React.useState(false);
  const [isHousingModalOpen, setIsHousingModalOpen] = React.useState(false);
  const [wasDataIncompleteModalOpened, setWasDataIncompleteModalOpened] = React.useState(
    false,
  );
  const [isDataIncompleteModalOpen, setIsDataIncompleteModalOpen] = React.useState(false);
  const [isCreatingLocks, setIsCreatingLocks] = React.useState(false);
  const [createdHousingId, setCreatedHousingId] = React.useState('');
  const [isTaxesSectionTouched, setIsTaxesSectionTouched] = React.useState(false);
  const [rooms, setRooms] = React.useState<Room[]>([]);

  const [isInfoSectionTouched, setIsInfoSectionTouched] = React.useState(false);
  const [isPoliceSectionTouched, setIsPoliceSectionTouched] = React.useState(false);
  const [isStatSectionTouched, setIsStatSectionTouched] = React.useState(false);
  const [isContractsSectionTouched, setIsContractsSectionTouched] = React.useState(false);
  const [isICalsSectionTouched, setIsICalsSectionTouched] = React.useState(false);
  const [isSelfCheckinSectionTouched, setIsSelfCheckinSectionTouched] = React.useState(
    false,
  );
  const [setIsSpacesSectionTouched, setSetIsSpacesSectionTouched] = React.useState(false);
  const [
    isOnlineCheckInSectionTouched,
    setIsOnlineCheckInSectionTouched,
  ] = React.useState(false);

  const isAnySectionTouched =
    isInfoSectionTouched ||
    isPoliceSectionTouched ||
    isStatSectionTouched ||
    isContractsSectionTouched ||
    isICalsSectionTouched ||
    isOnlineCheckInSectionTouched ||
    setIsSpacesSectionTouched ||
    isSelfCheckinSectionTouched;

  const housingName = formMethods.watch(FORM_NAMES.name) || t('new_property');
  const country = formMethods.watch(FORM_NAMES.country)?.value || '';
  const numberOfSpaces = formMethods.watch(FORM_NAMES.number_of_spaces) || 0;

  const onlineCheckInSectionRef = React.useRef<OnlineCheckInSectionRefType>({
    active: false,
    data: {},
  });
  const spacesSectionRef = React.useRef<SpacesSectionRefType>({
    active: false,
  });
  const contractsSectionRef = React.useRef<ContractsSectionRefType>({
    active: false,
    extraClauses: [],
    signature: '',
  });
  const policeConnectionSectionRef = React.useRef<PoliceConnectionSectionRefType>({
    active: false,
    submit: () => false,
    getValues: () => ({}),
  });
  const statConnectionSectionRef = React.useRef<StatConnectionSectionRefType>({
    active: false,
    submit: () => false,
    getValues: () => ({}),
  });
  const selfCheckinSectionRef = React.useRef<SelfCheckinSectionRefType>({
    active: false,
    data: {},
    tempLocks: [],
    lockUser: null,
  });
  const taxesSectionRef = React.useRef<TaxesSectionRefType>({
    getHighSeasonPayload: () => {},
    getLowSeasonPayload: () => {},
    active: false,
  });
  const depositsSectionRef = React.useRef<DepositsSectionRefType>({
    active: false,
    depositStatus: SECURITY_DEPOSIT_STATUSES.optional,
  });

  const openIncompleteModal = React.useCallback(() => {
    if (!wasDataIncompleteModalOpened) {
      setIsDataIncompleteModalOpen(true);
      setWasDataIncompleteModalOpened(true);
    }
  }, [wasDataIncompleteModalOpened]);

  React.useEffect(() => {
    if (IS_UNDER_MAINTENANCE) {
      history.push('/properties');
    }
  }, [history]);

  React.useLayoutEffect(
    function showIncompleteModalOneTime() {
      const shouldOpenModal =
        formState.isSubmitted && !prevFormState?.isValid && !formState.isValid;

      if (shouldOpenModal) {
        openIncompleteModal();
      }
    },
    [
      formState.isSubmitted,
      formState.isValid,
      prevFormState,
      wasDataIncompleteModalOpened,
      openIncompleteModal,
    ],
  );

  React.useEffect(
    function redirect() {
      const isAccessDenied = isNeedToAskForSubscription || isAccountCollaborator;
      if (isAccessDenied) {
        history.push('/bookings');
      }
    },
    [history, isAccountCollaborator, isNeedToAskForSubscription],
  );

  const goToHousings = () => {
    history.push('/properties');
  };

  const goToCreatedHousing = () => {
    history.push(`/properties/${createdHousingId}`);
  };

  const openHousingModal = () => {
    setIsHousingModalOpen(true);
  };

  const closeHousingModal = () => {
    setIsHousingModalOpen(false);
  };

  const getPolicePayload = () => {
    const data = policeConnectionSectionRef.current?.getValues();
    const isActive = POLICE_TYPES[country] && policeConnectionSectionRef.current?.active;

    if (!isActive) {
      return {};
    }
    return buildPolicePayload(data);
  };

  const getStatPayload = () => {
    const data = statConnectionSectionRef.current?.getValues();
    const isActive =
      COUNTRIES_WITH_STAT.includes(country) && statConnectionSectionRef.current?.active;

    if (!isActive) {
      return {};
    }
    return buildStatPayload(data);
  };

  const getContractsPayload = (data: FormTypes) => {
    const isActive = contractsSectionRef.current?.active;

    if (!isActive) {
      return {};
    }

    return {
      is_contract_enabled: true,
      manager_signature: contractsSectionRef.current?.signature || undefined,
      extra_clauses: contractsSectionRef.current?.extraClauses,
      [FORM_NAMES.manager_birth_date]: isValid(data[FORM_NAMES.manager_birth_date])
        ? format(data[FORM_NAMES.manager_birth_date], 'yyyy-MM-dd')
        : undefined,
      [FORM_NAMES.contract_type]: (data[FORM_NAMES.contract_type] as SelectOption)?.value,
      [FORM_NAMES.manager_name]: data[FORM_NAMES.manager_name] || undefined,
    };
  };

  const getSelfCheckinRemindersPayload = () => {
    const isActive = selfCheckinSectionRef.current?.active;
    const data = selfCheckinSectionRef.current?.data;

    if (!isActive && data) {
      let inactiveData: {[key: string]: boolean} = {};
      Object.keys(data).forEach((key: string) => {
        inactiveData[key] = false;
      });

      return inactiveData;
    }
    return data;
  };

  const getCheckInOnlineRemindersPayload = () => {
    const isActive = onlineCheckInSectionRef.current?.active;

    if (!isActive) {
      return {};
    }
    return onlineCheckInSectionRef.current?.data;
  };

  const getICalsPayload = (data: any) => {
    const isActive =
      data[ICALS_FORM_NAMES.booking] ||
      data[ICALS_FORM_NAMES.airbnb] ||
      data[ICALS_FORM_NAMES.other];

    if (!isActive) {
      return {};
    }
    return buildExternalSourcesPayload(data);
  };

  const getHousingPayload = (data: FormTypes) => {
    const payload = buildHousingPayload(data, country);

    return {
      ...payload,
      is_self_online_check_in_enabled: selfCheckinSectionRef.current?.active,
    };
  };

  const getRoomsPayload = () => {
    const isActive = spacesSectionRef.current?.active;
    if (!isActive) {
      return {
        rooms: [],
      };
    }

    const roomsWithDefaultNumbers = rooms.map((room: Room, i) => {
      if (!room.number) {
        return {
          ...room,
          number: `Space ${i + 1}`,
        };
      }

      return room;
    });

    return {rooms: roomsWithDefaultNumbers};
  };

  const getTaxesPayload = () => {
    const result: {[key: string]: undefined} = {};

    if (!taxesSectionRef?.current?.active) {
      return {};
    }

    Object.keys(TAXES_FORM_NAMES).forEach((key) => {
      result[key] = undefined;
    });
    return result;
  };

  const getDepositsPayload = (data: FormTypes) => {
    const isActive = depositsSectionRef.current?.active;
    const securityDepositStatus = depositsSectionRef.current?.depositStatus;

    if (!isActive) {
      return {
        security_deposit_status: SECURITY_DEPOSIT_STATUSES.inactive,
      };
    }

    return {
      security_deposit_status: securityDepositStatus,
      [FORM_NAMES.depositAmount]: data[FORM_NAMES.depositAmount],
    };
  };

  const getPayload = (data: FormTypes) => {
    return {
      ...getHousingPayload(data),
      ...getICalsPayload(data),
      ...getPolicePayload(),
      ...getStatPayload(),
      ...getContractsPayload(data),
      ...getCheckInOnlineRemindersPayload(),
      ...getSelfCheckinRemindersPayload(),
      ...getRoomsPayload(),
      ...getTaxesPayload(),
      ...getDepositsPayload(data),
    };
  };

  const handleResponseError = (error: any) => {
    if (!isMounted.current) {
      return;
    }

    closeHousingModal();
    setStatus('error');
    displayError(error);
  };

  const createLocks = async (
    tempLocks: TempLock[],
    housingId: string,
    housingRooms: Room[] = [],
  ) => {
    let hasError: any = null;

    for await (let lock of tempLocks) {
      if (!hasError && isMounted.current) {
        const isLockAssignedToAJustCreatedRoom =
          lock.access_type === LOCK_ACCESS_TYPES.private && !lock?.room_id;
        const payload = {
          ...lock,
          housing: housingId,
        };

        if (isLockAssignedToAJustCreatedRoom) {
          const lockRoom = housingRooms.find((room) => {
            return room?.number === lock?.room_number;
          });

          if (!lockRoom?.id) {
            return;
          }

          payload.room_id = lockRoom?.id;
        }

        const {error} = await api.locks.createLock(payload);
        hasError = error;
      }
    }
    return hasError;
  };

  const submitTempLocks = async (housingId: string, housingRooms: Room[] = []) => {
    const active = selfCheckinSectionRef.current?.active;
    const lockUser = selfCheckinSectionRef.current?.lockUser;
    const tempLocks = selfCheckinSectionRef.current?.tempLocks;

    if (!active || !tempLocks?.length || !lockUser) {
      return;
    }

    setIsCreatingLocks(true);

    const error = await createLocks(tempLocks, housingId, housingRooms);
    if (error) {
      return error;
    }

    setIsCreatingLocks(false);
  };

  const createHousing = async (housingData: FormTypes) => {
    const payload = getPayload(housingData);

    openHousingModal();
    const {error, data} = await api.housings.post(payload);

    if (!isMounted.current) {
      return null;
    }

    if (error) {
      handleResponseError(error);
      return null;
    }

    if (data) {
      queryCache.setQueryData([`housing`, data.id], data);

      const housingRooms = data?.rooms;
      const locksError = await submitTempLocks(data.id, housingRooms);
      if (locksError) {
        handleResponseError(locksError);
        return;
      }

      if (data) {
        const createdId = data?.id || '';
        setCreatedHousingId(createdId);
      }

      await queryCache.refetchQueries('housings');
      await queryCache.refetchQueries('upcomingInvoices');
      return data;
    }
  };

  const validatePoliceAndGetResult = async () => {
    policeViewRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    const isValid = await policeConnectionSectionRef.current.submit();

    if (!isValid) {
      setStatus('error');
    }
    return isValid;
  };

  const validateStatAndGetResult = async () => {
    statViewRef.current?.scrollIntoView({behavior: 'smooth'});
    const isValid = await statConnectionSectionRef.current.submit();

    if (!isValid) {
      setStatus('error');
    }
    return isValid;
  };

  const createSeason = async (payload: any) => {
    const result = await api.seasons.post(payload);

    if (result.error && isMounted.current) {
      displayError(result.error);
    }
    return result;
  };

  const createHighSeason = async (housingId: string) => {
    const payload = taxesSectionRef?.current?.getHighSeasonPayload();
    const result = await createSeason({...payload, housing_id: housingId});

    if (result.data?.id) {
      queryCache.setQueryData(['highSeason', result.data?.id], result.data);
    }
    return result;
  };

  const createLowSeason = async (housingId: string) => {
    const payload = taxesSectionRef?.current?.getLowSeasonPayload();
    if (!payload) {
      return {
        error: null,
        data: null,
      };
    }

    const result = await createSeason({...payload, housing_id: housingId});

    if (result.data?.id) {
      queryCache.setQueryData(['lowSeason', result.data.id], result.data);
    }
    return result;
  };

  const createSeasons = async (housingId: string) => {
    const highSeasonResult = await createHighSeason(housingId);
    if (highSeasonResult.error) {
      return highSeasonResult.error;
    }

    const lowSeasonResult = await createLowSeason(housingId);
    if (lowSeasonResult.error) {
      return lowSeasonResult.error;
    }

    queryCache.setQueryData([`housing`, housingId], (prevData?: Housing) => {
      if (!prevData) {
        return prevData;
      }

      const seasons = [];
      const highSeasonId = highSeasonResult?.data?.id;
      const lowSeasonId = lowSeasonResult?.data?.id;

      if (highSeasonId) {
        seasons.push(highSeasonId);
      }
      if (lowSeasonId) {
        seasons.push(lowSeasonId);
      }

      return {
        ...prevData,
        seasons,
      };
    });

    return null;
  };

  const saveSeasons = async (housingId: string) => {
    if (!taxesSectionRef?.current?.active) {
      setStatus('success');
      return;
    }

    const creationError = await createSeasons(housingId);
    if (creationError) {
      setStatus('idle');
      return;
    }

    setStatus('success');
  };

  const {
    goThroughConfirm,
    handleModalSave,
    handleModalDontSave,
    handleModalCancel,
    isDoYouWantToSaveModalOpen,
  } = useConfirmLeaveModal(isAnySectionTouched);

  const onSubmit = async (data: FormTypes) => {
    const isPoliceSectionActive =
      POLICE_TYPES[country] && policeConnectionSectionRef.current?.active;
    const isStatSectionActive =
      COUNTRIES_WITH_STAT.includes(country) && statConnectionSectionRef.current?.active;
    let isPoliceAccountValid = false;
    let isStatAccountValid = false;

    scrollToTop();
    setStatus('loading');

    if (isPoliceSectionActive) {
      isPoliceAccountValid = await validatePoliceAndGetResult();

      if (!isPoliceAccountValid) {
        resetStatus();
        return;
      }
    }

    const canValidateStat = isPoliceSectionActive
      ? isPoliceAccountValid && isStatSectionActive
      : isStatSectionActive;
    if (canValidateStat) {
      isStatAccountValid = await validateStatAndGetResult();

      if (!isStatAccountValid) {
        resetStatus();
        return;
      }
    }

    const housingData = await createHousing(data);

    const housingId = housingData?.id || '';
    await saveSeasons(housingId);
  };

  return (
    <>
      <ErrorModal />
      <Content>
        <FormContext {...formMethods}>
          <div>
            <Heading>
              <div>
                <BackButton onClick={() => goThroughConfirm('/properties')} />
              </div>
              <Title>{housingName}</Title>
              <div>
                <Button
                  onClick={formMethods.handleSubmit(onSubmit)}
                  disabled={isLoading}
                  label={
                    <ButtonLabelWrapper>
                      <ButtonLabelIcon src={floppyDiskIcon} alt="Plus" />
                      <ButtonLabelText>{t('save_property')}</ButtonLabelText>
                    </ButtonLabelWrapper>
                  }
                />
              </div>
            </Heading>
            <HousingInfoSection
              setIsSectionTouched={setIsInfoSectionTouched}
              isContractsSectionActive={isContractsSectionActive}
              disabled={isLoading}
              spacesCount={numberOfSpaces}
            />
            <div ref={policeViewRef}>
              <HousingPoliceConnectionSection
                setIsSectionTouched={setIsPoliceSectionTouched}
                country={country}
                ref={policeConnectionSectionRef}
                disabled={isLoading}
                openIncompleteModal={openIncompleteModal}
              />
            </div>
            <div ref={statViewRef}>
              <HousingStatConnectionSection
                setIsSectionTouched={setIsStatSectionTouched}
                country={country}
                ref={statConnectionSectionRef}
                disabled={isLoading}
                openIncompleteModal={openIncompleteModal}
              />
            </div>
            {isHotelSubscription && (
              <HousingSpacesSection
                setIsSectionTouched={setSetIsSpacesSectionTouched}
                ref={spacesSectionRef}
                disabled={isLoading}
                rooms={rooms}
                setRooms={setRooms}
              />
            )}
            <HousingOnlineCheckInSection
              setIsSectionTouched={setIsOnlineCheckInSectionTouched}
              ref={onlineCheckInSectionRef}
              disabled={isLoading}
            />
            <HousingSelfCheckinSection
              rooms={getRoomsPayload().rooms}
              setIsSectionTouched={setIsSelfCheckinSectionTouched}
              ref={selfCheckinSectionRef}
              disabled={isLoading}
            />
            <HousingContractsSection
              setIsSectionTouched={setIsContractsSectionTouched}
              ref={contractsSectionRef}
              setIsContractsSectionActive={setIsContractsSectionActive}
              disabled={isLoading}
              country={country}
            />
            <HousingTaxesSection
              ref={taxesSectionRef}
              disabled={isLoading}
              closeExportTaxesModal={closeExportTaxesModal}
              openExportTaxesModal={openExportTaxesModal}
              isExportTaxesModalOpen={isExportTaxesModalOpen}
              setIsSectionTouched={setIsTaxesSectionTouched}
              isSectionTouched={isTaxesSectionTouched}
              country={country}
            />
            <HousingSecurityDepositSection
              ref={depositsSectionRef}
              disabled={isLoading}
            />
            {user?.is_icals_allowed && (
              <HousingICalsSection
                setIsSectionTouched={setIsICalsSectionTouched}
                disabled={isLoading}
              />
            )}
            <BottomButtonWrapper>
              <Button
                onClick={formMethods.handleSubmit(onSubmit)}
                disabled={isLoading}
                label={
                  <ButtonLabelWrapper>
                    <ButtonLabelIcon src={floppyDiskIcon} alt="Plus" />
                    <ButtonLabelText>{t('save_property')}</ButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
            </BottomButtonWrapper>
          </div>
        </FormContext>
      </Content>
      {isDoYouWantToSaveModalOpen && (
        <YouHaveMadeChangesModal
          handleModalSave={() => handleModalSave(formMethods.handleSubmit(onSubmit))}
          handleModalDontSave={handleModalDontSave}
          handleModalCancel={handleModalCancel}
        />
      )}
      {isDataIncompleteModalOpen && (
        <Modal
          open
          iconSrc={missingDataIcon}
          iconAlt="Form with red fields"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={t('data_missing')}
          text={
            <>
              {t('you_cant_add_this_property_until')}
              <p />
              {t('we_have_outlined_fields')}
            </>
          }
        >
          <ModalButtonWrapper>
            <ModalButton
              label={t('ok')}
              onClick={() => setIsDataIncompleteModalOpen(false)}
            />
          </ModalButtonWrapper>
        </Modal>
      )}
      {isHousingModalOpen && (
        <Modal
          open
          iconSrc={propertyIcon}
          iconAlt="Property"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={isLoading ? t('creating_property') : t('property_created')}
          text={
            isCreatingLocks ? (
              `${t('creating_locks')}...`
            ) : isLoading ? (
              `${t('it_takes_seconds')}...`
            ) : (
              <Trans values={{name: housingName}} i18nKey="your_property_name_added">
                Your housing <b>123</b> has been added successfully!
              </Trans>
            )
          }
        >
          {!isLoading && (
            <ModalTwoButtonsWrapper>
              {createdHousingId && (
                <ModalButton label={t('view_my_property')} onClick={goToCreatedHousing} />
              )}
              <ModalButton
                secondary
                label={t('go_to_properties')}
                onClick={goToHousings}
              />
            </ModalTwoButtonsWrapper>
          )}
        </Modal>
      )}
      <ErrorModal />
    </>
  );
}

export {AddHousingSections};
