import React from 'react';
import {useTranslation} from 'react-i18next';
import {SelectOption} from '../../../utils/types';
import {toastResponseError} from '../../../utils/common';
import searchGuestIcon from '../../../assets/icon-search-guest.svg';
import api from '../../../api';
import Modal from '../Modal';
import ModalButton from '../ModalButton';
import {ButtonsWrapper, StyledAsyncSelect} from './styled';

function getSearchParams(query = '') {
  return `name=${query}`;
}

type SearchHousingsModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: SelectOption) => void;
};

const defaultProps: SearchHousingsModalProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

function SearchGuestLeaderModal({open, onClose, onSubmit}: SearchHousingsModalProps) {
  const {t} = useTranslation();
  const [query, setQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasResults, setHasResults] = React.useState(false);
  const [option, setOption] = React.useState<SelectOption | null>(null);

  const loadHousingsOptions = async (value: string) => {
    setIsLoading(true);
    const {data, error} = await api.guests.getGuestLeaders(getSearchParams(value));
    setIsLoading(false);

    if (data) {
      if (!data.length) {
        setHasResults(false);
      } else {
        setHasResults(true);
      }

      return data.map((h: any) => {
        return {
          label: h.full_name,
          value: h.full_name,
        };
      });
    }

    if (error) {
      toastResponseError(error);
    }
  };

  const handleInputChange = (value = '') => {
    setQuery(value);
  };

  const handleClose = () => {
    setOption(null);
    onClose();
  };

  const handleSubmit = () => {
    if (option) {
      onSubmit(option);
      handleClose();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onSubmit({
        value: query,
        label: query,
      });
      handleClose();
    }
  };

  return (
    <Modal
      closeOnEscape
      closeOnDocumentClick
      open={open}
      onClose={onClose}
      title={t('search_by_guest_leader')}
      iconSrc={searchGuestIcon}
      iconAlt="Guest magnifier"
      iconProps={{
        height: 84,
        width: 84,
      }}
    >
      <StyledAsyncSelect
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        loadOptions={loadHousingsOptions}
        placeholder={t('enter_guest_name')}
        loading={isLoading}
        hasResults={hasResults}
        value={option}
        onChange={(option: SelectOption) => setOption(option)}
      />
      <ButtonsWrapper>
        <ModalButton disabled={!option} onClick={handleSubmit} label={t('search')} />
        <ModalButton secondary onClick={handleClose} label={t('close')} />
      </ButtonsWrapper>
    </Modal>
  );
}

SearchGuestLeaderModal.defaultProps = defaultProps;
export {SearchGuestLeaderModal};
