import React, {Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import i18n from 'i18next';
import {useErrorModal, useErrorToast, useModalControls} from '../../../utils/hooks';
import api, {queryFetcher} from '../../../api';
import {Lock, LockUser, Room, SelectOption, TempLock} from '../../../utils/types';
import {useSubscription} from '../../../context/subscription';
import {
  LOCK_ACCESS_TYPES,
  LOCK_ACCOUNT_NAMES_OPTIONS,
  LOCK_REMINDER_OPTIONS,
  LOCK_SELECTORS,
  LOCK_TYPES_OPTIONS,
  LOCK_VENDORS,
  REMINDER_OPTIONS,
} from '../../../utils/constants';
import plusIcon from '../../../assets/plus-blue.svg';
import Section from '../Section';
import Switch from '../Switch';
import Select from '../Select';
import Input from '../Input';
import SelfCheckinSubscriptionAndProviderModals from '../SelfCheckinSubscriptionAndProviderModals';
import NewDoorModal from '../NewDoorModal';
import Loader from '../../common/Loader';
import Pagination from '../Pagination';
import SelectorButton from '../SelectorButton';
import {
  AccountSelectWrapper,
  AccountWrapper,
  AddButton,
  Content,
  Dot,
  LocksContainer,
  LocksHeader,
  LocksHeaderTitle,
  LocksList,
  LocksLoaderWrapper,
  LocksSubHeader,
  LockWrapper,
  NoLocksMessage,
  PaginationContent,
  PremiumLabel,
  PrivateLocksHeader,
  RemindersNotes,
  SelectorsWrapper,
  ThreeDotsGroup,
} from './styled';

const VENDORS_WITHOUT_KEYS_ADDING: string[] = [];
const PAGE_SIZE = 9;

function fetchLockUsers() {
  return queryFetcher(api.locks.ENDPOINTS.lockUsers());
}

function fetchLocks(key: string, housingId: string, userId: string, params = '') {
  return queryFetcher(
    api.locks.ENDPOINTS.locks(null, `housing=${housingId}&user_id=${userId}${params}`),
  );
}

function getLockUsersAsOptions(users?: LockUser[]) {
  if (!users) {
    return [];
  }

  return users.map((user) => {
    const vendorLabel =
      LOCK_ACCOUNT_NAMES_OPTIONS[user.vendor as keyof typeof LOCK_ACCOUNT_NAMES_OPTIONS]
        ?.label;
    const userLabel = user.account_name || user.username;

    return {
      label: `${userLabel} [${vendorLabel}]`,
      value: user.id,
      data: user,
    };
  });
}

type Selector = {
  [key: string]: boolean;
};

function getInitLockSelectors() {
  let result: Selector = {};
  Object.keys(REMINDER_OPTIONS).forEach((key) => {
    result[key] = false;
  });

  return result;
}

function getIsAnySelectorActive(selectors?: Selector) {
  if (selectors) {
    return Object.keys(REMINDER_OPTIONS).some((key) => {
      return selectors[key];
    });
  }
  return false;
}

type HousingSelfCheckinSectionProps = {
  rooms: Room[];
  disabled?: boolean;
  housing?: any;
  setIsSectionTouched?: Dispatch<SetStateAction<boolean>>;
};

const defaultProps: Partial<HousingSelfCheckinSectionProps> = {
  disabled: false,
};

const HousingSelfCheckinSection = React.forwardRef(
  (
    {disabled, housing, setIsSectionTouched, rooms}: HousingSelfCheckinSectionProps,
    ref,
  ) => {
    const {t} = useTranslation();
    const {isSelfCheckinActive, isHotelSubscription, isTrialMode} = useSubscription();
    const {ErrorModal} = useErrorModal();
    const [isSectionActive, setIsSectionActive] = React.useState<boolean>(
      housing?.is_self_online_check_in_enabled,
    );
    const [account, setAccount] = React.useState<SelectOption<LockUser> | null>(null);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [currentPrivateLocksPage, setCurrentPrivateLocksPage] = React.useState(0);
    const [isPreloaded, setIsPreloaded] = React.useState(false);
    const [tempLocks, setTempLocks] = React.useState<TempLock[]>([]);
    const [selectors, setSelectors] = React.useState<Selector>(() => {
      return getInitLockSelectors();
    });

    const accountVendor = account?.data?.vendor || '';

    const {
      isOpen: isSubscriptionAndProviderModalOpen,
      openModal: openSubscriptionAndProviderModal,
      closeModal: closeSubscriptionAndProviderModal,
    } = useModalControls();
    const {
      isOpen: isNewDoorModalOpen,
      openModal: openNewDoorModal,
      closeModal: closeNewDoorModal,
    } = useModalControls();
    const {
      isOpen: isNewPrivateDoorModalOpen,
      openModal: openNewPrivateDoorModal,
      closeModal: closeNewPrivateDoorModal,
    } = useModalControls();

    const {data: lockUsers, error: lockUsersError, status: lockUsersStatus} = useQuery<
      LockUser[],
      string
    >('lockUsers', fetchLockUsers);
    useErrorToast(lockUsersError, {
      notFoundMessage: 'Lock users not found.',
    });

    const {data: locks, error: locksError, status: locksStatus} = useQuery<
      Lock[],
      [string, string, string, string]
    >(
      Boolean(housing?.id && account) && [
        'locks',
        housing!.id,
        account!.value,
        `&access_type=${LOCK_ACCESS_TYPES.common}`,
      ],
      fetchLocks,
    );
    useErrorToast(locksError, {
      notFoundMessage: 'Locks not found.',
    });

    const {
      data: privateLocks,
      error: privateLocksError,
      status: privateLocksStatus,
      refetch: refetchPrivateLocks,
    } = useQuery<Lock[], [string, string, string, string]>(
      Boolean(isHotelSubscription && housing?.id && account) && [
        'locks',
        housing!.id,
        account!.value,
        `&access_type=${LOCK_ACCESS_TYPES.private}`,
      ],
      fetchLocks,
    );
    useErrorToast(privateLocksError, {
      notFoundMessage: 'Private locks not found.',
    });

    const commonTempLocks = React.useMemo(() => {
      return tempLocks.filter((lock) => {
        return lock.access_type === LOCK_ACCESS_TYPES.common;
      });
    }, [tempLocks]);
    const privateTempLocks = React.useMemo(() => {
      return tempLocks.filter((lock) => {
        return lock.access_type === LOCK_ACCESS_TYPES.private;
      });
    }, [tempLocks]);
    const allLocks = React.useMemo(() => {
      return [...(locks || []), ...(privateLocks || []), ...tempLocks];
    }, [locks, privateLocks, tempLocks]);

    const locksCount = React.useMemo(() => {
      const locksNumber = locks?.length || 0;
      const tempLocksNumber = commonTempLocks?.length || 0;

      return locksNumber + tempLocksNumber;
    }, [commonTempLocks, locks]);
    const privateLocksCount = React.useMemo(() => {
      const locksNumber = privateLocks?.length || 0;
      const tempLocksNumber = privateTempLocks?.length || 0;

      return locksNumber + tempLocksNumber;
    }, [privateLocks, privateTempLocks]);
    const lockUsersOptions = React.useMemo(() => {
      return getLockUsersAsOptions(lockUsers);
    }, [lockUsers]);

    const isLoading = locksStatus === 'loading' || privateLocksStatus === 'loading';

    React.useImperativeHandle(ref, () => {
      return {
        tempLocks,
        lockUser: account,
        active: isSectionActive,
        data: selectors,
      };
    });

    const getInitSelectors = React.useCallback(() => {
      const options: Selector = {};

      Object.values(LOCK_REMINDER_OPTIONS).forEach((value) => {
        options[value] = Boolean(housing?.[value]);
      });
      return options;
    }, [housing]);

    React.useEffect(
      function preloadSelectors() {
        const initSelectors = getInitSelectors();

        if (initSelectors && housing?.id) {
          if (!isPreloaded) {
            const isAnySelectorActive = getIsAnySelectorActive(initSelectors);
            setIsSectionActive(isAnySelectorActive);
          }

          setSelectors(initSelectors);
          setIsPreloaded(true);
        }
      },
      [isSectionActive, getInitSelectors, housing, isPreloaded],
    );

    React.useEffect(() => {
      if (housing) {
        const isSelfCheckInEnabled = housing?.is_self_online_check_in_enabled;
        setIsSectionActive(isSelfCheckInEnabled);
      }
    }, [housing]);

    React.useEffect(() => {
      if (housing) {
        setTempLocks([]);
        refetchPrivateLocks();
      }
    }, [housing, refetchPrivateLocks]);

    React.useEffect(() => {
      if (lockUsersOptions?.length && !account) {
        const defaultAccount = lockUsersOptions[0];
        setAccount(defaultAccount);
      }
    }, [account, lockUsersOptions]);

    const setSectionTouched = () => {
      if (setIsSectionTouched) {
        setIsSectionTouched(true);
      }
    };

    const toggleIsSectionActive = () => {
      setIsSectionActive((prevState) => {
        if (
          !isSectionActive &&
          !isSelfCheckinActive &&
          !(isTrialMode && lockUsers?.length)
        ) {
          openSubscriptionAndProviderModal();
          return false;
        }

        setSectionTouched();
        return !prevState;
      });
    };

    const handleAddAccountClick = () => {
      openSubscriptionAndProviderModal();
    };

    const handleAccountNameChange = (accountName: SelectOption) => {
      setAccount(accountName);
      setCurrentPage(0);
      setCurrentPrivateLocksPage(0);
      setTempLocks([]);
    };

    const getCurrentPageLocks = React.useCallback(
      (typeLocks?: Lock[], tempTypeLocks?: TempLock[], page = 0) => {
        if (!typeLocks?.length && !tempTypeLocks?.length) {
          return [];
        }

        if (!typeLocks?.length && tempTypeLocks?.length) {
          return tempTypeLocks.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
        }

        if (typeLocks?.length && !tempTypeLocks?.length) {
          return typeLocks.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
        }

        return [...typeLocks!, ...tempTypeLocks!].slice(
          page * PAGE_SIZE,
          (page + 1) * PAGE_SIZE,
        );
      },
      [],
    );

    const getCurrentPageCommonLocks = React.useCallback(() => {
      return getCurrentPageLocks(locks, commonTempLocks, currentPage);
    }, [commonTempLocks, currentPage, getCurrentPageLocks, locks]);

    const getCurrentPagePrivateLocks = React.useCallback(() => {
      return getCurrentPageLocks(privateLocks, privateTempLocks, currentPrivateLocksPage);
    }, [currentPrivateLocksPage, getCurrentPageLocks, privateLocks, privateTempLocks]);

    const toggleSelfCheckinSelector = (option = '') => {
      setSelectors((prevState) => {
        return {
          ...prevState,
          [option]: !prevState[option],
        };
      });

      setSectionTouched();
    };

    const saveTempLock = (lock: TempLock) => {
      setTempLocks((prevState) => {
        return [...prevState, lock];
      });

      setSectionTouched();
    };

    const privateLocksSection = React.useMemo(() => {
      if (!isHotelSubscription) {
        return null;
      }

      if (!isLoading && !privateLocks?.length && !privateTempLocks.length) {
        return (
          <section>
            <PrivateLocksHeader>
              <LocksHeaderTitle>{t('private_access')}</LocksHeaderTitle>
            </PrivateLocksHeader>
            <LocksSubHeader>
              {t('private_access_first_description')}
              <p />
              {t('private_access_second_description')}
            </LocksSubHeader>
            <NoLocksMessage>
              <div>{t('no_doors')}</div>
              <AddButton
                disabled={disabled}
                type="button"
                onClick={openNewPrivateDoorModal}
              >
                <img src={plusIcon} alt="Plus" />
                {t('add_a_private_door')}
              </AddButton>
            </NoLocksMessage>
          </section>
        );
      }

      return (
        <section>
          <PrivateLocksHeader>
            <LocksHeaderTitle>{t('private_access')}</LocksHeaderTitle>
          </PrivateLocksHeader>
          <LocksSubHeader>
            {t('private_access_first_description')}
            <p />
            {t('private_access_second_description')}
          </LocksSubHeader>
          <LocksList>
            {getCurrentPagePrivateLocks()?.map((lock, i) => {
              return (
                <PrivateLockItem
                  key={i}
                  lock={lock}
                  index={i}
                  accountVendor={accountVendor}
                />
              );
            })}
            {privateLocksCount < PAGE_SIZE && (
              <AddButton
                disabled={disabled}
                type="button"
                onClick={openNewPrivateDoorModal}
              >
                <img src={plusIcon} alt="Plus" />
                {t('add_a_private_door')}
              </AddButton>
            )}
          </LocksList>
          {privateLocksCount >= PAGE_SIZE && (
            <div>
              <PaginationContent hasPagination={privateLocksCount > PAGE_SIZE}>
                <AddButton
                  disabled={disabled}
                  type="button"
                  onClick={openNewPrivateDoorModal}
                >
                  <img src={plusIcon} alt="Plus" />
                  {t('add_a_private_door')}
                </AddButton>
                {privateLocksCount > PAGE_SIZE && (
                  <Pagination
                    onPageChange={setCurrentPrivateLocksPage}
                    pages={Math.ceil(Number(privateLocksCount) / PAGE_SIZE)}
                    page={currentPrivateLocksPage}
                  />
                )}
              </PaginationContent>
            </div>
          )}
        </section>
      );
    }, [
      accountVendor,
      currentPrivateLocksPage,
      disabled,
      getCurrentPagePrivateLocks,
      isHotelSubscription,
      isLoading,
      openNewPrivateDoorModal,
      privateLocks,
      privateLocksCount,
      privateTempLocks.length,
      t,
    ]);

    return (
      <>
        <Section
          title={
            <>
              {t('self_checkin_title')}
              <PremiumLabel>{t('premium_feature')}</PremiumLabel>
            </>
          }
          subtitle={
            <>
              <div>{t('self_checkin_subtitle')}</div>
            </>
          }
        >
          <Switch
            checked={isSectionActive}
            onChange={toggleIsSectionActive}
            label={t('activate_self_checkin')}
            disabled={disabled || lockUsersStatus === 'loading'}
          />
          {isSectionActive && (
            <Content>
              <AccountWrapper>
                <AccountSelectWrapper>
                  <Select
                    loading={lockUsersStatus === 'loading'}
                    label={t('account_name')}
                    options={lockUsersOptions}
                    onChange={handleAccountNameChange}
                    value={account}
                    disabled={disabled}
                    placeholder={t('select_your_account')}
                  />
                </AccountSelectWrapper>
                {!disabled && (
                  <AddButton
                    disabled={disabled}
                    type="button"
                    onClick={handleAddAccountClick}
                  >
                    <img src={plusIcon} alt="Plus" />
                    {t('add_account')}
                  </AddButton>
                )}
              </AccountWrapper>
              {account && (
                <LocksContainer>
                  <LocksHeader>
                    <LocksHeaderTitle>{t('common_access')}</LocksHeaderTitle>
                  </LocksHeader>
                  <LocksSubHeader>{t('common_access_description')}</LocksSubHeader>
                  {VENDORS_WITHOUT_KEYS_ADDING.includes(accountVendor) ? (
                    <NoLocksMessage>
                      <div>{t('doors_unavailable_for_vendor')}</div>
                    </NoLocksMessage>
                  ) : (
                    <div>
                      {isLoading ? (
                        <LocksLoaderWrapper>
                          <Loader height={45} width={45} label={t('loading')} />
                        </LocksLoaderWrapper>
                      ) : (
                        Boolean(locksCount) && (
                          <div>
                            <LocksList>
                              {getCurrentPageCommonLocks()?.map((lock, i) => {
                                return (
                                  <LockItem
                                    key={i}
                                    lock={lock}
                                    index={i}
                                    accountVendor={accountVendor}
                                  />
                                );
                              })}
                              {locksCount < PAGE_SIZE && (
                                <AddButton
                                  disabled={disabled}
                                  type="button"
                                  onClick={openNewDoorModal}
                                >
                                  <img src={plusIcon} alt="Plus" />
                                  {t('add_a_door')}
                                </AddButton>
                              )}
                            </LocksList>
                            {locksCount >= PAGE_SIZE && (
                              <div>
                                <PaginationContent hasPagination={locksCount > PAGE_SIZE}>
                                  <AddButton
                                    disabled={disabled}
                                    type="button"
                                    onClick={openNewDoorModal}
                                  >
                                    <img src={plusIcon} alt="Plus" />
                                    {t('add_a_door')}
                                  </AddButton>
                                  {locksCount > PAGE_SIZE && (
                                    <Pagination
                                      onPageChange={setCurrentPage}
                                      pages={Math.ceil(Number(locksCount) / PAGE_SIZE)}
                                      page={currentPage}
                                    />
                                  )}
                                </PaginationContent>
                              </div>
                            )}
                            {privateLocksSection}
                            <ThreeDotsGroup>
                              <Dot />
                              <Dot />
                              <Dot />
                            </ThreeDotsGroup>
                            <RemindersNotes>{t('i_will_send_keys_when')}</RemindersNotes>
                            <SelectorsWrapper>
                              {LOCK_SELECTORS.map((selector, index) => {
                                return (
                                  <SelectorButton
                                    type="button"
                                    key={index}
                                    label={selector.label}
                                    active={selectors[selector.name]}
                                    disabled={disabled}
                                    onClick={() =>
                                      toggleSelfCheckinSelector(selector.name)
                                    }
                                  />
                                );
                              })}
                            </SelectorsWrapper>
                          </div>
                        )
                      )}
                      {!isLoading && !locks?.length && !commonTempLocks.length && (
                        <div>
                          <NoLocksMessage>
                            <div>{t('no_doors')}</div>
                            <AddButton
                              disabled={disabled}
                              type="button"
                              onClick={openNewDoorModal}
                            >
                              <img src={plusIcon} alt="Plus" />
                              {t('add_a_door')}
                            </AddButton>
                          </NoLocksMessage>
                          {privateLocksSection}
                          <ThreeDotsGroup>
                            <Dot />
                            <Dot />
                            <Dot />
                          </ThreeDotsGroup>
                          <RemindersNotes>{t('i_will_send_keys_when')}</RemindersNotes>
                          <SelectorsWrapper>
                            {LOCK_SELECTORS.map((selector, index) => {
                              return (
                                <SelectorButton
                                  type="button"
                                  key={index}
                                  label={selector.label}
                                  active={selectors[selector.name]}
                                  disabled={disabled}
                                  onClick={() => toggleSelfCheckinSelector(selector.name)}
                                />
                              );
                            })}
                          </SelectorsWrapper>
                        </div>
                      )}
                    </div>
                  )}
                </LocksContainer>
              )}
            </Content>
          )}
        </Section>
        <SelfCheckinSubscriptionAndProviderModals
          onClose={closeSubscriptionAndProviderModal}
          open={isSubscriptionAndProviderModalOpen}
          setIsSectionActive={setIsSectionActive}
          setSectionTouched={setSectionTouched}
          isSectionActive={isSectionActive}
        />
        {isNewDoorModalOpen && (
          <NewDoorModal
            open
            allLocks={allLocks}
            housingId={housing?.id}
            user={account}
            saveTempLock={saveTempLock}
            onClose={closeNewDoorModal}
            accessType={LOCK_ACCESS_TYPES.common}
          />
        )}
        {isNewPrivateDoorModalOpen && (
          <NewDoorModal
            open
            allLocks={allLocks}
            housingId={housing?.id}
            user={account}
            rooms={rooms}
            saveTempLock={saveTempLock}
            onClose={closeNewPrivateDoorModal}
            accessType={LOCK_ACCESS_TYPES.private}
          />
        )}
        <ErrorModal />
      </>
    );
  },
);

type LockItemProps = {
  lock: Lock | TempLock;
  index: number;
  accountVendor: LOCK_VENDORS | string;
};

function getLockItemLabel({lock, index, accountVendor}: LockItemProps) {
  const number = index > 8 ? index + 1 : `0${index + 1}`;

  if (accountVendor === LOCK_VENDORS.manualBox) {
    return `(${number}) ${lock?.name}`;
  }

  return `(${number}) ${
    LOCK_TYPES_OPTIONS[lock.type as keyof typeof LOCK_TYPES_OPTIONS]?.label || ''
  }`;
}

function getLockItemValue({lock, accountVendor}: LockItemProps) {
  if (accountVendor === LOCK_VENDORS.manualBox) {
    return `${i18n.t('code')}: ${lock?.access_code}`;
  }

  return lock?.name || lock?.external_id;
}

function LockItem({lock, index, accountVendor}: LockItemProps) {
  const value = getLockItemValue({lock, index, accountVendor});
  const label = getLockItemLabel({lock, index, accountVendor});

  return (
    <LockWrapper>
      <Input readOnly label={label} value={value} />
    </LockWrapper>
  );
}

function getPrivateLockItemLabel({lock, index}: LockItemProps) {
  const number = index > 8 ? index + 1 : `0${index + 1}`;

  return `(${number}) ${lock?.room_number} ${lock?.name}`;
}

function getPrivateLockItemValue({lock, accountVendor}: LockItemProps) {
  if (accountVendor === LOCK_VENDORS.manualBox) {
    return `${i18n.t('code')}: ${lock?.access_code}`;
  }

  return lock?.external_id || '';
}

function PrivateLockItem({lock, accountVendor, index}: LockItemProps) {
  const value = getPrivateLockItemValue({lock, accountVendor, index});
  const label = getPrivateLockItemLabel({lock, index, accountVendor});

  return (
    <LockWrapper>
      <Input readOnly label={label} value={value} />
    </LockWrapper>
  );
}

HousingSelfCheckinSection.defaultProps = defaultProps;
export {HousingSelfCheckinSection};
