import React from 'react';
import {useOutsideClick} from '../../../utils/hooks';
import {Container, ToggleWrapper, Label, Menu, MenuItem} from './styled';

type OptionType = {
  label: React.ReactNode | JSX.Element | string;
  value: string;
  className?: string;
};

type HeaderItemProps = {
  icon?: React.ReactNode | JSX.Element | string;
  activeIcon?: React.ReactNode | JSX.Element | string;
  label?: React.ReactNode | JSX.Element | string;
  menuOptions: OptionType[];
  secondaryIcon?: React.ReactNode | JSX.Element | string;
  activeSecondaryIcon?: React.ReactNode | JSX.Element | string;
  className?: string;
  onMenuItemClick: (value: string) => void;
};

const defaultProps: HeaderItemProps = {
  icon: null,
  activeIcon: null,
  label: '',
  menuOptions: [],
  secondaryIcon: null,
  activeSecondaryIcon: null,
  className: undefined,
  onMenuItemClick: () => {},
};

function HeaderItem({
  icon,
  label,
  activeIcon,
  menuOptions,
  secondaryIcon,
  activeSecondaryIcon,
  className,
  onMenuItemClick,
}: HeaderItemProps) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const closeMenu = React.useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  useOutsideClick(containerRef, closeMenu);

  const toggleIsMenuOpen = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleMenuItemClick = (value: string) => {
    onMenuItemClick(value);
    closeMenu();
  };

  return (
    <>
      <Container className={className} ref={containerRef}>
        <ToggleWrapper
          onClick={toggleIsMenuOpen}
          onMouseOut={() => setIsHovered(false)}
          onMouseOver={() => setIsHovered(true)}
        >
          {activeIcon ? (isMenuOpen || isHovered ? activeIcon : icon) : icon}
          {label && <Label active={isMenuOpen}>{label}</Label>}
          {secondaryIcon && activeSecondaryIcon
            ? isMenuOpen
              ? activeSecondaryIcon
              : secondaryIcon
            : secondaryIcon}
        </ToggleWrapper>
        {Boolean(menuOptions.length) && isMenuOpen && (
          <Menu>
            {menuOptions.map((option, index) => {
              return (
                <MenuItem
                  className={option.className}
                  key={index}
                  onClick={() => handleMenuItemClick(option.value)}
                >
                  <div>{option.label}</div>
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </Container>
    </>
  );
}

HeaderItem.defaultProps = defaultProps;
export {HeaderItem};
