import React from 'react';
import {queryCache, useQuery} from 'react-query';
import {useTranslation} from 'react-i18next';
import {
  CardElement,
  Elements,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';
import {toast} from 'react-toastify';
import {addSupportEmailToMessage} from '../../../utils/common';
import api, {queryFetcher} from '../../../api';
import i18n from '../../../i18n';
import {Card} from '../../../utils/types';
import {
  useErrorModal,
  useErrorToast,
  useIsMounted,
  useStatus,
} from '../../../utils/hooks';
import creditCardFilledIcon from '../../../assets/credit-card-filled.svg';
import Button from '../Button';
import Loader from '../../common/Loader';
import CardPlaceholder from '../CardPlaceholder';
import {
  Content,
  HeaderText,
  ButtonLabelWrapper,
  ButtonLabelIcon,
  ButtonLabelText,
  stripeInputStyle,
  Form,
  ButtonWrapper,
  LoaderWrapper,
  CardNotAddedText,
  CardPlaceholderWrapper,
} from './styled';

function toastStripeError(error: any) {
  if (error?.message) {
    toast.error(addSupportEmailToMessage(error?.message));
  } else {
    console.log(error);
  }
}

function fetchCard() {
  return queryFetcher(api.payments.ENDPOINTS.paymentMethod());
}

function fetchStripeSecret() {
  return queryFetcher(api.payments.ENDPOINTS.secret());
}

type StripeSecret = {
  secret: string;
};

type PaymentCardSectionProps = {
  stripe?: ReactStripeElements.StripeProps;
};

function PaymentCardSection({stripe}: PaymentCardSectionProps) {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {ErrorModal, displayError} = useErrorModal();
  const {isLoading, setStatus} = useStatus();
  const [isEditing, setIsEditing] = React.useState(false);
  const [isCardComplete, setIsCardComplete] = React.useState(false);
  const [isCardReady, setIsCardReady] = React.useState(false);

  const {
    data: paymentMethod,
    error: paymentMethodError,
    status: paymentMethodStatus,
  } = useQuery('paymentMethod', fetchCard, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(paymentMethodError);
  const {data: stripeSecret, error: stripeSecretError} = useQuery<StripeSecret, string>(
    'stripeSecret',
    fetchStripeSecret,
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );
  useErrorToast(paymentMethodError, {
    notFoundMessage: t('errors.card_not_found'),
  });
  useErrorToast(stripeSecretError, {
    notFoundMessage: 'Unable to find stripe Secret. Please contact support.',
  });

  const isLoadingPaymentMethod = paymentMethodStatus === 'loading';
  const card: Card = paymentMethod?.card;

  React.useEffect(() => {
    if (isLoadingPaymentMethod) {
      return;
    }

    setIsEditing(!Boolean(card));
  }, [card, isLoadingPaymentMethod]);

  const startEditing = () => {
    setIsEditing(true);
  };

  const stopEditing = () => {
    setIsEditing(false);
  };

  const handleCardChange = (event: any) => {
    setIsCardComplete(event.complete);
  };

  const handleCardReady = () => {
    setIsCardReady(true);
  };

  const deleteUserCard = async () => {
    const {error} = await api.payments.deleteUserCard();

    if (!isMounted.current) {
      return;
    }

    if (error) {
      displayError(error);
      return false;
    }
    return true;
  };

  const createAndGetStripeToken = async () => {
    const {error, token} = await stripe!.createToken({name: 'Token'});

    if (!isMounted.current) {
      return;
    }

    if (error) {
      displayError(error);
      return null;
    }
    return token;
  };

  const createAndGetStripePaymentMethod = async (token: any) => {
    if (!token || !stripeSecret?.secret) {
      return null;
    }

    const {setupIntent, error} = await stripe!.confirmCardSetup(stripeSecret?.secret, {
      payment_method: {
        card: {
          token: token.id,
        },
      },
    });

    if (!isMounted.current) {
      return;
    }

    if (error) {
      toastStripeError(error);
      return null;
    }
    return setupIntent?.payment_method;
  };

  const createPaymentMethod = async (payment_method: any) => {
    if (!payment_method) {
      return;
    }

    const {data, error} = await api.payments.bindUserCard({payment_method});

    if (!isMounted.current) {
      return;
    }

    if (error) {
      displayError(error);
      return false;
    }

    return data?.status === 'ok';
  };

  const finishSubmitting = () => {
    setStatus('idle');
    setIsEditing(false);
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus('loading');

    if (card) {
      const isOk = await deleteUserCard();
      if (!isOk) {
        finishSubmitting();
        return;
      }
    }

    const token = await createAndGetStripeToken();
    if (!token) {
      finishSubmitting();
      return;
    }

    const paymentMethod = await createAndGetStripePaymentMethod(token);
    if (!paymentMethod) {
      finishSubmitting();
      return;
    }

    const isOk = await createPaymentMethod(paymentMethod);
    if (!isOk) {
      finishSubmitting();
      return;
    }

    await queryCache.refetchQueries('paymentMethod');
    await queryCache.refetchQueries('stripeSecret');
    finishSubmitting();
  };

  return (
    <Content>
      <HeaderText>{t('payment_card')}</HeaderText>
      {!isLoading && !card && (
        <CardNotAddedText>{i18n.t('you_have_not_a_payment_card')}</CardNotAddedText>
      )}
      {!isLoading && !isEditing && card && (
        <>
          <CardPlaceholderWrapper>
            <CardPlaceholder />
          </CardPlaceholderWrapper>
          <Button
            type="button"
            onClick={startEditing}
            label={
              <ButtonLabelWrapper>
                <ButtonLabelIcon src={creditCardFilledIcon} alt="Credit card" />
                <ButtonLabelText>{t('change_payment_card')}</ButtonLabelText>
              </ButtonLabelWrapper>
            }
            secondary
          />
        </>
      )}
      {isEditing && (
        <Form onSubmit={handleSubmit}>
          <CardElement
            hideIcon
            onChange={handleCardChange}
            onReady={handleCardReady}
            style={stripeInputStyle}
          />
          {isLoading || !isCardReady ? (
            <LoaderWrapper>
              <Loader width={40} height={40} />
            </LoaderWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                type="submit"
                disabled={!isCardComplete}
                label={
                  <ButtonLabelWrapper>
                    <ButtonLabelIcon src={creditCardFilledIcon} alt="Credit card" />
                    <ButtonLabelText>{t('set_payment_card')}</ButtonLabelText>
                  </ButtonLabelWrapper>
                }
                secondary
              />
              <Button
                secondary
                type="button"
                onClick={stopEditing}
                label={
                  <ButtonLabelWrapper>
                    <ButtonLabelText>{t('cancel')}</ButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
            </ButtonWrapper>
          )}
        </Form>
      )}
      {(isLoading || isLoadingPaymentMethod) && !isEditing && (
        <LoaderWrapper>
          <Loader width={40} height={40} />
        </LoaderWrapper>
      )}
      <ErrorModal />
    </Content>
  );
}

const InjectedPaymentCardSection = injectStripe(PaymentCardSection);

function ElementsInjectedPaymentCardSection() {
  return (
    <Elements>
      <InjectedPaymentCardSection />
    </Elements>
  );
}

export {ElementsInjectedPaymentCardSection};
