import React from 'react';
import {useQuery} from 'react-query';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import api, {queryFetcher} from '../../../api';
import {PaymentMethod} from '../../../utils/types';
import {getTrialDaysLeft} from '../../../utils/subscription';
import {useSubscription} from '../../../context/subscription';
import {TrialHeaderWrapper, TitleText, BoldText, SubscribeButton} from './styled';

function fetchPaymentMethod() {
  return queryFetcher(api.payments.ENDPOINTS.paymentMethod());
}

function TrialHeader() {
  const {t} = useTranslation();
  const {
    subscription,
    isSubscriptionCanceled,
    isSubscriptionActive,
    isSubscriptionScheduledToCancel,
    isTrialMode,
    restartSubscription,
    isLoading,
    hasSubscribedWithFreeCheckinsLeft,
    isTrialEnded,
    isCustomTrial,
  } = useSubscription();

  const {data: paymentMethod, status: paymentMethodStatus} = useQuery<
    PaymentMethod,
    string
  >(!isCustomTrial && 'paymentMethod', fetchPaymentMethod, {
    refetchOnWindowFocus: false,
  });

  const checkinsLeft = subscription?.quantity_allowed! - subscription?.quantity_used!;
  const daysLeft = getTrialDaysLeft(subscription?.trial_end_epoch);

  const isStripeTrialFinished = Boolean(!isCustomTrial && paymentMethod?.card);
  const isSubscribeButtonVisible = isCustomTrial
    ? !isSubscriptionScheduledToCancel && !hasSubscribedWithFreeCheckinsLeft
    : !isSubscriptionScheduledToCancel && !isStripeTrialFinished;
  const shouldHideHeader =
    paymentMethodStatus === 'loading' ||
    (!isSubscriptionScheduledToCancel && isSubscriptionActive) ||
    (!isSubscriptionScheduledToCancel &&
      subscription?.quantity_allowed === undefined &&
      !subscription?.trial_end_epoch &&
      !isSubscriptionCanceled);

  const getTitle = () => {
    if (isSubscriptionCanceled) {
      return (
        <TitleText>
          <BoldText>{t('you_dont_have_active_subscription')}</BoldText>
        </TitleText>
      );
    }

    if (isSubscriptionScheduledToCancel) {
      return (
        <TitleText>
          <BoldText>{t('you_are_not_subscribed')}</BoldText>
        </TitleText>
      );
    }

    if (isCustomTrial) {
      if (hasSubscribedWithFreeCheckinsLeft) {
        return (
          <TitleText>
            <Trans i18nKey="you_have_number_checkins_left">
              You have <b>{{number: checkinsLeft || 0}}</b> checkins left
            </Trans>
          </TitleText>
        );
      }

      if (isTrialMode) {
        return (
          <TitleText>
            <BoldText>{t('you_are_in_trial_mode')} - </BoldText>
            <Trans i18nKey="you_have_number_checkins_left">
              You have <b>{{number: checkinsLeft || 0}}</b> checkins left
            </Trans>
          </TitleText>
        );
      }

      if (isTrialEnded) {
        return (
          <TitleText>
            <BoldText>{t('your_trial_has_ended')} - </BoldText>
            <Trans i18nKey="you_have_number_checkins_left">
              You have <b>{{number: checkinsLeft || 0}}</b> checkins left
            </Trans>
          </TitleText>
        );
      }
    } else {
      if (isStripeTrialFinished) {
        return (
          <TitleText>
            <Trans i18nKey="you_have_number_days_left">
              You have <b>{{number: daysLeft}}</b> days left
            </Trans>
          </TitleText>
        );
      }

      if (isTrialMode) {
        return (
          <TitleText>
            <BoldText>{t('you_are_in_trial_mode')} - </BoldText>
            <Trans i18nKey="you_have_number_days_left">
              You have <b>{{number: daysLeft}}</b> days left
            </Trans>
          </TitleText>
        );
      }

      if (isTrialEnded) {
        return (
          <TitleText>
            <BoldText>{t('your_trial_has_ended')} - </BoldText>
            <Trans i18nKey="you_have_number_days_left">
              You have <b>{{number: daysLeft}}</b> days left
            </Trans>
          </TitleText>
        );
      }
    }
  };

  const title = getTitle();
  if (shouldHideHeader || !title) {
    return null;
  }

  return (
    <TrialHeaderWrapper>
      {title}
      {isSubscribeButtonVisible && (
        <Link to="/subscription/number">
          <SubscribeButton label={t('subscribe_now')} />
        </Link>
      )}
      {isSubscriptionScheduledToCancel && (
        <SubscribeButton
          disabled={isLoading}
          onClick={restartSubscription}
          label={t('reactivate_subscription')}
        />
      )}
    </TrialHeaderWrapper>
  );
}

export {TrialHeader};
