import React from 'react';
import {FormContext, useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Moment} from 'moment';
import {useQuery, queryCache} from 'react-query';
import {
  useErrorToast,
  useIsMounted,
  useModalControls,
  usePrevious,
  useScrollToTop,
  useStatus,
} from '../../../utils/hooks';
import {useConfirmLeaveModal} from '../../../context/openModals';
import {toastResponseError, clearLocationState} from '../../../utils/common';
import {
  DEFAULT_OCCUPIED_ROOMS_NUMBER,
  GUEST_PLACEHOLDER_ID,
} from '../../../utils/constants';
import {getName} from '../../../utils/housing';
import {useComputedDetails} from '../../../context/computedDetails';
import {FORM_NAMES, FormTypes} from '../ReservationInfoSection/ReservationInfoSection';
import {GuestGroup, Housing, LightReservation} from '../../../utils/types';
import {buildReservationPayload} from '../../../utils/reservations';
import api, {queryFetcher} from '../../../api';
import {useAuth} from '../../../context/auth';
import plusIcon from '../../../assets/plus.svg';
import rubbishIcon from '../../../assets/rubbish.svg';
import missingDataIcon from '../../../assets/icon-data-missing.svg';
import deleteBookingIcon from '../../../assets/icon-delete-booking.svg';
import Button from '../Button';
import ReservationInfoSection from '../ReservationInfoSection';
import ReservationStatusSection from '../ReservationStatusSection';
import ReservationOnlineCheckInSection from '../ReservationOnlineCheckInSection';
import ModalButton from '../ModalButton';
import Modal from '../Modal';
import GuestInfoSection from '../GuestInformationSection';
import YouHaveMadeChangesModal from '../YouHaveMadeChangesModal';
import ReservationAccessSection from '../ReservationAccessSection';
import BackButton from '../BackButton';
import ReservationKeysSection from '../ReservationKeysSection';
import ReservationTaxesSection from '../ReservationTaxesSection';
import FloatingSaveButton from '../FloatingSaveButton';
import ReservationPhotosSection from '../ReservationPhotosSection';
import {Heading} from '../../../styled/common';
import {
  ButtonLabelIcon,
  ButtonLabelText,
  ButtonLabelWrapper,
  Content,
  DeleteBookingButtonsWrapper,
  DeleteButtonLabelIcon,
  DeleteButtonLabelText,
  DeleteButtonWrapper,
  DeleteModalButton,
  Wrapper,
  ModalButtonWrapper,
  Title,
} from './styled';
import {DEFAULT_LANGUAGE_FIELD_NAME} from '../ReservationOnlineCheckInSection/ReservationOnlineCheckInSection';

function fetchReservation(key: string) {
  return queryFetcher(api.reservations.ENDPOINTS.oneLight(key));
}

function fetchHousing(key: string, id = '') {
  return queryFetcher(api.housings.ENDPOINTS.one(id));
}

function fetchGuestGroup(key: string, id: string) {
  return queryFetcher(api.guestGroups.ENDPOINTS.all(id));
}

type OnlineCheckInSectionRefType = {
  active: boolean;
  data: {
    [key: string]: boolean;
  };
  emailLanguage?: string;
};

function EditReservationSections() {
  useScrollToTop();
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const history = useHistory();
  const {id: reservationId} = useParams();
  const {accountDetails: user} = useAuth();
  const {isNeedToAskForSubscription} = useComputedDetails();
  const formMethods = useForm<FormTypes>({
    defaultValues: {
      [FORM_NAMES.occupied_rooms]: String(DEFAULT_OCCUPIED_ROOMS_NUMBER),
    },
  });
  const {formState} = formMethods;
  const prevFormState = usePrevious<typeof formState>(formState);
  const [isInfoSectionTouched, setIsInfoSectionTouched] = React.useState(false);
  const [
    isOnlineCheckInSectionTouched,
    setIsOnlineCheckInSectionTouched,
  ] = React.useState(false);
  const [isTaxesSectionTouched, setIsTaxesSectionTouched] = React.useState(false);

  const isAnySectionTouched =
    isInfoSectionTouched || isOnlineCheckInSectionTouched || isTaxesSectionTouched;

  const onlineCheckInSectionRef = React.useRef<OnlineCheckInSectionRefType>({
    active: false,
    data: {},
    emailLanguage: '',
  });

  const {isLoading, setStatus, status, isIdle} = useStatus({
    autoReset: true,
  });
  const {
    isLoading: isDeleting,
    setStatus: setDeleteStatus,
    isSuccess: isDeleted,
    isIdle: isNotDeleting,
    isError: isDeleteError,
  } = useStatus();
  const {
    openModal: openReservationDeleteModalOpen,
    closeModal: closeReservationDeleteModal,
    isOpen: isReservationDeleteModalOpen,
  } = useModalControls();
  const {
    openModal: openDataIncompleteModal,
    closeModal: closeDataIncompleteModal,
    isOpen: isDataIncompleteModalOpen,
  } = useModalControls();

  const {data: reservation, refetch: refetchReservation} = useQuery<
    LightReservation,
    string
  >(reservationId, fetchReservation, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const housingId = reservation?.housing_id;
  const {data: housing, status: housingStatus, error: housingError} = useQuery<
    Housing,
    [string, string]
  >(Boolean(housingId) && ['housing', housingId!], fetchHousing, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(housingError, {
    notFoundMessage: t('errors.requested_housing_not_found'),
  });

  const guestGroupId = reservation?.guest_group_id;
  const {data: guestGroup, error: guestGroupError} = useQuery<
    GuestGroup,
    [string, string]
  >(Boolean(guestGroupId) && ['guestGroup', guestGroupId!], fetchGuestGroup, {
    refetchOnWindowFocus: false,
  });
  useErrorToast(guestGroupError, {
    notFoundMessage:
      'Requested guest group\\s could not be found. Please contact support.',
  });

  const [wasDataIncompleteModalOpened, setWasDataIncompleteModalOpened] = React.useState(
    false,
  );
  const isDisabled = isLoading || !reservation || housingStatus === 'loading';
  const hasTaxes = Boolean(housing?.seasons?.length);

  const openIncompleteModal = React.useCallback(() => {
    if (!wasDataIncompleteModalOpened) {
      openDataIncompleteModal();
      setWasDataIncompleteModalOpened(true);
    }
  }, [openDataIncompleteModal, wasDataIncompleteModalOpened]);

  React.useEffect(
    function redirect() {
      if (isNeedToAskForSubscription) {
        history.push('/bookings');
      }
    },
    [history, isNeedToAskForSubscription],
  );

  React.useLayoutEffect(
    function showIncompleteModalOneTime() {
      const shouldOpenModal =
        formState.isSubmitted && !prevFormState?.isValid && !formState.isValid;

      if (shouldOpenModal) {
        openIncompleteModal();
      }
    },
    [
      formState.isSubmitted,
      formState.isValid,
      prevFormState,
      wasDataIncompleteModalOpened,
      openIncompleteModal,
    ],
  );

  React.useEffect(() => {
    window.addEventListener('beforeunload', clearLocationState);
    return () => {
      window.removeEventListener('beforeunload', clearLocationState);
    };
  }, []);

  const resetTaxesSection = () => {
    setIsTaxesSectionTouched(false);
  };

  const goToReservations = () => {
    history.push('/bookings');
  };

  const handleResponseError = (error?: any) => {
    setStatus('error');
    toastResponseError(error);
  };

  const deleteReservation = async () => {
    setDeleteStatus('loading');
    const {data, error} = await api.reservations.deleteOne(reservation!.id);

    if (!isMounted.current) {
      return;
    }

    if (data) {
      await queryCache.refetchQueries('reservations');
      toast.success(t('booking_deleted'));
      goToReservations();
    }

    if (error) {
      setDeleteStatus('error');
      toastResponseError(error);
    }
  };

  const getCheckInOnlineRemindersPayload = () => {
    const isActive = onlineCheckInSectionRef.current?.active;
    const data = onlineCheckInSectionRef.current?.data;

    if (!isActive && data) {
      let inactiveData: {[key: string]: boolean} = {};
      Object.keys(data).forEach((key: string) => {
        inactiveData[key] = false;
      });

      return inactiveData;
    }
    return data;
  };

  const updateHousing = async () => {
    const checkInOnlinePayload = getCheckInOnlineRemindersPayload();
    const housingId = formMethods.watch(FORM_NAMES.housing)!.value;

    const {error} = await api.housings.patchById(housingId, checkInOnlinePayload);

    if (isMounted.current && error) {
      handleResponseError(error);
    }
    return error;
  };

  const updateGuestGroup = async (reservation?: LightReservation) => {
    const guestGroupId = reservation?.guest_group_id;
    if (guestGroupId) {
      await queryCache.refetchQueries(['guestGroup', guestGroupId]);
    }
  };

  const getReservationOnlineCheckinPayload = () => {
    const emailLanguage = onlineCheckInSectionRef.current?.emailLanguage;
    return {
      [DEFAULT_LANGUAGE_FIELD_NAME]: emailLanguage,
    };
  };

  const getReservationPayload = (formData: FormTypes) => {
    const payload = buildReservationPayload(formData);
    return {
      ...payload,
      ...getReservationOnlineCheckinPayload(),
    };
  };

  const updateReservationAndGuestGroup = async (formData: FormTypes) => {
    const payload = getReservationPayload(formData);
    const {error, data} = await api.reservations.put(reservation!.id, payload);

    if (data) {
      const nextReservation = await refetchReservation({force: true});
      await updateGuestGroup(nextReservation);
      queryCache.setQueryData(reservationId, nextReservation);
    }

    if (isMounted.current && error) {
      handleResponseError(error);
    }
    return error;
  };

  const {
    linkToGo,
    goThroughConfirm,
    handleModalSave,
    handleModalDontSave,
    handleModalCancel,
    isDoYouWantToSaveModalOpen,
  } = useConfirmLeaveModal(isAnySectionTouched);

  const onSubmit = async (formData: FormTypes) => {
    setStatus('loading');

    const reservationError = await updateReservationAndGuestGroup(formData);
    if (!isMounted.current || reservationError) {
      return;
    }

    const housingError = await updateHousing();
    if (!isMounted.current || housingError) {
      return;
    }

    await queryCache.refetchQueries('reservations');
    setStatus('success');
    resetTaxesSection();

    if (linkToGo) {
      history.push(linkToGo);
    }
  };

  const getPersistedState = () => {
    const formData = {
      ...formMethods.getValues(),
      [FORM_NAMES.check_out_date]: (formMethods.getValues()[
        FORM_NAMES.check_out_date
      ] as Moment)?.toDate(),
      [FORM_NAMES.check_in_date]: (formMethods.getValues()[
        FORM_NAMES.check_in_date
      ] as Moment)?.toDate(),
    };
    const checkInOnlineData = getCheckInOnlineRemindersPayload();

    return {
      formData,
      checkInOnlineData,
    };
  };

  const goToGuest = (guestId = GUEST_PLACEHOLDER_ID) => {
    history.push(`/bookings/${reservation!.id}/guest/${guestId}`, getPersistedState());
  };

  return (
    <>
      <Content>
        <FormContext {...formMethods}>
          <Wrapper>
            <Heading>
              <div>
                <BackButton onClick={() => goThroughConfirm('/bookings')} />
              </div>
              <Title>{getName(housing)}</Title>
              <div>
                <Button
                  type="button"
                  disabled={isLoading}
                  onClick={() => goToGuest()}
                  label={
                    <ButtonLabelWrapper>
                      <ButtonLabelIcon src={plusIcon} alt="Plus" />
                      <ButtonLabelText>{t('register_guest')}</ButtonLabelText>
                    </ButtonLabelWrapper>
                  }
                />
              </div>
            </Heading>
            <div>
              <ReservationStatusSection goToGuest={goToGuest} reservation={reservation} />
              <div>
                <GuestInfoSection
                  isEditing
                  goToGuestEdit={goToGuest}
                  disabled={isDisabled}
                  reservation={reservation}
                />
              </div>
              {housing?.is_self_online_check_in_enabled && (
                <ReservationAccessSection
                  housing={housing}
                  guestGroup={guestGroup}
                  reservation={reservation}
                />
              )}
              <ReservationPhotosSection guestGroup={guestGroup} />
              {housing?.is_self_online_check_in_enabled && (
                <ReservationKeysSection reservation={reservation} />
              )}
              <ReservationOnlineCheckInSection
                isEditing
                isSectionTouched={isOnlineCheckInSectionTouched}
                setIsSectionTouched={setIsOnlineCheckInSectionTouched}
                ref={onlineCheckInSectionRef}
                reservation={reservation}
                disabled={isDisabled}
              />
              {hasTaxes && <ReservationTaxesSection reservation={reservation} />}
              <ReservationInfoSection
                isEditing
                setIsSectionTouched={setIsInfoSectionTouched}
                reservation={reservation}
                disabled={isDisabled}
              />
            </div>
            {reservation &&
              !isReservationDeleteModalOpen &&
              (isAnySectionTouched || !isIdle) && (
                <FloatingSaveButton
                  status={status}
                  onClick={formMethods.handleSubmit(onSubmit)}
                />
              )}
          </Wrapper>
        </FormContext>
        {user?.show_buttons_add_edit_delete_reservations && (
          <DeleteButtonWrapper>
            <Button
              secondary
              type="button"
              onClick={openReservationDeleteModalOpen}
              disabled={isLoading}
              label={
                <ButtonLabelWrapper>
                  <DeleteButtonLabelIcon src={rubbishIcon} alt="Plus" />
                  <DeleteButtonLabelText>{t('delete_booking')}</DeleteButtonLabelText>
                </ButtonLabelWrapper>
              }
            />
          </DeleteButtonWrapper>
        )}
      </Content>
      {isDataIncompleteModalOpen && (
        <Modal
          open
          iconSrc={missingDataIcon}
          iconAlt="Form with red fields"
          iconProps={{
            height: 84,
            width: 84,
          }}
          title={t('data_missing')}
          text={
            <>
              {t('you_cant_add_this_booking_until')}
              <p />
              {t('we_have_outlined_fields')}
            </>
          }
        >
          <ModalButtonWrapper>
            <ModalButton label={t('ok')} onClick={closeDataIncompleteModal} />
          </ModalButtonWrapper>
        </Modal>
      )}
      {isReservationDeleteModalOpen && (
        <Modal
          open
          iconSrc={deleteBookingIcon}
          iconAlt="Delete booking"
          iconProps={{
            height: 89,
            width: 84,
          }}
          title={
            isDeleted ? t('success') : isDeleting ? t('deleting') : t('are_you_sure')
          }
          text={
            isDeleted ? (
              t('successfully_deleted')
            ) : isDeleting ? (
              `${t('it_takes_seconds')}...`
            ) : (
              <Trans
                i18nKey="all_booking_info_will_be_deleted"
                values={{booking: getName(housing)}}
              >
                All the information associated to <b>Booking</b> will be deleted.
              </Trans>
            )
          }
        >
          {(isNotDeleting || isDeleteError) && (
            <DeleteBookingButtonsWrapper>
              <DeleteModalButton
                onClick={deleteReservation}
                label={
                  <ButtonLabelWrapper>
                    <DeleteButtonLabelIcon src={rubbishIcon} alt="Plus" />
                    <DeleteButtonLabelText>{t('delete_booking')}</DeleteButtonLabelText>
                  </ButtonLabelWrapper>
                }
              />
              <ModalButton
                secondary
                onClick={closeReservationDeleteModal}
                label={t('cancel')}
              />
            </DeleteBookingButtonsWrapper>
          )}
        </Modal>
      )}
      {isDoYouWantToSaveModalOpen && (
        <YouHaveMadeChangesModal
          handleModalSave={() => handleModalSave(formMethods.handleSubmit(onSubmit))}
          handleModalDontSave={handleModalDontSave}
          handleModalCancel={handleModalCancel}
        />
      )}
    </>
  );
}

export {EditReservationSections};
