import React from 'react';
import Header from '../components/dashboard/Header';
import TrialHeader from '../components/dashboard/TrialHeader';
import SubscriptionNumberStep from '../components/dashboard/SubscriptionNumberStep';

function SubscriptionNumber() {
  return (
    <>
      <TrialHeader />
      <Header />
      <SubscriptionNumberStep />
    </>
  );
}

export {SubscriptionNumber};
