import styled from 'styled-components';
import BaseCheckbox from '../Checkbox';
import {Label} from '../Checkbox/styled';
import {ModalTwoButtonsWrapper} from '../../../styled/common';

export const Checkbox = styled(BaseCheckbox)``;

export const Container = styled.div`
  width: 537px;
  height: 490px;
  display: flex;
  flex-direction: column;

  & ${ModalTwoButtonsWrapper} {
    margin-top: auto;
  }
`;

export const SelectAllWrapper = styled.div`
  padding-left: 59px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(0 66 154 / 0.2);

  & ${Label} {
    font-size: 16px;
    margin-top: 0;
    font-family: ProximaNova-Bold, sans-serif;
    color: #161643;
  }
`;

export const CheckboxesWrapper = styled.div`
  padding: 22px 59px 0;
  max-height: 232px;
  overflow-y: auto;

  & ${Checkbox} {
    margin-bottom: 30px;

    & ${Label} {
      margin-top: 0;
      font-family: ProximaNova-Medium, sans-serif;
      font-size: 16px;
    }
  }
`;

export const LoaderWrapper = styled.div`
  margin-top: 80px;
`;
