import styled from 'styled-components';
import {StyledButton as SelectorButton} from '../SelectorButton/styled';

export const TooltipContentItem = styled.div`
  margin-bottom: 15px;
`;

export const Content = styled.div``;

export const SelectorsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -10px;

  & ${SelectorButton} {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const SelectWrapper = styled.div`
  padding: 0 0 30px 0;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
    padding-top: 15px;
  }
`;
