import React from 'react';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import api, {queryFetcher} from '../../../api';
import {PaymentsMovements} from '../../../utils/types';
import {useErrorToast} from '../../../utils/hooks';
import PaymentsMovementsGrid from '../PaymentsMovementsGrid';
import {Content, SeeMoreButton} from './styled';

function fetchMovementsPreview() {
  return queryFetcher(api.paymentsSettings.ENDPOINTS.movementsPreview());
}

function filterEmptyMovements(movements?: PaymentsMovements) {
  const hasMovements = movements && Object.keys(movements).length;
  const result: PaymentsMovements = {};

  if (!hasMovements) {
    return {};
  }

  Object.keys(movements!).forEach((key) => {
    const isNotEmpty = Boolean(movements![key].length);
    if (isNotEmpty) {
      result[key] = movements![key];
    }
  });

  return result;
}

function PaymentsMovementsPreview() {
  const {t} = useTranslation();
  const {data: movementsPreview, error: movementsPreviewError} = useQuery<
    PaymentsMovements,
    string
  >('paymentsMovementsPreview', fetchMovementsPreview);
  useErrorToast(movementsPreviewError, {
    notFoundMessage: 'Movements previews not found.',
  });

  const nonEmptyMovements = React.useMemo(() => {
    return filterEmptyMovements(movementsPreview);
  }, [movementsPreview]);

  const hasMovementsPreview = React.useMemo(() => {
    return Boolean(Object.keys(nonEmptyMovements).length);
  }, [nonEmptyMovements]);

  return (
    <Content>
      <PaymentsMovementsGrid movements={nonEmptyMovements} />
      {hasMovementsPreview && (
        <Link to="/payments/movements">
          <SeeMoreButton secondary label={t('see_more')} />
        </Link>
      )}
    </Content>
  );
}

export {PaymentsMovementsPreview};
